import React, { useEffect, useState  } from 'react';
import { array, func } from 'prop-types';
import './style.scss';

const StatusSelectDropdown = ({
  options,
  onChange,
  statusFilterValues
}) => {
  const [selectedStatus, setSelectedStatus] = useState(options);
  const [allSelected, setAllSelected] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const name = 'statusFilter';
    const value = selectedStatus.length == options.length ? [] : selectedStatus.map((status) => status.value);
    onChange({target: {name, value}});
  }, [selectedStatus]);

  useEffect(() => {
    if (statusFilterValues.length == 0 && selectedStatus.length > 0) {
      setSelectedStatus(options);
      setAllSelected(true);
    }
  }, [statusFilterValues]);


  const handleChange = (e, value) => {
    const { checked } = e.target;

    if (allSelected && !checked) {
      setSelectedStatus((prevSelected) =>
        prevSelected.filter((option) => option.value == value.value)
      );
    } else if (checked) {
      setSelectedStatus((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedStatus((prevSelected) =>
        prevSelected.filter((option) => option.value !== value.value)
      );
    }

    setAllSelected(false);
  };

  const handleAllCheckboxChange = (e) => {
    setAllSelected(e.target.checked);

    if (e.target.checked) {
      setSelectedStatus(options);
    } else {
      setSelectedStatus([]);
    }
  };

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleDocumentClick = (e) => {
    if (!e.target.closest('.ap-select-wrap.admin.expanded')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className={`ap-select-wrap pci-status-select-wrap admin ${isOpen ? 'expanded' : ''}`}>
      <label htmlFor="status-dropdown-button" className="ap-select-label">
        Compliance status
      </label>
      <button
        id='status-dropdown-button'
        data-testid='status-combobox'
        className='ap-select-toggle'
        type='button'
        onClick={handleSelectClick}
      >
        <div data-testid='selected-status' className='selected-status ap-selected-text'>
          {allSelected ? 'All' : selectedStatus.length > 0 ? selectedStatus.map((status) => status.text).join(', ') : 'All'}
        </div>
      </button>
      <ul role='listbox' tabIndex='-1' className='ap-options pci-status-options'>
        <li className='ap-select-item'>
          <input
            type='checkbox'
            id='all-checkbox'
            checked={allSelected}
            onChange={handleAllCheckboxChange}
          />
          <label htmlFor='all-checkbox'>{options[0].text}</label>
        </li>
        {options.slice(1).map((option) => (
          <li className='ap-select-item' key={option.value}>
            <input
              type='checkbox'
              id={`option-${option.value}`}
              data-testid={`option-${option.value}`}
              className='status-select-input'
              value={option.value}
              checked={selectedStatus.some(status => status.value === option.value)}
              onChange={(e) => handleChange(e, option)}
            />
            <label htmlFor={`option-${option.value}`}>{option.text}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

StatusSelectDropdown.propTypes = {
  options: array,
  statusFilterValues: array,
  onChange: func
};

export default StatusSelectDropdown;