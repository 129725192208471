import React, { useEffect, useState } from 'react';
import { array, bool, number, shape, string } from 'prop-types';
import { DineroCentsFormat } from '../../lib/monetaryUtils';

// eslint-disable-next-line react/display-name
const SummaryComponent = React.memo(({
  currencySymbol,
  centsAmountDue,
  tabsState,
  ccSurcharge,
  achSurcharge,
  isSurchargeable,
  surchargeAchFee,
  surchargeCardFee
}) => {
  const [surchargeFee, setSurchargeFee] = useState();
  const [surchargeLabel, setSurchargeLabel] = useState();

  useEffect(() => {
    const activeTab = tabsState.find(el => el.active);
    if (activeTab?.text === 'Card') {
      handleSurcharge(ccSurcharge, 'card');
    } else if (activeTab?.text === 'eCheck') {
      handleSurcharge(achSurcharge, 'ach');
    }
  }, [tabsState, ccSurcharge, achSurcharge, isSurchargeable, surchargeAchFee, surchargeCardFee]);

  const handleSurcharge = ({ enabled, percent, label, type }, account) => {
    let isSurchargeDisplayed = enabled,
      surchargeFee;
    if (account === 'card') {
      isSurchargeDisplayed = enabled && isSurchargeable;
      surchargeFee = surchargeCardFee;
    } else if (account === 'ach') {
      surchargeFee = surchargeAchFee;
    }
    if (isSurchargeDisplayed) {
      let surchargeLabel = label;
      if (type === 'percent') {
        surchargeLabel = `${label} (${percent}%)`;
      }
      setSurchargeLabel(surchargeLabel);
      setSurchargeFee(DineroCentsFormat(surchargeFee, currencySymbol));
    } else {
      setSurchargeLabel();
      setSurchargeFee();
    }
  };

  return (
    <div className="payment-summary-subtotals">
      <div className="payment-summary-description">
        <div data-testid="summary-payment-amount">Payment</div>
        <div data-testid='subtotal'>{DineroCentsFormat(centsAmountDue, currencySymbol)}</div>
      </div>
      {surchargeLabel && (
        <div className="payment-summary-description">
          <div data-testid="surcharge-label">
            {surchargeLabel}
          </div>
          <div data-testid="surcharge-value">
            {surchargeFee}
          </div>
        </div>
      )}
    </div>
  );
});

SummaryComponent.propTypes = {
  currencySymbol: string,
  centsAmountDue: number,
  tabsState: array,
  ccSurcharge: shape({
    enabled: bool,
    percent: string,
    label: string,
    type: string
  }),
  achSurcharge: shape({
    enabled: bool,
    percent: string,
    label: string,
    type: string
  }),
  isSurchargeable: bool,
  surchargeAchFee: number,
  surchargeCardFee: number
};

export default SummaryComponent;
