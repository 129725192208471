import React from 'react';
import { render, screen } from '@testing-library/react';
import RequiredMaterialsModal from '.';

describe('Required Materials Modal Component', () => {
  it('renders the default business information label', () => {
    const brand = 'lawpay',
      brandTitle = 'LawPay';

    render(<RequiredMaterialsModal brand={brand} brandTitle={brandTitle} isModalShown={true} />);
    expect(screen.getByText('Before You Begin')).toBeInTheDocument();
    expect(screen.getByText('Business information')).toBeInTheDocument();
    expect(screen.getByText('Ownership information')).toBeInTheDocument();
  });

  it('renders the correct label for Affinipay brand', () => {
    const brand = 'affinipay',
      brandTitle = 'AffiniPay';

    render(<RequiredMaterialsModal brand={brand} brandTitle={brandTitle} isModalShown={true} />);
    expect(screen.getByText('Organization information')).toBeInTheDocument();
    expect(screen.getByText('Authorized signer information')).toBeInTheDocument();
  });
});

