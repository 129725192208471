import React, { useState } from 'react';
import BeneficialOwnerForm from './BeneficialOwnerForm';
import { bool, func, number } from 'prop-types';
import notify from 'lib/notify';
import client from 'lib/ajax';
import { defaultBeneficialOwnerFormState } from './helpers/constants';

const CreateBeneficialOwner = ({
  merchantId,
  setCurrentLegalEntity,
  setIsCreateOwnerView
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [formState, setFormState] = useState(defaultBeneficialOwnerFormState);

  const createBeneficialOwner = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const resp = await client.post(`/admin/merchants/${merchantId}/beneficial_owners`, formState);
      const json = await resp.json();
      if (resp.ok) {
        setCurrentLegalEntity(previousLegalEntity => ({
          ...previousLegalEntity,
          attributes: {
            ...previousLegalEntity.attributes,
            beneficial_owners: [...(previousLegalEntity?.attributes?.beneficial_owners || []), json]
          }
        }));
        notify.success('Beneficial Owner successfully created');
        backToLegalEntities();
      }
      else {
        notify.error(json?.error || 'Unexpected error occurred when creating beneficial owner', [], false);
        setIsSaving(false);
      }
    }
    catch (e) {
      notify.error('Unexpected error occurred when creating beneficial owner', [], false);
      setIsSaving(false);
    }
  };

  const backToLegalEntities = () => {
    setIsCreateOwnerView(false);
  };

  return (
    <BeneficialOwnerForm
      disabled={isSaving}
      formState={formState}
      setFormState={setFormState}
      onSubmit={createBeneficialOwner}
      backToLegalEntities={backToLegalEntities}
      isEditView={false}
    />
  );
};

export default CreateBeneficialOwner;

CreateBeneficialOwner.propTypes = {
  merchantId: number,
  setCurrentLegalEntity: func,
  setIsCreateOwnerView: func
};
