export const complianceStatusOptions = [
  { text: 'All', value: null },
  { text: 'Compliant', value: 'compliant' },
  { text: 'Due Soon', value: 'due_soon' },
  { text: 'Exempted', value: 'exempted' },
  { text: 'Exempted Advanced', value: 'non_saq_a' },
  { text: 'Exempted Closed', value: 'exempted_closed' },
  { text: 'Expired', value: 'expired' },
  { text: 'Grace Period', value: 'grace' },
  { text: 'Unknown', value: 'unknown' },
  { text: 'Started', value: 'started' },
  { text: 'Third-party Compliant', value: 'third_party_compliant' }
];
