import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import RecurringChargeFrequency from './RecurringChargeFrequency';
import RecurringChargeDatepicker from './RecurringChargeDatepicker';
import RecurringChargeInterval from './RecurringChargeInterval';
import RecurringChargeAmount from './RecurringChargeAmount';
import RecurringChargeEndDate from './RecurringChargeEndDate';
import {getDateFromHyphenedFormat} from '../../lib/utils';

const formatApiDate = (day) => {
  if (!day || day === 'LAST') {
    return '';
  }

  return `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
};

const formatRecurStartDate = (day) => {
  if (!day) {
    return '';
  }

  var month_names = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  // Should handle last day by date here instead of checking for LAST from callback
  return day === 'LAST'
    ? 'LAST'
    : `${day.getDate()}-${month_names[day.getMonth()]}-${day.getFullYear()}`;
};

const getFrequencyObject = (frequency) => {
  return frequency.schedule || frequency;
};
const getDaysFromProps = (props) => {

  const days = props.days;
  if (!days) {
    return undefined;
  }
  if (days && days[1] === 'LAST') {
    return 31;
  } else {
    return parseInt(days[1], 10);
  }
};
const setInitialSelectedDay = (obj, recurInitial, currentPage) => {
  if (currentPage === 'copy') {
    return new Date(Date.now());
  }
  if (obj.start) {
    return getDateFromHyphenedFormat(obj.start);
  }
  if (recurInitial) {
    return new Date(recurInitial);
  }
  return new Date(Date.now());
};

export default class RecurringChargeSchedule extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    recurFrequency: PropTypes.object,
    newRecord: PropTypes.bool,
    isComplete: PropTypes.bool,
    amount: PropTypes.string,
    intervalDelay: PropTypes.string,
    recurInitial: PropTypes.string,
    maxOccurrences: PropTypes.number,
    currentPage: PropTypes.string,
    isLastDayFirstCharge: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    paidOccurrences: PropTypes.number
  };
  //reload
  state = {
    oneTime:
      getFrequencyObject(this.props.recurFrequency).intervalUnit === 'DAY',
    selectedDay: setInitialSelectedDay(
      getFrequencyObject(this.props.recurFrequency),
      this.props.recurInitial,
      this.props.currentPage
    ),
    amount: this.props.amount,
    maxAmount:
      getFrequencyObject(this.props.recurFrequency).maxAmount === '0.00'
        ? ''
        : getFrequencyObject(this.props.recurFrequency).maxAmount,
    scheduleStartDate: this.props.recurInitial
      ? formatApiDate(new Date(this.props.recurInitial))
      : formatApiDate(new Date()),
    recurStart: this.props.recurInitial
      ? formatRecurStartDate(new Date(this.props.recurInitial))
      : formatRecurStartDate(new Date()),
    biMonthly: getFrequencyObject(this.props.recurFrequency).days && getFrequencyObject(this.props.recurFrequency).days.length > 1,
    isLastDayFirstCharge: getFrequencyObject(this.props.recurFrequency).days && getFrequencyObject(this.props.recurFrequency).days[0] === 'LAST' ? 'LAST' : undefined,
    isLastDaySecondCharge: getFrequencyObject(this.props.recurFrequency).days && getFrequencyObject(this.props.recurFrequency).days[1] === 'LAST' ? 'LAST' : undefined,
    days: getDaysFromProps(getFrequencyObject(this.props.recurFrequency))
  };

  constructor(props) {
    super(props);
  }

  handleChange = ({
    scheduleStartDate,
    recurStart,
    scheduleEndDate,
    recurFrequency,
    ...state
  }) => {
    let newState = {...state};

    if (scheduleStartDate) {
      if (recurStart.getDate() === this.state.days ||
        (this.state.days === 31 && newState.isLastDayFirstCharge === 'LAST')) {
        newState.days = undefined;
        newState.secondChargeDate = undefined;
      }

      newState.scheduleStartDate = formatApiDate(scheduleStartDate);
    }

    if (newState.days) {
      if (newState.days === 31 && this.state.isLastDayFirstCharge === 'LAST') {
        newState.days = undefined;
        newState.secondChargeDate = undefined;
      }
    }

    if (recurFrequency) {
      newState.recurFrequency = recurFrequency;
    }

    if (scheduleEndDate) {
      newState.scheduleEndDate = formatApiDate(scheduleEndDate);
    }

    if (recurStart) {
      let firstCalendarDate;
      let endDate;

      if (this.state.recurFrequency === 'MONTHLY' && newState.isLastDayFirstCharge === 'LAST') {
        newState.intervalDelay = undefined;
      }

      if (this.state.scheduleEndDate) {
        firstCalendarDate = getDateFromHyphenedFormat(recurStart);
        endDate = getDateFromHyphenedFormat(this.state.scheduleEndDate);

        if (firstCalendarDate.getTime() >= endDate.getTime() || recurStart.getDate() === endDate.getDate()) {
          newState.scheduleEndDate = undefined;
        }
      }

      newState.recurStart = formatRecurStartDate(recurStart);
    }
    this.enableSubmitButton();
    this.setState(newState);
  };


  enableSubmitButton = () => {
    const btn = document.getElementById('submit_button');
    if (btn) {
      btn.disabled = false;
    }
  };

  getEditRecurFrequency = () => {
    if (this.props.newRecord) {
      return 'MONTHLY';
    }
    const frequencyObj = getFrequencyObject(this.props.recurFrequency),
      intervalUnit = frequencyObj.intervalUnit,
      intervalDelay = frequencyObj.intervalDelay;
    if (frequencyObj.days && frequencyObj.days.length > 1) {
      return 'BI_MONTHLY';
    } else if (
      intervalDelay &&
      intervalUnit === 'MONTH'
    ) {
      return 'MONTHLY';
    } else if (intervalDelay && intervalUnit === 'WEEK') {
      return 'WEEKLY';
    } else {
      return 'DAY';
    }
  };

  getEditEndDate = () => {
    if (this.props.newRecord) {
      return;
    }
    const frequencyObj = getFrequencyObject(this.props.recurFrequency),
      endDate = frequencyObj.end;
    return endDate;
  };

  render() {
    return (
      <div className="recurring-charge-schedule">
        <div className="row">
          <div className="col-md-12">
            <RecurringChargeAmount  
              handleChange={this.handleChange}
              amount={this.state.amount}
              isComplete={this.props.isComplete}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <RecurringChargeFrequency
              handleChange={this.handleChange}
              items={this.props.items}
              selectId="recurFrequency"
              initialRecurChargeFrequency={
                this.props.newRecord
                  ? 'MONTHLY'
                  : getFrequencyObject(this.props.recurFrequency)
              }
            />
          </div>
          <div className='col-sm-6'>
            <RecurringChargeInterval
              recurFrequency={this.state.recurFrequency || this.getEditRecurFrequency()}
              oneTime={this.state.oneTime}
              biMonthly={this.state.biMonthly}
              handleChange={this.handleChange}
              selectedDay={this.state.selectedDay}
              secondChargeDate={this.state.days}
              isLastDayFirstCharge={this.state.isLastDayFirstCharge}
              isLastDaySecondCharge={this.state.isLastDaySecondCharge}
              intervalDelay={
                this.state.intervalDelay === undefined
                  ? getFrequencyObject(this.props.recurFrequency).intervalDelay
                  : this.state.intervalDelay
              }
            />
          </div>
          <div className='col-sm-12'>
            <RecurringChargeDatepicker 
              handleChange={this.handleChange}
              oneTime={this.state.oneTime}
              biMonthly={this.state.biMonthly}
              scheduleStartDate={this.state.scheduleStartDate}
              selectedDay={this.state.selectedDay}
              recurStart={this.state.recurStart}
              recurInitial={this.props.recurInitial}
              secondChargeDate={this.state.days}
              isLastDayFirstCharge={this.state.isLastDayFirstCharge}
              isLastDay={!!this.state.isLastDayFirstCharge && this.state.recurFrequency !== 'BI_MONTHLY'}
              isLastDaySecondCharge={this.state.isLastDaySecondCharge}
              recurFrequency={this.state.recurFrequency || this.getEditRecurFrequency()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {this.state.oneTime || (
              <RecurringChargeEndDate
                handleChange={this.handleChange}
                scheduleStartDate={this.state.scheduleStartDate}
                scheduleEndDate={
                  this.state.scheduleEndDate || this.getEditEndDate()
                }
                selectedDay={this.state.selectedDay}
                amountReaches={this.state.maxAmount}
                maxOccurrences={this.state.maxOccurrences || this.props.maxOccurrences}
              />
            )}
          </div>
        </div>

        <APInputText
          type="hidden"
          name="validation_paid_occurrences"
          id="validation-paid-occurrences"
          value={this.props.paidOccurrences}
        />
      </div>
    );
  }
}
