import React from 'react';
import {render, screen} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import Header, {checkHeaderProfileToggle} from './';

describe('checkHeaderProfileToggle', () => {
  it('returns a boolean value', ()=> {
    const target = {
      id: 'foo',
      parentNode: {
        id: 'test'
      }
    };

    expect(typeof checkHeaderProfileToggle(target)).toBe('boolean');
  });

  it('returns false if a target is not passed', () => {
    expect(checkHeaderProfileToggle()).toBe(false);
  });

  it('returns false if a parentNode of the passed target is missing', () => {
    expect(checkHeaderProfileToggle({})).toBe(false);
  });

  it('returns false if id of target is header-profile-toggle', () => {
    const target = { id: 'header-profile-toggle'};
    expect(checkHeaderProfileToggle(target)).toBe(false);
  });

  it('returns false if id of target is header-profile-menu', () => {
    const target = { id: 'header-profile-menu'};
    expect(checkHeaderProfileToggle(target)).toBe(false);
  });

  it('returns false if id of parentNode of target is header-profile-toggle', () => {
    const target = {
      id: 'foo',
      parentNode: {
        id: 'header-profile-toggle'
      }
    };

    expect(checkHeaderProfileToggle(target)).toBe(false);
  });

  it('returns false if id of parentNode of target is header-profile-menu', () => {
    const target = {
      id: 'foo',
      parentNode: {
        id: 'header-profile-toggle'
      }
    };

    expect(checkHeaderProfileToggle(target)).toBe(false);
  });

  it('returns true if ids of parentNode and target are not header-profile-menu or header-profile-toggle', () => {
    const target = {
      id: 'foo',
      parentNode: {
        id: 'bar'
      }
    };

    expect(checkHeaderProfileToggle(target)).toBe(true);
  });
});

describe('Header Component', () => {
  it('renders', () => {
    const {container} = render(<Header {...getMockProps('standard')} />);
    expect(container.firstChild).toHaveClass('ap-header');
  });

  it('render to test alert mode', () => {
    const {container} = render(<Header {...getMockProps('alert-mode')}/>);
    const topNav = container.querySelector('#top-navbar');
    expect(topNav).toHaveClass('alert-mode');
  });

  it('render to test echeck button', () => {
    render(<Header {...getMockProps('echeck')}/>);
    expect(screen.getAllByText('eCheck')[0]).toBeInTheDocument();
  });

  it('render to test profileMenu toggle', () => {
    const {container} = render(<Header {...getMockProps('standard')}/>);
    const toggle = container.querySelector('#header-profile-toggle');
    expect(toggle).not.toHaveClass('active');
    userEvent.click(toggle);
    expect(toggle).toHaveClass('active');
  });

  it('render to test mobile menu toggle', () => {
    const {container} = render(<Header {...getMockProps('standard')}/>);
    const toggle = container.querySelector('#hamburger-button');
    expect(toggle).not.toHaveClass('active');
    userEvent.click(toggle);
    expect(toggle).toHaveClass('active');
  });

  it('outsideClick attached to useEffect closes profile toggle', () => {
    const {container} = render(<Header {...getMockProps('standard')}/>);
    const toggle = container.querySelector('#header-profile-toggle');
    userEvent.click(toggle);
    expect(toggle).toHaveClass('active');
    userEvent.click(container.firstChild);
    expect(toggle).not.toHaveClass('active');
  });

  it('legacy quick bill button shows when props allow', () => {
    const {container} = render(<Header {...getMockProps('quickbill-legacy')}/>);
    expect(container.querySelector('.legacy-quick-bill')).toBeInTheDocument();
  });

  it('invoicing quick bill button shows when the correct props allow', () => {
    render(<Header {...getMockProps('invoicing-quickbill')} />);
    expect(screen.getByTitle('Send Quick Bill Icon')).toBeInTheDocument();
    expect(screen.getByText('Send Quick Bill')).toBeVisible();
  });
});

const getMockProps = type => {
  const mocks = {
    standard:{
      username: 'John Doe',
      profileMenu: [
        {
          icon: 'profile',
          text: 'My Profile',
          url: 'foo',
          isShown: true
        },
        {
          icon: 'settings',
          text: 'Settings',
          url: 'bar',
          isShown: false
        }],
      navigationData: {
        links: [
          {
            url: '/',
            text: 'Dashboard',
            isShown: true,
            isActive: true
          },
          {
            url: '/any',
            text: 'Charge',
            isShown: false,
            isActive: false
          }]
      }
    },
    'alert-mode': {
      username: 'John Doe',
      testMode: true,
      profileMenu: [
        {
          icon: 'profile',
          text: 'My Profile',
          url: 'foo',
          isShown: true
        },
        {
          icon: 'settings',
          text: 'Settings',
          url: 'bar',
          isShown: false
        }],
      navigationData: {
        links: [
          {
            url: '/',
            text: 'Dashboard',
            isShown: true,
            isActive: true
          },
          {
            url: '/any',
            text: 'Charge',
            isShown: false,
            isActive: false
          }]
      }
    },
    echeck: {
      username: 'John Doe',
      echeckUrl: 'foo',
      profileMenu: [
        {
          icon: 'profile',
          text: 'My Profile',
          url: 'foo',
          isShown: true
        },
        {
          icon: 'settings',
          text: 'Settings',
          url: 'bar',
          isShown: false
        }],
      navigationData: {
        links: [
          {
            url: '/',
            text: 'Dashboard',
            isShown: true,
            isActive: true
          },
          {
            url: '/any',
            text: 'Charge',
            isShown: false,
            isActive: false
          }]
      }
    },
    'quickbill-legacy': {
      username: 'John Doe',
      quickBillEmail: true,
      profileMenu: [
        {
          icon: 'profile',
          text: 'My Profile',
          url: 'foo',
          isShown: true
        },
        {
          icon: 'settings',
          text: 'Settings',
          url: 'bar',
          isShown: false
        }],
      navigationData: {
        links: [
          {
            url: '/',
            text: 'Dashboard',
            isShown: true,
            isActive: true
          },
          {
            url: '/any',
            text: 'Charge',
            isShown: false,
            isActive: false
          }]
      }
    },
    'invoicing-quickbill': {
      username: 'John Doe',
      invoiceSPAQuickBill: true,
      profileMenu: [
        {
          icon: 'profile',
          text: 'My Profile',
          url: 'foo',
          isShown: true
        },
        {
          icon: 'settings',
          text: 'Settings',
          url: 'bar',
          isShown: false
        }],
      navigationData: {
        links: [
          {
            url: '/',
            text: 'Dashboard',
            isShown: true,
            isActive: true
          },
          {
            url: '/any',
            text: 'Charge',
            isShown: false,
            isActive: false
          }]
      }
    }
  };
  return mocks[type] || undefined;
};
