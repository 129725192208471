import React from 'react';

const LegacyQuickBillButton = () => (
  <a 
    className="legacy-quick-bill" 
    data-toggle="modal" 
    data-target="#ajax-modal" 
    data-remote="true"
    href="/payment_request_emails/new"
    aria-label="Send a Quick Bill"
  >
    <svg xmlns="http://www.w3.org/2000/svg" id="icn-mail" viewBox="0 0 36 36">
      <path 
        className="icon-path-1"
        d="M0,3.6V32.4H36V3.6ZM4,7.2H32L18,16h0Zm28.4,4V28.8H3.6V11.2l14.4,9h0Z"
      ></path>
    </svg>
    <span className="quick-bill-button-text">Quick Bill</span>
  </a>
);

export default LegacyQuickBillButton;
