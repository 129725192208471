import React, { useState } from 'react';
import { APButton, APModal, APTable } from 'affinipay-ui-library';
import ErrorBoundary from 'components/ErrorBoundary';
import AdminPageHeader from '../AdminPageHeader';
import FeatureTogglePage from './FeatureTogglePage';
import AddFeatureForm from './AddFeatureForm';
import { featureTableHeaderData, strings } from './helpers/constants';
import PropTypes from 'prop-types';

const FeatureToggles = ({ flipper_features = {} }) => {
  const [features, setFeatures] = useState(flipper_features?.data?.map(f => f?.attributes) || []);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [isAddModalShowing, setIsAddModalShowing] = useState(false);

  const toggleModal = () => setIsAddModalShowing(!isAddModalShowing);
  const backToAllFeatures = () => setSelectedFeature(null);

  return (
    <ErrorBoundary>
      <section className='admin'>
        <AdminPageHeader title={selectedFeature ? selectedFeature.key : strings.sectionTitle}>
          {selectedFeature ? 
            <APButton onClick={backToAllFeatures}>
              Back to All Features
            </APButton>
            :
            <APButton onClick={toggleModal}>
              Create Feature
            </APButton>
          }
        </AdminPageHeader>
        {selectedFeature ?
          <FeatureTogglePage
            feature={selectedFeature}
            setFeatures={setFeatures}
            setSelectedFeature={setSelectedFeature}
          />
          :
          <div className='table-responsive'>
            <APTable
              data={features}
              header={[
                ...featureTableHeaderData, 
                {
                  accessor: 'actions',
                  disableSortBy: true,
                  Cell: ({ row }) => ( // eslint-disable-line react/display-name, react/prop-types
                    <APButton
                      className='ap-table-button'
                      onClick={() => setSelectedFeature(row.original)} // eslint-disable-line react/prop-types
                    >
                      Show Feature
                    </APButton>
                  )
                }
              ]}
              noRecordsText={strings.noRecords}
            />
          </div>
        }
        <APModal
          isActiveInitial={isAddModalShowing}
          onClose={toggleModal}
          headerText={strings.addFeatureModalTitle}
          className='feature-modal'
        >
          <div className="container-fluid">
            <AddFeatureForm 
              setFeatures={setFeatures} 
              setSelectedFeature={setSelectedFeature}
              closeModal={toggleModal}
            />
          </div>
        </APModal>
      </section>
    </ErrorBoundary>
  );
};

FeatureToggles.propTypes = {
  flipper_features: PropTypes.object
};

export default FeatureToggles;