import client from 'lib/ajax';
import { urls } from './constants';
import { buildQueryStringFromParams } from 'lib/utils';

export const createFeature = async (params = {}) => await client.post(urls.createFeature, params);

export const createFeatureGate = async (params = {}) => await client.post(urls.createFeatureGate(params.feature_key), params);

export const getFeatureGates = async (feature = {}, params = {}) => {
  const payload = {
    feature_key: feature.key,
    sort_by: params.sortBy,
    page: params.page,
    order: params.sortOrder,
    value: params.nameFilter
  };
  const queryString = buildQueryStringFromParams(payload);
  const url = `${urls.getFeatureGates(feature.key)}?${queryString}`;
  return await client.get(url);
};

export const deleteFeature = async (feature = {}) => await client.destroy(urls.deleteFeature(feature.key));

export const deleteFeatureGate = async (gate = {}) => await client.destroy(urls.deleteFeatureGate(gate.feature_key, gate.id));

export const enableFeature = async (feature = {}) => await client.post(urls.enableFeature(feature.key));

export const disableFeature = async (feature = {}) => await client.post(urls.disableFeature(feature.key));
