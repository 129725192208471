import React from 'react';
import { JobTitle, jobTitles } from 'components/Select';
import PropTypes from 'prop-types';

class JobTitles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: jobTitles.find(
        (option) => option.value === props.selected
      ),
    };
  }

  handleChange(value) {
    this.setState({
      selectedOption: jobTitles.find((option) => option.value === value),
    });
  }
  render() {
    const { name } = this.props;

    return (
      <div>
        <label>Job Title</label>
        <JobTitle
          name={name}
          value={this.state.selectedOption}
          onChange={(option) => {
            this.handleChange(option.value);
          }}
        />
      </div>
    );
  }
}

JobTitles.propTypes = {
  selected: PropTypes.string,
  name: PropTypes.string,
};

export default JobTitles;
