// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeBankAccount Component shallow snapshot render 1`] = `
<section>
  <div
    className="col-xs-12"
  >
    <label
      className="control-label--uppercase"
    >
      Enter Bank Account Information
    </label>
  </div>
  <div
    className="col-xs-12"
  >
    <label
      htmlFor="account"
    >
      Account Type
    </label>
  </div>
  <div
    className="col-xs-12 col-sm-6"
  >
    <div
      className="radio-buttons radio"
      key="0"
    >
      <APRadioButton
        checked={true}
        id="account_individual_checking"
        labelClassNames="radio-inline control-label"
        name="account"
        onChange={[Function]}
        text="Personal Checking"
        value="individual_checking"
      />
    </div>
    <div
      className="radio-buttons radio"
      key="1"
    >
      <APRadioButton
        id="account_individual_savings"
        labelClassNames="radio-inline control-label"
        name="account"
        onChange={[Function]}
        text="Personal Savings"
        value="individual_savings"
      />
    </div>
  </div>
  <div
    className="col-xs-12 col-sm-6"
  >
    <div
      className="radio-buttons radio"
      key="0"
    >
      <APRadioButton
        id="account_business_checking"
        labelClassNames="radio-inline control-label"
        name="account"
        onChange={[Function]}
        text="Business Checking"
        value="business_checking"
      />
    </div>
    <div
      className="radio-buttons radio"
      key="1"
    >
      <APRadioButton
        id="account_business_savings"
        labelClassNames="radio-inline control-label"
        name="account"
        onChange={[Function]}
        text="Business Savings"
        value="business_savings"
      />
    </div>
  </div>
  <AccountHolderFields
    type="individual"
  />
  <div
    className="account_fields"
  >
    <span>
      <ForwardRef
        className="aba_routing_field"
        dataset={
          {
            "data-chargeio": "routing_number",
          }
        }
        id="routing_number"
        label="Routing Number"
        onChange={[Function]}
        parentClass="col-xs-12 col-sm-6"
      />
      <ForwardRef
        name="method[routing_number]"
        type="hidden"
      />
      <ForwardRef
        dataset={
          {
            "data-chargeio": "account_number",
          }
        }
        id="account_number"
        label="Account Number"
        onChange={[Function]}
        parentClass="col-xs-12 col-sm-6"
      />
      <ForwardRef
        name="method[account_number]"
        type="hidden"
      />
    </span>
  </div>
</section>
`;
