import jwtDecode from 'jwt-decode';
import AccessTokenService from '../services/AccessTokensService';

let accessToken;

export const isAccessTokenExpired = ()  => {
  if (!accessToken) return true;

  // https://github.com/auth0/jwt-decode/issues/53#issuecomment-314828549
  const currentTime = Math.floor(Date.now() / 1000);

  return currentTime > accessTokenExp(accessToken);
};

const accessTokenExp = accessToken => {
  const { exp } = jwtDecode(accessToken);
  return exp;
};

const fetchAccessToken = async url => {
  const { accessToken, error } = await AccessTokenService(url);
  if (error) { throw new Error(error); }
  return accessToken;
};

export const authHeaders = async () => {
  if (isAccessTokenExpired(accessToken)) {
    accessToken = await fetchAccessToken();
  }

  return {
    'Accept': 'application/vnd.api+json',
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/vnd.api+json'
  };
};

export const csvHeaders = async () => {
  if (isAccessTokenExpired(accessToken)) {
    accessToken = await fetchAccessToken();
  }
  return {
    Accept: 'text/csv; charset=utf-8',
    Authorization: `Bearer ${accessToken}`
  };
};