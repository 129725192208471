import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const EyeIcon = ({clickHandler, tabIndex}) => {
  return (
    <button className='eye-icon'
      onClick={clickHandler}
      tabIndex={tabIndex} />
  );
};

EyeIcon.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  tabIndex: PropTypes.string
};

export default EyeIcon;
