import React from 'react';
import PropTypes from 'prop-types';
import APRadioButton from '../../components/APRadioButton';

const APRadioButtonRails = (props) => {
  return (
    <APRadioButton
      name={props.name}
      id={props.id}
      value={props.value}
      checked={props.checked}
      onChange={props.onChange}
      text={props.text}
    />
  );
};

APRadioButtonRails.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string
};

export default APRadioButtonRails;
