import React from 'react';
import { bool } from 'prop-types';

const DepositAccountsTableHead = ({
  isPolicyQuickBills
}) => {
  return (
    <thead>
      {isPolicyQuickBills ? (
        <tr>
          <th>Deposit</th>
          <th colSpan="3">Processing Account</th>
        </tr>
      ) : (
        <tr>
          <th>
            Name / Type
          </th>
        </tr>
      )}
    </thead>
  );
};

DepositAccountsTableHead.propTypes = {
  isPolicyQuickBills: bool
};

export default DepositAccountsTableHead;
