import React from 'react';
import { number, string } from 'prop-types';

const IconTransactions = ({
  className='ap-icon',
  fill,
  size,
  title = 'View Transaction Details'
}) => {
  return (
    <svg
      className={ className }
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox='0 0 36 36'
    >
      <title>{title}</title>
      <path
        d="M25.8,19.7l2.6-2.5,7.3,7.3.3.3h0l-.4.3-7.3,7.2-2.5-2.6,3.3-3.3H7.2V22.9H29.1ZM7.6,3.6l2.6,2.5L6.9,9.4H28.8V13H6.9l3.3,3.3L7.7,18.9.4,11.6,0,11.3l.3-.3Z"
        fill={ fill }
      />
    </svg>
  );
};

IconTransactions.defaultProps = {
  className: 'ap-icon',
  fill: '#000',
  size: 36
};

IconTransactions.propTypes = {
  className: string,
  fill: string,
  size: number,
  title: string
};

export default IconTransactions;