import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';

// eslint-disable-next-line react/display-name
const PaymentInformationComponent = React.memo(({
  formState,
  setFormState,
  referenceLabel = 'Reference',
  referenceRequired,
  disabledFieldsState
}) => {

  const handleInputChange = (e) => {
    const name = e.target.name,
      value = e.target.value;
    let newState = { [`${name}`]: value };
    setFormState({ ...formState, ...newState });
  };

  return (
    <div className="row">
      {referenceRequired!==0 && (
        <div className="col-12 form-group">
          <APInputText
            isFloatingLabel={true}
            label={referenceLabel}
            value={formState.reference}
            name='reference'
            required={referenceRequired===2}
            onChange={handleInputChange}
            disabled={disabledFieldsState['reference']}
          />
        </div>
      )}
    </div>
  );
});

PaymentInformationComponent.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  onChange: PropTypes.func,
  referenceLabel: PropTypes.string,
  referenceRequired: PropTypes.number,
  disabledFieldsState: PropTypes.object
};

export default PaymentInformationComponent;
