//not exposed in production
import React from 'react';
import {array, func, object} from 'prop-types';
import { APInputText, APSelect } from 'affinipay-ui-library';
import APRadioButton from '../../../components/APRadioButton';

const PlanSection = ({sites, formState, setFormState, handlePlanTypeChange, handleIncludeECheckChange, onInputValidation}) => {

  const trustOptions = [
    {text: 'No Trust'},
    {text: 'Trust Optional'},
    {text: 'Trust Required'},
    {text: 'Only Trust'}
  ];

  const handleInputChange = (e) => {
    const name = e.target.name,
      value = e.target.value,
      newState = {};
    newState[name] = value;
    setFormState({...formState, ...newState});
    onInputValidation(name, value);
  };

  return (
    <div className="row form-group">
      <div className="col-xs-4">
        <div className="form-group">
          <APSelect 
            label="Site"
            name="planSite"
            options={sites}
            onChange={handleInputChange}
            parentClass="admin planSite"
            value={formState['planSite']}
          />
        </div>
        <div className="form-group">
          <div>
            <label>Status</label>
          </div>
          <APRadioButton
            checked={true}
            name="planStatus"
            onChange={handleInputChange}
            text="Published"
            value="published"
          />
          <APRadioButton
            checked={false}
            name="planStatus"
            onChange={handleInputChange}
            text="Not Published"
            value="not published"
          />
        </div>
        <div className="form-group">
          <div>
            <label>Plan Type</label>
          </div>
          <APRadioButton
            checked={true}
            name="planType"
            onChange={(e) => {
              handlePlanTypeChange(e.target.value);
              handleInputChange(e);
            }}
            text="Internal Plan"
            value="internal"
          />
          <APRadioButton
            checked={false}
            name="planType"
            onChange={(e) => {
              handlePlanTypeChange(e.target.value);
              handleInputChange(e);
            }}
            text='External Plan'
            value='external'
          />
        </div>
      </div>
      <div className="col-xs-4">
        <div className="form-group">
          <APInputText
            label="Name (Token)"
            name="planName"
            onChange={handleInputChange}
            parentClass="admin"
            value={formState.planName}
          />
        </div>
        <div className="form-group">
          <div>
            <label>Guarantee Type</label>
          </div>
          <APRadioButton
            checked={true}
            name="guaranteeType"
            onChange={handleInputChange}
            text="Corporate Guarantee"
            value="corporate"
          />
          <APRadioButton
            name="guaranteeType"
            checked={false}
            text="Personal Guarantee"
            onChange={handleInputChange}
            value="personal"
          />
        </div>
        <div className="form-group">
          <div>
            <label>Payment Types</label>
          </div>
          <APRadioButton
            name="paymentType"
            value="includeECheck"
            checked={true}
            text="Include eCheck"
            onChange={(e) => {
              handleIncludeECheckChange(e.target.value);
              handleInputChange(e);
            }}/>
          <APRadioButton
            name="paymentType"
            value="noECheck"
            checked={false}
            text="No eCheck"
            onChange={(e) => {
              handleIncludeECheckChange(e.target.value);
              handleInputChange(e);
            }}/>
        </div>
      </div>
      <div className="col-xs-4">
        <div className="form-group">
          <APInputText 
            label="Internal Description"
            name="planDescription"
            value={formState.planDescription}
            onChange={handleInputChange}
            parentClass="admin"
          />
        </div>
        <div className="form-group">
          <APSelect label="Trust Option"
            name="planTrust"
            options={trustOptions}
            onChange={handleInputChange}
            parentClass="admin planTrust"
          />
        </div>
      </div>
    </div>

  );
};

PlanSection.propTypes = {
  sites: array,
  formState: object,
  setFormState: func,
  handlePlanTypeChange: func,
  handleIncludeECheckChange: func,
  onInputValidation: func
};

export default PlanSection;
