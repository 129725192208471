import React from 'react';
import {bool, func} from 'prop-types';
import {APButton, APTooltip} from 'affinipay-ui-library';
import IconEQB from '../../icons/icon-eqb';

const TooltipQuickBillSendContent = () => (
  <div>
    <p>This Quick Bill cannot be sent with the current invoice values.</p>
    <p>Please click the Edit & Send button to make changes.</p>
  </div>
);

const SendQuickBillButton = ({
  handleSendQuickBill,
  isDisabled = false,
  isSendingAllowed
}) => {
  const buttonLabel = isSendingAllowed ? 'Resend' : 'Email';
  return (
    <APTooltip
      placement="bottom"
      disabled={isSendingAllowed}
      tooltipContent={<TooltipQuickBillSendContent />}
    >
      <div className="ap-button-wrapper">
        <APButton
          className="ap-primary-button"
          disabled={isDisabled || !isSendingAllowed}
          onClick={handleSendQuickBill}
          type="button"
        >
          <IconEQB className="ap-icon" fill="#FFF" size="24" />
          <span className="ap-btn-label">{buttonLabel}</span>
        </APButton>
      </div>
    </APTooltip>
  );
};
SendQuickBillButton.propTypes = {
  handleSendQuickBill: func.isRequired,
  isDisabled: bool,
  isSendingAllowed: bool
};

export default SendQuickBillButton;
