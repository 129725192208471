import { string } from 'prop-types';
import React from 'react';
import './style.scss';

const PrintIcon = ({className}) => { 
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g className={className} fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path d="M21.75 9H21V3.841c0-.298-.119-.585-.33-.795L17.955.329C17.744.12 17.457 0 17.16 0H4.125C3.504 0 3 .504 3 1.125V9h-.75C1.007 9 0 10.007 0 11.25v6.188c0 .31.252.562.563.562H3v4.875C3 23.496 3.504 24 4.125 24h15.75c.621 0 1.125-.504 1.125-1.125V18h2.438c.31 0 .562-.252.562-.563V11.25C24 10.007 22.993 9 21.75 9zM18 21H6v-4.5h12V21zM6 10.5V3h9v1.875C15 5.494 15.506 6 16.125 6H18v4.5H6zm14.25 3.375c-.621 0-1.125-.504-1.125-1.125s.504-1.125 1.125-1.125 1.125.504 1.125 1.125-.504 1.125-1.125 1.125z" transform="translate(-780 -598) translate(487 80) translate(227 510) translate(66 8)"/>                          </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PrintIcon.propTypes = {
  className: string
};

export default PrintIcon;