import React from 'react';
import PropTypes from 'prop-types';

export default class DateRangeSegmentControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDateRange: this.props.activeDateRange
    };
    this.handleClick = this.handleClick.bind(this);
    this.rangeButtonClass = this.rangeButtonClass.bind(this);
  }

  handleClick(newRange) {
    if (this.state.activeDateRange === newRange) { return; }

    this.setState({
      activeDateRange: newRange
    });

    this.props.onClick(newRange);
  }

  rangeButtonClass(range) {
    return this.state.activeDateRange === range ? 'active' : '';
  }

  render() {
    return (
      <div className="text-center clearfix">
        <div className="dashboard-ranges">
          <span className={`dash-range ${this.rangeButtonClass('thirty')}`}
            onClick={() => this.handleClick('thirty')}>30 Days</span>

          <span className={`dash-range ${this.rangeButtonClass('ninety')}`}
            onClick={() => this.handleClick('ninety')}>90 Days</span>

          <span className={`dash-range ${this.rangeButtonClass('yearly')}`}
            onClick={() => this.handleClick('yearly')}>12 Months</span>
        </div>
      </div>
    );
  }
}

DateRangeSegmentControl.propTypes = {
  activeDateRange: PropTypes.string,
  onClick: PropTypes.func
};