// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeDatepicker shallow render 1`] = `
<Fragment>
  <div
    className="row"
  >
    <div
      className="col-sm-6"
    >
      <LegacyAPDatepicker
        canChangeMonth={true}
        disabledDays={
          [
            {
              "before": 2018-08-09T08:00:00.000Z,
            },
          ]
        }
        fromMonth={2018-08-09T08:00:00.000Z}
        id="firstChargeDatePicker"
        inlineLabel={false}
        isNavHidden={false}
        labelText="First Charge On"
        lastDayButtonEnabled={false}
        lastDayTextEnabled={false}
        month={2018-08-09T08:00:00.000Z}
        onDayCallback={[Function]}
        selectedDays={2018-10-30T00:00:00.000Z}
        todayTextEnabled={true}
      />
    </div>
    <div
      className="col-sm-6"
    />
    <ForwardRef
      id="scheduleStartDate"
      name="recurring_charge[schedule][start]"
      type="hidden"
      value="Tue Oct 30 2018 00:00:00 GMT-0500 (Central Daylight Time)"
    />
    <ForwardRef
      name="recurring_charge[recur_start]"
      type="hidden"
      value="1-Oct-2019"
    />
  </div>
</Fragment>
`;
