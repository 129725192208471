import React from 'react';
import PropTypes from 'prop-types';
import { APTooltip } from 'affinipay-ui-library';
import './style.scss';
import Icon from './icons/icon-info';

const tooltipText = {
  'Posting Date': 'is when all daily transactions were rolled-up together in a single amount before being funded in your account'
};

const ReportsDepositsTooltip = ({label}) => {
  const content = (label, text) => (
    <div key="reports-tooltip-content">
      <strong>{label}</strong> {text}
    </div>
  );

  if (tooltipText[label]) {
    return (
      <div className="reports-tooltip-container">
        <APTooltip
          placement="right"
          tooltipContent={content(label, tooltipText[label])}
          className="reports-tooltip-container"
        >
          <span key="reports-tooltip-icon" className="reports-tooltip">
            <Icon className="ap-icon"/>
          </span>
        </APTooltip>
      </div>
    );
  } else {
    return '';
  }
};

ReportsDepositsTooltip.propTypes = {
  label: PropTypes.string
};

export default ReportsDepositsTooltip;
