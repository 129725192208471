import React from 'react';
import { shallow, mount } from 'enzyme/build';
import RecurringChargeInterval from './RecurringChargeInterval';
import { APInputText } from 'affinipay-ui-library';

describe('RecurringChargeInterval', () => {
  it('shallow render', () => {
    const mockProps = getMockProps('new'),
      component = shallow(<RecurringChargeInterval {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow bi monthly render', () => {
    const mockProps = getMockProps('bi_monthly'),
      component = shallow(<RecurringChargeInterval {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  /*it('test get Label', () => {
    // enzyme isn't updated to work with hooks and can't test instance
    const mockProps = getMockProps('monthly'),
      component = mount(<RecurringChargeInterval {...mockProps} />),
      instance = component.instance();

    let actual = instance.labelData(
      new Date('2019-10-31T14:43:18.328Z'),
      'MONTHLY'
    );
    expect(actual).toEqual({
      label: 'month',
      date: 'last day of the month'
    });
    actual = instance.labelData(
      new Date('2019-10-01T14:43:18.328Z'),
      'BI_MONTHLY'
    );
    expect(actual).toEqual({ label: 'month', date: '1st' });
    actual = instance.labelData(
      new Date('2019-10-22T14:43:18.328Z'),
      'BI_MONTHLY'
    );
    expect(actual).toEqual({ label: 'month', date: '22nd' });
    actual = instance.labelData(
      new Date('2019-10-23T14:43:18.328Z'),
      'BI_MONTHLY'
    );
    expect(actual).toEqual({ label: 'month', date: '23rd' });
    actual = instance.labelData(
      new Date('2019-10-24T14:43:18.328Z'),
      'BI_MONTHLY'
    );
    expect(actual).toEqual({ label: 'month', date: '24th' });
    actual = instance.labelData(new Date('2019-10-24T14:43:18.328Z'), 'YEAR');
    expect(actual).toEqual({ label: 'year', date: '10/24' });
    actual = instance.labelData(
      new Date('2019-10-14T14:43:18.328Z'),
      'MONTHLY'
    );
    expect(actual).toEqual({ label: 'month', date: '14th' });

    actual = instance.labelData('LAST', 'any');
    expect(actual).toEqual({ label: 'month', date: 'last day of the month' });

    component.unmount();
  });*/
  it('test change event', () => {
    const mockProps = getMockProps('weekly'),
      component = mount(<RecurringChargeInterval {...mockProps} />),
      clickFn = jest.fn();
    component.setProps({ handleChange: clickFn });
    component
      .find(APInputText)
      .at(0)
      .prop('onChange')({ target: { value: 'foo' } });
    expect(clickFn).toHaveBeenCalled();
    component.unmount();
  });
  it('testing default value set for hidden interval delay', () => {
    const mockProps = getMockProps('no-int-delay'),
      component = mount(<RecurringChargeInterval {...mockProps} />),
      actual = component.find('[name="recurring_charge[schedule][interval_delay]"]').at(0).props().value;
    expect(actual).toEqual('1');
    component.unmount();
  });
  it('testing year transition value for hidden interval delay', () => {
    const mockProps = getMockProps('yearly'),
      component = mount(<RecurringChargeInterval {...mockProps} />),
      actual = component.find('[name="recurring_charge[schedule][interval_delay]"]').at(0).props().value;
    expect(actual).toEqual('12');
    component.unmount();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'new':
    return {
      oneTime: true,
      biMonthly: false,
      intervalDelay: '1',
      amount: null,
      selectedDay: new Date('2019-10-01T14:43:18.328Z'),
      recurFrequency: 'WEEKLY'
    };
  case 'monthly':
    return {
      oneTime: true,
      biMonthly: false,
      intervalDelay: '1',
      amount: null,
      selectedDay: new Date('2019-10-31T14:43:18.328Z'),
      recurFrequency: 'MONTHLY'
    };
  case 'bi_monthly':
    return {
      oneTime: false,
      biMonthly: true,
      intervalDelay: '1',
      amount: null,
      selectedDay: new Date('2019-10-31T14:43:18.328Z'),
      recurFrequency: 'MONTHLY'
    };
  case 'weekly':
    return {
      oneTime: false,
      biMonthly: false,
      intervalDelay: '1',
      amount: null,
      selectedDay: new Date('2019-10-31T14:43:18.328Z'),
      recurFrequency: 'MONTHLY'
    };
  case 'yearly':
    return {
      oneTime: false,
      biMonthly: false,
      intervalDelay: '1',
      amount: null,
      selectedDay: new Date('2019-10-31T14:43:18.328Z'),
      recurFrequency: 'YEAR'
    };
  case 'no-int-delay':
    return {
      oneTime: false,
      biMonthly: false,
      amount: null,
      selectedDay: new Date('2019-10-31T14:43:18.328Z'),
      recurFrequency: 'MONTHLY'
    };
  default:
    return;
  }
};
