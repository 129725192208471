import {array, arrayOf, bool, number, oneOf, oneOfType, shape, string} from 'prop-types';

const invoiceTimeStampProp = shape({
  user: string,
  timestamp: string,
  clientId: string,
  clientInetAddress: string,
  callerInetAddress: string
});

const itemPropType = shape({
  id: string,
  code: string,
  description: string,
  ratePerQuantity: string,
  quantity: string,
  rateType: string,
  total: number
});

const contactProp = shape({
  id: string,
  testMode: bool,
  ownerId: string,
  preferredEmail: string,
  contactCode: string,
  firstName: string,
  lastName: string,
  sortName: string,
  name: string,
  type: string,
  displayName: string
});

export const invoiceMessageProp = shape({
  id: string,
  created: invoiceTimeStampProp,
  modified: invoiceTimeStampProp,
  emailAddresses: arrayOf(string),
  body: string,
  message: string,
  subject: string,
  status: string,
  type: string
});

export const invoiceMessagesProp = arrayOf(invoiceMessageProp);

export const statusProp = oneOf(['draft', 'paid', 'pending', 'rolled over', 'void', 'write_off']);

const invoiceLiteProp = shape({
  id: string,
  dueDate: string,
  invoiceDate: string,
  status: statusProp,
  invoiceNumber: string,
  currency: string,
  presentmentStatus: string,
  type: string
});

export const attachmentProp = shape({
  created: invoiceTimeStampProp,
  fileName: string,
  id: string,
  modified: invoiceTimeStampProp,
  processingState: string,
  type: string
});

export const singleInvoicePayment = shape({
  id: string,
  created: invoiceTimeStampProp,
  modified: invoiceTimeStampProp,
  amount: number,
  payment: singlePaymentProp,
  surchargeAmount: number,
  unallocatedAmount: number,
  transactionId: string,
  paymentType: string,
  contactId: string,
  contact: contactProp,
  ownerId: string,
  cardType: string,
  last4Digits: string,
  currency: string,
  status: string,
  testMode: false,
  invoices: arrayOf(invoiceLiteProp),
  direction: string,
  type: string
});

export const invoiceProp = shape({
  ...invoiceLiteProp,
  created: invoiceTimeStampProp,
  modified: invoiceTimeStampProp,
  totalAmount: number,
  totalAmountDue: number,
  amountPaid: number,
  testMode: bool,
  bankAccountId: string,
  ownerId: string,
  contactId: string,
  contact: contactProp,
  invoicePayments: arrayOf(singleInvoicePayment),
  items:  arrayOf(itemPropType),
  lineItems: arrayOf(itemPropType),
  invoiceMessages: invoiceMessagesProp,
  reference: string,
  scheduledPaymentRequests: array,
  attachments: arrayOf(attachmentProp),
  transactionId: oneOfType([string, number])
});

export const bankAccountProp = shape({
  name: string,
  bankName: string,
  trust: bool,
  xid: string,
  accountNumber: string,
  achLimit: number
});

export const bankAccountsProp = arrayOf(bankAccountProp);

export const singlePaymentProp = shape({
  id: string,
  created: invoiceTimeStampProp,
  modified: invoiceTimeStampProp,
  amount: number,
  surchargeAmount: number,
  unallocatedAmount: number,
  transactionId: string,
  paymentType: string,
  contactId: string,
  contact: contactProp,
  ownerId: string,
  cardType: string,
  last4Digits: string,
  currency: string,
  status: statusProp,
  testMode: bool,
  direction: string,
  type: string,
  invoices: arrayOf(invoiceLiteProp)
});
