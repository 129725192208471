import React from 'react';
import PropTypes from 'prop-types';
import { APFormMessageBox, APInputMonetary, APInputText, APTooltip } from 'affinipay-ui-library';
import LegacyAPDatepicker from '../../components/LegacyAPDatepicker';
import APRadioButton from '../../components/APRadioButton';
import {getDateFromHyphenedFormat} from '../../lib/utils';

export default class RecurringChargeEndDate extends React.Component {
  static propTypes = {
    scheduleEndDate: PropTypes.string,
    amountReaches: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxOccurrences: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
    isComplete: PropTypes.bool,
    scheduleStartDate: PropTypes.string,
    selectedDay: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  };

  constructor(props) {
    super(props);
    this.state = {
      currentDisplayed: this.setDisplayedInput()
    };
  }
  setDisplayedInput = () => {
    if (this.props.maxOccurrences) {
      return 'numberOfOccurrences';
    } else if (this.props.scheduleEndDate) {
      return 'specificDate';
    } else if (this.props.amountReaches) {
      return 'amountReaches';
    } else {
      return 'hideAll';
    }
  };
  setCheckedRadioButton = (radioButtonsData) => {
    if (this.props.maxOccurrences) {
      radioButtonsData[1].checked = true;
    } else if (this.props.scheduleEndDate) {
      radioButtonsData[2].checked = true;
    } else if (this.props.amountReaches) {
      radioButtonsData[3].checked = true;
    } else {
      radioButtonsData[0].checked = true;
    }
  };
  radioButtonChange = (e) => {
    this.setState({ currentDisplayed: e.currentTarget.value });
  };

  limitNumberInput = (e) => {
    e.currentTarget.value = e.currentTarget.value.match(/^(?!0)\d*\.?\d+/);
    this.props.handleChange({maxOccurrences: e.currentTarget.value});
  };

  render() {
    const radioButtonsData = [
      {
        id: 'recurring_ends_0',
        value: 'hideAll',
        text: 'When canceled'
      },
      {
        id: 'recurring_ends_1',
        value: 'numberOfOccurrences',
        text: 'Number of occurrences'
      },
      {
        id: 'recurring_ends_2',
        value: 'specificDate',
        text: 'On specific date'
      },
      {
        id: 'recurring_ends_3',
        value: 'amountReaches',
        text: 'When amount attempted reaches'
      }
    ];

    this.setCheckedRadioButton(radioButtonsData);

    const disabledDaysArray = [
      this.props.selectedDay,
      {
        before: getDateFromHyphenedFormat(this.props.scheduleStartDate)
      }
    ];

    const additionalInput = () => {
        switch (this.state.currentDisplayed) {
        case 'numberOfOccurrences':
          return (
            <div className="input-group" id="numberOfOccurrences">
              <APInputText
                value={this.props.maxOccurrences || ''}
                className="has-addon"
                autoComplete="off"
                type="text"
                id="maxOccurrences"
                name="recurring_charge[max_occurrences]"
                onChange={this.limitNumberInput}
              />
            </div>
          );
        case 'specificDate':
          return (
            <div id="specificDate">
              <LegacyAPDatepicker
                lastDayButtonEnabled={false}
                placeholderText={'Select a date'}
                month={new Date(Date.now())}
                fromMonth={new Date(Date.now())}
                disabledDays={disabledDaysArray}
                onDayCallback={(day) =>
                  this.props.handleChange({
                    scheduleEndDate: day
                  })
                }
                selectedDays={
                  this.props.scheduleEndDate &&
                    getDateFromHyphenedFormat(this.props.scheduleEndDate)
                }
              />
              <APInputText
                type="hidden"
                name="recurring_charge[schedule][end]"
                id="endOnDate"
                value={this.props.scheduleEndDate}
              />
            </div>
          );
        case 'amountReaches':
          return (
            <div className="input-group" id="amountReaches">
              <APInputMonetary
                className="has-addon"
                disabled={this.props.isComplete}
                id="maxAmountUserInput"
                name="max_amount_user_input"
                onChange={e =>
                  this.props.handleChange({ maxAmount: e.target.value })
                }
                placeholder="Enter Amount"
                value={this.props.amountReaches || ''}
              />
              <APInputText
                type="hidden"
                id="maxAmount"
                name="recurring_charge[schedule][max_amount]"
                value={
                  this.props.amountReaches
                    ? this.props.amountReaches.toString().replace(/,/g, '')
                    : ''
                }
              />
            </div>
          );
        default:
          return;
        }
      },
      radioButtons = radioButtonsData.map((el) => {
        return (
          <div key={el.id} className="radio-buttons">
            <div className="radio">
              <APRadioButton
                name="recurring_ends"
                id={el.id}
                value={el.value}
                checked={el.checked}
                text={el.text}
                onChange={this.radioButtonChange}
              />
            </div>
            <div className="additional-input">
              {el.value === this.state.currentDisplayed && additionalInput()}
            </div>
          </div>
        );
      });
    return (
      <div>
        <fieldset>
          <label className="control-label end-label">
            <span>End on</span>
            <APTooltip placement='right' tooltipContent={<EndOnContent />}></APTooltip>
          </label>
          <div className="row">
            <div className="col-sm-12">{radioButtons}</div>
          </div>
          <APFormMessageBox
            type='warning'
            header="Please Note"
            messages={['Failed Scheduled Payment transactions are included in the Schedule Details and the Schedule will not be extended. In order to collect the funds, you must manually Retry the transaction to collect the failed payment.']}
          />
        </fieldset>
      </div>
    );
  }
}

const EndOnContent = () => (
  <div className='end-on-content'>
    <b>End on: </b> Schedule Details
    <ul>
      <li key="cancelled">
        <span><b>When canceled</b> - Schedule will run indefinitely until manually canceled.</span>
      </li>
      <li key="occurrences">
        <span><b>Number of occurrences</b> - Schedule will end after total number of occurrences specified have been attempted.</span>
      </li>
      <li key="date">
        <span><b>On specific date</b> - Schedule will end on the date specified.</span>
      </li>
      <li key="amount">
        <span><b>When amount attempted reaches</b> - Schedule will end after total amount has been attempted to be collected.</span>
      </li>
    </ul>
  </div>
);
