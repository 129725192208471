// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeFrequency shallow render default 1`] = `
<div>
  <fieldset>
    <label
      className="control-label end-label"
    >
      <span>
        End on
      </span>
      <APTooltip
        placement="right"
        tooltipContent={<EndOnContent />}
      />
    </label>
    <div
      className="row"
    >
      <div
        className="col-sm-12"
      >
        <div
          className="radio-buttons"
          key="recurring_ends_0"
        >
          <div
            className="radio"
          >
            <APRadioButton
              checked={true}
              id="recurring_ends_0"
              name="recurring_ends"
              onChange={[Function]}
              text="When canceled"
              value="hideAll"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
        <div
          className="radio-buttons"
          key="recurring_ends_1"
        >
          <div
            className="radio"
          >
            <APRadioButton
              id="recurring_ends_1"
              name="recurring_ends"
              onChange={[Function]}
              text="Number of occurrences"
              value="numberOfOccurrences"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
        <div
          className="radio-buttons"
          key="recurring_ends_2"
        >
          <div
            className="radio"
          >
            <APRadioButton
              id="recurring_ends_2"
              name="recurring_ends"
              onChange={[Function]}
              text="On specific date"
              value="specificDate"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
        <div
          className="radio-buttons"
          key="recurring_ends_3"
        >
          <div
            className="radio"
          >
            <APRadioButton
              id="recurring_ends_3"
              name="recurring_ends"
              onChange={[Function]}
              text="When amount attempted reaches"
              value="amountReaches"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
      </div>
    </div>
    <APFormMessageBox
      header="Please Note"
      messages={
        [
          "Failed Scheduled Payment transactions are included in the Schedule Details and the Schedule will not be extended. In order to collect the funds, you must manually Retry the transaction to collect the failed payment.",
        ]
      }
      type="warning"
    />
  </fieldset>
</div>
`;

exports[`RecurringChargeFrequency shallow render specificDate 1`] = `
<div>
  <fieldset>
    <label
      className="control-label end-label"
    >
      <span>
        End on
      </span>
      <APTooltip
        placement="right"
        tooltipContent={<EndOnContent />}
      />
    </label>
    <div
      className="row"
    >
      <div
        className="col-sm-12"
      >
        <div
          className="radio-buttons"
          key="recurring_ends_0"
        >
          <div
            className="radio"
          >
            <APRadioButton
              id="recurring_ends_0"
              name="recurring_ends"
              onChange={[Function]}
              text="When canceled"
              value="hideAll"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
        <div
          className="radio-buttons"
          key="recurring_ends_1"
        >
          <div
            className="radio"
          >
            <APRadioButton
              id="recurring_ends_1"
              name="recurring_ends"
              onChange={[Function]}
              text="Number of occurrences"
              value="numberOfOccurrences"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
        <div
          className="radio-buttons"
          key="recurring_ends_2"
        >
          <div
            className="radio"
          >
            <APRadioButton
              checked={true}
              id="recurring_ends_2"
              name="recurring_ends"
              onChange={[Function]}
              text="On specific date"
              value="specificDate"
            />
          </div>
          <div
            className="additional-input"
          >
            <div
              id="specificDate"
            >
              <LegacyAPDatepicker
                canChangeMonth={true}
                disabledDays={
                  [
                    undefined,
                    {
                      "before": 2019-10-23T05:00:00.000Z,
                    },
                  ]
                }
                fromMonth={2018-08-09T08:00:00.000Z}
                inlineLabel={false}
                isNavHidden={false}
                labelText=""
                lastDayButtonEnabled={false}
                lastDayTextEnabled={false}
                month={2018-08-09T08:00:00.000Z}
                onDayCallback={[Function]}
                placeholderText="Select a date"
                selectedDays={2019-10-23T05:00:00.000Z}
                todayTextEnabled={false}
              />
              <ForwardRef
                id="endOnDate"
                name="recurring_charge[schedule][end]"
                type="hidden"
                value="10/23/2019"
              />
            </div>
          </div>
        </div>
        <div
          className="radio-buttons"
          key="recurring_ends_3"
        >
          <div
            className="radio"
          >
            <APRadioButton
              id="recurring_ends_3"
              name="recurring_ends"
              onChange={[Function]}
              text="When amount attempted reaches"
              value="amountReaches"
            />
          </div>
          <div
            className="additional-input"
          />
        </div>
      </div>
    </div>
    <APFormMessageBox
      header="Please Note"
      messages={
        [
          "Failed Scheduled Payment transactions are included in the Schedule Details and the Schedule will not be extended. In order to collect the funds, you must manually Retry the transaction to collect the failed payment.",
        ]
      }
      type="warning"
    />
  </fieldset>
</div>
`;
