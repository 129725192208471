import { fetch } from 'whatwg-fetch';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
    ? document.querySelector('meta[name="csrf-token"]').content
    : ''
};

const toBody = (body = {}) => {
  return JSON.stringify(body);
};

const toOpts = (opts = {}) => {
  return Object.assign(
    {
      headers: defaultHeaders,
      credentials: 'same-origin'
    },
    opts
  );
};

const client = {
  put: function(path, body = {}, opts = {}) {
    return fetch(
      path,
      Object.assign(
        {
          method: 'PUT',
          body: toBody(body)
        },
        toOpts(opts)
      )
    );
  },

  patch: function(path, body = {}, opts = {}) {
    return fetch(
      path,
      Object.assign(
        {
          method: 'PATCH',
          body: toBody(body)
        },
        toOpts(opts)
      )
    );
  },

  post: function(path, body = {}, opts = {}) {
    return fetch(
      path,
      Object.assign(
        {
          method: 'POST',
          body: toBody(body)
        },
        toOpts(opts)
      )
    );
  },

  multiPartPost: function(path, body , opts = {}) {
    const headersWithoutContentType = Object.keys(defaultHeaders)
      .filter(key => key !== 'Content-Type')
      .reduce((obj, key) => {
        obj[key] = defaultHeaders[key];
        return obj;
      }, {});

    return fetch(
      path,
      {
        method: 'POST',
        body: body,
        headers: headersWithoutContentType,
        credentials: 'same-origin',
        ...opts
      }
    );
  },

  get: function(path, opts = {}) {
    return fetch(
      path,
      Object.assign(
        {
          method: 'GET'
        },
        toOpts(opts)
      )
    );
  },

  destroy: function(path, opts = {}) {
    return fetch(
      path,
      Object.assign(
        {
          method: 'DELETE'
        },
        toOpts(opts)
      )
    );
  }
};

export default client;
