export const initApiSettingsFormState = (merchant) => ({
  apiAllowedIpAddressRanges: splitTagString(merchant?.api_allowed_ip_address_ranges),
  liveEventsUrls: splitTagString(merchant?.live_events_urls),
  testEventsUrls: splitTagString(merchant?.test_events_urls)
});

export const buildApiSettingsRequestBody = (formState) => ({
  api_allowed_ip_address_ranges: formState?.apiAllowedIpAddressRanges?.join(','),
  live_events_urls: formState?.liveEventsUrls?.join(','),
  test_events_urls: formState?.testEventsUrls?.join(',')
});

const splitTagString = (s) => s?.split(',')?.filter(item => item) || [];