import React from 'react';
import {bool, func, number, shape, string} from 'prop-types';
import { APButton, APInputMonetary } from 'affinipay-ui-library';

const UpdateAccountTransactionWarningTable = ({ data }) => {
  return (
    <div className="col-xs-12">
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th className="span4">Type</th>
              <th>Value</th>
              <th>Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.event_description}</td>
              <td>{data.value}</td>
              <td>{data.limit_value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};



const UpdateAccountTransactionWarningNameHeader = ({ label, value }) => {
  return (
    <div className="row">
      <div className="col-xs-12">
        {label}: <strong>{value}</strong>
      </div>
    </div>
  );
};

const UpdateAccountModalContent = ({
  data,
  disabled,
  errorMessage,
  newValue,
  onChange,
  onClick
}) =>  {
  let controlGroupClassNames = 'col-xs-12 control-group';
  if (errorMessage) controlGroupClassNames += ' error';

  return (
    <div>
      <UpdateAccountTransactionWarningNameHeader label="Merchant" value={data.merchant_name} />
      <UpdateAccountTransactionWarningNameHeader label="Account" value={data.account_name} />
      <UpdateAccountTransactionWarningTable data={data} />
      <br/>
      <div className="form-inline">
        <div className="row">
          <div className={controlGroupClassNames}>
            <APInputMonetary
              onChange={onChange}
              value={newValue}
              placeholder="New Limit"
              parentClass="admin"
            />
            <APButton
              className="pull-right btn btn-primary"
              onClick={onClick}
              disabled={disabled}
            >
              Update Account
            </APButton>
            <span className="help-inline">{errorMessage}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateAccountTransactionWarningTable.propTypes = {
  data: shape({
    event_description: string.isRequired,
    value: string.isRequired,
    limit_value: string.isRequired
  }).isRequired
};

UpdateAccountTransactionWarningNameHeader.propTypes = {
  label: string.isRequired,
  value: string.isRequired
};

UpdateAccountModalContent.propTypes = {
  errorMessage: string,
  onClick: func,
  onChange: func,
  disabled: bool,
  newValue: string,
  data: shape({
    id: number,
    account_id: number,
    value: string,
    limit_value: string,
    event_description: string,
    value_type: string,
    merchant_name: string,
    account_name: string,
    resolve_url: string,
    delete_url: string
  }).isRequired
};

export default UpdateAccountModalContent;
