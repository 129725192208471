import { fetch } from 'whatwg-fetch';
import client from '../../../lib/ajax';

export const getChargeReceipt = async (path, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept':'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json'
  };
  return await fetch(path, { method: 'GET', headers });
};

export const recordSignature = async (path, data) => await client.put(path, data);

export const getSignature = async path => await client.get(path);

export const processSignatureLink = async path => await client.get(path);