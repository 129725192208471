import React from 'react';
import { bool, string } from 'prop-types';
import { APModal } from 'affinipay-ui-library';

const RequiredMaterialsModal = ({brand, brandTitle, isModalShown}) => {

  const brandLabels = brand === 'affinipay' ?
    {
      business: 'Organization',
      signer: 'Authorized signer'
    } :
    {
      business: 'Business',
      signer: 'Ownership'
    };

  return (
    <APModal isActiveInitial={isModalShown}
      headerText='Before You Begin'
      className='required-materials'
    >
      <p>By filling out this application, you are requesting an account with {brandTitle} to accept payment for the professional services that you provide.</p>
      <p>You will need the following information in order to complete your {brandTitle} application:</p>
      <ul className='required-materials-list'>
        <li>{brandLabels.business} information <span className='secondary-text'>(e.g., company type, tax ID number)</span></li>
        <li>{brandLabels.signer} information<span className='secondary-text'>(e.g., name of owner, SSN)</span></li>
        <li>Bank account information <span className='secondary-text'>(e.g., account number, routing number)</span></li>
        <li>Please note, the business owner will have to sign this application prior to submission</li>
      </ul>
      <p>We estimate that the application will take about 10 minutes to complete.</p>
      <p><span className='secondary-text--bold'>Don&#39;t have this information at hand?</span> Click <span className='secondary-text--underline'>&#39;Save and come back later&#39;</span> button and return to this application once you have everything you need.</p>
    </APModal>
  );
};

RequiredMaterialsModal.propTypes = {
  brand: string,
  brandTitle: string,
  isModalShown: bool
};

export default RequiredMaterialsModal;
