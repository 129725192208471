import { paymentPageErrors as errors} from './constants';
import { rollbarLog } from '../utils';

//public
const setV2CaptchaCallbacks = (setV2Token, setCaptchaVersion, formErrors, setFormErrors) => {
  window.v2CaptchaCallback = (token) => {
    setV2Token(token);
    setCaptchaVersion('v2');
  };

  window.v2CaptchaExpired = () => {
    setCaptchaVersion('v2');
    setV2Token('');
  };

  window.v2CaptchaError = () => {
    setCaptchaVersion('v2');
    setV2Token('');
    setFormErrors([...formErrors, errors.captchaFail]);
  };
};

const enableV2Captcha = (setCaptchaVersion, setFormErrors, v2_captcha_key) => {
  setCaptchaVersion('v2');
  if(!document.getElementsByClassName('g-recaptcha')[0] ) {
    const content = document.getElementsByClassName('expanded-content active')[0];
    const submitBtn = content.querySelector('.submitButton');
    const captchaDiv = document.createElement('div');
    captchaDiv.className = 'g-recaptcha';
    captchaDiv.dataset.sitekey = v2_captcha_key;
    captchaDiv.dataset.callback = 'v2CaptchaCallback';
    captchaDiv.dataset.expiredCallback = 'v2CaptchaExpired';
    captchaDiv.dataset.errorCallback = 'v2CaptchaError';
    captchaDiv.style.marginBottom = '25px';
    submitBtn.parentNode.insertBefore(captchaDiv, submitBtn);
    const scriptEl = document.createElement('script');
    scriptEl.src = 'https://www.google.com/recaptcha/api.js';
    document.getElementsByTagName('head')[0].appendChild(scriptEl);
  } else {
    try {
      // eslint-disable-next-line no-undef
      grecaptcha.reset();
    } catch(err) {
      setFormErrors([errors.captchaFail]);
      rollbarLog('Payment Page', `Captcha Failed to reset: ${errors.captchaFail}`);
    }
  }
};

const removeCaptcha = (callback) => {
  const captchaEl = document.getElementsByClassName('g-recaptcha')[0];
  if(captchaEl) {
    captchaEl.remove();
    callback();
  }
};

const CaptchaHelper = {
  setV2Callbacks: setV2CaptchaCallbacks,
  turnOnV2: enableV2Captcha,
  removeCaptcha: removeCaptcha
};

export default CaptchaHelper;
