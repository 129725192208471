import { APInputText, APInputMonetary } from 'affinipay-ui-library';
import {bool, func, string} from 'prop-types';
import React, { useEffect } from 'react';

const RecurringChargeAmount = ({
  handleChange,
  amount,
  isComplete
}) => {
  useEffect(() => {
    const el = document.getElementById('amount');
    if (el) {
      el.setAttribute('autocomplete', 'transaction-amount');
    }
  }, []);

  return (
    <div className="row">
      <div className="col-sm-6">
        <APInputMonetary
          disabled={isComplete}
          id="amount"
          label="Amount"
          name="amount_user_input"
          onChange={(e) => handleChange({ amount: e.target.value })}
          parentClass="amount-frequency"
          placeholder="Enter amount"
          value={amount || ''}
        />
        <APInputText
          type="hidden"
          id="monetaryAmount"
          name="recurring_charge[amount]"
          value={
            amount ? amount.replace(/,/g, '') : ''
          }
        />
      </div>
    </div>
  );
};

RecurringChargeAmount.propTypes = {
  handleChange: func,
  amount: string,
  isComplete: bool
};

export default RecurringChargeAmount;
