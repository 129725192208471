// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Masked Component shallow snapshot render 1`] = `
<t
  className="form-control"
  mask={
    [
      "[abc]",
    ]
  }
  name="bar"
  placeholder="foobar"
  render={[Function]}
  value="fbc"
/>
`;
