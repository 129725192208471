import React from 'react';
import { months, adHocFeeNames } from 'lib/constants';

export const generateNewFee = (withEffectiveDate) => ({
  id: null,
  name: 'pci_non_compliance',
  rate: '',
  frequency: 'MONTHLY',
  errors: null,
  ...(withEffectiveDate && { effective_month: '' }),
  ...(withEffectiveDate && { effective_year: '' })
});

export const buildYearOptions = (year = new Date().getFullYear()) => {
  year = parseInt(year, 10);
  let range = [...Array(3)];
  let years = range.map((_, i) => {
    return (new Date().getFullYear() + i);
  });

  if (year && !years.includes(year)) years = [year].concat(years);

  return years.map((year, i) => {
    return (
      <option key={i} value={year}>
        {year}
      </option>
    );
  });
};

export const buildMonthOptions = () => {
  return months.map((m, idx) => {
    return (
      <option key={idx} value={idx+1}>
        {m.text}
      </option>
    );
  });
};

export const buildAdHocFeeNameOptions = () => {
  return adHocFeeNames.map(m => {
    return (
      <option key={m.value} value={m.value}>
        {m.text}
      </option>
    );
  });
};

export const adHocFeeNamesLength = adHocFeeNames.length;
