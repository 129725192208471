import React, {useState} from 'react';
import { paymentTypes } from '../../utils';
import {func, string} from 'prop-types';
import {APButton} from 'affinipay-ui-library';
import {sendEvent, EventNames} from '../../../../lib/intercom';
import client from '../../../../lib/ajax';

const paymentOptions = [
  {
    id: 'Cash',
    value: 'cash',
    label: 'Cash'
  },
  {
    id: 'Check',
    value: 'check',
    label: 'Check'
  },
  {
    id: 'CreditCard',
    value: 'credit_card',
    label: 'Credit Card'
  },
  {
    id: 'Other',
    value: 'other',
    label: 'Other'
  }
];

const MarkQuickBillAsPaidForm = ({
  handleClose,
  invoiceID,
  setToastValues,
  setIsDisabled
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentType, setPaymentType] = useState();

  const toggleSelection = ({target: {value}}) => {
    setPaymentType(value);
  };


  const handleMarkAsPaid = async e => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    const paymentParams = {payment_type: paymentType};

    const {status} = await client.post(`/quick_bills/${invoiceID}/mark_as_paid`, { invoice_payment: paymentParams });

    if (status === 200) {
      setIsDisabled(true);
      sendEvent(EventNames.markQuickBillAsPaid);
      setToastValues({
        show: true,
        header: 'Success',
        message: 'Successfully marked Quick Bill as paid',
        type: 'success'
      });
      setTimeout(() => document.location.reload(), 2000);
    } else {
      setToastValues({
        show: true,
        header: 'Error',
        message: 'Quick Bill could not be marked as paid',
        type: 'error'
      });
      setIsDisabled(false);
      setIsSubmitting(false);
    }
    handleClose();
  };

  const displayOptions = () => paymentOptions.map(({id, value, label}) => (
    <div className="radio ap-modal-row" key={id}>
      <label htmlFor={id}>
        <input
          type="radio"
          name="paymentType"
          id={id}
          value={value}
          disabled={isSubmitting}
          onChange={toggleSelection}
          checked={paymentType === paymentTypes[id]}
        /> {label}
        <span className="ap-radio">&nbsp;</span>
      </label>
    </div>
  ));

  return (
    <form onSubmit={handleMarkAsPaid}>
      {displayOptions()}
      <div className="ap-modal-footer">
        <APButton
          disabled={isSubmitting}
          onClick={handleClose}
          className='ap-secondary-button'
          type="button"
        >
          Close
        </APButton>
        <APButton
          className='ap-primary-button left-spacer-20'
          disabled={isSubmitting || !paymentType}
        >
          Save Changes
        </APButton>
      </div>
    </form>
  );
};

MarkQuickBillAsPaidForm.propTypes = {
  invoiceID: string,
  setIsDisabled: func,
  handleClose: func,
  setToastValues: func
};

export default MarkQuickBillAsPaidForm;
