import { rollbarLog } from '../utils';

export const gatewayErrorMessages = {
  declinedByBank: {
    codes: ['card_declined', 'card_declined_insufficient_funds', 'card_declined_hold'],
    message: 'This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.'
  },
  invalidCardNumber: {
    codes: ['card_number_invalid', 'card_number_incorrect'],
    message: 'The card number you entered is invalid. Please review this information and retry your payment.'
  },
  expiredCard: {
    codes: ['card_expired'],
    message:'The card you are trying to use is expired. Please review the expiration date and retry your payment.'
  },
  incorrectCVV: {
    codes: ['card_cvv_incorrect'],
    message: 'The CVV number you entered is invalid. Please review this information and retry your payment.'
  },
  rejectedAVS: {
    codes: ['card_avs_rejected'],
    message: 'The billing address or zip/postal code you entered is invalid. Please review this information and retry your payment.'
  },
  unsupportedCurrency: {
    codes: ['unsupported_currency'],
    message: merchantName => `This transaction can not be processed because of a currency mismatch. Please contact ${merchantName}.`
  },
  cardDeclined: {
    codes: ['card_declined_processing_error'],
    message: 'Your card was declined due to a processing error. Retry your payment or use a different card.'
  },
  cardDeclinedLimit: {
    codes: ['card_declined_limit_exceeded'],
    message: 'This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.'
  },
  cardDeclinedIssuer: {
    codes: ['card_declined_refer_to_issuer'],
    message: 'This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.'
  },
  cardDeclinedNoAccount: {
    codes: ['card_declined_no_account'],
    message: 'This card transaction has been declined by your bank (account could not be found). Please call the phone number on the back of the card to resolve the issue and then retry your payment.'
  },
  cardTypeNotAccepted: {
    codes: ['card_type_not_accepted'],
    message: 'This card brand is not supported. Retry your payment with a different card.'
  },
  merchantLimitExceeded: {
    codes: ['merchant_trans_max_amount_exceeded'],
    message: 'We could not process your transaction at the moment. Please try with a lower amount.'
  },
  merchantMinimumNotMet: {
    codes: ['merchant_trans_min_amount_not_met'],
    message: 'We could not process your transaction at the moment. Please try again in about an hour.'
  },
  merchantDailyCountExceeded: {
    codes: ['merchant_trans_daily_count_exceeded'],
    message: 'We could not process your transaction at the moment. Please try again in about an hour.'
  },
  merchantDailyTransactionAmountExceeded: {
    codes: ['merchant_trans_daily_amount_exceeded'],
    message: 'We could not process your transaction at the moment. Please try again in about an hour.'
  },
  merchantMonthlyTransactionCount: {
    codes: ['merchant_trans_monthly_count_exceeded'],
    message: 'We could not process your transaction at the moment. Please try again in about an hour.'
  },
  merchantMonthlyTransactionAmount: {
    codes: ['merchant_trans_monthly_amount_exceeded'],
    message: merchantName => `The transaction cannot be completed at this time. Please contact ${merchantName} directly for more information.`
  },
  echeckDeclinedByProcessor: {
    codes: ['ach_declined'],
    message: 'Your transaction was declined due to a processing error. Retry your payment with a different eCheck account or use a payment card.'
  },
  echeckDeclinedByProcessorHold: {
    codes: ['ach_declined_hold'],
    message: 'We could not process your eCheck transaction at the moment. Contact your bank to resolve the issue.'
  },
  echeckInvalidAccountNumber: {
    codes: ['ach_invalid_account_number'],
    message: 'The bank account number you entered is invalid. Correct the information and retry your payment.'
  },
  echeckInvalidRoutingNumber: {
    codes: ['ach_invalid_routing_number'],
    message: 'The routing number you entered is invalid. Correct the information and retry your payment.'
  },
  echeckNotFound: {
    codes: ['ach_account_not_found'],
    message: 'The bank account number you entered cannot be found. Correct the information and retry your payment.'
  },
  echeckAccountClosed: {
    codes: ['ach_account_closed'],
    message: 'This transaction could not be completed because the account is closed. Contact your bank to resolve the issue and then retry your payment.'
  },
  echeckAccountFrozen: {
    codes: ['ach_account_frozen'],
    message: 'This transaction could not be completed because the account is frozen. Contact your bank to resolve the issue and then retry your payment.'
  },
  echeckLimitExceeded: {
    codes: ['ach_limit_exceeded'],
    message: 'This eCheck transaction has been declined by your bank. Please call your bank directly to resolve the issue and then retry your payment.'
  },
  generic: {
    message: 'Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.'
  },
  getMessage(msg, merchantName) {
    if(!msg) {
      rollbarLog('Payment Page', 'No error object returned from gateway');
      return this.generic.message;
    }

    if(!msg.code) {
      rollbarLog('Payment Page', 'No error code returned from gateway', 'warning');
      return msg.message || this.generic.message;
    }

    const error = Object.values(this).find(e => e.codes && e.codes.includes(msg.code));
    if (this.unsupportedCurrency?.codes.includes(msg.code) ||
        this.merchantMonthlyTransactionAmount.codes.includes(msg.code)) {
      return error.message(merchantName);
    }

    if(!error && !msg.message) {
      rollbarLog('Payment Page', `Error code ${msg.code} not found - no gateway message to fallback to`, 'warning');
    }

    return error ? error.message : (msg.message || this.generic.message);
  }
};