import React, {useState} from 'react';
import {bool, func, string} from 'prop-types';
import {APAlertModal, APButton, APToast} from 'affinipay-ui-library';
import IconDelete from '../../icons/icon-delete';
import client from '../../../../lib/ajax';
import { navigateToQuickBillsIndex } from '../../utils';

const AlertModalFooter = ({isSubmitting, closeAlert, handleConfirm}) => (
  <div className="delete-quick-bill-confirmation-button">
    <APButton
      className="ap-ghost-alert-button"
      type="button"
      onClick={closeAlert}
    >
      Cancel
    </APButton>
    <APButton
      className="ap-critical-button"
      disabled={isSubmitting}
      onClick={handleConfirm}
    >
      Yes, delete
    </APButton>
  </div>
);

const DeleteQuickBillButton = ({invoiceID, isDisabled}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isToastShowing, setIsToastShowing] = useState(false);

  const toggleModal = () => {
    setIsWarningOpen(!isWarningOpen);
  };

  const handleDelete = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const response = await client.destroy(`/quick_bills/${invoiceID}`);
    const {status} = response;
    setIsSubmitting(false);
    setIsWarningOpen(false);

    if (status === 200) {
      navigateToQuickBillsIndex();
    } else {
      setIsToastShowing(true);
    }
  };

  return (
    <>
      <APButton
        className="ap-secondary-button"
        onClick={toggleModal}
        type="button"
        disabled={isDisabled || isSubmitting}
      >
        <IconDelete className="ap-icon"/>
        <span className="ap-btn-label">Delete</span>
      </APButton>
      <APAlertModal
        type="error"
        isActiveInitial={isWarningOpen}
        headerText="Confirm Delete"
        bodyText="After you delete this Quick Bill, it cannot be recovered. Do you still want to delete it?"
        footer={
          <AlertModalFooter
            isSubmitting={isSubmitting}
            closeAlert={toggleModal}
            handleConfirm={handleDelete}
          />
        }
      />
      <APToast
        isOpen={isToastShowing}
        type="error"
        showCloseButton={true}
        onClose={() => setIsToastShowing(false)}
        fadeOutTime={5000}
      >
        <span className='message-header'>
          Quick Bill could not be deleted.
        </span>
      </APToast>
    </>
  );
};

DeleteQuickBillButton.propTypes = {
  invoiceID: string.isRequired,
  isDisabled: bool
};

AlertModalFooter.propTypes = {
  isSubmitting: bool,
  closeAlert: func,
  handleConfirm: func
};

export default DeleteQuickBillButton;
