// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`State Component shallow snapshot render 1`] = `
<div>
  <label>
    foo
  </label>
  <withProps(withProps(StateManager))
    id="foobar"
    name="bar"
    onChange={[Function]}
  />
</div>
`;
