//not exposed in production
import React, {useState} from 'react';
import { APButton, APSelect, APFormMessageBox } from 'affinipay-ui-library';
import PropTypes from 'prop-types';

const ChangePlanForm = ({currentPlan, availablePlans, submitForm}) => {
  const initialFormState = {
    selectedPlan: undefined
  };

  const [formState, setFormState] = useState(initialFormState);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const handleUserInput = (e) => {
    const name = e.target.name,
      value = e.target.value,
      newState = {};
    newState[name] = value;
    setFormState({...formState, ...newState});
  };

  return (
    <>
      <div className="col-xs-12">
        <h3>Change Plan</h3>
      </div>
      <form>
        <div className="col-xs-12 form-group">
          <h4>Current Plan</h4>
          <div>{currentPlan}</div>
        </div>
        <div className="col-xs-12 form-group">
          <APSelect 
            label='Plan'
            options={availablePlans}
            name='selectedPlan'
            onChange={handleUserInput}
            required={true}
            value={formState['selectedPlan']}
          />
        </div>
        <div className="col-xs-12">
          <APFormMessageBox type="warning">
            <h3>Warning</h3>
            <p>Changing plans will reset this application pricing to the default values for the new plan selected.</p>
            <p>Remember that if you manually changed pricing for the customer, you&apos;ll need to re-apply the pricing
              changes.</p>
          </APFormMessageBox>
        </div>
        <div className="col-xs-12">
          <APButton onClick={handleFormSubmit}>
            Save Plan
          </APButton>
        </div>
      </form>
    </>
  );
};

export default ChangePlanForm;

ChangePlanForm.propTypes = {
  currentPlan: PropTypes.string,
  availablePlans: PropTypes.array,
  submitForm: PropTypes.func
};
