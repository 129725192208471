import React from 'react';
import PropTypes from 'prop-types';
import PlaceHolderAffiniPay from './svg/placeholder_affinipay';
import PlaceHolderMedPay from './svg/placeholder_medpay';
import PlaceHolderClientPay from './svg/placeholder_clientpay';
import PlaceHolderCPACharge from './svg/placeholder_cpacharge';
import PlaceHolderLawPay from './svg/placeholder_lawpay.js';


export default class EmptyCardPlaceholder extends React.Component {
  render() {
    const isMobile = window.innerWidth <= 375;
    switch(this.props.brand) {
    case 'lawpay':
      return <PlaceHolderLawPay isMobile={isMobile} />;
    case 'cpacharge':
      return <PlaceHolderCPACharge isMobile={isMobile} />;
    case 'medpay':
      return <PlaceHolderMedPay isMobile={isMobile} />;
    case 'clientpay':
      return <PlaceHolderClientPay isMobile={isMobile} />;
    default:
      return <PlaceHolderAffiniPay isMobile={isMobile} />;
    }
  }
}

EmptyCardPlaceholder.propTypes = {
  brand: PropTypes.string
};
