/* eslint-disable no-undef */
import React from 'react';
import {array, string} from 'prop-types';
import NoTransactionsRow from './no_transaction_row';
import TopClientsRow from './top_clients_row';

const TopClientsTable = ({ clients, range }) => {
  return (
    <div className="row clearfix bottom-spacer-20">
      <div className="col-sm-6">
        <h3 className="dashboard-title">Top Clients
          <span id="dash_range_selected" className="stat-label">
            { AffiniPay.DashboardUtils.setRangeLabel(range) }
          </span>
        </h3>
      </div>
      <div className="col-sm-6">
        <form id="frm_dash_search" action="/transactions">
          <div className="input-group ap-input-group">
            <input className="form-control" type="text" name="search" id="client_search" placeholder="Search"/>
            <span className="input-group-btn ap-input-group__btn">
              <button className="ap-btn" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" id="icn-search" viewBox="0 0 36 36" className="ap-glyph">
                  <path className="icon-path-1"
                    d="M36,33.4,24.1,21.5a13.5,13.5,0,1,0-2.6,2.6L33.4,36ZM13.4,23.2a9.8,9.8,0,1,1,9.8-9.8,9.8,9.8,0,0,1-9.8,9.8Z"></path>
                </svg>
              </button>
            </span>
          </div>
        </form>
      </div>

      <div className="col-xs-12 table-responsive">
        <table className="table table-striped table-condensed top-clients">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-right">Amount</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody className="container-top-clients">
            {
              clients.length > 0 ? (
                clients.map((c, idx) => <TopClientsRow client={c} key={idx}/>)
              ) : (
                <NoTransactionsRow key={0} />
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopClientsTable;

TopClientsTable.propTypes = {
  clients: array,
  range: string
};
