import React from 'react';

import ThemeProvider from 'components/themes/ThemeProvider';

const wrapperPadding = {
  'paddingTop': '1em',
  'paddingBottom': '2em'
};

class Disclaimer extends React.Component {
  render() {
    return (
      <ThemeProvider>
        <div className="row" style={wrapperPadding}>
          <div className="col-sm-12">
            <div className="title-bar">Bank Disclosure</div>
            <h5>
              <strong>Merchant Services Provider Contact Information</strong>
            </h5>

            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-xs-6 col-sm-2">
                    Name
                    <br />
                    Address
                  </div>
                  <div className="col-xs-6 col-sm-3">
                    Affinipay, LLC <br />
                    3700 N Capital of Texas Hwy Suite 300
                    <br />
                    Austin, TX 78746
                  </div>
                  <div className="col-xs-6 col-sm-3">
                    Website URL <br />
                    Phone Number
                  </div>
                  <div className="col-xs-6 col-sm-4">
                    <a
                      href="https://www.affinipay.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.affinipay.com
                    </a>
                    <br />
                    <a href="tel:855-656-4684">(855) 656-4684</a>
                  </div>
                </div>
              </div>
            </div>

            <h5>
              <strong>Member Bank Information: Wells Fargo Bank</strong>
            </h5>
            <p>
              The Bank’s mailing address is Wells Fargo Bank, N.A., PO Box 6079,
              Concord, CA 94524 and its phone number is{' '}
              <a
                href="tel:800-451-5817"
                target="_blank"
                rel="noopener noreferrer"
              >
                (800) 451-5817
              </a>
              .
            </p>

            <h5>
              <strong>Important Member Bank Responsibilities</strong>
            </h5>
            <ul>
              <li>
                The Bank is the only entity approved to extend acceptance of
                Card Organization products directly to a Merchant.
              </li>
              <li>
                The Bank must be a principal (signer) to the Merchant Agreement.
              </li>
              <li>
                The Bank is responsible for educating Merchants on pertinent
                Visa and MasterCard Rules with which Merchants must comply; but
                this information may be provided to you by Processor.
              </li>
              <li>
                The Bank is responsible for and must provide settlement funds to
                the Merchant.
              </li>
              <li>The Bank is responsible for all funds held in reserve.</li>
            </ul>

            <h5>
              <strong>Important Merchant Responsibilities</strong>
            </h5>
            <ul>
              <li>
                Ensure compliance with cardholder data security and storage
                requirements.
              </li>
              <li>
                Maintain fraud and chargebacks below Card Organization
                thresholds.
              </li>
              <li>
                Review and understand the terms of the Merchant Agreement.
              </li>
              <li>Comply with Card Organization rules.</li>
              <li>Retain a signed copy of the Disclosure Page.</li>
            </ul>

            <h5>
              <strong>Merchant Resources</strong>
            </h5>
            <ul>
              <li>
                You may download &ldquo;Visa Regulations&rdquo; from
                Visa&rsquo;s website at:{' '}
                <a
                  href="https://usa.visa.com/support/small-business/regulations-fees.html#3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://usa.visa.com/support/small-business/regulations-fees.html#3
                </a>
              </li>
              <li>
                You may download &ldquo;MasterCard Rules&rdquo; from
                MasterCard&rsquo;s website at:{' '}
                <a
                  href="http://www.mastercard.com/us/merchant/support/rules.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.mastercard.com/us/merchant/support/rules.html
                </a>
              </li>
            </ul>

            <p>
              The responsibilities above do not replace the terms of the
              Merchant Agreement and are provided to ensure the Merchant
              understands some important obligations of each party and that the
              Bank is the ultimate authority should the Merchant experience any
              problems.
            </p>

            <strong>
              By submitting this form you consent to this electronic disclosure
              of the BANK DISCLOSURE FORM.
            </strong>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default Disclaimer;
