import React from 'react';
import {shallow, mount} from 'enzyme';
import {act} from 'react-dom/test-utils';
import AdditionalOwners from './index';
import * as service from './services';

describe('AdditionalOwners Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(<AdditionalOwners/>);
    expect(component).toMatchSnapshot();
  });
  
  it('mount to interact with modal', () => {
    const component = mount(<AdditionalOwners/>),
      button = component.find('.ap-secondary-button').at(0);
    button.simulate('click');
    const closeButton = component.find('.ap-secondary-button.pull-right').at(0);
    expect(closeButton).toBeTruthy();
    closeButton.simulate('click');
    const modal = component.find('.APModal');
    expect(modal).toEqual({});
    component.unmount();
  });

  it('mount to test with owners', () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    expect(component.containsMatchingElement(<div className="col-xs-8">John Doe, Owner</div>)).toBeTruthy();
    expect(component.containsMatchingElement(<div className="col-xs-8">John2312 Doe, Manager</div>)).toBeTruthy();
    component.unmount();
  });

  it('mount to test success submit', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    jest.spyOn(service, 'createOwner').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(newOwner);
        })
      })
    );
    component.find('.ap-secondary-button').at(0).simulate('click');
    await act(async () => {
      component.find('AddOwnerForm').at(0).prop('onSubmit')({});
    });
    component.update();
    expect(component.containsMatchingElement(<div className="col-xs-8">New Owner Doe, Owner</div>)).toBeTruthy();
    component.unmount();
  });

  it('mount to test fail submit', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    jest.spyOn(service, 'createOwner').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve([{error: 1}]);
        })
      })
    );
    component.find('.ap-secondary-button').at(0).simulate('click');
    await act(async () => {
      component.find('AddOwnerForm').at(0).prop('onSubmit')({});
    });
    component.update();
    expect(component.find('AddOwnerForm').prop('errors')).toBeTruthy();
    component.unmount();
  });

  it('mount to test success remove', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    jest.spyOn(service, 'deleteOwner').mockImplementation(() =>
      Promise.resolve({
        status: 204,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({id: 31});
        })
      })
    );
    await act(async () => {
      component.find('OwnerListItem').at(0).prop('handleRemove')({id: 31});
    });
    component.update();
    expect(component.containsMatchingElement(<div className="col-xs-8">John Doe, Owner</div>)).toBeFalsy();
    component.unmount();
  });

  it('mount to test fail remove', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    jest.spyOn(service, 'deleteOwner').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve([{error: 1}]);
        })
      })
    );
    await act(async () => {
      component.find('OwnerListItem').at(0).prop('handleRemove')({});
    });
    component.update();
    expect(component.containsMatchingElement(<div className="col-xs-8">John Doe, Owner</div>)).toBeTruthy();
    component.unmount();
  });

  it('mount to test success edit', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    jest.spyOn(service, 'patchOwner').mockImplementation(() =>
      Promise.resolve({
        status: 204,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({
            'id': 31,
            'first_name': 'Edited John',
            'last_name': 'Doe',
            'has_significant_responsibility': null,
            'has_significant_ownership': true,
            'job_title': 'Owner'});
        })
      })
    );
    component.find('.edit-btn').at(1).simulate('click');
    await act(async () => {
      component.find('AddOwnerForm').at(0).prop('onEditSubmit')({
        'id': 31,
        'first_name': 'Edited John',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Owner'});
    });
    component.update();
    expect(component.containsMatchingElement(<div className="col-xs-8">Edited John Doe, Owner</div>)).toBeTruthy();
    component.unmount();
  });

  it('mount to test fail edit', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')}/>);
    jest.spyOn(service, 'patchOwner').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve([{error: 1}]);
        })
      })
    );
    component.find('.edit-btn').at(1).simulate('click');
    await act(async () => {
      component.find('AddOwnerForm').at(0).prop('onEditSubmit')({
        id: 31,
        'first_name': 'Edited John',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Owner'});
    });
    component.update();
    expect(component.containsMatchingElement(<div className="col-xs-8">Edited John Doe, Owner</div>)).toBeFalsy();
    component.unmount();
  });

  it('sets "Additional Authorized Signers" to the header for AffiniPay Applications', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')} isAffiniPaySite={true} />);
    const header = component.find('h3');
    expect(header.text()).toEqual('Additional Authorized Signers');
  });

  it('sets "Additional Business Owners" to the header for AffiniPay Applications', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')} />);
    const header = component.find('h3');
    expect(header.text()).toEqual('Additional Business Owners');
  });

  it('sets the correct copy for adding additional AffiniPay Application Signers', async () => {
    const component = mount(<AdditionalOwners {...getMockProps('withOwners')} isAffiniPaySite={true} />);
    const paragraph = component.find('p').at(0);
    expect(paragraph.text()).toEqual('Authorized Signers are individuals with significant management responsibilities, like an owner, CEO or CFO.  List at least one authorized signer with management responsibilities.');
  });
});

const newOwner = {
  'id': 34,
  'first_name': 'New Owner',
  'last_name': 'Doe',
  'has_significant_responsibility': null,
  'has_significant_ownership': true,
  'job_title': 'Owner',
  'social_security_number_last_4': '8989',
  'date_of_birth': '08/08/1994',
  'home_address1': '2020 Fake Elm St',
  'home_address2': '',
  'home_city': 'Wonderland',
  'home_state': 'AA',
  'home_zip_code': '55998',
  'merchant_application_id': 4,
  'created_at': '2020-04-20T20:03:12.000Z',
  'updated_at': '2020-04-21T21:12:52.000Z',
  'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/31'
};

const getMockProps = (type) => {
  switch (type) {
  case 'withOwners':
    return {
      'paths': {
        'create': '/register/business_owners',
        'update': '/register/business_owners/{id}',
        'destroy': '/register/business_owners/{id}'
      },
      'initialOwners': [{
        'id': 31,
        'first_name': 'John',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Owner',
        'social_security_number_last_4': '8989',
        'date_of_birth': '08/08/1994',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AA',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-20T20:03:12.000Z',
        'updated_at': '2020-04-21T21:12:52.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/31'
      }, {
        'id': 32,
        'first_name': 'John2312',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Manager',
        'social_security_number_last_4': '3123',
        'date_of_birth': '11/11/1990',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AZ',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-21T21:09:54.000Z',
        'updated_at': '2020-04-21T21:09:54.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/32'
      }],
      'isAdmin': false
    };
  default:
    return;
  }
};
