import React from 'react';
import {arrayOf, string} from 'prop-types';

export const ClientCreditTextList = ({ className, textArray, textId }) => (
  <ul className={className} data-testid="client-credit-list">
    {textArray.map((text, index) => (
      <li key={`${textId}-${index}`}>{text}</li>
    ))}
  </ul>
);

ClientCreditTextList.propTypes = {
  className: string,
  textArray: arrayOf(string),
  textId: string
};
