import React from 'react';
import { shallow } from 'enzyme/build';
import RecurringChargeFrequency from './RecurringChargeFrequency';

describe('RecurringChargeFrequency', () => {
  it('shallow render', () => {
    const mockProps = getMockProps('new'),
      component = shallow(<RecurringChargeFrequency {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow render weekly', () => {
    const mockProps = getMockProps('weekly'),
      component = shallow(<RecurringChargeFrequency {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow render monthly', () => {
    const mockProps = getMockProps('monthly'),
      component = shallow(<RecurringChargeFrequency {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow render bi monthly', () => {
    const mockProps = getMockProps('bi_monthly'),
      component = shallow(<RecurringChargeFrequency {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'new':
    return {
      recurChargeFrequency: 'DAY',
      items: [
        {
          text: 'One Time',
          value: 'DAY'
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        {
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        {
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ]
    };
  case 'weekly':
    return {
      recurChargeFrequency: { intervalDelay: '1', intervalUnit: 'WEEK' },
      items: [
        {
          text: 'One Time',
          value: 'DAY'
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        {
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        {
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ]
    };
  case 'monthly':
    return {
      recurChargeFrequency: { intervalDelay: '1', intervalUnit: 'MONTH' },
      items: [
        {
          text: 'One Time',
          value: 'DAY'
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        {
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        {
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ]
    };
  case 'bi_monthly':
    return {
      recurChargeFrequency: { days: ['1', '2'] },
      items: [
        {
          text: 'One Time',
          value: 'DAY'
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        {
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        {
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ]
    };
  default:
    return;
  }
};
