import React, {useEffect, useState} from 'react';
import {bool, func, string} from 'prop-types';
import { APButton, APFormMessageBox, APInputMonetary, APInputText, APTextArea } from 'affinipay-ui-library';
import SaveAndResendButton from './SaveAndResendButton';
import {hasEcheckEnabled, isAmountOverLimit} from '../../utils';
import {invoiceProp, bankAccountProp} from '../../../../customProps';
import QuickBillAttachments from '../../QuickBillModal/QuickBillAttachments';
import QuickBillStatusBarUnpaid from '../../QuickBillStatusBarUnpaid';
import client from '../../../../lib/ajax';
import { getDollarsFromCents } from '../../../../lib/monetaryUtils';
import {isEmailPattern} from '../../../../lib/utils';
import {characterLimits, defaultLimit} from '../../constants';

export const checkValuesMissing = formValues => {
  const requiredFields = ['totalAmount', 'emailAddress', 'message', 'subject'];
  return requiredFields.some(key => !formValues[key]);
};

const QuickBillEditForm = ({
  attachmentDisabled,
  bankAccount,
  defaultSubject = '',
  defaultBody = '',
  invoice,
  setIsDisabled,
  setShowModal,
  siteSupportPhone
}) => {
  const getLatestInvoiceMessage = messages => {
    const lastInvoiceMessage = messages[messages.length - 1];

    if (lastInvoiceMessage) return lastInvoiceMessage;

    return {
      emailAddresses: [''],
      subject: defaultSubject,
      message: defaultBody
    };
  };

  const lastInvoiceMessage = getLatestInvoiceMessage(invoice?.invoiceMessages || []);
  const initialFormValues = {
    totalAmount: getDollarsFromCents(invoice.totalAmountDue),
    attachments: invoice.attachments || [],
    bankAccountId: invoice.bankAccountId,
    emailAddress: lastInvoiceMessage.emailAddresses[0],
    message: lastInvoiceMessage.message || defaultBody,
    subject: lastInvoiceMessage.subject || defaultSubject,
    firstName: invoice.contact.firstName || '',
    lastName:  invoice.contact.lastName || '',
    reference: invoice.reference || ''
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);
  const [hasContactChanged, setHasContactChanged] = useState(false);
  const [validationErrors, setValidationErrors] = useState({emailAddress: ''});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [showEcheckWarning, setShowEcheckWarning] = useState(false);
  const [charLimitValidation, setCharLimitValidation] = useState({
    emailUsername: false,
    emailAddress: false,
    firstName: false,
    lastName: false,
    reference: false,
    message: false,
    subject: false
  });

  useEffect(() => {
    const fieldLimits = Object.keys(charLimitValidation);
    const hasLimitRestrictions = fieldLimits.some(field => charLimitValidation[field]);
    if (!validationErrors.emailAddress) {
      setIsSendDisabled(checkValuesMissing(formValues) || hasLimitRestrictions);
    }
  }, [formValues, charLimitValidation]);

  useEffect(() => {
    const lastInvoiceMessage = getLatestInvoiceMessage(invoice?.invoiceMessages || []);
    const lastEmailAddress = lastInvoiceMessage.emailAddresses[0];
    const isEmailDifferent = (formValues.emailAddress !== lastEmailAddress);
    const isContactNameDifferent =  ['firstName', 'lastName'].some(field => formValues[field] !== invoice.contact[field]);

    setHasContactChanged(isEmailDifferent || isContactNameDifferent);
  }, [formValues.emailAddress, formValues.firstName, formValues.lastName]);

  useEffect(() => {
    const {totalAmount} = formValues;
    if (hasEcheckEnabled(bankAccount)) {
      const limit = Number(bankAccount?.achLimit) > 0 ? bankAccount.achLimit : defaultLimit;
      setShowEcheckWarning(isAmountOverLimit(limit, totalAmount));
      return;
    }
    setShowEcheckWarning(false);
  }, [formValues.totalAmount, bankAccount]);

  const handleFormChange = (event, lengthValidation) => {
    const {target: {name, value}} = event;
    if (name === 'totalAmount') {
      const amountValue = value.replace(/,/g, '');
      setFormValues({...formValues, totalAmount: amountValue});
      return;
    }
    if (name === 'emailAddress') {
      const trimmedValue = value.trim();
      setFormValues({...formValues, [name]: trimmedValue});

      if (validationErrors[name] === 'must be a valid email' && isEmailPattern(trimmedValue)) {
        setValidationErrors({...validationErrors, [name]: ''});
      }
    }

    setCharLimitValidation({...charLimitValidation, [name]: lengthValidation === 'fail'});
    setFormValues({...formValues, [name]: value});
  };

  const displayErrorMessage = () => {
    setIsAlertVisible(true);
    setIsSendDisabled(false);
  };

  const buildPayload = () => {
    let payload = {
      id: invoice.id,
      invoice: {
        reference: formValues.reference,
        amount: formValues.totalAmount,
        subject: formValues.subject,
        body: formValues.message,
        contact_id: invoice.contactId,
        email_address: formValues.emailAddress,
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        merchant_id: invoice.ownerId,
        bank_account_id: invoice.bankAccountId,
        line_item_id: invoice.items[0]?.id || []
      }
    };
    if(formValues.attachments && formValues.attachments.length > 0){
      payload.invoice.attachment_ids = formValues.attachments.map(attachment => attachment.id);
    }
    return payload;
  };

  const destroyInvoiceAttachment = async (attachmentId, invoiceId) => {
    return await client.destroy(`attachments/${attachmentId}/delete_from_invoice/${invoiceId}`);
  };

  const destroyMerchantAttachment = async (attachmentId, ownerId) => {
    return await client.destroy(`attachments/${attachmentId}/delete_from_merchant/${ownerId}`);
  };

  const processAttachmentsToDelete = async attachmentsToDelete => {
    const processedAttachmentResponses = attachmentsToDelete.map(async attachmentId => {
      const invoiceResponse = await destroyInvoiceAttachment(attachmentId, invoice.id);
      const merchantResponse = await destroyMerchantAttachment(attachmentId, invoice.ownerId);
      return [invoiceResponse, merchantResponse];
    });
    return processedAttachmentResponses;
  };

  const detatchInvoiceAttachments = async attachments => {
    const detachedFilesResponse = attachments.map(async attachment => {
      const response = await destroyInvoiceAttachment(attachment.id, invoice.id);
      return [response];
    });
    return detachedFilesResponse;
  };

  const handleSaveAndResend = async event => {
    event.preventDefault();

    if (isSendDisabled) return;

    if (!isEmailPattern(formValues.emailAddress)) {
      setValidationErrors({
        ...validationErrors,
        emailAddress: 'must be a valid email'
      });

      setIsSendDisabled(true);
      return;
    }

    setIsSendDisabled(true);
    const requestMethod = hasContactChanged ? client.post : client.patch;

    if (attachmentsToDelete.length > 0) await processAttachmentsToDelete(attachmentsToDelete);

    const {attachments} = formValues;

    if (hasContactChanged && attachments.length > 0) {
      await detatchInvoiceAttachments(attachments);
    }

    const response = await requestMethod(`/quick_bills/${invoice.id}/save_and_resend`, buildPayload());
    const json = await response.json();
    const {status} = response;

    if([200, 201].includes(status)) {
      window.location = window.location.pathname.replace(/^(.+?\/).*/, `$1/${json.id}`);
      setIsSendDisabled(false);
      return;
    }
    displayErrorMessage();
  };

  const closeModal = () => {
    setIsDisabled(false);
    setShowModal(false);
  };

  return (
    <div className="edit-quick-bill">
      <QuickBillStatusBarUnpaid invoice={invoice} />
      {isAlertVisible &&
        <APFormMessageBox
          type="error"
          header="Error editing Quick Bill information"
          messages={[
            'An error occurred editing this Quick Bill, please try again.',
            `If the error reoccurs, contact our Support team at ${siteSupportPhone}.`
          ]}
        />
      }
      <form className="edit-quick-bill" method="post">
        <APInputText
          characterLimits={{warn: 243,error: 254}}
          id="emailAddress"
          label="Client Email"
          name="emailAddress"
          value={formValues.emailAddress }
          error={validationErrors.emailAddress}
          required={true}
          onChange={handleFormChange}
        />
        <div className="edit-quick-bill-row">
          <APInputText
            characterLimits={characterLimits.name}
            id="firstName"
            label="First Name"
            name="firstName"
            value={formValues.firstName }
            onChange={handleFormChange}
          />

          <APInputText
            characterLimits={characterLimits.name}
            id="lastName"
            label="Last Name"
            name="lastName"
            value={formValues.lastName}
            onChange={handleFormChange}
          />
        </div>
        <APInputMonetary
          id="totalAmount"
          label="Payment Amount"
          name="totalAmount"
          value={formValues.totalAmount}
          required={true}
          onChange={handleFormChange}
        />
        {showEcheckWarning &&
          <APFormMessageBox
            type="warning"
            header="Payment amount exceeds single eCheck transaction limit."
            messages={[`This Quick Bill amount can be paid with card only. To increase your eCheck limit, please contact our Support team at ${siteSupportPhone}.`]}
          />
        }
        <APInputText
          characterLimits={characterLimits.reference}
          id="reference"
          label="Reference Number"
          name="reference"
          value={formValues.reference}
          onChange={handleFormChange}
        />
        <APInputText
          characterLimits={characterLimits.subject}
          label="Subject Line"
          name="subject"
          id="subject"
          value={formValues.subject}
          required={true}
          onChange={handleFormChange}
        />
        <APTextArea
          characterLimits={characterLimits.body}
          label="Message Body"
          name="message"
          id="message"
          value={formValues.message}
          required={true}
          onChange={handleFormChange}
        />
        <div className="edit-quick-bill-note">
          <p>Note: A secure, custom payment link will be embedded in the email message.</p>
        </div>
        {!attachmentDisabled && (
          <QuickBillAttachments
            formValues={formValues}
            setFormValues={setFormValues}
            setIsDisabled={setIsSendDisabled}
            checkValuesMissing={checkValuesMissing}
            merchantId={invoice.ownerId}
            invoiceId={invoice.id}
            setAttachmentsToDelete={setAttachmentsToDelete}
          />
        )}
        <div className="edit-quick-bill-buttons">
          <APButton
            className="ap-ghost-button"
            onClick={closeModal}
          >
            Cancel
          </APButton>
          <SaveAndResendButton
            handleSaveAndResend={handleSaveAndResend}
            contactChanged={hasContactChanged}
            submitting={isSendDisabled}
            hasAttachments={formValues.attachments.length > 0}
            isInvoicePending={(!invoice.presentmentStatus || invoice.presentmentStatus === 'pending')}
          />
        </div>
      </form>
    </div>
  );
};

QuickBillEditForm.propTypes = {
  attachmentDisabled: bool,
  bankAccount: bankAccountProp,
  defaultSubject: string,
  defaultBody: string,
  invoice: invoiceProp,
  isDisabled: bool,
  setShowModal: func,
  setIsDisabled: func,
  siteSupportPhone: string
};

export default QuickBillEditForm;
