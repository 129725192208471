// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Reports Datepicker Snapshot renders snapshot 1`] = `
<div
  className="element-wrapper"
>
  <ForwardRef
    className="datepicker-input"
    id="datepicker-input"
    name="date"
    onChange={[Function]}
    onClick={[Function]}
    value="Jan-2020"
  />
  <div
    className="months-container "
  >
    <div
      className="flex-container"
    >
      <div
        className="arrows arrow-left flex-items "
        data-value={2020}
        id="arrow-left"
        onClick={[Function]}
      >
        &lt;
      </div>
      <div
        className="year-container"
        id="year-container"
        onClick={[Function]}
      >
        <div
          className="years active"
          data-value={2020}
          id="year-2020"
          key="year-2020"
        >
          2020
        </div>
      </div>
      <div
        className="arrows arrow-left flex-items  disableArrow"
        data-value={2020}
        id="arrow-right"
        onClick={[Function]}
      >
        &gt;
      </div>
    </div>
    <div
      className="month-container"
      id="month-container"
    >
      <div
        className="blocks"
        data-value="01"
        id="unit-01"
        key="unit-01"
        onClick={[Function]}
      >
        Jan
      </div>
      <div
        className="blocks"
        data-value="02"
        id="unit-02"
        key="unit-02"
        onClick={[Function]}
      >
        Feb
      </div>
      <div
        className="blocks"
        data-value="03"
        id="unit-03"
        key="unit-03"
        onClick={[Function]}
      >
        Mar
      </div>
      <div
        className="blocks"
        data-value="04"
        id="unit-04"
        key="unit-04"
        onClick={[Function]}
      >
        Apr
      </div>
      <div
        className="blocks"
        data-value="05"
        id="unit-05"
        key="unit-05"
        onClick={[Function]}
      >
        May
      </div>
      <div
        className="blocks"
        data-value="06"
        id="unit-06"
        key="unit-06"
        onClick={[Function]}
      >
        Jun
      </div>
      <div
        className="blocks"
        data-value="07"
        id="unit-07"
        key="unit-07"
        onClick={[Function]}
      >
        Jul
      </div>
      <div
        className="blocks"
        data-value="08"
        id="unit-08"
        key="unit-08"
        onClick={[Function]}
      >
        Aug
      </div>
      <div
        className="blocks"
        data-value="09"
        id="unit-09"
        key="unit-09"
        onClick={[Function]}
      >
        Sept
      </div>
      <div
        className="blocks"
        data-value="10"
        id="unit-10"
        key="unit-10"
        onClick={[Function]}
      >
        Oct
      </div>
      <div
        className="blocks"
        data-value="11"
        id="unit-11"
        key="unit-11"
        onClick={[Function]}
      >
        Nov
      </div>
      <div
        className="blocks"
        data-value="12"
        id="unit-12"
        key="unit-12"
        onClick={[Function]}
      >
        Dec
      </div>
    </div>
  </div>
  <ForwardRef
    className="addon-right"
    id="start_month"
    name="date"
    type="hidden"
    value="01/2020"
  />
</div>
`;
