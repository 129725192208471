import React from 'react';
import { string } from 'prop-types';

const CalendarIcon = ({ fill='#003566', title='Timeline Calendar Icon'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 22 22"
  >
    <title>{title}</title>
    <defs>
      <path id="a" d="M2.692 21.429H6.5v-3.536H2.692v3.536zm4.654 0h4.23v-3.536h-4.23v3.536zm-4.654-4.322H6.5V13.18H2.692v3.928zm4.654 0h4.23V13.18h-4.23v3.928zm-4.654-4.714H6.5V8.857H2.692v3.536zm9.731 9.036h4.23v-3.536h-4.23v3.536zm-5.077-9.036h4.23V8.857h-4.23v3.536zM17.5 21.429h3.808v-3.536H17.5v3.536zm-5.077-4.322h4.23V13.18h-4.23v3.928zM7.77 6.5V2.964a.365.365 0 0 0-.125-.276.423.423 0 0 0-.298-.117H6.5a.423.423 0 0 0-.297.117.365.365 0 0 0-.126.276V6.5c0 .106.042.198.126.276a.423.423 0 0 0 .297.117h.846a.423.423 0 0 0 .298-.117.365.365 0 0 0 .125-.276zM17.5 17.107h3.808V13.18H17.5v3.928zm-5.077-4.714h4.23V8.857h-4.23v3.536zm5.077 0h3.808V8.857H17.5v3.536zm.423-5.893V2.964a.365.365 0 0 0-.126-.276.423.423 0 0 0-.297-.117h-.846a.423.423 0 0 0-.298.117.365.365 0 0 0-.125.276V6.5c0 .106.042.198.125.276a.423.423 0 0 0 .298.117h.846a.423.423 0 0 0 .297-.117.365.365 0 0 0 .126-.276zM23 5.714V21.43c0 .425-.167.793-.502 1.104a1.69 1.69 0 0 1-1.19.467H2.692a1.69 1.69 0 0 1-1.19-.467C1.167 22.223 1 21.854 1 21.43V5.714c0-.425.167-.794.502-1.105a1.69 1.69 0 0 1 1.19-.466h1.693V2.964c0-.54.207-1.002.621-1.387A2.118 2.118 0 0 1 6.5 1h.846c.582 0 1.08.192 1.494.577.414.385.622.847.622 1.387v1.179h5.076V2.964c0-.54.208-1.002.622-1.387A2.118 2.118 0 0 1 16.654 1h.846c.582 0 1.08.192 1.494.577.414.385.621.847.621 1.387v1.179h1.693c.458 0 .855.155 1.19.466.335.311.502.68.502 1.105z"/>
    </defs>
    <use fill={fill} fillRule="nonzero" transform="translate(-1 -1)" xlinkHref="#a"/>
  </svg>
);

CalendarIcon.propTypes = {
  fill: string,
  title: string
};

export default CalendarIcon;
