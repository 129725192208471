import { APInputMonetary } from 'affinipay-ui-library';
import { array, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import PaymentPageCustomAmount from '../../components/PaymentPageCustomAmount';
import AmountCheckbox from '../../components/CustomAmountFields/AmountCheckbox';
import AmountRadio from '../../components/CustomAmountFields/AmountRadio';
import {
  DineroDollarsFormat,
  getCentsFromDollars,
  getCentsFromMaskedMoneyInput,
  getDollarsFromCents,
  getDollarsFromMaskedMoneyInput,
  getRoundedMonetaryNumber
} from '../../lib/monetaryUtils';

// eslint-disable-next-line react/display-name
const AmountComponent = React.memo(({
  fieldsData,
  formState,
  setFormState,
  customFieldsState,
  setCustomFieldsState,
  currencySymbol,
  disabledFormFieldsState
}) => {
  const [hasDefaultAmountField, setHasDefaultAmountField] = useState(true);
  const [disabledFields, setDisabledFields] = useState();

  useEffect(() => {
    if(fieldsData && !customFieldsState) {
      initializeCustomFieldsState(fieldsData);
      //amth checkbox amt radio
      setHasDefaultAmountField(fieldsData.find(el => el.type==='amount' || el.type==='amount_radio' || el.type==='amount_checkbox') === undefined);
    }
  }, [fieldsData]);

  useEffect(() => {
    let centsSubtotal = 0;
    for(const field in customFieldsState) {
      if(customFieldsState[field] === 'section') continue;
      if(Array.isArray(customFieldsState[field])){
        centsSubtotal += getCentsFromMaskedMoneyInput(customFieldsState[field][1]);
      } else {
        centsSubtotal += getCentsFromMaskedMoneyInput(customFieldsState[field]);
      }
    }
    const dollarsSubtotal = getDollarsFromCents(centsSubtotal);
    if(dollarsSubtotal !==formState.customFieldsSubtotal)
      setFormState({...formState, customFieldsSubtotal: dollarsSubtotal});
  }, [customFieldsState]);

  const initializeCustomFieldsState = fields => {
    let newCustomFieldsState = {},
      centsCustomFieldsSubtotal = 0,
      disabledFields = {},
      newFormState = {};
    fields.map(el => {
      const isQuantityField = el.amount && el.max_quantity;
      switch(el.type) {
      case 'amount':
        if(isQuantityField) {
          newCustomFieldsState[el.name] = [undefined, 0];
        }else{
          newCustomFieldsState[el.name] = el.amount || undefined;
          centsCustomFieldsSubtotal = centsCustomFieldsSubtotal + getCentsFromMaskedMoneyInput(el.amount);
        }
        break;
      case 'amount_checkbox':
      case 'amount_radio':
        disabledFields[el.name] = true;
        if(isQuantityField){
          newCustomFieldsState[el.name] = [undefined, 0];
        }else{
          newCustomFieldsState[el.name] = undefined;
        }
        break;
      case 'amount_section':
        newCustomFieldsState[el.name] = 'section';
        break;
      case 'amount_percent':{
        //Percent is saved in db as multiplier. So 1.5% is 0.015
        const wholePercentageNumber = el.amount * 10000,
          centsCharge = (centsCustomFieldsSubtotal * wholePercentageNumber) / 10000;
        centsCustomFieldsSubtotal += centsCharge;
        newCustomFieldsState[el.name] = getDollarsFromCents(centsCharge);
        break;
      }
      case 'amount_subtotal': {
        let subtotalFieldValue = 0;
        const monetaryFields = fields.filter(p => p.type==='amount_subtotal' || p.type==='amount' || p.type==='amount_section' || p.type==='amount_percent');
        monetaryFields.map((f, fieldIndex) => {
          if(f.name === el.name) {
            for(let i=fieldIndex-1; i>=0; i--) {
              const checkedEl = monetaryFields[i];
              if(checkedEl.type === 'amount_section') return;
              if(checkedEl.type === 'amount_percent'){
                subtotalFieldValue = subtotalFieldValue + newCustomFieldsState[checkedEl.name];
              }else if(checkedEl.amount && !checkedEl.max_quantity){
                subtotalFieldValue = subtotalFieldValue + getDollarsFromMaskedMoneyInput(checkedEl.amount);
              }
            }
          }
        });
        newFormState = {...newFormState, [el.name]:subtotalFieldValue};
        break;
      }
      case 'amount_total': {
        const pos = el.position;
        let subtotalFieldValue = 0;
        fields.filter(p => p.type==='amount_subtotal' || p.type==='amount' || p.type==='amount_section' || p.type==='amount_percent').map(f => {
          if(f.position < pos) {
            if(f.type === 'amount_percent'){
              subtotalFieldValue = subtotalFieldValue + getCentsFromDollars(newCustomFieldsState[f.name]);
            }else if(f.amount && !f.max_quantity){
              subtotalFieldValue = subtotalFieldValue + getCentsFromMaskedMoneyInput(f.amount);
            }
          }
        });
        newFormState = {...newFormState, [el.name]:getDollarsFromCents(subtotalFieldValue)};
        break;
      }
      default:
        break;
      }
    });
    newFormState = {...newFormState, customFieldsSubtotal: getDollarsFromCents(centsCustomFieldsSubtotal)};
    setFormState({...formState, ...newFormState});
    setCustomFieldsState(newCustomFieldsState);
    setDisabledFields(disabledFields);
  };

  const recalculatePercentFields = state => {
    const percentFields = fieldsData.filter(el => el.type==='amount_percent');
    percentFields.map(p => {
      const name = p.name;
      let centsSubtotal = 0;
      fieldsData.forEach(f => {
        if(f.position < p.position) {
          if(Array.isArray(state[f.name])) {
            centsSubtotal += getCentsFromMaskedMoneyInput(state[f.name][1]);
          } else if(state[f.name] !== 'section') {
            centsSubtotal += getCentsFromMaskedMoneyInput(state[f.name]);
          }
        }
      });
      //Percent is saved in db as multiplier. So 1.5% is 0.015
      const wholePercentageNumber = p.amount * 10000,
        centsCharge = (centsSubtotal * wholePercentageNumber) / 10000;
      state[name] = getDollarsFromCents(centsCharge);
    });
    return state;
  };

  const recalculateSubtotalFields = (FState, CFState) => {
    fieldsData.map((field, fieldIndex) => {
      if(field.type==='amount_subtotal' || field.type==='amount_total'){
        const name = field.name;
        let subtotal = 0;
        for(let i=fieldIndex-1; i>=0; i--){
          const f = fieldsData[i];
          if(f.type === 'amount_section' && field.type==='amount_subtotal') break;
          if(f.type === 'amount_subtotal' || f.type === 'amount_total') continue;
          if(Array.isArray(CFState[f.name])) {
            subtotal += getCentsFromMaskedMoneyInput(CFState[f.name][1]);
          } else if(CFState[f.name] !== 'section') {
            subtotal += getCentsFromMaskedMoneyInput(CFState[f.name]);
          }
        }
        FState[name] = getDollarsFromCents(subtotal);
      }
    });
    return FState;
  };

  const handleAmountChange = ({target}) => {
    const {name, value} = target;
    setFormState({ ...formState, [name]: value });
  };

  const handleCustomInputChange = ({target}) => {
    const {name, value} = target;
    let newCustomFieldState = recalculatePercentFields({ ...customFieldsState, [name]: value });
    const newFormState = recalculateSubtotalFields(formState, newCustomFieldState);
    setCustomFieldsState(newCustomFieldState);
    setFormState(newFormState);
  };

  const handleQuantityChange = ({name, value}, multiplier) => {
    let newCustomFieldState = { ...customFieldsState, [name]: [value, getRoundedMonetaryNumber(value*multiplier)] };
    newCustomFieldState = recalculatePercentFields(newCustomFieldState);
    const newFormState = recalculateSubtotalFields(formState, newCustomFieldState);
    setFormState(newFormState);
    setCustomFieldsState(newCustomFieldState);
  };

  const handleCheckBoxClick = e => {
    const checked = e.target.checked,
      name = e.target.name,
      el = fieldsData.find(el => el.name === name),
      isStaticField = el.amount && !el['max_quantity'],
      isAmountField = !el.amount,
      isQuantityField = el.amount && el['max_quantity'];
    let newCustomFieldsState = {...customFieldsState};

    if (checked) {
      if (isStaticField) newCustomFieldsState[name] = el.amount;
    } else {
      if (isQuantityField) {
        newCustomFieldsState[name] = [undefined, 0];
      } else if (isStaticField || isAmountField) {
        newCustomFieldsState[name] = '';
      }
    }
    newCustomFieldsState = recalculatePercentFields(newCustomFieldsState);
    const newFormState = recalculateSubtotalFields(formState, newCustomFieldsState);
    setFormState(newFormState);
    setCustomFieldsState(newCustomFieldsState);
    setDisabledFields({...disabledFields, [name]: !checked});
  };

  const handleRadioButtonChange = e => {
    const name = e.target.value,
      allRadios = fieldsData.filter(el => el.type=== 'amount_radio'),
      radioNames = allRadios.map(el => el.name),
      el = allRadios.find(el => el.name === name),
      isStaticField = el.amount && !el['max_quantity'],
      newDisabledState = disabledFields;
    let newCustomFieldsState = {};
    for(const field in customFieldsState) {
      if(radioNames.indexOf(field) !== -1) {
        if(Array.isArray(newCustomFieldsState[field])) {
          newCustomFieldsState[field] = [undefined, 0];
        } else {
          newCustomFieldsState[field] = '';
        }
      }
    }
    if(isStaticField) newCustomFieldsState[name] = el.amount;
    for(const field in newDisabledState) {
      if(name === field) {
        newDisabledState[field] = false;
      } else if(radioNames.indexOf(field) !== -1) {
        newDisabledState[field] = true;
      }
    }
    newCustomFieldsState = {...customFieldsState, ...newCustomFieldsState};
    newCustomFieldsState = recalculatePercentFields(newCustomFieldsState);
    const newFormState = recalculateSubtotalFields(formState, newCustomFieldsState);
    setFormState(newFormState);
    setDisabledFields(newDisabledState);
    setCustomFieldsState(newCustomFieldsState);
  };

  const renderCustomAmountFields = (fieldsData) => {
    const view = fieldsData.map( el => {
      const showAmountInput = !el.amount || (!!el.amount && !!el.max_quantity);
      switch(el.type) {
      case 'amount':
        return (
          <div key={el.position} className="col-12 form-group custom-amount-container">
            <div>
              <PaymentPageCustomAmount
                fieldType={el.type}
                label={el.name}
                value={customFieldsState[el.name]}
                name={el.name}
                amount={el.amount}
                maxQuantity={el.max_quantity}
                onChange={handleCustomInputChange}
                handleQuantityChange={handleQuantityChange}
                currencySymbol={currencySymbol}
                disabled={disabledFormFieldsState[el.name]}
              />
              {el.help_text && (
                <span className="field-help-text amount-helper-text">{el.help_text}</span>
              )}
            </div>
          </div>
        );
      case 'amount_checkbox':
        return (
          <AmountCheckbox
            key={el.position}
            element={el}
            currencySymbol={currencySymbol}
            customFieldsState={customFieldsState}
            disabledFields={disabledFields}
            showAmountInput={showAmountInput}
            handleQuantityChange={handleQuantityChange}
            handleCheckBoxClick={handleCheckBoxClick}
            handleCustomInputChange={handleCustomInputChange}
          />
        );
      case 'amount_radio':
        return (
          <AmountRadio
            key={el.position}
            element={el}
            currencySymbol={currencySymbol}
            customFieldsState={customFieldsState}
            disabledFields={disabledFields}
            showAmountInput={showAmountInput}
            handleQuantityChange={handleQuantityChange}
            handleRadioButtonChange={handleRadioButtonChange}
            handleCustomInputChange={handleCustomInputChange}
          />
        );
      case 'amount_percent': {
        return (
          <div key={el.position} className="col-12 form-group">
            <div><strong>{el.name} - {DineroDollarsFormat(customFieldsState[el.name], currencySymbol)}</strong></div>
            {el.help_text && (
              <span className="field-help-text">{el.help_text}</span>
            )}
          </div>
        );
      }
      case 'amount_section':
        return (
          <div key={el.position} className="amount-section-title col-12 form-group">
            <h3>{el.name}</h3>
            {el.help_text && (
              <span className="field-help-text">{el.help_text}</span>
            )}
          </div>
        );
      case 'amount_subtotal':
      case 'amount_total':
        return (
          <div key={el.position} className="col-12 form-group">
            <div><strong>{el.name} - {DineroDollarsFormat(formState[el.name], currencySymbol)}</strong></div>
            {el.help_text && (
              <span className="field-help-text">{el.help_text}</span>
            )}
          </div>
        );
      default:
        return null;
      }
    });
    return view;
  };

  let memoizedView = '';
  if(fieldsData && customFieldsState) {
    memoizedView = renderCustomAmountFields(fieldsData);
  }

  return (
    <div className="row">
      {hasDefaultAmountField ? (
        <div className="col-12 col-md-7 form-group">
          <APInputMonetary
            disabled={disabledFormFieldsState['amountField']}
            hasAddon={false}
            isFloatingLabel={true}
            label="Payment Amount"
            name="amountField"
            onChange={handleAmountChange}
            required={true}
            value={formState.amountField}
          />
        </div>
      ) : (
        <>
          {memoizedView}
          <div className="col-12 summary">
            <hr />
            <div className="text-right">
              Amount due - <strong>{DineroDollarsFormat(formState.customFieldsSubtotal, currencySymbol)}</strong>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

AmountComponent.propTypes = {
  fieldsData: array,
  formState: object,
  setFormState: func,
  customFieldsState: object,
  disabledFormFieldsState: object,
  currencySymbol: string,
  setCustomFieldsState: func
};

export default AmountComponent;

