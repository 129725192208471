import React from 'react';
import {func, object} from 'prop-types';
import { APInputMonetary, APInputPercent } from 'affinipay-ui-library';
import './style.scss';

const CardFeesSection = ({formState, setFormState, handleValidationError}) => {

  const onUserInput = e => {
    const {name, value} = e.target;
    setFormState({...formState, [name]: value});
    handleValidationError(name, value);
  };
  return (
    <section>
      <div className="row">
        <div className="col-xs-12">
          <h4>Card Transaction Rates</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 pricing-select">
          <label><strong>Flat Plan</strong></label>
        </div>
        <div className="col-xs-8 bordered-box">
          <div className="col-xs-6">
            <div className="row form-group">
              <div className="col-xs-8 cc-row form-group">
                <APInputPercent 
                  label="Credit Card"
                  name='creditCardFee'
                  value={formState.creditCardFee}
                  onChange={onUserInput}
                  parentClass="admin"
                  required={true}
                />
              </div>
              <div className="col-xs-8">
                <APInputPercent 
                  label="Debit Card"
                  name='debitCardFee'
                  onChange={onUserInput}
                  parentClass="admin"
                  value={formState.debitCardFee}
                  required={true}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <h4>Per Item fees</h4>
            <div className="row form-group">
              <div className="col-xs-6">
                <div className="input-group">
                  <APInputMonetary
                    label="Visa, MC, Discover"
                    name="visaMCDiscoverFee"
                    onChange={onUserInput}
                    parentClass="admin"
                    value={formState.visaMCDiscoverFee}
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <div className="input-group">
                  <APInputMonetary
                    label="Chargeback Fee"
                    name="chargebackFee"
                    onChange={onUserInput}
                    parentClass="admin"
                    value={formState.chargebackFee}
                  />
                </div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-xs-6">
                <div className="input-group">
                  <APInputMonetary
                    label="American Express"
                    name="amexFee"
                    onChange={onUserInput}
                    parentClass="admin"
                    value={formState.amexFee}
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <APInputPercent 
                  label="Foreign Card Fee"
                  name='foreignFee'
                  value={formState.foreignFee}
                  onChange={onUserInput}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardFeesSection;

CardFeesSection.propTypes = {
  formState: object,
  setFormState: func,
  handleValidationError: func
};
