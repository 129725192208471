import React from 'react';
import {number, string} from 'prop-types';

const IconEQB = ({
  className='ap-icon',
  fill='#171a1c',
  size=36,
  title='IconEQB'
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 36 36"
  >
    {title && <title>{title}</title>}
    <path d="M6.1,20.6l22-12.9l-15.2,14L6.1,20.6z M31.4,7.8l-5.7,17.5l-9.3-2.7L31.4,7.8z M15.3,29.8v-4.7l3.2,0.9
    L15.3,29.8z M21.6,26.9l4.7,1.4c0.2,0.1,0.3,0.1,0.5,0.1c0.6,0,1.1-0.3,1.3-0.7c1.4-3.9,5.2-16.5,7.8-23.1c0.3-0.8,0-1.7-0.9-2.2
    c-0.6-0.4-1.4-0.3-2,0L0.8,19.9C0.3,20.1,0,20.6,0,21.1c0,0.6,0.5,1.3,1.2,1.3l11,1.9v7.4c0,1.2,1,2.1,2.3,2.1
    c0.6,0,1.3-0.3,1.8-0.6C16.4,33.1,21.6,26.9,21.6,26.9z"
    fill={fill}
    />
  </svg>
);

IconEQB.propTypes = {
  className: string,
  fill: string,
  size: number,
  title: string
};

export default IconEQB;
