import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import { APButton, APFormMessageBox, APInputMonetary, APInputText, APSelect, APTable } from 'affinipay-ui-library';
import { getDeposits } from './services';
import LegacyAPDatepicker from '../../../components/LegacyAPDatepicker';

const DepositsReport = ({
  merchant
}) => {
  const initialFilterFormState = {
    amount: undefined,
    account: undefined,
    type: 'All',
    dateTo: undefined,
    dateFrom: undefined,
    sortBy: 'deposit_date',
    sortOrder: 'desc',
    page: 1,
    loading: false
  };

  let paymentTypeOptions = [
    {text: 'All', selected: true},
    {text: 'Card', value: 'card'},
    {text: 'eCheck', value: 'ach'}
  ];

  const tableHeaderData = [
    {Header: 'Date Disbursed', accessor: 'deposit_date'},
    {Header: 'Bank Account', accessor: 'bank_name'},
    {Header: 'Payment Type', accessor: 'source'},
    {Header: 'Deposit Amount', accessor: 'deposit_total'},
    {Header: 'Deposit ID', accessor: 'id', disableSortBy: true}
  ];

  const [deposits, setDeposits] = useState([]);
  const [displayError, setDisplayError] = useState();
  const [filterFormState, setFilterFormState] = useState(initialFilterFormState);
  const [resetInputs, setResetInputs] = useState();
  const [totalEntries, setTotalEntries] = useState(0);

  const SERVER_ERROR = 'Failed to retrieve deposits';

  useEffect(() => {
    dataFetch(filterFormState);
  }, [filterFormState.sortBy, filterFormState.sortOrder, filterFormState.page]);

  const handleInputChange = e => {
    const name = e.target.name,
      value = e.target.value;
    let newState = {[`${name}`]: value};
    setFilterFormState({...filterFormState, ...newState});
  };

  const dataFetch = async (payload) => {
    setFilterFormState({...filterFormState, loading: true});
    getDeposits(payload, merchant).then(async res => {
      if(res.ok) {
        const json = await res.json();
        setTotalEntries(json.meta.totalResults);
        if(json.depositReports.length > 0) {
          setDeposits(json.depositReports);
        } else {
          setDeposits();
        }
        setDisplayError();
      } else {
        setDisplayError(SERVER_ERROR);
      }
      setFilterFormState({...filterFormState, loading: false});
    }).catch(() => {
      setDisplayError(SERVER_ERROR);
      setFilterFormState({...filterFormState, loading: false});
    });
  };

  const handleTableSort = useCallback(({id, desc}) => {
    let newState = {
      sortBy: id,
      sortOrder: desc ? 'desc' : 'asc'
    };
    setFilterFormState({...filterFormState, ...newState});
  }, [filterFormState, setFilterFormState]);

  const handleResetClick = e => {
    e.preventDefault();
    setResetInputs(new Date(Date.now()).getTime());
    setFilterFormState(initialFilterFormState);
    dataFetch(initialFilterFormState);
  };

  const handleFilterClick = e => {
    e.preventDefault();
    if(filterFormState.page !== 1) {
      let newState = {
        page: 1
      };
      setFilterFormState({...filterFormState, ...newState});
    } else {
      dataFetch(filterFormState);
    }
  };

  const handlePageChange = useCallback(({page}) => {
    let newState = {['page']: page};
    setFilterFormState({...filterFormState, ...newState});
  }, [filterFormState, setFilterFormState]);

  return (
    <div className="row admin deposit-report">
      <div className="col-xs-12">
        <form>
          <div className="col-xs-2">
            <APInputMonetary
              label="Search by amount"
              name='amount'
              onChange={handleInputChange}
              parentClass="admin"
              required={true}
              value={filterFormState.amount}
            />
          </div>
          <div className="col-xs-2">
            <APSelect
              isResetRequired={resetInputs}
              label='Filter by type'
              name='type'
              options={paymentTypeOptions}
              onChange={handleInputChange}
              parentClass="admin"
              required={true}
              value={filterFormState['type']}
            />
          </div>
          <div className="col-xs-2">
            <APInputText
              label='Filter by account'
              name='account'
              onChange={handleInputChange}
              parentClass="admin"
              required={true}
              value={filterFormState.account}
            />
          </div>
          <div className="col-xs-2">
            <LegacyAPDatepicker
              labelText='From'
              month={new Date(Date.now())}
              onDayCallback={day =>
                handleInputChange({target:{name:'dateFrom', value:day}})
              }
              isResetRequired={resetInputs}
            />
          </div>
          <div className="col-xs-2">
            <LegacyAPDatepicker
              labelText='To'
              month={new Date(Date.now())}
              onDayCallback={day =>
                handleInputChange({target:{name:'dateTo', value:day}})
              }
              isResetRequired={resetInputs}
            />
          </div>
          <div className="col-xs-2 button-row">
            <APButton
              className='ap-admin-default-secondary-button'
              onClick={handleResetClick}
            >
              Reset
            </APButton>
            <APButton
              className='ap-primary-button filter-btn'
              onClick={handleFilterClick}>
              Filter
            </APButton>
          </div>
        </form>
      </div>
      {displayError && (
        <div className="col-xs-12">
          <APFormMessageBox type="error">
            {displayError}
          </APFormMessageBox>
        </div>
      )}
      <div className="col-xs-12">
        <APTable
          data={deposits}
          header={tableHeaderData}
          onSort={handleTableSort}
          isServerSort={true}
          isPaginated={true}
          handlePaginationChange={handlePageChange}
          showPageSizeOptions={false}
          total={totalEntries}
          currentPage={filterFormState.page}
          noRecordsText='No Records Found'
          loading={filterFormState.loading}
        />
      </div>
    </div>
  );
};

DepositsReport.propTypes = {
  merchant: PropTypes.object
};

export default DepositsReport;
