import React, {useState, useEffect} from 'react';
import {array, bool, func, number} from 'prop-types';
import { APButton, APModal, APSelect, APDatepicker } from 'affinipay-ui-library';
import PCICompliancesService from './src/services/PCICompliancesService';
import { complianceStatusOptions } from './src/lib/StatusOptions';
import { toISODate } from './PCIModal';

const filteredStatusOptions = complianceStatusOptions.filter(status => !['All', 'Started'].includes(status.text));
const today = new Date(Date.now());

const BulkUpdateModal = ({
  filters,
  count,
  isDisabled,
  isModalOpen,
  toggleModal,
  onCloseAndRefresh
}) => {
  const [date, setDate] = useState(today);
  const [status, setStatus] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if(!isModalOpen) {
      setDate(today);
      setStatus('');
      setIsConfirmed(false);
    }
  }, [isModalOpen]);

  const handleDateChange = e => {
    const value = e?.target?.value;
    setDate(value);
  };

  const handleStatusChange = e => {
    const {value} = e.target;
    setStatus(value);
  };

  const handleComplianceReassignment = async () => {
    try {
      await PCICompliancesService.bulkUpdate(filters, status, toISODate(date));
    } catch (e) {
      throw new Error(e);
    }
    onCloseAndRefresh();
  };

  const handleConfirmation = (e) => {
    const checked = e.target.checked;
    setIsConfirmed(checked);
  };

  return (
    <div className="pci-bulk-update-modal-container">
      <APButton 
        className="ap-button ap-table-button admin small" 
        type="button"
        disabled={filters.length === 0 || count === 0 || isDisabled}
        onClick={toggleModal}
      >
        Batch update filtered
      </APButton>
      <APModal
        className="ap-pci-modal"
        headerText="Update"
        isActiveInitial={isModalOpen}
        onClose={toggleModal}
      >
        <div className="ap-pci-modal-date-wrap">
          <h4 className="ap-pci-modal-header">Change Due Date</h4>
          <p className="ap-pci-modal-copy" data-testid="ap-pci-modal-datepicker-text">
            Please use the datepicker below to change <span className="ap-pci-modal-bold" data-testid="ap-pci-modal-datepicker-customer-id">{count} Customer{`'`}s</span> date of PCI compliance.
          </p>
          <APDatepicker
            datepickerId="ap-pci-modal-datepicker"
            value={date}
            required={true}
            minimumDate={today}
            onChange={handleDateChange}
            openToDate={today}
          />
        </div>
        <h4 className="ap-pci-modal-header">Change Status</h4>
        <p className="ap-pci-modal-copy">
          Please select a new compliance status from the dropdown options below.
        </p>
        <APSelect
          className="ap-pci-modal-status"
          label="Compliance Status"
          onChange={handleStatusChange}
          options={filteredStatusOptions}
          placeholderText="Select Status"
          required={true}
          value={status}
          parentClass="admin"
        />
        <div className="confirmation-wrapper admin">
          <input 
            type="checkbox" 
            id="bulk-update-confirmation" 
            name="bulk-update-confirmation" 
            onChange={handleConfirmation} 
            data-testid="bulk-update-confirm-checkbox"
          />
          <label htmlFor="bulk-update-confirmation" className="bulk-update-confirmation-label admin">I understand I am updating {count} merchants and I cannot undo these changes</label>
        </div>
        <div className="ap-pci-modal-save">
          <APButton
            className="ap-button ap-table-button admin small"
            disabled={!isConfirmed || !status || !date}
            onClick={handleComplianceReassignment}
            type="button"
          >
            Save changes
          </APButton>
        </div>
      </APModal>
    </div>
  );
};

BulkUpdateModal.propTypes = {
  filters: array,
  count: number,
  isDisabled: bool,
  isModalOpen: bool,
  toggleModal: func,
  onCloseAndRefresh: func
};
export default BulkUpdateModal;
