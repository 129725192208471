import React from 'react';
import {number, string} from 'prop-types';

const IconUpload = ({ className, fill='#003566', width=24, height=24, title = 'Attachment Upload Icon' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`icon-upload${className ? ` ${className}` : ''}`}
    viewBox="0 0 433.5 433.5"
    width={width}
    height={height}
    fill={fill}
  >
    <title>{title}</title>
    <polygon points="140.25,331.5 293.25,331.5 293.25,178.5 395.25,178.5 216.75,0 38.25,178.5 140.25,178.5"></polygon>
    <rect x="38.25" y="382.5" width="357" height="51"></rect>
  </svg>
);

IconUpload.propTypes = {
  className: string,
  fill: string,
  height: number,
  width: number,
  title: string
};

export default IconUpload;
