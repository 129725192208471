import React from 'react';

/* eslint-disable no-unused-vars */
export const paymentPageErrors = {
  genericRefreshPage: 'Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.',
  genericTransactionFail: 'Failed to process transaction. Please refresh the page and try again.',
  captchaFail: 'Failed to validate your session. Please refresh the page and try again.',
  captchaVerify: 'Please verify that you are not a robot.',
  zeroChargeAttempt: 'Amount should be greater than 0',
  missingExpMonth: 'A valid card expiration month is required',
  missingExpYear: 'A valid card expiration year is required',
  transactionLimit: 'We could not process your transaction at the moment. Please try with a lower amount.',
  callBank: 'This card transaction has been declined by the bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.',
  hostedFields: {
    checkPaymentInfo: 'Failed to process transaction. Please verify your payment account information and try again.',
    refreshPage: 'Failed to process transaction. Please refresh the page and try again.',
    fillPaymentInfo: 'Please fill out payment account information and try again.',
    invalidCVV: 'A valid CVV is required'
  }
};

export const loanErrors = {
  tokenFailure: 'There was an error when creating this charge. Please refresh the page and try again.',
  chargeFailure: 'There was an error when creating this charge. Please refresh the page and try again.',
  affirmLoadFailure: 'There was a problem loading this content. Try reloading this page.',
  firstNameValidation: 'First Name is required.',
  lastNameValidation: 'Last Name is required.',
  emailValidation: 'Email Address is required.',
  emailPattern: 'Email address is not valid. Please review and try again.',
  minLimit: amount => `The amount you entered is too low. Please enter an amount of ${amount} or higher.`,
  maxLimit: amount => `The amount you entered is too high. Please enter an amount of ${amount} or less.`,
  zeroAmount: 'Amount should be greater than 0.',
  affirmErrorHeader: 'Your Pay Later loan request did not go through.',
  affirmErrorMessage: function affirmErrorMessage(merchantName, merchantSupportPhone) {
    return <span>We recommend trying a lower amount or another payment method if possible. If not possible, or in case of question, please contact {merchantName}{merchantSupportPhone && (<span> at <a href={`tel:${merchantSupportPhone}`}>{merchantSupportPhone}</a></span>)}.</span>;
  }
};

export const loanInfo = {
  abandonmentMessage: 'Your session has ended due to inactivity. Please fill the form and try again.'
};

export const paymentPageOptionalFields = [
  'firstName',
  'lastName',
  'businessName',
  'cardNumber',
  'cardName',
  'cardCVV',
  'email',
  'cardBillingAddress',
  'cardBillingAddress2',
  'cardBillingCity',
  'cardBillingState',
  'cardBillingZip',
  'cardBillingCountry',
  'echeckHolderName',
  'echeckFirstName',
  'echeckLastName',
  'echeckBillingAddress',
  'echeckBillingCity',
  'echeckBillingState',
  'echeckBillingZip',
  'echeckBillingCountry',
  'echeckBillingAddress2',
  'echeckAccountNumber',
  'echeckRoutingNumber',
  'echeckAccountConfirm',
  'echeckRoutingConfirm',
  'recurFrequency',
  'secondDayOfMonth',
  'endAmountField'
];

export const paymentPageFieldNameMap = {
  amount: 'Payment Amount',
  firstName: 'Client\'s First Name',
  lastName: 'Client \'s Last Name',
  businessName: 'Business Name',
  email: 'Email',
  cardName: 'Name on Card',
  cardNumber: 'Card Number',
  cardCVV: 'CVV',
  cardExpiration: 'Expiration Date',
  cardBillingAddress: 'Billing Address',
  cardBillingAddress2: 'Billing Address 2',
  cardBillingCity: 'Billing City',
  cardBillingState: 'Billing State',
  cardBillingZip: 'Billing Zip/Postal Code',
  cardBillingCountry: 'Billing Country',
  echeckAccountOwnerType: 'Account Owner Type',
  echeckAccountType: 'Account Type',
  echeckFirstName: 'Account Holder First Name',
  echeckLastName: 'Account Holder Last Name',
  echeckHolderName: 'Account Holder Name',
  echeckAccountNumber: 'Account Number',
  echeckRoutingNumber: 'Routing Number',
  echeckBillingAddress: 'Billing Address',
  echeckBillingAddress2: 'Billing Address 2',
  echeckBillingCity: 'Billing City',
  echeckBillingState: 'Billing State',
  echeckBillingZip: 'Billing Zip/Postal Code',
  echeckBillingCountry: 'Billing Country'
};

export const generalUrlParamToFieldMap = {
  amount: 'amountField',
  reference: 'reference',
  email: 'email',
  phone: 'phone',
  ['Invoice']: 'Invoice',
  'recur_frequency': 'recurFrequency'
};

export const cardUrlParamToFieldNameMap = {
  name: 'cardName',
  address1: 'cardBillingAddress',
  address2: 'cardBillingAddress2',
  city: 'cardBillingCity',
  state: 'cardBillingState',
  postal_code: 'cardBillingZip',
  country: 'cardBillingCountry'
};

export const echeckUrlParamToFieldNameMap = {
  name: 'echeckHolderName',
  address1: 'echeckBillingAddress',
  address2: 'echeckBillingAddress2',
  city: 'echeckBillingCity',
  state: 'echeckBillingState',
  postal_code: 'echeckBillingZip',
  country: 'echeckBillingCountry'
};

export const allowedURLBillingParams = ['name', 'address1', 'address2', 'city', 'state', 'postal_code', 'country'];
export const allowedURLCustomFieldParams = ['phone', 'Invoice'];
export const allowedURLParams = ['amount', 'reference', 'email', 'recur_frequency', ...allowedURLCustomFieldParams, ...allowedURLBillingParams];

export const swiperStates = {
  initial: {
    buttonText: 'Swipe Card',
    swipeText: '',
    isSwiping: false,
    isSwiped: false
  },
  waiting: {
    buttonText: 'Cancel',
    swipeText: 'Swipe now...',
    isSwiping: true,
    isSwiped: false
  },
  error: {
    buttonText: 'Cancel',
    swipeText: 'Swipe Error!',
    isSwiping: false,
    isSwiped: false
  },
  success: {
    buttonText: 'Swipe Card',
    swipeText: '',
    isSwiping: false,
    isSwiped: true
  }
};

export const paymentPageTabsText = {
  loan: 'Pay Later',
  card: 'Card',
  echeck: 'eCheck'
};

export const basicFrequencyOptions = [
  { text: 'One-Time Payment', value: 'NONE' },
  { text: 'Every Week', value: 'WEEKLY' },
  { text: 'Every Two Weeks', value: 'BI_WEEKLY' },
  { text: 'Every Month', value: 'MONTHLY' },
  { text: 'Every Two Months', value: 'TWO_MONTHS' },
  { text: 'Every Three Months', value: 'THREE_MONTHS' },
  { text: 'Every Six Months', value: 'SIX_MONTHS' },
  { text: 'Every Year', value: 'YEAR' }
];

export const fullFrequencyOptions = [
  { text: 'No Schedule', value: 'NONE' },
  { text: 'Every Week', value: 'WEEKLY' },
  { text: 'Every Two Weeks', value: 'BI_WEEKLY' },
  { text: 'Every Month', value: 'MONTHLY' },
  { text: 'Twice a Month', value: 'BI_MONTHLY' },
  { text: 'Every Two Months', value: 'TWO_MONTHS' },
  { text: 'Every Three Months', value: 'THREE_MONTHS' },
  { text: 'Every Six Months', value: 'SIX_MONTHS' },
  { text: 'Every Year', value: 'YEAR' }
];
