import React from 'react';
import {func, string} from 'prop-types';
import { APButton, APModal } from 'affinipay-ui-library';
import {ClientCreditTextList} from '../helpers.js';
import './style.scss';

const clientCreditModalSell = [
  'You get paid 100% up front, improving your cash flow in comparison to a traditional scheduled payment plan and mitigating the rist of non-payment',
  'Reduce the amount of time spent following up on client payments',
  'Expand your client base to whose who wish to have additional payment options',
  'Minimize your outstanding accounts receivable and the need for discounted payment options'
];

const clientCreditModalBehavior = (baseRate) => [
  "Your clients will see an option to \"Pay Later\" on your payment page(s) and selecting this option will take them to an online application. To apply, your clients will be asked to enter a few pieces of information and they'll get a real-time decision",
  'If approved, Pay Later/AffiniPay will send 100% of the funds directly to you',
  'The funds settle to your bank account via ACH transfer within 3-5 business days of each transaction',
  'You will be charged ' + baseRate + ' of the transaction value. You may not pass on this cost onto the client'
];

const ClientCreditCheckboxModal = ({closeModal, brandTitle, brandLink, supportPhone, baseRate}) => (
  <APModal isActiveInitial={true}
    onClose={closeModal}
    className='client-credit-about-modal'
    headerText='Learn More About Pay Later'
  >
    <p>{`Pay Later is a legal fee lending solution exclusively available through ${brandTitle}. With Pay Later, you recieve the full invoiced amount at the start of an engagement while still being able to offer clients the option to pay a loan for legal fees in automated installments.`}</p>
    <p className="client-credit-heading">Why is Pay Later a good option for you and your firm?</p>
    <ClientCreditTextList
      className="client-credit-learn-more"
      textArray={clientCreditModalSell}
      textId="client-credit-modal-sell"
    />
    <p className="client-credit-heading">How does Pay Later work?</p>
    <ClientCreditTextList
      className="client-credit-learn-more"
      textArray={clientCreditModalBehavior(baseRate)}
      textId="client-credit-modal-function"
    />
    <p className="client-credit-heading">For more information, click <a href={brandLink} target="_blank" rel="noreferrer">this link</a> or call support at <a href={`tel:${supportPhone}`}>{supportPhone}</a></p>
    <div className="client-credit-learn-more-footer">
      <APButton
        onClick={closeModal}
        data-action="click"
        data-label="Cancel"
        type="button"
      >
        Close
      </APButton>
    </div>
  </APModal>
);

ClientCreditCheckboxModal.propTypes = {
  closeModal: func,
  brandTitle: string.isRequired,
  brandLink: string.isRequired,
  supportPhone: string.isRequired,
  baseRate: string.isRequired
};

export default ClientCreditCheckboxModal;
