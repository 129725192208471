import React from 'react';

const MobileScanIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="49" viewBox="0 0 31 49">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path d="M27 5H15v19.331l.015.01c1.038.764 1.307 2.205.623 3.297L15 28.84V29h12V5zM21 30c-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1zM12.834 35.192l.125 1.74c.123 1.703-.573 3.396-1.862 4.528l-1.933 1.697c-.16.141-.36.21-.558.21-.233 0-.465-.095-.631-.282-.309-.346-.276-.875.073-1.181l1.933-1.698c.898-.789 1.383-1.968 1.298-3.156l-.185-2.565c-.108-1.509-.093-3.106.741-4.57l2.356-4.37c.233-.353.147-.824-.196-1.073-.337-.245-.806-.19-1.076.124l-6.254 8.629c-.272.375-.798.46-1.176.19-.378-.27-.464-.793-.192-1.168l5.262-7.257V22L3.9 29.849c-.54.637-.877 1.411-.973 2.24l-.811 6.985c-.518 2.528.736 5.116 3.047 6.293 2.299 1.17 5.127.684 6.895-1.182l2.28-3.055c.427-.572.662-1.279.662-1.99V35.2h-2.017c-.05 0-.1-.005-.149-.008z" transform="translate(-619 -165) translate(487 80) translate(35 73) translate(97 12)"/>
              <path d="M28.29 2H12.71c-.391 0-.71.324-.71.723v20.905c.545-.335 1.201-.435 1.816-.277V4.223c0-.228.182-.412.406-.412h12.556c.224 0 .406.184.406.412v24.538c0 .228-.182.412-.406.412H14.222c-.036 0-.07-.006-.103-.015l-1.098 2.089c-.456.813-.563 1.757-.55 2.708.075.027.155.045.24.045h15.578c.392 0 .711-.324.711-.723V2.723c0-.399-.319-.723-.71-.723zM20.5 32.949c-.85 0-1.54-.703-1.54-1.567s.69-1.567 1.54-1.567c.85 0 1.54.703 1.54 1.567s-.69 1.567-1.54 1.567z" transform="translate(-619 -165) translate(487 80) translate(35 73) translate(97 12)"/>
              <path fill="#FFF" fillRule="nonzero" d="M28.603 0H12.588c-1.322 0-2.397 1.06-2.397 2.364v17.314c-.02.018-.04.034-.058.055L2.33 28.866c-.747.873-1.211 1.936-1.344 3.071L.2 38.694c-.133.66-.168 1.324-.117 1.974-.295 1.979.213 3.99 1.419 5.568 1.212 1.587 3.003 2.558 5.042 2.733.238.02.475.031.71.031.954 0 1.872-.169 2.688-.497.213-.086.316-.325.229-.536-.087-.21-.33-.31-.544-.225-.894.36-1.934.502-3.01.409-1.801-.156-3.381-1.011-4.45-2.41-.386-.506-.69-1.063-.911-1.65.69 1.054 1.655 1.94 2.838 2.539 1.068.54 2.225.801 3.375.801 2.023 0 4.021-.811 5.458-2.34l2.342-3.107c.633-.843.982-1.885.982-2.933v-3.87h12.353c1.322 0 2.397-1.06 2.397-2.364V2.364C31 1.061 29.925 0 28.603 0zm-14.02 39.05c0 .7-.232 1.394-.654 1.955l-2.256 3.001c-1.748 1.832-4.546 2.311-6.82 1.161-2.286-1.156-3.525-3.698-3.014-6.181l.803-6.861c.095-.814.428-1.574.962-2.2l6.587-7.71v2.938l-5.205 7.128c-.268.368-.184.882.19 1.147.373.265.894.181 1.163-.187l6.186-8.475c.268-.308.731-.362 1.064-.122.34.244.425.707.195 1.053l-2.331 4.294c-.825 1.436-.84 3.006-.733 4.488l.183 2.52c.084 1.166-.396 2.324-1.284 3.099l-1.912 1.667c-.345.301-.377.82-.072 1.16.164.184.394.278.624.278.196 0 .393-.068.552-.206l1.912-1.668c1.275-1.111 1.963-2.774 1.842-4.448l-.124-1.708c.05.003.098.008.148.008h1.995v3.87zm-.025-11.302l.642-1.184c.689-1.075.418-2.494-.627-3.247l-.015-.01V4.27h12.075v23.635H14.558v-.157zm14.776 5.07c0 .397-.328.72-.731.72H12.588c-.086 0-.169-.018-.246-.045-.014-.948.097-1.889.565-2.699l1.128-2.082c.034.009.07.015.106.015H27.05c.23 0 .416-.184.416-.41V3.858c0-.227-.186-.41-.416-.41H14.14c-.23 0-.416.183-.416.41v19.065c-.633-.158-1.307-.057-1.867.276V2.364c0-.397.327-.72.73-.72h16.015c.403 0 .73.323.73.72v30.453z" transform="translate(-619 -165) translate(487 80) translate(35 73) translate(97 12)"/>
              <path fill="#FFF" fillRule="nonzero" d="M20.5 29c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm0 2.21c-.392 0-.71-.318-.71-.71 0-.392.318-.71.71-.71.392 0 .71.318.71.71 0 .392-.318.71-.71.71z" transform="translate(-619 -165) translate(487 80) translate(35 73) translate(97 12)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MobileScanIcon;