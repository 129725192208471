
import React from 'react';
import { shallow} from 'enzyme';
import APTooltipRails from './index';

describe('APTooltipRails Component', () => {
  it('shallow snapshot render', () => {
    const wrapper = shallow(<APTooltipRails />);
    expect(wrapper).toMatchSnapshot();
  });
});
