/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

export default class GraphVolume extends React.Component {

  componentDidMount() {
    const activeRange = this.props.activeDateRange;
    const graphDataPoints = this.props.data.amounts;
    const daysInMonth = this.props.data.dates;
    const volumeGraphOptions = {
      chart: {
        type: 'column',
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        renderTo: 'transaction_volume_chart'
      },
      title: {text: 'Total Payment Volume'},
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b/%Y '
        },
        categories: daysInMonth,
        title: {text: null},
        labels: {
          style: {
            color: '#777777'
          }
        }
      },

      yAxis: {
        min: 0,
        title: {text: null},
        useHTML: false,
        labels: {
          style: {
            color: '#777777'
          },
          formatter: function () {
            let tickValue = this.value / 100;
            let maxValue = Math.ceil(this.axis.max / 100);
            if (maxValue < 500000) {
              return `$${ AffiniPay.DashboardUtils.numberFormat(tickValue, 0, ',', '.') }`;
            }
            if (maxValue >= 500000 && maxValue < 1000000) {
              return `$${ tickValue / 1000 }K`;
            }
            if (maxValue >= 1000000) {
              return `$${ tickValue / 1000000 }M`;
            }
          }
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: '#0087E8',
          cursor: 'pointer',
          states: {
            hover: {
              color: '#143156',
              halo: {
                attributes: {fill: 'none'},
                size: 0,
                opacity: 0
              }
            }
          }
        }
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        borderColor: '#959DA7',
        align: 'left',
        useHTML: true,
        headerFormat: '',
        pointFormatter: function() {
          this.activeDateRange = activeRange;
          return setVolumeToolTipContent(this);
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          showInLegend: false,
          data: graphDataPoints,
          minPointLength: 2
        }
      ]
    };
    this.VolumeGraph = $('.dashboard-chart-volume').highcharts(volumeGraphOptions);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const activeRange = props.activeDateRange;
    let chart = this.VolumeGraph.highcharts();
    chart.xAxis[0].update({ categories: props.data.dates });
    chart.series[0].destroy();
    chart.addSeries({
      showInLegend: false,
      data: props.data.amounts,
      minPointLength: 2,
      tooltip: {
        pointFormatter: function() {
          this.activeDateRange = activeRange;
          return setVolumeToolTipContent(this);
        }
      }
    });
  }

  render() {
    return (
      // eslint-disable-next-line react/no-string-refs
      <div ref="chart_volume" className="dashboard-chart-volume"></div>
    );
  }
}

const setVolumeToolTipContent = function(data) {
  let tooltipContent, tt_date_label, monthOfYear, yearLabel, tt_data, tt_month, tt_day;
  const { numberFormat } = AffiniPay.DashboardUtils;
  switch (data.activeDateRange) {
  case 'ninety':
    tt_date_label = data.category;
    break;

  case 'yearly':
    monthOfYear = moment().month(data.category.split(' ')[0]);
    yearLabel = ((monthOfYear > moment() || data.index === 0) ? moment().subtract(1, 'years') : moment());
    tt_date_label = `${ monthOfYear.format('MMMM') } ${ yearLabel.format('YYYY') }`;
    break;

  default:
    tt_data = data.category.split('/');
    tt_month = tt_data[0];
    tt_day = tt_data[1];

    monthOfYear = moment().month(tt_month - 1);
    yearLabel = (monthOfYear > moment() ? moment().subtract(1, 'years') : moment());
    tt_date_label = `${ monthOfYear.format('MMMM') } ${ tt_day }, ${yearLabel.format('YYYY')}`;
    break;
  }

  tooltipContent = `<span class="column_tooltip">
                      <span class="column_date">${ tt_date_label }</span>
                      <span class="column_amount numeric">$${ numberFormat(data.y / 100, 2) }</span>
                    </span>`;

  return tooltipContent;
};

GraphVolume.propTypes = {
  activeDateRange: PropTypes.string,
  data: PropTypes.object
};
