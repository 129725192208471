import React from 'react';
import {bool} from 'prop-types';

const AlertRibbon = ({
  accountInactive,
  accountSuspended,
  testMode
}) => {
  if (!accountInactive && !accountSuspended && !testMode) {
    return null;
  }
  let ribbonText = '';
  if (accountInactive) {
    ribbonText = 'ACCOUNT INACTIVE';
  } else if (accountSuspended) {
    ribbonText = 'ACCOUNT SUSPENDED';
  } else if (testMode) {
    ribbonText =
      <div><strong>YOU ARE IN TEST MODE</strong> <span className="hidden-xs">Transactions created in test mode do not affect your account.</span></div>;
  }
  return (
    <div className="test-mode-alert">
      {ribbonText}
    </div>
  );
};

AlertRibbon.propTypes = {
  accountInactive: bool,
  accountSuspended: bool,
  testMode: bool
};

export default AlertRibbon;
