import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import './style.scss';

export default class Terms extends Component {
  errorAdjustment = () => { this.props.hasErrors ? ' active' : ''; };
  brandedTerms = () => {
    switch (this.props.brandIdentifier.toLowerCase()) {
    case 'clientpay':
      return 'https://www.clientpay.com/terms/service-agreement/';
    case 'lawpay':
      return 'https://www.lawpay.com/terms/service-agreement/';
    case 'cpacharge':
      return 'https://www.cpacharge.com/terms/service-agreement/';
    default:
      return 'https://www.affinipay.com/terms/service-agreement/';
    }
  };


  render() {
    return (
      <div data-jest-echeck-terms>
        <h1 className='noTopMargin'>eCheck Terms & Conditions</h1>
        <div className={`termsWrapper errors ${this.errorAdjustment()}`}>
          <iframe
            id="echeckTermsDoc"
            title="Affinipay Services Agreement"
            width="100%"
            height="100%"
            src="https://www.affinipay.com/terms/stripped-service-agreement/">
          </iframe>
        </div>

        <div className='row buttonContainer'>
          <div className="col-xs-12 col-md-5 col-lg-8 noPadding">
            <APButton
              text="I Agree"
              onClick={this.props.onAccept}
            >
              I Agree
            </APButton>
            <APButton
              className="ap-secondary-button terms-secondary-button"
              text="I Do Not Agree"
              onClick={this.props.onDecline}
            >
              I Do Not Agree
            </APButton>
          </div>

          <div className="xs-hidden col-sm-3 col-md-7 col-lg-4 text-right">
            <div className="downloadWrapper">
              <a
                href={this.brandedTerms()}
                className="hidden-xs hidden-sm downloadclassNames"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className='downloadIcon'
                  src={this.props.relocateIcon}
                  alt="Download the eCheck Terms & Conditions PDF"
                />{' '}
                <span className='downloadText'>Open in a new tab</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    relocateIcon: PropTypes.string.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    brandIdentifier: PropTypes.string.isRequired
  };
}
