const date = new Date();

export const formatDate = date => date ? new Intl.DateTimeFormat('en-US').format(date) : '';

export const getCurrentMonthString = () => {
  const firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return `${formatDate(firstDayCurrentMonth)} - ${formatDate(lastDayCurrentMonth)}`;
};

export const getNextMonthString = () => {
  const firstDayNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const lastDayNextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);

  return `${formatDate(firstDayNextMonth)} - ${formatDate(lastDayNextMonth)}`;
};

export const getPreviousMonthString = () => {
  const firstDayPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const lastDayPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);

  return `${formatDate(firstDayPreviousMonth)} - ${formatDate(lastDayPreviousMonth)}`;
};

export const verifyDate = date => {
  if (!date) return undefined;
  const dateObj = new Date(date);
  const dateToMilliseconds = Number(dateObj);

  if (!isNaN(dateToMilliseconds)) {
    const day = Number(dateObj.getDate());
    const resultingday = day < 10 ? '0' + day : day;
    const month = Number(dateObj.getMonth() + 1);
    const resultingMonth = month < 10 ? '0' + month : month;
    const year = dateObj.getFullYear();
    const resultingYear = year > 9999 ? new Date().getFullYear() : year;
    const resultDate = `${resultingMonth}/${resultingday}/${resultingYear}`;

    return resultDate;
  }

  return undefined;
};

export const getCompiledParentClass = ({
  baseClass,
  disabled,
  labelError,
  valueLengthValidation,
  helpText,
  isFloatingLabel,
  isFocused,
  label,
  parentClass,
  required,
  value
}) => {
  const classAttributes = {
    floatLabel: isFloatingLabel ? 'float-label' : false,
    focusedFloat: isFloatingLabel && (value || isFocused) ? 'focused-float' : false,
    label: label ? 'labeled' : false,
    disabled: disabled ? 'disabled' : false,
    helpText: helpText ? 'help-text' : false,
    error: (labelError || valueLengthValidation?.error) && !disabled ? 'invalid' : false,
    warn: !labelError && !disabled && valueLengthValidation?.warn ? 'warn' : false,
    required: required && !disabled ? 'required' : false,
    parentClass
  };

  const buildClassString = (classString, key) => {
    if (classAttributes[key]) classString = `${classString} ${classAttributes[key]}`;
    return classString;
  };

  const classKeys = Object.keys(classAttributes);
  return classKeys.reduce(buildClassString, baseClass);
};
