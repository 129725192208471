//not exposed in production
import React from 'react';
import PropTypes from 'prop-types';
import { APCheckbox, APSelect, APTags } from 'affinipay-ui-library';

import {
  countries as cardCountry,
  countries as echeckCountry
} from '../../../lib/constants';

import {
  manualPolicyData as manualData,
  cardPolicyData as cardData,
  echeckPolicyData as echeckData,
  holdPeriodDropdown as holdPeriod,
  manualCvvCheck,
  manualAvsCheck,
  cardCvvCheck,
  cardAvsCheck
} from './PlanData';

const PolicySection = ({
  formState,
  setFormState,
  cardsSection,
  echeckSection
}) => {

  const handleInputChange = (e) => {
    const name = e.target.name,
      value= e.target.value,
      newState = {[name]: value};
    setFormState({...formState, ...newState});
  };

  const manualCheckboxData = manualData.map((el, k) => {
      return (
        <div key={k} className="col-md-6">
          <APCheckbox
            id={el.id}
            text={el.text}
            value={el.value}
            name={el.name}
            checked={el.checked}
            disabled={el.disabled}
            formState={formState}
            setFormState={setFormState}
            onChange={handleInputChange}
          />
        </div>
      );
    }),
    cardCheckBoxData = cardData.map((el, k) => {
      return (
        <div key={k} className="col-md-6">
          <APCheckbox
            id={el.id}
            text={el.text}
            value={el.value}
            name={el.name}
            disabled={el.disabled}
            checked={el.checked}
            formState={formState}
            setFormState={setFormState}
            onChange={handleInputChange}
          />
        </div>
      );
    }), 
    echeckCheckBoxData = echeckData.map((el, k) => {
      return (
        <div key={k} className="col-md-6">
          <APCheckbox
            id={el.id}
            text={el.text}
            value={el.value}
            name={el.name}
            disabled={el.disabled}
            checked={el.checked}
            formState={formState}
            setFormState={setFormState}
            onChange={handleInputChange}
          />
        </div>
      );
    });

  const getCardsContent = () => (
    <div className="row">
      <div className='col-md-12'>
        <div className="col-md-4">
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='uppercase'>Manual Entry Policies</h4>
              <APSelect
                label='CVV Check'
                options={manualCvvCheck}
                name='manualCvvCheck'
                onChange={handleInputChange}
                value={formState['manualCvvCheck']}
              />
              <APSelect
                label='AVS Check'
                options={manualAvsCheck}
                name='manualAvsCheck'
                onChange={handleInputChange}
                value={formState['manualAvsCheck']}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 checkbox-section">
              <span className='required-fields-label'>Required Payment Fields</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className="row">
                {manualCheckboxData}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='uppercase'>Card Present Policies</h4>
              <APSelect
                label='CVV Check'
                options={cardCvvCheck}
                name='cardCvvCheck'
                onChange={handleInputChange}
                value={formState['cardCvvCheck']}
              />
              <APSelect
                label='AVS Check'
                options={cardAvsCheck}
                name='cardAvsCheck'
                onChange={handleInputChange}
                value={formState['cardAvsCheck']}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 checkbox-section">
              <span className='required-fields-label'>Required Payment Fields</span>
            </div>
          </div>
          <div className="row">
            {cardCheckBoxData}
          </div>
        </div>
        <div className='col-md-4'>
          <h4 className='uppercase hidden-element' aria-hidden='true'>Countries Allowed</h4>
          <span className='required-fields-label hidden-md-down'>Countries Allowed</span>
          <APTags
            selectId='countriesSelect'
            propWhitelist={cardCountry}
            placeholderText='...add countries'
            enforceWhitelist={true}
            formState={formState}
            setFormState={setFormState}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );

  const getEcheckContent = () => (
    <div className="row">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-md-12">
            <h4 className='uppercase'>Policies</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 checkbox-section no-top-margin">
            <span className='required-fields-label'>Required Payment Fields</span>
          </div>
          <div className="col-md-7 checkbox-section no-top-margin">
            <span className='required-fields-label hidden-md-down'>Countries Allowed</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="row">
              {echeckCheckBoxData}
            </div>
          </div>
          <div className="col-md-7">
            <APTags
              selectId='countriesSelect'
              propWhitelist={echeckCountry}
              placeholderText='...add countries'
              enforceWhitelist={true}
              formState={formState}
              setFormState={setFormState}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <APSelect
              label='Hold Period'
              options={holdPeriod}
              name='holdPeriod'
              onChange={handleInputChange}
              value={formState['holdPeriod']}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <section className='policy-section'>
      { cardsSection && getCardsContent() }
      { echeckSection && getEcheckContent() }
    </section>
  );
};

PolicySection.propTypes = {
  echeckCountry: PropTypes.arrayOf(PropTypes.object),
  cardCountry: PropTypes.arrayOf(PropTypes.object),
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  onInputValidation: PropTypes.func,
  cardsSection: PropTypes.bool,
  echeckSection: PropTypes.bool
};

export default PolicySection;