export const merchant = {
  'id': 3,
  'site_id': 1,
  'owner_id': 3,
  'gateway_id': 'sqwYJmBeQzuTRSzefBhWGQ',
  'path': 'acme-inc',
  'name': 'Acme Inc.',
  'gw_name': null,
  'phone': '9789879878',
  'address': '123 Main Street',
  'additional_address': 'Suite 300',
  'city': 'Austin',
  'state': 'TX',
  'postal_code': '02360',
  'country': 'US',
  'status': null,
  'auth_user': 'm_ucAqMziTQOKtSGeMLSDRsw',
  'api_allowed_ip_address_ranges': '',
  'logo_file_name': null,
  'logo_content_type': null,
  'logo_file_size': null,
  'logo_updated_at': null,
  'email_success_to': 'owner@foo.com',
  'support_email': 'owner@foo.com',
  'website': 'http://www.foobar.com',
  'email_failure_to': 'owner@foo.com',
  'current_state': null,
  'created_at': '2021-08-10T13:42:50.000-05:00',
  'updated_at': '2021-08-11T14:57:32.000-05:00',
  'deleted_at': null,
  'timezone': 'Eastern Time (US & Canada)',
  'support_phone': '9789879878',
  'live_events_urls': '',
  'test_events_urls': '',
  'reference_required': 1,
  'reference_label': 'Reference',
  'has_card_reader': true,
  'is_trial': false,
  'recurring_enabled': true,
  'admin_id': null,
  'partner_id': 2,
  'tax_number': '987698769',
  'delta': true,
  'merchant_application_id': null,
  'secure_token': 'pDIkPyq1AE3W2FByqEJRf_vt92I9Wgo3EEauDmFHVAxoi1RXFWfWTjOJnvxx-_6U',
  'portal_url': null,
  'root': null,
  'tenant_id': 1,
  'invoicing_address_id': 'addr_rNvQ25xFZgzSqLLfGu05s',
  'invoicing_email_address_id': 'eml_g8SBaZBNAmdjla8NBmqqz',
  'invoicing_phone_number_id': 'phn_pSBcMd8BFUjmJOV5a3OOz',
  'email': '',
  'phone_extension': '',
  'default_currency': '',
  'invoicing_enabled': false,
  'live_merchant_accounts_count': 0,
  'live_ach_accounts_count': 2,
  'invoice_sync_last_success_at': null,
  'invoice_sync_last_failure_at': null,
  'disclaimer_html': '<h3>Lorem Ipsum<br></h3>\n<p>\n  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sapien lectus, hendrerit et faucibus sed, semper pretium felis.\n  Aliquam vestibulum massa vitae nunc eleifend tincidunt. Nullam tincidunt volutpat lectus vel efficitur. Aliquam lobortis sit amet leo lobortis bibendum.\n  Sed leo turpis, congue id fringilla ac, aliquam non turpis. Nulla dictum non lacus et tincidunt.\n  Morbi malesuada cursus lacus, vel tristique mi lacinia a. Donec lacinia nisi urna, at luctus purus interdum id.\n  Duis blandit diam sit amet orci viverra mollis. Vestibulum ornare dui id metus laoreet, in fermentum ligula rhoncus.\n  Nam massa erat, ornare eget erat a, maximus dapibus risus. Vestibulum non velit est. Praesent vel hendrerit metus, sit amet aliquam metus.\n  Mauris purus turpis, venenatis ac mauris posuere, elementum accumsan justo.\n</p>\n',
  'quick_bill_subject_line': 'Request for Payment',
  'quick_bill_message_body': 'For your convenience, our firm accepts secure, online payments through . Thank you for the courtesy of your prompt payment.',
  'suppress_pci_notice': false,
  'mailing_address1': '123 Main Street',
  'mailing_address2': 'Suite 300',
  'mailing_city': 'Austin',
  'mailing_state': 'TX',
  'mailing_postal_code': '02360',
  'mailing_country': 'US',
  'billing_account_id': 'bil_pyf2cc2HhFWqYnu1s9PZC',
  'billing_account_suspended_at': null,
  'subscription_id': 'sub_nrdaus0i0GU12U0CMdx6k',
  'billing_payment_method_id': null,
  'primary_billing_bank_account_id': null,
  'plan_id': null,
  'optin_echeck_at': null,
  'optin_echeck_ip': null,
  'optin_echeck_user_id': null,
  'encrypted_tax_number': 'juSYQhivHeWB3X1FxNTW71OeT/vygfT93A==\n',
  'encrypted_tax_number_iv': 'lAQ7Rwuc9EFiknlS\n',
  'encryption_key_version': '1',
  'legacy_tax_number': '987698769',
  'captcha_enabled': true,
  'captcha_v2_fallback_enabled': true,
  'captcha_threshold': '0.5',
  'recurring_notification_days': 0,
  'recurring_notification_days_opt_in_at': null,
  'recurring_notification_days_opt_out_at': null,
  'platform': 'legacy',
  'org_id': 'org_ZSURQQCl2NvuaRWOTKKs5',
  'mfa_required_for_all_users': false,
  'aggregate_id': null,
  'aggregate_version': null,
  'training_status': 'trained'
};