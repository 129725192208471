import React from 'react';
import './style.scss';
import { APButton } from 'affinipay-ui-library';
import { safeSendGA } from '../../lib/utils';
import { PropTypes } from 'prop-types';

const LawpayPlusButtonComponent = ({link, text}) => {
  const handleLPPButton = () => {
    safeSendGA(`${text} Button`, `${text} button Clicked`);
    window.open(link, '_self');
  };

  return (
    <div className='lawpay-plus-container' data-testid='lpp-button'>
      <APButton
        id="lawpay-plus-button"
        ariaLabel={text}
        className="lpp-btn ap-secondary-button"
        onClick={handleLPPButton}
      >
        {text.toUpperCase()}
      </APButton>
    </div>
  );
};

LawpayPlusButtonComponent.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string
};

export default LawpayPlusButtonComponent;
