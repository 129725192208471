import React from 'react';
import {shallow, mount} from 'enzyme';
import OwnersList from './index';

describe('OwnersList', () => {

  it('shallow snapshot', () => {
    const component = shallow(<OwnersList {...getMockProps('withOwners')}/>);
    expect(component).toMatchSnapshot();
  });

  it('mount to test register form', () => {
    global.confirm = () => true;
    const component = mount(<OwnersList {...getMockProps('withOwners')}/>);
    component.find('.remove-btn').at(0).prop('onClick')({preventDefault:()=>{}});
    component.update();
    global.confirm = () => false;
    component.find('.remove-btn').at(0).prop('onClick')({preventDefault:()=>{}});
    component.update();
    expect(component.containsMatchingElement(<div className="col-xs-8">John Doe, Owner</div>)).toBeTruthy();
  });

  it('mount to test admin form', () => {
    global.confirm = () => true;
    const component = mount(<OwnersList {...getMockProps('withOwnersAdmin')}/>);
    component.find('.remove-btn').at(0).prop('onClick')({preventDefault:()=>{}});
    component.update();
    global.confirm = () => false;
    component.find('.remove-btn').at(0).prop('onClick')({preventDefault:()=>{}});
    component.update();
    expect(component.containsMatchingElement(<h4>John Doe, Owner</h4>)).toBeTruthy();
  });

});

const getMockProps = (type) => {
  switch (type) {
  case 'withOwnersAdmin':
    return {
      owners: [{
        'id': 31,
        'first_name': 'John',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Owner',
        'social_security_number_last_4': '8989',
        'date_of_birth': '08/08/1994',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AA',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-20T20:03:12.000Z',
        'updated_at': '2020-04-21T21:12:52.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/31'
      }, {
        'id': 32,
        'first_name': 'John2312',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Manager',
        'social_security_number_last_4': '3123',
        'date_of_birth': '11/11/1990',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AZ',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-21T21:09:54.000Z',
        'updated_at': '2020-04-21T21:09:54.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/32'
      }],
      handleRemove: jest.fn(),
      handleEdit: jest.fn(),
      isAdmin: true
    };
  case 'withOwners':
    return {
      owners: [{
        'id': 31,
        'first_name': 'John',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Owner',
        'social_security_number_last_4': '8989',
        'date_of_birth': '08/08/1994',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AA',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-20T20:03:12.000Z',
        'updated_at': '2020-04-21T21:12:52.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/31'
      }, {
        'id': 32,
        'first_name': 'John2312',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Manager',
        'social_security_number_last_4': '3123',
        'date_of_birth': '11/11/1990',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AZ',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-21T21:09:54.000Z',
        'updated_at': '2020-04-21T21:09:54.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/32'
      }],
      handleRemove: jest.fn(),
      handleEdit: jest.fn()
    };
  default:
    return;
  }
};