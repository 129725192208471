import React from 'react';
// eslint-disable-next-line no-unused-vars
import { shallow, mount} from 'enzyme';
import Surcharge from './index';
// eslint-disable-next-line no-unused-vars
import { act } from 'react-dom/test-utils';

describe('Admin Surcharge Component', () => {
  it('shallow snapshot render', () => {
    const wrapper = shallow(<Surcharge />);
    expect(wrapper).toMatchSnapshot();
  });
  it('mount to test form checkbox toggle', () => {
    const component = mount(<Surcharge />);
    act(() => {
      component.find('APCheckbox').at(0).prop('onChange')({target:{checked:true}});
    });
    component.update();
    const actual = component.find('.checkbox-label').at(0).text();
    expect(actual).toEqual('Surcharge enabled');
  });

  it('mount to test radio switch', () => {
    const component = mount(<Surcharge surcharge_enabled={true}/>);
    act(() => {
      component.find('APRadioButton').at(1).prop('onChange')({target:{value:'flat'}});
    });
    component.update();
    const actual = component.find('.money').at(0);
    expect(actual).toBeTruthy();
  });

  it('mount to test form controller input', () => {
    const component = mount(<Surcharge surcharge_enabled={true}/>);
    act(() => {
      component.find('.label-input').at(1).prop('onChange')({target:{name:'surcharge_label',value:'label'}});
    });
    component.update();
    const actual = component.find('.label-input').at(1).prop('value');
    expect(actual).toEqual('label');
  });
});
