import React from 'react';
import {string} from 'prop-types';

export const Expiring = ({dueBy}) => (
  <span
    className="pci-banner-message"
    data-testid="pci-banner-expiring"
  >
    Your PCI-compliance questionnaire is due by <strong>{dueBy}</strong>.
  </span>
);

export const ExpiresSoon = ({dueBy}) => (
  <span
    className="pci-banner-message"
    data-testid="pci-banner-expires-soon"
  >
    PCI-compliance questionnaire due by <strong>{dueBy}</strong>.
  </span>
);

export const Expired = () => (
  <span
    className="pci-banner-message"
    data-testid="pci-banner-expired"
  >
    Your PCI-compliance questionnaire is <strong>now overdue.</strong>
  </span>
);


ExpiresSoon.propTypes = {
  dueBy: string
};

Expiring.propTypes = {
  dueBy: string
};
