import { APButton, APFormMessageBox } from 'affinipay-ui-library';
import { array, bool, func, number, object, string } from 'prop-types';
import React from 'react';
import { DineroCentsFormat } from '../../lib/monetaryUtils';

const SubmitButtonComponent = ({
  currency,
  formErrors,
  isSubmitting,
  submitForm,
  swiperState,
  centsChargeTotal,
  paymentType,
  disabled
}) => (
  <>
    {formErrors.length > 0 && (
      <APFormMessageBox
        type="error"
        header={formErrors.length > 1 ? 'Please fix the following and try to submit your payment again:' : null}
        messages={formErrors} />
    )}
    <div className="row">
      <div className="col-12 text-center text-md-right">
        <APButton
          className="ap-primary-button submitButton"
          disabled={isSubmitting || swiperState.isSwiping || disabled}
          onClick={submitForm}
          dataset={{ 'data-testid': `submit-btn-${paymentType}` }}
        >
          {isSubmitting ? 'Submitting Payment' : `Pay ${DineroCentsFormat(centsChargeTotal, currency)}`}
        </APButton>
      </div>
    </div>
  </>
);

SubmitButtonComponent.propTypes = {
  currency: string,
  formErrors: array,
  isSubmitting: bool,
  submitForm: func,
  swiperState: object,
  centsChargeTotal: number,
  paymentType: string,
  disabled: bool
};

export default SubmitButtonComponent;
