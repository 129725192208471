import React, { useState } from 'react';
import { number, string, func } from 'prop-types';
import { APButton, APAlertModal } from 'affinipay-ui-library';
import notify from 'lib/notify';
import client from 'lib/ajax';

const DeleteBeneficialOwnerButton = ({
  merchantId,
  beneficialOwnerId,
  beneficialOwnerName,
  beneficialOwnerSurname,
  setCurrentLegalEntity
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);


  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
  };

  const deleteBeneficialOwner = async () => {
    try {
      setIsSaving(true);
      const resp = await client.destroy(`/admin/merchants/${merchantId}/beneficial_owners/${beneficialOwnerId}`);
      if (resp.ok) {
        setCurrentLegalEntity(previousLegalEntity => ({
          ...previousLegalEntity,
          attributes: {
            ...previousLegalEntity.attributes,
            beneficial_owners: (previousLegalEntity?.attributes?.beneficial_owners || []).filter((bo) => { return bo.id !== beneficialOwnerId; })
          }
        }));
        notify.success('Benefical Owner successfully deleted');
      } else {
        const jsonResponse = await resp.json();
        notify.error(jsonResponse?.error || 'Unexpected error occurred when deleting beneficial owner', [], false);
      }
    }
    catch (e) {
      notify.error('There was an error while trying to delete the beneficial owner. Please refresh and try again.', [], false);
    }
    toggleConfirmationModal();
    setIsSaving(false);
  };

  return (
    <>
      <APButton className='ap-negative-button delete-benefecial-owner-button' onClick={toggleConfirmationModal}>Delete</APButton>

      <APAlertModal
        className='delete-beneficial-owner-confirmation-modal'
        isActiveInitial={isConfirmationModalOpen}
        headerText='Delete Beneficial Owner?'
        bodyText={`Are you sure you want to delete beneficial owner ${beneficialOwnerName} ${beneficialOwnerSurname}?`}
        onClose={toggleConfirmationModal}
        type='error'
        footer={
          <div className='delete-beneficial-owner-confirmation-modal-footer'>
            <APButton
              onClick={toggleConfirmationModal}
              className='ap-ghost-alert-button'
              disabled={isSaving}
            >
              Cancel
            </APButton>
            <APButton
              onClick={deleteBeneficialOwner}
              className='ap-critical-button delete-button'
              disabled={isSaving}
            >
              Confirm
            </APButton>
          </div>
        }
      />
    </>
  );
};

export default DeleteBeneficialOwnerButton;

DeleteBeneficialOwnerButton.propTypes = {
  merchantId: number,
  beneficialOwnerId: number,
  beneficialOwnerName: string,
  beneficialOwnerSurname: string,
  setCurrentLegalEntity: func
};
