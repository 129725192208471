import React from 'react';
import {shallow, mount} from 'enzyme';
import AddOwnerForm from './index';
import {act} from 'react-dom/test-utils';
import * as utils from '../../lib/utils';
import { APInputText } from 'affinipay-ui-library';

describe('AddOwnerForm', () => {

  it('shallow snapshot', () => {
    const component = shallow(<AddOwnerForm/>);
    expect(component).toMatchSnapshot();
  });

  it('mount to test form pre fill with owners', () => {
    const component = mount(<AddOwnerForm {...getMockProps('withOwners')}/>);
    expect(component.find(APInputText).at(0).prop('value')).toEqual('New Owner');
    component.unmount();
  });

  it('mount to test with errors', () => {
    const component = mount(<AddOwnerForm {...getMockProps('withErrors')}/>);
    expect(component.find('.alert')).toBeTruthy();
    component.unmount();
  });

  it('mount to test form prefill with owners', () => {
    const component = mount(<AddOwnerForm {...getMockProps('withoutOwners')}/>);
    component.find('.submit-btn').at(0).prop('onClick')();
    component.unmount();
  });

  it('mount to test field interaction', async () => {
 
    const component = mount(<AddOwnerForm {...getMockProps('withoutOwners')}/>);
    const input = component.find(APInputText).at(0);
    await act(async () => {
      input.prop('onChange')({target: {value: 'bar', name: 'doesnt_exist'}});
      input.prop('onChange')({target: {value: 'foo', name: 'first_name'}});
    });
    component.update();
    expect(component.find(APInputText).at(0).prop('value')).toEqual('foo');
  });

  it('mount to test field validation', async () => {
    jest.spyOn(utils, 'hasClass').mockImplementation(() => false);
    const component = mount(<AddOwnerForm {...getMockProps('withoutOwners')}/>);
    await act(async () => {
      const inputs = component.find(APInputText),
        maskedInputs = component.find('t'),
        parent = component.find('.col-xs-6.form-group').at(0);
      inputs.forEach(input => {
        try {
          input.prop('onChange')({target: {value: 'foo', name: 'first_name'}});
          runBlurEvent(input, parent);    
        } catch {
          return;
        }
      });
      maskedInputs.forEach(input => {
        try {
          runBlurEvent(input, parent);
        } catch {
          return;
        }
      });
    });
    component.update();
  });
});

const runBlurEvent = (input, parent) => {
  input.prop('onBlur')({target: {value: '', name: 'first_name', parentNode: parent}});      
};

const getMockProps = (type) => {
  switch (type) {
  case 'withoutOwners':
    return {
      errors: [], 
      onSubmit: () => {}, 
      onCancel: () => {},
      onEditSubmit: () => {}
    };
  case 'withErrors':
    return {
      errors: [{message: 'foo'}]
    };
  case 'withOwners':
    return {errors: [], 
      onSubmit: () => {}, 
      onCancel: () => {},
      owner: {
        'id': 34,
        'first_name': 'New Owner',
        'last_name': 'Doe',
        'has_significant_responsibility': null,
        'has_significant_ownership': true,
        'job_title': 'Owner',
        'social_security_number_last_4': '8989',
        'date_of_birth': '08/08/1994',
        'home_address1': '2020 Fake Elm St',
        'home_address2': '',
        'home_city': 'Wonderland',
        'home_state': 'AA',
        'home_zip_code': '55998',
        'merchant_application_id': 4,
        'created_at': '2020-04-20T20:03:12.000Z',
        'updated_at': '2020-04-21T21:12:52.000Z',
        'ssn_request_url': '/admin/merchant_applications/4/business_owner_social_security_numbers/31'
      }, 
      onEditSubmit: () => {}};
  default:
    return;
  }
};