import React, { useState } from 'react';
import {bool, string} from 'prop-types';
import {ClientCreditTextList} from '../helpers.js';
import ClientCreditCheckboxModal from './ClientCreditCheckboxModal';
import './style.scss';

const clientCreditBenefits = [
  'Get paid 100% up front, while your clients pay installments over time',
  'No need to follow up on client payments',
  'Expand your client base with additional payment options'
];

export const getClientCreditStatus = (brandTitle, clientCreditIntent, isCaliforniaBusiness) => {
  if (isCaliforniaBusiness && brandTitle === 'LawPay') return Boolean(clientCreditIntent);
  return clientCreditIntent === null || Boolean(clientCreditIntent);
};

const ClientCreditCheckbox = ({
  brandTitle,
  brandLink,
  checkboxName,
  clientCreditIntent,
  isCaliforniaBusiness,
  supportPhone,
  baseRate
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [clientCreditStatus, setClientCreditStatus] = useState(getClientCreditStatus(brandTitle, clientCreditIntent, isCaliforniaBusiness));

  const handleChange = () => {
    setClientCreditStatus(!clientCreditStatus);
  };

  const closeModal = () => setDisplayModal(false);

  const openModal = () => setDisplayModal(true);

  return (
    <div className="client-credit-option">
      <label htmlFor="forms_registrations_deposit_step_form_accept_payment_cards">
        <input
          id="forms_registrations_deposit_step_form_accept_payment_cards"
          className="client-credit-checkbox"
          type="checkbox"
          checked={true}
          value={true}
          disabled={true}
        />
          I want to accept payment cards
      </label>
      <input
        name={checkboxName}
        type="hidden"
        value={clientCreditStatus}
        autoComplete="off"
        data-testid="hidden-client-credit-checkbox"
      />
      <label htmlFor="forms_registrations_deposit_step_form_cc_intent_status">
        <input
          className="client-credit-checkbox"
          id="forms_registrations_deposit_step_form_cc_intent_status"
          type="checkbox"
          onChange={handleChange}
          checked={clientCreditStatus}
          value={clientCreditStatus}
          data-testid="client-credit-checkbox"
        />
          I want to activate Pay Later
      </label>
      <ClientCreditTextList
        textArray={clientCreditBenefits}
        textId="client-credit-benefits"
        className="client-credit-benefits"
      />
      <p className="client-credit-support-resources">
        <a
          onClick={openModal}
          className="client-credit-support-resources"
        >
          Learn more about Pay Later
        </a> or call support at <a href={`tel:${supportPhone}`}>{supportPhone}</a>
      </p>
      {displayModal && (
        <ClientCreditCheckboxModal
          closeModal={closeModal}
          supportPhone={supportPhone}
          brandTitle={brandTitle}
          brandLink={brandLink}
          baseRate={baseRate}
        />
      )}
    </div>
  );
};

ClientCreditCheckbox.propTypes = {
  brandTitle: string.isRequired,
  brandLink: string.isRequired,
  checkboxName: string.isRequired,
  clientCreditIntent: bool,
  isCaliforniaBusiness: bool,
  supportPhone: string.isRequired,
  baseRate: string.isRequired
};

export default ClientCreditCheckbox;
