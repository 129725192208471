import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import ApiSettings from '.';
import userEvent from '@testing-library/user-event';
import client from 'lib/ajax';
import notify from 'lib/notify';

const fakeMerchant = {
  id: 1,
  api_allowed_ip_address_ranges: '192.168.1.1/32',
  live_events_urls: 'http://live-site.io/webhooks',
  test_events_urls: 'http://test-site.io/webhooks'
};

describe('ApiSettings Component tests', () => {
  beforeAll(() => {
    jest.spyOn(notify, 'success').mockImplementation(() => {});
  });
  
  it('renders a button to open the modal', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    expect(screen.getByText('Show Settings')).toBeInTheDocument();
  });

  it('clicking the show settings button opens the modal and shows form', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText(fakeMerchant.api_allowed_ip_address_ranges)).toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.live_events_urls)).toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.test_events_urls)).toBeInTheDocument();
  });

  it('can open and close the modal', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    expect(screen.queryByText('API Settings')).not.toBeInTheDocument();
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    userEvent.click(screen.getByText('Cancel'));
    expect(screen.queryByText('API Settings')).not.toBeInTheDocument();
  });

  it('Doesnt let user enter invalid ip addresses', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getByPlaceholderText('IP range (i.e. 50.112.0.0/16)');
    userEvent.type(input, '123');
    userEvent.type(input, '{enter}');
    expect(screen.queryByText('123')).not.toBeInTheDocument();
  });

  it('Does let user enter valid ip addresses', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getByPlaceholderText('IP range (i.e. 50.112.0.0/16)');
    userEvent.type(input, '192.168.1.1/31');
    userEvent.type(input, '{enter}');
    expect(screen.getByText('192.168.1.1/31')).toBeInTheDocument();
  });

  it('Doesnt let user enter invalid live urls', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getAllByPlaceholderText('https://example.com')[0];
    userEvent.type(input, '123');
    userEvent.type(input, '{enter}');
    expect(screen.queryByText('123')).not.toBeInTheDocument();
  });

  it('Does let user enter valid valid live urls', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getAllByPlaceholderText('https://example.com')[0];
    userEvent.type(input, 'https://test.com');
    userEvent.type(input, '{enter}');
    expect(screen.getByText('https://test.com')).toBeInTheDocument();
  });

  it('Doesnt let user enter invalid test urls', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getAllByPlaceholderText('https://example.com')[1];
    userEvent.type(input, '123');
    userEvent.type(input, '{enter}');
    expect(screen.queryByText('123')).not.toBeInTheDocument();
  });

  it('Does let user enter valid valid test urls', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getAllByPlaceholderText('https://example.com')[1];
    userEvent.type(input, 'https://test.com');
    userEvent.type(input, '{enter}');
    expect(screen.getByText('https://test.com')).toBeInTheDocument();
  });

  it('disables inputs when saving', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(fakeMerchant);
        })
      })
    );
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const ipInput = screen.getByPlaceholderText('IP range (i.e. 50.112.0.0/16)');
    const liveUrlInput = screen.getAllByPlaceholderText('https://example.com')[0];
    const testUrlInput = screen.getAllByPlaceholderText('https://example.com')[1];
    const cancelButton = screen.getByText('Cancel');
    const saveButton = screen.getByText('Save Changes');
    expect(ipInput).not.toBeDisabled();
    expect(liveUrlInput).not.toBeDisabled();
    expect(testUrlInput).not.toBeDisabled();
    expect(cancelButton).not.toBeDisabled();
    expect(saveButton).not.toBeDisabled();
    userEvent.click(saveButton);
    expect(ipInput).toBeDisabled();
    expect(liveUrlInput).toBeDisabled();
    expect(testUrlInput).toBeDisabled();
    expect(cancelButton).toBeDisabled();
    expect(saveButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
  });

  it('Closes the modal when save is successful', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(fakeMerchant);
        })
      })
    );
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    const saveButton = screen.getByText('Save Changes');
    userEvent.click(saveButton);
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.queryByText('API Settings')).not.toBeInTheDocument();
  });

  it('Shows error box when response is an error and doesnt close modal', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    const saveButton = screen.getByText('Save Changes');
    userEvent.click(saveButton);
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    expect(screen.getByText('test error')).toBeInTheDocument();
  });

  it('Shows generic error when response is an error with empty string and doesnt close modal', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: ''});
        })
      })
    );
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    const saveButton = screen.getByText('Save Changes');
    userEvent.click(saveButton);
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    expect(screen.getByText('Something went wrong. Please refresh the page and try again')).toBeInTheDocument();
  });

  it('Shows generic error in error box when error is thrown', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() => {
      throw new Error('thrown error');
    });
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    const saveButton = screen.getByText('Save Changes');
    userEvent.click(saveButton);
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('API Settings')).toBeInTheDocument();
    expect(screen.getByText('Something went wrong. Please refresh the page and try again')).toBeInTheDocument();
  });

  it('resets inputs to initial if user closes modal without saving', () => {
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText(fakeMerchant.api_allowed_ip_address_ranges)).toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.live_events_urls)).toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.test_events_urls)).toBeInTheDocument();
    const input = screen.getAllByPlaceholderText('https://example.com')[0];
    userEvent.type(input, 'https://test.com');
    userEvent.type(input, '{enter}');
    expect(screen.getByText('https://test.com')).toBeInTheDocument();
    userEvent.click(screen.getByText('Cancel'));
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.queryByText('https://test.com')).not.toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.api_allowed_ip_address_ranges)).toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.live_events_urls)).toBeInTheDocument();
    expect(screen.getByText(fakeMerchant.test_events_urls)).toBeInTheDocument();
  });

  it('doesnt reset inputs to initial if user reopens then closes modal after saving', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(fakeMerchant);
        })
      })
    );
    render(<ApiSettings merchant={fakeMerchant}/>);
    userEvent.click(screen.getByText('Show Settings'));
    const input = screen.getAllByPlaceholderText('https://example.com')[0];
    userEvent.type(input, 'https://test.com');
    userEvent.type(input, '{enter}');
    expect(screen.getByText('https://test.com')).toBeInTheDocument();
    const saveButton = screen.getByText('Save Changes');
    userEvent.click(saveButton);
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Show Settings'));
    userEvent.click(screen.getByText('Cancel'));
    userEvent.click(screen.getByText('Show Settings'));
    expect(screen.getByText('https://test.com')).toBeInTheDocument();
  });

});