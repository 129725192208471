import React, { useMemo } from 'react';
import { oneOf, shape, string } from 'prop-types';
import TrustIcon from '../../components/icons/TrustIcon';
import './style.scss';

const bankAccountTypesMap = new Map();
bankAccountTypesMap.set('iolta', true);

const BankAccountDisplay = ({ invoice: { bankAccount, bankName, bankAccountType } }) => {
  const isTrust = useMemo(() => !!bankAccountTypesMap.get(bankAccountType), [bankAccountType]);

  return (
    <div className="deposit-account-display">
      { isTrust ? (<span className="bold hidden-xs trust-account"> <TrustIcon size='14' /><strong>TRUST</strong> </span>) : null }
      <span className="normal hidden-xs"> {bankName} </span>
      <span className="account-number numeric">{bankAccount}</span>
    </div>
  );
};

BankAccountDisplay.propTypes = {
  invoice: shape({
    bankAccount: string,
    bankName: string,
    bankAccountType: oneOf(['iolta', 'operating'])
  })
};

export default BankAccountDisplay;
