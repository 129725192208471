import React from 'react';
import {node, oneOfType, string} from 'prop-types';

const QuickBillStatistic = ({label, value}) => (
  <div className="quick-bill-statistic">
    <span>{label} </span>
    <span>{value}</span>
  </div>
);

QuickBillStatistic.propTypes = {
  label: string,
  value: oneOfType([string, node])
};

export default QuickBillStatistic;
