import client from '../../../lib/ajax';

export const updateProcessorAccountName = async (path, body = {}) => {
  const paramKeys = Object.keys(body);
  const payload = { account: {} };
  paramKeys.forEach(key => payload.account[key] = body[key]);

  const response = await client.patch(path, payload);

  return response;
};
