import React from 'react';
import {screen, render} from '@testing-library/react';
import {ClientCreditTextList} from './helpers';

describe('ClientCreditTextList', () => {
  const props = {
    className: 'text-list-test',
    textArray: ['foo', 'bar', 'baz'],
    textId: 'text-list-id'
  };

  it('takes a className and applies to the list', () => {
    render(<ClientCreditTextList {...props} />);
    expect(screen.getByTestId('client-credit-list')).toHaveAttribute('class', 'text-list-test');
  });

  it('takes a textArrauy prop and applies to the list items', () => {
    render(<ClientCreditTextList {...props} />);
    expect(screen.getByText('foo')).toBeVisible();
    expect(screen.getByText('bar')).toBeVisible();
    expect(screen.getByText('baz')).toBeVisible();
  });
});
