import { withProps } from 'recompose';

import Mask from 'react-text-mask';

export const DobInput = withProps({
  mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
})(Mask);

export const SsnInput = withProps({
  mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
})(Mask);

export { Mask as default };
