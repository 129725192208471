/* eslint-disable no-undef */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { getSignature } from '../../lib/paymentPage/services';
import { rollbarLog, addClass } from '../../lib/utils';

function ThankYouComponent({
  content,
  dataSignature,
  current_user,
  charge}) {

  useEffect(() => {
    if(dataSignature) {
      $('#signature').jSignature();

      let scale = 1.0,
        heightText = '200';
      if ($('#signature').data('sig-height')) {
        heightText = $('#signature').data('sig-height');
        scale = parseFloat(heightText) / 200;
      }
      $('canvas.jSignature').prop('height', heightText);
      $('canvas.jSignature').css('height', heightText + 'px');

      if(current_user) {
        const path = `/charges/${charge.id}/signature`;
        getSignature(path).then(async res => {
          if(res.ok){
            const date = document.querySelector('#sig_date');
            const json = await res.json();
            if(date) {
              date.textContent = json.created_at;
            }
            $('#signature').jSignature('setData', scaleSignaturePoints(JSON.parse(json.data), scale), 'native');
            attachSignature();
          } else {
            rollbarLog('Payment Page', `Server didn't find a recorded signature: ${res}. Attempting to build the signature of prop received`, 'info');
            buildSignatureFromProp(scale);
          }
        }).catch(err => {
          rollbarLog('Payment Page', `Server didn't find a recorded signature: ${err.message || err.statusText}. Attempting to build the signature of prop received`, 'info');
          buildSignatureFromProp(scale);
        });
      } else {
        buildSignatureFromProp(scale);
      }
    }
  }, [dataSignature]);

  useEffect(() => {
    if(content) {
      try{
        addClass(parent.document.querySelector('.ap-modal-header'), 'charge-receipt');
        window.scrollTo(0, 0);
      } catch(e) {
        rollbarLog('Payment Page', `PP2.0: Failed to reveal the print button. error: ${e.message}`);
      }
    }
  }, [content]);

  const buildSignatureFromProp = scale => {
    try{
      if(dataSignature) $('#signature').jSignature('setData', scaleSignaturePoints(dataSignature, scale), 'native');
      attachSignature();
    } catch (e) {
      rollbarLog('Payment Page', `Failed to build signature from prop: ${e.message}.`, 'warning');
    }
  };

  const scaleSignaturePoints = (pointsData, scale) => {
    let scaledPoints = [];
    $.each(pointsData, function(idx, item) {
      item.x = $.map(item.x, function(v) { return v * scale;  });
      item.y = $.map(item.y, function(v) { return v * scale;  });
      scaledPoints.push(item);
    });
    return scaledPoints;
  };

  const attachSignature = () => {
    const img = $('#signature').jSignature('getData');
    $('#signature').empty();
    $('#signature').append($(`<img src="${img}" alt="signature of applicant" role="img" />`));
  };

  return (
    <div className="col-12" data-testid='thank-you-page'>
      <div id="thank-you-wrap" dangerouslySetInnerHTML={{__html: content}}></div>
    </div>
  );
}

ThankYouComponent.propTypes = {
  content: PropTypes.node,
  dataSignature: PropTypes.array,
  current_user: PropTypes.bool,
  charge: PropTypes.object
};

export default ThankYouComponent;

