import React from 'react';
import PropTypes from 'prop-types';

const StatBox = ({ label, value, placement }) => {
  return (
    <div className="col-sm-6">
      <span className={ (placement === 'left' ? 'stat-summary pull-right' : 'stat-summary pull-left') }>
        <span className="stat-summary-label">{ label }</span>
        <span className="stat-summary-value">${ value }</span>
      </span>
    </div>
  );
};

export default StatBox;

StatBox.propTypes = {
  label: PropTypes.string,
  placement: PropTypes.string,
  value: PropTypes.string
};
