import React from 'react';
import {array, bool, func, object, shape, string } from 'prop-types';
import EcheckButton from './EcheckButton';
import QuickBillButton from './QuickBillButton';
import HeaderProfileMenu from '../HeaderProfileMenu';

const HeaderButtons = ({
  echeckUrl,
  handleEcheckClick,
  handleProfileMenuClick,
  profileMenu,
  quickBillEmail,
  quickBillModal,
  quickBillPolicy,
  invoiceSPAQuickBill,
  truncatedUsernames
}) => (
  <>
    <EcheckButton echeckUrl={echeckUrl} onClick={handleEcheckClick} />
    <div className="enhanced-quick-bill">
      <QuickBillButton
        quickBillEmail={quickBillEmail}
        quickBillModal={quickBillModal}
        quickBillPolicy={quickBillPolicy}
        invoiceSPAQuickBill={invoiceSPAQuickBill}
      />
    </div>
    <HeaderProfileMenu
      {...truncatedUsernames}
      items={profileMenu}
      onClick={handleProfileMenuClick}
    />
  </>
);

HeaderButtons.propTypes = {
  echeckUrl: string,
  handleEcheckClick: func,
  handleProfileMenuClick: func,
  profileMenu: array,
  quickBillEmail: bool,
  quickBillModal: object,
  quickBillPolicy: bool,
  invoiceSPAQuickBill: bool,
  truncatedUsernames: shape({
    desktopUserName: string,
    mobileUserstring: string
  })
};

export default HeaderButtons;
