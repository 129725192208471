import React, { useState } from 'react';
import './style.scss';
import PlanSection from './PlanSection';
import { APButton, APTabs, APFormMessageBox } from 'affinipay-ui-library';
import SubscriptionFeesSection from '../SubscriptionFeesSection';
import CardFeesSection from '../CardFeesSection';
import LimitsSection from './LimitsSection';
import EcheckRateTransactionFee from './echeckRateTransactionFee';
import PolicySection from './PolicySection';
import {addClass, hasClass, removeClass} from '../../../lib/utils';
import PropTypes from 'prop-types';

const NewPlanForm = ({
  sites = [{text: 'AffiniPay'}, {text: 'LawPay'}],
  feeNames = [{text: 'Program Fee', selected: true}],
  frequencies = [{text: 'Monthly'}, {text: 'Yearly'}]
}) => {

  const initialTabsState = [{text: 'Cards', active: true}, {text: 'eCheck'}];
  const initialPlanInfo = {
    planSite: undefined,
    planName: undefined,
    planDescription: undefined,
    planTrust: undefined,
    planStatus: 'published',
    planType: 'internal',
    paymentType: 'includeECheck',
    guaranteeType: 'corporate'
  };

  const initialCardsTab = {
    subscriptionFeeRows: [{key: 1, feeName: 'Program Fee', amount: undefined, frequency: undefined}],
    creditCardFee: undefined,
    visaMCDiscoverFee: undefined,
    chargebackFee: undefined,
    debitCardFee: undefined,
    amexFee: undefined,
    foreignFee: undefined,
    manualCvvCheck: 'optional',
    manualAvsCheck: 'submitIgnoreResult',
    cardCvvCheck: 'disabled',
    cardAvsCheck: 'disabled',
    manualCardNumber: 'manualCardNumber',
    manualCardExpiration: 'manualCardExpiration',
    manualName: undefined,
    manualCardCVV: undefined,
    manualEmail: undefined,
    manualAddress1: undefined,
    manualCity: undefined,
    manualState: undefined,
    manualPostalCode: undefined,
    manualCountry: undefined,
    cardCardNumber: 'cardCardNumber',
    cardCardExpiration: 'cardCardExpiration',
    cardName: undefined, 
    cardCardCVV: undefined,
    cardEmail: undefined, 
    cardAddress1: undefined,
    cardCity: undefined,   
    cardState: undefined,
    cardPostalCode: undefined, 
    cardCountry: undefined,
    cardDailyWarning: undefined,
    cardDailyMax: undefined,
    cardDailyWarningMonetary: undefined,
    cardDailyMaxMonetary: undefined,
    cardMonthlyWarning: undefined,
    cardMonthlyMax: undefined,
    cardMonthlyWarningMonetary: undefined,
    cardMonthlyMaxMonetary: undefined,
    cardSingleWarningMonetary: undefined,
    cardSingleMaxMonetary: undefined,
    cardTransactionFloor: undefined
  };
  
  const initialEcheckTab = {
    echeckBaseRate: undefined,
    additionalPerTransaction: undefined,
    returnFee: undefined,
    holdPeriod: '2',
    echeckName: undefined,
    echeckEmail: undefined,
    echeckAddress1: undefined,
    echeckCity: undefined,
    echeckState: undefined,
    echeckPostalCode: undefined,
    echeckCountry: undefined, 
    echeckDailyWarning: undefined,
    echeckDailyMax: undefined,
    echeckDailyWarningMonetary: undefined,
    echeckDailyMaxMonetary: undefined,
    echeckMonthlyWarning: undefined,
    echeckMonthlyMax: undefined,
    echeckMonthlyWarningMonetary: undefined,
    echeckMonthlyMaxMonetary: undefined,
    echeckSingleWarningMonetary: undefined,
    echeckSingleMaxMonetary: undefined,
    echeckTransactionFloor: undefined
  };

  const initialVimasTab = {};

  const [tabsState, setTabsState] = useState(initialTabsState);
  const [planInfo, setPlanInfo] = useState(initialPlanInfo);
  const [cardsTab, setCardsTab] = useState(initialCardsTab);
  /* eslint-disable no-unused-vars */
  const [echeckTab, setEcheckTab] = useState(initialEcheckTab);
  const [vimasTab, setVimasTab] = useState(initialVimasTab);
  /* eslint-enable no-unused-vars */
  const [showFormError, setShowFormError] = useState(false);

  const handleSubscriptionRowsChange = (feeRows) => {
    setCardsTab({...cardsTab, subscriptionFeeRows: feeRows});
  };

  const handlePlanTypeChange = (value) => {
    setShowFormError(false);
    if (value === 'internal') {
      const newTabs = addTab('Cards', deleteTab('Vimas', tabsState));
      setTabsState(newTabs);
    } else {
      const newTabs = addTab('Vimas', deleteTab('Cards', tabsState));
      setTabsState(newTabs);
    }
  };

  const deleteTab = (name, tabsState) => {
    return tabsState.filter(el => el.text !== name);
  };

  const addTab = (name, tabsState) => {
    if (name === 'eCheck') {
      return [...tabsState, {text: name}];
    }
    return [{text: name, active: true}, ...tabsState];
  };


  const handleIncludeECheckChange = (value) => {
    setShowFormError(false);
    if (value === 'includeECheck') {
      const newTabs = addTab('eCheck', tabsState);
      setTabsState(newTabs);
    } else {
      const newTabs = deleteTab('eCheck', tabsState);
      newTabs[0].active = true;
      setTabsState(newTabs);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendFormState();
    }
  };

  const validateForm = () => {
    const activeStates = getActiveStates();
    let isValid = true;

    activeStates.forEach(formState => {
      for (const prop in formState) { // eslint-disable-line no-unused-vars
      
        if (prop !== 'subscriptionFeeRows' && !formState[prop]) {
          const invalidField = document.querySelector(`input[name='${prop}']`) || document.querySelector(`.${prop}`);

          if (invalidField) {
            addClass(invalidField.parentElement, 'error');
          }
          if (!showFormError) {
            setShowFormError(true);
            isValid = false;
          }
        }
      }

      if (Object.prototype.hasOwnProperty.call(formState, 'subscriptionFeeRows') && formState.subscriptionFeeRows) {
        formState.subscriptionFeeRows.map((el, i) => {
          for (const prop in el) { // eslint-disable-line no-unused-vars
            if (!el[prop]) {
              const invalidField = document.querySelectorAll(`input[name='${prop}']`)[i] || document.querySelectorAll(`.${prop}`)[i];
              addClass(invalidField.parentElement, 'error');
              if (!showFormError) {
                setShowFormError(true);
                isValid = false;
              }
            }
          }
        });
      }
    });

    if (isValid) {
      setShowFormError(false);
    }
    return isValid;
  };

  const getActiveStates = () => {
    const activeStates = tabsState.map(el => {
      switch (el.text) {
      case 'Cards':
        return cardsTab;
      case 'Vimas':
        return vimasTab;
      case 'eCheck':
        return echeckTab;
      }
    });
    return [planInfo, ...activeStates];
  };

  const onInputValidation = (name, value, rowNumber) => {
    let invalidField;
    if (rowNumber || rowNumber === 0) {
      invalidField = document.querySelectorAll(`input[name='${name}']`)[rowNumber] || document.querySelectorAll(`.${name}`)[rowNumber];
    } else {
      invalidField = document.querySelector(`input[name='${name}']`) || document.querySelector(`.${name}`);
    }
    if (value && hasClass(invalidField.parentElement, 'error')) {
      removeClass(invalidField.parentElement, 'error');
    }
    if (!document.querySelector('.error')) {
      setShowFormError(false);
    }
  };

  const sendFormState = () => {

  };

  const navigateBack = () => {
    window.location = '/admin/plans/';
  };

  const generateCardsForm = () => (
    <>
      <div className='ap-tab-content tabContent-cards'>
        <SubscriptionFeesSection
          feeNames={feeNames}
          frequencies={frequencies}
          onRowsChange={handleSubscriptionRowsChange}
          handleValidationError={onInputValidation}
          dateFieldsEnabled={false}/>
        <hr/>
        <CardFeesSection
          setFormState={setCardsTab}
          formState={cardsTab}
          handleValidationError={onInputValidation}/>
        <hr/>
        <PolicySection
          setFormState={setCardsTab}
          formState={cardsTab}
          cardsSection={true}
        />
        <hr/>
        <LimitsSection setFormState={setCardsTab}
          formState={cardsTab}
          onInputValidation={onInputValidation}
          statePrefix='card' />
      </div>
    </>
  );

  const generateEcheckForm = () => (
    <>
      <div className='ap-tab-content tabContent-echeck'>
        <EcheckRateTransactionFee
          setFormState={setEcheckTab}
          formState={echeckTab}
          handleValidationError={onInputValidation}
        />
        <hr/>
        <PolicySection
          setFormState={setEcheckTab}
          formState={echeckTab}
          echeckSection={true}
        />
        <hr/>
        <LimitsSection setFormState={setEcheckTab}
          formState={echeckTab}
          onInputValidation={onInputValidation}
          statePrefix='echeck' />
      </div>
      <div className='ap-tab-content tabContent-vimas'>
        Vimas Content
      </div>
    </>
  );

  return (
    <div className="row admin">
      <div className="col-xs-10">
        <form className="new-plan-form">
          <h3>New Plan</h3>
          <PlanSection
            sites={sites}
            formState={planInfo}
            setFormState={setPlanInfo}
            handlePlanTypeChange={handlePlanTypeChange}
            handleIncludeECheckChange={handleIncludeECheckChange}
            onInputValidation={onInputValidation}/>
          <APTabs
            tabsData={tabsState}>
            {generateCardsForm()}
            {generateEcheckForm()}
          </APTabs>
          {showFormError && (
            <APFormMessageBox type="error">
              All fields are required
            </APFormMessageBox>
          )}
          <APButton onClick={submitForm}>
            Save Plan
          </APButton>
          <APButton onClick={navigateBack}
            className="ap-admin-default-secondary-button">
            Cancel
          </APButton>
        </form>
      </div>
      <div className="col-xs-2">
        <APButton onClick={navigateBack}
          className="ap-admin-medium-secondary-button">
          Back to Plans
        </APButton>
      </div>
    </div>
  );
};

NewPlanForm.propTypes = {
  sites: PropTypes.array,
  feeNames: PropTypes.array,
  frequencies: PropTypes.array
};

export default NewPlanForm;
