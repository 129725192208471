import React, { useMemo }from 'react';
import { arrayOf, oneOf, string } from 'prop-types';
import { APTable } from 'affinipay-ui-library';
import { gatherPaymentValues } from './utils/helpers';
import tableColumns from './utils/tableColumns';
import './style.scss';
import { sortByTime } from '../utils';
import { invoiceProp } from '../../../customProps';

const AssociatedInvoicesTable = ({invoices = [], invoicePath, transaction = {}, title = 'Invoice'}) => {
  const sortedPayments = useMemo(() => {
    const paymentValues = gatherPaymentValues(invoices, transaction);
    const sortedPaymentValues = sortByTime(paymentValues, 'desc');

    return sortedPaymentValues;
  }, [invoices]);

  const handleRowClick = ({ id }) => {
    if (!invoicePath || !id) return;
    window.location.assign(`${window.location.origin}${invoicePath}${id}`);
  };

  return (
    <div className="associated-invoices-table-wrapper">
      <h3>{`Associated ${title}(s)`}</h3>
      <div className="table-responsive">
        <APTable
          data={sortedPayments}
          header={tableColumns}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

AssociatedInvoicesTable.propTypes = {
  invoices: arrayOf(invoiceProp),
  invoicePath: string.isRequired,
  title: oneOf(['Invoice', 'Quick Bill'])
};

export default AssociatedInvoicesTable;