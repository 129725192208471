import PropTypes from 'prop-types';
import React from 'react';

const AdminPageHeader = ({ title, children }) => (
  <div className='page-header clearfix'>
    <div className='row'>
      <div className='col-sm-12'>
        <h3>{title}</h3>
        {children}
      </div>
    </div>
  </div>
);

AdminPageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element
};

export default AdminPageHeader;