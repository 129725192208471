import React, {useEffect, useState} from 'react';
import {arrayOf, node, number, object, oneOfType, shape, string} from 'prop-types';
import './style.scss';
import { convertSvgToImage } from './helpers/convertSvgToImage';
import {rollbarLog} from '../../lib/utils';

const SvgToImage = ({ children, focusId, parentId, svgSettings }) => {
  const [renderedSVG, setRenderedSVG] = useState(null); 
  useEffect(() => {
    const qrSVG = document.getElementById(focusId)?.cloneNode(true);

    if (qrSVG && !renderedSVG) {
      setRenderedSVG(qrSVG);
    } else if (qrSVG) {
      convertSvgToImage({
        svg: qrSVG,
        quality: 1,
        width: svgSettings.width,
        height: svgSettings.height
      }).then(base64Image => {
        let img = document.createElement('img');
        img.src = base64Image;
        img.className = 'qr-code-image';
        document.getElementById(parentId)?.appendChild(img);
      }).catch(error => {
        rollbarLog('SvgToImage', `Failed to convert svg element to image: ${error.message}`);
      });
    } else {
      rollbarLog('SvgToImage', `Failed to convert svg element to image: ${focusId} was not found in the document`);
    }
  }, [renderedSVG]);

  return (
    <div id={parentId}>
      {children}
    </div>
  );
};

SvgToImage.propTypes = {
  children: oneOfType([arrayOf(node), object]),
  focusId: string,
  parentId: string,
  svgSettings: shape({
    width: number,
    height: number
  })
};

export default SvgToImage;