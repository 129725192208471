import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { APSelect, APInputMonetary } from 'affinipay-ui-library';
import APRadioButton from '../../components/APRadioButton';
import { getDateWithOrdinal, getDayOfWeek, getMonth } from '../../lib/utils';
import { basicFrequencyOptions, frequencySecondDateOptions, fullFrequencyOptions } from './helpers/constants';
import { generateRecurringChargeData } from './helpers/submitBuilders';
import { getCentsFromMaskedMoneyInput } from '../../lib/monetaryUtils';

const todaysDate = new Date().getDate();

const FrequencyComponent = ({
  recurringVisible,
  selectedPaymentMethod,
  disabledFields,
  defaultFields,
  upfRef
}) => {
  const frequencyDisabled = disabledFields.includes('recur_frequency');  
  const [frequency, setFrequency] = useState(() => {
    const { recur_frequency: defaultFrequency } = defaultFields;
    if (!defaultFrequency) return 'NONE';
    if (recurringVisible === 'BASIC' && !basicFrequencyOptions.some(option => option.value === defaultFrequency)) return 'NONE';
    if (recurringVisible === 'FULL' && !fullFrequencyOptions.some(option => option.value === defaultFrequency)) return 'NONE';
    return defaultFrequency;
  });
  const [secondDay, setSecondDay] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [maxAmountEnabled, setMaxAmountEnabled] = useState(false);

  useEffect(() => {
    const upfElement = upfRef.current;
    if (upfElement) {
      const maxAmountCents = getCentsFromMaskedMoneyInput(maxAmount);
      const data = ['', 'credit_card', 'ach'].includes(selectedPaymentMethod) ? generateRecurringChargeData(frequency, maxAmountCents, secondDay) : {};
      upfElement['recurring-data'] = data;
    }
  }, [frequency, secondDay, maxAmount, selectedPaymentMethod]);

  const handleRadioButtonChange = (e) => {
    if (e.target.value === 'never') {
      setMaxAmount('');
      setMaxAmountEnabled(false);
    } else {
      setMaxAmountEnabled(true);
    }
  };

  const handleFrequencyChange = (e) => {
    const { value } = e.target;
    if (value === 'NONE') {
      setMaxAmount('');
      setMaxAmountEnabled(false);
      setSecondDay('');
    } else if (value !== 'BI_MONTHLY') {
      setSecondDay('');
    }
    setFrequency(value);
  };

  const handleSecondDayChange = (e) => {
    const { value } = e.target;
    setSecondDay(value);
  };

  const handleAmountChange = (e) => {
    const {value} = e.target;
    setMaxAmount(value);
  };

  const displayLabeledDays = () => {
    switch(frequency) {
    case 'NONE':
      return '';
    case 'BI_MONTHLY':
      return <>on the <strong>{ getDateWithOrdinal(todaysDate) } and the</strong></>;
    case 'WEEKLY':
    case 'BI_WEEKLY':
      return <>on <strong>{ getDayOfWeek() }</strong></>;
    case 'YEAR':
      return <>on <strong>{ getMonth().substring(0, 3) }, {todaysDate}</strong></>;
    default:
      return <>on the <strong>{ getDateWithOrdinal(todaysDate) }</strong></>;
    }
  };

  return ['', 'credit_card', 'ach'].includes(selectedPaymentMethod) && (
    <>
      <div className='row form-group'>
        <div className='col-md-6'>
          <APSelect
            onChange={handleFrequencyChange}
            options={recurringVisible === 'BASIC' ? basicFrequencyOptions : fullFrequencyOptions}
            value={frequency}
            name='recurFrequency'
            label='Frequency'
            isFloatingLabel={true}
            disabled={frequencyDisabled}
          />
        </div>
        <div className='col-md-2 align-middle days-label'>
          {displayLabeledDays()}
        </div>
        {frequency == 'BI_MONTHLY' &&
          <div className='col-md-4'>
            <APSelect
              onChange={handleSecondDayChange}
              options={frequencySecondDateOptions}
              value={secondDay}
              name='secondDayOfMonth'
              label='Day of Month'
              isFloatingLabel={true}
            />
          </div>
        }
      </div>
      {(recurringVisible === 'FULL' && frequency !== 'NONE') &&
        <div className='row'>
          <label className="ends-group-label">Ends:</label>
          <div className='col-md-12'>
            <APRadioButton
              text='Never'
              name='recurringEnds'
              value='never'
              onChange={handleRadioButtonChange}
              checked={maxAmountEnabled === false}
              disabled={frequencyDisabled}
            />
          </div>
          <div className="pay-off-total-group">
            <APRadioButton
              className="pay-off-total-btn"
              text='When total paid:'
              name='recurringEnds'
              value='whenTotalPaid'
              onChange={handleRadioButtonChange}
              checked={maxAmountEnabled === true}
              disabled={frequencyDisabled}
            />
            <APInputMonetary
              className='end-amount'
              disabled={!maxAmountEnabled || frequencyDisabled}
              hasAddon={false}
              isFloatingLabel={true}
              label='Total Amount Due'
              name='maxAmount'
              onChange={handleAmountChange}
              value={maxAmount}
            />
          </div>
        </div>
      }
    </>
  );
};

FrequencyComponent.propTypes = {
  recurringVisible: PropTypes.string,
  selectedPaymentMethod: PropTypes.string,
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  defaultFields: PropTypes.object,
  upfRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default FrequencyComponent;