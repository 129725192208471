import React, { useState, useEffect } from 'react';
import { string, number, bool } from 'prop-types';
import client from 'lib/ajax';
import { APButton } from 'affinipay-ui-library';
import notify from 'lib/notify';

const EnrollInAdyenButton = ({
  merchantId, 
  adyenSyncStatus,
  adyenSyncMessage,
  merchantPartiallyMigrated = false
}) => {
  const [syncStatus, setSyncStatus] = useState({status: adyenSyncStatus, message: adyenSyncMessage});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let interval;
    const getAdyenSyncStatus = async () => {
      try {
        const resp = await client.get(`/admin/merchants/${merchantId}/adyen_sync_status`);
        if (resp.ok) {
          const json = await resp.json();
          setSyncStatus({status: json.status, message: json.message});
          if (json.status === 'completed') notify.success('Successfully synchronized to Adyen');
          if (json.status !== 'synchronizing') clearInterval(interval);
        }
      } catch (e) {
        notify.error('Error getting sync status from adyen. Please refresh the page and try again', [], false);
        clearInterval(interval);
      }
    };

    if (syncStatus.status === 'synchronizing') {
      interval = setInterval(getAdyenSyncStatus, 10000);
    }
    return () => clearInterval(interval);
  }, [merchantId]);

  useEffect(() => {
    if (syncStatus.status === 'failed') {
      notify.error(syncStatus.message, [], false);
    }
  }, [syncStatus]);

  const onEnrollClick = async () => {
    setIsLoading(true);
    try {
      const resp = await client.post(`/admin/merchants/${merchantId}/migrate_adyen_platform`);
      if (resp.ok) {
        notify.success('Merchant enrolled in adyen');
        window.location.reload();
      }
      else {
        const json = await resp.json();
        notify.error(json?.error || 'Error enrolling merchant to Adyen. Please reload the page and try again', [], false);
      }
    } catch(e) {
      notify.error('Error enrolling merchant to Adyen. Please reload the page and try again', [], false);
    } finally {
      setIsLoading(false);
    }
  };

  return (!['failed', 'completed'].includes(syncStatus.status) || merchantPartiallyMigrated) && (
    <>
      <APButton
        className={`btn enroll-in-adyen-button ${merchantPartiallyMigrated ? 'btn-warning' : 'btn-primary'}`}
        disabled={(isLoading || syncStatus.status === 'synchronizing') && !merchantPartiallyMigrated} 
        onClick={onEnrollClick}
      >
        {merchantPartiallyMigrated ? 'Adyen Enrollment Incomplete: Submit again to complete' : 'Enroll In Adyen'}
      </APButton>
      {syncStatus.status === 'synchronizing' && !merchantPartiallyMigrated &&
        <span className='sync-with-adyen-text'>Synchronizing with Adyen...</span>
      }
    </>
  );
};

EnrollInAdyenButton.propTypes = {
  merchantId: number.isRequired,
  adyenSyncStatus: string,
  adyenSyncMessage: string,
  merchantPartiallyMigrated: bool
};

export default EnrollInAdyenButton;