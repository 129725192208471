import React from 'react';
import { Table } from 'react-bootstrap';
import { arrayOf, func, string } from 'prop-types';
import BankAccountDisplay from '../BankAccountDisplay';
import IconCheck from '../icons/icon-check';
import IconPaperclip from '../icons/icon-paperclip';
import { navigateToInvoice, formatPresentmentStatus, formatDaysOut } from '../utils';
import { dateFormat } from '../../../lib/utils';
import { DineroCentsFormat } from '../../../lib/monetaryUtils';
import { invoiceProp } from '../../../customProps';

const SortableTableHeader = ({ className, onSort, sortName, columnName, currentSort, sortDirection }) => (
  <th
    className={`sortable-link ${className}`}
    onClick={() => onSort(sortName)}
  >
    <span>{columnName}</span>
    {
      currentSort === sortName &&
      <span className={`current ${sortDirection}`} />
    }
  </th>
);

const attachmentTableColumn = ({attachments = []}) => {
  let attachmentIcon = (attachments && attachments.length > 0) ?
    <IconPaperclip className="quick-bill-attachment-icon" />
    : '';

  return <td>{attachmentIcon}</td>;
};

const QuickBillsTable = ({invoices = [], currentSort, onSort, sortDirection}) => {
  if (invoices.length > 0) {
    return (
      <div>
        <Table responsive striped className="transactions" >
          <thead>
            <tr>
              <SortableTableHeader
                onSort={onSort}
                columnName="Created"
                currentSort={currentSort}
                sortName="created"
                sortDirection={sortDirection}
              />
              <SortableTableHeader
                onSort={onSort}
                columnName="Client"
                currentSort={currentSort}
                sortName="contactName"
                sortDirection={sortDirection}
              />
              <th><span className="hidden-xs">Deposit </span>Account</th>
              <SortableTableHeader
                className="text-right"
                onSort={onSort}
                columnName="Amount"
                currentSort={currentSort}
                sortName="totalAmount"
                sortDirection={sortDirection}
              />
              <SortableTableHeader
                className="hidden-xs"
                onSort={onSort}
                columnName="Reference"
                currentSort={currentSort}
                sortName="reference"
                sortDirection={sortDirection}
              />
              <SortableTableHeader
                className="hidden-xs text-center"
                onSort={onSort}
                columnName="Status"
                currentSort={currentSort}
                sortName="presentmentStatus"
                sortDirection={sortDirection}
              />
              <SortableTableHeader
                className="text-center"
                onSort={onSort}
                columnName="Days Out"
                currentSort={currentSort}
                sortName="created"
                sortDirection={sortDirection}
              />
              <th className="hidden-xs">Method</th>
              <th>Attachment(s)</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(i => {
              const daysOut = (i.status === 'paid' ? <IconCheck /> : formatDaysOut(i.created.timestamp));
              const CSSPaid = (i.status === 'paid' ? 'paid-eqb' : '');

              return (
                <tr
                  key={i.id}
                  className="eqb-row"
                  onClick={ () => navigateToInvoice(i.id) }
                >
                  <td className="numeric">{ dateFormat(i.created.timestamp) }</td>
                  <td>{i.contact.displayName}</td>
                  <td><BankAccountDisplay invoice={i} /></td>
                  <td className="numeric text-right">{ DineroCentsFormat(i.totalAmount, i.currency) }</td>
                  <td className="hidden-xs">{i.reference}</td>
                  <td className={ `hidden-xs text-center ${CSSPaid}` }>{ formatPresentmentStatus(i) }</td>
                  <td className={ `text-center ${CSSPaid}` }>{ daysOut }</td>
                  <td className="hidden-xs">{ i.paymentMethod }</td>
                  { attachmentTableColumn(i) }
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return ( <p>There are no matching invoices.</p> );
  }
};

SortableTableHeader.propTypes = {
  className: string,
  columnName: string,
  currentSort: string,
  onSort: func,
  sortDirection: string,
  sortName: string
};

QuickBillsTable.propTypes = {
  currentSort: string,
  invoices: arrayOf(invoiceProp),
  onSort: func.isRequired,
  sortDirection: string
};

export default QuickBillsTable;
