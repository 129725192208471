import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line react/display-name
const FooterComponent = React.memo(({
  site
}) => {

  const logoPath = `/assets/${site}/powered-by-logo.png`;

  return (
    <footer className="row" role="contentinfo">
      <div className="col-12 col-sm-2 text-center text-sm-right">
        <img src={logoPath} className="powered-logo" alt={`Powered by ${site}`} />
      </div>
      <div className="col-12 col-sm-4 text-center text-sm-right footer-disclaimer">
        Our system uses SSL encryption technology, which is the same technology banks use to protect sensitive information.
      </div>
    </footer>
  );
});

FooterComponent.propTypes = {
  site: PropTypes.string
};

export default FooterComponent;
