import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ReferralButton from '.';

describe('Referral Component', () => {
  it('renders without crashing', () => {
    render(<ReferralButton link={mockLink} site={mockSite} />);
    expect(screen.getByText('Refer a Colleague')).toBeVisible();
  });

  it('referral button is clicked and call to open a tab', () => {
    render(<ReferralButton link={mockLink} site={mockSite} />);
    const button = screen.getByText('Refer a Colleague');
    jest.spyOn(window, 'open');

    userEvent.click(button);

    expect(window.open).toHaveBeenCalledWith(mockLink);
  });
});

const mockLink = 'example.foo';
const mockSite = 'fakeSite';