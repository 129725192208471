
import { emailRegex } from './constants';

export const isNumber = obj => Object.prototype.toString.call(obj) === '[object Number]';

export const hasActiveClass = el => {if(el && el.className) { return el.className.indexOf('active') !== -1;}};

export const removeActiveClass = (el) => { if (el && el.className && typeof(el.className) === 'string') { el.className = el.className.replace(' active', ''); } };

export const addActiveClass = (el) => { if (el) { el.className = el.className + ' active'; } };

export const addInvalid = (el) => { if (el && !hasClass(el, 'invalid')) { addClass(el, 'invalid'); } };

export const removeInvalid = (el) => { if (el && hasClass(el, 'invalid')) { removeClass(el, 'invalid'); } };

export const addClass = (el, classTitle) => { if (el && !hasClass(el, classTitle)) { el.className += ` ${classTitle}`; }};

export const removeClass = (el, classTitle) => { if (el && el.classList) { el.classList.remove(`${classTitle}`); } };

export const hasClass = (el, className) => { if(el && el.className && typeof el.className === 'string') {return el.className.includes(className);}};

export const getDateFromHyphenedFormat = date => new Date(date.toString().replace(/-/g, '/'));

export const isEmailPattern = email => {if(!email){return false;} return emailRegex.test(String(email).toLowerCase());};
