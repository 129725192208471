
import { paymentPageOptionalFields } from './constants';

const _setRequiredFieldsFromGatewayRules = (requiredFieldsFromServer, nameMap, modifiedOptionalFields) => {
  requiredFieldsFromServer.map(serverFieldName => {
    if (nameMap[serverFieldName])
      modifiedOptionalFields = modifiedOptionalFields.filter(reactFieldName => reactFieldName !== nameMap[serverFieldName]);
  });
  return modifiedOptionalFields;
};


const _handleAccountPolicies = (policiesString, nameMap, activeTabText, accountType, modifiedOptionalFields) => {
  const policiesArray = policiesString.split(',');
  if (activeTabText && activeTabText.toLowerCase() === accountType && policiesArray.indexOf('email') > -1) nameMap = { ...nameMap, email: 'email' };
  const result = _setRequiredFieldsFromGatewayRules(policiesArray, nameMap, modifiedOptionalFields);
  return accountType === 'echeck' ? result.filter(reactFieldName => reactFieldName !== 'echeckFirstName' && reactFieldName !== 'echeckLastName') : result;
};

const _makeEmailFieldRequired = (
  optionalFields,
  setRevalidateEmail,
  setOptionalFields
) => {
  if (optionalFields.indexOf('email') !== -1) {
    setRevalidateEmail('required');
    setOptionalFields(optionalFields.filter(el => el !== 'email'));
  }
};

const _makeEmailFieldOptional = (
  optionalFields,
  setRevalidateEmail,
  setOptionalFields
) => {
  if (optionalFields.indexOf('email') === -1) {
    setRevalidateEmail('optional');
    setOptionalFields([...optionalFields, 'email']);
  }
};

const setEmailFieldRequiredOrOptional = (
  state,
  cc_required_fields,
  ach_required_fields,
  optionalFields,
  setRevalidateEmail,
  setOptionalFields
) => {
  const activeTabText = state.find(st => st.active)?.text;
  if (activeTabText === 'Card' || activeTabText === 'eCheck') {
    const fields = {
      'Card': cc_required_fields,
      'eCheck': ach_required_fields
    };
    if (fields[activeTabText].indexOf('email') !== -1) {
      _makeEmailFieldRequired(optionalFields, setRevalidateEmail, setOptionalFields);
    } else {
      _makeEmailFieldOptional(optionalFields, setRevalidateEmail, setOptionalFields);
    }
  }

};

const getOptionalFields = (
  reference_required,
  tabsState,
  cc_required_fields,
  ach_required_fields
) => {
  const activeTabText = tabsState.find(st => st.active)?.text;
  let modifiedOptionalFields = [...paymentPageOptionalFields];
  //if (!activeTabText) return modifiedOptionalFields;
  if (reference_required !== 2) modifiedOptionalFields = [...modifiedOptionalFields, 'reference'];
  if (cc_required_fields) {
    let nameMap = {
      name: 'cardName',
      address1: 'cardBillingAddress',
      city: 'cardBillingCity',
      state: 'cardBillingState',
      postal_code: 'cardBillingZip',
      country: 'cardBillingCountry',
      cvv: 'cardCVV'
    };
    modifiedOptionalFields = _handleAccountPolicies(cc_required_fields, nameMap, activeTabText, 'card', modifiedOptionalFields);
  }

  if (ach_required_fields) {
    let nameMap = {
      address1: 'echeckBillingAddress',
      city: 'echeckBillingCity',
      state: 'echeckBillingState',
      postal_code: 'echeckBillingZip',
      country: 'echeckBillingCountry'
    };
    modifiedOptionalFields = _handleAccountPolicies(ach_required_fields, nameMap, activeTabText, 'echeck', modifiedOptionalFields);
  }
  return modifiedOptionalFields;
};

const optionalFieldsHelper = {
  getOptionalFields: getOptionalFields,
  setEmailFieldRequiredOrOptional: setEmailFieldRequiredOrOptional
};

export default optionalFieldsHelper;

export const getFieldLabel = (label, name, optionalFields) => label; // eslint-disable-line no-unused-vars
