import React, {useState} from 'react';
import {bool, func, string} from 'prop-types';
import {APButton, APModal, APToast} from 'affinipay-ui-library';
import MarkQuickBillAsPaidForm from './MarkQuickBillAsPaidForm';

const initialToastValues = {
  show: false,
  type: undefined,
  message: undefined
};

const MarkQuickBillAsPaidButton = ({invoiceID, isDisabled = false, title, setIsDisabled}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toastValues, setToastValues] = useState(initialToastValues);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <APButton
        onClick={toggleModal}
        className="ap-secondary-button"
        type="button"
        disabled={isDisabled}
      >
        Mark As Paid
      </APButton>
      {isModalOpen && (
        <APModal
          headerText={title}
          className="ap-modal-dialog"
          onClose={toggleModal}
          isActiveInitial={isModalOpen}
        >
          <MarkQuickBillAsPaidForm
            handleClose={toggleModal}
            invoiceID={invoiceID}
            setToastValues={setToastValues}
            setIsDisabled={setIsDisabled}
          />
        </APModal>
      )}
      <APToast
        isOpen={toastValues.show}
        type={toastValues.type}
        onClose={() => setToastValues({...toastValues, show: false})}
        fadeOutTime={5000}
      >
        <div>{toastValues.message}</div>
      </APToast>
    </>
  );
};

MarkQuickBillAsPaidButton.propTypes = {
  invoiceID: string,
  isDisabled: bool,
  title: string,
  setIsDisabled: func
};

export default MarkQuickBillAsPaidButton;