export const getSelectedCustomFieldsWithoutSections = fields => {
  const filteredFields = {};

  for(const key in fields) {
    if(fields[key] && fields[key] !== 'section') {
      filteredFields[key] = fields[key];
    }
  }

  return filteredFields;
};