import Env from './Env';
// import Env from '../../../../../lib/environment'

const Config = {
  production: {
    accessTokenUrl: 'https://identity.affinipay.com/oauth/authorize?client_id=cda288386b7279082cac806a243a24fb9cf1cc7435e644794122ce2429f885f4&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default',
    pciCompliancesUrl: 'https://api.affinipay.com/pci-compliances',
    bulkOperation: 'https://api.affinipay.com/v2/pci-compliances/bulk-operation',
    referralPartnersUrl: 'https://api.affinipay.com/v2/partners'
  },
  staging: {
    accessTokenUrl: 'https://identity.staging.affinipay.com/oauth/authorize?client_id=0c6d8940773edfc0e472a61ba838b4e5cc75acbc96ad8828cd724bde696cd79f&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default',
    pciCompliancesUrl: 'https://api.staging.affinipay.com/pci-compliances',
    bulkOperation: 'https://api.staging.affinipay.com/v2/pci-compliances/bulk-operation',
    referralPartnersUrl: 'https://api.staging.affinipay.com/v2/partners'
  },
  development: {
    accessTokenUrl: 'http://localhost:5000/oauth/authorize?client_id=0c6d8940773edfc0e472a61ba838b4e5cc75acbc96ad8828cd724bde696cd79f&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_type=token&scope=default',
    pciCompliancesUrl: 'http://localhost:4000/pci-compliances',
    bulkOperation: 'http://localhost:4000/v2/pci-compliances/bulk-operation',
    referralPartnersUrl: 'http://localhost:4000/v2/partners'
  }
};

export default Config[Env];
