// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeAmount Component shallow render 1`] = `
<div
  className="row"
>
  <div
    className="col-sm-6"
  >
    <APInputMonetary
      currencySymbol="$"
      dataset={{}}
      disabled={false}
      error=""
      hasAddon={true}
      id="amount"
      label="Amount"
      maskOptions={{}}
      name="amount_user_input"
      onChange={[Function]}
      padDecimals={true}
      parentClass="amount-frequency"
      placeholder="Enter amount"
      required={false}
      value=""
    />
    <ForwardRef
      id="monetaryAmount"
      name="recurring_charge[amount]"
      type="hidden"
      value=""
    />
  </div>
</div>
`;
