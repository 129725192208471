export const urls = {
  createFeature: '/admin/feature_toggles',
  createFeatureGate: (key) =>  `/admin/feature_toggles/${key}/feature_gates`,
  getFeatureGates: (key) => `/admin/feature_toggles/${key}/feature_gates`,
  deleteFeature: (key) => `/admin/feature_toggles/${key}`,
  deleteFeatureGate: (key, id) =>  `/admin/feature_toggles/${key}/feature_gates/${id}`,
  enableFeature: (key) => `/admin/feature_toggles/${key}/activator`,
  disableFeature: (key) => `/admin/feature_toggles/${key}/deactivator`
};

export const featureTableHeaderData = [
  {Header: 'ID', accessor: 'id'},
  {Header: 'Feature Name', accessor: 'key'},
  {Header: 'Created At', accessor: 'created_at'}
];

export const gateTableHeaderData = [
  {Header: 'ID', accessor: 'id'},
  {Header: 'Name', accessor: 'value'},
  {Header: 'Created At', accessor: 'created_at'}
];

export const strings = {
  sectionTitle: 'Feature Toggles',
  addFeatureModalTitle: 'Add Feature',
  addGateModalTitle: 'Add Actor',
  deleteGateModalTitle: 'Disable Feature for this Actor',
  filterInputLabel: 'Filter by name',
  featureNameLabel: 'Feature Name',
  actorNameLabel: 'Actor Name',
  noRecords: 'No Records Found',
  featureFullyEnabled: 'Feature is enabled for all actors',
  featureGatesErrorText: 'Error retrieving feature actors',
  deleteFeatureError: 'Error deleting feature',
  enableFeatureError: 'Error enabling feature for all actors',
  disableFeatureError: 'Error disabling feature for all actors',
  createFeatureError: 'Error creating feature',
  createFeatureGateError: 'Error enabling feature for actor',
  deleteFeatureGateError: 'Error removing actor from feature',
  featureGateRecommendationText: 'Recommended naming conventions: Upper case with ID. Good: Admin3, User10. Bad: user10, admin_3, user-10, admin.',
  featureRecommendationText: 'Recommended naming conventions: lower case, snake case, underscores over dashes. Good: foo_bar, foo. Bad: FooBar, Foo Bar, foo bar, foo-bar.',
  deleteFeatureWarningText: 'WARNING: Deleting a feature removes it from the list of features and disables it for everyone.',
  enableButtonText: 'Enable feature for all actors',
  disableButtonText: 'Disable feature for all actors',
  deleteButtonText: 'Delete Feature',
  cancelButtonText: 'Cancel',
  yesEnableButtonText: 'Yes, enable',
  yesDisableButtonText: 'Yes, disable',
  recordCountText: (num) => `Currently enabled for ${num} actor(s)`,
  deleteGateModalText: (actor) => `Remove ${actor} from this feature?`,
  deleteFeatureModalTitle:  'Remove this feature?',
  enableDisableFeatureModalTitle:  'Confirmation Required',
  enableAllFeatureModalText:  'Are you sure you want to enable this feature for ALL actors?',
  disableAllFeatureModalText:  'Are you sure you want to disable this feature for ALL actors?'

};

export const initGateTableState = {
  loading: true,
  error: null,
  featureGates: [],
  selectedFeatureGate: null,
  page: 1,
  totalRecordCount: 0,
  filteredRecordCount: 0,
  sortBy: null,
  sortOrder: 'asc',
  nameFilter: ''
};
