import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import ChangeApplication from './change_application';
import client from 'lib/ajax';
import notify from 'lib/notify';
import userEvent from '@testing-library/user-event';

describe('admin/merchants/change_application', () => {
  beforeAll(() => {
    jest.spyOn(notify, 'success').mockImplementation(() => {});
    jest.spyOn(notify, 'error').mockImplementation(() => {});
  });

  it('renders the change application popover with input and submit button', () => {
    render(<ChangeApplication {...getMockProps('editable')} />);
    expect(screen.getByText('Merchant Application')).toBeVisible();
    expect(screen.getByText('Change Application')).toBeVisible();
    userEvent.click(screen.getByText('Change Application'));
    const idInput = screen.getByRole('textbox');
    const submitButton = screen.getByText('Submit');
    expect(idInput).toBeVisible();
    expect(idInput).toHaveValue('');
    expect(submitButton).toBeVisible();
    expect(submitButton).toBeDisabled();
    userEvent.type(idInput, '123');
    expect(idInput).toHaveValue('123');
    expect(submitButton).not.toBeDisabled();
  });

  it('renders none when merchant is not attached to a merchant app', () => {
    render(<ChangeApplication {...getMockProps('editable')} applicationId={null}/>);
    expect(screen.queryByTestId('current-merchant-application-link')).not.toBeInTheDocument();
    expect(screen.getByText('None')).toBeVisible();
  });

  it('renders link to current merchant app id', () => {
    render(<ChangeApplication {...getMockProps('editable')}/>);
    expect(screen.queryByTestId('current-merchant-application-link')).toHaveAttribute('href', '/admin/merchant_applications/1');
    expect(screen.queryByText('None')).not.toBeInTheDocument();
  });

  it('Shows success on successfully submit and updates merchant app id', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({merchant_application_id: 123, merchant_applications: [{id: 7, current_state: 'signup'}, {id: 9, current_state: 'completed'}]});
        })
      })
    );
    render(<ChangeApplication {...getMockProps('editable')} />);
    userEvent.click(screen.getByText('Change Application'));
    const idInput = screen.getByRole('textbox');
    const submitButton = screen.getByText('Submit');
    userEvent.type(idInput, '123');
    expect(submitButton).not.toBeDisabled();
    userEvent.click(submitButton);
    expect(submitButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledTimes(0);
    expect(client.patch).toHaveBeenLastCalledWith('/admin/merchants/1/application', {application_id: '123', overwriteMerchantData: false});
    expect(screen.queryByTestId('current-merchant-application-link')).toHaveAttribute('href', '/admin/merchant_applications/123');
  });

  it('Renders errors when server responds with 422', async () => {
    const error = {message: 'message', errors: 'err'};
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(error);
        })
      })
    );
    render(<ChangeApplication {...getMockProps('editable')} />);
    userEvent.click(screen.getByText('Change Application'));
    const idInput = screen.getByRole('textbox');
    const submitButton = screen.getByText('Submit');
    userEvent.type(idInput, '123');
    expect(submitButton).not.toBeDisabled();
    userEvent.click(submitButton);
    expect(submitButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(0);
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenLastCalledWith(error.message, error.errors);

  });


  it('Renders errors when server responds with 401', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 401,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({merchant_application_id: 1});
        })
      })
    );
    render(<ChangeApplication {...getMockProps('editable')} />);
    userEvent.click(screen.getByText('Change Application'));
    const idInput = screen.getByRole('textbox');
    const submitButton = screen.getByText('Submit');
    userEvent.type(idInput, '123');
    expect(submitButton).not.toBeDisabled();
    userEvent.click(submitButton);
    expect(submitButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(0);
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenLastCalledWith('Not Authorized');
  });

  it('Renders errors when server responds with other error code', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 999,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({message: 'msg', errors:'err'});
        })
      })
    );
    render(<ChangeApplication {...getMockProps('editable')} />);
    userEvent.click(screen.getByText('Change Application'));
    const idInput = screen.getByRole('textbox');
    const submitButton = screen.getByText('Submit');
    userEvent.type(idInput, '123');
    userEvent.click(submitButton);
    expect(submitButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(0);
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenLastCalledWith('Something went wrong. Please contact Dev Team.');
  });

  it('Renders errors when server throws an error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() => {
      throw new Error();
    });
    render(<ChangeApplication {...getMockProps('editable')} />);
    userEvent.click(screen.getByText('Change Application'));
    const idInput = screen.getByRole('textbox');
    const submitButton = screen.getByText('Submit');
    userEvent.type(idInput, '123');
    userEvent.click(submitButton);
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(0);
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenLastCalledWith('Something went wrong. Please contact Dev Team.');
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'standard':
    return {
      paths: {
        merchantApplication: 'TestURL'
      },
      underwriting: true,
      merchantApplications: [{id: 7, current_state: 'signup'}, {id: 9, current_state: 'completed'}]
    };
  case 'editable':
    return {
      editable: true,
      applicationId: 1,
      paths: {
        merchantApplication: '/admin/merchant_applications/{id}',
        merchant: '/admin/merchants/1/application'
      },
      underwriting: true,
      merchantApplications: [{id: 7, current_state: 'signup'}, {id: 9, current_state: 'completed'}]
    };
  default:
    return;
  }
};
