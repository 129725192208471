import React from 'react';
import './style.scss';
import { APButton } from 'affinipay-ui-library';
import { sendEvent, EventNames } from '../../lib/intercom';
import { PropTypes } from 'prop-types';


const ReferralButtonComponent = ({
  site,
  link
}) => {
  const handleReferralButton = () => {
    sendEvent(EventNames.referralButtonClicked, { site: site });
    window.open(link);
  };

  return (
    <div className="referral-container">
      <APButton
        className="referral-button ap-secondary-button uppercase"
        onClick={handleReferralButton}>Refer a Colleague
      </APButton>
    </div>
  );
};

ReferralButtonComponent.propTypes = {
  site: PropTypes.string,
  link: PropTypes.string
};

export default ReferralButtonComponent;