//not exposed in production
import React from 'react';
import {array, string} from 'prop-types';
import { APInputText, APSelect } from 'affinipay-ui-library';

const ChangePlanSection = ({sites, planName, planDescription}) => (
  <section>
    <div className="row form-group">
      <div className="col-xs-4">
        <APSelect 
          disabled={true}
          label='Site'
          options={sites}
          parentClass="admin"
        />
      </div>
      <div className="col-xs-4">
        <APInputText 
          disabled={true}
          label="Name(Token)"
          parentClass="admin"
          value={planName}
        />
      </div>
      <div className="col-xs-4">
        <APInputText 
          disabled={true}
          label="Internal Description"
          parentClass="admin"
          value={planDescription}
        />
      </div>
    </div>
  </section>
);

export default ChangePlanSection;

ChangePlanSection.propTypes = {
  sites: array,
  planName: string,
  planDescription: string
};
