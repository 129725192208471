// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`InteractiveSocialSecurityNumber business owner social security shallow snapshot render 1`] = `
<BusinessOwnerSocialSecurityNumber
  displayPolicy={true}
  maskedSocialSecurityNumber="***-**-6789"
  requestUrl="/admin/merchant_applications/200/business_owner_social_security_numbers/1"
  socialSecurityNumber="***-**-6789"
  toggle={[Function]}
/>
`;

exports[`InteractiveSocialSecurityNumber owner social security shallow snapshot render 1`] = `
<OwnerSocialSecurityNumber
  disabled={true}
  isMerchantApplicationOwner={true}
  maskedSocialSecurityNumber="***-**-6789"
  readonly={true}
  requestUrl="/admin/merchant_applications/200/owner_social_security_numbers"
  socialSecurityNumber="***-**-6789"
  toggle={[Function]}
/>
`;
