import { APButton, APInputMonetary, APInputText, APModal, APSelect, APFormMessageBox } from 'affinipay-ui-library';
import client from 'lib/ajax';
import { isEmailPattern } from '../../../lib/utils';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import notify from 'lib/notify';
import './styles.scss';
import { getCentsFromMaskedMoneyInput } from '../../../lib/monetaryUtils';

const initialFormState = {
  account_id: '',
  amount: '',
  reference: '',
  email: ''
};

const formValidator = (formState) => {
  const requiredFields = [formState.account_id, formState.amount].every(item => item);
  const emailValid = formState.email ? isEmailPattern(formState.email) : true;
  return requiredFields && emailValid;
};

const FeeCollectionModal = ({ achAccounts, merchant }) => {
  const [formState, setFormState] = useState(initialFormState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [emailError, setEmailError] = useState(false);

  const isFormValid = useMemo(() => formValidator(formState), [formState.account_id, formState.amount, formState.email]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    toggleModal();
    setIsSubmitting(false);
    setFormState(initialFormState);
    setEmailError(false);
    setSubmitError('');
  };

  const onFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmailError(false);
    setFormState(prevState => ({...prevState, [name]: value}));
  };

  const onEmailBlur = (e) => {
    const { value } = e.target;
    const isValidEmail = isEmailPattern(value);
    if (value && !isValidEmail) setEmailError(true);
    else setEmailError(false);
  };

  const save = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');
    try {
      const resp = await client.post(`/admin/merchants/${merchant.id}/manual_fee_collections`, {...formState, amount: getCentsFromMaskedMoneyInput(formState.amount)});
      const json = await resp.json();
      if (resp.ok) {
        closeModal();
        notify.success('Charge successfully submitted. Charges made through manual fee collection can take up to 5 minutes to appear in the Transactions page.');
      }
      else {
        setSubmitError(json.error);
        setIsSubmitting(false);
      }
    }
    catch (e) {
      setSubmitError('Error creating charge');
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <button onClick={toggleModal} className='btn btn-primary'>
        Collect
      </button>
      <APModal isActiveInitial={isModalOpen} onClose={toggleModal} headerText='Fee Collection' className='fee-collection-modal'>
        <form className='fee-collection-form' onSubmit={save}>
          {submitError &&
            <APFormMessageBox type="error">
              {submitError}
            </APFormMessageBox>
          }
          <div className='fee-collection-form-row'>
            <APSelect
              label='Account'
              name='account_id'
              value={formState.account_id}
              options={achAccounts}
              required={true}
              onChange={onFormChange}
              needsNativeElement={true}
            />
            <APInputMonetary
              label='Amount'
              value={formState.amount}
              name='amount'
              onChange={onFormChange} 
              required={true}
            />
          </div>
          <div className='fee-collection-form-row'>
            <APInputText
              label='Receipt Email'
              value={formState.email}
              name='email'
              onChange={onFormChange}
              onBlur={onEmailBlur}
              error={emailError ? 'is invalid' : ''}
            />
            <APInputText
              label='Reference'
              value={formState.reference}
              name='reference'
              type='text'
              onChange={onFormChange} 
            />
          </div>
          <div className='fee-collection-submit-row'>
            <APButton
              className='ap-secondary-button'
              onClick={closeModal}
              disabled={isSubmitting}
              type='button'
            >
              Cancel
            </APButton>
            <APButton
              className='ap-button ap-primary-button'
              disabled={isSubmitting || !isFormValid }
            >
              Submit
            </APButton>
          </div>
        </form>

      </APModal>
    </>
  );
};

export default FeeCollectionModal;

FeeCollectionModal.propTypes = {
  achAccounts: PropTypes.array,
  merchant: PropTypes.object
};