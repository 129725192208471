import React from 'react';
import {string} from 'prop-types';
import IconViewed from './icons/icon-viewed';
import IconEQB from './icons/icon-eqb';
import DraftIcon from './icons/icon-ready';
import PaymentMethodIcon from './PaymentMethodIcon';
import QuickBillStatistic from './QuickBillStatistic';

const StatusViewed = () => (
  <>
    <IconViewed className="quick-bill-statistic-icon"/> Viewed
  </>
);

const StatusPaid = ({ paymentMethod }) => (
  <PaymentMethodIcon paymentMethod={ paymentMethod }/>
);

const StatusSent = () => (
  <>
    <IconEQB className="quick-bill-statistic-icon" size="20" /> Sent
  </>
);

const StatusUnsent = () => (
  <><DraftIcon className="quick-bill-statistic-icon"/> Draft </>
);

const QuickBillStatusIndicator = ({ presentmentStatus, paymentMethod }) => {
  const statusIndicatorOptions = {
    viewed: <StatusViewed />,
    paid: <StatusPaid paymentMethod={ paymentMethod } />,
    sent: <StatusSent />
  };
  const StatusIndicator = presentmentStatus in statusIndicatorOptions
    ? statusIndicatorOptions[presentmentStatus]
    : <StatusUnsent />;

  return <QuickBillStatistic label="Status" value={StatusIndicator} />;
};

StatusPaid.propTypes = {
  paymentMethod: string
};

QuickBillStatusIndicator.propTypes = {
  presentmentStatus: string,
  paymentMethod: string
};

export default QuickBillStatusIndicator;
