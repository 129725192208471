import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import client from 'lib/ajax';
import notify from 'lib/notify';
import './style.scss';

const ResendPopover = (props) => {
  return (
    <Popover id="popover-basic" title="Send Quick Bill" placement="top">
      <div>
        <p>Are you sure you want to resend this Quick Bill?</p>
        <div>
          <input
            onChange={props.handleChange}
            type="email"
            className="form-control"
            value={props.email}
          />
        </div>

        <button
          onClick={props.handleSubmit}
          type="button"
          className="btn btn-success pull-right confirm-button"
        >
          Confirm
        </button>
      </div>
    </Popover>
  );
};

ResendPopover.propTypes = {
  email: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default class Resend extends React.Component {
  static propTypes = {
    email: PropTypes.string,
    path: PropTypes.string,
  };

  state = {
    email: this.props.email,
  };

  constructor(props) {
    super(props);
  }

  handleChange = (e) => this.setState({ email: e.target.value });

  close = () => document.body.click();

  reset = () =>
    this.setState(
      {
        email: this.props.email,
      },
      () => this.close()
    );

  handleResponse = (resp) => {
    const status = resp.status;

    resp.json().then((json) => {
      switch (status) {
        case 200:
        case 201:
          notify.success(json.message);
          this.close();
          break;
        case 401:
        case 403:
          notify.error('Not Authorized');
          break;
        case 404:
        case 422:
          notify.error(json.message, json.errors);
          break;
        default:
          notify.error(
            json.message || 'Something went wrong. Please contact Dev Team.'
          );
      }
    });
  };

  handleSubmit = () => {
    client
      .post(this.props.path, {
        email: this.state.email,
      })
      .then(this.handleResponse);
  };

  render() {
    return (
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={ResendPopover({
          handleChange: this.handleChange,
          email: this.state.email,
          handleSubmit: this.handleSubmit,
        })}
        onExited={this.reset}
        rootClose={true}
      >
        <button type="button" className="btn btn-primary pull-right">
          Resend Bill
        </button>
      </OverlayTrigger>
    );
  }
}
