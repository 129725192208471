import React, {useEffect, useState} from 'react';
import {bool, func} from 'prop-types';
import IconUpload from '../../icons/icon-upload';
import './style.scss';

const colors = {
  blue80: '#003566',
  blue60: '#0069cc',
  grey20: '#c7cdd1',
  grey90: '#171a1c',
  white: '#fff'
};

const staticBtnStyle = {
  color: colors.blue80,
  backgroundColor: colors.white,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: colors.blue80,
  cursor: 'pointer'
};

const activeBtnStyle = {
  color: colors.white,
  backgroundColor: colors.blue60,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: colors.blue60,
  cursor: 'pointer'
};

const disabledBtnStyle = {
  color: colors.grey20,
  backgroundColor: colors.white,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: colors.grey20,
  cursor: 'not-allowed'
};

const AttachmentsButton = ({disabled, onChange}) => {
  const [buttonStyle, setButtonStyle] = useState(staticBtnStyle);
  useEffect(() => {
    setButtonStyle(disabled ? disabledBtnStyle : staticBtnStyle);
  }, [disabled]);
  const handleFocus = () => setButtonStyle(activeBtnStyle);
  const handleBlur = () => setButtonStyle(staticBtnStyle);
  const targetingName = 'quick-bill-attachment-btn';

  return (
    <label
      htmlFor={targetingName}
      className={targetingName}
      data-testid={targetingName}
      style={buttonStyle}
      onFocus={disabled ? undefined : handleFocus}
      onBlur={disabled ? undefined : handleBlur}
      onMouseEnter={disabled ? undefined : handleFocus}
      onMouseLeave={disabled ? undefined : handleBlur}
      tabIndex="0"
    >
      <IconUpload fill={buttonStyle.color} width={20} height={20} /> Choose File
      <input
        id={targetingName}
        className={targetingName}
        type="file"
        role="button"
        aria-label="Attachment Upload"
        aria-disabled={disabled}
        disabled={disabled}
        onChange={disabled ? undefined : onChange}
      />
    </label>
  );
};

AttachmentsButton.propTypes = {
  disabled: bool,
  onChange: func.isRequired
};

export default AttachmentsButton;
