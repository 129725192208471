import React from 'react';
import {func, string} from 'prop-types';
import {APButton} from 'affinipay-ui-library';

const EcheckButton = ({echeckUrl, onClick}) => {
  let button = null;
  if (echeckUrl) {
    button = <div className="header-echeck">
      <APButton
        className="ap-secondary-button"
        onClick={onClick}
        type="button"
      >
        <span>eCheck<div id="echeck-icon" /></span>
      </APButton>
    </div>;
  }
  return button;
};

EcheckButton.propTypes = {
  echeckUrl: string,
  onClick: func
};

export default EcheckButton;
