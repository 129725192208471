import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import * as utils from '../../lib/utils';
import CrossSellTagButton from '.';


const affinipayUrl = 'https://www.affinipayassociations.com/payments?utm_campaign=vt-features-link&utm_medium=web&utm_source=cust&utm_content=payments';
const lawpayUrl = 'https://lp.mycase.com/schedule-demo-lawpay/?utm_campaign=lp-lawpayappdemo&utm_medium=web&utm_source=in-app&utm_content=demo';
const cpachargeUrl = 'https://www.cpacharge.com/partners/integrated-partners/?utm_campaign=cpa-jira23&utm_medium=in-app&utm_source=cpacharge&utm_content=in-app';
const clientpayUrl = 'https://www.clientpay.com/partners?utm_campaign=vt-features-link&utm_medium=web&utm_source=cust&utm_content=partners';

const affinipayCrossSellTagClicked = {
  location: 'AffiniPay cross-sell',
  event: 'click'
};

const lawpayCrossSellTagClicked = {
  location: 'LawPay cross-sell',
  event: 'click'
};

const cpachargeCrossSellTagClicked = {
  location: 'CPACharge cross-sell',
  event: 'click'
};

const clientpayCrossSellTagClicked = {
  location: 'ClientPay cross-sell',
  event: 'click'
};

describe('Cross Sell Tag Button Component', () => {

  beforeAll(() => {
    window.open = jest.fn().mockImplementationOnce(() => ({...window}));
  });

  afterAll(() => {
    window.open.mockClear();
  });

  it('renders without crashing', () => {
    render(<CrossSellTagButton siteTitle={'AffiniPay'} text='Explore More' url={affinipayUrl} />);
    expect(screen.getByTestId('cross-sell-tag')).toBeVisible();
  });

  it('Cross sell tag button is clicked and opens a tab for affinipay and sends GA event', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(affinipayCrossSellTagClicked);
        })
      })
    );

    render(<CrossSellTagButton siteTitle={'AffiniPay'} text='Explore More' url={affinipayUrl} />);

    await userEvent.click(screen.getByText('EXPLORE MORE'));

    expect(window.open).toHaveBeenCalledWith(affinipayUrl, '_self');
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('AffiniPay cross-sell');
    expect(safeSendGA.mock.calls[0][1]).toBe('click');
  });

  it('Cross sell tag button is clicked and opens a tab for lawpay and sends GA event', async () => {

    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(lawpayCrossSellTagClicked);
        })
      })
    );

    render(<CrossSellTagButton siteTitle={'LawPay'} text='Enhance your Firm' url={lawpayUrl} />);

    await userEvent.click(screen.getByText('ENHANCE YOUR FIRM'));

    expect(window.open).toHaveBeenCalledWith(lawpayUrl, '_self');
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('LawPay cross-sell');
    expect(safeSendGA.mock.calls[0][1]).toBe('click');
  });

  it('Cross sell tag button is clicked and opens a tab for cpacharge and send GA event', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(cpachargeCrossSellTagClicked);
        })
      })
    );

    render(<CrossSellTagButton siteTitle={'CPACharge'} text='Explore More' url={cpachargeUrl} />);

    await userEvent.click(screen.getByText('EXPLORE MORE'));

    expect(window.open).toHaveBeenCalledWith(cpachargeUrl, '_self');
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('CPACharge cross-sell');
    expect(safeSendGA.mock.calls[0][1]).toBe('click');
  });

  it('Cross sell tag button is clicked and opens a tab for clientpay and sends GA event', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(clientpayCrossSellTagClicked);
        })
      })
    );

    render(<CrossSellTagButton siteTitle={'ClientPay'} text='Explore More' url={clientpayUrl} />);

    await userEvent.click(screen.getByText('EXPLORE MORE'));

    expect(window.open).toHaveBeenCalledWith(clientpayUrl, '_self');
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('ClientPay cross-sell');
    expect(safeSendGA.mock.calls[0][1]).toBe('click');
  });

  it('does not send cross sell tag clicked GA event if button is called from admin page', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(affinipayCrossSellTagClicked);
        })
      })
    );

    render(<CrossSellTagButton siteTitle={'Affinipay'} text='Explore More' url={affinipayUrl} fromAdmin={true} />);

    await userEvent.click(screen.getByText('EXPLORE MORE'));
    expect(safeSendGA).not.toHaveBeenCalled();
  });
});