import notify from './notify';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {keyPressCodes, getDeleteKey, emailRegex} from './constants';


export const isNumber = obj => Object.prototype.toString.call(obj) === '[object Number]';

export const getDateFromHyphenedFormat = date => new Date(date.toString().replace(/-/g, '/'));

export const hasActiveClass = el => {if(el && el.className) { return el.className.indexOf('active') !== -1;}};

export const removeActiveClass = (el) => { if (el && el.className && typeof(el.className) === 'string') { el.className = el.className.replace(' active', ''); } };

export const addActiveClass = (el) => { if (el) { el.className = el.className + ' active'; } };

export const addInvalid = (el) => { if (el && !hasClass(el, 'invalid')) { addClass(el, 'invalid'); } };

export const removeInvalid = (el) => { if (el && hasClass(el, 'invalid')) { removeClass(el, 'invalid'); } };

export const addClass = (el, classTitle) => { if (el && !hasClass(el, classTitle)) { el.className += ` ${classTitle}`; }};

export const removeClass = (el, classTitle) => { if (el && el.classList) { el.classList.remove(`${classTitle}`); } };

export const hasClass = (el, className) => { if(el && el.className && typeof el.className === 'string') {return el.className.includes(className);}};

export const switchActiveClass = (el) => {
  if (el) {
    if (hasActiveClass(el)) {
      removeActiveClass(el);
    } else {
      addActiveClass(el);
    }
  }
};

export const addFocus = el => {
  if (el && !hasActiveClass(el)) {
    addActiveClass(el);
  }
};

export const removeFocus = el => {
  if (el && hasActiveClass(el)) {
    removeActiveClass(el);
  }
};

export const outputError = (text) => {
  try {
    notify.error(text);
  }
  catch(e) {
    console.error(text);
  }
};

export const amountMask = createNumberMask({
  prefix: '',
  allowDecimal: true
});

export const setSelectedInAPSelect = (options, toSelect) => {
  return options.map((el) => {
    let newEl;
    if(el.value === toSelect) {
      newEl = {...el, selected: true};
    }else if(el.selected === true && el.value !== toSelect){
      delete el.selected;
      newEl = {...el};
    } else {
      newEl={...el};
    }
    return newEl;
  });
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isInsertKeyPressed = (e) => {
  const pressedKey = e.keyCode;

  for (let key in keyPressCodes) {
    if (keyPressCodes[key] === pressedKey) {
      return true;
    }
  }
};

export const isDeleteKeyPressed = (e) => {
  const pressedKey = e.keyCode;

  if (pressedKey === getDeleteKey) {
    return true;
  }
};

export const isFieldRequired = (name, optionalFields) => {
  if(!name || !optionalFields){
    console.error('isFieldRequired needs both parameters');
    return;
  }
  return optionalFields.indexOf(name) === -1;
};

export const isEmailPattern = email => {if(!email){return false;} return emailRegex.test(String(email).toLowerCase());};

export const rollbarLog = (caller, message, severity='critical') => {
  try{
    if(isCardNumber(caller)){
      _rollbarLog(`[${caller}] Attempted to log sensitive information in caller parameter`, 'warning');
      return;
    }
    if(message.length > 2000){
      _rollbarLog(`[${caller}] Attempted log is too long`, 'warning');
      return;
    }
    const matches = _getPotentialCardNumbersInString(message);
    for (const match of matches) {
      if(isCardNumber(match[0])){
        _rollbarLog(`[${caller}] Attempted to log sensitive information`, 'warning');
        return;
      }
    }
    _rollbarLog(`[${caller}] ${message}`, severity);
  }catch(e){
    _rollbarLog(`[${caller}] Failed to sanitize log message. Error: ${e}`, 'warning');
  }
};
const _getPotentialCardNumbersInString = str => [...str.replace(/[- ]/g, '').matchAll(/\d{14}|d{15}|d{16}/g)];

const _rollbarLog = (message, severity) => {
  if(window.Rollbar) {
    switch(severity) {
    case 'critical':
      window.Rollbar.critical(message);
      break;
    case 'warning':
      window.Rollbar.warning(message);
      break;
    case 'info':
      window.Rollbar.info(message);
      break;
    case 'debug':
      window.Rollbar.debug(message);
      break;
    default:
      break;
    }
  } else {
    console.error('Rollbar is not initialized. Error:', message, 'Severity: ', severity);
  }
};

export const safeSendGA = (category='', action='', label='') => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  } else {
    rollbarLog(`Failed to send GA 4 event: ${category}, ${action}, ${label}`, 'warning');
  }
};

export const debounce = (func, wait = 0, immediate = false) => {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// TODO: remove this global dependency
export const dateFormat = (timestamp) => {
  if (!window.moment) { return; }
  return moment(timestamp).format('MM/DD/Y'); // eslint-disable-line no-undef
};

export const dateFormatUtc = (timestamp) => {
  if (!window.moment) { return; }
  return moment.utc(timestamp).format('MM/DD/YYYY'); // eslint-disable-line no-undef
};

// from https://stackoverflow.com/a/8834265
export const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

export const isEmptyObject = o => o && Object.keys(o).length === 0 && o.constructor === Object;

export const getDateWithOrdinal = (date) => {
  if (date < 4 || date > 20) {
    switch (date % 10) {
    case 1:
      return `${date}st`;
    case 2:
      return `${date}nd`;
    case 3:
      return `${date}rd`;
    default:
      return `${date}th`;
    }
  } else {
    return `${date}th`;
  }
};

export const getDayOfWeek = () => {
  const now = new Date(),
    days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

  return days[now.getDay()];
};

export const getMonth = () => {
  const now = new Date(),
    months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

  return months[now.getMonth()];
};

const _isCardLength = val => val.length >=14 && val.length <=16;

const _isLuhnValid = val => {
  const checkSum = (val + '').split('').reverse().map((el, i) => {
    const n = parseInt(el);
    if(i%2!==0){
      const doubled = n*2;
      if(doubled > 9) {
        return (doubled+'').split('').reduce((a, b) => Number(a) + Number(b), 0);
      }else {
        return doubled;
      }
    } else {
      return n;
    }
  }).reduce((a, b) => Number(a) + Number(b), 0);
  return checkSum % 10 === 0;
};

export const isCardNumber = val => {
  const str = val+'',
    trimmed = str.replace(/[- ]/g, ''),
    num = Number(trimmed);
  return (isNumber(num) && _isCardLength(trimmed) && _isLuhnValid(trimmed));
};

export const buildQueryStringFromParams = params =>  {
  if (!isObject(params)) return '';
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));
  return Object.keys(filteredParams).map(key => `${key}=${params[key]}`).join('&');
};

export const getUserLocale = () => {
  if (navigator.languages != undefined) {
    return navigator.languages[0] || 'en-US';
  }
  return navigator?.language || 'en-US';
};
