import React from 'react';
import RecurringChargeSchedule from './index';
import { shallow, mount } from 'enzyme';

describe('RecurringChargeSchedule Component', () => {
  it('shallow snapshot render', () => {
    const mockProps = getMockProps('new');
    const component = shallow(<RecurringChargeSchedule {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow monthly edit render', () => {
    const mockProps = getMockProps('edit');
    const component = shallow(<RecurringChargeSchedule {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow weekly edit render', () => {
    let mockProps = getMockProps('edit');
    mockProps.recurFrequency.intervalUnit = 'WEEK';
    const component = shallow(<RecurringChargeSchedule {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow daily edit render', () => {
    let mockProps = getMockProps('edit');
    mockProps.recurFrequency.intervalUnit = 'DAY';
    const component = shallow(<RecurringChargeSchedule {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow bi monthly new render', () => {
    let mockProps = getMockProps('edit bi monthly');
    const component = shallow(<RecurringChargeSchedule {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow new render with recurInitial', () => {
    let mockProps = getMockProps('new no start date');
    const component = shallow(<RecurringChargeSchedule {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('mount to test events', () => {
    const mockProps = getMockProps('new'),
      component = mount(<RecurringChargeSchedule {...mockProps} />);
    component.find('RecurringChargeFrequency').prop('handleChange')({
      test: 'test'
    });
    component.find('RecurringChargeFrequency').prop('handleChange')({
      scheduleStartDate: new Date('10/09/2019'),
      recurFrequency: 'DAY',
      scheduleEndDate: new Date('10/09/2019'),
      recurStart: new Date('10/09/2019')
    });
    expect(component).toBeTruthy();
    component.unmount();
  });
  it('tests currentPage copy flow', () => {
    Date.now = jest.fn(() => new Date(Date.UTC(2018, 7, 9, 8)).valueOf());
    const mockProps = getMockProps('copy'),
      component = mount(<RecurringChargeSchedule {...mockProps} />);
    expect(component.state('selectedDay')).toEqual(new Date('2018-08-09T08:00:00.000Z'));
    component.unmount();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'new':
    return {
      items: [
        { 
          text: 'One Time', 
          value: 'DAY'
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        { 
          text: 'Twice a Month',
          value: 'BI_MONTHLY' 
        },
        { 
          text: 'Monthly / Every X Months',
          value: 'MONTHLY' 
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ],
      recurFrequency: {
        schedule: {
          start: '2019-9-30  09:30:00',
          intervalDelay: '1',
          intervalUnit: 'MONTH',
          maxAmount: '0.00'
        }
      },
      newRecord: true,
      recurInitial: '09/30/2019  09:30:00',
      amount: null,
      maxOccurrences: null,
      isComplete: false
    };
  case 'new no start date':
    return {
      items: [
        { 
          text: 'One Time', 
          value: 'DAY' 
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        { 
          text: 'Twice a Month', 
          value: 'BI_MONTHLY'
        },
        { 
          text: 'Monthly / Every X Months', 
          value: 'MONTHLY'
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ],
      recurFrequency: {
        schedule: {
          intervalDelay: '1',
          intervalUnit: 'MONTH',
          maxAmount: '0.00'
        }
      },
      newRecord: true,
      recurInitial: '09/30/2019  09:30:00',
      amount: null,
      maxOccurrences: null,
      isComplete: false
    };
  case 'copy':
    return {
      items: [
        { 
          text: 'One Time',
          value: 'DAY'
        },
        {
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        { 
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        { 
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        {
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ],
      recurFrequency: {
        schedule: {
          start: '2019-9-30  09:30:00',
          intervalDelay: '1',
          intervalUnit: 'MONTH',
          maxAmount: '0.00'
        }
      },
      newRecord: true,
      recurInitial: '09/30/2019  09:30:00',
      amount: null,
      maxOccurrences: null,
      isComplete: false,
      currentPage: 'copy'
    };

  case 'edit':
    return {
      items: [
        { 
          text: 'One Time',
          value: 'DAY'
        },
        { 
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        { 
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        { 
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        { 
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ],
      recurFrequency: {
        start: '2019-9-30  09:30:00',
        intervalDelay: '1',
        intervalUnit: 'MONTH'
      },
      newRecord: false,
      recurInitial: '09/30/2019  09:30:00',
      amount: '6.00',
      maxOccurrences: null,
      isComplete: false
    };
  case 'edit bi monthly':
    return {
      items: [
        { 
          text: 'One Time',
          value: 'DAY'
        },
        { 
          text: 'Weekly / Every X Weeks',
          value: 'WEEKLY'
        },
        { 
          text: 'Twice a Month',
          value: 'BI_MONTHLY'
        },
        { 
          text: 'Monthly / Every X Months',
          value: 'MONTHLY'
        },
        { 
          text: 'Yearly / Every X Years',
          value: 'YEAR'
        }
      ],
      recurFrequency: {
        start: '2019-9-30  09:30:00',
        days: ['30'],
        intervalDelay: '1',
        intervalUnit: 'MONTH'
      },
      newRecord: false,
      recurInitial: '09/30/2019  09:30:00',
      amount: '6.00',
      maxOccurrences: null,
      isComplete: false
    };
  default:
    return;
  }
};
