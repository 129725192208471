import React from 'react';
import { string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import { safeSendGA } from '../../lib/utils';
import './style.scss';

const ClientCreditTextBox = ({
  site,
  clientCreditStatus,
  merchantState
}) => {

  const sendLinkClickedGAEvent = () => {
    if (site == 'lawpay') {
      safeSendGA('ClientCredit box', 'ChargeScreen_CLC_LawPay');
    } else {
      safeSendGA('ClientCredit box', 'ChargeScreen_CLC_CPA');
    }
  };

  const sendSignUpGAEvent = () => {
    safeSendGA('ClientCredit box', 'ChargeScreen_CLC_CA_LawPay');
    window.open('https://survey.survicate.com/ff477305422c4e48/', '_blank');
  };

  const isLawpaySite = site === 'lawpay';
  return (
    <div className='client-credit-textbox'>
      <div className={`clientcredit-box-logo ${site}`}></div>
      <p>
        {`${isLawpaySite ? 'LawPay' : 'CPACharge'} has partnered with Affirm to bring you ${isLawpaySite ? 'a legal' : 'an accounting'} fee lending solution that makes it possible to provide additional payment flexibility to your clients.`}
      </p>
      <p className='paragraph-head'>
        Why Pay Later:
      </p>
      <ul className='benefits-list'>
        <li>Get paid 100% up front, improving your cash flow in comparison to a traditional scheduled payment plan and mitigating the risk of non-payment</li>
        <li>Reduce time chasing after client payments</li>
        <li>Minimize your outstanding account receivables</li>
      </ul>
      { site === 'lawpay' && merchantState === 'CA' && clientCreditStatus === 'unknown' ?
        <div>
          <p className='signup-instruction'>
            <b>Click the button below to have Pay Later added to your LawPay account!</b>
          </p>
          <APButton
            disabled={false}
            onClick={sendSignUpGAEvent}
            className='ap-secondary-button'
            type="button"
          >
          Sign Up
          </APButton>
        </div>
        :
        <p>
          <a
            href={ site == 'lawpay' ? 'https://www.lawpay.com/features/clientcredit/' : 'https://www.cpacharge.com/features/clientcredit/' }
            target="_blank"
            className='learn-more-link'
            onClick={sendLinkClickedGAEvent} rel="noreferrer"
          >
            Learn more
          </a>
        </p>
      }
    </div>
  );
};

ClientCreditTextBox.propTypes = {
  site: string.isRequired,
  clientCreditStatus: string.isRequired,
  merchantState: string.isRequired
};

export default ClientCreditTextBox;