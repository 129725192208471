const AccessToken = ({
  access_token: accessToken,
  created_at: createdAt,
  error,
  expires_in: expiresIn,
  scope,
  token_type: tokenType
}) => {
  return {
    accessToken,
    createdAt,
    error: error === '' ? 'Identity service error' : error,
    expiresIn,
    scope,
    tokenType
  };
};

export default AccessToken;