/* eslint-disable no-unused-vars */

import { getDateWithOrdinal } from '../../../lib/utils';

export const allowedURLParams = [
  'amount', 
  'reference', 
  'email', 
  'recur_frequency',
  'name', 
  'address1', 
  'address2', 
  'city', 
  'state', 
  'postal_code', 
  'country',
  'phone', 
  'Invoice' // capital I is intentional here
];

export const basicFrequencyOptions = [
  { text: 'One-Time Payment', value: 'NONE' },
  { text: 'Every Week', value: 'WEEKLY' },
  { text: 'Every Two Weeks', value: 'BI_WEEKLY' },
  { text: 'Every Month', value: 'MONTHLY' },
  { text: 'Every Two Months', value: 'TWO_MONTHS' },
  { text: 'Every Three Months', value: 'THREE_MONTHS' },
  { text: 'Every Six Months', value: 'SIX_MONTHS' },
  { text: 'Every Year', value: 'YEAR' }
];

export const fullFrequencyOptions = [
  { text: 'No Schedule', value: 'NONE' },
  { text: 'Every Week', value: 'WEEKLY' },
  { text: 'Every Two Weeks', value: 'BI_WEEKLY' },
  { text: 'Every Month', value: 'MONTHLY' },
  { text: 'Twice a Month', value: 'BI_MONTHLY' },
  { text: 'Every Two Months', value: 'TWO_MONTHS' },
  { text: 'Every Three Months', value: 'THREE_MONTHS' },
  { text: 'Every Six Months', value: 'SIX_MONTHS' },
  { text: 'Every Year', value: 'YEAR' }
];

export const frequencySecondDateOptions = [
  ...Array(28).fill(1).map((x, y) => x + y).map(day => ({ text: getDateWithOrdinal(day), value: day.toString()})),
  { text: 'Last Day', value: 'LAST' }
];
