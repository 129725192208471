import React from 'react';
import {bool, func, string} from 'prop-types';
import {APSelect} from 'affinipay-ui-library';
import './style.scss';
import {formatBankAccountOptions} from '../../utils';
import {bankAccountsProp} from '../../../../customProps';

const BankAccountDropdown = ({
  bankAccounts= [],
  selectedBankAccount,
  isDisabled,
  onAccountChange
}) => (
  <APSelect
    disabled={isDisabled}
    parentClass="deposit-selection"
    name="bank_account_id"
    options={formatBankAccountOptions(bankAccounts || [])}
    required={true}
    value={selectedBankAccount}
    onChange={onAccountChange}
    needsNativeElement={true}
  />
);

BankAccountDropdown.propTypes = {
  bankAccounts: bankAccountsProp,
  selectedBankAccount: string,
  isDisabled: bool,
  onAccountChange: func.isRequired
};

export default BankAccountDropdown;
