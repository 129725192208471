import React from 'react';
import {bool, func, instanceOf, string} from 'prop-types';
import { APTimelineCalendar } from './APTimelineCalendar';

export const APTimelineCustomRange = ({
  isActiveInitial = false,
  calendarStartDate,
  calendarEndDate,
  inputStartDate,
  inputEndDate,
  parentClass,
  handleCalendarStartDate,
  handleCalendarEndDate,
  handleInputStartDate,
  handleInputEndDate
}) => (
  <>
    {isActiveInitial &&
      <div className="aptimeline-custom-range">
        <APTimelineCalendar
          label="Start Date"
          name="start-date"
          iconTitle="Start Date Calendar Icon"
          calendarEndDate={calendarEndDate}
          calendarStartDate={calendarStartDate}
          inputDate={inputStartDate}
          parentClass={parentClass}
          handleCalendarDateChange ={handleCalendarStartDate}
          handleInputDateChange={handleInputStartDate}
          selectedCalendarDate={calendarStartDate}
          isEndDate={false}
        />
        <APTimelineCalendar
          label="End Date"
          name="end-date"
          iconTitle="End Date Calendar Icon"
          calendarEndDate={calendarEndDate}
          calendarStartDate={calendarStartDate}
          inputDate={inputEndDate}
          parentClass={parentClass}
          handleCalendarDateChange = {handleCalendarEndDate}
          selectedCalendarDate={calendarEndDate}
          handleInputDateChange={handleInputEndDate}
          isEndDate={true}
        />
      </div>
    }
  </>
);

APTimelineCustomRange.propTypes = {
  isActiveInitial: bool,
  calendarStartDate: instanceOf(Date),
  calendarEndDate: instanceOf(Date),
  inputStartDate: string,
  inputEndDate: string,
  parentClass: string,
  handleCalendarStartDate: func.isRequired,
  handleCalendarEndDate: func.isRequired,
  handleInputStartDate: func.isRequired,
  handleInputEndDate: func.isRequired
};
