import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { safeSendGA } from '../../lib/utils';
import { initializeLoanJS } from '../../loanjs/helpers/webComponentHelper';
import { getSelectedCustomFieldsWithoutSections } from '../../lib/paymentPage/submitBuilders';

const LoanTab = ({
  email,
  merchantPublicKey,
  merchantName,
  merchantSupportPhone,
  reference,
  accountId,
  amount,
  chargeURL,
  handleLoanSuccess,
  tokenURL,
  customFieldsStates,
  isUserLoggedIn,
  brand,
  maxLimitCents,
  minLimitCents,
  isAffirmModalDisplayed,
  environment,
  isAccordionEnabled,
  loanSupportNumber
}) => {

  useEffect(_ => {
    initializeLoanJS();
    sendPageWithBNPLGAEvent();
    window.addEventListener('loan-js-success', function () {
      handleLoanSuccess();
    });
    return () => window.removeEventListener('loan-js-success', handleLoanSuccess);
  },[]);

  const [optionalFields, setOptionalFields] = useState(getSelectedCustomFieldsWithoutSections(customFieldsStates));
  useEffect(_ => {
    setOptionalFields(getSelectedCustomFieldsWithoutSections(customFieldsStates));
  }, [customFieldsStates]);

  const getErrorHeaderText = _ => {
    if(isUserLoggedIn) return `Pay Later cannot be used when you’re logged in${` to ${brand}` || '.'}`;
  };

  const getErrorText = _ => {
    if(isUserLoggedIn) return `We recommend that you share the payment page link directly with your client, or call support at ${loanSupportNumber || '(855) 656-4685'}.`;
  };

  const sendPageWithBNPLGAEvent = () => {
    safeSendGA('Payment Page 2.0', 'ClientCredit', 'Payment Page with PL Load');
  };

  return (
    <>
      {isUserLoggedIn ? (
        <LoanErrorScreen errorHeader={getErrorHeaderText()} errorText={getErrorText()} />
      ) : (
        <loan-js
          email={email}
          pub-key={merchantPublicKey}
          account-id={accountId}
          reference={reference}
          optional-fields={JSON.stringify(optionalFields)}
          amount={amount}
          min-transaction-limit={minLimitCents}
          max-transaction-limit={maxLimitCents}
          charge-url={chargeURL}
          token-url={tokenURL}
          mode={ environment === 'production' ? 'prod' : 'sandbox' }
          merchant-name={merchantName}
          merchant-support-phone={merchantSupportPhone}
          is-affirm-modal-displayed={isAffirmModalDisplayed}
          is-accordion-enabled={isAccordionEnabled}
        ></loan-js>
      )
      }
    </>
  );
};

const LoanErrorScreen = ({errorHeader, errorText}) => {
  return (
    <div className='col-12'>
      <div className='col-12'>
        <div className="loan-error-icon"></div>
      </div>
      <div className="loan-error-header col-12">{errorHeader}</div>
      <div className="loan-error-text col-12">{errorText}</div>
    </div>
  );
};

export default LoanTab;

LoanErrorScreen.propTypes = {
  errorHeader: PropTypes.string,
  errorText: PropTypes.string
};

LoanTab.propTypes = {
  email: PropTypes.string,
  merchantPublicKey: PropTypes.string,
  merchantName: PropTypes.string,
  merchantSupportPhone: PropTypes.string,
  reference: PropTypes.string,
  accountId: PropTypes.string,
  amount: PropTypes.number,
  chargeURL: PropTypes.string,
  handleLoanSuccess: PropTypes.func,
  tokenURL: PropTypes.string,
  customFieldsStates: PropTypes.object,
  isUserLoggedIn: PropTypes.bool,
  isAffirmModalDisplayed: PropTypes.bool,
  brand: PropTypes.string,
  maxLimitCents: PropTypes.number,
  minLimitCents: PropTypes.number,
  environment: PropTypes.string,
  isAccordionEnabled: PropTypes.bool,
  loanSupportNumber: PropTypes.string
};
