import React from 'react';
import { shallow, mount } from 'enzyme/build';
import RecurringChargeEndDate from './RecurringChargeEndDate';

describe('RecurringChargeFrequency', () => {
  it('shallow render default', () => {
    const mockProps = getMockProps('new'),
      component = shallow(<RecurringChargeEndDate {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('shallow render specificDate', () => {
    Date.now = jest.fn(() => new Date(Date.UTC(2018, 7, 9, 8)).valueOf());
    const mockProps = getMockProps('specificDate'),
      component = shallow(<RecurringChargeEndDate {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('mount amount reaches for events', () => {
    const mockProps = getMockProps('amountReaches'),
      component = mount(<RecurringChargeEndDate {...mockProps} />);
    component
      .find('APRadioButton')
      .at(2)
      .prop('onChange')({ currentTarget: { value: 'foo' } });
    expect(component.state('currentDisplayed')).toEqual('foo');
    component.unmount();
  });
  it('mount number of occurrences for events', () => {
    const mockProps = getMockProps('numberOfOccurrences'),
      component = mount(<RecurringChargeEndDate {...mockProps} handleChange={jest.fn()}/>),
      instance = component.instance();
    component
      .find('input')
      .at(0)
      .simulate('change', { currentTarget: { value: 'foo' } });
    let input = { currentTarget: { value: 'foo' } };
    instance.limitNumberInput(input);
    expect(input).toEqual({ currentTarget: { value: null } });
    component.unmount();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'new':
    return { 
      scheduleStartDate: '10/23/2019',
      amountReaches: '', 
      maxOccurrences: null 
    };
  case 'specificDate':
    return {
      scheduleStartDate: '10/23/2019',
      scheduleEndDate: '10/23/2019',
      amountReaches: '',
      maxOccurrences: null
    };
  case 'amountReaches':
    return { 
      scheduleStartDate: '10/23/2019',
      amountReaches: '5000', 
      maxOccurrences: null 
    };
  case 'numberOfOccurrences':
    return { 
      scheduleStartDate: '10/23/2019',
      maxOccurrences: '5' 
    };
  default:
    return;
  }
};
