import React from 'react';
import InteractiveSocialSecurityNumber from './';
import { mount, shallow } from 'enzyme';
import * as fetchLib from 'whatwg-fetch';

describe('InteractiveSocialSecurityNumber', () => {
  beforeAll(() => {
    global.console.error = jest.fn();
  });
  afterAll(() => {
    global.console.error.mockRestore();
  });
  it('owner social security shallow snapshot render', () => {
    const component = shallow(
      <InteractiveSocialSecurityNumber
        {...getMockProps(true)}
      />
    );
    expect(component).toMatchSnapshot();
  });

  it('business owner social security shallow snapshot render', () => {
    const component = shallow(
      <InteractiveSocialSecurityNumber
        {...getMockProps()}
      />
    );
    expect(component).toMatchSnapshot();
  });

  it('mount as owner to test server error response', () => {
    const component = mount(<InteractiveSocialSecurityNumber {...getMockProps(true)}/>);
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        response: true,
        json: jest.fn(() => Promise.reject('Internal Server Error'))
      })
    );
    component.find('.eye-icon').simulate('click');
    component.update();
    expect(component.state().show).toEqual(false);
    expect(component.state().socialSecurityNumber).toEqual(getMockProps(true).maskedSocialSecurityNumber);
    setTimeout(() => {
      component.unmount();
    });
  });

  it('mount as business owner to test server error response', () => {
    const component = mount(<InteractiveSocialSecurityNumber {...getMockProps()}/>);
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        response: true,
        json: jest.fn(() => Promise.resolve({'status':500,'error':'Internal Server Error'}))
      })
    );
    component.find('.eye-icon').simulate('click');
    component.update();
    process.nextTick(() => {
      expect(component.state().show).toEqual(false);
      expect(component.state().socialSecurityNumber).toEqual(getMockProps().maskedSocialSecurityNumber);
    });
    setTimeout(() => {
      component.unmount();
    });
  });

  it('mount as owner to test not authorized response', () => {
    const component = mount(<InteractiveSocialSecurityNumber {...getMockProps(true)}/>);
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        response: true,
        json: jest.fn(() => Promise.resolve({'status':403,'errors':["Permission Denied. You may have tried to perform an operation you didn't have sufficient permissions for."]}))
      })
    );
    component.find('.eye-icon').simulate('click');
    component.update();
    expect(component.state().show).toEqual(false);
    expect(component.state().socialSecurityNumber).toEqual(getMockProps(true).maskedSocialSecurityNumber);
    setTimeout(() => {
      component.unmount();
    });
  });

  it('mount as business owner to test not authorized response', () => {
    const component = mount(<InteractiveSocialSecurityNumber {...getMockProps()}/>);
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        response: true,
        json: jest.fn(() => Promise.resolve({'status':403,'errors':["Permission Denied. You may have tried to perform an operation you didn't have sufficient permissions for."]}))
      })
    );
    component.find('.eye-icon').simulate('click');
    component.update();
    expect(component.state().show).toEqual(false);
    expect(component.state().socialSecurityNumber).toEqual(getMockProps().maskedSocialSecurityNumber);
    setTimeout(() => {
      component.unmount();
    });
  });

  it('mount as owner to test successful response', () => {
    const component = mount(<InteractiveSocialSecurityNumber {...getMockProps(true)}/>);
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => Promise.resolve(mockJSONAPIResponse))
      })
    );
    component.find('.eye-icon').simulate('click');
    process.nextTick(() => {
      expect(component.state().show).toEqual(true);
      expect(component.state().socialSecurityNumber).toEqual(successExpectation);
    });
    setTimeout(() => {
      component.unmount();
    });
  });

  it('mount as business owner to test successful response', () => {
    const component = mount(<InteractiveSocialSecurityNumber {...getMockProps()}/>);
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => Promise.resolve(mockJSONAPIResponse))
      })
    );
    component.find('.eye-icon').simulate('click');
    process.nextTick(() => {
      expect(component.state().show).toEqual(true);
      expect(component.state().socialSecurityNumber).toEqual(successExpectation);
    });
    setTimeout(() => {
      component.unmount();
    });
  });

  it('does not render a clickable icon if no social security number is present', () => {
    const mockProps = getMockProps(true);
    delete mockProps.maskedSocialSecurityNumber;
    const component = mount(<InteractiveSocialSecurityNumber {...mockProps}/>);
    expect(component.find('.eye-icon').exists()).toEqual(false);
    component.unmount();
  });

  it('updates masked social security number on edit of business owner', () => {
    jest.spyOn(InteractiveSocialSecurityNumber.prototype, 'componentDidUpdate');
    const component = shallow(<InteractiveSocialSecurityNumber {...getMockProps()}/>);
    component.setProps({maskedSocialSecurityNumber: '***-**-0000'});
    expect(InteractiveSocialSecurityNumber.prototype.componentDidUpdate).toHaveBeenCalled();
  });
});

const mockJSONAPIResponse = {
  data: {
    id: 1,
    type: 'Merchant Application',
    attributes: {
      social_security_number: '123-45-6789'
    }
  }
};

const successExpectation = mockJSONAPIResponse.data.attributes.social_security_number;

const getMockProps = (owner = false) => {
  const ownerProps = {
    disabled: true,
    isMerchantApplicationOwner: true,
    readonly: true,
    requestUrl: '/admin/merchant_applications/200/owner_social_security_numbers',
    maskedSocialSecurityNumber: '***-**-6789'
  };
  const businessOwnerProps = {
    displayPolicy: true,
    requestUrl: '/admin/merchant_applications/200/business_owner_social_security_numbers/1',
    maskedSocialSecurityNumber: '***-**-6789'
  };
  return owner ? ownerProps : businessOwnerProps;
};
