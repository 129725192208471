import React from 'react';
import './style.scss';
import { APButton, APModal } from 'affinipay-ui-library';
import PropTypes from 'prop-types';
import UpdateAccountModalContent from './UpdateAccountModalContent';
import SuccessfulUpdatedAccountModalContent from './SuccessfulUpdatedAccountModalContent';
import client from '../../../lib/ajax';

class ResolveTransactionWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stage: 'initial', disableButton: true, showModal: false };
    this.valid = this.valid.bind(this);
    this.updateOnBackend = this.updateOnBackend.bind(this);
    this.updateNewLimit = this.updateNewLimit.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  updateNewLimit(e) {
    let newLimitValue = e.target.value;
    let disableButton = !this.valid(newLimitValue);
    this.setState({ newLimitValue, disableButton });
  }

  updateOnBackend(e) {
    e.preventDefault();

    if( !this.valid(this.state.newLimitValue) ) { return; }

    this.setState({ errorMessage: null, disableButton: true });

    let { data } = this.props;
    let params = {
      transaction_warning: {
        new_limit: this.state.newLimitValue
      }
    };

    let respStatus;
    client.put(data.resolve_url, params)
      .then((r) => {
        respStatus = r.status;
        return r.json();
      })
      .then((json) => {
        switch (respStatus) {
        case 200:
          this.setState({ stage: 'update_successful' });
          break;
        case 422: {
          let key = Object.keys(json)[0];
          let newState = {};
          if ( key ) {
            newState = { errorMessage: json[key], stage: 'initial' };
          }
          this.setState({ ...newState, disableButton: false });
          break;
        }
        default:
        }
      });
  }

  removeDOMElement(elem) {
    elem.parentElement.removeChild(elem);
  }

  deleteAlert(e) {
    e.preventDefault();

    let respStatus;
    client.destroy(this.props.data.delete_url)
      .then((r) => {
        respStatus = r.status;
        return r.json();
      })
      .then((json) => {
        switch (respStatus) {
        case 200: {
          const transactionWarningRow = document.querySelector(`#transaction_warning_${json.id}`);
          const transactionAlertsBadge = document.querySelector('span.transaction-alerts-badge');
          if (transactionWarningRow) {
            this.removeDOMElement(transactionWarningRow);
          }
          if (transactionAlertsBadge) {
            if (json.error_count > 0) {
              transactionAlertsBadge.innerHTML = json.error_count;
            } else {
              this.removeDOMElement(transactionAlertsBadge);
            }
          }
          break;
        }
        default:
        }
      })
      .finally(this.closeModal);
  }

  valid(value) {
    let validationRegex = {
      'currency': /^\d+\.?(\d{1,2})?$/,
      'count': /^\d+$/
    }[this.props.data.value_type];

    return validationRegex.test(value.replaceAll(',', ''));
  }

  render() {
    return(
      <>
        <APButton
          id='resolve-button'
          className='btn btn-default'
          onClick={this.openModal}
        >
          Resolve
        </APButton>
        <APModal
          isActiveInitial={this.state.showModal}
          onClose={this.closeModal}
          className='transaction-warning-modal'
          headerText='Update Account'
        >
          { (this.state.stage === 'initial') ? (
            <UpdateAccountModalContent
              errorMessage={this.state.errorMessage}
              onClick={this.updateOnBackend}
              onChange={this.updateNewLimit}
              disabled={this.state.disableButton}
              newValue={this.state.newLimitValue}
              data={this.props.data}
            />
          ) : (
            <SuccessfulUpdatedAccountModalContent onClick={this.deleteAlert}/>
          ) }
        </APModal>
      </>
    );
  }
}

ResolveTransactionWarning.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    limit_value: PropTypes.string.isRequired,
    event_description: PropTypes.string.isRequired,
    value_type: PropTypes.string.isRequired,
    merchant_name: PropTypes.string.isRequired,
    account_name: PropTypes.string.isRequired,
    resolve_url: PropTypes.string.isRequired,
    delete_url: PropTypes.string.isRequired
  }).isRequired
};

export default ResolveTransactionWarning;
