import { APSelect } from 'affinipay-ui-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const RecurringChargeFrequency = ({
  items,
  handleChange,
  initialRecurChargeFrequency
}) => {

  const [selected, setSelected] = useState();

  useEffect(() => {
    if(initialRecurChargeFrequency) {
      const frequency = initialRecurChargeFrequency;
      if (frequency.days && frequency.days.length > 1) {
        setSelected('BI_MONTHLY');
      } else if (
        frequency.intervalDelay &&
        frequency.intervalUnit === 'MONTH' ||
        frequency === 'MONTHLY'
      ) {
        setSelected('MONTHLY');
      } else if (frequency.intervalDelay && frequency.intervalUnit === 'WEEK') {
        setSelected('WEEKLY');
      } else {
        setSelected('DAY');
      }
    }
  }, [initialRecurChargeFrequency]);

  const handleOnChange = (e) => {
    if(e.target) {
      const frequency = e.target.value;
      setSelected(frequency);
      handleChange({
        recurFrequency: frequency,
        oneTime: frequency === 'DAY',
        biMonthly: frequency === 'BI_MONTHLY'
      });
    }
  };

  return (
    <>
      <APSelect
        label="Frequency"
        nativeElementId='recurFrequency'
        onChange={handleOnChange}
        parentClass='ap-select-frequency'
        value={selected}
        options={items}
        required={true}
        needsNativeElement={true}
      />
    </>
  );
};

RecurringChargeFrequency.propTypes = {
  items: PropTypes.array,
  handleChange: PropTypes.func,
  initialRecurChargeFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default RecurringChargeFrequency;
