import React, { Children } from 'react';
import {
  ThemeProvider as MineralThemeProvider,
  mineralTheme
} from 'mineral-ui/themes';
import registerTheme from './registerTheme';
import { withProps } from 'recompose';

import PropTypes from 'prop-types';

const ThemeProvider = (props) => {
  let { children, theme } = props;

  if (typeof theme === 'string' || theme instanceof String) {
    theme = registerTheme(theme)(mineralTheme);
  }

  return (
    <MineralThemeProvider theme={theme}>
      {Children.only(children)}
    </MineralThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.element,
  theme: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string
  ])
};

ThemeProvider.defaultProps = {
  theme: registerTheme('lawpay')(mineralTheme)
};

export const AdminTheme = withProps({ theme: mineralTheme })(ThemeProvider);

export default ThemeProvider;
