import React, { useState } from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import client from 'lib/ajax';
import notify from 'lib/notify';
import UriTemplate from 'uri-templates';
import { APPopover, APButton, APInputMask, APCheckbox, APFormMessageBox } from 'affinipay-ui-library';

const numberMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: false
});

const ChangeApplication = ({applicationId, editable, underwriting, paths, merchantApplications, checkbox_value}) => {
  const [updatedAppId, setUpdatedAppId] = useState('');
  const [currentAppId, setCurrentAppId] = useState(applicationId);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentMerchantApplications, setCurrentMerchantApplications] = useState(merchantApplications);
  const [allowOverwriteMerchantData, setOverwriteMerchantDataFlag] = useState(checkbox_value);

  const handleChange = (e) => {
    setUpdatedAppId(e.target.value);
  };

  const isValid = () => updatedAppId.length && updatedAppId.match(/^\d+$/);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const resp = await client.patch(paths.merchant, {application_id: updatedAppId, overwriteMerchantData: allowOverwriteMerchantData || false});
      handleResponse(resp);
    } catch {
      notify.error('Something went wrong. Please contact Dev Team.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxClick = (e) => {
    const { checked } = e.target;
    setOverwriteMerchantDataFlag(checked);
  };

  const handleResponse = async (resp) => {
    const status = resp.status;
    try {
      const json = await resp.json();
      switch (status) {
      case 200:
        setCurrentMerchantApplications(json.merchant_applications);
        setCurrentAppId(json.merchant_application_id);
        setUpdatedAppId('');
        notify.success(json.message);
        break;
      case 401:
        notify.error('Not Authorized');
        break;
      case 403:
      case 404:
      case 422:
        setHasError(true);
        notify.error(json.message, json.errors);
        break;
      default:
        notify.error('Something went wrong. Please contact Dev Team.');
      }
    } catch {
      notify.error('Something went wrong. Please contact Dev Team.');
    }
  };

  const handleCreateMerchantApplication = async (resp) => {
    setIsSubmitting(true);
    try {
      const resp = await client.post(paths.createMerchantApplication);
      handleCreateMerchantApplicationResponse(resp);
    } catch {
      notify.error('Something went wrong. Please contact Dev Team.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateMerchantApplicationResponse = async (resp) => {
    const status = resp.status;
    try {
      const json = await resp.json();
      console.log('handleCreateMerchantApplicationResponse', json);
      switch (status) {
      case 200:
        setCurrentMerchantApplications(json.merchant_applications);
        notify.success(json.message);
        break;
      case 401:
        notify.error('Not Authorized');
        break;
      case 403:
      case 404:
      case 422:
        setHasError(true);
        notify.error(json.message, json.errors);
        break;
      default:
        notify.error('Something went wrong. Please contact Dev Team.');
      }
    } catch {
      notify.error('Something went wrong. Please contact Dev Team.');
    }
  };

  const state_class = (state) => {
    switch(state) {
      case 'signup':
        return 'label label-default';
      case 'incomplete':
       return 'label label-primary';
      case 'finished', 'completed':
        return 'label label-success';
      case 'provisioned':
        return 'label label-info';
      case 'submitting':
      case 'validating':
        return 'label label-warning';
      case 'submission_errors':
      case 'validation_errors':
        return 'label label-danger';
      default:
        return 'label label-danger'
    }
  };

  return (
    <div className="change-application col-xs-12 top-spacer-20">
      <h4>Merchant Application</h4>
      <p>
        Current Application:&nbsp;
        {currentAppId ? (
          <a
            data-testid="current-merchant-application-link"
            href={UriTemplate(paths.merchantApplication).fill({
              id: currentAppId
            })}
          >
            {currentAppId}
          </a>
        ) : (
          <span className="text-danger">None</span>
        )}
      </p>

      {underwriting ? (
        <p>
          <APButton
            type="button"
            onClick={handleCreateMerchantApplication}
            disabled={isSubmitting}
          >
            Create Application
          </APButton>
        </p>
      ) : null}

      {editable ? (
        <div>
          <APPopover
            titleText='Merchant Application ID'
            buttonText='Change Application'
            popOverPosition='bottom'
          >
            <div className="change-application-top col-sm-10 col-md-12">
              <APCheckbox
                text='Update this Merchant with the data in the Merchant Application'
                onChange={handleCheckboxClick}
              />

              {allowOverwriteMerchantData ? (
                <APFormMessageBox
                  type="warning"
                  header="Further Actions Required"
                  messages={['Bank account information must be manually updated after merchant application change.']}
                />
              ) : null}

              {currentMerchantApplications && currentMerchantApplications.length ? (
              <div className='change-application-container'>
                <Table className='table-striped table-responsive'>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Current State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentMerchantApplications.map((available_ma) => {
                      const {
                        id,
                        current_state
                      } = available_ma;

                      return (
                        <tr key={id}>
                          <td className="numeric">
                          <a
                            href={UriTemplate(paths.merchantApplication).fill({
                              id: id
                            })}
                          >
                            {id}
                          </a>
                          </td>
                          <td>
                            <span className={state_class(current_state)}>{current_state}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              ) : null}
              <div className='change-application-container'>
              <APInputMask
                  mask={numberMask}
                  onFocus={() => setHasError(false)}
                  type="text"
                  onChange={handleChange}
                  value={updatedAppId}
                  error={hasError}
                />
                <APButton
                  type="button"
                  disabled={!isValid() || isSubmitting}
                  onClick={handleSubmit}
                >
                  Submit
                </APButton>
               </div>
            </div>
          </APPopover>
        </div>
      ) : null}
    </div>
  );
};

ChangeApplication.propTypes = {
  applicationId: PropTypes.number,
  editable: PropTypes.bool,
  underwriting: PropTypes.bool,
  paths: PropTypes.shape({
    merchant: PropTypes.string,
    merchantApplication: PropTypes.string
  }),
  merchantApplications: PropTypes.array
};

export default ChangeApplication;
