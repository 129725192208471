// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`APStatementsRails Component shallow snapshot render 1`] = `
<div>
  <APAccordion
    key="year-1"
    label={2020}
  >
    <div
      className="ap-statements-rails month-0"
      key="month-0"
    >
      <a
        className="pdf-icon"
        href="https://dec-statment-here"
        id="month-0"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          December
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-1"
      key="month-1"
    >
      <a
        className="pdf-icon"
        href="https://nov-statement-here"
        id="month-1"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          November
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-2"
      key="month-2"
    >
      <a
        className="pdf-icon"
        href="https://oct-statement-here"
        id="month-2"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          October
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-3"
      key="month-3"
    >
      <a
        className="pdf-icon"
        href="https://sept-statement-here"
        id="month-3"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          September
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-4"
      key="month-4"
    >
      <a
        className="pdf-icon"
        href="https://aug-statement-here"
        id="month-4"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          August
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-5"
      key="month-5"
    >
      <a
        className="pdf-icon"
        href="https://jul-statement-here"
        id="month-5"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          July
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-6"
      key="month-6"
    >
      <a
        className="pdf-icon"
        href="https://jun-statement-here"
        id="month-6"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          June
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-7"
      key="month-7"
    >
      <a
        className="pdf-icon"
        href="https://may-statement-here"
        id="month-7"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          May
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-8"
      key="month-8"
    >
      <a
        className="pdf-icon"
        href="https://apr-statement-here"
        id="month-8"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          April
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-9"
      key="month-9"
    >
      <a
        className="pdf-icon"
        href="https://mar-statement-here"
        id="month-9"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          March
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-10"
      key="month-10"
    >
      <a
        className="pdf-icon"
        href="https://feb-statement-here"
        id="month-10"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          February
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-11"
      key="month-11"
    >
      <a
        className="pdf-icon"
        href="https://jan-statement-here"
        id="month-11"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          January
        </p>
      </a>
    </div>
  </APAccordion>
  <APAccordion
    key="year-2"
    label={2019}
  >
    <div
      className="ap-statements-rails month-0"
      key="month-0"
    >
      <a
        className="pdf-icon"
        href="https://sept-statement-here"
        id="month-0"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          September
        </p>
      </a>
    </div>
    <div
      className="ap-statements-rails month-1"
      key="month-1"
    >
      <a
        className="pdf-icon"
        href="https://may-statement-here"
        id="month-1"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          May
        </p>
      </a>
    </div>
  </APAccordion>
  <APAccordion
    key="year-3"
    label={2015}
  >
    <div
      className="ap-statements-rails month-0"
      key="month-0"
    >
      <a
        className="pdf-icon"
        href="https://feb-statement-here"
        id="month-0"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p
          className="month-text"
        >
          February
        </p>
      </a>
    </div>
  </APAccordion>
</div>
`;
