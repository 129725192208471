import React from 'react';
import {bool, object} from 'prop-types';
import LegacyQuickBillButton from './LegacyQuickBillButton';
import QuickBillModal from '../../QuickBill/QuickBillModal';
import InvoicesQuickBillButton from './InvoicingQuickBillButton';

const QuickBillButton = ({ quickBillPolicy, quickBillModal, quickBillEmail, invoiceSPAQuickBill }) => {
  const components = {
    quickBillPolicy: QuickBillModal,
    quickBillEmail: LegacyQuickBillButton,
    invoiceSPAQuickBill: InvoicesQuickBillButton
  };
  let target = quickBillPolicy ? 'quickBillPolicy' : quickBillEmail ? 'quickBillEmail' : invoiceSPAQuickBill ? 'invoiceSPAQuickBill' : '';
  const SpecificQuickBillButton = components[target];

  if (SpecificQuickBillButton) return <SpecificQuickBillButton {...quickBillModal} />;
  return null;
};

QuickBillButton.propTypes = {
  quickBillPolicy: bool,
  quickBillModal: object,
  quickBillEmail: bool,
  invoiceSPAQuickBill: bool
};

export default QuickBillButton;
