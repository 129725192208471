const Env = {
  set current(hostname) {
    const lookup = {
      'staging': /^secure(.preview)?.staging/,
      'development': /^localhost/
    };
    const [env] = Object.entries(lookup).find(([_, re]) => hostname.match(re)) || ['production'];
    this._current = env;
  },
  get current() {
    if (!this._current) {
      this.current = window.location.hostname;
    }
    return this._current;
  }
};

export default Env.current;