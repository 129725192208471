import React, {useEffect} from 'react';
import { APCheckbox, APInputText, APSelect, APTextArea } from 'affinipay-ui-library';
import { array, func, object } from 'prop-types';

// eslint-disable-next-line react/display-name
const CustomFieldsComponent = React.memo(({
  formState,
  setFormState,
  customFieldsData,
  setOptionalFields,
  disabledFieldsState
}) => {

  useEffect(() => {
    if(customFieldsData) {
      const noRefFields = customFieldsData.filter(el => el.custom_field !== 'reference');
      initializeCustomFieldsState(noRefFields);
      initializeOptionalCustomFields(noRefFields);
    }
  }, [customFieldsData]);

  const initializeCustomFieldsState = fields => {
    let newFormState = {};
    fields.map(el => {
      newFormState[`${el.name}`] = el.type === 'check_box' ? undefined : (el.default_value || undefined);
    });
    setFormState(newFormState);
  };

  const initializeOptionalCustomFields = fields => {
    let optionalCustomFields = [];
    fields.map(el => {
      if(el.required === 1 || el.required === 0) {
        optionalCustomFields = [...optionalCustomFields, el.name];
      }
    });
    setOptionalFields(optionalCustomFields);
  };

  const handleInputChange = (e) => {
    const name = e.target.name,
      value = e.target.value;
    let newState = { [`${name}`]: value };
    setFormState({ ...formState, ...newState });
  };

  const handleCheckBoxClick = e => {
    const name = e.target.name,
      checked = e.target.checked;
    let newState;
    if(checked) {
      newState = { [`${name}`]: 'yes' };
    } else {
      newState = { [`${name}`]: undefined };
    }
    setFormState({ ...formState, ...newState });
  };

  const renderCustomFields = v => {
    const fieldsData = v.filter(el => el.required !== 0 && el.custom_field !== 'reference');
    const view = fieldsData.map( el => {
      switch(el.type) {
      case 'text_field':
        return (
          <div key={el.position} className="col-12 form-group custom-text-field-container">
            <APInputText
              isFloatingLabel={true}
              name={el.name}
              value={formState[el.name]}
              label={el.name}
              onChange={handleInputChange}
              placeholder={el.placeholder}
              required={el.required===2}
              disabled={disabledFieldsState[el.name]}
              helpText={el.help_text}
            />
          </div>
        );
      case 'text_area':
        return (
          <div key={el.position} className="col-12 form-group custom-text-area-container">
            <APTextArea
              name={el.name}
              id={el.name}
              isFloatingLabel={true}
              value={formState[el.name]}
              label={el.name}
              onChange={handleInputChange}
              placeholder={el.placeholder}
              required={el.required===2}
              disabled={disabledFieldsState[el.name]}
              helpText={el.help_text}
            />
          </div>
        );
      case 'check_box':
        return (
          <div key={el.position} className="col-12 form-group toggled-amount custom-checkbox-container">
            <APCheckbox
              name={el.name}
              value={formState[el.name]}
              text={el.name}
              onChange={handleCheckBoxClick}
              disabled={disabledFieldsState[el.name]}
            />
            {el.help_text && (
              <span className="checkbox-help-text">{el.help_text}</span>
            )}
          </div>
        );
      case 'select_field': {
        const options = el.options.split(',');
        if(options.length === 0) {
          return;
        }
        let selectOptions;
        selectOptions = options.map(o =>
          ({'text': o.trim(), 'value': o.trim()})
        );
        return (
          <div key={el.position} className="col-12 form-group custom-select-field-container">
            <APSelect
              options={selectOptions}
              label={el.name}
              name={el.name}
              value={formState[el.name] || el.default_value}
              placeholderText={el.placeholder}
              onChange={handleInputChange}
              required={el.required===2}
              disabled={disabledFieldsState[el.name]}
              isFloatingLabel={true}
              helpText={el.help_text}
            />
            {el.help_text && (
              <span className="field-help-text">{el.help_text}</span>
            )}
          </div>
        );
      }
      default:
        return null;
      }
    });
    return view;
  };

  let memoizedView = '';
  if(customFieldsData && formState) {
    memoizedView = renderCustomFields(customFieldsData);
  }

  return (
    <div className="row">
      {memoizedView?.length>0 && (
        <>
          <div className="col-12">
            <h2>Additional Information</h2>
          </div>
          {memoizedView}
        </>
      )}
    </div>
  );
});

CustomFieldsComponent.propTypes = {
  formState: object,
  setFormState: func,
  customFieldsData: array,
  disabledFieldsState: object,
  setOptionalFields: func
};

export default CustomFieldsComponent;
