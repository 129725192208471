import React from 'react';
import PropTypes from 'prop-types';
import client from 'lib/ajax';
import './style.scss';

class Modal extends React.Component {
  static propTypes = {
    requestUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.requestUrl = this.props.requestUrl;

    this.state = {
      errorMessages: [],
      hasErrors: false,
      show: false,
      showAlert: false,
      body: '',
    };
  }

  getValidationState = () => (this.state.hasErrors ? 'error' : null);

  handleChange = (e) => this.setState({ body: e.target.value });

  handleDismiss = () => this.setState({ showAlert: false });

  handleFailure = (resp) => resp.json().then(this.setErrors);

  reset = () => {
    this.setState({
      errorMessages: [],
      hasErrors: false,
      show: false,
      showAlert: false,
    });
  };

  handleSuccess = (resp) => {
    if (resp.status === 200) {
      location.reload();
    } else {
      this.handleFailure(resp);
    }
  };

  requestBody = () => {
    return {
      merchant_note: { body: this.state.body },
    };
  };

  sendRequest = () => {
    client
      .post(this.requestUrl, this.requestBody())
      .then(this.handleSuccess)
      .catch(this.handleFailure);
  };

  setErrors = (json) => {
    this.setState({
      errorMessages: json,
      hasErrors: true,
      showAlert: true,
    });
  };

  toggleModal = () => {
    this.setState({ show: !this.state.show }, () => {
      if (!this.state.show) {
        this.reset();
      }
    });
  };

  render() {
    return (
      <div>
        <button
          className="pull-right btn btn-success"
          onClick={this.toggleModal}
        >
          Create Merchant Note
        </button>
        <div role='dialog'>
          {this.state.show &&
            <div>
              <div className='ap-modal-backdrop fading in' onClick={this.toggleModal}></div>
              <div className='fading in ap-modal' role='dialog'>
                <div className='ap-modal-dialog'>
                  <div className='ap-modal-content'>
                    <div className='ap-modal-header'>
                      <h4>Create Merchant Note</h4>
                    </div>
                    <div className='ap-modal-body'>
                      <form>
                        <div className={this.state.showAlert ? 'ap-alert' : 'ap-hidden'}>
                          <div className='ap-alert-close' onClick={this.handleDismiss}>x</div>
                          <ul>
                            {this.state.errorMessages.map((err, i) => (
                              <li key={i}>{err}</li>
                            ))}
                          </ul>
                        </div>
                        <div
                          className='form-group'
                          validationstate={this.getValidationState()}
                        >
                          <label className='control-label'>Body</label>
                          <textarea
                            className='form-control'
                            onChange={this.handleChange}
                            value={this.state.body}
                          >
                          </textarea>
                        </div>
                      </form>
                    </div>
                    <div className='ap-modal-footer'>
                      <button className='ap-modal-buttons button-default' onClick={this.toggleModal}>Close</button>
                      <button className='ap-modal-buttons button-primary' onClick={this.sendRequest}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Modal;
