import React from 'react';
import { render, screen } from '@testing-library/react';
import AdminPageHeader from './index';

describe('Admin page header', () => {
  it('renders with a title', () => {
    render(<AdminPageHeader title='test title' />);
    expect(screen.getByText('test title')).toBeInTheDocument();
  });

  it('renders its children', () => {
    render(<AdminPageHeader title='test title'>
      <button>test button</button>
    </AdminPageHeader>);
    expect(screen.getByRole('button', {name: 'test button'})).toBeInTheDocument();
  });
});