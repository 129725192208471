import { compose } from 'ramda';
import { lighten, darken, saturate, desaturate } from 'polished';

const rampUp = (amount) => {
  return compose(
    darken(amount),
    saturate(amount)
  );
};

const rampDown = (amount) => {
  return compose(
    lighten(amount),
    desaturate(amount)
  );
};

const brandType = {
  lawpay: '#EB6B19',
  cpacharge: '#418FDE',
  affinipay: '#2056BA'
};

const getBrandColor = (color) => {
  return brandType[color] || '#0069CC';
};

export default function(color) {
  return function(baseTheme) {
    return {
      ...baseTheme,

      backgroundColor_theme_selected: rampDown(0.45)(getBrandColor(color)),
      backgroundColor_theme_selectedAction: rampDown(0.3)(getBrandColor(color)),
      backgroundColor_theme_selectedHover: rampDown(0.2)(getBrandColor(color)),

      borderColor_theme: getBrandColor(color),
      borderColor_theme_hover: rampUp(0.1)(getBrandColor(color)),
      borderColor_theme_active: rampUp(0.2)(getBrandColor(color)),
      borderColor_theme_focus: rampUp(0.1)(getBrandColor(color)),

      Button_borderRadius: 0,

      Button_backgroundColor_hover: rampDown(0.52)(getBrandColor(color)),
      Button_backgroundColor_active: 'transparent',
      Button_backgroundColor_focus: 'transparent',

      Button_backgroundColor_minimal_hover: rampDown(0.45)(
        getBrandColor(color)
      ),
      Button_backgroundColor_minimal_active: rampDown(0.45)(
        getBrandColor(color)
      ),
      Button_backgroundColor_minimal_focus: rampDown(0.45)(
        getBrandColor(color)
      ),

      Button_backgroundColor_primary: getBrandColor(color),
      Button_backgroundColor_primary_hover: rampUp(0.1)(getBrandColor(color)),
      Button_backgroundColor_primary_active: rampUp(0.2)(getBrandColor(color)),
      Button_backgroundColor_primary_focus: rampUp(0.1)(getBrandColor(color)),

      Button_borderColor: getBrandColor(color),
      Button_borderColor_hover: rampUp(0.1)(getBrandColor(color)),
      Button_borderColor_active: rampUp(0.2)(getBrandColor(color)),
      Button_borderColor_focus: rampUp(0.1)(getBrandColor(color)),

      Button_boxShadow_focus: `0 0 0 2px rampUp(0.1)(color)`,

      Button_color: getBrandColor(color),
      Button_color_minimal: getBrandColor(color),
      Button_color_primary: '#fff',

      color_theme_hover: '#fff',

      icon_color_theme: '#eb6b19'
    };
  };
}
