import React, { useState } from 'react';
import { APButton, APFormMessageBox } from 'affinipay-ui-library';
import { deleteFeatureGate } from './helpers/services'; 
import { strings } from './helpers/constants';
import PropTypes from 'prop-types';

const DeleteFeatureGateForm = ({ gate, fetchData, closeModal, setTableState }) => {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    try {
      const response = await deleteFeatureGate(gate);
      const json = await response.json();
      if (response.ok) {
        closeModal();
        fetchData();
        setTableState({selectedFeatureGate: null});
      }
      else {
        setError(json.error);
        setIsSubmitting(false);
      }
    }
    catch (e) {
      setError(strings.deleteFeatureGateError);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {error &&                        
        <APFormMessageBox type="error">
          <p className='error-header'>
            {error}
          </p>
        </APFormMessageBox>
      }
      <form>
        <div className='row'>
          <div className='col-sm-6'>
            {strings.deleteGateModalText(gate.value)}
          </div>
          <div className='col-sm-6'>
            <APButton
              className='ap-negative-button'
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Remove
            </APButton>
          </div>
        </div>
      </form>
    </>
  );
};

DeleteFeatureGateForm.propTypes = {
  gate: PropTypes.object,
  fetchData: PropTypes.func,
  closeModal: PropTypes.func,
  setTableState: PropTypes.func
};

export default DeleteFeatureGateForm;