import { APButton, APFormMessageBox, APInputText } from 'affinipay-ui-library';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DineroCentsFormat } from '../../../lib/monetaryUtils';
import { safeSendGA, debounce } from '../../../lib/utils';
import './style.scss';
import LoanJSErrorBoundary from './errorBoundary';
import { isEmailPattern } from '../../../lib/utils';
import AFFINIPAY_COMMUNICATIONS from '../../../loanjs/helpers/affinipayCommunications';
import AFFIRM_COMMUNICATIONS from './helpers/affirmCommunications';
import { loanErrors, loanInfo } from '../../../lib/paymentPage/constants';
import serverMock from './mocks/serverMock';
import { handleAbandonment, getAbandonmentTime } from './helpers/loanAbandonmentHelper';

const LoanForm = ({
  externalFirstName,
  externalLastName,
  pubKey,
  accountId,
  reference,
  externalEmail,
  optionalFields,
  amount = 0,
  minTransactionLimit,
  maxTransactionLimit,
  chargeURL,
  tokenURL,
  merchantName,
  merchantSupportPhone,
  isAccordionEnabled,
  mode
}) => {
  useEffect(() => {
    affirmScript();
    maybeShowAbandonmentMessage();
  }, []);

  useEffect(_ => { safeSendGA('Payment Page 2.0', 'ClientCredit', 'Application Beginning'); }, []);

  const affirmScript = () => {
    AFFIRM_COMMUNICATIONS.attachAffirmScript(mode);
  };

  const [showAbandonmentMessage, setShowAbandonmentMessage] = useState(false);
  const maybeShowAbandonmentMessage = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('loan_reload') === 'true') {
      setShowAbandonmentMessage(true);
    }
  };

  useEffect(_ => {
    window.addEventListener('loan-js-affirm-error', handleLoanDeclined);
    return () => window.removeEventListener('loan-js-affirm-error', handleLoanDeclined);
  }, []);

  const [loanDeclined, setLoanDeclined] = useState(false);
  const handleLoanDeclined = _ => {
    safeSendGA('Payment Page 2.0', 'ClientCredit', 'Application Error');
    setLoanDeclined(true);
    setFormErrors([loanErrors.affirmErrorMessage(merchantName, merchantSupportPhone)]);
  };

  const [firstName, setFirstName] = useState(externalFirstName);
  useEffect(() => {
    setFirstName(externalFirstName);
  }, [externalFirstName]);
  const onFirstNameChange = e => { setFirstName(e.target.value); };

  const [lastName, setLastName] = useState(externalLastName);
  useEffect(() => {
    setLastName(externalLastName);
  }, [externalLastName]);
  const onLastNameChange = e => { setLastName(e.target.value); };

  const [email, setEmailState] = useState(externalEmail);
  useEffect(() => {
    setEmailState(externalEmail);
  }, [externalEmail]);
  const onEmailChange = e => { setEmailState(e.target.value); };

  const [formErrors, setFormErrors] = useState([]);
  const [isActionEnabled, setIsActionEnabled] = useState(false);

  const handleLimitErrorDisplay = _ => {
    const numberMax = maxTransactionLimit,
      numberMin = minTransactionLimit,
      numberAmount = amount;
    if (
      numberAmount &&
      numberMin &&
      numberAmount < numberMin
    ) {
      setFormErrors([loanErrors.minLimit(DineroCentsFormat(numberMin))]);
      setIsActionEnabled(false);
    } else if (
      numberAmount &&
      numberMax &&
      numberAmount > numberMax) {
      setFormErrors([loanErrors.maxLimit(DineroCentsFormat(numberMax))]);
      setIsActionEnabled(false);
    } else {
      setFormErrors();
      setIsActionEnabled(true);
    }
  };

  useEffect(() => {
    handleLimitErrorDisplay(minTransactionLimit, maxTransactionLimit, amount);
  }, [minTransactionLimit, maxTransactionLimit, amount]);

  const getIsFormValid = _ => {
    let missingFields = [];
    if (!amount) missingFields = [...missingFields, loanErrors.zeroAmount];
    if (!firstName) missingFields = [...missingFields, loanErrors.firstNameValidation];
    if (!lastName) missingFields = [...missingFields, loanErrors.lastNameValidation];
    if (!email) {
      missingFields = [...missingFields, loanErrors.emailValidation];
    } else if (!isEmailPattern(email)) {
      missingFields = [...missingFields, loanErrors.emailPattern];
    }
    if (missingFields.length === 0) return true;
    setFormErrors(missingFields);
    return false;
  };

  const handleSubmitClick = async e => {
    e.preventDefault();
    setIsActionEnabled(false);
    let tokenResponse, chargeResponse, isFormValid = false;

    isFormValid = getIsFormValid();

    if (isFormValid) {
      safeSendGA('Payment Page 2.0', 'ClientCredit', 'Application Submit');
      safeSendGA('Payment Page 2.0', 'ClientCredit', `Begin Process - Affirm upfunnel toggle: Modal displayed: ${isAffirmModalDisplayed}`);

      safeSendGA(
        `Payment Page ${isAccordionEnabled ? '3' : '2'}.0`,
        `The PL ${isAccordionEnabled ? 'bar' : 'tab'} “Begin Process” button is clicked`,
        `PL-${isAccordionEnabled ? 'Accordion' : 'Tab'}-CheckOut`
      );

      tokenResponse = await AFFINIPAY_COMMUNICATIONS.getPaymentToken({
        url: `${tokenURL}/v1/tokens`,
        firstName,
        lastName,
        email,
        pubKey,
        serverMock,
        setFormErrors
      });
    }

    const data = optionalFields ? AFFINIPAY_COMMUNICATIONS.buildCustomFieldsPayload(optionalFields) : { 'custom_fields': {} };

    if (tokenResponse) chargeResponse = await AFFINIPAY_COMMUNICATIONS.createAffinipayCharge({
      tokenResponse,
      url: `${chargeURL}/create-loan`,
      accountId,
      reference,
      amount,
      serverMock,
      setFormErrors,
      data
    });
    if (chargeResponse) {
      setTimeout(handleAbandonment, getAbandonmentTime(chargeResponse?.action?.checkout_expiry, 2 * 60 * 1000));
      AFFIRM_COMMUNICATIONS.initiateAffirmCheckout(chargeResponse, setFormErrors);
    }
    setIsActionEnabled(true);
  };

  const handleLenderClick = _ => {
    safeSendGA('Payment Page 2.0', 'ClientCredit tab', 'Lender');
  };

  const handleTermsClick = _ => {
    safeSendGA('Payment Page 2.0', 'ClientCredit tab', 'Terms');
  };

  const [isAffirmModalDisplayed, setIsAffirmModalDisplayed] = useState(false);

  const handleAffirmClick = (event) => {
    if (event.target.nodeName.toLowerCase() === 'a') {
      setIsAffirmModalDisplayed(true);
      safeSendGA('Payment Page 2.0', 'ClientCredit tab', 'Affirm upfunnel open click');
    }
  };

  const refreshAffirm = useCallback(
    debounce(() => {
      if (typeof(affirm) !== 'undefined') {
        affirm.ui.ready(() => {
          affirm.ui.refresh();
        });
      }
    }, 250), []);

  useEffect(() => {
    refreshAffirm();
  }, [amount]);

  return (
    <LoanJSErrorBoundary>
      <div className='affinipay-loanjs-wrapper row' data-testid="loan-js">
        {showAbandonmentMessage && (
          <div className="col-12">
            <APFormMessageBox>
              {loanInfo.abandonmentMessage}
            </APFormMessageBox>
          </div>
        )}
        {(!isAccordionEnabled) && (
          <div className={`col-12 affirm-promotional-message ${amount <= 0 ? 'hidden' : ''}`} onClick={handleAffirmClick}>
            <p className="affirm-as-low-as" data-page-type="product" data-amount={amount}></p>
          </div>
        )}
        <div className='col-12'>
          <span>For your convenience, we are offering financing for your service provider fees with flexible options through Affirm. You can begin your loan application below. It is quick and secure, and you’ll get a real-time decision.</span>
        </div>
        <div className='col-12 mt-2'>
          <span>Start by entering your basic information:</span>
        </div>
        <div className='col-xs-12 col-6 form-field'>
          <APInputText
            value={firstName}
            name='firstName'
            onChange={onFirstNameChange}
            label='First Name'
            isFloatingLabel={true}
            required={true}
          />
        </div>
        <div className='col-xs-12 col-6 form-field'>
          <APInputText
            value={lastName}
            name='lastName'
            onChange={onLastNameChange}
            label='Last Name'
            isFloatingLabel={true}
            required={true}
          />
        </div>
        <div className='col-12 form-field'>
          <APInputText
            value={email}
            name='email'
            onChange={onEmailChange}
            label='Email Address'
            isFloatingLabel={true}
            required={true}
          />
        </div>
        {(formErrors && formErrors.length) > 0 && (
          <div className='col-12'>
            <APFormMessageBox type={`${loanDeclined ? 'warning' : 'error'}`} header={`${loanDeclined ? loanErrors.affirmErrorHeader : ''}`} >
              {formErrors.length > 1 && (
                <p className='message-header'>
                  Please fix the following and try to submit your payment again:
                </p>
              )}
              {formErrors.map((err, i) => <p key={i} className={`${(formErrors.length === 1 && !loanDeclined) ? 'message-header' : ''}`}>{err}</p>)}
            </APFormMessageBox>
          </div>
        )}
        <div className='col-12 mt-2'>
          <span>Your application will be processed exclusively through Affirm and its lending partners, not your service provider.</span>
        </div>
        <div className='col-12 align-right continue-button-wrapper'>
          <APButton
            onClick={handleSubmitClick}
            disabled={!isActionEnabled}
          >
            Begin Process
          </APButton>
        </div>
        <div className='align-left secondary-text'>
          <span><sup>*</sup>US consumers only. Affirm is not available to entity customers who would use Affirm financing for business or commercial purposes. Subject to credit eligibility. Payment options through Affirm are provided by these lending partners: </span>
          <span>
            <a href="https://www.affirm.com/lenders" target="_blank" rel="noreferrer" onClick={handleLenderClick}>affirm.com/lenders</a>
            <a href="https://www.affirm.com/terms" target="_blank" rel="noreferrer" onClick={handleTermsClick}>See terms</a>
          </span>
        </div>
      </div>
    </LoanJSErrorBoundary>
  );
};

export default LoanForm;

LoanForm.propTypes = {
  externalFirstName: PropTypes.string,
  externalLastName: PropTypes.string,
  authKey: PropTypes.string,
  pubKey: PropTypes.string,
  accountId: PropTypes.string,
  reference: PropTypes.string,
  externalEmail: PropTypes.string,
  optionalFields: PropTypes.string,
  amount: PropTypes.number,
  minTransactionLimit: PropTypes.number,
  maxTransactionLimit: PropTypes.number,
  chargeURL: PropTypes.string,
  tokenURL: PropTypes.string,
  merchantName: PropTypes.string,
  merchantSupportPhone: PropTypes.string,
  isAccordionEnabled: PropTypes.bool,
  mode: PropTypes.string
};
