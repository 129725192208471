import React from 'react';
import { shallow } from 'enzyme';
import APRadioButtonRails from './';

describe('APRadioButtonRails Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(<APRadioButtonRails />);
    expect(component).toMatchSnapshot();
  });
});
