import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import 'react-datepicker/dist/react-datepicker.css';
import APTimelineSelector from './APTimelineSelector';
import { APTimelineCustomRange } from './APTimelineCustomRange';
import './style.scss';
import CalendarIcon from './CalendarIcon';
import {
  formatDate,
  getCompiledParentClass,
  getCurrentMonthString,
  getNextMonthString,
  getPreviousMonthString,
  verifyDate
}from './helpers';


const defaultTimelineOptions = [
  {
    id: 'all',
    text: 'All',
    method: () => 'All'
  },
  {
    id: 'current_month',
    text: 'Current Month',
    method: getCurrentMonthString
  },
  {
    id: 'next_month',
    text: 'Next Month',
    method: getNextMonthString
  },
  {
    id: 'previous_month',
    text: 'Previous Month',
    method: getPreviousMonthString
  },
  {
    id: 'custom_range',
    text: 'Custom Range',
    method: () => ''
  }
];

const defaultDate = new Date();
const formattedDate = formatDate(defaultDate);
const defaultFunction = () => {};

export const APTimelinePicker = ({
  id = 'aptimeline',
  label,
  name = 'aptimelinepicker',
  parentClass,
  options = defaultTimelineOptions,
  disabled,
  shouldReset,
  setResetTimeline = defaultFunction,
  onChange = defaultFunction
}) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [iconColor, setIconColor] = useState(disabled ? '#8f9ba3' : '#003566');
  const [selectedOption, setSelectedOption] = useState('all');
  const [calendarStartDate, setCalendarStartDate] = useState(defaultDate);
  const [calendarEndDate, setCalendarEndDate] = useState(defaultDate);
  const [inputStartDate, setInputStartDate] = useState(formattedDate);
  const [inputEndDate, setInputEndDate] = useState(formattedDate);
  const [rangeValue, setRangeValue] = useState('All');

  useEffect(() => {
    setSelectedOption('all');
    setCalendarStartDate(defaultDate);
    setCalendarEndDate(defaultDate);
    setInputStartDate(formattedDate);
    setInputEndDate(formattedDate);
    setRangeValue('All');
    setResetTimeline(false);
  }, [shouldReset]);

  useEffect(() => {
    if (rangeValue === 'All') {
      setRangeValue('All');
    } else {
      const rangeValue = `${formatDate(calendarStartDate)} - ${formatDate(calendarEndDate)}`;
      setRangeValue(rangeValue);
      if (selectedOption === 'custom_range') {
        const customValues = {
          target: {
            value: { startDate: calendarStartDate, endDate: calendarEndDate },
            name
          }
        };
        onChange(customValues);
      }
    }
  }, [calendarEndDate, calendarStartDate]);

  const compiledParentClass = getCompiledParentClass({
    baseClass: 'aptimeline-wrapper',
    label,
    parentClass
  });

  const separateRangeValues = range => {
    const [startDay, endDay] = range.split('-');
    const startDate = new Date(startDay);
    const endDate = new Date(endDay);

    return {startDate, endDate};
  };

  const handleOpenSelection = event => {
    event.preventDefault();
    setIsSelectorOpen(true);
  };

  const handleCloseSelection = () => {
    setIsSelectorOpen(false);
  };

  const handleTimelineSelection = id => {
    setSelectedOption(id);
    if (id === 'all') {
      setRangeValue('All');
      const allValues = {
        target: {
          value: { startDate: undefined, endDate: undefined},
          name
        }
      };
      onChange(allValues);
      setIsSelectorOpen(false);
    }
    else if (id === 'custom_range') {
      const formattedDate = formatDate(defaultDate);
      setRangeValue(`${formattedDate} - ${formattedDate}`);
      const customValues = {
        target: {
          value: { startDate: calendarStartDate, endDate: calendarEndDate },
          name
        }
      };
      onChange(customValues);
    } else {
      const chosenOption = options.find(option => option.id === id) || options[0];
      const range = chosenOption?.method();
      setRangeValue(range);
      const rangeValues = {
        target: { value: separateRangeValues(range), name }
      };
      onChange(rangeValues);
      setIsSelectorOpen(false);
    }
  };

  const handleCalendarStartDate = date => {
    if (date > calendarEndDate) {
      setCalendarEndDate(date);
      setInputEndDate(formatDate(date));
    }
    setCalendarStartDate(date);
    setInputStartDate(formatDate(date));
  };

  const handleCalendarEndDate = date => {
    if (date < calendarStartDate) {
      setCalendarStartDate(date);
      setInputStartDate(formatDate(date));
    }
    setCalendarEndDate(date);
    setInputEndDate(formatDate(date));
  };

  const handleInputStartDate = ({currentTarget: {value}}) => {
    setInputStartDate(value);
    const dateVerification = verifyDate(value);

    if (dateVerification) {
      const verifiedInputDate = new Date(dateVerification);
      if (verifiedInputDate > calendarEndDate) {
        setCalendarEndDate(verifiedInputDate);
        setInputEndDate(formatDate(verifiedInputDate));
      }
      setCalendarStartDate(verifiedInputDate);
    }
  };

  const handleInputEndDate = ({currentTarget: {value}}) => {
    setInputEndDate(value);
    const dateVerification = verifyDate(value);

    if (dateVerification) {
      const verifiedInputDate = new Date(dateVerification);

      setCalendarEndDate(verifiedInputDate);
    }
  };

  const handleApplyRange = () => {
    const value = separateRangeValues(rangeValue);
    const rangeValues = {
      target: { value, name }
    };

    onChange(rangeValues);
    setIsSelectorOpen(false);
  };

  const handleTimelineBlur = e => {
    e.preventDefault();
    const {currentTarget} = e;

    if (calendarEndDate < calendarStartDate) {
      setCalendarEndDate(calendarStartDate);
      setInputEndDate(inputStartDate);
    }
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) setIsSelectorOpen(false);
    }, 0);
  };

  return (
    <div className={compiledParentClass} tabIndex={1} onBlur={handleTimelineBlur}>
      <div className="aptimeline-input-wrapper">
        {label && (
          <label
            htmlFor={id || name}
            className="aptimeline-label"
            data-testid="aptimeline-label"
            aria-label="aptimeline-label"
          >
            {label}
          </label>
        )}
        <div className="aptimeline-input">
          <div className="calendar-icon">
            <APButton
              disabled={disabled}
              className="ap-icon-button"
              ariaLabel="aptimeline-calendar-icon"
              ariaDescribedBy="clicked-calendar-icon"
              onMouseEnter={() => setIconColor('#fff')}
              onMouseLeave={() => setIconColor('#003566')}
              onClick={handleOpenSelection}
            >
              <CalendarIcon fill={iconColor} />
            </APButton>
          </div>
          <input
            disabled={disabled}
            className="aptimeline-input-field"
            id={id}
            readOnly={true}
            name={name}
            value={rangeValue}
            onClick={handleOpenSelection}
            data-testid="aptimeline-input-field"
          />
        </div>
      </div>
      <div className="aptimeline-selector-wrapper">
        {isSelectorOpen && (
          <APTimelineSelector
            isSelectorOpen={isSelectorOpen}
            selectedOption={selectedOption}
            options={options}
            parentClass={parentClass}
            handleApplyRange={handleApplyRange}
            handleTimelineSelection={handleTimelineSelection}
            handleCloseSelection={handleCloseSelection}
          />
        )}
        {selectedOption === 'custom_range' && (
          <APTimelineCustomRange
            parentClass={parentClass}
            isActiveInitial={isSelectorOpen}
            calendarStartDate={calendarStartDate}
            calendarEndDate={calendarEndDate}
            inputStartDate={inputStartDate}
            inputEndDate={inputEndDate}
            handleCalendarStartDate={handleCalendarStartDate}
            handleCalendarEndDate={handleCalendarEndDate}
            handleInputStartDate={handleInputStartDate}
            handleInputEndDate={handleInputEndDate}
          />
        )}
      </div>
    </div>
  );
};

APTimelinePicker.propTypes = {
  id: string,
  label: string,
  name: string,
  parentClass: string,
  options: arrayOf(shape({
    id: string,
    text: string,
    method: func.isRequired
  })),
  disabled: bool,
  shouldReset: bool,
  setResetTimeline: func,
  onChange: func.isRequired
};

export default APTimelinePicker;
