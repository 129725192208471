import React from 'react';
import PropTypes from 'prop-types';

class IconCharge extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={ `0 0 ${this.props.size} ${this.props.size}`}
        className={ this.props.className }
      >
        <path
          d="M3.6,27H32.4V9H3.6ZM32.4,5.4A3.6,3.6,0,0,1,36,9V27a3.6,3.6,0,0,1-3.6,3.6H3.6A3.6,3.6,0,0,1,0,27V9A3.6,3.6,0,0,1,3.6,5.4ZM22.5,22.5V19.8h6.3v2.7ZM3.6,11.7H32.4v3.6H3.6Z"
          fill={ this.props.fill }
        />
        <rect x="22.5" y="19.8" width="6.3" height="2.7" fill={ this.props.fill } />
        <path
          d="M32.4,5.4H3.6A3.6,3.6,0,0,0,0,9V27a3.6,3.6,0,0,0,3.6,3.6H32.4A3.6,3.6,0,0,0,36,27V9A3.6,3.6,0,0,0,32.4,5.4Zm0,21.6H3.6V15.3H32.4Zm0-15.3H3.6V9H32.4Z"
          fill={ this.props.fill }
        />
      </svg>
    );
  }
}


IconCharge.defaultProps = {
  fill: '#000000',
  size: 36,
  className: 'ap-icon'
};

IconCharge.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
};

export default IconCharge;