import React, {useState} from 'react';
import { APAlert, APButton, APToast } from 'affinipay-ui-library';
import { firstSuccessfulTransactionalPayment } from '../utils';
import IconTransactions from '../icons/icon-transactions';
import client from '../../../lib/ajax';
import {invoiceProp} from '../../../customProps';

const initialToastValues = {
  show: false,
  header: undefined,
  message: undefined,
  type: 'error'
};

const QuickBillActionsForPaid = ({invoice}) => {
  const {
    transactionId,
    invoicePayments
  } = invoice;
  const [toastValues, setToastValues] = useState(initialToastValues);
  const [isDisabled, setIsDisabled] = useState();
  const successfulTransactionalPayment = firstSuccessfulTransactionalPayment(invoicePayments);

  const markAsUnpaid = async invoiceId => {
    setIsDisabled(true);

    const {status} = await client.destroy(`/quick_bills/${invoiceId}/mark_as_paid`);

    const successAction = () => {
      setToastValues({
        show: true,
        header: 'Success',
        message: 'Quick Bill Marked as Unpaid',
        type: 'success'
      });
      setTimeout(() => document.location.reload(), 3000);
    };

    const notAuthorizedAction = () => {
      setToastValues({
        show: true,
        header: 'Not Authorized',
        message: 'You do not have permission to do this operation',
        type: 'error'
      });
      setIsDisabled(false);
    };

    const errorAction = () => {
      setToastValues({
        show: true,
        header: 'Error',
        message: 'Unable to mark quick bill as unpaid',
        type: 'error'
      });
      setIsDisabled(false);
    };

    const actionMap = new Map([
      [200, successAction],
      [401, notAuthorizedAction],
      [403, notAuthorizedAction],
      [422, errorAction]
    ]);

    const action = actionMap.get(status);
    if (typeof action === 'function') {
      action();
    } else {
      errorAction();
    }
  };

  const handleToastClose = () => {
    setToastValues({
      ...toastValues,
      show: false
    });
  };

  const handleViewTransactionClick = () => {
    window.location.assign(`${window.location.origin}/transactions/${transactionId}`);
  };

  const SuccessfulTransactionalPayment = () => (
    <div>
      <APAlert alertType="information">
        This Quick Bill has been processed and cannot be edited or resent.
      </APAlert>
      {transactionId && (
        <APButton
          type="button"
          onClick={handleViewTransactionClick}
        >
          <IconTransactions fill="#FFF" size="24" />
          <span className="view-transaction-label">View Transaction</span>
        </APButton>
      )}
    </div>
  );

  const TransactionPending = () => (
    <>
      <APButton
        type="button"
        disabled={isDisabled}
        onClick={() => markAsUnpaid(invoice.id)}
      >
        Mark As Unpaid
      </APButton>
      <APToast
        type={toastValues.type}
        isOpen={toastValues.show}
        header={toastValues.header}
        fadeOutTime={5000}
        onClose={handleToastClose}
      >
        {toastValues.message}
      </APToast>
    </>
  );

  const SpecificPaidComponent = successfulTransactionalPayment ? SuccessfulTransactionalPayment : TransactionPending;

  return <SpecificPaidComponent />;
};

QuickBillActionsForPaid.propTypes = {
  invoice: invoiceProp
};

export default QuickBillActionsForPaid;
