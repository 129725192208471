import React, {useEffect, useState} from 'react';
import {node, number, shape, string} from 'prop-types';
import LastChanceModal from './LastChanceModal';

const statusMap = new Map([['true', false], ['false', true]]);
const ccIntentCheckboxId = 'forms_registrations_deposit_step_form_cc_intent_status';

const ClientCreditLastChanceButton = ({
  appId,
  checkboxId,
  data,
  formId,
  supportPhone
}) => {
  const ccIntentCheckbox = document.getElementById(ccIntentCheckboxId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLastChance, setShowLastChance] = useState(statusMap.get(ccIntentCheckbox?.value));

  useEffect(() => {
    const checkboxListener = () => {
      const checkBoxValue = statusMap.get(ccIntentCheckbox.value);
      setShowLastChance(!checkBoxValue);
    };
    ccIntentCheckbox.addEventListener('click', checkboxListener);
    return () => ccIntentCheckbox.removeEventListener('click', checkboxListener);
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return showLastChance ? (
    <>
      <div className="col-sm-3 btn-forward-arrow">
        <button
          name="button"
          type="button"
          onClick={openModal}
          data-testid="client-credit-last-chance-btn"
          data-label="Next button clicked"
        >
          Next
          <span>&nbsp;</span>
        </button>
      </div>
      <LastChanceModal
        appId={appId}
        checkboxId={checkboxId}
        formId={formId}
        isModalOpen={isModalOpen}
        supportPhone={supportPhone}
        closeModal={closeModal}
        data={data}
      />
    </>
  ) : (
    <div className="col-sm-3 btn-forward-arrow">
      <button
        name="button"
        id="btn-next"
        className="trackable"
        data-label="Next button clicked"
        {...data}
        data-testid="client-credit-next-btn"
        data-app_id={appId}
      >
        Next
        <span>&nbsp;</span>
      </button>
    </div>
  );
};

ClientCreditLastChanceButton.propTypes = {
  appId: number.isRequired,
  ccIntentCheckbox: node,
  checkboxId: string.isRequired,
  formId: string.isRequired,
  data: shape({
    action: string,
    form: string,
    label: string,
    options: shape({partner: string}),
    step: string
  }).isRequired,
  supportPhone: string.isRequired
};

export default ClientCreditLastChanceButton;
