import React, {useEffect} from 'react';
import {array, bool, number, object, shape, string} from 'prop-types';
import AlertRibbon from './AlertRibbon';
import HeaderButtons from './buttons/HeaderButtons';
import Navigation from '../Navigation';
import PCIBanner from '../PCIBanner';
import {removeActiveClass, switchActiveClass} from '../../lib/utils';

export const checkHeaderProfileToggle = target => {
  if (!target) return false;
  if (!target.parentNode) return false;

  const ids = ['header-profile-toggle', 'header-profile-menu'];

  return ids.reduce((bool, id) => {
    if (!bool) return bool;
    if (target.id === id) return false;
    if (target.parentNode.id === id) return false;
    return bool;
  }, true);
};

const Header = ({
  accountInactive,
  accountSuspended,
  echeckUrl,
  navigationData,
  quickBillModal,
  quickBillEmail,
  quickBillPolicy,
  invoiceSPAQuickBill,
  pciCompliance,
  profileMenu,
  showPCIBanner,
  testMode,
  username
}) => {
  useEffect(() => {
    document.addEventListener('click', closeProfileMenu);
  }, []);

  const closeProfileMenu = ({target}) => {
    const menu = document.getElementById('header-profile-menu');
    const toggle = document.getElementById('header-profile-toggle');

    if (checkHeaderProfileToggle(target)) {
      removeActiveClass(menu);
      removeActiveClass(toggle);
    }
  };

  const toggleMobileMenu = ({target}) => {
    const menu = document.getElementById('mobile-toggle-menu');
    switchActiveClass(target);
    switchActiveClass(menu);
  };

  const handleProfileMenuClick = e => {
    const toggle = (e.currentTarget) ? e.currentTarget : e.srcElement;
    const menu = toggle.nextElementSibling;
    switchActiveClass(toggle);
    switchActiveClass(menu);
  };

  const handleEcheckClick = () => {
    window.location = echeckUrl;
  };

  const isAlertMode = () => testMode || accountSuspended || accountInactive;

  const truncateName = name => {
    const desktopMaxChar = 25;
    const mobileMaxChar = 18;
    let result = {
      desktopUserName: name,
      mobileUserName: name
    };
    if( name.length > desktopMaxChar ) {
      result.desktopUserName = `${name.substr(0, desktopMaxChar - 1)}...`;
    }
    if( name.length > mobileMaxChar ) {
      result.mobileUserName = `${name.substr(0, mobileMaxChar - 1)}...`;
    }
    return result;
  };

  return (
    <div id="top-navbar" className={`ap-header${isAlertMode() ? ' alert-mode' : ''}`}>
      {showPCIBanner && <PCIBanner {...pciCompliance} isAlertMode={isAlertMode()} />}
      <AlertRibbon
        accountInactive={accountInactive}
        accountSuspended={accountSuspended}
        testMode={testMode}
      />
      <div className="header-navigation-wrapper">
        <div className="brand-logo" />
        <div className="hidden-xs header-nav-buttons">
          <HeaderButtons
            echeckUrl={echeckUrl}
            handleEcheckClick={handleEcheckClick}
            handleProfileMenuClick={handleProfileMenuClick}
            profileMenu={profileMenu}
            quickBillEmail={quickBillEmail}
            quickBillModal={quickBillModal}
            quickBillPolicy={quickBillPolicy}
            invoiceSPAQuickBill={invoiceSPAQuickBill}
            truncatedUsernames={truncateName(username)}
          />
        </div>
        <div className="visible-xs header-mobile-navigation">
          <div id="hamburger-button" onClick={toggleMobileMenu}/>
          <div id="mobile-toggle-menu">
            <div className="active-area">
              <HeaderButtons
                echeckUrl={echeckUrl}
                handleEcheckClick={handleEcheckClick}
                handleProfileMenuClick={handleProfileMenuClick}
                profileMenu={profileMenu}
                quickBillEmail={quickBillEmail}
                quickBillModal={quickBillModal}
                quickBillPolicy={quickBillPolicy}
                truncatedUsernames={truncateName(username)}
              />
              <Navigation isMobile={true} {...navigationData}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  accountInactive: bool,
  accountSuspended: bool,
  echeckUrl: string,
  navigationData: object.isRequired,
  pciCompliance: shape({
    pciComplianceUrl: string,
    pciDueBy: string,
    pciExpiresWithin: number
  }),
  quickBillModal: object,
  quickBillEmail: bool,
  quickBillPolicy: bool,
  invoiceSPAQuickBill: bool,
  profileMenu: array,
  showPCIBanner: bool,
  testMode: bool,
  username: string.isRequired
};

export default Header;
