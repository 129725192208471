import React, {useEffect,useState} from 'react';
import {string} from 'prop-types';
import QRCode from 'qrcode.react';
import QRCodeCard from './QRCodeCard';
import {styles} from './printQRstyles';
import './style.scss';
import {getAdditionalUrlParams} from './helpers/getAdditionalUrlParams';
import {truncateTitle} from './helpers/truncateTitle';
import PrintIcon from '../../components/icons/PrintIcon';
import { APButton, APModal } from 'affinipay-ui-library';
import {safeSendGA} from '../../lib/utils';

const QRCodeModal = ({currentMerchantName, paymentPath, paymentTitle, site}) => {
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    if (displayModal) {
      safeSendGA('Charges QR Modal', 'QR Modal opened', `Merchant Name: ${currentMerchantName}; Payment Title: ${paymentTitle}; Payment Path: ${paymentPath}`);
    }
  }, [displayModal]);

  const toggleModal = () => {
    setDisplayModal(!displayModal);
  };

  const handlePrint = () => {
    safeSendGA('Charges QR Modal', 'QR Modal print button clicked', `Merchant Name: ${currentMerchantName}; Payment Title: ${paymentTitle}; Payment Path: ${paymentPath}`);

    const createQrCodeWrapper = () => {
      const printContents = document.createElement('div');
      printContents.className = 'qr-print-wrapper';
      const qrCardClone = document.getElementById('qr-card-print')?.cloneNode(true);
      qrCardClone.id = 'qr-card-print-clone';
      printContents.appendChild(qrCardClone);
      return printContents;
    };

    const setPrintWindowWithStyledContents = printContents => {
      const w = window.open();
      w.document.head.innerHTML = '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">';
      w.document.title = `${paymentTitle} QR Code`;
      w.document.body.innerHTML = `${printContents.outerHTML}`;

      for(let element in styles) {
        let targetedElement = w.document.querySelector(element);
        if (targetedElement) {
          let elementStyle = targetedElement.style;
          for (let attribute in styles[element]){
            elementStyle[attribute] = styles[element][attribute];
          }
        }
      }
      return w;
    };

    const setSvgDimensions = printWindow => {
      const qrSVG = printWindow.document.querySelector('.qr-code-svg');
      qrSVG?.setAttribute('width', 170);
      qrSVG?.setAttribute('height', 170);
    };

    async function loadImage(elem) {
      return new Promise((resolve, reject) => {
        elem.onload = () => resolve(elem);
        elem.onerror = reject;
        elem.src = `${window.location.origin}/${`assets/${site}/powered-by-logo.png`}`;
      });
    }

    async function printWhenLoaded(printWindow) {
      const img = printWindow.document.querySelector('.powered-logo');
      await loadImage(img);
    }
    const printWindow = setPrintWindowWithStyledContents(createQrCodeWrapper());
    setSvgDimensions(printWindow);
    printWhenLoaded(printWindow).then(() => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    });
  };

  return (
    <div className="qr-code">
      <APButton className="ap-icon-button" onClick={toggleModal}>
        <QRCode
          className="qr-code-svg-button"
          level="M"
          renderAs="svg"
          value={getAdditionalUrlParams(paymentPath)}
          title="qr-code-svg-button"
        />
      </APButton>
      <APModal
        className="qr-code-modal"
        isActiveInitial={displayModal}
        headerText={`${paymentTitle} - QR Code`}
        onClose={toggleModal}
      >
        <QRCodeCard
          currentMerchantName={truncateTitle(currentMerchantName)}
          id="qr-card-print"
          paymentPath={getAdditionalUrlParams(paymentPath)}
          paymentTitle={truncateTitle(paymentTitle, 40)}
          site={site}
        />
        <div className="qr-code-buttons">
          <div className="qr-code-copy-instructions">
            Right click on the QR Code to copy, and paste in your client invoice.
          </div>
          <APButton className="ap-secondary-button" onClick={handlePrint}>
            <div className="qr-code-action-button">
              <PrintIcon className="qr-code-action-icons" />
              <span>Print</span>
            </div>
          </APButton>
        </div>
      </APModal>
    </div>
  );
};

QRCodeModal.propTypes =  {
  currentMerchantName: string,
  paymentPath: string,
  paymentTitle: string,
  site: string
};

export default QRCodeModal;
