const PCICompliance = ({
  id,
  brand,
  previous_test_date: previousTestDate,
  previous_test_dates: previousTestDates = [],
  next_due_date: nextDueDate,
  name,
  status,
  contact_email,
  referral_partner: referralPartner,
  integration_partner: integrationPartner,
  merchant_id: merchantVTID,
  merchant_uuid_base62: merchantUUID,
  error
}) => ({
  id,
  name,
  brand,
  previousTestDate,
  previousTestDates,
  nextDueDate,
  status,
  contactEmail: contact_email ? contact_email.replace(/,/g, ' ') : '',
  referralPartner,
  integrationPartner,
  merchantVTID,
  merchantUUID,
  error
});

export default PCICompliance;
