import React from 'react';
import { APButton } from 'affinipay-ui-library';
import {array, bool, func, object} from 'prop-types';
import './style.scss';
import InteractiveSocialSecurityNumber from '../InteractiveSocialSecurityNumber';

const getConfirmationText = isAffiniPaySite => {
  const position = isAffiniPaySite ? 'authorized signer' : 'business owner';
  return `Are you sure you want to remove this ${position}?`;
};

const brandedJobTitle = (jobTitle, isAffiniPaySite) => {
  const brandedTitle = isAffiniPaySite && jobTitle == 'Treasurer' ? 'CFO, Treasurer' : jobTitle;
  return brandedTitle;
};

const OwnerListItem = ({owner, handleEdit, handleRemove, isAffiniPaySite}) => {
  return (
    <div className='col-xs-12 owner-list-item'>
      <div className='col-xs-8'>
        {owner.first_name} {owner.last_name}, {brandedJobTitle(owner.job_title, isAffiniPaySite)}
      </div>
      <div className='col-xs-4'>
        <APButton className="ap-primary-button edit-btn" onClick={handleEdit}>
          Edit
        </APButton>
        <APButton className="ap-primary-button remove-btn" onClick={(e) => {
          e.preventDefault();
          if (confirm(getConfirmationText(isAffiniPaySite))) {
            handleRemove(owner);
          }}}>
          Remove
        </APButton>
      </div>
    </div>
  );
};

const AdminOwnerListItem = ({owner, handleEdit, handleRemove, displayPolicy, isAffiniPaySite}) => {
  const displayData = [
    {label:'Job Title', data: owner.job_title},
    {label:'First Name', data: owner.first_name},
    {label:'Last Name', data: owner.last_name},
    {label:'Social Security Number', data: `***-**-${owner.social_security_number_last_4}`},
    {label:'Date of Birth', data: owner.date_of_birth},
    {label:'Owner\'s Home Address (Street and number)', data: owner.home_address1},
    {label:'Apartment, suite, unit, etc. (Optional)', data: owner.home_address2},
    {label:'City', data: owner.home_city},
    {label:'State', data: owner.home_state},
    {label:'Postal Code', data: owner.home_zip_code}
  ];
  const list = displayData.map((el, i) => {
    return (
      <div className="col-xs-12 admin-owner-row" key={i}>
        <div className="col-xs-6">
          {el.label}
        </div>

        <div className="col-xs-6">
          {el.label === 'Social Security Number' ? (
            <InteractiveSocialSecurityNumber
              maskedSocialSecurityNumber={el.data}
              requestUrl={owner?.ssn_request_url}
              displayPolicy={displayPolicy} >
            </InteractiveSocialSecurityNumber>
          ) : el.data}
        </div>
      </div>
    );
  });

  return (
    <div className="col-xs-12 admin-owner">
      <h4>
        {owner.first_name} {owner.last_name}, {owner.job_title}
      </h4>
      {list}
      <APButton className="ap-secondary-button edit-btn" onClick={handleEdit}>Edit</APButton>
      <APButton className="ap-primary-button remove-btn" onClick={(e) => {
        e.preventDefault();
        if (confirm(getConfirmationText(isAffiniPaySite))) {
          handleRemove(owner);
        }}}>Remove</APButton>
    </div>
  );
};

const OwnersList = ({owners, handleEdit, handleRemove, isAdmin, displayPolicy, isAffiniPaySite}) => {
  let list;

  if ( isAdmin ) {
    list = owners.map((owner, i) =>
      <AdminOwnerListItem
        owner={owner}
        key={i}
        handleEdit={(e) => {
          handleEdit(e, owner);
        }}
        displayPolicy={displayPolicy}
        handleRemove={handleRemove}
      />);
  } else {
    list = owners.map((owner, i) =>
      <OwnerListItem
        owner={owner}
        key={i}
        handleEdit={(e) => {
          handleEdit(e, owner);
        }}
        handleRemove={handleRemove}
        isAffiniPaySite={isAffiniPaySite}
      />);
  }


  return (
    <>
      {list}
    </>
  );
};

OwnersList.propTypes = {
  owners: array,
  displayPolicy: object,
  handleEdit: func,
  handleRemove: func,
  isAdmin: bool,
  isAffiniPaySite: bool
};

OwnerListItem.propTypes = {
  owner: object,
  handleEdit: func,
  handleRemove: func,
  isAffiniPaySite: bool
};

AdminOwnerListItem.propTypes = {
  owner: object,
  displayPolicy: object,
  handleEdit: func,
  handleRemove: func,
  isAffiniPaySite: bool
};

export default OwnersList;
