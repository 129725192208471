import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import ThemeProvider from 'components/themes/ThemeProvider';
import BorderedContentBox from 'components/BorderedContentBox';
import './style.scss';

export default class MerchantApp extends React.Component {
  static propTypes = {
    theme: PropTypes.string,
    continueUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <div className="row page-padding">
          <div className="col-sm-12 center-content" >
            <h1>Prepare for your application</h1>
            <p className={'flex-container vertical-margin'}>
              Please make sure to have the following information handy before
              you start. These details are collected as part of the underwriting
              process, which verifies your identity in order to protect your
              firm against fraud.
            </p>
            <div className={'vertical-margin'}>
              <div className={'flex-container'}>
                <BorderedContentBox className={'cols'}>
                  <b>For the firm and the owner(s):</b>
                  <ul>
                    <li>Firm Federal Tax ID</li>
                    <li>Owner&apos;s Social Security Number</li>
                    <li>Owner&apos;s Driver’s License</li>
                  </ul>
                </BorderedContentBox>
                <BorderedContentBox className={'cols'}>
                  <b>For the bank accounts:</b>
                  <ul>
                    <li>ABA Routing Number</li>
                    <li>Bank Account Number</li>
                  </ul>
                </BorderedContentBox>
              </div>
            </div>
            <p className={'container-width'}>
              Terms and Conditions must be accepted and signed by the{' '}
              <b>business owner</b> or <b>authorized signer</b>.
            </p>

            <Button
              className={'vertical-margin'}
              primary
              element="a"
              href={this.props.continueUrl}
            >
              START APPLICATION
            </Button>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
