// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`JobTitle Component shallow snapshot render 1`] = `
<div>
  <label>
    Job Title
  </label>
  <withProps(withProps(StateManager))
    onChange={[Function]}
  />
</div>
`;
