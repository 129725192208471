const serverMock = {
  paymentTokenRequest: {
    'type': 'loan',
    'given_name': 'John',
    'surname': 'Doe',
    'email': 'foobar@affinipay.com'
  },
  paymentTokenResponse: {
    'id': 'wt02BQrSR-6kM3GnglqgOA',
    'created': '2021-06-25T14:09:46.654Z',
    'modified': '2021-06-25T14:09:46.654Z',
    'given_name': 'John',
    'surname': 'Doe',
    'email': 'foobar@affinipay.com',
    'type': 'loan'
  },
  chargeRequest: {
    'method': 'wt02BQrSR-6kM3GnglqgOA',
    'account_id': 'Xm_sSo9sRXSc3Mbb2KY1dg',
    'reference': 'foobar payment',
    'amount': '100',
    'currency': 'USD',
    'vt_type_identifier': 'loan'
  },
  chargeResponse: {
    'id': 'oLATib5IQhi25UVOjrsCYw',
    'type': 'CHARGE',
    'created': '2021-06-25T14:10:07.327Z',
    'created_by': 't_admin/127.0.0.1',
    'modified': '2021-06-25T14:10:07.359Z',
    'modified_by': 't_admin/127.0.0.1',
    'account_id': 'Xm_sSo9sRXSc3Mbb2KY1dg',
    'status': 'PENDING',
    'amount': 100,
    'currency': 'USD',
    'archived': false,
    'auto_capture': true,
    'amount_refunded': 0,
    'method': {
      'type': 'loan',
      'given_name': 'John',
      'surname': 'Doe',
      'country': 'US',
      'email': 'foobar@affinipay.com'
    },
    'action': {
      'type': 'affirm',
      'checkout_ari': 'X9I9SQLXLHZDK9KA',
      'checkout_expiry': Date.now()+3*60*1000 // for testing purpose GW timeout is set to one minute from now. 
    }
  }
};

export default serverMock;