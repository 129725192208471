import React from 'react';
import './style.scss';
import { APButton } from 'affinipay-ui-library';
import { safeSendGA } from '../../lib/utils';
import { PropTypes } from 'prop-types';

const CrossSellTagButton = ({siteTitle, text, url, fromAdmin}) => {

  const handleTagButton = () => {
    if (!fromAdmin) {
      safeSendGA(`${siteTitle} cross-sell`, 'click');
    }
    window.open(url, '_self');
  };

  return (
    <div className={`cross-sell-tag-container ${fromAdmin ? 'admin-container' : ''}`}  data-testid='cross-sell-tag'>
      <APButton
        id="cross-sell-tag-button"
        className={`cross-sell-tag ap-secondary-button ${fromAdmin ? 'admin-button' : ''}`}
        onClick={handleTagButton}
        name='intercom-cross-sell-tag'
      >
        { text.toUpperCase() }
      </APButton>
    </div>
  );
};

CrossSellTagButton.propTypes = {
  siteTitle: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  fromAdmin: PropTypes.bool
};

export default CrossSellTagButton;
