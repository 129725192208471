import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './adhoc.scss';
import { APButton, APInputMonetary } from 'affinipay-ui-library';
import { generateNewFee, buildMonthOptions, buildYearOptions, buildAdHocFeeNameOptions, adHocFeeNamesLength } from './helpers';

const AdyenAdditionalFees = ({
  fees = [],
  resourceName = 'pricing',
  attributeName = 'ad_hoc_fees_attributes',
  withEffectiveDate,
  restrictedEdit,
  disabled
}) => {

  // Adding the original_fee_effective_year attribute to the fees array to keep the original effective_year of the fee
  // after changing it in the effective_year dropdown and displaying it afterwards
  const [additionalFees, setAdditionalFees] = useState(() => fees.map((fee) => ({ ...fee, original_fee_effective_year: fee.effective_year })));

  const addFee = () => {
    setAdditionalFees([...additionalFees, generateNewFee(withEffectiveDate)]);
  };

  const removeFee = (idx) => {
    setAdditionalFees(additionalFees.filter((_, fidx) => idx !== fidx));
  };

  const markFeeAsDeleted = (idx) => {
    const newFees = additionalFees.map((fee, fidx) => idx !== fidx ? fee : { ...fee, _destroy: true });
    setAdditionalFees(newFees);
  };

  const updateFee = (e, idx) => {
    const { field } = e.target.dataset;
    if (field) {
      const newFees = additionalFees.map((fee, fidx) => idx !== fidx ? fee : { ...fee, [field]: e.target.value });
      setAdditionalFees(newFees);
    }
  };

  const inputName = (fieldName, idx) => `${resourceName}[${attributeName}][${idx}][${fieldName}]`;

  const inputId = (fieldName, idx) => `${resourceName}_${attributeName}_${idx}_${fieldName}`;

  const hasErrorsOn = (fee, fieldName) => fee.errors && fee.errors[fieldName];

  const errorClass = (fee, fieldName) => hasErrorsOn(fee, fieldName) ? 'has-error' : '';

  const markAsDeletedClass = (fee) => fee._destroy ? 'adhoc-fees__fee-row--marked-as-deleted' : '';

  const isReadOnly = (fee) => fee._destroy || (fee.id && restrictedEdit);

  const readOnlyClass = (bool) => bool ? 'readonly' : '';

  const canAddFees = () => {
    const feesAdded = additionalFees.filter(x => !x._destroy ).length;
    return !disabled && feesAdded < adHocFeeNamesLength;
  };

  return (
    <div className="adhoc-fees">
      {additionalFees.map((fee, idx) => {
        return (
          <div
            className={`form-group adhoc-fees__fee-row ${markAsDeletedClass(fee)}`}
            key={fee.id || idx}
            data-testid={`adhoc-fees-row-${idx}`}
          >
            <div className={`col-md-2 ${errorClass(fee, 'name')}`}>
              <label htmlFor={inputId('name', idx)} className='control-label'>Fee Name</label>
              <select
                value={fee.name}
                className={`form-control ${readOnlyClass(disabled || fee._destroy)}`}
                data-field="name"
                onChange={(e) => updateFee(e, idx)}
                id={inputId('name', idx)}
                name={inputName('name', idx)}
                disabled={disabled}
                readOnly={disabled || fee._destroy}
              >
                {buildAdHocFeeNameOptions()}
              </select>
              {hasErrorsOn(fee, 'name') &&
                <span className="help-block">
                  {fee.errors.name.join(', ')}
                </span>
              }
            </div>

            <div className={`col-md-2 ${errorClass(fee, 'rate')}`}>
              <div>
                <APInputMonetary
                  className="form-control"
                  label="Amount"
                  name={inputName('rate', idx)}
                  onChange={(e) => updateFee(e, idx)}
                  required={true}
                  value={fee.rate}
                  data-field="rate"
                  id={inputId('rate', idx)}
                  disabled={disabled}
                  readOnly={disabled || fee._destroy}
                />
              </div>
              {hasErrorsOn(fee, 'rate') &&
                <span className="help-block">
                  {fee.errors.rate.join(', ')}
                </span>
              }
            </div>

            <div
              className={`col-md-2 ${errorClass(fee,'frequency')}`}
            >
              <label htmlFor={inputId('frequency', idx)} className='control-label'>Frequency</label>
              <select
                value={fee.frequency}
                className={`form-control ${readOnlyClass(isReadOnly(fee))}`}
                data-field="frequency"
                onChange={(e) => updateFee(e, idx)}
                id={inputId('frequency', idx)}
                name={inputName('frequency', idx)}
                disabled={disabled}
                readOnly={disabled || isReadOnly(fee)}
              >
                <option value="ONE_TIME">One Time</option>
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
              </select>
              {hasErrorsOn(fee, 'frequency') &&
                <span className="help-block">
                  {fee.errors.frequency.join(', ')}
                </span>
              }
            </div>

            {withEffectiveDate &&
              <>
                <div className={`col-md-2 ${errorClass(fee, 'effective_month')}`}>
                  <label htmlFor={inputId('effective_month', idx)} className='control-label'>Effective</label>
                  <select
                    value={fee.effective_month || (new Date().getMonth() + 1)}
                    className={`form-control ${readOnlyClass(isReadOnly(fee))}`}
                    data-field="effective_month"
                    onChange={(e) => updateFee(e, idx)}
                    id={inputId('effective_month', idx)}
                    name={inputName('effective_month', idx)}
                    disabled={disabled}
                    readOnly={disabled || isReadOnly(fee)}
                  >
                    {buildMonthOptions()}
                  </select>
                  {hasErrorsOn(fee, 'effective_month') &&
                    <span className="help-block">
                      {fee.errors.effective_month.join(', ')}
                    </span>
                  }
                </div>

                <div className={`col-md-2 ${errorClass(fee,'effective_year')}`}>
                  <label htmlFor={inputId('effective_year', idx)} className='control-label'>Year</label>
                  <select
                    value={fee.effective_year || new Date().getFullYear()}
                    className={`form-control ${readOnlyClass(isReadOnly(fee))}`}
                    data-field="effective_year"
                    onChange={(e) => updateFee(e, idx)}
                    id={inputId('effective_year', idx)}
                    name={inputName('effective_year', idx)}
                    disabled={disabled}
                    readOnly={disabled || isReadOnly(fee)}
                  >
                    {buildYearOptions(fee.original_fee_effective_year)}
                  </select>
                  {hasErrorsOn(fee, 'effective_year') &&
                    <span className="help-block">
                      {fee.errors.effective_year.join(', ')}
                    </span>
                  }
                </div>
              </>
            }

            <div className="col-md-2 delete-col">
              <label htmlFor={inputId('_destroy', idx)} />
              {fee.id &&
                <input
                  type="hidden"
                  value={fee.id}
                  id={inputId('id', idx)}
                  name={inputName('id', idx)}
                  disabled={disabled}
                />
              }

              {fee._destroy ? (
                <input
                  type="hidden"
                  value={fee._destroy}
                  data-field="_destroy"
                  onChange={(e) => updateFee(e, idx)}
                  id={inputId('_destroy', idx)}
                  name={inputName('_destroy', idx)}
                />
              ) : (
                !disabled ?
                  <span
                    data-testid='delete-icon'
                    className="glyphicon glyphicon-trash"
                    onClick={() => fee.id ? markFeeAsDeleted(idx) : removeFee(idx)}
                  /> : null )}
            </div>
          </div>
        );
      })}

      <div className="row">
        <div className="col-md-2 admin">
          { canAddFees() &&
            <APButton type='button' className='ap-secondary-button' onClick={addFee}>+ Add {additionalFees.length > 0 ? 'another ' : null}fee</APButton>
          }
        </div>
      </div>
    </div>
  );
};

export default AdyenAdditionalFees;

AdyenAdditionalFees.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.object),
  resourceName: PropTypes.string,
  attributeName: PropTypes.string,
  withEffectiveDate: PropTypes.bool,
  restrictedEdit: PropTypes.bool,
  disabled: PropTypes.bool
};
