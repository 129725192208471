import React from 'react';
import {string} from 'prop-types';

export const CloseIcon = ({className = 'pci-banner-close-icon'}) => (
  <svg
    className={className}
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="15.0005333 1.99989333 10 7.00042667 5.00053333 1.99989333 2 5.00042667 6.99946667 10.00096 2 15.0004267 5.00053333 17.9998933 10 13.0004267 15.0005333 17.9998933 18.0010667 15.0004267 12.9994667 10.00096 18.0010667 5.00042667"
      >
      </polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icn_small_close_x">
        <mask id="mask-2" fill="#fff">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Mask" fill="#000000" xlinkHref="#path-1"></use>
        <g mask="url(#mask-2)" fill="#FFF">
          <polygon id="clr" points="0 20 20 20 20 0 0 0"></polygon>
        </g>
      </g>
    </g>
  </svg>
);

CloseIcon.propTypes = {
  className: string
};

export const PCIDueSoonIcon = ({className = 'pci-banner-due-soon-icon'}) => (
  <svg
    className={className}
    width="26"
    height="26"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M22.28 13.19s.298 7.019-8.062 10.082l-1.305.41-1.294-.41C3.24 20.188 3.556 13.19 3.556 13.19V2.592H22.28v10.597zM.963 0v13.689s0 .205.005.313c.063 1.14.832 7.938 10.29 11.473l1.653.525 1.666-.525c9.676-3.617 10.258-10.65 10.293-11.543.002-.062.002-.213.002-.213V0H.963zM14.21 16.688v2.592h-2.592v-2.592h2.592zm.324-11.745-.648 10.206h-1.944l-.567-10.206h3.159z"
        id="pci-due-soon-icon"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#pci-due-soon-icon"/>
      </mask>
      <use fill="#000" xlinkHref="#pci-due-soon-icon"/>
      <g mask="url(#b)" fill="#FFF">
        <path d="M0 26h26V0H0z"/>
      </g>
    </g>
  </svg>
);

PCIDueSoonIcon.propTypes = {
  className: string
};
