import React from 'react';
import PropTypes from 'prop-types';

const IconCaretUp = ({scope = 'default', size = 36}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg'
      className={`icon-caret-up ${scope}`}
      viewBox={`0 0 ${size} ${size}`}>
      <polygon points='18 6.2 0 24.2 5.6 29.8 18 17.3 30.4 29.8 36 24.2 18 6.2' />
    </svg>
  );
};

IconCaretUp.propTypes = {
  scope: PropTypes.string,
  size: PropTypes.number
};

export default IconCaretUp;
