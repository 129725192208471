import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { APSelect, APInputMonetary } from 'affinipay-ui-library';
import APRadioButton from '../../components/APRadioButton';
import { addClass, removeClass, getDateWithOrdinal, getDayOfWeek, getMonth } from '../../lib/utils';
import { basicFrequencyOptions, fullFrequencyOptions } from '../../lib/paymentPage/constants';

const FrequencyComponent = React.memo(function FrequencyComponent({
  formState,
  setFormState,
  recurringVisible,
  disabledFormFieldsState
}) {
  const [disabled, setDisabled] = useState(true);
  const [recurFrequency, setRecurFrequency] = useState('NONE');
  const [endAmountState, setEndAmountState] = useState(undefined);
  const endOptionsActive = useRef(null);
  const secondDayActive = useRef(null);

  useEffect(() => {
    if(formState.recurFrequency && isValidFrequency(formState.recurFrequency)) {
      setRecurFrequency(formState.recurFrequency);
    }
  }, [formState.recurFrequency]);

  const isValidFrequency = (frequency) => {
    return !!fullFrequencyOptions.find((f) => frequency === f.value);
  };

  const getRecurringOptions = () => {
    if (!recurringVisible) return [];
    return recurringVisible === 'BASIC' ? basicFrequencyOptions : fullFrequencyOptions;
  };

  const getSecondDateOptions = () => {
    const maxDays = 28,
      lastValue = { text: 'Last Day', value: 'LAST' },
      options = [];

    for (let i = 1; i <= maxDays; i++) {
      let obj = { text: getDateWithOrdinal(i), value: i.toString() };
      options.push(obj);
    }

    options.push(lastValue);
    return options;
  };

  const handleRadioButtonChange = (e) => {
    enableOrDisableEndAmountField(e);

    if (e.target.value === 'never') {
      setEndAmountState(undefined);
      const newAmountState = { endAmountField: undefined };
      setFormState({...formState, ...newAmountState});
    }
  };

  const handleFrequencyChange = (e) => {
    setRecurFrequency(e.target.value);
    toggleSecondDayDropdown(e);

    if (e.target.value != 'NONE') {
      const newState = { [e.target.name]: e.target.value };
      setFormState({ ...formState, ...newState });
      removeClass(endOptionsActive.current, 'hidden');
    }

    if (e.target.value === 'NONE') {
      const initialSecondDayOfMonth = new Date().getDate().toString();
      const initialState = {
        'recurFrequency': 'NONE',
        'secondDayOfMonth': initialSecondDayOfMonth,
        'endAmountField': ''
      };

      setFormState({ ...formState, ...initialState});
      addClass(endOptionsActive.current, 'hidden');
    }
  };

  const handleSecondDayChange = (e) => {
    const newState = { [e.target.name]: e.target.value };
    setFormState({ ...formState, ...newState });
  };

  const handleAmountChange = ({target}) => {
    const {name, value} = target;
    setFormState({ ...formState, [name]: value  });
    setEndAmountState(value);
  };

  const toggleSecondDayDropdown = (e) => {
    if (e.target.value === 'BI_MONTHLY') {
      removeClass(secondDayActive.current, 'hidden');
    } else if (e.target.name != 'secondDayOfMonth' &&
               e.target.name != 'recurringEnds' &&
               e.target.name != 'endAmountField') {
      addClass(secondDayActive.current, 'hidden');
    }
  };

  const enableOrDisableEndAmountField = (e) => {
    if (e.target.value === 'never') {
      setDisabled(true);
    }
    if (e.target.value === 'whenTotalPaid') {
      setDisabled(false);
    }
  };

  const displayLabeledDays = () => {
    const todaysDate = new Date().getDate();
    switch(recurFrequency) {
    case 'NONE':
      return '';
    case 'BI_MONTHLY':
      return <>on the <strong>{ getDateWithOrdinal(todaysDate) } and the</strong></>;
    case 'WEEKLY':
    case 'BI_WEEKLY':
      return <>on <strong>{ getDayOfWeek() }</strong></>;
    case 'YEAR':
      return <>on <strong>{ getMonth().substring(0, 3) }, {todaysDate}</strong></>;
    default:
      return <>on the <strong>{ getDateWithOrdinal(todaysDate) }</strong></>;
    }
  };

  const displayFrequencyDropdown = (
    <APSelect
      onChange={handleFrequencyChange}
      options={getRecurringOptions()}
      value={recurFrequency}
      name='recurFrequency'
      label='Frequency'
      disabled={disabledFormFieldsState['recurFrequency']}
      isFloatingLabel={true}
    />
  );

  const displaySecondDayDropdown = (
    <div className='hidden' ref={secondDayActive}>
      <APSelect
        onChange={handleSecondDayChange}
        options={getSecondDateOptions()}
        value={formState?.['secondDayOfMonth']}
        name='secondDayOfMonth'
        label='Day of Month'
        disabled={disabledFormFieldsState['secondDayOfMonth']}
        isFloatingLabel={true}
      />
    </div>
  );

  const displayEndOnOptions = (
    <>
      <label className="ends-group-label">Ends:</label>
      <div className='col-md-12'>
        <APRadioButton
          text='Never'
          name='recurringEnds'
          value='never'
          checked={true}
          onChange={handleRadioButtonChange}
          disabled={disabledFormFieldsState['recurringEnds']}
        />
      </div>
      <div className="pay-off-total-group">
        <APRadioButton
          className="pay-off-total-btn"
          text='When total paid:'
          name='recurringEnds'
          value='whenTotalPaid'
          onChange={handleRadioButtonChange}
        />
        <APInputMonetary
          className='end-amount'
          disabled={disabled || disabledFormFieldsState['endAmountField']}
          hasAddon={false}
          isFloatingLabel={true}
          label='Total Amount Due'
          name='endAmountField'
          onChange={handleAmountChange}
          value={endAmountState}
        />
      </div>
    </>
  );

  const displayFrequencyElements = (
    <>
      <div className='row form-group'>
        <div className='col-md-6'>
          {displayFrequencyDropdown}
        </div>
        <div className='col-md-2 align-middle days-label'>
          {displayLabeledDays()}
        </div>
        <div className='col-md-4'>
          {displaySecondDayDropdown}
        </div>
      </div>
      { recurringVisible === 'FULL' && (
        <div className='row hidden' ref={endOptionsActive}>
          {displayEndOnOptions}
        </div>
      )}
    </>
  );

  return displayFrequencyElements;
});

FrequencyComponent.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  recurringVisible: PropTypes.string,
  disabledFormFieldsState: PropTypes.object
};

export default FrequencyComponent;