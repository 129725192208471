import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {array, bool, func, number, object, oneOfType, string} from 'prop-types';
import './style.scss';
import { polyfillClosest } from '../../lib/Polyfills';
import {removeActiveClass, switchActiveClass} from '../../lib/utils';

export default class LegacyAPDatepicker extends React.Component {
  constructor(props) {
    super(props);
    polyfillClosest();
    this.state = {
      inputText: this.props.inputText,
      selectedInDatepicker: new Date(Date.now())
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
    if(this.props.selectedDays) {
      this.setInputText(this.props.selectedDays);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDays !== prevProps.selectedDays) {
      if (this.props.isLastDayFirstCharge !== 'LAST' && this.props.secondChargeDate !== 31) {
        this.setInputText(this.props.selectedDays);
      } else {
        this.setState({ inputText: 'Last Day of the Month' });
      }
    }

    if (this.props.isResetRequired !== prevProps.isResetRequired) {
      this.setState({inputText: ''});
    }

    if (this.props.lastDayTextEnabled !== prevProps.lastDayTextEnabled ) {
      this.setInputText(this.props.selectedDays);
    }
  }

  handleOutsideClick = (e) => {
    const clickedParent = e.target.closest('.LegacyAPDatepicker'),
      allDatePickers = document.getElementsByClassName('day-picker-container');
    for (let datepicker of allDatePickers) { // eslint-disable-line no-unused-vars
      if (datepicker.closest('.LegacyAPDatepicker') !== clickedParent) {
        removeActiveClass(datepicker);
      }
    }
  };

  handleInputClick = (e) => {
    const datePickerContainer = e.currentTarget.nextElementSibling;
    switchActiveClass(datePickerContainer);
  };

  handleLastDayButtonClick = (e) => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'Recurring Charge Page',
        event_label: 'Last Day of the Month'
      });
    }
    
    const date = !this.props.canChangeMonth ? new Date('2018/07/31') : new Date(this.state.selectedInDatepicker.getFullYear(), this.state.selectedInDatepicker.getMonth() + 1, 0);
    this.handleDayClick(date, {isLastDay: true}, e);
  };

  handleDayClick = (day, modifiers = {}, e) => {
    if ( modifiers.disabled ) {
      return;
    }
    const container = e.currentTarget.closest('.day-picker-container');
    removeActiveClass(container);
    if ( modifiers.isLastDay ) {
      this.setInputText('LAST');
      if (typeof this.props.onDayCallback === 'function') {
        this.props.onDayCallback(day, 'LAST');
      }
    } else {
      this.setInputText(day);
      if (typeof this.props.onDayCallback === 'function') {
        this.props.onDayCallback(day);
      }
    }
  };

  handleMonthChange = month => {
    this.setState({ selectedInDatepicker: month });
  };

  getOrdinal = (day) => {
    if (day < 4 || day > 20) {
      switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
      }
    } else {
      return 'th';
    }
  };

  setInputText = day => {
    const {placeholderText} = this.state;

    if (placeholderText === 'Select a day' && day === undefined) {
      this.setState({ inputText: 'Select a day' });
      return;
    }

    if (placeholderText === 'Select a date' && day === undefined) {
      this.setState({ inputText: 'Select a date' });
      return;
    }

    if ( day === 'LAST' && this.props.lastDayTextEnabled) {
      this.setState({ inputText: 'Last Day of the Month' });
      return;
    }

    if (!day && this.props.placeholderText) {
      this.setState({ inputText: this.props.placeholderText });
      return;
    }

    const today = new Date(Date.now()),
      selectedDay = day,
      isSameDay =
        today.getMonth().toString() + today.getDate() + today.getFullYear() ===
        selectedDay.getMonth().toString() +
          selectedDay.getDate() +
          selectedDay.getFullYear();
    this.setState({ placeholderDisabled: true });
    if (this.props.isNavHidden) {
      this.setState({
        inputText: `${day.getDate()}${this.getOrdinal(day.getDate())}`
      });
    } else if (isSameDay && this.props.todayTextEnabled) {
      this.setState({ inputText: 'Today' });
    } else {
      this.setState({
        inputText: `${selectedDay.getMonth() +
          1}/${selectedDay.getDate()}/${selectedDay
            .getFullYear()
            .toString()
            .substr(-2)}`
      });
    }
  };

  getParentClass = () => {
    const {isNavHidden, lastDayButtonEnabled, placeholderText, inlineLabel} = this.props;
    const hideNav = isNavHidden ? ' hidden-nav' : '';
    const lastDayEnabled = lastDayButtonEnabled ? ' last-day-enabled' : '';
    const placeHolder = (placeholderText && !this.state.placeholderDisabled) ? ' placeholder' : '';
    const hasInline = inlineLabel ? ' inline-label' : '';
    return `LegacyAPDatepicker${hideNav}${lastDayEnabled}${placeHolder}${hasInline}`;
  };

  render() {
    const {
      canChangeMonth,
      datePickerId,
      disabledDays,
      fromMonth,
      isLastDayFirstCharge,
      isLastDaySecondCharge,
      labelText,
      lastDayButtonEnabled,
      month,
      secondChargeDate,
      selectedDays
    } = this.props;

    return (
      <div className={this.getParentClass()}>
        {labelText && <label>{labelText}</label>}
        <div className="element-wrapper">
          <div
            className="form-control datepicker-input"
            onClick={this.handleInputClick}
            id={datePickerId}
          >
            {this.state.inputText}
          </div>
          <div className="day-picker-container">
            <DayPicker
              disabledDays={disabledDays ? disabledDays : null}
              fromMonth={fromMonth}
              month={month}
              selectedDays={selectedDays}
              isLastDayFirstCharge={isLastDayFirstCharge}
              isLastDaySecondCharge={isLastDaySecondCharge}
              onDayClick={this.handleDayClick}
              secondChargeDate={secondChargeDate}
              canChangeMonth={canChangeMonth !== undefined ? canChangeMonth: true
              }
              onMonthChange={this.handleMonthChange}
            />
            {lastDayButtonEnabled && (
              <div className='last-day-container'>
                <button
                  type="button"
                  className="last-day"
                  onClick={this.handleLastDayButtonClick}
                >
                  Last Day of the Month
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

LegacyAPDatepicker.propTypes = {
  canChangeMonth: bool,
  datePickerId: string,
  disabledDays: oneOfType([array, object]),
  fromMonth: object,
  labelText: string,
  lastDayButtonEnabled: bool,
  lastDayTextEnabled: bool,
  inlineLabel: bool,
  inputText: string,
  isLastDayFirstCharge: string,
  isLastDaySecondCharge: string,
  isNavHidden: bool,
  isResetRequired: number,
  month: object,
  onDayCallback: func,
  placeholderText: string,
  secondChargeDate: number,
  selectedDays: object,
  todayTextEnabled: bool
};

LegacyAPDatepicker.defaultProps = {
  canChangeMonth: true,
  inlineLabel: false,
  isNavHidden: false,
  labelText: '',
  lastDayButtonEnabled: false,
  lastDayTextEnabled: false,
  month: new Date(Date.now()),
  todayTextEnabled: false
};
