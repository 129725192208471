import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function HostedFieldLabeled({
  hostedFieldsState,
  id,
  label,
  isRequired = false,
  disabled
}) {

  const [hostedField, setHostedField] = useState({
    selector: '',
    error: '',
    length: 0,
    focus: false,
    card: ''
  });

  const [touched, setTouched] = useState(false);
  const [cardBrand, setCardBrand] = useState('generic-card');
  const labelId = `${label.toLowerCase().split(' ').join('-')}-label`;
  const isCardInput = id === 'cc_number';
  const isValueEmpty = hostedField.length === 0;
  const cardsWithIcons = ['visa', 'amex', 'mastercard', 'dinersclub', 'jcb', 'discover'];

  const getWrapClassName = (disabled, touched, focused, isRequired, isValueEmpty, error) => {
    if (!touched) return '';
    if (disabled) return isValueEmpty ? 'disabled' : 'disabled active';
    if (focused) return 'active focused';
    if (isRequired && isValueEmpty && touched) return 'invalid';
    if (error) return 'invalid active';
    return '';
  };

  const getLabelText = (label, touched, focused, isRequired, isValueEmpty, error) => {
    if (focused || !touched) return label;
    if (isRequired && isValueEmpty) return `${label} is required`;
    if (error) return `${label} is invalid`;
    return label;
  };

  useEffect(() => {
    const target = hostedFieldsState?.target;
    const field = hostedFieldsState?.fields?.find(field => field.selector === `#${id}`);
    if(target?.selector === `#${id}`) {
      if (touched === false) setTouched(target?.focus === true);
      setHostedField(target);
    } else if (field) {
      setHostedField(field);
    }
  }, [hostedFieldsState, touched]);

  useEffect(() => {
    const setCardBrandIcon = () => {
      //Hosted Fields can recognize: 'elo', 'maestro', 'forbrugsforeningen', 'dankort', 'visa','mastercard', 'amex', 'dinersclub', 'discover', 'unionpay', 'jcb'
      const card = hostedField.card;
      if(card !== cardBrand.name) {
        const brand = cardsWithIcons.indexOf(card) > -1 ? card : 'generic-card';
        if(brand !== cardBrand.name) {
          setCardBrand(brand)
        }
      }
    };

    if (isCardInput && hostedField.selector === '#cc_number' ) {
      setCardBrandIcon();
    }
  }, [hostedFieldsState, hostedField]);

  return (
    <div className={`ap-labeled-hosted-input ${getWrapClassName(disabled, touched, hostedField.focus, isRequired, isValueEmpty, hostedField.error)}`}>
      <label className='inline-label'
        id={labelId}
      >
        {getLabelText(label, touched, hostedField.focus, isRequired, isValueEmpty, hostedField.error)}
      </label>
      <div
        id={id}
        data-testid={id}
        className={`hosted-wrap ${isCardInput ? 'col-10' : ''}`}
        aria-labelledby={labelId}
      ></div>
      {isCardInput && (
        <div
          className={`input-card-icon ${cardBrand}`}
          role="img"
          alt="credit cards logo"
          title="credit card logo"
        ></div>
      )}
    </div>
  );
}

HostedFieldLabeled.propTypes = {
  addFocus: PropTypes.func,
  removeFocus: PropTypes.func,
  hostedFieldsState: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool
};

export default HostedFieldLabeled;
