import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import './style.scss';
import { safeSendGA } from '../../lib/utils';

export const NavigationItem = ({
  className,
  isActive,
  isMobile,
  text,
  url,
  onClick
}) => {
  const divId = text.toLowerCase().replace(' ', '-');
  return (
    <li className={isActive ? 'current' : ''}>
      <a href={url}
        onClick={onClick}
        className={className}
      >
        <div
          id={isMobile ? `mobile-${divId}` : `nav-${divId}`}
          className="nav-icon"
        />
        <span className="nav-text">{text}</span>
      </a>
    </li>
  );
};

const Navigation = ({ links, isAlertMode, isMobile}) => {
  const [topNavHeight, setTopNavHeight] = useState(document.querySelector('#top-navbar')?.clientHeight || 0);

  useEffect(() => {
    const appContent = document.querySelector('.application-content');
    if (appContent) {
      const initialNavigationHeight = document.querySelector('#top-navbar')?.clientHeight || 0;
      setTopNavHeight(initialNavigationHeight);
      appContent.style = `margin-top: ${initialNavigationHeight}px`;
    }
  }, []);

  const handleNavigationClick = ({currentTarget}) => {
    const current = document.querySelector('.current');
    if (current) {
      current.className = current.className.replace('current', '');
    }
    if (currentTarget.className === 'reports_link') {
      safeSendGA(
        'Reports',
        'click',
        'Link to Reports Clicked'
      );
    }
    currentTarget.parentElement.className =  'current';
  };

  let navList = links.map((link, i) => {
    return link.isShown ?
      <NavigationItem url={link.url}
        className={link.className}
        text={link.text}
        isMobile={isMobile}
        key={i}
        isActive={link.isActive}
        onClick={handleNavigationClick}
      /> : '';
  });

  return (
    <nav
      className={`row merchant ${isAlertMode ? 'alert-mode' : ''}`}
      style={{ top: `${topNavHeight}px`}}
    >
      <ul>
        {navList}
      </ul>
    </nav>
  );
};

NavigationItem.propTypes = {
  className: string,
  isActive: bool.isRequired,
  isMobile: bool,
  text: string.isRequired,
  url: string.isRequired,
  onClick: func.isRequired
};

Navigation.propTypes = {
  links: arrayOf(shape({
    className: string,
    url: string.isRequired,
    text: string.isRequired,
    isShown: bool,
    isActive: bool.isRequired
  })).isRequired,
  isAlertMode: bool,
  isMobile: bool
};

export default Navigation;
