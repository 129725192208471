import React from 'react';
import PropTypes from 'prop-types';
import { APInputText, APSelect } from 'affinipay-ui-library';
import { usStates, countries, caStates } from '../../lib/constants';
import { isFieldRequired } from '../../lib/utils';
import { getFieldLabel } from '../../lib/paymentPage/optionalFields';
import ReceiptEmailFieldComponent from './ReceiptEmailField';

const BillingAddressComponent = ({
  formState,
  handleInputChange,
  prefix,
  optionalFields = [],
  disabledFieldsState,
  revalidateEmail,
  clientInfoState,
  setClientInfoState
}) => {
  const handleZipChange = e => {
    handleInputChange(e);
  };

  const handleCountryChange = e => {
    const needsStateReset = e.target.value !== formState[`${prefix}BillingCountry`];
    handleInputChange(e, needsStateReset);
  };

  return (
    <div className="billing-address row">
      <div className="col-12">
        <h3>Billing Address</h3>
      </div>
      <div className="col-12 form-group">
        <APInputText
          isFloatingLabel={true}
          label={getFieldLabel('Address', `${prefix}BillingAddress`, optionalFields)}
          value={formState[`${prefix}BillingAddress`]}
          name={`${prefix}BillingAddress`}
          onChange={handleInputChange}
          required={isFieldRequired(`${prefix}BillingAddress`, optionalFields)}
          disabled={disabledFieldsState[`${prefix}BillingAddress`]}
        />
      </div>
      <div className="col-12 form-group">
        <APInputText
          isFloatingLabel={true}
          label="Address 2"
          value={formState[`${prefix}BillingAddress2`]}
          name={`${prefix}BillingAddress2`}
          onChange={handleInputChange}
          disabled={disabledFieldsState[`${prefix}BillingAddress2`]}
        />
      </div>
      <div className="col-12 col-sm-6 form-group">
        <APInputText
          isFloatingLabel={true}
          label={getFieldLabel('City', `${prefix}BillingCity`, optionalFields)}
          value={formState[`${prefix}BillingCity`]}
          name={`${prefix}BillingCity`}
          onChange={handleInputChange}
          required={isFieldRequired(`${prefix}BillingCity`, optionalFields)}
          disabled={disabledFieldsState[`${prefix}BillingCity`]}
        />
      </div>
      <div className="col-12 col-sm-6 form-group state-dropdown">
        <StateSelectComponent
          formState={formState}
          prefix={prefix}
          isRequired={isFieldRequired(`${prefix}BillingState`, optionalFields)}
          handleInputChange={handleInputChange}
          disabled={disabledFieldsState[`${prefix}BillingState`]}
        />
      </div>
      <div className="col-12 col-sm-6 form-group">
        <APInputText
          isFloatingLabel={true}
          label={getFieldLabel('Zip / Postal Code', `${prefix}BillingZip`, optionalFields)}
          value={formState[`${prefix}BillingZip`]}
          name={`${prefix}BillingZip`}
          onChange={handleZipChange}
          required={isFieldRequired(`${prefix}BillingZip`, optionalFields)}
          disabled={disabledFieldsState[`${prefix}BillingZip`]}
        />
      </div>
      <div className="col-12 col-sm-6 form-group">
        <APSelect
          options={countries}
          label={getFieldLabel('Country', `${prefix}BillingCountry`, optionalFields)}
          value={formState[`${prefix}BillingCountry`]}
          name={`${prefix}BillingCountry`}
          onChange={handleCountryChange}
          required={isFieldRequired(`${prefix}BillingCountry`, optionalFields)}
          disabled={disabledFieldsState[`${prefix}BillingCountry`]}
          isFloatingLabel={true}
        />
      </div>
      <ReceiptEmailFieldComponent
        formState={clientInfoState}
        setFormState={setClientInfoState}
        optionalFields={optionalFields}
        revalidateEmail={revalidateEmail}
        disabledFieldsState={disabledFieldsState}
        prefix={prefix}
      />
    </div>
  );
};

const StateSelectComponent = ({
  formState,
  prefix,
  handleInputChange,
  isRequired = false,
  disabled
}) => {
  const country = formState[`${prefix}BillingCountry`].toLowerCase(),
    state = formState[`${prefix}BillingState`];

  if (country === 'us' || country === 'ca' || !country) {
    return (
      <APSelect
        options={country === 'us' ? usStates : caStates}
        label='State'
        name={`${prefix}BillingState`}
        onChange={handleInputChange}
        required={isRequired}
        disabled={disabled}
        value={state}
        isFloatingLabel={true}
      />
    );
  } else {
    return (
      <APInputText
        isFloatingLabel={true}
        label={`State/Province`}
        name={`${prefix}BillingState`}
        value={formState[`${prefix}BillingState`]}
        onChange={handleInputChange}
        isRequired={isRequired}
        disabled={disabled}
      />
    );
  }
};

BillingAddressComponent.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  handleInputChange: PropTypes.func,
  prefix: PropTypes.string,
  disabledFieldsState: PropTypes.object,
  optionalFields: PropTypes.array,
  revalidateEmail: PropTypes.string,
  merchantCountry: PropTypes.string,
  clientInfoState: PropTypes.object,
  setClientInfoState: PropTypes.func
};

StateSelectComponent.propTypes = {
  formState: PropTypes.object,
  handleInputChange: PropTypes.func,
  prefix: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool
};

export default BillingAddressComponent;