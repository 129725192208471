import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { APSelect, APInputMonetary, APInputPercent, APCheckbox } from 'affinipay-ui-library';

const AdyenCardFees = ({
  fees = [],
  resourceName = 'pricing',
  pricingModel = 'standard',
  pricingTypes = [],
  newPlan = true,
  includePassthroughFees = false
}) => {
  const initialState = fees.reduce((rest, fee) => ({ ...rest, [fee.name]: fee}), {});
  const [cardFees, setCardFees] = useState(initialState);
  const [passThroughFeesEnabled, setPassThroughFeesEnabled] = useState(newPlan ? true : includePassthroughFees);
  const [pricingModelState, setPricingModelState] = useState(pricingModel);

  const onFeeChange = (e) => {
    const { value, dataset: { feename: feeName } } = e.target;
    setCardFees(prevFees => ({
      ...prevFees,
      [feeName]: {
        ...prevFees[feeName],
        rate: value
      }
    }));
  };

  const onPassThroughFeeChange = (e) => {
    const { checked } = e.target;
    setPassThroughFeesEnabled(checked);
  };

  const inputName = (fieldName) => `${resourceName}[${fieldName}]`;

  const inputId = (fieldName) => `${resourceName}_${fieldName}`;

  const buildFieldErrors = (fee) => hasErrorsOn(fee) ? fee.errors.rate.join(', ') : '';

  const hasErrorsOn = (fee) => fee?.errors && fee?.errors?.['rate'];

  const {
    credit_net_volume, credit_net_volume_amex, debit_net_volume_amex, credit_per_transaction,
    credit_per_transaction_amex, debit_per_transaction_amex, chargeback_per_transaction, foreign_net_volume,
    debit_net_volume_reg, debit_net_volume_unreg, debit_per_transaction_reg,
    debit_per_transaction_unreg
  } = cardFees;

  const handlePricingModelChange = (e) => {
    setPricingModelState(e.target.value);
  };

  return (
    <div className="admin card-fees bottom-spacer-20">
      <div className='row bottom-spacer-20'>
        <div className='col-sm-6'>
          <APSelect
            name={inputName('pricing_model')}
            id={inputId('pricing_model')}
            nativeElementId={inputId('pricing_model')}
            options={pricingTypes}
            value={pricingModelState}
            label='Pricing Model'
            needsNativeElement={true}
            required={true}
            disabled={!newPlan}
            onChange={handlePricingModelChange}
            parentClass="admin"
          />
        </div>
      </div>
      { pricingModelState === 'standard' && (<div>
        <div className='row'>
          <div className='col-sm-3'>
            <h4>RATE</h4>
          </div>
          <div className='col-sm-3'>
            <h4>PER ITEM FEES</h4>
          </div>
          <div className='col-sm-3'>
            <h4>ALL CARDS</h4>
          </div>
        </div>
        <div className='row bottom-spacer-20'>
          <div className='col-sm-3'>
            <APInputPercent
              name={inputName('debit_net_volume_reg')}
              id={inputId('debit_net_volume_reg')}
              value={debit_net_volume_reg?.rate || ''}
              label='Regulated Debit'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'debit_net_volume_reg'}}
              error={buildFieldErrors(debit_net_volume_reg)}
            />
          </div>
          <div className='col-sm-3'>
            <APInputMonetary
              name={inputName('debit_per_transaction_reg')}
              id={inputId('debit_per_transaction_reg')}
              value={debit_per_transaction_reg?.rate || ''}
              label='Regulated Debit'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'debit_per_transaction_reg'}}
              error={buildFieldErrors(debit_per_transaction_reg)}
            />
          </div>
          <div className='col-sm-3'>
            <APInputMonetary
              name={inputName('chargeback_per_transaction')}
              id={inputId('chargeback_per_transaction')}
              value={chargeback_per_transaction?.rate || ''}
              label='Chargeback Fee'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'chargeback_per_transaction'}}
              error={buildFieldErrors(chargeback_per_transaction)}
            />
          </div>
        </div>
        <div className='row bottom-spacer-20'>
          <div className='col-sm-3'>
            <APInputPercent
              name={inputName('card_net_volume')}
              id={inputId('card_net_volume')}
              value={credit_net_volume?.rate || debit_net_volume_unreg?.rate || ''}
              label='Card'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'credit_net_volume'}}
              error={buildFieldErrors(credit_net_volume) || buildFieldErrors(debit_net_volume_unreg)}
            />
          </div>
          <div className='col-sm-3'>
            <APInputMonetary
              name={inputName('card_per_transaction')}
              id={inputId('card_per_transaction')}
              value={credit_per_transaction?.rate || debit_per_transaction_unreg?.rate || ''}
              label='Card'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'credit_per_transaction'}}
              error={buildFieldErrors(credit_per_transaction) || buildFieldErrors(debit_per_transaction_unreg)}
            />
          </div>
          <div className='col-sm-3'>
            <APInputPercent
              name={inputName('foreign_net_volume')}
              id={inputId('foreign_net_volume')}
              value={foreign_net_volume?.rate || ''}
              label='Foreign Card Fee'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'foreign_net_volume'}}
              error={buildFieldErrors(foreign_net_volume)}
            />
          </div>
        </div>
        <div className='row bottom-spacer-20'>
          <div className='col-sm-3'>
            <APInputPercent
              name={inputName('amex_net_volume')}
              id={inputId('amex_net_volume')}
              value={credit_net_volume_amex?.rate || debit_net_volume_amex?.rate || ''}
              label='American Express'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'credit_net_volume_amex'}}
              error={buildFieldErrors(credit_net_volume_amex) || buildFieldErrors(debit_net_volume_amex)}
            />
          </div>
          <div className='col-sm-3'>
            <APInputMonetary
              name={inputName('amex_per_transaction')}
              id={inputId('amex_per_transaction')}
              value={credit_per_transaction_amex?.rate || debit_per_transaction_amex?.rate || ''}
              label='American Express'
              onChange={onFeeChange}
              parentClass='admin'
              dataset={{'data-feename': 'credit_per_transaction_amex'}}
              error={buildFieldErrors(credit_per_transaction_amex) || buildFieldErrors(debit_per_transaction_amex)}
            />
          </div>
          <div className='col-sm-3'>
            <input
              type='hidden' 
              name={inputName('include_passthrough_fees')}
              value={false}
              data-testid='hidden-passthrough-fees-input'
            />
            <APCheckbox
              name={inputName('include_passthrough_fees')}
              id={inputId('include_passthrough_fees')}
              checked={passThroughFeesEnabled ? true : false}
              value={passThroughFeesEnabled ? true : false}
              text='Enable Pass Through Fees'
              onChange={onPassThroughFeeChange}
              parentClass='admin'
            />
          </div>
        </div>
      </div>)
      }
    </div>
  );
};

export default AdyenCardFees;

AdyenCardFees.propTypes = {
  resourceName: PropTypes.string,
  fees: PropTypes.array,
  pricingModel: PropTypes.string,
  pricingTypes: PropTypes.array,
  newPlan: PropTypes.bool,
  includePassthroughFees: PropTypes.bool
};
