import React from 'react';
import PropTypes from 'prop-types';
import { APTooltip } from 'affinipay-ui-library';

function APTooltipRails({
  text
}) {
  return (
    <APTooltip  text={text}/>
  );
}

APTooltipRails.propTypes = {
  text: PropTypes.string
};

export default APTooltipRails;

