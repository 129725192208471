import { getDateFromHyphenedFormat, isObject, isEmptyObject } from 'lib/utils';

// This transforms any date field in beneficial owner response from merchant aggregate service from 
// YYYY-MM-DD format to javascript date object
export const transformOwnerResponseToFormState = (owner = {}) => {
  if (!owner.data?.attributes) {
    return {};
  }
  const dateOfBirth = getDateFromHyphenedFormat(owner.data.attributes.date_of_birth || '');
  const expirationDate = getDateFromHyphenedFormat(owner.data.attributes.identification?.expiration_date || '');
  return {
    ...owner.data.attributes,
    date_of_birth: isNaN(dateOfBirth) ? '' : dateOfBirth,
    identification: {
      ...(owner.data.attributes.identification || {}),
      ...(owner.data.attributes.identification?.expiration_date && {expiration_date: isNaN(expirationDate) ? '' : expirationDate})
    }
  };
};

// removes BO 'identification' data from patch request to merchant aggregate 
// if it hasnt changed from the initial form state 
// (cant do this on backend because identification data isnt stored in VT db)
export const transformBeneficialOwnerFormStateForUpdate = (initialState = {}, finalState = {}) => {
  const objectsEqual = Object.entries(initialState.identification || {}).every(([k, v]) => finalState.identification?.[k] === v);
  if (objectsEqual) {
    const { identification, ...newFinalState } = finalState; // eslint-disable-line no-unused-vars
    return newFinalState;
  }
  return finalState;
};

// removes LE 'tax_id' data from patch request to merchant aggregate 
// if it hasnt changed from the initial form state 
// (cant do this on backend because tax_id data isnt stored in VT db)
export const transformLegalEntityFormStateForUpdate = (initialState = {}, finalState = {}) => {
  const taxIdEqual = initialState.tax_id === finalState.tax_id;
  if (taxIdEqual) {
    const { tax_id, ...newFinalState } = finalState; // eslint-disable-line no-unused-vars
    return newFinalState;
  }
  return finalState;
};

export const buildIdentificationTypeFormState = (identificationType) => {
  const today = new Date();
  return {
    us_social_security: {
      type: 'us_social_security',
      number: ''
    },
    us_driver_license: {
      type: 'us_driver_license',
      number: '',
      state: '',
      expiration_date: today
    },
    passport: {
      type: 'passport',
      number: '',
      country: '',
      expiration_date: today
    },
    visa: {
      type: 'visa',
      number: '',
      country: '',
      expiration_date: today
    }
  }[identificationType];
};

export const isBeneficialOwnerFormValid = (formState) => {
  const {years_at_residence, ...formStateToValidate} = formState; // eslint-disable-line no-unused-vars
  return Object.keys(formStateToValidate).every(key => {
    if (isObject(formStateToValidate[key])) {
      return Object.keys(formStateToValidate[key]).every(objectKey => objectKey === 'address2' || formStateToValidate[key][objectKey]) && !isEmptyObject(formStateToValidate[key]); 
    } else {
      return formStateToValidate[key] !== undefined && formStateToValidate[key] !== null && formStateToValidate[key] !== '';
    }
  });
};