// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`merchant notes modal shallow renders component 1`] = `
<div>
  <button
    className="pull-right btn btn-success"
    onClick={[Function]}
  >
    Create Merchant Note
  </button>
  <div
    role="dialog"
  />
</div>
`;
