// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Echeck Component shallow snapshot render 1`] = `
<div
  className="outerContainer optin-echeck"
  data-jest-echecks-container={true}
>
  <div
    className="container-fluid messageBorder"
  >
    <div
      className="flexyContainer row"
    >
      <div
        className="col-lg-5"
        style={
          {
            "display": "flex",
          }
        }
      >
        <div
          className="stockImage"
        >
          <img
            src="echeck_opt_in/echeck-lp-graphic.jpg"
          />
        </div>
      </div>
      <div
        className="col-lg-7"
      >
        <div
          className="wrapper"
        >
          <Success
            onClose={[Function]}
            successIcon="echeck_opt_in/icn_success.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
`;
