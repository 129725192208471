import { useState, useEffect } from 'react';
import AccessToken from './AccessToken';
import AccessTokenService from './AccessTokenService';

// Refresh the access token 1 minute before expiry
const TOKEN_REFRESH_BACKOFF_MS = 60000;

export const useRefreshingToken = (authorizationUrl) => {
  const [refreshEnabled, setRefreshEnabled] = useState(false);
  const [token, setToken] = useState(new AccessToken({}));

  const refreshAccessToken = async () => {
    const tokenResponse = await AccessTokenService(authorizationUrl);
    setToken(tokenResponse);
  };

  useEffect(() => {
    if (refreshEnabled) {
      refreshAccessToken();
    } else {
      setToken(new AccessToken({}));
    }
  }, [refreshEnabled]);

  useEffect(() => {
    let timerId = null;
    if (token && token.accessToken && token.expiresIn > 0) {
      const delayMillis = Math.max((token.expiresIn * 1000) - TOKEN_REFRESH_BACKOFF_MS, TOKEN_REFRESH_BACKOFF_MS);
      timerId = setTimeout(refreshAccessToken, delayMillis);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [token, authorizationUrl]);

  return { refreshEnabled, setRefreshEnabled, token };
};
