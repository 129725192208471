import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import TrainingStatus from './TrainingStatus';
import client from 'lib/ajax';
import { merchant } from './fixtures';
import notify from 'lib/notify';

describe('TrainingStatus component', () => {

  it('Renders default status with only required props', () => {
    render(<TrainingStatus merchantId={1}/>);
    expect(screen.getByText('not trained')).toBeInTheDocument();
  });

  it('Renders the correct value ', () => {
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled'/>);
    expect(screen.getByText('scheduled')).toBeInTheDocument();
    expect(screen.queryByText('not trained')).not.toBeInTheDocument();
  });

  it('does not render a dropdown by default', () => {
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled'/>);
    expect(screen.getByText('scheduled')).toBeInTheDocument();
    expect(screen.queryByTestId('combobox')).not.toBeInTheDocument();
  });

  it('does render a dropdown if adminCanEdit is set to true', () => {
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled' adminCanEdit={true}/>);
    expect(screen.getByText('Scheduled')).toBeInTheDocument();
    expect(screen.getByTestId('combobox')).toBeInTheDocument();
  });

  it('Disables the dropdown while loading', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(merchant);
        })
      })
    );
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled' adminCanEdit={true}/>);
    userEvent.click(screen.getByText('Scheduled'));
    userEvent.click(screen.getByText('Trained'));
    expect(screen.getByText('Scheduled').parentElement).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Trained').parentElement).not.toBeDisabled();
  });

  it('Sets new training status based on user input', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(merchant);
        })
      })
    );
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled' adminCanEdit={true}/>);
    userEvent.click(screen.getByText('Scheduled'));
    userEvent.click(screen.getByText('Trained'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Trained')).toBeInTheDocument();
  });

  it('Calls notify to alert user of an error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    jest.spyOn(notify, 'error').mockImplementation(() => {});
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled' adminCanEdit={true}/>);
    userEvent.click(screen.getByText('Scheduled'));
    userEvent.click(screen.getByText('Trained'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledWith('test error');
    expect(screen.queryByText('Trained')).not.toBeInTheDocument();
    expect(screen.getByText('Scheduled')).toBeInTheDocument();
  });

  it('Calls notify with generic error in case of 500 error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() => {
      throw new Error();
    });
    jest.spyOn(notify, 'error').mockImplementation(() => {});
    render(<TrainingStatus merchantId={1} initialTrainingStatus='scheduled' adminCanEdit={true}/>);
    userEvent.click(screen.getByText('Scheduled'));
    userEvent.click(screen.getByText('Trained'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    const genericError = 'Training status couldn’t be updated, please refresh the page and try again';
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledWith(genericError);
    expect(screen.queryByText('Trained')).not.toBeInTheDocument();
    expect(screen.getByText('Scheduled')).toBeInTheDocument();
  });
});
