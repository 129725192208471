import React from 'react';
import ReactDOM from 'react-dom';
import Navigation, {NavigationItem} from './';
import {shallow, mount} from 'enzyme';

describe('Navigation Component', () => {
  it('renders without crashing', () => {
    const div = document.createElement('div');
    ReactDOM.render(<Navigation links={mockLinks}/>, div);
    ReactDOM.unmountComponentAtNode(div);
  });
  it('shallow snapshot render', () => {
    const component = shallow(<Navigation links={mockLinks} debug/>);
    expect(component).toMatchSnapshot();
  });
  it('test handleNavigation', () => {
    const component = mount(<Navigation links={mockLinks} isAlertMode={true}/>);
    component.find('div#nav-dashboard')
      .simulate('click');
    expect(true).toBe(true);
    component.unmount();
  });
  it('navLink click navigates', () => {
    const component = mount(<NavigationItem url={mockLinks[0].url}
      text={mockLinks[0].text}
      key={1}
      isActive={mockLinks[1].isActive}
      onClick={clickFn}/>);
    component.find('div#nav-dashboard')
      .simulate('click');
    expect(clickFn).toHaveBeenCalled();
    component.unmount();
  });
});
const clickFn = jest.fn();
const mockLinks = [
  {
    url: '/',
    text: 'Dashboard',
    isShown: true,
    isActive: true
  },
  {
    url: '/any',
    text: 'Charge',
    isShown: false,
    isActive: false
  }];
