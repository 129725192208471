import React, {useEffect, useState} from 'react';
import {bool, func, instanceOf, oneOfType, number, string} from 'prop-types';
import { APButton, APModal, APSelect, APDatepicker } from 'affinipay-ui-library';
import PCICompliancesService from './src/services/PCICompliancesService';
import { complianceStatusOptions } from './src/lib/StatusOptions';

const filteredStatusOptions = complianceStatusOptions.filter(status => !['All', 'Started'].includes(status.text));

const determineOpenDate = (merchantDueDate, today) => {
  if (!merchantDueDate) return today;
  return today > merchantDueDate ? today : merchantDueDate;
};

export const toISODate = date => new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().replace(/^(\d+?)-(\d+?)-(\d+).+/, '$1-$2-$3');

const PCIModal = ({
  isModalOpen,
  merchantStatus,
  merchantDueDate,
  merchantUUID,
  merchantVTID,
  onClose,
  onCloseAndRefresh
}) => {
  const today = new Date();
  const [date, setDate] = useState(merchantDueDate || today);
  const [status, setStatus] = useState(merchantStatus);

  useEffect(() => {
    setStatus(merchantStatus);
    setDate(merchantDueDate || today);
  }, [merchantUUID, merchantStatus, merchantDueDate]);

  const handleDateChange = e => {
    const value = e?.target?.value;
    setDate(value);
  };

  const handleStatusChange = e => {
    const {value} = e.target;
    setStatus(value);
  };

  const handleComplianceReassignment = async () => {
    await PCICompliancesService.patch(merchantUUID, status, toISODate(date));
    onCloseAndRefresh();
  };

  return (
    <APModal
      className="ap-pci-modal"
      headerText="Update"
      isActiveInitial={isModalOpen}
      onClose={onClose}
    >
      <div className="ap-pci-modal-date-wrap">
        <h4 className="ap-pci-modal-header">Change Due Date</h4>
        <p className="ap-pci-modal-copy" data-testid="ap-pci-modal-datepicker-text">
          Please use the datepicker below to change <span className="ap-pci-modal-bold" data-testid="ap-pci-modal-datepicker-customer-id">{`Customer ID ${merchantVTID}'s`}</span> date of PCI compliance.
        </p>
        <APDatepicker
          datepickerId="ap-pci-modal-datepicker"
          value={date}
          required={true}
          minimumDate={today}
          onChange={handleDateChange}
          openToDate={determineOpenDate(merchantDueDate, today)}
        />
      </div>
      <h4 className="ap-pci-modal-header">Change Status</h4>
      <p className="ap-pci-modal-copy">
        Please select a new compliance status from the dropdown options below.
      </p>
      <APSelect
        className="ap-pci-modal-status"
        label="Compliance Status"
        onChange={handleStatusChange}
        options={filteredStatusOptions}
        placeholderText="Select Status"
        required={true}
        value={status}
        parentClass="admin"
      />
      <div className="ap-pci-modal-save">
        <APButton
          className="ap-button ap-table-button admin small"
          disabled={!status || !date}
          onClick={handleComplianceReassignment}
          type="button"
        >
          Save changes
        </APButton>
      </div>
    </APModal>
  );
};

PCIModal.propTypes = {
  isModalOpen: bool,
  merchantDueDate: instanceOf(Date),
  merchantStatus: string,
  merchantUUID: string,
  merchantVTID: oneOfType([string, number]),
  onClose: func,
  onCloseAndRefresh: func
};
export default PCIModal;
