import React, { useState } from 'react';
import { APButton, APFormMessageBox } from 'affinipay-ui-library';
import { deleteFeature } from './helpers/services';
import { strings } from './helpers/constants';
import PropTypes from 'prop-types';

const DeleteFeatureForm = ({ feature, setFeatures, setSelectedFeature, closeModal }) => {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    try {
      const response = await deleteFeature(feature);
      const json = await response.json();
      if (response.ok) {
        const deletedFeature = json.data?.attributes;
        setIsSubmitting(false);
        setFeatures(features => features.filter(f => f?.key !== deletedFeature?.key));
        setSelectedFeature(null);
        closeModal();
      }
      else {
        setError(json.error);
        setIsSubmitting(false);
      }
    }
    catch(e) {
      setError(strings.deleteFeatureError);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {error &&
        <APFormMessageBox type="error">
          <p className='error-header'>
            {error}
          </p>
        </APFormMessageBox>
      }
      <form>
        <div>
          <label className='row'>{strings.deleteFeatureWarningText}</label>
        </div>
        <div className='row text-center'>
          <APButton
            className='ap-negative-button'
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Remove
          </APButton>
        </div>
      </form>
    </>
  );
};

DeleteFeatureForm.propTypes = {
  feature: PropTypes.object,
  setFeatures: PropTypes.func,
  setSelectedFeature: PropTypes.func,
  closeModal: PropTypes.func
};

export default DeleteFeatureForm;
