import React, { useEffect, useState } from 'react';
import { bool, oneOf, string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import './style.scss';
import {CloseIcon, PCIDueSoonIcon} from './pciIcons';
import {Expiring, ExpiresSoon, Expired} from './messages';
import ErrorBoundary from '../../components/ErrorBoundary/index.js';

const SESSION_KEY = 'Merchant::ComplianceDecorator::HIDE_COMPLIANCE_BANNER_KEY';

const PCIBanner = ({
  isAlertMode,
  pciComplianceUrl,
  pciDueBy,
  pciExpiresWithin
}) => {
  const [isHidden, setIsHidden] = useState(
    pciExpiresWithin === null ||
    window.sessionStorage.getItem(SESSION_KEY) ||
    !window.location.pathname.indexOf('/pci_compliance')
  );

  useEffect(() => {
    const removePCIBannerPresentClass = async () => {
      const mainContent = document.getElementById('mainContent');

      if (mainContent === null) return;

      if (isHidden) {
        await mainContent.classList.remove('pci-banner-present');
      } else {
        await mainContent.classList.add('pci-banner-present');
      }
    };
    removePCIBannerPresentClass();
  }, [isHidden]);

  const handleDismissal = async e => {
    e.preventDefault();
    const mainContent = document.getElementById('mainContent');

    if (mainContent === null) return;

    await mainContent?.classList.remove('pci-banner-present');
    window.sessionStorage.setItem(SESSION_KEY, true);
    setIsHidden(true);
  };

  const handleComplianceClick = () => window.location.assign(pciComplianceUrl);

  const pciMessages = {
    expiring: Expiring,
    'expires-soon': ExpiresSoon,
    expired: Expired
  };

  const getPCIStatus = pciExpiresWithin => {
    if (Number(pciExpiresWithin) === 0) return 'expires-soon';
    if (Number(pciExpiresWithin) === 1) return 'expiring';
    return 'expired';
  };

  const status = getPCIStatus(pciExpiresWithin);
  const SpecificBanner = pciMessages[status];

  return (!isHidden &&
    <ErrorBoundary showBoundaryMessage={false}>
      <div
        className={`pci-banner pci-banner-${status}${isAlertMode ? ' pci-banner-alert' : ''}`}
        id="pci-banner"
      >
        <div className="pci-banner-content">
          <PCIDueSoonIcon />
          <SpecificBanner
            dueBy={pciDueBy}
          />
          <APButton
            className="ap-transparent-button btn"
            onClick={handleComplianceClick}
            data-testid="pci-banner-compliance-button"
          >
            Get Compliant
          </APButton>
        </div>
        <div
          aria-label="close"
          className="pci-banner-dismissal"
          onClick={handleDismissal}
          role="button"
        >
          <CloseIcon />
        </div>
      </div>
    </ErrorBoundary>
  );
};

PCIBanner.propTypes = {
  isAlertMode: bool,
  pciComplianceUrl: string.isRequired,
  pciDueBy: string.isRequired,
  pciExpiresWithin: oneOf([1, 0, -1])
};

export default PCIBanner;
