import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import { emailRegex } from '../../lib/constants';
import { isEmailPattern } from '../../lib/utils';
import { isFieldRequired } from '../../lib/utils';
import { getFieldLabel } from '../../lib/paymentPage/optionalFields';

// eslint-disable-next-line react/display-name
const ReceiptEmailFieldComponent = React.memo(({
  formState,
  setFormState,
  onChange,
  revalidateEmail,
  optionalFields = [],
  disabledFieldsState,
  prefix,
  minimalRequiredPaymentFieldsSelected
}) => {

  const [emailError, setEmailError] = useState(undefined);

  useEffect(() => {
    if (revalidateEmail === 'required' &&
      formState.email &&
      !isEmailPattern(formState.email)) {
      setEmailError(' - Please provide a valid email');
    } else if (revalidateEmail === 'optional' && (emailError !== ' - Please provide a valid email' || !formState.email)) {
      setEmailError(undefined);
    }
  }, [revalidateEmail]);


  const handleInputChange = e => {
    const value = e.target.value;

    let newState = { ['email']: value };
    setFormState({ ...formState, ...newState });
    if (onChange) {
      onChange(e);
    }
  };

  const validateEmail = e => {
    const value = e.target.value;
    if (!value && !isFieldRequired('email', optionalFields)) {
      setEmailError(undefined);
      return;
    }
    if (value && emailRegex.test(String(value).toLowerCase())) {
      setEmailError(undefined);
    }
  };

  const invalidateEmail = e => {
    const value = e.target.value;
    if (value && !emailRegex.test(String(value).toLowerCase())) {
      setEmailError(' - Please provide a valid email');
    } else if (!value && isFieldRequired('email', optionalFields)) {
      setEmailError(' - Please provide a valid email');
    }
  };

  const handleEmailChange = e => {
    validateEmail(e);
    handleInputChange(e);
  };

  return (
    <div className={`col-12 form-group ${minimalRequiredPaymentFieldsSelected ? 'col-md-8' : ''}`}>
      <APInputText
        isFloatingLabel={true}
        label={getFieldLabel('Receipt Email', 'email', optionalFields)}
        error={emailError}
        required={isFieldRequired('email', optionalFields)}
        value={formState.email}
        name={`${prefix}Email`}
        onChange={handleEmailChange}
        onBlur={invalidateEmail}
        disabled={disabledFieldsState['email']}
      />
    </div>
  );
});

ReceiptEmailFieldComponent.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  onChange: PropTypes.func,
  setIsEmailValid: PropTypes.func,
  isEmailValid: PropTypes.bool,
  revalidateEmail: PropTypes.string,
  disabledFieldsState: PropTypes.object,
  optionalFields: PropTypes.array,
  prefix: PropTypes.string,
  minimalRequiredPaymentFieldsSelected: PropTypes.bool
};

export default ReceiptEmailFieldComponent;
