import { PropTypes } from 'prop-types';
import React from 'react';
import './paymentPageStyle.scss';

const LoanThankYou = ({ brand }) => {
  const brandLabel = brand?.toLowerCase()?.trim();

  return (
    <div className="loan-thank-you-page" data-testid='loan-thank-you-page'>
      <div className='loan-checkmark'>
      </div>
      <h1 className='loan-thank-you-message'>
        Thank you for your payment.
      </h1>
      <div>
        <p>
          Typically, your {brandLabel === 'cpacharge' ? 'CPA' : 'attorney'} should receive payment within a few business days.
          You will receive an email from Affirm confirming the details of your payment agreement.
        </p>
        <p>
          If you have any questions about your payment agreement, please visit  <a href="https://www.affirm.com/help" target="_blank" rel="noreferrer">www.affirm.com/help</a>
        </p>
      </div>
    </div>
  );
};

LoanThankYou.propTypes = {
  brand: PropTypes.string
};

export default LoanThankYou;
