import {fetch} from 'whatwg-fetch';

export const patchMerchantAppDeposit = async (path, body = {}) => {
  const formBody = [];
  for(const prop in body) {
    const key = encodeURIComponent(prop);
    const value = encodeURIComponent(body[prop]);
    formBody.push(`${key}=${value}`);
  }
  return await fetch(
    path,
    {
      credentials: 'same-origin',
      body: formBody.join('&'),
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content: ''
      }
    }
  );
};

const MerchantAppDepositService = {
  patchMerchantAppDeposit
};

export default MerchantAppDepositService;
