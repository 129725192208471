export const getAdditionalUrlParams = paymentPath => {
  if (!paymentPath) return '';

  const params = {};
  const replacer = (match, key, value) => { 
    params[key] = value; 
    return '';
  };
  const cleanedPaymentPath = paymentPath.replace(/[?&]+([^=&]+)=([^&]*)/gi, replacer);
  const reducer = (acc, cur) => `${acc}&${cur}=${params[cur]}`;

  return Object.keys(params).reduce(reducer, `${cleanedPaymentPath}?qr_used=true`);
};
