/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

export default class GraphPaymentSource extends React.Component {

  componentDidMount() {
    const { numberFormat, positionPaymentSourceStats } = AffiniPay.DashboardUtils;
    const sourceGraphData = this.props.data;
    sourceGraphData.sort(function(a, b) {
      return b.y - a.y;
    });

    const paymentSourceColors = [
      '#32B170',
      '#FC8847',
      '#FFB902',
      '#0A6039',
      '#000000',
      '#2666F0',
      '#EEDECA',
      '#A57436',
      '#7C13A4',
      '#B9E5FB'
    ];

    const sourceGraphOptions = {
      chart: {
        renderTo: 'payment_source_chart',
        type: 'pie',
        backgroundColor:'rgba(255, 255, 255, 0)',
        events: {
          load: function() {
            positionPaymentSourceStats();
          }
        }
      },
      credits: false,
      title: {
        text: 'Payment Source'
      },
      tooltip: {
        shared: true,
        backgroundColor: 'rgba(255,255,255,1)',
        opacity: 1,
        useHTML: true,
        pointFormatter: function() {
          if (this.name) {
            return `<b>${ numberFormat(this.y, 0) }</b> Transactions<br/><b>${ numberFormat(this.segmentTotal / 100, 2) }</b>`;
          }
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          center: ['50%', '50%'],
          colors: paymentSourceColors,
          showInLegend: true,
          point: {
            events: {
              legendItemClick: function () {
                return false; //  Prevent the default behavior
              }
            }
          }
        },
        allowPointSelect: false
      },
      legend: {
        enabled: true,
        useHTML: true,
        labelFormatter: function() {
          return `${ this.percentage.toFixed(2) }% ${ this.name } `;
        },
        itemStyle: {
          color: '#777777',
          fontWeight: 'normal'
        }
      },
      series: [
        {
          type: 'pie',
          name: 'Payment Source',
          innerSize: '50%',
          data: sourceGraphData,
          marker: {
            enabled: true
          },
          dataLabels: {
            enabled: false,
            formatter: function() {
              if (this.percentage.length) {
                return `${ this.percentage.toFixed(2) }%`;
              }
            }
          }
        }
      ]
    };

    this.sourceGraph = $('.dashboard-chart-source').highcharts(sourceGraphOptions);
  }


  UNSAFE_componentWillReceiveProps(props) {
    let chart = this.sourceGraph.highcharts();
    chart.series[0].remove();
    chart.addSeries({
      name: '',
      innerSize: '50%',
      data: props.data.sort(function(a, b) {
        return b.y - a.y;
      }),
      dataLabels: {
        enabled: false,
        formatter: function() {
          if (this.percentage.length) {
            return `${ this.percentage.toFixed(2) }%`;
          }
        }
      },
      tooltip: {
        useHTML: true,
        backgroundColor: 'rgba(255,255,255,1)',
        pointFormatter: function() {
          if (this.name) {
            return `<b>${ AffiniPay.DashboardUtils.numberFormat(this.y, 0) }</b> Transactions<br /><b>$${AffiniPay.DashboardUtils.numberFormat(this.segmentTotal / 100, 2) }</b>`;
          }
        }
      }
    }, true);
  }

  render() {
    // eslint-disable-next-line react/no-string-refs
    return (<div ref="chart_source" className="dashboard-chart-source"></div>);
  }
}
GraphPaymentSource.propTypes = {
  activeDateRange: PropTypes.string,
  data: PropTypes.array
};
