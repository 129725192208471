export const getSelectedCustomFieldsWithoutSections = (fields) => {
  const filteredFields = Object.fromEntries(Object.entries(fields).filter(([_, value]) => {
    const valPresent = Array.isArray(value) ? !!value[0] : !!value;
    return valPresent && value !== 'section';
  }));
  return filteredFields;
};

export const generateRecurringChargeData = (frequency, maxAmount, secondDay) => {
  if (frequency === 'NONE') return {};
  const today = new Date();
  const recurringChargeData = {
    interval_unit: 'MONTH',
    interval_delay: 1,
    start: today.toISOString().split('T')[0], // YYYY-MM-DD format required by gateway API
    ...(maxAmount > 0 && { max_amount: maxAmount })
  };
  switch(frequency) {
  case 'WEEKLY':
    recurringChargeData.interval_unit = 'WEEK';
    break;
  case 'BI_WEEKLY':
    recurringChargeData.interval_unit = 'WEEK';
    recurringChargeData.interval_delay =  2;
    break;
  case 'MONTHLY': {
    // make sure we dont set `days` to a date that is possibly out of bounds depending on month
    if (today.getDate() > 28) {
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
      recurringChargeData.days = ['LAST'];
      recurringChargeData.next_occurence_after = lastDayOfMonth.toISOString().split('T')[0];
    } else {
      recurringChargeData.days = [today.getDate().toString()];
    }
    break;
  }
  case 'BI_MONTHLY': {
    if (today.getDate() > 28) {
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
      recurringChargeData.days = ['LAST', secondDay.toString()];
      recurringChargeData.next_occurence_after = lastDayOfMonth.toISOString().split('T')[0];
    } else {
      recurringChargeData.days = [today.getDate().toString(), secondDay.toString()];
    }
    break;
  }
  case 'TWO_MONTHS':
    recurringChargeData.interval_delay = 2;
    break;
  case 'THREE_MONTHS':
    recurringChargeData.interval_delay = 3;
    break;
  case 'SIX_MONTHS':
    recurringChargeData.interval_delay = 6;
    break;
  case 'YEAR':
    recurringChargeData.interval_delay = 12;
    break;
  }
  return recurringChargeData;
};