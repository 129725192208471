import React from 'react';
import {number, string} from 'prop-types';

const IconChargeReader = ({
  className='ap-icon',
  fill='#171a1c',
  size=20,
  title='IconChargeReader'
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox={`0 0 ${size} ${size}`}
  >
    {title && <title>{title}</title>}
    <path d="M 19.06,11.38
           C 15.08,11.38 11.88,14.62 11.88,18.56
             11.88,19.11 11.45,19.50 10.94,19.50
             10.39,19.50 10.00,19.11 10.00,18.56
             10.00,13.60 14.06,9.50 19.06,9.50
             19.57,9.50 20.00,9.93 20.00,10.44
             20.00,10.98 19.57,11.38 19.06,11.38 Z
           M 17.85,17.39
           C 18.32,16.88 19.14,16.88 19.61,17.39
             20.12,17.86 20.12,18.68 19.61,19.15
             19.14,19.66 18.32,19.66 17.85,19.15
             17.34,18.68 17.34,17.86 17.85,17.39 Z
           M 19.06,13.25
           C 19.57,13.25 20.00,13.68 20.00,14.19
             20.00,14.73 19.57,15.12 19.06,15.12
             17.15,15.12 15.62,16.69 15.62,18.56
             15.62,19.11 15.20,19.50 14.69,19.50
             14.14,19.50 13.75,19.11 13.75,18.56
             13.75,15.63 16.13,13.25 19.06,13.25 Z
           M 1.88,15.75
           C 1.88,16.10 2.15,16.38 2.50,16.38
             2.50,16.38 8.98,16.38 8.98,16.38
             8.83,17.00 8.75,17.62 8.75,18.25
             8.75,18.25 2.50,18.25 2.50,18.25
             1.09,18.25 0.00,17.16 0.00,15.75
             0.00,15.75 0.00,3.25 0.00,3.25
             0.00,1.88 1.09,0.75 2.50,0.75
             2.50,0.75 15.00,0.75 15.00,0.75
             16.37,0.75 17.50,1.88 17.50,3.25
             17.50,3.25 17.50,8.41 17.50,8.41
             16.84,8.48 16.21,8.64 15.62,8.88
             15.62,8.88 15.62,3.25 15.62,3.25
             15.62,2.94 15.31,2.62 15.00,2.62
             15.00,2.62 2.50,2.62 2.50,2.62
             2.15,2.62 1.88,2.94 1.88,3.25
             1.88,3.25 1.88,15.75 1.88,15.75 Z
           M 5.00,13.25
           C 5.00,13.25 10.23,13.25 10.23,13.25
             9.88,13.88 9.57,14.50 9.34,15.12
             9.34,15.12 4.57,15.12 4.57,15.12
             3.75,15.12 3.12,14.50 3.12,13.68
             3.12,13.68 3.12,5.36 3.12,5.36
             3.12,4.54 3.75,3.87 4.57,3.87
             4.57,3.87 5.31,3.87 5.31,3.87
             5.82,3.87 6.25,4.30 6.25,4.81
             6.25,5.36 5.82,5.75 5.31,5.75
             5.31,5.75 5.00,5.75 5.00,5.75
             5.00,5.75 5.00,13.25 5.00,13.25 Z
           M 14.38,9.42
           C 13.67,9.73 13.05,10.16 12.50,10.63
             12.50,10.63 12.50,5.75 12.50,5.75
             12.50,5.75 9.69,5.75 9.69,5.75
             9.69,5.75 9.69,7.90 9.69,7.90
             10.23,8.21 10.62,8.84 10.62,9.50
             10.62,10.55 9.77,11.38 8.75,11.38
             7.70,11.38 6.88,10.55 6.88,9.50
             6.88,8.84 7.23,8.21 7.81,7.90
             7.81,7.90 7.81,4.81 7.81,4.81
             7.81,4.30 8.20,3.87 8.75,3.87
             8.75,3.87 12.89,3.87 12.89,3.87
             13.71,3.87 14.38,4.54 14.38,5.36
             14.38,5.36 14.38,9.42 14.38,9.42 Z"
    fill={fill}
    />
  </svg>
);

IconChargeReader.propTypes = {
  className: string,
  fill: string,
  size: number,
  title: string
};

export default IconChargeReader;
