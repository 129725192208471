import React from 'react';
import { shallow } from 'enzyme';
import MerchantApp from './merchant-app';

describe('MerchantApp Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(
      <MerchantApp theme={'lawpay'} continueUrl={'http://foo'} />
    );
    expect(component).toMatchSnapshot();
  });
});
