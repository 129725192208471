import React from 'react';
import { shallow } from 'enzyme';
import Disclaimer from './disclaimer';

describe('Disclaimer Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(<Disclaimer />);
    expect(component).toMatchSnapshot();
  });
});
