import React from 'react';
import PropTypes from 'prop-types';
import LegacyAPDatepicker from '../../components/LegacyAPDatepicker';
import { APInputText } from 'affinipay-ui-library';
import Config from '../../lib/datepickerConfig';

class LegacyAPDatepickerRails extends React.Component {
  static propTypes = {
    case: PropTypes.string
  };

  state = {
    selectedDays: new Date(Date.now())
  };

  constructor(props) {
    super(props);
  }

  handleChange = ({
    selectedDays,
    ...state
  }) => {
    let newState = { ...state };

    if (selectedDays) {
      newState.selectedDays = selectedDays;
    }

    this.setState(newState);
  };

  render() {
    const id = Config.getData(this.props.case).id,
      disabledDays = Config.getData(this.props.case).disabledDays,
      toMonth = Config.getData(this.props.case).toMonth,
      selectedDays = this.state.selectedDays,

      dataObject = (option) => Config.getData(this.props.case, option).dataObject;

    return (
      <div>
        <LegacyAPDatepicker
          disabledDays={disabledDays}
          toMonth={toMonth}
          month={new Date(Date.now())}
          selectedDays={selectedDays ? selectedDays : new Date(Date.now())}
          onDayCallback={(day) =>
            this.handleChange({
              ...dataObject(day)
            })
          }
        />
        <APInputText
          className="addon-right"
          type="hidden"
          name="date"
          id={id}
          value={selectedDays}         
        />
      </div>
    );
  }
}

export default LegacyAPDatepickerRails;
