import React, {useState} from 'react';
import {bool, func, object, string} from 'prop-types';
import {APModal, APToast} from 'affinipay-ui-library';
import DeleteQuickBillButton from './QuickBillActions/DeleteQuickBillButton';
import BankAccountDropdown from './QuickBillActions/BankAccountDropdown';
import MarkQuickBillAsPaidButton from './QuickBillActions/MarkQuickBillAsPaidButton';
import SendQuickBillButton from './QuickBillActions/SendQuickBillButton';
import EditAndSendQuickBillButton from './QuickBillActions/EditAndSendQuickBillButton';
import QuickBillEditForm from './QuickBillEditForm';
import {bankAccountsProp, invoiceProp} from '../../../customProps';
import {isEmailPattern} from '../../../lib/utils';
import client from '../../../lib/ajax';

const initialToastValues = {
  show: false,
  header: undefined,
  message: undefined,
  type: 'error'
};

const verifyInvoiceSending = invoice => {
  const {invoiceMessages = []} = invoice;
  const lastInvoiceMessage = invoiceMessages[invoiceMessages.length - 1];
  if (!lastInvoiceMessage) return false;
  const {emailAddresses = [], subject} = lastInvoiceMessage;
  if (!subject || emailAddresses.length === 0 || emailAddresses.some(address => !isEmailPattern(address))) return false;

  return true;
};

const QuickBillActionsForPending = ({
  attachmentDisabled,
  bankAccounts = [],
  defaultBody,
  defaultSubject,
  invoice,
  permissions,
  selectedBankAccount,
  setSelectedBankAccount,
  siteSupportPhone
}) => {
  const {id: invoiceId, invoiceMessages} = invoice;
  const [isDisabled, setIsDisabled] = useState();
  const [showModal, setShowModal] = useState(false);
  const lastInvoiceMessage = invoiceMessages[invoiceMessages.length - 1];
  const [toastValues, setToastValues] = useState(initialToastValues);

  const isSendingAllowed = verifyInvoiceSending(invoice);

  const changeDepositAccount = async payload => {
    setIsDisabled(true);
    const response = await client.patch(`/quick_bills/${payload.id}`, payload);
    const {status} = response;

    const successAction = () => {
      setToastValues({
        show: true,
        header: 'Success',
        message: 'Deposit Account has been changed',
        type: 'success'
      });
      setSelectedBankAccount(payload.invoice.bank_account_id);
    };

    const notAuthorizedAction = () => {
      setToastValues({
        show: true,
        header: 'Not Authorized',
        message: 'You do not have permission to do this operation',
        type: 'error'
      });
    };

    const errorAction = () => {
      setToastValues({
        show: true,
        header: 'Error',
        message: 'Unable to change Deposit Account',
        type: 'error'
      });
    };

    const actionMap = new Map([
      [200, successAction],
      [401, notAuthorizedAction],
      [403, notAuthorizedAction],
      [422, errorAction]
    ]);

    const action = actionMap.get(status);

    if (typeof action === 'function') {
      action();
    } else {
      errorAction();
    }
    setIsDisabled(false);
  };

  const handleSendQuickBill = async () => {
    if (isDisabled) return;
    setIsDisabled(true);

    //TODO: multiple emails creates issues here. Should find a way to have multiple recipients that match the API format.
    const { subject, message, emailAddresses } = lastInvoiceMessage;
    const [email] = emailAddresses;
    const invoiceMessageParams = { email, subject, message };

    const response = await client.post(`/quick_bills/${invoiceId}/resend`, { invoice_message: invoiceMessageParams });
    const {status} = response;

    if (status === 200) {
      setToastValues({
        show: true,
        header: 'Success',
        message: `The Quick Bill has been resent to ${invoiceMessageParams.email}`,
        type: 'success'
      });
    } else {
      setToastValues({
        show: true,
        header: 'Error',
        message: 'Quick Bill could not be resent',
        type: 'error'
      });
    }
    setIsDisabled(false);
  };

  const handleAccountChange = ({target: {value}}) => {
    changeDepositAccount({id: invoiceId, invoice: {bank_account_id: value}});
  };

  const handleEditQuickBill = () => {
    setIsDisabled(true);
    setShowModal(true);
  };

  const closeEditQuickBillModal = () => {
    setIsDisabled(false);
    setShowModal(false);
  };

  const handleToastClose = () => {
    setToastValues({
      ...toastValues,
      show: false
    });
  };

  return (
    <div className="quick-bill-actions">
      <BankAccountDropdown
        isDisabled={isDisabled}
        onAccountChange={handleAccountChange}
        selectedBankAccount={selectedBankAccount}
        bankAccounts={bankAccounts}
      />
      <div className="quick-bill-status-actions">
        {permissions.collectPayments &&
          <>
            <SendQuickBillButton
              invoiceId={invoiceId}
              handleSendQuickBill={handleSendQuickBill}
              isDisabled={isDisabled}
              isSendingAllowed={isSendingAllowed}
            />
            <EditAndSendQuickBillButton
              isDisabled={isDisabled}
              handleEditQuickBill={handleEditQuickBill}
              isSendingAllowed={isSendingAllowed}
            />
          </>
        }
        <MarkQuickBillAsPaidButton
          title="Mark As Paid"
          buttonText="Mark As Paid"
          buttonType="secondary"
          invoiceID={invoiceId}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          setToastValues={setToastValues}
        />
        {permissions.deleteInvoice && (
          <DeleteQuickBillButton
            invoiceID={invoiceId}
            isDisabled={isDisabled}
          />
        )}
      </div>
      <APToast
        type={toastValues.type}
        isOpen={toastValues.show}
        header={toastValues.header}
        fadeOutTime={5000}
        onClose={handleToastClose}
      >
        {toastValues.message}
      </APToast>
      <APModal isActiveInitial={showModal}
        onClose={closeEditQuickBillModal}
        className='quick-bill-modal'
        headerText='Edit Quick Bill'
      >
        <QuickBillEditForm
          attachmentDisabled={attachmentDisabled}
          bankAccount={bankAccounts.find(account => account.xid === selectedBankAccount)}
          defaultBody={defaultBody}
          defaultSubject={defaultSubject}
          invoice={invoice}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          setShowModal={setShowModal}
          siteSupportPhone={siteSupportPhone}
        />
      </APModal>
    </div>
  );
};

QuickBillActionsForPending.propTypes = {
  attachmentDisabled: bool,
  bankAccounts: bankAccountsProp,
  defaultBody: string,
  defaultSubject: string,
  invoice: invoiceProp,
  permissions: object,
  selectedBankAccount: string,
  setSelectedBankAccount: func.isRequired,
  siteSupportPhone: string
};

export default QuickBillActionsForPending;
