import client from '../../../lib/ajax';

export const createUser = async (path, user) => {
  return  await client.post(
    path,
    user
  );
};

export const updateUser = async (path, user) => {
  return await client.patch(
    path,
    user
  );
};

export const reinviteUser = async (path) => {
  return await client.put(
    path
  );
};