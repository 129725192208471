const SelectCommonStyle = {
  control: (base, state) => ({
    ...base,
    height: '34px',
    minHeight: '34px',
    border: state.isFocused ? '1px solid #66afe9' : '1px solid #ccc',
    boxShadow: state.isFocused
      ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)'
      : 'inset 0 1px 1px rgba(0, 0, 0, 0.075)'
  })
};

export default SelectCommonStyle;
