// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Navigation Component shallow snapshot render 1`] = `
<nav
  className="row merchant "
  style={
    {
      "top": "0px",
    }
  }
>
  <ul>
    <NavigationItem
      isActive={true}
      key="0"
      onClick={[Function]}
      text="Dashboard"
      url="/"
    />
  </ul>
</nav>
`;
