import React from 'react';
import { shallow, mount } from 'enzyme';
import Country from './country';

describe('Country Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(
      <Country label={'foo'} selected={'bar'} name={'foobar'} />
    );
    expect(component).toMatchSnapshot();
  });
  it('mount for events', () => {
    const component = mount(
      <Country label={'foo'} selected={'bar'} name={'foobar'} />
    );
    component
      .find('[name="foobar"]')
      .at(1)
      .prop('onChange')({ value: 'US' });
    expect(component.state('selectedOption')).toEqual({
      label: 'United States',
      value: 'US'
    });
    component.unmount();
  });
});
