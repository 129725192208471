import React, {useState} from 'react';
import {func, string} from 'prop-types';
import {APComboBoxEmail} from 'affinipay-ui-library';
import {useFetchContacts} from './customHooks';

export const wrapContactListItems = (highlightedIndex, getItemProps) => {
  const mapContactListItems = (contact, index) => {
    const {email, id, name} = contact;

    return (
      <li key={id}
        data-testid='contact-search-list-item'
        className={`ap-combobox-list-item${highlightedIndex === index
          ? ' ap-combobox-list-item-highlight'
          : ''} contact-search-list-item`
        }
        {...getItemProps({contact, index})}
      >
        {`${name} ${email}`}
      </li>
    );
  };

  return mapContactListItems;
};

export const contactToString = contact => contact?.email || '';

const characterLimits = {warn: 243,error: 254};

const ContactSearchField = ({error, onChange, onSelect}) => {
  const [searchValue, setSearchValue] = useState('');
  const {data, loading} = useFetchContacts(searchValue, searchValue.length >= 2);

  const handleChange = (event, lengthValidation) => {
    const {target: {name, value}} = event;
    setSearchValue(value.trim());
    onChange({target: {name, value}}, lengthValidation);
  };

  return (
    <APComboBoxEmail
      characterLimits={characterLimits}
      error={error}
      itemToString={contactToString}
      label="Client Email"
      listItems={data}
      loading={loading}
      name="emailAddress"
      placeholder="Client Email"
      mapObjectListItems={wrapContactListItems}
      required={true}
      value={searchValue}
      onChange={handleChange}
      onSelectedItemChange={onSelect}
    />
  );
};

ContactSearchField.propTypes = {
  error: string,
  placeholder: string,
  onChange: func.isRequired,
  onSelect: func.isRequired
};
export default ContactSearchField;
