// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Country Component shallow snapshot render 1`] = `
<div>
  <label>
    foo
  </label>
  <withProps(withProps(StateManager))
    name="foobar"
    onChange={[Function]}
  />
</div>
`;
