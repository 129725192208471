import {useCallback, useEffect, useState} from 'react';
import {rollbarLog} from '../../../lib/utils';
import client from '../../../lib/ajax';
import {debounce} from '../../../lib/utils';

export const useFetchContacts = (searchValue = '', shouldSearch = true) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const fetchContacts = useCallback(
    debounce(async (searchValue) => {
      setError(undefined);
      setLoading(true);
      const url = `/quick_bills/contact_search?term=${encodeURIComponent(searchValue)}`;
      const response = await client.get(url);
      const {status} = response;

      if (status === 200) {
        const responseJSON = await response.json();
        if (responseJSON.length > 0) {
          let contacts = responseJSON.map((contact, i) => ({ ...contact, key: i }));
          setData(contacts);
        } else {
          setData([]);
        }
      } else {
        setData([]);
        setError('Something went wrong, please try again.');
        rollbarLog('Quickbill', `Failed to load Component: ${response.error}`);
      }
      setLoading(false);
    }, 250), []);

  useEffect(() => {
    if (shouldSearch) {
      fetchContacts(searchValue);
    }
  }, [searchValue, shouldSearch]);

  return {data, error, loading};
};
