import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import FeeCollectionModal from '.';
import client from 'lib/ajax';
import notify from '../../../lib/notify';

const accounts = [
  {value: 'account_id_1', text: 'Wells Fargo ACH'},
  {value: 'account_id_2', text: 'Adyen ACH'}
];

const merchant = { id: 'merchant_id_1' };

describe('FeeCollectionModal', () => {
  it('Renders collect button and modal form', () => {
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));
    expect(screen.getByText('Fee Collection')).toBeInTheDocument();
    const accountInput = screen.getAllByLabelText('Account')[0];
    const amountInput = screen.getByLabelText('Amount');
    const emailInput = screen.getByText('Receipt Email');
    const referenceInput = screen.getByText('Reference');
    expect(accountInput).toBeInTheDocument();
    expect(accountInput.nextSibling.nextSibling).toBeRequired();
    expect(amountInput).toBeInTheDocument();
    expect(amountInput).toBeRequired();
    expect(emailInput).toBeInTheDocument();
    expect(emailInput).not.toBeRequired();
    expect(referenceInput).toBeInTheDocument();
    expect(referenceInput).not.toBeRequired();
    expect(screen.getByText('Cancel')).toBeInTheDocument();
    expect(screen.getByText('Submit')).toBeInTheDocument();
  });

  it('User can interact with form elements', () => {
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));

    const accountInput = screen.getAllByLabelText('Account')[0];
    expect(accountInput).toHaveTextContent('Select One');
    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));
    expect(accountInput).toHaveTextContent(accounts[0].text);

    const amountInput = screen.getByLabelText('Amount');
    expect(amountInput).toHaveDisplayValue('');
    userEvent.type(amountInput, '10');
    userEvent.tab();
    expect(amountInput).toHaveDisplayValue('10.00');

    const emailInput = screen.getByLabelText('Receipt Email');
    expect(emailInput).toHaveDisplayValue('');
    userEvent.type(emailInput, 'tester@gmail.com');
    expect(emailInput).toHaveDisplayValue('tester@gmail.com');
  
    const referenceInput = screen.getByLabelText('Reference');
    expect(referenceInput).toHaveDisplayValue('');
    userEvent.type(referenceInput, 'test reference');
    expect(referenceInput).toHaveDisplayValue('test reference');
  });

  it('Shows form required and validity errors', () => {
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));
    const accountInput = screen.getAllByLabelText('Account')[0];
    const amountInput = screen.getByLabelText('Amount');
    const emailInput = screen.getByLabelText('Receipt Email');
    const referenceInput = screen.getByLabelText('Reference');
    const submitButton = screen.getByText('Submit');
    userEvent.click(accountInput);
    userEvent.click(amountInput);
    userEvent.click(referenceInput);

    expect(screen.getByText('Amount is required')).toBeInTheDocument();
    expect(screen.getByText('Account is required')).toBeInTheDocument();
    expect(submitButton).toBeDisabled();

    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));
    expect(submitButton).toBeDisabled();
    expect(screen.queryByText('Account is required')).not.toBeInTheDocument();

    userEvent.type(amountInput, '10');
    expect(submitButton).toBeEnabled();
    expect(screen.queryByText('Amount is required')).not.toBeInTheDocument();

    userEvent.type(emailInput, 'test');
    userEvent.click(referenceInput);
    expect(screen.getByText('Receipt Email is invalid')).toBeInTheDocument();
    expect(submitButton).toBeDisabled();
    userEvent.type(emailInput, '@tester.com');
    userEvent.click(referenceInput);
    expect(screen.queryByText('Receipt Email is invalid')).not.toBeInTheDocument();
    expect(submitButton).toBeEnabled();
    userEvent.clear(emailInput);
    expect(submitButton).toBeEnabled();
  });

  it('Resets the form when the modal is closed', () => {
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));

    const accountInput = screen.getAllByLabelText('Account')[0];
    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));

    const amountInput = screen.getByLabelText('Amount');
    userEvent.type(amountInput, '10');

    const emailInput = screen.getByLabelText('Receipt Email');
    userEvent.type(emailInput, 'tester@gmail.com');
  
    const referenceInput = screen.getByLabelText('Reference');
    userEvent.type(referenceInput, 'test reference');
  
    const submitButton = screen.getByText('Submit');
    expect(submitButton).toBeEnabled();
  
    userEvent.click(screen.getByText('Cancel'));
    userEvent.click(screen.getByText('Collect'));

    expect(accountInput).toHaveTextContent('Select One');
    expect(screen.getByLabelText('Amount')).toHaveDisplayValue(''); // for some reason APInputMask components have to be re-queried to get real current value
    expect(emailInput).toHaveDisplayValue('');
    expect(referenceInput).toHaveDisplayValue('');
    expect(submitButton).toBeDisabled();
  });

  it('Can submit charge successfully', async () => {
    jest.spyOn(notify, 'success').mockImplementation(() => {});
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));

    const accountInput = screen.getAllByLabelText('Account')[0];
    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));
    const amountInput = screen.getByLabelText('Amount');
    userEvent.type(amountInput, '10');
    const emailInput = screen.getByLabelText('Receipt Email');
    userEvent.type(emailInput, 'tester@gmail.com');
    const referenceInput = screen.getByLabelText('Reference');
    userEvent.type(referenceInput, 'test reference');
    const submitButton = screen.getByText('Submit');
    const cancelButton = screen.getByText('Cancel');
    userEvent.click(submitButton);
    expect(submitButton).toBeDisabled();
    expect(cancelButton).toBeDisabled();

    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(notify.success).toHaveBeenCalledTimes(1));
    expect(client.post).toHaveBeenLastCalledWith(`/admin/merchants/${merchant.id}/manual_fee_collections`, {
      account_id: accounts[0].value,
      amount: 1000,
      email: 'tester@gmail.com',
      reference: 'test reference'
    });
    expect(notify.success).toHaveBeenLastCalledWith('Charge successfully submitted. Charges made through manual fee collection can take up to 5 minutes to appear in the Transactions page.');
    expect(screen.queryByText('Fee Collection')).not.toBeInTheDocument();
  });

  it('Can submit charges greater than $999', async () => {
    jest.spyOn(notify, 'success').mockImplementation(() => {});
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));

    const accountInput = screen.getAllByLabelText('Account')[0];
    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));
    const amountInput = screen.getByLabelText('Amount');
    userEvent.type(amountInput, '1234');
    const emailInput = screen.getByLabelText('Receipt Email');
    userEvent.type(emailInput, 'tester@gmail.com');
    const referenceInput = screen.getByLabelText('Reference');
    userEvent.type(referenceInput, 'test reference');
    const submitButton = screen.getByText('Submit');
    const cancelButton = screen.getByText('Cancel');
    userEvent.click(submitButton);
    expect(submitButton).toBeDisabled();
    expect(cancelButton).toBeDisabled();

    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(notify.success).toHaveBeenCalledTimes(1));
    expect(client.post).toHaveBeenLastCalledWith(`/admin/merchants/${merchant.id}/manual_fee_collections`, {
      account_id: accounts[0].value,
      amount: 123400,
      email: 'tester@gmail.com',
      reference: 'test reference'
    });
    expect(notify.success).toHaveBeenLastCalledWith('Charge successfully submitted. Charges made through manual fee collection can take up to 5 minutes to appear in the Transactions page.');
    expect(screen.queryByText('Fee Collection')).not.toBeInTheDocument();
  });

  it('Displays error to user when charge submission fails', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'this is a test error'});
        })
      })
    );
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));

    const accountInput = screen.getAllByLabelText('Account')[0];
    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));
    const amountInput = screen.getByLabelText('Amount');
    userEvent.type(amountInput, '10');
    const emailInput = screen.getByLabelText('Receipt Email');
    userEvent.type(emailInput, 'tester@gmail.com');
    const referenceInput = screen.getByLabelText('Reference');
    userEvent.type(referenceInput, 'test reference');
    userEvent.click(screen.getByText('Submit'));

    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(client.post).toHaveBeenLastCalledWith(`/admin/merchants/${merchant.id}/manual_fee_collections`, {
      account_id: accounts[0].value,
      amount: 1000,
      email: 'tester@gmail.com',
      reference: 'test reference'
    });
    expect(screen.queryByText('Fee Collection')).toBeInTheDocument();
    expect(screen.getByText('this is a test error')).toBeInTheDocument();
  });

  it('Displays generic error for 500 errors', async () => {
    jest.spyOn(client, 'post').mockImplementation(() => {
      throw new Error();
    });
    render(<FeeCollectionModal achAccounts={accounts} merchant={merchant} />);
    userEvent.click(screen.getByText('Collect'));

    const accountInput = screen.getAllByLabelText('Account')[0];
    userEvent.click(accountInput);
    userEvent.click(screen.getByText(accounts[0].text));
    const amountInput = screen.getByLabelText('Amount');
    userEvent.type(amountInput, '10');
    const emailInput = screen.getByLabelText('Receipt Email');
    userEvent.type(emailInput, 'tester@gmail.com');
    const referenceInput = screen.getByLabelText('Reference');
    userEvent.type(referenceInput, 'test reference');
    userEvent.click(screen.getByText('Submit'));

    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(client.post).toHaveBeenLastCalledWith(`/admin/merchants/${merchant.id}/manual_fee_collections`, {
      account_id: accounts[0].value,
      amount: 1000,
      email: 'tester@gmail.com',
      reference: 'test reference'
    });
    expect(screen.queryByText('Fee Collection')).toBeInTheDocument();
    expect(screen.getByText('Error creating charge')).toBeInTheDocument();
  });
});