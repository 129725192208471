import React from 'react';
import {number, oneOfType, string} from 'prop-types';

const TrustIcon = ({className='account-trust-icon', size = 16, title = 'Trust Account Icon'}) => (
  <svg className={className} width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#4B8CCB" cx="8" cy="8" r="8"/>
      <path fill="#FFF" fillRule="nonzero" d="M9.037 12.923H6.963V5.321H4.308V3.692h7.384v1.63H9.037z"/>
    </g>
  </svg>
);

TrustIcon.propTypes = {
  className: string,
  size: oneOfType([number, string]),
  title: string
};

export default TrustIcon;
