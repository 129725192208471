import React from 'react';
import PropTypes from 'prop-types';

const IconCheck = ({
  size,
  className,
  fill
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={ `0 0 ${size} ${size}` }
      className={ className }
    >
      <path
        d="M29.1,1.5,13,26.1,6.8,19a3.9,3.9,0,0,0-5.5-.5A4,4,0,0,0,1,24.1l9,10.4h0l.7.7a3.9,3.9,0,0,0,5.4-.7l19-28.3a3.9,3.9,0,0,0-6.1-4.8"
        fill={ fill }
      />
    </svg>
  );
};

IconCheck.defaultProps = {
  fill: '#000',
  size: 36,
  className: 'ap-icon'
};

IconCheck.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
};

export default IconCheck;
