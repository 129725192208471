import React from 'react';
import { bool, func, instanceOf, string } from 'prop-types';
import DatePicker from 'react-datepicker';
import { APInputText } from 'affinipay-ui-library';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from './CalendarIcon';
import { verifyDate } from './helpers';

const defaultMaximumDate = new Date('12/31/9999');
const defaultMinimumDate = new Date('1/1/1000');

export const APTimelineCalendar = ({
  label,
  name,
  iconTitle,
  calendarEndDate,
  calendarStartDate,
  inputDate,
  isEndDate,
  parentClass,
  selectedCalendarDate,
  handleCalendarDateChange,
  handleInputDateChange
}) => {
  const getOpenDate = inputDate => {
    if (inputDate && verifyDate(inputDate)) return new Date(inputDate);
    return new Date();
  };

  return (
    <div className="aptimeline-custom-range-calendar">
      <div className="aptimeline-custom-range-calendar-input">
        <CalendarIcon title={iconTitle} />
        <APInputText
          className="aptimeline-custom-input"
          isFloatingLabel={true}
          label={label}
          name={name}
          parentClass={parentClass}
          value={inputDate}
          onChange={handleInputDateChange}
        />
      </div>
      <DatePicker
        inline={true}
        selected={selectedCalendarDate}
        selectsStart={isEndDate ? false : true}
        selectsEnd={isEndDate ? true : false}
        startDate={calendarStartDate}
        endDate={calendarEndDate}
        maxDate={defaultMaximumDate}
        minDate={isEndDate ? calendarStartDate : defaultMinimumDate}
        openToDate={getOpenDate(inputDate)}
        onChange={handleCalendarDateChange}
      />
    </div>
  );
};

APTimelineCalendar.propTypes = {
  label: string,
  name: string,
  iconTitle: string,
  calendarEndDate: instanceOf(Date),
  calendarStartDate: instanceOf(Date),
  inputDate: string,
  isEndDate: bool,
  parentClass: string,
  selectedCalendarDate: instanceOf(Date),
  handleCalendarDateChange: func.isRequired,
  handleInputDateChange: func.isRequired
};
