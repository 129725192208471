import React from 'react';
import './style.scss';
import { APCheckbox } from 'affinipay-ui-library';
import { PropTypes } from 'prop-types';

const RecurringChargeFilterToggle = ({
  toggleName,
  toggleValue,
  toggleText
}) => {

  return (
    <div className='form-group admin filter-toggle'>
      <div className='checkbox'>
        <APCheckbox
          id={toggleName}
          text={toggleText}
          value={toggleValue}
          name={toggleName}
          checked={false}
          disabled={false}
          displayType='toggle'
        />
      </div>
    </div>
  );
};

RecurringChargeFilterToggle.propTypes = {
  toggleName: PropTypes.string,
  toggleValue: PropTypes.string,
  toggleText: PropTypes.string
};

export default RecurringChargeFilterToggle;