import { withProps } from 'recompose';

import Select from 'components/Select/Standard';

export const jobTitles = [
  { label: 'President', value: 'President' },
  { label: 'Vice President', value: 'V.President' },
  { label: 'Owner', value: 'Owner' },
  { label: 'Partner', value: 'Partner' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Treasurer', value: 'Treasurer' },
  { label: 'Secretary', value: 'Secretary' },
  { label: 'Executive Director', value: 'Exec.Director' },
];

export default withProps({
  options: jobTitles,
})(Select);
