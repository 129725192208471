import React, { Component } from 'react';
import { fetch } from 'whatwg-fetch';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import Terms from './terms';
import ActivateText from './activateText';
import Success from './success';
import './style.scss';
import { sendEvent, EventNames } from '../../lib/intercom';

export default class Echeck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interested: false,
      success: props.status.alreadyOptedIn ? true : false,
      submitting: false,
      errors: []
    };
  }

  safeSendEvent(event) {
    sendEvent(EventNames[event]);
  }

  showInterest() {
    this.setState({
      interested: true
    });
  }

  close() {
    window.location.href = '/';
  }

  declineTerms() {
    this.safeSendEvent('rejectedEcheckAgreement');
    this.close();
  }

  async optin() {
    this.setState({ submitting: true });

    const response = await fetch(this.props.paths.optin, {
      method: 'POST',
      credentials: 'same-origin',
      redirect: 'error',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });

    const status = await response.status;
    const headers = await response.headers;
    const contentType = headers.get('content-type');

    if (
      status >= 200 &&
      status <= 299 &&
      contentType.indexOf('application/json') !== -1
    ) {
      this.setState({
        success: true,
        errors: [],
        submitting: false
      });

      this.safeSendEvent('acceptEcheckAgreement');

      return Promise.resolve(true);
    } else if (contentType.indexOf('application/json') == -1) {
      // Assume if response isn't json then we have hit an unauthorized response
      window.location.reload(true);
    } else {
      try {
        const text = await response.text();
        const json = JSON.parse(text);
        return this.handleFailure(json);
      } catch (err) {
        return this.handleFailure([{ message: 'connection issues' }]);
      }
    }
  }

  handleFailure(errorResponse) {
    this.setState({
      success: false,
      errors: errorResponse,
      submitting: false
    });

    return Promise.reject({ errors: errorResponse });
  }

  render() {
    return (
      <div
        data-jest-echecks-container
        className='outerContainer optin-echeck'
      >
        <div className="container-fluid messageBorder">
          <div className='flexyContainer row'>
            <div className="col-lg-5" style={{ display: 'flex' }}>
              <div className='stockImage'>
                <img src={this.props.assets.echeckImage} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className='wrapper'>
                {this.state.errors.map((error, index) => (
                  <div key={index} className="row">
                    <div className="col-sm-12">
                      <div className="alert alert-danger" role="alert">
                        <p>{error.message}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {this.state.submitting ? (
                  <Spinner />
                ) : this.state.success ? (
                  <Success
                    onClose={this.close.bind(this)}
                    successIcon={this.props.assets.successIcon}
                  />
                ) : this.state.interested ? (
                  <Terms
                    onAccept={this.optin.bind(this)}
                    onDecline={this.declineTerms.bind(this)}
                    hasErrors={this.state.errors.length > 0}
                    relocateIcon={this.props.assets.relocateIcon}
                    brandIdentifier={this.props.brand.identifier}
                  />
                ) : (
                  <ActivateText
                    onAccept={this.showInterest.bind(this)}
                    onDecline={this.close.bind(this)}
                    brandIdentifier={this.props.brand.identifier}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    assets: PropTypes.shape({
      echeckImage: PropTypes.string.isRequired,
      successIcon: PropTypes.string.isRequired,
      relocateIcon: PropTypes.string.isRequired
    }),
    brand: PropTypes.shape({
      identifier: PropTypes.string.isRequired
    }),
    paths: PropTypes.shape({
      optin: PropTypes.string.isRequired
    }),
    status: PropTypes.shape({
      alreadyOptedIn: PropTypes.bool.isRequired
    })
  };
}
