import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { APButton, APFormMessageBox, APInputText } from 'affinipay-ui-library';
import { createFeatureGate } from './helpers/services'; 
import { strings } from './helpers/constants';

const AddFeatureGateForm = ({ feature, fetchData, closeModal }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNameChange = (e) => setName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    try {
      const response = await createFeatureGate({ feature_key: feature.key, value: name });
      const json = await response.json();
      if (response.ok) {
        closeModal();
        fetchData();
      }
      else {
        setError(json.error);
        setIsSubmitting(false);
      }
    }
    catch (e) {
      setError(strings.createFeatureGateError);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {error &&                        
        <APFormMessageBox type="error">
          <p className='error-header'>
            {error}
          </p>
        </APFormMessageBox>
      }
      <form>
        <div className='row flex-bottom'>
          <div className='col-sm-6'>
            <APInputText 
              id='feature_gate_name'
              label={strings.actorNameLabel}
              parentClass="admin"
              onChange={handleNameChange}
              value={name}
            />
          </div>
          <div className='col-sm-6'>
            <APButton
              onClick={handleSubmit}
              disabled={!name || isSubmitting}
            >
              Create
            </APButton>
          </div>
        </div>
      </form>
      <div className='row'>
        <div className='col-sm-12'>
          <p>{strings.featureGateRecommendationText}</p>
        </div>
      </div>
    </>
  );
};

AddFeatureGateForm.propTypes = {
  feature: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default AddFeatureGateForm;