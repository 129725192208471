
import { rollbarLog } from '../genericHelpers/rollbar';
import server, { maybeParseJSON } from '../genericHelpers/server';
import { ERRORS } from './constants';

const _getGatewayError = messages => {
  if(!Array.isArray(messages)) return messages;
  return messages.map(m => `Code: ${m.code} Error: ${m.messages}`).join('; ');
};

// public

const getPaymentToken = async ({
  url, 
  firstName, 
  lastName, 
  email,
  pubKey,
  serverMock,
  setFormErrors
}) => {
  try {
    const res =  await server.post({ 
      url: url,
      body: {
        'type': 'loan',
        'given_name': firstName,
        'surname': lastName,
        'email': email
      }, 
      headers: {
        'Authorization': `Basic ${btoa(`${pubKey}:`)}`
      },
      mockEnabled: false,
      mockResponse: serverMock.paymentTokenResponse
    });
    const json = await maybeParseJSON(res);
    return json;
  } catch(err) {
    const errorJson = await maybeParseJSON(err);
    rollbarLog('LoanJS',`Failed to fetch a token. Status: ${err.status} Errors: ${_getGatewayError(errorJson?.messages)}`);
    setFormErrors([ERRORS.tokenFailure]);
    return false;
  }
};

const createAffinipayCharge = async ({
  tokenResponse,
  url,
  accountId,
  reference,
  amount,
  serverMock,
  setFormErrors,
  data
}) => {
  try {
    const res =  await server.post({
      url: url,
      body: {
        'method': tokenResponse?.id,
        'account_id': accountId,
        'reference': reference || '',
        'amount': amount,
        'currency': 'USD',
        'data': data 
      },
      mockEnabled: false,
      mockResponse: serverMock.chargeResponse
    });
    const json = await maybeParseJSON(res);
    return json;
  } catch(err) {
    const errorJson = await maybeParseJSON(err);
    rollbarLog('LoanJS',`Failed to create charge. Status: ${err.status} Errors: ${_getGatewayError(errorJson?.charge?.messages)}`);
    setFormErrors([ERRORS.chargeFailure]);
    return false;
  }
};

const buildCustomFieldsPayload = fieldsString => {
  let result = {'custom_fields': {}};
  try {
    result = {'custom_fields': JSON.parse(fieldsString)};
  }catch(e){
    rollbarLog('LoanJS', `Failed to parse JSON for custom fields: ${fieldsString}`);
    return result;
  }  
  return result;
};

const AFFINIPAY_COMMUNICATIONS = {
  getPaymentToken,
  createAffinipayCharge,
  buildCustomFieldsPayload 
};

export default AFFINIPAY_COMMUNICATIONS;