import React from 'react';
import PropTypes from 'prop-types';

class IconDelete extends React.Component {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${this.props.size} ${this.props.size}`}
        className={ this.props.className }
      >
        <path fill={ this.props.fill }
          d="M24.8,32.2H11.2L7.4,11H28.6ZM14,3.8h8.1l.5,3.6H13.5ZM34.2,7.4H26.1l-.5-3.9A3.6,3.6,0,0,0,22.1.2H14a3.6,3.6,0,0,0-3.6,3.3L9.8,7.4h-8a1.8,1.8,0,0,0,0,3.6h2c.5,3.8,3.9,21.4,3.9,21.5a3.6,3.6,0,0,0,3.6,3.3H24.8a3.6,3.6,0,0,0,3.6-3.3c0-.1,3.3-17.4,3.9-21.5h2a1.8,1.8,0,0,0,0-3.6Z" />
      </svg>
    );
  }
}

IconDelete.defaultProps = {
  fill: '#000',
  size: 36,
  className: 'ap-icon'
};

IconDelete.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
};

export default IconDelete;