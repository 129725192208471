import React from 'react';

export const ERRORS = {
  tokenFailure: 'There was an error when creating this charge. Please refresh the page and try again.',
  chargeFailure: 'There was an error when creating this charge. Please refresh the page and try again.',
  affirmLoadFailure: 'There was a problem loading this content. Try reloading this page.',
  firstNameValidation: 'First Name is required.',
  lastNameValidation: 'Last Name is required.',
  emailValidation: 'Email Address is required.',
  emailPattern: 'Email address is not valid. Please review and try again.',
  minLimit: amount => `The amount you entered is too low. Please enter an amount of ${amount} or higher.`,
  maxLimit: amount => `The amount you entered is too high. Please enter an amount of ${amount} or less.`,
  zeroAmount: 'Amount should be greater than 0.',
  affirmErrorHeader: 'Your Pay Later loan request did not go through.',
  affirmErrorMessage: function affirmErrorMessage(merchantName, merchantSupportPhone) {
    return <span>We recommend trying a lower amount or another payment method if possible. If not possible, or in case of question, please contact {merchantName}{merchantSupportPhone && (<span> at <a href={`tel:${merchantSupportPhone}`}>{merchantSupportPhone}</a></span>)}.</span>;
  }
};

export const INFO = {
  abandonmentMessage: 'Your session has ended due to inactivity. Please fill the form and try again.'
};
