import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import AccountHolderFields from './AccountHolderFields';
import APRadioButton from '../../components/APRadioButton';

export default class RecurringChargeBankAccount extends React.Component {
  static propTypes = {
    isComplete: PropTypes.bool,
    bankAccountType: PropTypes.string,
    accountHolderType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      accountTypeSelected: 'individual'
    };
  }

  setCheckedRadioOnEdit = (individual, business) => {
    if (this.props.bankAccountType === 'CHECKING' && this.props.accountHolderType === 'business') {
      business[0].checked = true;
    } else if (this.props.bankAccountType === 'SAVINGS' && this.props.accountHolderType === 'business') {
      business[1].checked = true;
    } else if (this.props.bankAccountType === 'CHECKING' && this.props.accountHolderType === 'individual') {
      individual[0].checked = true;
    } else if (this.props.bankAccountType === 'SAVINGS' && this.props.accountHolderType === 'individual') {
      individual[1].checked = true;
    } else {
      individual[0].checked = true;
    }
  };

  showAccountHolderFields = (e) => {
    this.setState({ accountTypeSelected: e.target.value.split('_')[0] });
  };

  render() {
    const individualButtonsData = [
        {
          id: 'account_individual_checking',
          value: 'individual_checking',
          text: 'Personal Checking',
          name: 'account'
        },
        {
          id: 'account_individual_savings',
          value: 'individual_savings',
          text: 'Personal Savings',
          name: 'account'
        }
      ],
      businessButtonsData = [
        {
          id: 'account_business_checking',
          value: 'business_checking',
          text: 'Business Checking',
          name: 'account'
        },
        {
          id: 'account_business_savings',
          value: 'business_savings',
          text: 'Business Savings',
          name: 'account'
        }
      ];
    this.setCheckedRadioOnEdit(individualButtonsData, businessButtonsData);
    const individualButtons = individualButtonsData.map((el, k) => {
        return (
          <div key={k} className="radio-buttons radio">
            <APRadioButton
              name="account"
              id={el.id}
              value={el.value}
              checked={el.checked}
              text={el.text}
              labelClassNames="radio-inline control-label"
              onChange={this.showAccountHolderFields}
            />
          </div>
        );
      }),
      businessButtons = businessButtonsData.map((el, k) => {
        return (
          <div key={k} className="radio-buttons radio">
            <APRadioButton
              name="account"
              id={el.id}
              value={el.value}
              checked={el.checked}
              text={el.text}
              labelClassNames="radio-inline control-label"
              onChange={this.showAccountHolderFields}
            />
          </div>
        );
      });

    return (
      <section>
        <div className="col-xs-12">
          <label className="control-label--uppercase">
            Enter Bank Account Information
          </label>
        </div>

        <div className="col-xs-12">
          <label htmlFor="account">Account Type</label>
        </div>
        <div className="col-xs-12 col-sm-6">{individualButtons}</div>
        <div className="col-xs-12 col-sm-6">{businessButtons}</div>

        <AccountHolderFields type={this.state.accountTypeSelected} />
        <div className="account_fields">
          <span>
            <APInputText
              className="aba_routing_field"
              dataset={{
                'data-chargeio': 'routing_number'
              }}
              id="routing_number"
              label="Routing Number"
              onChange={(e) => {
                this.setState({ routingNumber: e.target.value });
              }}
              parentClass="col-xs-12 col-sm-6"
              value={this.state.routingNumber}
            />
            <APInputText
              type="hidden"
              name="method[routing_number]"
              value={this.state.routingNumber}
            />
            <APInputText
              id="account_number"
              dataset={{
                'data-chargeio': 'account_number'
              }}
              label="Account Number"
              onChange={(e) => {
                this.setState({ accountNumber: e.target.value });
              }}
              parentClass="col-xs-12 col-sm-6"
              value={this.state.accountNumber}
            />
            <APInputText
              type="hidden"
              name="method[account_number]"
              value={this.state.accountNumber}
            />
          </span> 
        </div>
      </section>
    );
  }
}
