import React from 'react';
import { shallow, mount } from 'enzyme';
import Select from './select';

describe('Select Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(
      <Select
        label={'foo'}
        selected={'dog'}
        name={'bar'}
        optionsList={[{ value: 'dog' }, { value: 'cat' }]}
      />
    );
    expect(component).toMatchSnapshot();
  });
  it('mount for events', () => {
    const component = mount(
      <Select
        label={'foo'}
        selected={'dog'}
        name={'foobar'}
        optionsList={[{ value: 'dog' }, { value: 'cat' }]}
      />
    );
    component
      .find('[name="foobar"]')
      .at(1)
      .prop('onChange')({ value: 'dog' });
    expect(component.state('selectedOption')).toEqual({ value: 'dog' });
    component.unmount();
  });
});
