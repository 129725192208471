/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

const RecentTransactionRow = ({ transaction }) => {

  return (
    <tr id={`transaction_${transaction.id}`}
      className="transaction"
      onClick={() => AffiniPay.DashboardUtils.goToTransaction(transaction)} >
      <td className="numeric">{AffiniPay.DashboardUtils.formatTransaction(transaction)}</td>
      <td>{transaction.name}</td>
      <td className="amount numeric">${AffiniPay.DashboardUtils.numberFormat(transaction.amount / 100, 2)}</td>
    </tr>
  );
};

export default RecentTransactionRow;

RecentTransactionRow.propTypes = {
  transaction: PropTypes.object
};
