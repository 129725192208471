/* global PNotify */
// TODO: Change to pnotify package in package.json instead of wrapping global

const stack_bar_top = {
  dir1: 'down',
  dir2: 'right',
  push: 'bottom',
  firstpos1: 5,
  firstpos2: false,
  top: 0,
  spacing1: 0,
  spacing2: 0
};

function _notify(type, text, errors, hide) {
  new PNotify({
    addclass: 'stack-bar-top flash-message',
    sticker: false,
    width: false,
    stack: stack_bar_top,
    type: type,
    text: errors ? [text, ...errors].join('\n') : text,
    buttons: { sticker: false },
    hide: hide
  });
}

export default {
  success: (text = 'Success', errors, hide = true) => _notify('success', text, errors, hide),
  error: (text, errors, hide = true) => _notify('error', text, errors, hide),
  info: (text, errors, hide = true) => _notify('info', text, errors, hide)
};
