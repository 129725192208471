import React from 'react';
import { Countries, allCountries } from 'components/Select';
import PropTypes from 'prop-types';

class Country extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: allCountries.find(
        (option) => option.value === props.selected
      ),
    };
  }

  handleChange(value) {
    this.setState({
      selectedOption: allCountries.find((option) => option.value === value),
    });
  }
  render() {
    const { label, name } = this.props;

    return (
      <div>
        <label>{label}</label>
        <Countries
          name={name}
          value={this.state.selectedOption}
          onChange={(option) => {
            this.handleChange(option.value);
          }}
        />
      </div>
    );
  }
}

Country.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string,
  name: PropTypes.string,
};

export default Country;
