// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Resend Component shallow snapshot render 1`] = `
<OverlayTrigger
  defaultOverlayShown={false}
  onExited={[Function]}
  overlay={
    <Popover
      bsClass="popover"
      id="popover-basic"
      placement="top"
      title="Send Quick Bill"
    >
      <div>
        <p>
          Are you sure you want to resend this Quick Bill?
        </p>
        <div>
          <input
            className="form-control"
            onChange={[Function]}
            type="email"
          />
        </div>
        <button
          className="btn btn-success pull-right confirm-button"
          onClick={[Function]}
          type="button"
        >
          Confirm
        </button>
      </div>
    </Popover>
  }
  placement="top"
  rootClose={true}
  trigger="click"
>
  <button
    className="btn btn-primary pull-right"
    type="button"
  >
    Resend Bill
  </button>
</OverlayTrigger>
`;
