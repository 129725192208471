export const styles = {
  ['.qr-print-wrapper']: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    border: '1px dashed #e3e6e8',
    width: '6in',
    height: '4in'
  },
  ['.qr-card']: {
    width: '2.75in',
    marginRight: '.35in'
  },
  ['.qr-card-header']: {
    fontFamily: '"Open Sans", sans-serif',
    background: '#0069cc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '.7in',
    borderRadius: '8px 8px 0 0',
    color: '#fff',
    colorAdjust: 'exact'
  },
  ['.qr-card-header-cpacharge']: {
    background: '#73828c'
  },
  ['.qr-card-scan-icon']: {
    marginLeft: '8px'
  },
  ['.qr-card-header-details']: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 8px'
  },
  ['.qr-card-header-title']: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  ['.qr-card-merchant-title']: {
    fontSize: '10px',
    fontWeight: 200,
    lineHeight: '10px'
  },
  ['.qr-card-payment-title']: {
    fontSize: '10px',
    fontWeight: 200,
    lineHeight: '10px'
  },
  ['.qr-code-image']: {
    display: 'none'
  },
  ['.qr-code-svg']: {
    display: 'block'
  },
  ['.qr-card-body']: {
    display: 'flex',
    justifyContent: 'center',
    padding: '.25in 0',
    borderLeft: '1px solid #e3e6e8',
    borderRight: '1px solid #e3e6e8'
  },
  ['.qr-card-footer']: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '1rem',
    borderBottom: '1px solid #e3e6e8',
    borderRight: '1px solid #e3e6e8',
    borderLeft: '1px solid #e3e6e8',
    borderRadius: '0 0 8px 8px'
  },
  ['.powered-logo']: {
    height: '.25in'
  }
};