import React from 'react';
import client from '../../../lib/ajax';
import PropTypes from 'prop-types';
import { APButton } from 'affinipay-ui-library';

const TransactionWarning = ({merchant_id: merchantId}) => {
  const deleteTransactionWarnings = async () => {
    let eventSelect,
      dateFilterInput,
      currentEvent,
      dateFilter,
      warningCount,
      res;

    eventSelect = document.querySelector('#current_event');
    dateFilterInput = document.querySelector('#transaction_warnings_created_at');
    warningCount = document.querySelector('#transaction_warnings_count').value;

    let message = `Confirmation Required.\n\nAre you sure you want to delete ${ warningCount } transaction warnings?`;

    if (window.confirm(message)) {
      try {
        currentEvent = eventSelect.value;
        dateFilter = dateFilterInput.value;

        res = await client.post('/admin/transaction_warnings/destroy_all', {
          current_event: currentEvent,
          date_filter: dateFilter,
          merchant_id: merchantId
        });

        if (res.status === 204) {
          window.location.reload();
        } else {
          throw Error(res.statusText);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div>
      <APButton onClick={deleteTransactionWarnings} className='btn btn-danger btn-del-trans-warnings'>
        Clear Alerts
      </APButton>
    </div>
  );
};

TransactionWarning.propTypes = {
  merchant_id: PropTypes.number
};

export default TransactionWarning;

