import React, { useState } from 'react';
import {string} from 'prop-types';
import { APSelect, APModal, APButton, APToast } from 'affinipay-ui-library';
import './style.scss';

const ClientCreditDropdown = ({
  clientCreditStatus
}) => {
  const [selectedClientCreditStatus, setSelectedClientCreditStatus] = useState(clientCreditStatus != 'unknown' ? clientCreditStatus : null);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayReminder, setDisplayReminder] = useState(false);

  const handleChange = (e) => {
    if (e.target.value == 'opt_out') {
      handleMessageModal();
      closeReminder();
    } else {
      setSelectedClientCreditStatus('opt_in');
      setDisplayReminder(true);
    }
  };

  const handleMessageModal = () => {
    setDisplayModal(!displayModal);
  };

  const confirmAction = () => {
    setSelectedClientCreditStatus('opt_out');
    handleMessageModal();
  };

  const closeReminder = () => {
    setDisplayReminder(false);
  };

  return (
    <div>
      <div className="bottom-spacer-40">
        <h4>Pay Later</h4>
        <div className="form-group">
          <div className="col-sm-9">
            <label htmlFor="cc_intent_status" className='col-sm-3 control-label'>Opt-in Status</label>
            <div className="col-sm-6 cc-select-container">
              <APSelect
                name="merchant[cc_intent_status]"
                placeholderText = 'Please Select a value'
                nativeElementId="merchant_cc_intent_status"
                options={[{ text: 'Opt-in', value: 'opt_in' }, {text: 'Opt-out', value: 'opt_out'}]}
                onChange={handleChange}
                parentClass='admin cc-intent-status-select'
                value={selectedClientCreditStatus}
                needsNativeElement={true}
              />
              <APToast
                isOpen={displayReminder}
                type='warning'
                onClose={closeReminder}
                fadeOutTime={null}
              >
                <div>
                  <p className='message-header'>
                    Please remember to do the following after saving this change:
                  </p>
                  <p>
                    1. Create a loan processing account for this merchant (if it does not already exist)
                  </p>
                  <p>
                    2. Set up the loan account in the merchant’s payment page(s)
                  </p>
                </div>
              </APToast>
            </div>
          </div>
        </div>
      </div>

      <APModal
        isActiveInitial={displayModal}
        onClose={handleMessageModal}
        headerText='Change Pay Later status'
        className='client-credit-message-modal'
      >
        <div>
          <p>This action will deactivate the loan processing account from this merchant. Are you sure you want to continue?</p>
          <div className='row client-credit-actions-footer'>
            <div className='col-sm-3'>
              <APButton
                onClick={handleMessageModal}
                data-action="click"
                data-label="Cancel"
                type="button"
              >
                No, cancel
              </APButton>
            </div>
            <div className='col-sm-3'>
              <APButton
                onClick={confirmAction}
                data-action="click"
                data-label="Confirm"
                type="button"
              >
                Yes, deactivate
              </APButton>
            </div>
          </div>
        </div>
      </APModal>
    </div>
  );
};

ClientCreditDropdown.propTypes = {
  clientCreditStatus: string.isRequired
};

export default ClientCreditDropdown;