import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import {addClass, removeClass, addActiveClass, removeActiveClass} from '../../lib/utils';
import './style.scss';

class ReportsDatepicker extends React.Component {
  static propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired
  };

  state = {
    inputText: this.props.endDate,
    selectedDate: this.props.endDate,
    startYear: '',
    endYear: '',
    currentYear: '',
    selectedYearTitle: false,
    showArrowLeft: true,
    showArrowRight: false,
    showModal: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      startYear: this.getMerchantDateObj().startDate.year,
      endYear: this.getMerchantDateObj().endDate.year,
      currentYear: this.getMerchantDateObj().endDate.year
    });

    this.setDateOnLoad();
  }

  componentDidUpdate() {
    const yearContainer = document.getElementById('year-container');

    this.setActiveClass();

    if (!yearContainer) {
      return;
    }

    yearContainer.addEventListener('click', this.handleYearlyClick);
  }

  getMerchantDateObj = () => {
    const startDateArray = this.props.startDate.split('/'),
      endDateArray = this.props.endDate.split('/');

    return {
      startDate: {
        'month': parseInt(startDateArray[0]),
        'year': parseInt(startDateArray[1])
      },
      endDate: {
        'month': parseInt(endDateArray[0]),
        'year': parseInt(endDateArray[1])
      }
    };
  };

  pushStartDates = (dates, arr, obj) => {
    for (let i = 0; i < dates; i++) {
      arr.push(obj + i);
    }
  };

  pushEndDates = (dates, arr, obj) => {
    for (let i = 0; i < dates; i++) {
      arr.push(obj - i);
    }
  };

  pushBetweenDates = (totalYears, endYear, startYear, yearsArr) => {
    for (let k = 0; k < totalYears; k++) {
      const diff = endYear - totalYears + k;

      if (diff !== startYear) {
        yearsArr.push({[diff]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] });
      }
    }
  };

  getActiveDatesObj = () => {
    const dateObj = this.getMerchantDateObj(),
      endYear = dateObj.endDate.year,
      startYear = dateObj.startDate.year,
      totalYears = endYear - startYear,
      startMonth = dateObj.startDate.month,
      activeEndMonths = dateObj.endDate.month,
      activeStartMonths = 13 - startMonth,

      activeYears = [],
      startMonthArray = [],
      endMonthArray = [];

    this.pushStartDates(activeStartMonths, startMonthArray, startMonth);
    this.pushEndDates(activeEndMonths, endMonthArray, activeEndMonths);

    activeYears.push({[startYear]: startMonthArray});
    this.pushBetweenDates(totalYears, endYear, startYear, activeYears);
    activeYears.push({[endYear]: endMonthArray});

    return activeYears;
  };

  setActiveClass = () => {
    const yearContainer = document.getElementById('year-container');

    if (!yearContainer) {
      return;
    }

    const datesObj = this.getActiveDatesObj(),
      currentYear = yearContainer.textContent;

    if (!this.state.selectedYearTitle) {
      const yearObj = datesObj.find(o => Object.keys(o)[0] == currentYear),
        blocks = document.getElementsByClassName('blocks');

      let block = {},
        year = '';

      for (block of blocks) {
        removeActiveClass(block);
      }

      for (year in yearObj) {
        for(let i = 0; i < yearObj[year].length; i++) {
          const doubleDigits = ('0' + yearObj[year][i]).slice(-2),
            unitId = document.getElementById(`unit-${doubleDigits}`);

          if (!unitId) {
            return;
          }

          unitId && addActiveClass(unitId);
        }
      }
    }
  };

  setDateOnLoad = () => {
    const loadMonth = this.getMerchantDateObj().endDate.month,
      loadYear = this.getMerchantDateObj().endDate.year;

    this.getMonthNames().map((month, index) => {
      if (index === loadMonth - 1) {
        this.setState({ inputText: `${month}-${loadYear}` });
      }
    });
  };

  showAllYears = (yearTitleId, parentElement) => {
    if (parentElement === yearTitleId) {
      this.setState({ selectedYearTitle: true });
    }
  };

  showYearArrows = (parentElement, yearId, selectedYear) => {
    if (parentElement === yearId) {
      this.setState({
        selectedYearTitle: false,
        currentYear: selectedYear
      }, () => {
        const currentYear = parseInt(this.state.currentYear);

        if (currentYear === this.state.startYear) {
          this.setState({ showArrowLeft: false, showArrowRight: true });
        } else if (currentYear === this.state.endYear) {
          this.setState({ showArrowLeft: true, showArrowRight: false });
        } else {
          this.setState({ showArrowLeft: true, showArrowRight: true });
        }
      });
    }
  };

  setArrowDirection = (userAction) => {
    const startYear = this.getMerchantDateObj().startDate.year,
      endYear = this.getMerchantDateObj().endDate.year,
      currentYear = parseInt(this.state.currentYear),
      compareYears = userAction === 'arrow-left' ? currentYear > startYear : currentYear < endYear,
      setYear = userAction === 'arrow-left' ? currentYear - 1 : currentYear + 1;

    if (compareYears) {
      this.setState({
        currentYear: setYear,
        selectedYearTitle: false
      }, () => {
        if (this.state.currentYear === startYear) {
          this.setState({ showArrowLeft: false, showArrowRight: true });
        } else if (this.state.currentYear === endYear) {
          this.setState({ showArrowLeft: true, showArrowRight: false });
        } else {
          this.setState({ showArrowLeft: true, showArrowRight: true });
        }
      });
    }
  };

  hideArrows = () => { this.setState({ showArrowLeft: false, showArrowRight: false }); }

  handleArrowClick = (e) => {
    this.setArrowDirection(e.target.id);
    this.removeSelectedClass();
  };

  handleYearlyClick = (e) => {
    const selectedYear = e.target.getAttribute('data-value'),
      yearId = document.getElementById('month-container'),
      parentElement = e.target.parentNode;

    this.showAllYears();
    this.hideArrows();
    this.showYearArrows(parentElement, yearId, selectedYear);
  };

  removeSelectedClass = () => {
    const allMonths = document.getElementsByClassName('blocks');
    let month = {};

    for (month of allMonths) {
      removeClass(month, 'selected');
    }
  };

  handleMonthlyClick = (e) => {
    const monthValue = e.target.textContent,
      secondClass = e.target.classList[1];

    if (secondClass === 'active') {
      let month = monthValue,
        year = document.getElementById('year-container').textContent,
        value = `${month}-${year}`;

      this.removeSelectedClass();
      addClass(e.target, 'selected');

      this.setState({
        selectedDate: value,
        inputText: value,
        showModal: false
      });

      return value;
    }
  };

  handleInputClick = () => { this.setState({ showModal: !this.state.showModal }); }

  handleInputChange = (e) => {
    e.value = e.target.value;

    e.target.addEventListener('keypress', () => {
      this.setState({ showModal: false });
    });

    this.setState({ selectedDate: e.target.value, inputText: e.target.value });
  };

  getMonthNames = () => {
    return [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  };

  renderMonths = () => {
    return this.getMonthNames().map((month, index) => {
      const monthIndex = index + 1,
        num = ('0' + monthIndex).slice(-2);

      return (
        <div id={`unit-${num}`}
          className='blocks'
          data-value={num}
          key={`unit-${num}`}
          onClick={this.handleMonthlyClick}>
          {month}
        </div>
      );
    });
  };

  renderYears = () => {
    return this.getActiveDatesObj().map((year) => {
      let i = '';
      for (i in year) {
        return (
          <div id={`year-${i}`}
            className='blocks years'
            key={`year-${i}`}
            data-value={i}
            onClick={this.handleYearlyClick}>
            {i}
          </div>
        );
      }
    });
  };

  renderCurrentYear = () => {
    return (
      <div id={`year-${this.state.currentYear}`}
        className='years active'
        key={`year-${this.state.currentYear}`}
        data-value={this.state.currentYear}>
        {this.state.currentYear}
      </div>
    );
  };

  render() {
    const modal = this.state.showModal ? 'active' : '',
      arrowLeft = this.state.showArrowLeft ? '' : ' disableArrow',
      arrowRight = this.state.showArrowRight ? '' : ' disableArrow';

    let renderYear,
      renderDates;

    if (this.state.selectedYearTitle) {
      renderYear = ( <div className='years'>Select a Year</div> );
      renderDates = this.renderYears();
    } else {
      renderYear = this.renderCurrentYear();
      renderDates = this.renderMonths();
    }

    return (
      <div className="element-wrapper">
        <APInputText
          className="datepicker-input"
          id="datepicker-input"
          name="date"
          onClick={this.handleInputClick}
          onChange={this.handleInputChange}
          value={this.state.inputText || ''}
        />

        <div className={`months-container ${modal}`}>
          <div className='flex-container'>

            <div
              id="arrow-left"
              className={`arrows arrow-left flex-items ${arrowLeft}`}
              onClick={this.handleArrowClick}
              data-value={this.state.currentYear}>
              {'<'}
            </div>

            <div
              id="year-container"
              className="year-container"
              onClick={this.handleYearlyClick}>
              { renderYear }
            </div>

            <div
              id="arrow-right"
              className={`arrows arrow-left flex-items ${arrowRight}`}
              onClick={this.handleArrowClick}
              data-value={this.state.currentYear}>
              {'>'}
            </div>
          </div>

          <div
            id="month-container"
            className="month-container">
            { renderDates }
          </div>
        </div>

        <APInputText
          type="hidden"
          name="date"
          id='start_month'
          value={this.state.selectedDate}
          className="addon-right"
        />
      </div>
    );
  }
}

export default ReportsDatepicker;
