// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeFrequency shallow render 1`] = `
<Fragment>
  <APSelect
    label="Frequency"
    nativeElementId="recurFrequency"
    needsNativeElement={true}
    onChange={[Function]}
    options={
      [
        {
          "text": "One Time",
          "value": "DAY",
        },
        {
          "text": "Weekly / Every X Weeks",
          "value": "WEEKLY",
        },
        {
          "text": "Twice a Month",
          "value": "BI_MONTHLY",
        },
        {
          "text": "Monthly / Every X Months",
          "value": "MONTHLY",
        },
        {
          "text": "Yearly / Every X Years",
          "value": "YEAR",
        },
      ]
    }
    parentClass="ap-select-frequency"
    required={true}
  />
</Fragment>
`;

exports[`RecurringChargeFrequency shallow render bi monthly 1`] = `
<Fragment>
  <APSelect
    label="Frequency"
    nativeElementId="recurFrequency"
    needsNativeElement={true}
    onChange={[Function]}
    options={
      [
        {
          "text": "One Time",
          "value": "DAY",
        },
        {
          "text": "Weekly / Every X Weeks",
          "value": "WEEKLY",
        },
        {
          "text": "Twice a Month",
          "value": "BI_MONTHLY",
        },
        {
          "text": "Monthly / Every X Months",
          "value": "MONTHLY",
        },
        {
          "text": "Yearly / Every X Years",
          "value": "YEAR",
        },
      ]
    }
    parentClass="ap-select-frequency"
    required={true}
  />
</Fragment>
`;

exports[`RecurringChargeFrequency shallow render monthly 1`] = `
<Fragment>
  <APSelect
    label="Frequency"
    nativeElementId="recurFrequency"
    needsNativeElement={true}
    onChange={[Function]}
    options={
      [
        {
          "text": "One Time",
          "value": "DAY",
        },
        {
          "text": "Weekly / Every X Weeks",
          "value": "WEEKLY",
        },
        {
          "text": "Twice a Month",
          "value": "BI_MONTHLY",
        },
        {
          "text": "Monthly / Every X Months",
          "value": "MONTHLY",
        },
        {
          "text": "Yearly / Every X Years",
          "value": "YEAR",
        },
      ]
    }
    parentClass="ap-select-frequency"
    required={true}
  />
</Fragment>
`;

exports[`RecurringChargeFrequency shallow render weekly 1`] = `
<Fragment>
  <APSelect
    label="Frequency"
    nativeElementId="recurFrequency"
    needsNativeElement={true}
    onChange={[Function]}
    options={
      [
        {
          "text": "One Time",
          "value": "DAY",
        },
        {
          "text": "Weekly / Every X Weeks",
          "value": "WEEKLY",
        },
        {
          "text": "Twice a Month",
          "value": "BI_MONTHLY",
        },
        {
          "text": "Monthly / Every X Months",
          "value": "MONTHLY",
        },
        {
          "text": "Yearly / Every X Years",
          "value": "YEAR",
        },
      ]
    }
    parentClass="ap-select-frequency"
    required={true}
  />
</Fragment>
`;
