import React from 'react';
import {number, string} from 'prop-types';

const IconEQB = ({
  size,
  className
}) => (
  <svg width={ size } height={ size } viewBox={ `0 0 ${size} ${size}` } fill="none" xmlns="http://www.w3.org/2000/svg" className={ className }>
    <g id="Icon / Nav / Authorizations Copy">
      <path id="Mask" d="M16 8C16 3.58167 12.4182 0 8 0C3.58182 0 0 3.58167 0 8C0 12.4183 3.58182 16 8 16C12.4182 16 16 12.4183 16 8ZM14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.31815 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.31815 4.31815 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.31815 14.6667 8ZM8.66667 11.3333C8.66667 11.7015 8.36819 12 8 12C7.63181 12 7.33333 11.7015 7.33333 11.3333V6.66667C7.33333 6.29848 7.63181 6 8 6C8.36819 6 8.66667 6.29848 8.66667 6.66667V11.3333ZM8.66667 4C8.66667 3.6318 8.368 3.33333 8 3.33333C7.632 3.33333 7.33333 3.6318 7.33333 4C7.33333 4.3682 7.632 4.66667 8 4.66667C8.368 4.66667 8.66667 4.3682 8.66667 4Z" fill="#0069CC"/>
      <mask id="mask0_1_6032" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <path id="Mask_2" d="M16 8C16 3.58167 12.4182 0 8 0C3.58182 0 0 3.58167 0 8C0 12.4183 3.58182 16 8 16C12.4182 16 16 12.4183 16 8ZM14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.31815 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.31815 4.31815 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.31815 14.6667 8ZM8.66667 11.3333C8.66667 11.7015 8.36819 12 8 12C7.63181 12 7.33333 11.7015 7.33333 11.3333V6.66667C7.33333 6.29848 7.63181 6 8 6C8.36819 6 8.66667 6.29848 8.66667 6.66667V11.3333ZM8.66667 4C8.66667 3.6318 8.368 3.33333 8 3.33333C7.632 3.33333 7.33333 3.6318 7.33333 4C7.33333 4.3682 7.632 4.66667 8 4.66667C8.368 4.66667 8.66667 4.3682 8.66667 4Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_1_6032)">
      </g>
    </g>
  </svg>
);

IconEQB.defaultProps = {
  size: 16,
  className: 'ap-icon'
};

IconEQB.propTypes = {
  className: string,
  size: number
};

export default IconEQB;
