import React, { useState } from 'react';
import PropTypes from 'prop-types';
import client from 'lib/ajax';
import { APButton, APModal, APFormMessageBox, APTags } from 'affinipay-ui-library';
import { initApiSettingsFormState, buildApiSettingsRequestBody } from './helpers';
import notify from 'lib/notify';

const ApiSettings = ({ merchant }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [initialFormState, setInitialFormState] = useState(initApiSettingsFormState(merchant));
  const [formState, setFormState] = useState(initApiSettingsFormState(merchant));

  const onTagInputChange = ({ tags, name }) => {
    setFormState({...formState, [name]: tags});
  };

  const closeModal = () => {
    setFormState(initialFormState);
    setIsModalVisible(false);
  };

  const save = async (e) => {
    e.preventDefault();
    if (!formState) return;
    setIsSaving(true);
    setError('');
    try {
      const reqBody = buildApiSettingsRequestBody(formState);
      const resp = await client.patch(`/admin/merchants/${merchant?.id}/api_settings`, reqBody);
      if (resp.ok) {
        setInitialFormState(formState);
        setIsModalVisible(false);
        notify.success('Merchant API settings updated successfully.');
      }
      else if (resp.status === 422) {
        const json = await resp.json();
        setError(json.error || 'Something went wrong. Please refresh the page and try again');
      }
      else setError(`Something went wrong: ${resp.statusText}`);
    } catch (e) {
      setError('Something went wrong. Please refresh the page and try again');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="clearfix">
        <APButton onClick={() => setIsModalVisible(true)}>
          Show Settings
        </APButton>
        <APModal isActiveInitial={isModalVisible} onClose={closeModal} headerText='API Settings'>
          <div className="container-fluid api-settings-form">
            {error && (
              <div className="col-sm-12">
                <APFormMessageBox type='error'>
                  {error}
                </APFormMessageBox>
              </div>
            )}
            <div className='col-sm-12 input-help-message'>
              <div className="blue-i-icon" />
              Enter a value in a text field, then press &apos;return&apos;
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="allowed-ip-addresses">
                API - allowed IP address ranges
              </label>
              <div className="col-sm-9">
                <APTags 
                  tags={formState?.apiAllowedIpAddressRanges || []}
                  onChange={(tags) => onTagInputChange({name: 'apiAllowedIpAddressRanges', tags })}
                  placeholderText="IP range (i.e. 50.112.0.0/16)"
                  id="allowed-ip-addresses"
                  disabled={isSaving}
                  validator={(val) => /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([1-2]\d|3[0-2]|\d))$/.test(val)}
                />
                <p id="help-block-allowed-ip" className="help-block">
                  This list is useful only if you plan to use the AffiniPay Payment Gateway APIs and want to lock down the source IP(s) allowed to use them. An empty list allows any source IP to access the APIs. Must be a valid IP address.
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="live-events-urls">
                Live-mode Webhook URLs
              </label>
              <div className="col-sm-9">
                <APTags 
                  tags={formState?.liveEventsUrls || []}
                  onChange={(tags) => onTagInputChange({name: 'liveEventsUrls', tags })}
                  placeholderText="https://example.com"
                  id="live-events-urls"
                  disabled={isSaving}
                  validator={(val) => /^http[s]?:\/\/\S+(:[0-9]{4})?(\/\S+)?/.test(val)}
                />
                <p id="help-block-live-events" className="help-block">List of URLs to receive live-mode events. Must be a valid URL.</p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="test-events-urls">
                Test-mode Webhook URLs
              </label>
              <div className="col-sm-9">
                <APTags 
                  tags={formState?.testEventsUrls || []}
                  onChange={(tags) => onTagInputChange({name: 'testEventsUrls', tags })}
                  placeholderText="https://example.com"
                  id="test-events-urls"
                  disabled={isSaving}
                  validator={(val) => /^http[s]?:\/\/\S+(:[0-9]{4})?(\/\S+)?/.test(val)}
                />
                <p id="help-block-test-events" className="help-block">List of URLs to receive test-mode events. Must be a valid URL.</p>
              </div>
            </div>
          </div>
          <div className="ap-modal-footer">
            <APButton
              className="ap-secondary-button"
              onClick={closeModal}
              disabled={isSaving}
            >
              Cancel
            </APButton>
            <APButton
              className="ap-primary-button api-settings-save-button"
              disabled={isSaving}
              onClick={save}
            >
              Save Changes
            </APButton>
          </div>
        </APModal>
      </div>
      <hr/>
    </>
  );
};

export default ApiSettings;

ApiSettings.propTypes = {
  merchant: PropTypes.shape({
    api_allowed_ip_address_ranges: PropTypes.string,
    live_events_urls: PropTypes.string,
    test_events_urls: PropTypes.string,
    id: PropTypes.number
  })
};