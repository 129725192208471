// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`MerchantApp Component shallow snapshot render 1`] = `
<ThemeProvider
  theme="lawpay"
>
  <div
    className="row page-padding"
  >
    <div
      className="col-sm-12 center-content"
    >
      <h1>
        Prepare for your application
      </h1>
      <p
        className="flex-container vertical-margin"
      >
        Please make sure to have the following information handy before you start. These details are collected as part of the underwriting process, which verifies your identity in order to protect your firm against fraud.
      </p>
      <div
        className="vertical-margin"
      >
        <div
          className="flex-container"
        >
          <BorderedContentBox
            className="cols"
          >
            <b>
              For the firm and the owner(s):
            </b>
            <ul>
              <li>
                Firm Federal Tax ID
              </li>
              <li>
                Owner's Social Security Number
              </li>
              <li>
                Owner's Driver’s License
              </li>
            </ul>
          </BorderedContentBox>
          <BorderedContentBox
            className="cols"
          >
            <b>
              For the bank accounts:
            </b>
            <ul>
              <li>
                ABA Routing Number
              </li>
              <li>
                Bank Account Number
              </li>
            </ul>
          </BorderedContentBox>
        </div>
      </div>
      <p
        className="container-width"
      >
        Terms and Conditions must be accepted and signed by the
         
        <b>
          business owner
        </b>
         or 
        <b>
          authorized signer
        </b>
        .
      </p>
      <Button
        className="vertical-margin"
        element="a"
        href="http://foo"
        primary={true}
        size="large"
        type="button"
      >
        START APPLICATION
      </Button>
    </div>
  </div>
</ThemeProvider>
`;
