import React from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import '../style.scss';
import PaymentPageCustomAmount from '../../../components/PaymentPageCustomAmount';
import { DineroDollarsFormat } from '../../../lib/monetaryUtils';
import APRadioButton from '../../../components/APRadioButton/index';

const AmountRadio = ({
  currencySymbol,
  customFieldsState,
  disabledFields,
  element,
  showAmountInput,
  handleRadioButtonChange,
  handleCustomInputChange,
  handleQuantityChange
}) => {
  const {
    amount,
    help_text,
    max_quantity,
    name,
    position,
    type
  } = element;

  return showAmountInput ? (
    <div className='custom-amount-input-wrapper'>
      <APRadioButton
        name="customAmountRadio"
        value={name}
        checked={false}
        onChange={handleRadioButtonChange}
      />
      <div>
        <span>
          {`${name}${(amount && max_quantity) ? ` - ${DineroDollarsFormat(amount, currencySymbol)}` : ''}`}
        </span>
        {help_text && <span className="field-help-text">{help_text}</span>}
      </div>
      {showAmountInput && (disabledFields && !disabledFields[name]) &&
        <PaymentPageCustomAmount
          fieldType={type}
          required={disabledFields && !disabledFields[name]}
          label="Enter Amount"
          value={customFieldsState[name]}
          name={name}
          amount={amount}
          maxQuantity={max_quantity}
          onChange={handleCustomInputChange}
          handleQuantityChange={handleQuantityChange}
          disabled={false}
          currencySymbol={currencySymbol}
        />
      }
    </div>
  ) : (
    <div  key={`${position}-${name}`} className="custom-amount-static-wrapper">
      <APRadioButton
        name="customAmountRadio"
        value={name}
        checked={false}
        onChange={handleRadioButtonChange}
      />
      <div>
        <span>
          {`${name}${amount ? ` - ${DineroDollarsFormat(amount, currencySymbol)}` : ''}`}
        </span>
        {help_text && <span className="field-help-text">{help_text}</span>}
      </div>
    </div>
  );
};

AmountRadio.propTypes = {
  customFieldsState: object,
  currencySymbol: string,
  disabledFields: object,
  element: shape({
    amount: string,
    help_text: string,
    max_quantity: number,
    name: string,
    position: number,
    type: string
  }),
  showAmountInput: bool,
  handleRadioButtonChange: func.isRequired,
  handleCustomInputChange: func.isRequired,
  handleQuantityChange: func.isRequired
};

export default AmountRadio;
