import { APInputText } from 'affinipay-ui-library';
import PropTypes from 'prop-types';
import React from 'react';

const RecurringChargeInterval = ({
  oneTime,
  biMonthly,
  recurFrequency,
  intervalDelay,
  handleChange,
  selectedDay,
  isLastDayFirstCharge,
  secondChargeDate
}) => {
  const handleIntervalChange = (e) => {
    handleChange({ intervalDelay: e.target.value });
  };

  const labelDays = (selectedDay, frequency) => {
    let data = { label: 'day', date: '' };

    if (selectedDay == 'LAST') {
      data.date = 'last day of the month';
      data.label = 'month';
      return data;
    }

    let ordinal = 'th';
    const date = selectedDay;
    const day = date.getDate();

    const days = [
      'Sundays',
      'Mondays',
      'Tuesdays',
      'Wednesdays',
      'Thursdays',
      'Fridays',
      'Saturdays'
    ];

    switch (frequency) {
    case 'WEEKLY':
      data.label = 'week';
      data.date = days[date.getDay()];
      break;
    case 'MONTHLY':
    case 'BI_MONTHLY': {
      const isLastDay = date.getDate() === 31 || isLastDayFirstCharge;
      data.label = 'month';
      if (isLastDay) {
        data.date = 'last day of the month';
        break;
      }
      if (day < 4 || day > 20) {
        switch (day % 10) {
        case 1:
          ordinal = 'st';
          break;
        case 2:
          ordinal = 'nd';
          break;
        case 3:
          ordinal = 'rd';
          break;
        default:
          ordinal = 'th';
          break;
        }
      }
      data.date = day ? day + ordinal : '';
      break;
    }
    case 'YEAR':
      data.label = 'year';
      data.date = `${date.getMonth() + 1}/${day}`;
    }

    return data;
  };

  const intervalUnit = () => {
    const result = labelDays(
      selectedDay,
      recurFrequency
    ).label.toUpperCase();
    if (result === 'YEAR') {
      return 'MONTH';
    }
    return result;
  };

  const getHiddenIntervalDelay = () => {
    const isUserInputDisabled = recurFrequency === 'MONTHLY' && isLastDayFirstCharge === 'LAST';
    if (intervalDelay === undefined || isUserInputDisabled || recurFrequency === 'BI_MONTHLY') {
      return '1';
    } else if (recurFrequency === 'YEAR') {
      return String(intervalDelay * 12);
    } else {
      return intervalDelay;
    }
  };

  const renderOneTime = () => {
    return (
      <APInputText
        type="hidden"
        name="recurring_charge[max_occurrences]"
        value="1"
      />
    );
  };

  const renderBiMonthly = () => {
    const secondChargeDateObject = new Date(2020, 4, secondChargeDate);

    const labelData = labelDays(
      selectedDay,
      recurFrequency
    );
    
    const secondChargeLabelData = labelDays(
      secondChargeDateObject,
      recurFrequency
    );
    
    return (
      <div>
        <label className='hidden-element'>Interval</label>
        <div className="bi-monthly-label">
          On the <strong>{labelData.date}</strong> and the <strong>{secondChargeDate && secondChargeLabelData.date}</strong>
        </div>
      </div>
    );
  };

  const renderCommon = () => {
    const labelData = labelDays(
        selectedDay,
        recurFrequency
      ),
      isDisabled = recurFrequency === 'MONTHLY' && isLastDayFirstCharge === 'LAST';
    const {date, label} = labelData;
    return (
      <div className="row recurring-charge-interval">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-xs-12 interval-row">
              <label className='interval-label no-margin'>Every</label>
              <APInputText
                value={intervalDelay === undefined || isDisabled ? '1' : intervalDelay}
                type="text"
                onChange={handleIntervalChange}
                className="has-addon recurring-charge-interval-input"
                disabled={isDisabled}
              />
              <div className="interval-label">
                <span>{`${label}(s) `}</span><strong>{` on ${recurFrequency === 'MONTHLY' ? 'the' : ''} ${date}`}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  let intervalRenderer = renderCommon;

  if (oneTime) {
    intervalRenderer = renderOneTime;
  } else if (biMonthly) {
    intervalRenderer = renderBiMonthly;
  }

  return (
    <>
      {intervalRenderer()}
      <APInputText
        type="hidden"
        value={getHiddenIntervalDelay()}
        name="recurring_charge[schedule][interval_delay]"
      />
      <APInputText
        name="recurring_charge[schedule][interval_unit]"
        type="hidden"
        value={intervalUnit()}
      />
    </>
  );
};

RecurringChargeInterval.propTypes = {
  isLastDayFirstCharge: PropTypes.string,
  isLastDaySecondCharge: PropTypes.string,
  recurFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedDay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  scheduleStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  recurStart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  oneTime: PropTypes.bool,
  biMonthly: PropTypes.bool,
  intervalDelay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func,
  secondChargeDate: PropTypes.number
};

export default RecurringChargeInterval;
