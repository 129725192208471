import React, { Component } from 'react';
import { APButton } from 'affinipay-ui-library';
import PropTypes from 'prop-types';
import './style.scss';

export default class Success extends Component {
  render() {
    return (
      <div data-jest-echeck-success>
        <div className="flexContainer">
          <img
            className='successIcon'
            src={this.props.successIcon}
            alt="Success Checkmark"
          />
          <h3 className='successMessage flexItem'>
            You have successfully opted in to eCheck payments!
          </h3>
        </div>
        <p>
          You&#39;ll be able to accept eCheck payments from your clients within
          2-3 business days.
        </p>

        <div className='buttonContainer'>
          <APButton
            type="primary"
            text="Go to Dashboard"
            onClick={this.props.onClose}
          >
            Go to Dashboard
          </APButton>
        </div>
      </div>
    );
  }

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    successIcon: PropTypes.string.isRequired
  };
}
