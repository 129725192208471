import React, { useState } from 'react';
import { arrayOf, string } from 'prop-types';
import { APButton, APModal } from 'affinipay-ui-library';

const MultiplePreviousDates = ({merchantName, previousTestDate, previousTestDates}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div>
        <span>{previousTestDate}</span>
        <button
          className="view-previous-dates"
          type="button"
          onClick={toggleModal}
        >
          view all
        </button>
      </div>
      <APModal
        isActiveInitial={isModalOpen}
        headerText="Test Date History"
        onClose={toggleModal}
        closeModalIconTitle={'Close Test Date Modal Icon'}
      >
        <div className="previous-dates-container">
          <div className="previous-dates-title">{`Test History for ${merchantName}`}</div>
          <div className="previous-dates-table">
            <div>Date</div>
            {previousTestDates.map((date, index) => <div key={merchantName+date+index}>{date}</div>)}
          </div>
          <div className="previous-dates-done-btn">
            <APButton type="button" onClick={toggleModal}>Done</APButton>
          </div>
        </div>
      </APModal>
    </>
  );
};

const SinglePreviousDate = ({ previousTestDate }) => <span>{ previousTestDate || '' }</span>;

const PreviousTestDateTableCell = ({ merchantName, previousTestDate, previousTestDates }) => {
  const cellMap = new Map([
    ['multiple', MultiplePreviousDates],
    ['not-multiple', SinglePreviousDate]
  ]);
  const componentSwitch = previousTestDates.length <= 1 ? 'not-multiple' : 'multiple';
  const SpecificDateCell = cellMap.get(componentSwitch);

  return (<SpecificDateCell
    previousTestDates={previousTestDates}
    merchantName={merchantName}
    previousTestDate={previousTestDate}
  />);
};

/* Prop Type Assignment */
const previousTestDatesProp = arrayOf(string);
const testRowProp = () => ({ merchantName: string, previousTestDate: string, previousTestDates: previousTestDatesProp });
SinglePreviousDate.propTypes = { previousTestDate: string };
MultiplePreviousDates.propTypes = testRowProp;
PreviousTestDateTableCell.propTypes = testRowProp;

export default PreviousTestDateTableCell;
