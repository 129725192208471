import React from 'react';
import { shallow, mount } from 'enzyme';
import RecurringChargeBankAccount from './RecurringChargeBankAccount';

describe('RecurringChargeBankAccount Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(<RecurringChargeBankAccount />);
    expect(component).toMatchSnapshot();
  });

  it('update state for account and routing numbers', () => {
    const component = shallow(<RecurringChargeBankAccount />);
    const accountNum = '123456789';
    const routingNum = '987654321';

    component
      .find('#account_number')
      .at(0)
      .prop('onChange')({ target: { value: accountNum } });
    component
      .find('#routing_number')
      .at(0)
      .prop('onChange')({ target: { value: routingNum } });

    expect(component.state('accountNumber')).toEqual(accountNum);
    expect(component.state('routingNumber')).toEqual(routingNum);
  });

  it('calls showAccountHolderFields to switch account type selected', () => {
    const component = shallow(<RecurringChargeBankAccount />);
    const spy = jest.spyOn(component.instance(), 'showAccountHolderFields');
    component.instance().forceUpdate();

    expect(component.state('accountTypeSelected')).toEqual('individual');
    component
      .find('APRadioButton')
      .at(2)
      .prop('onChange')({ target: { value: 'business_checking' } });

    expect(component.state('accountTypeSelected')).toEqual('business');
    expect(spy).toHaveBeenCalled();
    spy.mockRestore();
  });
  it('testing checkboxes checking business', () => {
    const component = mount(<RecurringChargeBankAccount {...getMockProps('checking-business')} />),
      actual = component.find('#account_business_checking').at(1).props().defaultChecked;
    expect(actual).toBe(true);
    component.unmount();
  });
  it('testing checkboxes savings business', () => {
    const component = mount(<RecurringChargeBankAccount {...getMockProps('savings-business')} />),
      actual = component.find('#account_business_savings').at(1).props().defaultChecked;
    expect(actual).toBe(true);
    component.unmount();
  });
  it('testing checkboxes checking individual', () => {
    const component = mount(<RecurringChargeBankAccount {...getMockProps('checking-individual')} />),
      actual = component.find('#account_individual_checking').at(1).props().defaultChecked;
    expect(actual).toBe(true);
    component.unmount();
  });
  it('testing checkboxes savings individual', () => {
    const component = mount(<RecurringChargeBankAccount {...getMockProps('savings-individual')} />),
      actual = component.find('#account_individual_savings').at(1).props().defaultChecked;
    expect(actual).toBe(true);
    component.unmount();
  });
});
const getMockProps = (type) => {
  switch (type) {
  case 'checking-business':
    return {
      bankAccountType: 'CHECKING',
      accountHolderType: 'business'
    };
  case 'savings-business':
    return {
      bankAccountType: 'SAVINGS',
      accountHolderType: 'business'
    };
  case 'checking-individual':
    return {
      bankAccountType: 'CHECKING',
      accountHolderType: 'individual'
    };
  case 'savings-individual':
    return {
      bankAccountType: 'SAVINGS',
      accountHolderType: 'individual'
    };
  default:
    return;
  }
};
