import React, { useEffect, useState } from 'react';
import { func, object, string } from 'prop-types';
import { APComboBox, APToast } from 'affinipay-ui-library';
import PartnersService from './src/services/PartnerService';
import './style.scss';

const PartnersFilter = ({
  onChange,
  onSelect,
  filterValues,
  partnerType
}) => {

  const [partners, setPartners] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const sanitazedPartnerType = () => {
    const partnerTypeValue = partnerType.toLowerCase();
    // validates that the partner type param is either integration or referral, otherwise return referral as default
    return partnerTypeValue == 'integration' || partnerTypeValue == 'referral' ? partnerTypeValue : 'referral';
  };

  const capitalizedPartnerType = sanitazedPartnerType().charAt(0).toUpperCase() + sanitazedPartnerType().slice(1);

  useEffect(() => {
    if (filterValue.length >= 3 || filterValue.trim().length == 0) {
      fetchPartners();
    }
  }, [filterValue]);

  useEffect(() => {
    if (filterValues[`${sanitazedPartnerType()}PartnersFilter`].trim().length == 0) {
      setFilterValue('');
    }
  }, [filterValues[`${sanitazedPartnerType()}PartnersFilter`]]);

  const fetchPartners = async () => {
    try {
      const params = [
        sanitazedPartnerType() == 'integration' ? `filter[partner_type]=${sanitazedPartnerType()}`: null,
        filterValue? `filter[name]=${filterValue}` : null
      ].filter(x => x !== null);

      const { data } = await PartnersService.get(params);
      setPartners(data);
    } catch (error) {
      setErrorMessage(`Error fetching ${capitalizedPartnerType} Partners, please add more characters to the filter or try again later.`);
      setShowErrorMessage(true);
    }
  };

  const wrapPartnersListItems = (highlightedIndex, getItemProps) => {
    const mapPartnersListItems = (partner, index) => {

      return (
        <li key={partner.id}
          className={`ap-combobox-list-item${highlightedIndex === index
            ? ' ap-combobox-list-item-highlight' : ''}`
          }
          {...getItemProps({partner, index})}
        >
          {`${partner.name}`}
        </li>
      );
    };

    return mapPartnersListItems;
  };

  const partnerToString = partner => {
    if (!partner || !partner.name) return '';
    return partner?.name ? partner.name : '';
  };

  const handleChange = (event) => {
    const {target: {name, value}} = event;
    onChange({target: {name, value}});
    setFilterValue(value);
  };

  const handleErrorMessage = () => {
    setErrorMessage('');
    setShowErrorMessage(false);
  };

  const handleSelect = (partner) => {
    onSelect(partner, sanitazedPartnerType());
  };


  return (
    <div className={`${sanitazedPartnerType()}-filter-wrapper`}>
      <APComboBox
        dataset={{'aria-label': `${capitalizedPartnerType} Partners`, 'data-testid': `${sanitazedPartnerType()}-partner-filter`}}
        itemToString={partnerToString}
        name={`${sanitazedPartnerType()}PartnersFilter`}
        label={`${capitalizedPartnerType} Partners`}
        listItems={partners}
        mapObjectListItems={wrapPartnersListItems}
        placeholder="All"
        selected={filterValue}
        value={filterValue}
        onChange={handleChange}
        onSelectedItemChange={handleSelect}
      />

      <APToast
        isOpen={showErrorMessage}
        type='error'
        onClose={handleErrorMessage}
        fadeOutTime={null}
      >
        <div>
          <p className='message-header'>
            Error fetching {capitalizedPartnerType} Partners
          </p>
          <p>
            {errorMessage}
          </p>
        </div>
      </APToast>
    </div>
  );
};

export default PartnersFilter;

PartnersFilter.propTypes = {
  onChange: func.isRequired,
  onSelect: func.isRequired,
  filterValues: object.isRequired,
  partnerType: string.isRequired
};
