import React from 'react';
import { shallow, mount } from 'enzyme';
import Resend from './resend';
import client from '../../../lib/ajax';
import notify from '../../../lib/notify';

describe('Resend Component', () => {
  beforeAll(() => {
    jest.spyOn(notify, 'success').mockImplementation(() => {
      return true;
    });
    jest.spyOn(notify, 'error').mockImplementation(() => {
      return false;
    });
  });

  it('shallow snapshot render', () => {
    const component = shallow(<Resend />);
    expect(component).toMatchSnapshot();
  });

  it('mount to test UI events', () => {
    const component = mount(<Resend email="propTest"/>);
    component.instance().handleChange({target:{value: 'Test'}});
    component.update();
    expect(component.state().email).toEqual('Test');
    component.instance().reset();
    component.update();
    expect(component.state().email).toEqual('propTest');
    component.unmount();
  });

  it('mount to test success form submit', async () => {
    const component = mount(<Resend/>);
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => Promise.resolve({message: 'text'}))
      })
    );
    component.instance().handleSubmit({name: 'John'});
    setTimeout(() => {
      expect(notify.success).toHaveBeenCalled();
      component.unmount();
    },100);
  });

  it('mount to test Not Authorized form submit', async () => {
    const component = mount(<Resend/>);
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 401,
        response: true,
        json: jest.fn(() => Promise.resolve({message: 'text'}))
      })
    );
    component.instance().handleSubmit({name: 'John'});
    setTimeout(() => {
      expect(notify.error).toHaveBeenCalled();
      component.unmount();
    },100);
  });

  it('mount to test 404 form submit', async () => {
    const component = mount(<Resend/>);
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 404,
        response: true,
        json: jest.fn(() => Promise.resolve({message: 'text'}))
      })
    );
    component.instance().handleSubmit({name: 'John'});
    setTimeout(() => {
      expect(notify.error).toHaveBeenCalled();
      component.unmount();
    },100);
  });

  it('mount to test any error code form submit', async () => {
    const component = mount(<Resend/>);
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 406,
        response: true,
        json: jest.fn(() => Promise.resolve({message: 'text'}))
      })
    );
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 406,
        response: true,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    component.instance().handleSubmit({name: 'John'});
    setTimeout(() => {
      expect(notify.error).toHaveBeenCalled();
      component.unmount();
    },100);
  });
});
