import React from 'react';
import {bool, func} from 'prop-types';
import {APButton} from 'affinipay-ui-library';

const EditAndSendQuickBillButton = ({
  isDisabled = false,
  handleEditQuickBill,
  isSendingAllowed
}) => (
  <div className="ap-button-wrapper">
    <APButton
      className="ap-secondary-button"
      disabled={isDisabled}
      type="button"
      onClick={handleEditQuickBill}
    >
      {`Edit & ${isSendingAllowed ? 'Resend' : 'Send'}`}
    </APButton>
  </div>
);

EditAndSendQuickBillButton.propTypes = {
  isDisabled: bool,
  handleEditQuickBill: func.isRequired,
  isSendingAllowed: bool
};

export default EditAndSendQuickBillButton;
