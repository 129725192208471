import React from 'react';
import PropTypes from 'prop-types';
import { APAccordion } from 'affinipay-ui-library'; 

class APStatementsRails extends React.Component {
  static propTypes = {
    statements: PropTypes.arrayOf(PropTypes.object)
  };

  constructor(props) {
    super(props);
  }

  getData = () => { 
    let newData = {};

    const data = this.props.statements.filter(statement => {
      let previous = '';
      if (Object.keys(newData).includes(String(statement.year))) {
        previous = newData[statement.year];
        previous.month.push(statement.month);
        previous.link.push(statement.link);

        return false;
      }

      if (!Array.isArray(statement.month)) {
        statement.month = [statement.month];
        statement.link = [statement.link];
      }

      newData[statement.year] = statement;

      return true;
    });

    return data; 
  };

  getMonths = (accordionLink, accordionMonth) => {  
    return accordionLink.map((link, i) => {  
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], 
        date = new Date(2020, accordionMonth[i] - 1),
        month = monthNames[date.getMonth()];
      
      return (
        <div key={`month-${i}`} className={`ap-statements-rails month-${i}`}>
          <a id={`month-${i}`} href={link} rel="noopener noreferrer" target='_blank' className='pdf-icon'>
            <p className='month-text'>{month}</p>
          </a> 
        </div>    
      );
    });
  };

  getContent = () => {
    return this.getData().map((accordion, index) => {
      const yearIndex = index + 1;

      if (yearIndex > 10) return false;

      return (
        <APAccordion key={`year-${yearIndex}`} label={accordion.year}>
          {this.getMonths(accordion.link, accordion.month)}
        </APAccordion>
      );
    });
  };

  render() {
    return (
      <div>
        {this.getContent()}
      </div>
    );
  }
}

export default APStatementsRails;