
import { isNumber } from './utils';

// private

const _getPotentialCardNumbersInString = str => [...str.replace(/[- ]/g, '').matchAll(/\d{14}|d{15}|d{16}/g)];

const _rollbarLog = (message, severity) => {
  if(window.Rollbar) {
    switch(severity) {
    case 'critical':
      window.Rollbar.critical(message);
      break;
    case 'warning':
      window.Rollbar.warning(message);
      break;
    case 'info':
      window.Rollbar.info(message);
      break;
    case 'debug':
      window.Rollbar.debug(message);
      break;
    default:
      break;
    }
  } else {
    console.error('Rollbar is not initialized. Error:', message, 'Severity: ', severity);
  }
};

const _isCardLength = val => val.length >=14 && val.length <=16;

const _isLuhnValid = val => {
  const checkSum = (val + '').split('').reverse().map((el, i) => {
    const n = parseInt(el);
    if(i%2!==0){
      const doubled = n*2;
      if(doubled > 9) {
        return (doubled+'').split('').reduce((a, b) => Number(a) + Number(b), 0); 
      }else {
        return doubled;
      }
    } else {
      return n;
    }
  }).reduce((a, b) => Number(a) + Number(b), 0);
  return checkSum % 10 === 0;
};

// public

export const isCardNumber = val => {
  const str = val+'',
    trimmed = str.replace(/[- ]/g, ''),
    num = Number(trimmed);
  return (isNumber(num) && _isCardLength(trimmed) && _isLuhnValid(trimmed));
};

export const rollbarLog = (caller, message, severity='critical') => {
  try{
    if(isCardNumber(caller)){
      _rollbarLog(`[${caller}] Attempted to log sensitive information in caller parameter`, 'warning');
      return;      
    }
    if(message.length > 2000){
      _rollbarLog(`[${caller}] Attempted log is too long`, 'warning'); 
      return;
    }
    const matches = _getPotentialCardNumbersInString(message);
    for (const match of matches) {
      if(isCardNumber(match[0])){
        _rollbarLog(`[${caller}] Attempted to log sensitive information`, 'warning');
        return;
      }
    }
    _rollbarLog(`[${caller}] ${message}`, severity);
  }catch(e){
    _rollbarLog(`[${caller}] Failed to sanitize log message. Error: ${e}`, 'warning'); 
  }
};
