import React from 'react';

export default class NoTransactionsRow extends React.Component {
  render() {
    let emptyStyles = { textAlign: 'left' };
    return (
      <tr>
        <td colSpan={3} style={ emptyStyles }>No Available Transactions</td>
      </tr>
    );
  }
}
