import React from 'react';
import {bool, element, func, number, object, oneOfType, string} from 'prop-types';

class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.totalPages = this.totalPages.bind(this);
    this.leftWindow = this.leftWindow.bind(this);
    this.rightWindow = this.rightWindow.bind(this);
    this.relevantPages = this.relevantPages.bind(this);
    this.isFirstPage = this.isFirstPage.bind(this);
    this.isLastPage = this.isLastPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.hasLeftTruncatedTags = this.hasLeftTruncatedTags.bind(this);
    this.hasRightTruncatedTags = this.hasRightTruncatedTags.bind(this);
  }

  totalPages() {
    const { totalEntries, pageSize } = this.props;

    if (totalEntries === 0) {
      return 1;
    }

    return Math.ceil((totalEntries / pageSize));
  }

  leftWindow() {
    return Array(this.props.window + 1).fill(this.props.currentPage - (this.props.window + 1))
      .map((n, i) => n + ++i);
  }

  rightWindow() {
    return Array(this.props.window + 1).fill(this.props.currentPage + (this.props.window + 1))
      .map((n, i) => n - ++i);
  }

  hasLeftTruncatedTags() {
    const { currentPage, window } = this.props;
    return (currentPage - (window + 1)) > 0;
  }

  hasRightTruncatedTags() {
    const { currentPage, window } = this.props;

    return (currentPage + window) < this.totalPages();
  }

  relevantPages() {
    let currentWindow = this.leftWindow().concat(this.rightWindow())
      .sort((a, b) => a - b)
      .filter(w => w > 0 && w <= this.totalPages());

    // TODO - Set might not be compatible with all browsers :(
    return Array.from(new Set(currentWindow));
  }

  isFirstPage() {
    return this.props.currentPage === 1;
  }

  isLastPage() {
    return this.props.currentPage === this.totalPages();
  }

  nextPage() {
    const { currentPage } = this.props;
    currentPage < this.totalPages() ? currentPage + 1 : null;
  }

  previousPage() {
    const { totalEntries, currentPage } = this.props;
    if (totalEntries === 1 ) {
      return 1;
    }

    if (this.isFirstPage()) {
      return null;
    }

    return currentPage - 1;
  }

  render() {
    if (this.totalPages() === 1) { return null; }

    const firstPageTag = (
      this.isFirstPage() ? null : (
        <PageTag onClick={(e) => {
          e.preventDefault();
          this.props.onClick(1);
        }}>
          &laquo; First
        </PageTag>
      )
    );

    const prevPageTag = (
      this.isFirstPage() ? null : (
        <PageTag onClick={(e) => {
          e.preventDefault();
          this.props.onClick(this.props.currentPage - 1);
        }}>
          &lsaquo;
        </PageTag>
      )
    );

    const leftTruncatedTags = this.hasLeftTruncatedTags() ? <TruncatedTag /> : null;
    const rightTruncatedTags = this.hasRightTruncatedTags() ? <TruncatedTag /> : null;

    const nextPageTag = (
      this.isLastPage() ? null : (
        <PageTag onClick={(e) => {
          e.preventDefault();
          this.props.onClick(this.props.currentPage + 1);
        }}>
          &rsaquo;
        </PageTag>
      )
    );

    const lastPageTag = (
      this.isLastPage() ? null : (
        <PageTag onClick={(e) => {
          e.preventDefault();
          this.props.onClick(this.totalPages());
        }}>
          Last &raquo;
        </PageTag>
      )
    );

    return (
      <div className={`pagination ${this.props.className}`}>
        <ul className="pagination">
          {firstPageTag}
          {prevPageTag}
          {leftTruncatedTags}
          {
            this.relevantPages().map((p, i) => {
              return (
                <li
                  className={this.props.currentPage === p ? 'active' : null}
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.props.currentPage === p) { return; }
                    this.props.onClick(p);
                  }}
                >
                  <a rel="next">{p}</a>
                </li>
              );
            })
          }
          {rightTruncatedTags}
          {nextPageTag}
          {lastPageTag}
        </ul>
      </div>
    );
  }
}

const PageTag = (props) => {
  return (
    <li>
      <a onClick={props.onClick}>
        {props.children}
      </a>
    </li>
  );
};

const TruncatedTag = () => {
  return (
    <li className="disabled">
      <a>&hellip;</a>
    </li>
  );
};

Paginator.propTypes = {
  totalEntries: oneOfType([number, string]),
  pageSize: oneOfType([number, string]),
  window: oneOfType([number, object]),
  currentPage: oneOfType([number, string]),
  permissionBannerHidden: bool,
  onClick: func,
  className: string,
  children: element
};

PageTag.propTypes = {
  onClick: func,
  children: string
};

Paginator.defaultProps = {
  window: 3
};

export default Paginator;
