import React, { useEffect, useState } from 'react';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import { APButton, APInputText, APInputMonetary } from 'affinipay-ui-library';

const CustomAmount = ({
  label,
  value,
  name,
  onChange,
  required=false,
  amount,
  maxQuantity,
  handleQuantityChange,
  disabled,
  fieldType
}) => {
  const [quantityAmount, setQuantityAmount] = useState(value ? value[0] : undefined);

  useEffect(() => {
    if(disabled) {
      setQuantityAmount(undefined);
    } else {
      const el = document.getElementById(name);
      if(el) {
        el.focus();
      }
    }
  }, [disabled]);

  useEffect(() => {
    if(amount && maxQuantity) {
      handleQuantityChange({name, value: quantityAmount || 0}, amount);
    }
  }, [quantityAmount]);

  const onQuantityChange = e => {
    const {value} = e.target;
    if(!value || (/^(?:[1-9]\d*|0)$/.test(value) && parseFloat(value) <= maxQuantity)) {
      setQuantityAmount(value);
    }
  };

  const handlePlusAndMinus = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const currAmt = quantityAmount ? Number(quantityAmount) : 0;
    switch(val) {
    case '-':
      if(currAmt > 0) {
        setQuantityAmount(currAmt - 1);
      }
      break;
    case '+':
      if(currAmt < maxQuantity) {
        setQuantityAmount( currAmt + 1);
      }
      break;
    default:
      break;
    }
  };

  if(amount && maxQuantity) {
    return (
      <div className="amount-quantity">
        {fieldType !== 'amount' && <APButton
          className='ap-icon-button minus-button'
          value='-'
          onClick={handlePlusAndMinus}
          disabled={!quantityAmount || Number(quantityAmount) === 0}
          dataset={{'data-testid': 'decrease-quantity-button'}}
        />}
        <APInputText
          label={label}
          required={required}
          isFloatingLabel={true}
          value={quantityAmount}
          name={name}
          max={maxQuantity}
          min={0}
          disabled={disabled}
          onChange={onQuantityChange}
          placeholder="Quantity"
          type="number"
        />
        {fieldType !== 'amount' && <APButton
          className='ap-icon-button plus-button'
          value='+'
          onClick={handlePlusAndMinus}
          disabled={quantityAmount && Number(quantityAmount) === maxQuantity}
          dataset={{'data-testid': 'increase-quantity-button'}}
        />}
      </div>
    );
  }

  return (
    <APInputMonetary
      disabled={disabled}
      hasAddon={false}
      isFloatingLabel={true}
      label={label}
      name={name}
      onChange={onChange}
      required={required}
      value={value}
    />
  );
};

CustomAmount.propTypes = {
  label: string,
  value: oneOfType([string, number, array]),
  name: string,
  onChange: func,
  required: bool,
  amount: oneOfType([string, number]),
  maxQuantity: oneOfType([string, number]),
  handleQuantityChange: func,
  disabled: bool,
  fieldType: string
};

export default CustomAmount;
