import React from 'react';
import {arrayOf, object, string} from 'prop-types';
import {APCheckbox} from 'affinipay-ui-library';

const cardTypeEnum = {
  VISA: 'Visa',
  DISCOVER: 'Discover',
  MASTERCARD: 'MasterCard',
  DINERS_CLUB: 'Diners Club',
  AMERICAN_EXPRESS: 'American Express',
  JCB: 'JCB'
};

const cardRequiredEnum = {
  card_number: 'Card Number',
  address1: 'Address 1',
  card_expiration: 'Card Expiration',
  city: 'City',
  cvv: 'Card CVV',
  state: 'State',
  name: 'Card Name',
  postal_code: 'Postal Code',
  email: 'Email',
  country: 'Country'
};

const achRequiredEnum = {
  name: 'Account Name',
  state: 'State',
  email: 'Email',
  postal_code: 'Postal Code',
  address1: 'Address 1',
  country: 'Country',
  city: 'City'
};

const allCardTypes = ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS', 'DISCOVER', 'DINERS_CLUB', 'JCB'];
const allRequiredCardValues = ['cvv', 'name', 'email', 'address1', 'city', 'state', 'postal_code', 'country'];
const allAchRequiredValues = ['name', 'email', 'address1', 'city', 'state', 'postal_code', 'country'];

const convertStringValuesToArray = (values, valuesType) => {
  const valueEnums = {
    card: allCardTypes,
    requiredCardValues: allRequiredCardValues,
    ach: allAchRequiredValues
  };
  if (values === '*') return valueEnums[valuesType];
  return values.split(',');
};

const SettingDetailCheckboxes = ({title, values, valuesEnum}) => (
  <div className="setting-detail-checkboxes-wrapper">
    <div>{title}</div>
    <div className="setting-detail-checkboxes">
      {Object.keys(valuesEnum).map(value  => (
        <div key={value} className="setting-detail-checkbox">
          <APCheckbox
            checked={value === 'card_number' || value === 'card_expiration' ? true : values.indexOf(value) !== -1}
            disabled={true} />
          <span className="setting-detail-checkbox-label">{valuesEnum[value]}</span>
        </div>
      ))}
    </div>
  </div>
);

const CardAccountSettingsDetails = ({
  acceptedCardTypes='',
  requiredPaymentFields='',
  swipeRequiredPaymentFields=''
}) => {
  const acceptedCardArr = convertStringValuesToArray(acceptedCardTypes, 'card');
  const requiredFieldsArr = convertStringValuesToArray(requiredPaymentFields, 'requiredCardValues');
  const requiredSwiperFieldsArr = convertStringValuesToArray(swipeRequiredPaymentFields, 'requiredCardValues');

  return (
    <div className="deposit-account-required-fields-container">
      <SettingDetailCheckboxes
        title="Accepted Card Types:"
        values={acceptedCardArr}
        valuesEnum={cardTypeEnum}
      />
      <SettingDetailCheckboxes
        title="Manual Entry Policies:"
        values={requiredFieldsArr}
        valuesEnum={cardRequiredEnum}
      />
      <SettingDetailCheckboxes
        title="Swiper Policies:"
        values={requiredSwiperFieldsArr}
        valuesEnum={cardRequiredEnum}
      />
    </div>
  );
};

const AchAccountSettingsDetails = ({ requiredPaymentFields }) => {
  const requiredAchFields = convertStringValuesToArray(requiredPaymentFields, 'ach');
  return (
    <div className="deposit-account-required-fields-container">
      <SettingDetailCheckboxes
        title="Required Payment Fields:"
        values={requiredAchFields}
        valuesEnum={achRequiredEnum} />
    </div>
  );
};

const DepositAccountsSettingDetails = ({
  acceptedCardTypes,
  displayType,
  requiredPaymentFields,
  swipeRequiredPaymentFields
}) => {
  const detailsComponents = {
    card: CardAccountSettingsDetails,
    echeck: AchAccountSettingsDetails
  };
  const detailsValues = {
    card: { acceptedCardTypes, requiredPaymentFields, swipeRequiredPaymentFields },
    echeck:{ requiredPaymentFields }
  };
  const SpecificAccountSettingsDetails = detailsComponents[displayType?.toLowerCase()] || {};
  const specificAccountProps = detailsValues[displayType?.toLowerCase()] || {};

  return (
    <div>
      {displayType && <SpecificAccountSettingsDetails {...specificAccountProps} />}
    </div>
  );
};

AchAccountSettingsDetails.propTypes = {
  requiredPaymentFields: string
};
CardAccountSettingsDetails.propTypes = {
  acceptedCardTypes: string,
  requiredPaymentFields: string,
  swipeRequiredPaymentFields: string
};
SettingDetailCheckboxes.propTypes = {
  title: string.isRequired,
  values: arrayOf(string),
  valuesEnum: object.isRequired
};
DepositAccountsSettingDetails.propTypes = {
  acceptedCardTypes: string,
  displayType: string,
  requiredPaymentFields: string,
  swipeRequiredPaymentFields: string
};

export default DepositAccountsSettingDetails;
