import React from 'react';
import {string} from 'prop-types';
import IconPaperclip from '../icons/icon-paperclip';
import {bankAccountsProp, invoiceProp} from '../../../customProps';
import { DineroCentsFormat } from '../../../lib/monetaryUtils';

const QuickBillDetails = ({bankAccounts = [], invoice, selectedBankAccountId}) => {
  const {attachments, contact, id, invoiceMessages, reference} = invoice;
  const lastInvoiceMessage = invoiceMessages[invoiceMessages.length - 1];

  const getFormattedTotalValue = () => {
    if (selectedBankAccountId) {
      const {currency} = bankAccounts.find(account => account.xid === selectedBankAccountId);
      return DineroCentsFormat(invoice.totalAmount, currency);
    } else {
      return DineroCentsFormat(invoice.totalAmount, invoice.currency);
    }
  };

  const buildDetails = values => (
    values.map(({label, value}) => {
      if (value) return (
        <div className="quick-bill-details-item" key={label === 'Total Amount' ? value : label}>
          <span>{label}</span>
          <span>{value}</span>
        </div>
      );
    })
  );

  const buildClientValue = name => name ? name.trim() : null;

  const buildRecipients = () => {
    const emailAddresses = lastInvoiceMessage && lastInvoiceMessage?.emailAddresses;

    if (!emailAddresses || emailAddresses.length < 1) {
      return 'Recipients have not been added to this invoice. Click Edit and Send to add recipients.';
    }

    return emailAddresses.map((address, index) => (
      <div key={address + index}>{address}</div>
    ));
  };

  const buildAttachments = () => {
    if (!attachments || attachments.length < 1) return 'No Attachments';
    return attachments.map(attachment => (
      <div key={attachment.id} className="quick-bill-details-attachment">
        <IconPaperclip className="quick-bill-detail-attachment-icon" />
        <a
          data-action="click"
          data-category="QuickBill"
          data-label="Attachment Download"
          className="trackable"
          href={`/quick_bills/${id}/attachments/${attachment.id}`}
        >
          {attachment.fileName}
        </a>
      </div>
    ));
  };

  return (
    <div className="legacy-quick-bill-details">
      <h3>Details</h3>
      <div className="legacy-quick-bill-details-row">
        {buildDetails([
          {label: 'Reference', value: reference},
          {label: 'Total Amount', value: getFormattedTotalValue()}
        ])}
      </div>
      {buildDetails([{label: 'Client', value: buildClientValue(contact?.name)}])}
      <div className="quick-bill-details-item">
        <span>Recipient(s)</span>
        {buildRecipients()}
      </div>
      {buildDetails([
        {label: 'Subject Line', value: lastInvoiceMessage?.subject},
        {label: 'Body Message', value: lastInvoiceMessage?.message}
      ])}
      <div className="quick-bill-details-item">
        <span>Attachment(s)</span>
        {buildAttachments()}
      </div>
    </div>
  );
};

QuickBillDetails.propTypes = {
  bankAccounts: bankAccountsProp,
  invoice: invoiceProp,
  selectedBankAccountId: string
};

export default QuickBillDetails;
