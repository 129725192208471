import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { swiperStates } from '../../lib/paymentPage/constants';

const SwiperComponent = React.memo(({
  options,
  swiperState,
  setSwiperState,
  cardState,
  setCardState,
  hostedCardFields,
  disabledFieldsState,
  setDisabledFieldsState
}) => {

  const [preSwipeDisabledState, setPreSwipeDisabledState] = useState({});

  useEffect(() => {
    if (swiperState.isSwiping) {
      document.addEventListener('click', handleSwipeEventCancel);
    }else if(!swiperState.isSwiping){
      document.removeEventListener('click', handleSwipeEventCancel);
    }
  }, [swiperState.isSwiping]);

  const setFormDisabledOnSwipe = () => {
    let newDisabledFields = {...disabledFieldsState};
    for(const fieldName in newDisabledFields){
      if(!fieldName.startsWith('echeck') && fieldName !== 'cvv') newDisabledFields[fieldName] = true;
    }
    setPreSwipeDisabledState(disabledFieldsState);
    setDisabledFieldsState(newDisabledFields);
  };

  const handleUnsuccessfulSwipeEvent = () => {
    const swipeDataField = document.getElementById('swipeData');
    setDisabledFieldsState({...preSwipeDisabledState,cc_number: false, cardExpiration: false, cardName: false});
    hostedCardFields?.enableIframeInput();
    swipeDataField.blur();
  };

  const handleSwipeEventCancel = useCallback(() => {
    handleUnsuccessfulSwipeEvent();
    setSwiperState(swiperStates.initial);
  }, [preSwipeDisabledState]);

  const handleSwipeEventFail = () => {
    setSwiperState(swiperStates.error);
    handleUnsuccessfulSwipeEvent();
  };
  const restoreDisabledStateAfterSwipeSuccess = () => { setDisabledFieldsState({...preSwipeDisabledState, cc_number: true, cardExpiration: true, cardName: true}); };

  const handleSwipeEventSuccess = data => {
    const nameData = data[3]?.match(/^([\w ]*)\/([\w ]*)/),
      track1 = 'B' + data[1],
      track2 = data[8],
      expMonth = parseInt(data[5]) < 10 ? `0${parseInt(data[5])}` : parseInt(data[5])+'',
      expYear = `20${parseInt(data[4])}`,
      cardNumber = data[2];
    let newCardState = {};
    if (nameData && nameData.length == 3){
      newCardState.cardName = nameData[2] == null ? '' : `${nameData[2].trim()} ${nameData[1]}`;
    } else {
      newCardState.cardName = '';
    }
    newCardState.cardExpiration = `${expMonth}/${expYear}`;
    restoreDisabledStateAfterSwipeSuccess();
    setCardState({ ...cardState, ...newCardState });
    hostedCardFields?.setCardIframeInputText(cardNumber);
    setSwiperState({...swiperStates.success, track1: track1, track2: track2});
    setTimeout(() => {
      hostedCardFields?.focusIframeInput('#cvv');
    }, 200);
  };

  const handleSwipeEventAttempt = () => {
    const swipeDataField = document.getElementById('swipeData');
    setFormDisabledOnSwipe();
    setSwiperState(swiperStates.waiting);
    hostedCardFields?.disableIframeInput('#cc_number');
    swipeDataField?.focus();
  };

  const handleSwipeButtonClick = () => {
    if (!swiperState.isSwiping) {
      handleSwipeEventAttempt();
    } else if(swiperState.isSwiping) {
      handleSwipeEventCancel();
    }
  };

  const clearHiddenSwipeField = f => {f.value = '';};

  const handleSwipeEvent = e => {
    if(swiperState.isSwiping) {
      const value = e.target.value;
      if (e?.keyCode == 13) {
        e.preventDefault();
        const data = value.match(/^%B((\d{0,19})\^([^^]{2,26})\^(\d{2})(\d{2})(\d{3})(.*))\?;((\d{0,19})[=D](\d{2})(\d{2})(\d{3})(.*))\?$/);
        if (data) {
          handleSwipeEventSuccess(data);
          clearHiddenSwipeField(e.target);
        } else {
          handleSwipeEventFail();
          clearHiddenSwipeField(e.target);
        }
      }
    }
  };

  return (
    <div id="swiper" className="swiper">
      <div
        id="swipeButton"
        className="swipe-text"
        onClick={handleSwipeButtonClick}
        data-options={options}>
        <span className={`${swiperState.swipeText === 'Swipe Error!' ? 'swipe-error' : ''}`}>{swiperState.swipeText}</span>
        <span className="swipe-button">{swiperState.buttonText}</span>
      </div>
      <input type="text" name="swipeData" id="swipeData" data-testid="swipeData" className="swipe-data-field" onKeyDown={handleSwipeEvent} />
    </div>
  );
});

SwiperComponent.displayName = 'SwiperComponent';

SwiperComponent.propTypes = {
  resetForm: PropTypes.func,
  options: PropTypes.object,
  swiperState: PropTypes.object,
  setSwiperState: PropTypes.func,
  cardState: PropTypes.object,
  setCardState: PropTypes.func,
  waitingSwiperState: PropTypes.object,
  errorSwiperState: PropTypes.object,
  hostedCardFields: PropTypes.object,
  tabsState: PropTypes.array,
  disabledFieldsState: PropTypes.object,
  setDisabledFieldsState: PropTypes.func
};

export default SwiperComponent;
