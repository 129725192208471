import { paymentPageErrors as errors} from './constants';
import { rollbarLog, isNumber } from '../utils';
import { getCentsFromDollars, roundNumber } from '../monetaryUtils';

//public
const initializePaymentPageSurchargeFromProps = (type,amount, percent, centsAmountDue) => {
  let surcharge;
  if(type==='flat') {
    if(isNumber(Number(amount))) {
      surcharge= getCentsFromDollars(amount);
    } else {
      rollbarLog('Payment Page', 'Flat surcharge is enabled on account but amount is not set or isNaN.');
    }
  } else if(type==='percent'){
    surcharge = calculatePercentSurcharge(percent, centsAmountDue);
  }
  return surcharge;
};

const calculatePercentSurcharge = (percent, amountDue, needsLogs=true) => {
  if( !isNumber(Number(percent)) ) {
    if(needsLogs) rollbarLog('Payment Page', 'Percent surcharge is enabled on account but percent is not set or isNaN.');
    return;
  }
  if(amountDue && Number(amountDue) > 0){
    //Percent is saved in db as a whole number. So 1.5% is 1.5
    const wholePercentNumber = percent * 100,
      centsSurcharge = (amountDue * wholePercentNumber) / 10000;
    return roundNumber(centsSurcharge);
  } else {
    return 0;
  }
};

const loadHostedFields = (
  merchant,
  accepts_cards,
  hostedFieldsCallback,
  accepts_banks,
  hosted_fields_config,
  cc_surcharge_enabled,
  setHostedEcheckFields,
  setHostedCardFields
) => {
  if(window.AffiniPay && window.AffiniPay.HostedFields) {
    initializeHostedFields(
      merchant,
      accepts_cards,
      hostedFieldsCallback,
      accepts_banks,
      hosted_fields_config,
      cc_surcharge_enabled,
      setHostedEcheckFields,
      setHostedCardFields
    );
  } else {
    window.onload = initializeHostedFields(
      merchant,
      accepts_cards,
      hostedFieldsCallback,
      accepts_banks,
      hosted_fields_config,
      cc_surcharge_enabled,
      setHostedEcheckFields,
      setHostedCardFields
    );
  }
};

const validateHostedFields = async (state, hostedCardFields, hostedEcheckFields, formErrors, setFormErrors, swiperState) => {
  let hostedFields, activeTab, track1, track2, result;
  const isCardTabActive = Object.prototype.hasOwnProperty.call(state, 'cardName'),
    isEcheckTabActive = Object.prototype.hasOwnProperty.call(state, 'echeckAccountType');
  if(isCardTabActive) {
    activeTab = 'card';
  } else if(isEcheckTabActive) {
    activeTab = 'echeck';
  }
  if(activeTab === 'card') {
    hostedFields = hostedCardFields;
    track1 = swiperState?.track1;
    track2 = swiperState?.track2;
  }else if (activeTab === 'echeck') {
    hostedFields = hostedEcheckFields;
  }
  if(!hostedFields || !hostedFields.getState()) {
    setFormErrors([...formErrors, errors.hostedFields.paymentInfo]);
    return false;
  }
  const hostedFieldsState = hostedFields.getState();
  if(hostedFieldsState.isReady) {
    let hostedFieldsPayload = {};
    if(activeTab === 'card') {
      hostedFieldsPayload = getHostedFieldCardPayload(state);
    } else if(activeTab === 'echeck') {
      hostedFieldsPayload = getHostedFieldEcheckPayload(state);
    }
    try{
      if(track1 || track2) {
        result = await hostedFields?.getPaymentTokenSwipe(hostedFieldsPayload, track1, track2);
      } else {
        result = await hostedFields?.getPaymentToken(hostedFieldsPayload);
      }
      if(!result){
        _handleTokenizationFailure(setFormErrors, 'Result is falsy');
        return false;
      } else {
        return result;
      }
    } catch (err) {
      _handleTokenizationFailure(setFormErrors, err);
      return false;
    }
  } else {
    setFormErrors([errors.hostedFields.fillPaymentInfo]);
    return false;
  }
};

//private
const _handleTokenizationFailure = (setFormErrors, err) => {
  setFormErrors([errors.hostedFields.refreshPage]);
  rollbarLog('Payment Page', `Hosted Fields failed to get token, err: ${err}, Messages: ${err.messages}`);
};

const initializeHostedFields = (
  merchant,
  accepts_cards,
  hostedFieldsCallback,
  accepts_banks,
  hosted_fields_config,
  cc_surcharge_enabled,
  setHostedEcheckFields,
  setHostedCardFields) => {
  const style = {
    padding: '0',
    height: '26px',
    width: '100%',
    'margin-top': '15px',
    'font-size': '14px',
    'box-shadow':'none'
  };
  const publicKey = merchant.auth_user;

  if(accepts_cards) {
    const creditCardFields = [{
      selector: '#cc_number',
      input: {
        type: 'credit_card_number',
        css: style
      }
    },
    {
      selector: '#cvv',
      input: {
        type: 'cvv',
        css: style
      }
    }];
    initializeLibrary(
      publicKey,
      creditCardFields,
      hostedFieldsCallback,
      'card',
      hosted_fields_config,
      cc_surcharge_enabled,
      setHostedEcheckFields,
      setHostedCardFields);
  }

  if(accepts_banks) {
    const echeckFields = [{
      selector: '#routingNumber',
      input: {
        type: 'routing_number',
        css: style
      }
    },
    {
      selector: '#accountNumber',
      input: {
        type: 'bank_account_number',
        css: style
      }
    }];
    initializeLibrary(
      publicKey,
      echeckFields,
      hostedFieldsCallback,
      'echeck',
      hosted_fields_config,
      cc_surcharge_enabled,
      setHostedEcheckFields,
      setHostedCardFields);
  }
};

const initializeLibrary = (
  publicKey,
  fields,
  callback,
  type,
  hosted_fields_config,
  cc_surcharge_enabled,
  setHostedEcheckFields,
  setHostedCardFields) => {
  let init = {publicKey: publicKey,
    fields: fields
  };
  for(let key in hosted_fields_config) {
    if(hosted_fields_config[key])
      init[key] = hosted_fields_config[key];
  }
  if(type==='card' && cc_surcharge_enabled){
    init.surchargeEnabled = cc_surcharge_enabled;
  }
  const hostedFields = window.AffiniPay?.HostedFields.initializeFields(init,callback);
  if(type==='echeck') {
    setHostedEcheckFields(hostedFields);
  }else if(type==='card'){
    setHostedCardFields(hostedFields);
  }
};

const getHostedFieldCardPayload = state => {
  const exp = state.cardExpiration,
    expMonth = exp.substring(0, exp.indexOf('/')),
    expYear = exp.substring(exp.indexOf('/') + 1);
  return {
    address1: state.cardBillingAddress,
    address2: state.cardBillingAddress2,
    city: state.cardBillingCity,
    country: state.cardBillingCountry,
    email: state.email,
    name: state.cardName,
    reference: state.reference,
    state: state.cardBillingState,
    postal_code: state.cardBillingZip,
    exp_year: expYear,
    exp_month: expMonth
  };
};

const getHostedFieldEcheckPayload = state => {
  let hostedFieldsPayload = {
    address1: state.echeckBillingAddress,
    address2: state.echeckBillingAddress2,
    city: state.echeckBillingCity,
    country: state.echeckBillingCountry,
    email: state.email,
    reference: state.reference,
    state: state.echeckBillingState,
    postal_code: state.echeckBillingZip,
    account_holder_type: state.echeckAccountOwnerType,
    account_type: state.echeckAccountType.toUpperCase()
  };
  if(state.echeckAccountOwnerType==='individual') {
    hostedFieldsPayload.given_name = state.echeckFirstName;
    hostedFieldsPayload.surname = state.echeckLastName;
  } else if(state.echeckAccountOwnerType==='business') {
    hostedFieldsPayload.name = state.echeckHolderName;
  }
  return hostedFieldsPayload;
};

const HostedFieldsHelper = {
  initLibrary: loadHostedFields,
  setStateFromProps: initializePaymentPageSurchargeFromProps,
  validate: validateHostedFields,
  calculatePercent: calculatePercentSurcharge
};

export default HostedFieldsHelper;