import React, {useState, useEffect} from 'react';
import './style.scss';
import {bool, func, oneOfType, string} from 'prop-types';

const APRadioButton = ({
  name,
  id,
  value,
  checked,
  onChange,
  text,
  labelClassNames,
  disabled
}) => {

  return (
    <label htmlFor={id} className={`ap-radio-label ${labelClassNames ? labelClassNames : ''}`}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        defaultChecked={!!checked}
        onChange={onChange}
        data-testid={value}
        disabled={disabled}
      />
      {text}
      <span className="ap-radio" />
    </label>
  );
};

APRadioButton.propTypes = {
  name: string.isRequired,
  id: string,
  value: oneOfType([string, bool]),
  checked: bool,
  onChange: func.isRequired,
  text: string,
  labelClassNames: string,
  disabled: bool
};

export const APControlledRadioButton = ({
  name,
  id,
  value,
  checked,
  onChange,
  text,
  labelClassNames
}) => {

  const [checkedState,setCheckedState] = useState(!!checked);

  useEffect(() => {
    setCheckedState(!!checked);
  }, [checked]);

  const handleChange = e => {
    setCheckedState(!checkedState);
    onChange(e);
  };

  return (
    <label htmlFor={id} className={`ap-radio-label ${labelClassNames ? labelClassNames : ''}`}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checkedState}
        onChange={handleChange}
      />
      {text}
      <span className="ap-radio" />
    </label>
  );
};

APControlledRadioButton.propTypes = {
  name: string,
  id: string,
  value: oneOfType([string, bool]),
  checked: bool,
  onChange: func,
  text: string,
  labelClassNames: string,
  radioPosition: string,
  disabled: bool
};

export default APRadioButton;
