import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line react/display-name
const FooterComponent = React.memo(({
  site,
  hasEcheck,
  cards
}) => {

  const logoPath = `/assets/${site}/powered-by-logo.png`;
  // eslint-disable-next-line react/display-name

  const images = cards?.map((el,i) => (
    <div
      key={i}
      className={`footer-card-icon ${el}`}
      role="img"
      alt={`${el} credit card`}
      aria-label="credit-card"
    ></div>
  ));

  return (
    <footer className="row" role="contentinfo">
      <div className="col-12 col-sm-2 text-center text-sm-right">
        <img src={logoPath} className="powered-logo" alt={`Powered by ${site}`} />
      </div>
      <div className="col-12 col-sm-4 text-center text-sm-right footer-disclaimer">
        Our system uses SSL encryption technology, which is the same technology banks use to protect sensitive information.
      </div>
      <div className="col-12 col-sm-6 text-center text-sm-right card-icons">
        {images}
        {hasEcheck && (
          <div
            className='footer-card-icon bank'
            role="img"
            alt="bank"
            aria-label="bank-icon"
          ></div>
        )}
      </div>
    </footer>
  );
});

FooterComponent.propTypes = {
  site: PropTypes.string,
  hasEcheck: PropTypes.bool,
  cards: PropTypes.array
};

export default FooterComponent;
