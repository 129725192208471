import React from 'react';
import { shallow, mount } from 'enzyme';
import ReportsDatepicker from './';

describe('Reports Datepicker Snapshot', () => {
  it('renders snapshot', () => {
    let component = shallow(<ReportsDatepicker {...getMockProps('standard')} />);
    expect(component).toMatchSnapshot();
  });
});

describe('ReportsDatepicker', () => {
  let component;

  const elementMock = { addEventListener: jest.fn() };
  jest.spyOn(document, 'getElementById').mockImplementation(() => elementMock);

  beforeEach(() => {
    component = mount(<ReportsDatepicker {...getMockProps('standard')} />);
  });

  afterEach(() => {
    component.unmount();
  });
  /*
  it('tests open/close', () => {
    const inputEl = component.find('.datepicker-input').at(0);
    const instance = component.instance();

    instance.handleInputClick();

    component.setState({showModal: false});

    inputEl.simulate('click');
    inputEl.simulate('click');

    expect(component.find('.APModal').hasClass('active')).toBeFalsy();
  });
  */
  it('tests that when you first open modal, the current year shows', () => {
    expect(component.find('.years').text()).toEqual('2020');
  });

  it('tests when clicking year at top, a list of years from start to current year display', () => {
    const year = component.find('.years').at(0);

    year.simulate('click');

    expect(component.find('.blocks').every('.years')).toBeTruthy();
  });

  it('tests initial value of input on page load', () => {
    const input = component.find('.datepicker-input').at(0);

    expect(input.prop('value')).toEqual('Jan-2020');
  });

  it('tests clicking left arrow will return the modal title as 2019 and currentYear state as 2019', () => {
    const arrow = component.find('.arrow-left').at(0);

    expect(component.state('currentYear')).toEqual(2020);

    arrow.simulate('click');

    expect(component.find('.years').text()).toEqual('2019');
    expect(component.state('currentYear')).toEqual(2019);
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'standard':
    return {
      startDate: '09/2019',
      endDate: '01/2020'
    };
  case 'merchantDate':
    return {
      dates: {
        startDate: {
          month: '09',
          year: '2019'
        },
        endDate: {
          month: '01',
          year: '2020'
        }
      }
    };
  default:
    return;
  }
};
