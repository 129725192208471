import React from 'react';
import { shallow } from 'enzyme/build';
import APStatementsRails from './APStatementsRails';

describe('APStatementsRails Component', () => {
  it('shallow snapshot render', () => {
    let component = shallow(<APStatementsRails statements={statementProps} />);
    expect(component).toMatchSnapshot();
  });
});

const statementProps = 
  [
    { year: 2020,
      month: 12,
      link: 'https://dec-statment-here'
    },
    { year: 2020,
      month: 11,
      link: 'https://nov-statement-here'
    },
    { year: 2020,
      month: 10,
      link: 'https://oct-statement-here'
    },
    { year: 2020,
      month: 9,
      link: 'https://sept-statement-here'
    },
    { year: 2020,
      month: 8,
      link: 'https://aug-statement-here'
    },
    { year: 2020,
      month: 7,
      link: 'https://jul-statement-here'
    },
    { year: 2020,
      month: 6,
      link: 'https://jun-statement-here'
    },
    { year: 2020,
      month: 5,
      link: 'https://may-statement-here'
    },
    { year: 2020,
      month: 4,
      link: 'https://apr-statement-here'
    },
    { year: 2020,
      month: 3,
      link: 'https://mar-statement-here'
    },
    { year: 2020,
      month: 2,
      link: 'https://feb-statement-here'
    },
    { year: 2020,
      month: 1,
      link: 'https://jan-statement-here'
    },
    { year: 2019,
      month: 9,
      link: 'https://sept-statement-here'
    }, 
    { year: 2019,
      month: 5,
      link: 'https://may-statement-here'
    }, 
    { year: 2015,
      month: 2,
      link: 'https://feb-statement-here'
    }
  ];