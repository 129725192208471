import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { APButton, APFormMessageBox } from 'affinipay-ui-library';
import { recordSignature } from '../../lib/paymentPage/services';
import { DineroDollarsFormat, DineroCentsFormat, roundNumber } from '../../lib/monetaryUtils';

function SignatureComponent({
  currency,
  signature,
  charge,
  text,
  onSignatureFailure,
  onSignatureSuccess
}) {

  const [type, setType] = useState('');
  const [signatureError, setSignatureError] = useState();
  const [chargeAmount, setChargeAmount] = useState('$0.00');

  useEffect(() => {
    if(signature) {
      switch (signature) {
      case 1:
        setType('');
        break;
      case 2:
        setType('jSig');
        break;
      case 3:
        setType('SigWeb');
        break;
      default:
        break;
      }
    }
    try {
      // eslint-disable-next-line no-undef
      $('#signature').jSignature();
    } catch(err) {
      onSignatureFailure('Failed to initialize signature');
      setSignatureError('Failed to load signature. Please refresh the page and try again.');
    }
    if( signature === 3 && window.onSign) {
      // eslint-disable-next-line no-undef
      onSign();
    }
  }, [signature]);

  useEffect(() => {
    if(charge) {
      if(charge?.amount?.original_value){ setChargeAmount(DineroDollarsFormat(charge?.amount?.original_value, currency)); }
      else if(charge?.amount?.fractional){ setChargeAmount(DineroCentsFormat(roundNumber(charge?.amount?.fractional), currency)); }
    }
  }, [charge]);

  const handleClearClick = e => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-undef
    $('#signature').jSignature('reset');
    setSignatureError();
  };

  const handleSubmitClick = e => {
    e.preventDefault();
    e.stopPropagation();
    let dataSignature;
    if(signature === 3 && window.onDone) {
      // eslint-disable-next-line no-undef
      dataSignature = onDone();
    } else {
      try {
        // eslint-disable-next-line no-undef
        dataSignature = $('#signature').jSignature('getData','native');
      } catch(err) {
        onSignatureFailure('Failed to get signature data');
        setSignatureError('Failed to record your signature. Please try Again.');
        return;
      }
    }
    if (dataSignature.length > 0 && dataSignature[0].x.length > 0) {
      const path = `/signatures/${charge.signature_token}`,
        data = {signature: JSON.stringify(dataSignature)};
      recordSignature(path, data).then(async res => {
        const json = await res.json();
        if(res.ok) {
          onSignatureSuccess(json, dataSignature);
          setSignatureError();
        } else {
          onSignatureFailure(Object.prototype.hasOwnProperty.call(json, 'error') ? json.error : 'Unexpected server error response. Check server logs');
          setSignatureError('Failed to record your signature. Please try Again.');
        }
      }).catch(err => {
        onSignatureFailure(`Fetch error: ${err.message}`);
        setSignatureError('Failed to record your signature. Please try Again.');
      });
    } else {
      onSignatureFailure('Failed to record a signature. Incorrect lengths');
      setSignatureError('Failed to record your signature. Please try Again.');
      return;
    }
  };

  return (
    <div role="main">
      <div data-testid="signature" className='row'>
        <div className='col-4'>
          <strong>Name</strong>
        </div>
        <div className='col-8'>
          <span>{charge && charge.name || 'UNKNOWN'}</span>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <strong>Payment Method</strong>
        </div>
        <div className='col-8'>
          <span>{charge && charge.method.toString().toUpperCase()}</span>
        </div>
      </div>
      {charge.method === 'card' && (
        <div className='row'>
          <div className='col-4'>
            <strong>Card Type</strong>
          </div>
          <div className='col-8'>
            <span>{charge && charge.card_type}</span>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-4'>
          <strong>Payment Status</strong>
        </div>
        <div className='col-8'>
          <span>{charge && charge.status}</span>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <strong>Amount</strong>
        </div>
        <div className='col-8'>
          <span>{chargeAmount}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-12 signature-row">
          <div id="signature" className={type}></div>
        </div>
        {signatureError && (
          <div className="col-12">
            <APFormMessageBox type="error">
              {signatureError}
            </APFormMessageBox>
          </div>
        )}
        <div className="col-12">
          <APButton
            className="ap-secondary-button"
            onClick={handleClearClick}>
              Clear
          </APButton>
          <APButton
            className="ap-primary-button signature-btn"
            onClick={handleSubmitClick}>
              Done
          </APButton>
        </div>
        <div className="col-12 signature-legend">
          {text || 'By signing above, I confirm that I am an authorized user of the card being used for this transaction and understand and agree to the terms and conditions of this payment. I also agree to pay, and specifically authorize to charge my credit card for the services provided. I further agree that in the event my credit card becomes invalid, I will provide a new valid credit card upon request, to be charged for the payment of any outstanding balances owed.'}
        </div>
      </div>
    </div>
  );
}

SignatureComponent.propTypes = {
  currency: PropTypes.string,
  signature: PropTypes.number,
  charge: PropTypes.object,
  text: PropTypes.string,
  onSignatureFailure: PropTypes.func,
  onSignatureSuccess: PropTypes.func
};

export default SignatureComponent;
