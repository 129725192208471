/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import GraphVolume from './graph_volume';
import GraphPaymentSource from './graph_payment_source';

const ChartSection = ({ activeDateRange, graphVolume, graphSource  }) => {
  const { calculateRange, numberFormat } = AffiniPay.DashboardUtils;
  return (
    <section className="col-xs-12 dashboard-charts clearfix">
      <div className="row">
        <div className="col-sm-6" id="transaction_volume_chart">
          <GraphVolume data={ graphVolume } activeDateRange={ activeDateRange } />
        </div>
        <div className="col-sm-6" id="payment_source_chart">
          <GraphPaymentSource data={ graphSource } activeDateRange={ activeDateRange } />
          <div id="payment_source_summary" className="source-summary">
            <span id="transaction_count" className="source-summary-count">
              { numberFormat(calculateRange(graphSource).numTransactions, 0) }
            </span><br />
            Transactions
          </div>
        </div>
      </div>
    </section>
  );
};


ChartSection.propTypes = {
  activeDateRange: PropTypes.string,
  graphVolume: PropTypes.object,
  graphSource: PropTypes.array
};

export default ChartSection;