import React from 'react';
import TrustIcon from '../../components/icons/TrustIcon';

const attrNameMap = {
  'email_address': 'Email Address',
  'amount': 'Amount',
  'bank_account_id': 'Deposit Account',
  'body': 'Message Body',
  'subject': 'Subject Line'
};

/*
  Polyfill of Object.keys
  taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
 */
export const objectEntries = (obj) => {
  let ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i); // preallocate the Array
  while (i--) {
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  }

  return resArray;
};

export const errorToFullMessage = (error) => {
  let attr = error[0];
  let messages = error[1].join(', ');
  let titleizedAttr = attrNameMap[attr] || titleize(attr);
  return [titleizedAttr, messages].join(' ');
};

export const titleize = (string) => {
  const stringToUpperCase = string?.charAt(0).toUpperCase();
  const stringToLowerCase = string?.substr(1).toLowerCase();

  if (string) {
    return (`${stringToUpperCase}${stringToLowerCase}`).replace(/[_-]/g, ' ');
  } else{
    return '';
  }
};

export const navigateToQuickBillsIndex = () => {
  window.location = '/quick_bills';
};

export const navigateToInvoice = (id) => {
  window.location = `/quick_bills/${id}`;
};

export const navigateToEditInvoice = (id) => {
  window.location = `/quick_bills/${id}/edit`;
};

export const navigateToTransaction = (id) => {
  window.location = `/transactions/${id}`;
};

export const formatPresentmentStatus = (invoice) => {
  let presentmentStatus = invoice.presentmentStatus;

  if (typeof(presentmentStatus) !== 'undefined') {
    return presentmentStatus?.charAt(0).toUpperCase() + presentmentStatus?.slice(1);
  }
};

// TODO: remove this global dependency
/* global moment */
export const formatDaysOut = (timestamp) => {
  const currentDate = moment.utc();
  const invoiceDate = moment.utc(timestamp).startOf('day');
  return currentDate.diff(invoiceDate, 'days');
};

export const daysOutText = (timestamp) => {
  let daysOut = formatDaysOut(timestamp);
  let days = 'Days';

  if (daysOut === 1) {
    days = 'Day';
  }

  return [daysOut, days]?.join(' ');
};

// TODO: consolidate with the code on lib/notify.js
/* global PNotify */
export const displayNotice = (type, title, message) => {
  const PNotifyStackBarTop = {
    dir1: 'down',
    dir2: 'right',
    push: 'bottom',
    firstpos1: 5,
    firstpos2: false,
    top: 0,
    spacing1: 0,
    spacing2: 0
  };

  new PNotify({
    addclass: 'stack-bar-top',
    sticker: false,
    width: false,
    stack: PNotifyStackBarTop,
    type: type,
    title: title,
    text: message,
    buttons: {
      sticker: false
    }
  });
};

export const paymentTypes = {
  Cash: 'cash',
  Check: 'check',
  CreditCard: 'credit_card',
  Other: 'other'
};

export const firstSuccessfulTransactionalPayment = (invoicePayments) => {
  // if this needs polyfill, it will be fixed when BA-41 is resolved
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
  return invoicePayments?.find((ip) => {
    let payment = ip.payment;
    return payment.transactionId && ['authorized', 'completed']?.includes(payment.status);
  });
};

export const CreatedEventName = 'quick_bill.created';

// https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
export const dispatchQuickBillCreatedEvent = () => {
  let event;
  try {
    event = new Event(CreatedEventName);
  } catch(e) {
    // We're on IE :( so use the old, deprecated way
    event = document.createEvent('Event');
    event.initEvent(CreatedEventName, true, true);
  }

  document.dispatchEvent(event);
};

export const renderOptionWithIcon = (type, text) => {
  const optionIcons = {
    'trust': <TrustIcon className="bank-account-trust-icon" title={`Trust Account Icon`} size={14}/>
  };
  return (
    <span className="bank-account-option">
      {optionIcons[type]}
      {text}
    </span>
  );
};

export const formatBankAccountOptions = (accounts, initialOption) => {
  const options = accounts?.map((account) => ({
    value: account?.xid || '',
    text: account.name,
    ...(account.trust && { element: renderOptionWithIcon('trust', account.name) })
  }));
  const output = initialOption ? [initialOption] : [];
  return [...output, ...options];
};

export const hasEcheckEnabled = account => account?.achLimit !== null;

export const isAmountOverLimit = (limit, amount) => Number(amount) > limit;
