import React from 'react';
import { shallow } from 'enzyme';
import RecurringChargeAmount from './RecurringChargeAmount';

describe('RecurringChargeAmount Component', () => {
  it('shallow render', () => {
    const mockProps = getMockProps('new'),
      component = shallow(<RecurringChargeAmount {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'new':
    return {
      amount: null,
      isComplete: false
    };
  case 'weekly':
    return {
      amount: '55',
      isComplete: false
    };
  default:
    return;
  }
};
