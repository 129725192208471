import React from 'react';
import PropTypes from 'prop-types';

const IconCaretDown = ({scope = 'default', size = 36, fill='#000000', width='auto', viewHeight = 0}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      className={`icon-caret-up ${scope}`}
      viewBox={`0 ${viewHeight} ${size} ${size}`}
      fill={fill}
      width={width}
    >
      <polygon points='18 29.8 0 11.8 5.6 6.2 18 18.7 30.4 6.2 36 11.8 18 29.8' />
    </svg>
  );
};

IconCaretDown.propTypes = {
  scope: PropTypes.string,
  size: PropTypes.number,
  fill: PropTypes.string,
  width: PropTypes.string,
  viewHeight: PropTypes.number
};

export default IconCaretDown;
