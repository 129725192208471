import React, { useEffect, useState } from 'react';
import { array, bool, func, string } from 'prop-types';
import {
  APButton,
  APFormMessageBox,
  APInputMonetary,
  APInputText,
  APSelect,
  APTextArea
} from 'affinipay-ui-library';
import ContactSearchField from './ContactSearchField';
import QuickBillAttachments from './QuickBillAttachments';
import IconEQB from '../icons/icon-eqb';
import {
  errorToFullMessage,
  objectEntries,
  formatBankAccountOptions,
  hasEcheckEnabled,
  isAmountOverLimit
} from '../utils';
import { characterLimits, defaultLimit } from '../constants';
import client from '../../../lib/ajax';
import { sendEvent, EventNames } from '../../../lib/intercom';
import { isEmailPattern } from '../../../lib/utils';

export const checkValuesMissing = formValues => {
  const requiredFields = ['amount', 'bankAccountId', 'emailAddress', 'emailBody', 'emailSubject'];
  return requiredFields.some(key => !formValues[key]);
};

const QuickBillForm = ({
  attachmentDisabled = false,
  bankAccounts = [],
  body,
  merchantId,
  referenceLabel = 'Reference',
  siteSupportPhone,
  subject,
  onCancel,
  onSuccess
}) => {
  const initialFormValues = {
    amount: '',
    attachments: [],
    bankAccount: undefined,
    bankAccountId: '',
    emailAddress: '',
    emailBody: body,
    emailSubject: subject,
    firstName: '',
    lastName: '',
    reference: ''
  };
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [showEcheckWarning, setShowEcheckWarning] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectedContact, setSelectedContact] = useState();
  const [validationErrors, setValidationErrors] = useState({emailAddress: ''});
  const [errors, setErrors] = useState([]);
  const [charLimitValidation, setCharLimitValidation] = useState({
    emailAddress: false,
    firstName: false,
    lastName: false,
    reference: false,
    emailBody: false,
    emailSubject: false
  });

  useEffect(() => {
    const fieldLimits = Object.keys(charLimitValidation);
    const hasLimitRestrictions = fieldLimits.some(field => charLimitValidation[field]);

    if (!validationErrors.emailAddress) {
      setIsDisabled(checkValuesMissing(formValues) || hasLimitRestrictions);
    }
  }, [formValues, charLimitValidation]);

  useEffect(() => {
    const {amount, bankAccount} = formValues;

    if (hasEcheckEnabled(bankAccount)) {
      const limit = Number(bankAccount?.achLimit) > 0 ? bankAccount.achLimit : defaultLimit;
      setShowEcheckWarning(bankAccount && isAmountOverLimit(limit, amount));
      return;
    }
    setShowEcheckWarning(false);
  }, [formValues.amount, formValues.bankAccount]);

  useEffect(() => {
    let updatedFormValues = {...formValues};
    let firstName, lastName;

    if (selectedContact?.name) {
      const splitName = selectedContact.name?.split(' ');
      firstName = splitName[0];
      lastName = splitName[1];
    }

    if (!formValues.firstName && firstName) {
      updatedFormValues.firstName = firstName;
    }

    if (!formValues.lastName && lastName) {
      updatedFormValues.lastName = lastName;
    }
    setFormValues(updatedFormValues);
  }, [selectedContact]);

  const handleFormChange = (event, lengthValidation) => {
    const {target: {name, value}} = event;
    if (name === 'amount') {
      const amountValue = value.replace(/,/g, '');
      setFormValues({...formValues, amount: amountValue});
      return;
    }
    if (name === 'emailAddress') {
      const trimmedValue = value.trim();
      setFormValues({...formValues, [name]: trimmedValue});

      if (validationErrors[name] === 'must be a valid email' && isEmailPattern(trimmedValue)) {
        setValidationErrors({...validationErrors, [name]: ''});
      }
    }

    setCharLimitValidation({...charLimitValidation, [name]: lengthValidation === 'fail'});
    setFormValues({...formValues, [name]: value});
  };

  const handleContactSelection = ({selectedItem}) => {
    setSelectedContact(selectedItem);
  };

  const handleBankAccountChange = ({target: {value}}) => {
    const bankAccount = bankAccounts.find(account => account.xid === value);
    setFormValues({
      ...formValues,
      bankAccountId: value,
      bankAccount
    });
  };

  const sendStats = attachments =>  {
    attachments.length > 0
      ? sendEvent(EventNames.quickBillCreatedWithAttachments, {count: attachments.length})
      : sendEvent(EventNames.quickBillCreated);
  };

  const getPayload = () => {
    const {
      amount,
      attachments,
      bankAccountId: bank_account_id,
      emailAddress: email_address,
      emailBody: body,
      emailSubject: subject,
      firstName: first_name,
      lastName: last_name,
      reference
    } = formValues;

    const payloadResult = {
      amount,
      bank_account_id,
      body,
      email_address,
      first_name,
      last_name,
      merchant_id: merchantId,
      reference,
      subject
    };

    if (attachments?.length > 0) payloadResult.attachment_ids = attachments.map(({id})=> id);

    return payloadResult;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSending(true);
    if (!isEmailPattern(formValues.emailAddress)) {
      setValidationErrors({
        ...validationErrors,
        emailAddress: 'must be a valid email'
      });

      setIsDisabled(true);
      setIsSending(false);
      return;
    }

    const response = await client.post('/quick_bills', getPayload());
    const {status} = response;
    const json = await response.json();

    const successAction = () => {
      sendStats(formValues.attachments);
      onSuccess();
      setIsSending(false);
    };

    const unproccessableAction = () => {
      if ('errors' in json) {
        setErrors(objectEntries(json.errors).map(error => errorToFullMessage(error)));
        setIsAlertVisible(true);
        setIsSending(false);
      }
    };

    const serverErrorAction = () => {
      setErrors([
        'An error occurred, please try again.',
        `If the error reoccurs, contact our Support team at ${siteSupportPhone}.`
      ]);
      setIsAlertVisible(true);
      setIsSending(false);
    };

    const statusActionMap = new Map([
      [201, successAction],
      [422, unproccessableAction],
      [500, serverErrorAction]
    ]);

    const action = statusActionMap.get(status);
    if (typeof action === 'function') {
      action();
    } else {
      setIsAlertVisible(true);
      setErrors([
        'An error occurred saving the Quick Bill, please try again.',
        `If the error reoccurs, contact our Support team at ${siteSupportPhone}.`
      ]);
      setIsSending(false);
    }
  };

  const getButtonLabel = attachments => attachments.length > 0 ? 'Send With Attachments' : 'Send';

  return (
    <form className="quick-bill-form">
      {isAlertVisible &&
        <APFormMessageBox
          type="error"
          header="Error saving new Quick Bill information"
          messages={errors}
          showCloseButton={true}
          onClose={() => setIsAlertVisible(true)}
        />
      }
      <ContactSearchField
        error={validationErrors.emailAddress}
        onChange={handleFormChange}
        onSelect={handleContactSelection}
      />
      <div className="quick-bill-form-side-by-side">
        <APInputText
          characterLimits={characterLimits.name}
          id="contactFirstName"
          label="First Name"
          name="firstName"
          showFieldRequirement={true}
          value={formValues.firstName}
          onChange={handleFormChange}
        />
        <APInputText
          characterLimits={characterLimits.name}
          id="contactLastName"
          label="Last Name"
          name="lastName"
          showFieldRequirement={true}
          value={formValues.lastName}
          onChange={handleFormChange}
        />
      </div>
      <div className="quick-bill-form-side-by-side">
        <APSelect
          label="Deposit Account"
          options={formatBankAccountOptions(bankAccounts)}
          placeholderText="Select Deposit Account"
          onChange={handleBankAccountChange}
          name="bankAccountId"
          required={true}
          value={formValues.bankAccountId}
        />
        <APInputMonetary
          label="Payment Amount"
          id="paymentAmount"
          name="amount"
          required={true}
          value={formValues.amount}
          onChange={handleFormChange}
          currencySymbol={'$'}
        />
      </div>
      {showEcheckWarning &&
        <APFormMessageBox
          type="warning"
          header="Payment amount exceeds single eCheck transaction limit."
          messages={[`This Quick Bill amount can be paid with card only. To increase your eCheck limit, please contact our Support team at ${siteSupportPhone}.`]}
        />
      }
      <APInputText
        characterLimits={characterLimits.reference}
        id="reference"
        label={referenceLabel}
        name="reference"
        showFieldRequirement={true}
        value={formValues.reference}
        onChange={handleFormChange}
      />
      <APInputText
        characterLimits={characterLimits.subject}
        id="subject"
        label="Subject Line"
        onChange={handleFormChange}
        name="emailSubject"
        required={true}
        value={formValues.emailSubject}
      />
      <APTextArea
        characterLimits={characterLimits.body}
        id="body"
        label="Custom Email Text"
        name="emailBody"
        onChange={handleFormChange}
        rows={8}
        required={true}
        value={formValues.emailBody}
      />
      <div className="quick-bill-subject-message-note">
        <p>Note: A secure, custom payment link will be embedded in the email message.</p>
        <p>Tip: Customize the default subject line and email text in Settings.</p>
      </div>
      {
        !attachmentDisabled && (
          <QuickBillAttachments
            formValues={formValues}
            setFormValues={setFormValues}
            setIsDisabled={setIsDisabled}
            checkValuesMissing={checkValuesMissing}
            merchantId={merchantId}
          />
        )
      }
      <div className="quick-bill-buttons">
        <APButton
          className="ap-button ap-ghost-button trackable"
          onClick={onCancel}
          data-action="click"
          data-step="QuickBill"
          data-label="Cancel"
          type="button"
        >
          Cancel
        </APButton>
        <APButton
          onClick={handleSubmit}
          className="ap-button ap-primary-button trackable"
          disabled={isDisabled || isSending}
          type="submit"
          data-action="click"
          data-step="QuickBill"
          data-label={getButtonLabel(formValues.attachments)}
        >
          <IconEQB fill="#fff" size="24" />
          {getButtonLabel(formValues.attachments)}
        </APButton>
      </div>
    </form>
  );
};

QuickBillForm.propTypes = {
  attachmentDisabled: bool,
  bankAccounts: array,
  subject: string.isRequired,
  body: string.isRequired,
  referenceLabel: string,
  merchantId: string.isRequired,
  onSuccess: func.isRequired,
  onCancel: func.isRequired,
  siteSupportPhone: string
};

export default QuickBillForm;
