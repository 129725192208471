import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { APButton, APAlertModal, APInputText, APModal } from 'affinipay-ui-library';
import AddFeatureGateForm from './AddFeatureGateForm';
import DeleteFeatureForm from './DeleteFeatureForm';
import { enableFeature, disableFeature } from './helpers/services';
import { strings } from './helpers/constants';

const FeatureOptions = ({
  tableState,
  setTableState,
  feature,
  fetchData,
  featureEnabledForAllActors,
  setFeatures,
  setSelectedFeature
}) => {
  const [showAddGateModal, setShowAddGateModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [isEnableAllModalShowing, setIsEnableAllModalShowing] = useState(false);
  const [isDisableAllModalShowing, setIsDisableAllModalShowing] = useState(false);

  const handleEnableOrDisableFeature = async (e) => {
    const method = e.target.dataset.method;
    const apiCall = method === 'disable' ? disableFeature : enableFeature;
    setIsSubmitting(true);
    try {
      const response = await apiCall(feature);
      if (response.ok) {
        setIsSubmitting(false);
        fetchData();
      }
      else {
        const json = await response.json();
        setTableState({error: json.error});
        setIsSubmitting(false);
      }
    }
    catch(e) {
      setTableState({error: method === 'disable' ? strings.disableFeatureError : strings.enableFeatureError});
      setIsSubmitting(false);
    }

    setIsEnableAllModalShowing(false);
    setIsDisableAllModalShowing(false);
  };

  const handleFilterChange = (e) => {
    const val = e.target.value;
    setTableState({nameFilter: val});
  };

  const filterTable = (e) => {
    e.preventDefault();
    if (tableState.page === 1) {
      fetchData();
    }
    else {
      setTableState({page: 1});
    }
  };

  const toggleDeleteFeatureModal = () => setIsDeleteModalShowing(!isDeleteModalShowing);
  const toggleAddGateModal = () => setShowAddGateModal(!showAddGateModal);
  const toggleEnableAllModal = () => setIsEnableAllModalShowing(!isEnableAllModalShowing);
  const toggleDisableAllModal = () => setIsDisableAllModalShowing(!isDisableAllModalShowing);

  return (
    <>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='row'>
            <div className='col-sm-8'>
              {featureEnabledForAllActors ?
                <span>{strings.featureFullyEnabled}</span> :
                <span>{strings.recordCountText(tableState.totalRecordCount)}</span>
              }
            </div>
            <div className='col-sm-4'>
              {!featureEnabledForAllActors &&
                <APButton
                  className='ap-secondary-button'
                  onClick={toggleAddGateModal}
                  disabled={isSubmitting}
                >
                  Add Actor
                </APButton>
              }
            </div>
          </div>
          {!featureEnabledForAllActors &&
            <div className='row'>
              <form onSubmit={filterTable} className='flex-bottom'>
                <div className='col-sm-8'>
                  <APInputText
                    disabled={isSubmitting}
                    id='name_filter'
                    label={strings.filterInputLabel}
                    onChange={handleFilterChange}
                    parentClass="admin"
                    value={tableState.nameFilter}
                  />
                </div>
                <div className='col-sm-4'>
                  <APButton
                    type='submit'
                    className='ap-secondary-button'
                    disabled={isSubmitting}
                  >
                    Filter
                  </APButton>
                </div>
              </form>
            </div>
          }
        </div>
        <div className='col-sm-6'>
          <div className='row'>
            <div className='col-sm-12 options-button-col'>
              {!featureEnabledForAllActors &&
                <APButton
                  onClick={toggleEnableAllModal}
                  disabled={isSubmitting}
                >
                  {strings.enableButtonText}
                </APButton>
              }
              {tableState.totalRecordCount > 0 &&
                <APButton
                  className='ap-negative-button'
                  onClick={toggleDisableAllModal}
                  disabled={isSubmitting}
                >
                  {strings.disableButtonText}
                </APButton>
              }
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 text-center'>
              <APButton
                className='ap-negative-button'
                disabled={isSubmitting}
                onClick={toggleDeleteFeatureModal}
              >
                {strings.deleteButtonText}
              </APButton>
            </div>
          </div>
        </div>
      </div>
      <APModal
        isActiveInitial={showAddGateModal}
        onClose={toggleAddGateModal}
        headerText={strings.addGateModalTitle}
        className='feature-modal'
      >
        <div className="container-fluid">
          <AddFeatureGateForm
            feature={feature}
            closeModal={toggleAddGateModal}
            fetchData={fetchData}
          />
        </div>
      </APModal>

      <APModal
        isActiveInitial={isDeleteModalShowing}
        onClose={toggleDeleteFeatureModal}
        headerText={strings.deleteFeatureModalTitle}
        className='feature-modal'
      >
        <div className="container-fluid">
          <DeleteFeatureForm
            feature={feature}
            setFeatures={setFeatures}
            setSelectedFeature={setSelectedFeature}
            closeModal={toggleDeleteFeatureModal}
          />
        </div>
      </APModal>

      <APAlertModal
        className='enable-all-confirmation-modal'
        isActiveInitial={isEnableAllModalShowing}
        headerText={strings.enableDisableFeatureModalTitle}
        bodyText={strings.enableAllFeatureModalText}
        onClose={toggleEnableAllModal}
        type='error'
        footer={
          <div className='enable-all-confirmation-modal-footer'>
            <APButton
              onClick={toggleEnableAllModal}
              className='ap-ghost-alert-button'
              disabled={isSubmitting}
            >
              {strings.cancelButtonText}
            </APButton>
            <APButton
              onClick={handleEnableOrDisableFeature}
              dataset={{'data-method': 'enable'}}
              className='ap-critical-button delete-button'
              disabled={isSubmitting}
            >
              {strings.yesEnableButtonText}
            </APButton>
          </div>
        }
      />

      <APAlertModal
        className='disable-all-confirmation-modal'
        isActiveInitial={isDisableAllModalShowing}
        headerText={strings.enableDisableFeatureModalTitle}
        bodyText={strings.disableAllFeatureModalText}
        onClose={toggleDisableAllModal}
        type='error'
        footer={
          <div className='disable-all-confirmation-modal-footer'>
            <APButton
              onClick={toggleDisableAllModal}
              className='ap-ghost-alert-button'
              disabled={isSubmitting}
            >
              {strings.cancelButtonText}
            </APButton>
            <APButton
              onClick={handleEnableOrDisableFeature}
              className='ap-critical-button delete-button'
              dataset={{'data-method': 'disable'}}
              disabled={isSubmitting}
            >
              {strings.yesDisableButtonText}
            </APButton>
          </div>
        }
      />

    </>
  );
};

FeatureOptions.propTypes = {
  tableState: PropTypes.object,
  setTableState: PropTypes.func,
  feature: PropTypes.object,
  fetchData: PropTypes.func,
  featureEnabledForAllActors: PropTypes.bool,
  setFeatures: PropTypes.func,
  setSelectedFeature: PropTypes.func
};

export default FeatureOptions;
