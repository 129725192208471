import React, { useReducer, useState } from 'react';
import { bool, string, number, array, object } from 'prop-types';
import {
  APFormMessageBox,
  APInputText,
  APInputMask,
  APComboBoxEmail,
  APCheckbox,
  APButton,
  APRadioButton,
  APSpinner
} from 'affinipay-ui-library';
import WarningIcon from './warning-icon';
import { createUser, updateUser, reinviteUser } from './helpers/services';
import './styles.scss';

const userReducer = (state, action) => {
  const { type, value, accounts, name } = action;

  switch(type) {
  case 'update_input':
    return {...state, [name]: value};
  case 'add_ability':
    if(value === 'admin') {
      return {...state, abilities: [value]};
    }
    return {...state, abilities: [...state.abilities, value]};
  case 'remove_ability':
    return {...state, abilities: state.abilities.filter(ability => ability !== value)};
  case 'add_account':
    return {...state, accounts: [...state.accounts, Number(value)]};
  case 'remove_account':
    return {...state, accounts: state.accounts.filter(account => account !== Number(value))};
  case 'restrict_access':
    return {...state, unrestrict_access: 0, accounts: accounts.map(account => account.id)};
  case 'unrestrict_access':
    return {...state, unrestrict_access: 1, accounts: []};
  case 'restrict_transactions':
    return {...state, restrict_transactions: value};
  default:
    return state;
  }
};

const UserForm = ({
  show_lawpay_plus_content,
  show_lawpay_plus_content_for_new_record,
  show_lawpay_plus_permissions_warning,
  pending_reconfirmation,
  unconfirmed_email,
  user_abilities,
  display_abilites,
  user_abilities_for_check_list,
  non_entitlement_user_abilities_for_check_list,
  accounts,
  visible_account_ids,
  unrestrict_access,
  restrict_transactions,
  lawpay_plus_monthly_fee,
  lawpay_plus_remaining_months_yearly_fee,
  lawpay_plus_yearly_fee,
  lawpay_label,
  settings_path,
  invited,
  phone,
  first_name,
  last_name,
  email,
  form_type,
  user_id,
  reinvite_user_path
}) => {

  const [state, dispatch] = useReducer(userReducer, {
    first_name,
    last_name,
    email,
    phone,
    abilities: user_abilities,
    accounts: accounts.filter(account => visible_account_ids.includes(account.id)).map(account => account.id),
    unrestrict_access: unrestrict_access ? 1 : 0,
    restrict_transactions: restrict_transactions
  });
  const [lpp_license, setLppLicense] = useState(0);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    const data = {user: {...state}, lpp_license};
    const path = form_type === 'edit' ? `/users/${user_id}` : '/users';
    setLoading(true);
    if(data.lpp_license === 0 && show_lawpay_plus_content_for_new_record) {
      setErrors({...errors, lpp_license: 'lpp_license_required'});
      setLoading(false);
      return;
    }
    data.user.abilites_mask = data.user.abilities.map((a, idx) => 2**idx).sum;

    if(form_type === 'edit') {
      response = await updateUser(path, data);
    } else {
      response = await createUser(path, data);
    }

    if (response.status >= 200 && response.status <= 299) {
      setErrors(undefined);
      window.location.assign(`${window.location.origin}/${settings_path}`);
    } else {
      setErrors(await response.json());
      setLoading(false);
    }
  };

  const handleReinviteSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await reinviteUser(reinvite_user_path);
    if (response.status >= 200 && response.status <= 299) {
      setErrors(undefined);
      window.location.assign(`${window.location.origin}/${settings_path}`);
    } else {
      setErrors(await response.json());
      setLoading(false);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    window.location.assign(`${window.location.origin}/${settings_path}`);
  };

  return (
    <div className="user-form-container">
      {show_lawpay_plus_content_for_new_record &&
        (
          <div className='user-form-group lpp-info'>
            <div className='form-group-label col-sm-3'/>
            <div className='col-sm-9'>
              <APFormMessageBox>By adding a new user, you are purchasing a paid license with {lawpay_label}</APFormMessageBox>
            </div>
          </div>
        )
      }
      <form className={`user-form ${show_lawpay_plus_content || show_lawpay_plus_content_for_new_record ? 'lpp-form' : ''}`} onSubmit={invited ? handleReinviteSubmit : handleSubmit} >
        <div className='user-info user-form-group'>
          <div className='col-sm-3' />
          <div className='col-sm-9 col-xs-9'>
            <APInputText
              className="user-info-input"
              label="First name"
              name='first_name'
              value={state.first_name}
              required
              disabled={invited}
              error={errors && errors.first_name}
              onChange={({target: {value, name}}) => dispatch({type: 'update_input', value, name })}
            />
            <APInputText
              className="user-info-input"
              label="Last name"
              name='last_name'
              value={state.last_name}
              required
              disabled={invited}
              error={errors && errors.last_name}
              onChange={({target: {value, name}}) => dispatch({type: 'update_input', value, name })}
            />
            <APComboBoxEmail
              className="user-info-input"
              label='Email'
              name='email'
              value={state.email}
              required
              disabled={invited}
              error={errors && errors.email}
              onChange={({target: {value, name}}) => dispatch({type: 'update_input', value, name })}
            />
            {pending_reconfirmation && <p className='note'>{`Pending Confirmation: ${unconfirmed_email}`}</p>}
            <APInputMask
              className="user-info-input"
              label='Phone'
              name='phone'
              mask={['(',/[0-9]/, /[0-9]/, /[0-9]/, ')', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
              value={state.phone}
              disabled={invited}
              error={errors && errors.phone}
              onChange={({target: {value, name}}) => dispatch({type: 'update_input', value, name })}
            />
          </div>
        </div>
        <div className='ability-checkbox-group user-form-group'>
          <label className='col-sm-3 form-group-label'>User Abilities</label>
          <div className='col-sm-9 checkbox-group'>
            {show_lawpay_plus_permissions_warning && <p className='warning-label' data-testid='ability-warning'><b>These permissions apply to both LawPay and {lawpay_label}. E.g. a user with Administrator abilities in LawPay also has Administrator abilities in {lawpay_label}.</b></p>}
            {user_abilities_for_check_list.map((ability, idx) => {
              return (
                <div key={`user_ability_${idx}`} className='user-checkbox-container entitlement-abilities'>
                  <APCheckbox
                    key={`user_ability_${idx}`}
                    name={`user_ability_${ability}`}
                    text={`${display_abilites[ability]}`}
                    value={ability}
                    checked={state.abilities.includes(ability)}
                    disabled={(state.abilities.includes('admin') && ability !== 'admin') || invited}
                    disabledStyle={(state.abilities.includes('admin') && ability !== 'admin' || invited) && 'disabled-ability'}
                    onChange={({target: {checked, value}}) => dispatch({type: checked ? 'add_ability' : 'remove_ability', value})}
                  />
                </div>
              );
            })}
            {show_lawpay_plus_permissions_warning && <p className='warning-label non-entitlement-warning-label' data-testid='non-entitlement-warning'><b>These permissions have no equivalent in {lawpay_label}, therefore, the selections in LawPay do not impact what a user can do in {lawpay_label}.</b></p>}
            {non_entitlement_user_abilities_for_check_list.map((ability, idx) => {
              if(!show_lawpay_plus_permissions_warning && user_abilities_for_check_list.includes(ability)) {
                return;
              }
              return (
                <div key={`user_ability_${idx}`} className='user-checkbox-container'>
                  <APCheckbox
                    name={`user_ability_${ability}`}
                    text={display_abilites[ability]}
                    value={ability}
                    checked={state.abilities.includes(ability)}
                    disabled={(state.abilities.includes('admin') && ability !== 'admin') || invited}
                    disabledStyle={(state.abilities.includes('admin') && ability !== 'admin' || invited) && 'disabled-ability'}
                    onChange={({target: {checked, value}}) => dispatch({type: checked ? 'add_ability' : 'remove_ability', value})}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className='account-access-group user-form-group'>
          <label className='col-sm-3 form-group-label'>Account Access</label>
          <div className='col-sm-9 checkbox-group'>
            {show_lawpay_plus_permissions_warning && <p  className='warning-label' data-testid='account-access-warning'><WarningIcon /> <b>The LawPay “Account Access” permission has no equivalent in {lawpay_label}, therefore any user of {lawpay_label} can collect payments in all accounts used in {lawpay_label}, regardless of their LawPay permission below.</b></p>}
            <div className='user-checkbox-container'>
              <APCheckbox
                name='restrict_access'
                text="User can access all accounts"
                checked={state.unrestrict_access === 1}
                value={state.unrestrict_access}
                disabled={invited}
                onChange={
                  ({target: {checked}}) =>
                    dispatch({
                      type: checked ? 'unrestrict_access' : 'restrict_access',
                      ...(!checked && {accounts}),
                      accounts
                    })
                }
              />
            </div>
            {accounts.map((account) => {
              return (
                <div key={account.id} className='user-checkbox-container'>
                  <APCheckbox
                    name='accounts'
                    text={account.name}
                    checked={(state.unrestrict_access === 1 || visible_account_ids.includes(account.id))}
                    disabled={state.unrestrict_access === 1 || invited}
                    disabledStyle={(state.unrestrict_access === 1 || invited) && 'disabled-account'}
                    value={account.id}
                    onChange={
                      ({target: { checked, value}}) =>
                        dispatch({
                          type: checked ? 'add_account' : 'remove_account',
                          value,
                          accounts
                        })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className='transaction-access-group user-form-group'>
          <label className='col-sm-3 form-group-label transaction-access-label'>Transaction Access</label>
          <div className='col-sm-9'>
            {show_lawpay_plus_permissions_warning && <p  className='warning-label' data-testid='transaction-access-warning'><WarningIcon /> <b>The LawPay “Transaction Access” permission has no equivalent in {lawpay_label}, therefore any user of {lawpay_label} can access all transactions in {lawpay_label}, regardless of their LawPay permission below.</b></p>}
            <APRadioButton
              checked={!state.restrict_transactions}
              name='restrict_transactions'
              text='User can access any transaction'
              disabled={invited}
              onChange={() => dispatch({type: 'restrict_transactions', value: false})}
            />
            <APRadioButton
              checked={state.restrict_transactions}
              name='restrict_transactions'
              text='Restrict access to transactions created by this user'
              disabled={invited}
              onChange={() => dispatch({type: 'restrict_transactions', value: true})}
            />
            <p className='transaction-note'>{`Note: user will only be able to see transactions for the accounts selected in the 'Account Access' section above`}</p>
          </div>
        </div>
        {show_lawpay_plus_content_for_new_record && (
          <div className='user-checkbox-container user-form-group lawpay-license-container' >
            <label className={`col-sm-3 form-group-label ${errors && errors.lpp_license ? 'lawpay-license-error': ''}`}>{lawpay_label} License (Required)</label>
            <div className='col-sm-9 lawpay-license'>
              <APCheckbox
                name='lpp_license'
                text={
                  <span data-testid='lpp-license-warning'>
                    I understand that adding a new user to LawPay will require a {lawpay_label} license, and I&#39;ll be charged an additional <strong>${lawpay_plus_monthly_fee}/month/user</strong> for monthly plans, or for yearly plans: <b>${lawpay_plus_remaining_months_yearly_fee}/month/user</b> immediately for the remaining time on the annual plan, and then <b>${lawpay_plus_yearly_fee}/year/user</b>.
                  </span>
                }
                value={1}
                checked={lpp_license === 1}
                disabled={invited}
                onChange={({target: { checked, value }}) => {
                  if(checked) {
                    setLppLicense(Number(value));
                  } else {
                    setLppLicense(0);
                  }
                }}
                displayType='toggle'
              />
            </div>
          </div>
        )}
        <div className="submit-buttons user-form-group">
          <div className='col-sm-3 form-group-label' />
          <div className='col-sm-9 form-group-buttons admin'>
            <APButton className='ap-secondary-button back-button' onClick={handleBack} disabled={loading}>Back</APButton>
            <APButton className='ap-primary-button save-button' disabled={loading}>
              {loading ? <APSpinner className='user-submit-spinner'/> : `${invited ? 'Resend Invitation' : 'Save Changes'}`}
            </APButton>
          </div>
        </div>
        {(errors && !errors.lpp_license) && <APFormMessageBox type='error' messages={errors.full_messages} />}
      </form>
    </div>
  );
};

UserForm.propTypes = {
  show_lawpay_plus_content: bool,
  show_lawpay_plus_content_for_new_record: bool,
  show_lawpay_plus_permissions_warning: bool,
  pending_reconfirmation: bool,
  unconfirmed_email: string,
  user_abilities: array,
  display_abilites: object,
  user_abilities_for_check_list: array,
  non_entitlement_user_abilities_for_check_list: array,
  accounts: array,
  visible_account_ids: array,
  unrestrict_access: bool,
  restrict_transactions: bool,
  lawpay_plus_monthly_fee: number,
  lawpay_plus_remaining_months_yearly_fee: number,
  lawpay_plus_yearly_fee: number,
  lawpay_label: string,
  settings_path: string,
  invited: bool,
  phone: string,
  first_name: string,
  last_name: string,
  email: string,
  form_type: string,
  user_id: number,
  reinvite_user_path: string
};

export default UserForm;
