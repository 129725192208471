import React from 'react';
import { shallow, mount } from 'enzyme';
import Echeck from './';
import * as fetchLib from 'whatwg-fetch';

describe('Echeck Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(<Echeck {...getMockProps('standard')} />);

    component.find('Success').prop('onClose')();

    expect(component).toMatchSnapshot();
  });

  it('checks onDecline on Terms component', () => {
    const component = mount(<Echeck {...getMockProps('standard')} />);

    component.setState({
      interested: true,
      submitting: false,
      success: false,
      errors: [1, 2]
    });

    const safeSendEventSpy = jest.spyOn(component.instance(), 'safeSendEvent');
    component.find('Terms').prop('onDecline')();
    expect(safeSendEventSpy).toHaveBeenCalled();

    component.unmount();
  });

  it('mount for async optin', async () => {
    const component = mount(<Echeck {...getMockProps('standard')} />);

    component.setState({
      interested: true,
      submitting: false,
      success: false
    });

    jest.spyOn(fetchLib, 'fetch').mockImplementation(() =>
      Promise.resolve({
        status: 204,
        response: true,
        headers: {
          get: jest.fn(() => {
            return { indexOf: jest.fn(() => 2) };
          })
        }
      })
    );
    await component.find('Terms').prop('onAccept')();

    expect(component.state('interested')).toEqual(true);

    fetchLib.fetch.mockRestore();
    component.unmount();
  });

  it('check for handleFailure', async () => {
    const component = mount(<Echeck {...getMockProps('standard')} />),
      instance = component.instance();
    try {
      await instance.handleFailure([{ foo: 'bar' }]);
    } catch (e) {
      expect(e).toEqual({ errors: [{ foo: 'bar' }] });
    }
    component.unmount();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'standard':
    return {
      status: { alreadyOptedIn: true },
      brand: { identifier: 'identifier' },
      paths: { optin: 'foo' },
      assets: {
        echeckImage: 'echeck_opt_in/echeck-lp-graphic.jpg',
        echeckImageCropped: 'echeck_opt_in/echeck-lp-graphic-crop.jpg',
        downloadIcon: 'echeck_opt_in/pdf-icon.png',
        downloadLink: 'echeck_opt_in/affinipay-terms-of-service.pdf',
        successIcon: 'echeck_opt_in/icn_success.svg'
      }
    };
  default:
    return;
  }
};
