import React from 'react';
import { shallow, mount } from 'enzyme';
import State from './state';

describe('State Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(
      <State label={'foo'} selected={'US'} name={'bar'} id={'foobar'} />
    );
    expect(component).toMatchSnapshot();
  });
  it('mount for events', () => {
    const component = mount(
      <State label={'foo'} selected={'US'} name={'foobar'} id={'foobar'} />
    );
    component
      .find('[name="foobar"]')
      .at(1)
      .prop('onChange')({ value: 'US' });
    expect(component.state('selectedOption')).toBeUndefined();
    component.unmount();
  });
});
