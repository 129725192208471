import React, {useState} from 'react';
import {array, func} from 'prop-types';
import {APAlertModal, APButton} from 'affinipay-ui-library';
import './style.scss';
import IconPaperclip from '../../icons/icon-paperclip';

const RemoveAttachmentButtons = ({handleCancel, handleRemoval}) => (
  <div className="quick-bill-attachments-remove-buttons">
    <APButton
      className="ap-ghost-alert-button"
      type="button"
      onClick={handleCancel}
    >
      Cancel
    </APButton>
    <APButton
      className="ap-critical-button"
      type="button"
      ariaLabel="Remove Attachment"
      onClick={handleRemoval}
    >
      Yes, Delete
    </APButton>
  </div>
);

const AttachmentsList = ({
  attachments,
  removeAttachment
}) => {
  const [showRemovalAlert, setShowRemovalAlert] = useState(false);
  const [targetedAttachment, setTargetedAttachment] = useState();

  const handleRemoveClick = (id, fileName) => {
    setShowRemovalAlert(true);
    setTargetedAttachment({id, fileName});
  };

  const removeSpecificAttachment = () => {
    removeAttachment(targetedAttachment.id);
    setShowRemovalAlert(false);
  };

  const closeRemovalAlert = () => {
    setShowRemovalAlert(false);
    setTargetedAttachment();
  };

  return (
    <div className="quick-bill-attachments-list" data-testid="quick-bill-attachments-list">
      <ul>
        {attachments.map(({fileName, id}) => (
          <li key={id}>
            <button
              className="quick-bill-attachments-remove-btn"
              type="button"
              onClick={() => handleRemoveClick(id, fileName)}
            >
              Remove
            </button>
            <IconPaperclip size={16} />
            <span className="quick-bill-attachment-description">{fileName}</span>
          </li>
        ))}
      </ul>
      {showRemovalAlert && (
        <APAlertModal
          type="error"
          isActiveInitial={showRemovalAlert}
          headerText="Confirmation Required"
          bodyText={`Are you sure you want to remove the attached file ${targetedAttachment.fileName}?`}
          footer={<RemoveAttachmentButtons
            handleCancel={closeRemovalAlert}
            handleRemoval={removeSpecificAttachment}
          />}
        />
      )}
    </div>
  );
};

RemoveAttachmentButtons.propTypes = {
  handleCancel: func,
  handleRemoval: func
};

AttachmentsList.propTypes = {
  attachments: array.isRequired,
  removeAttachment: func.isRequired
};

export default AttachmentsList;
