import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { APInputText } from 'affinipay-ui-library';

const IndividualFields = ({ givenName, surname, setGivenName, setSurname }) => {
  const handleSurnameChange = e => setSurname(e.target.value);
  const handleGivenNameChange = e => setGivenName(e.target.value);

  return (
    <span>
      <APInputText
        dataset={{
          'data-chargeio': 'given_name',
          'data-validation': 'First Name'
        }}
        id="given_name"
        label="First Name"
        onChange={handleGivenNameChange}
        parentClass="col-xs-12 col-sm-6"
        value={givenName}
      />
      <APInputText
        dataset={{
          'data-chargeio': 'surname',
          'data-validation': 'Last Name'
        }}
        id="surname"
        label="Last Name"
        onChange={handleSurnameChange}
        parentClass="col-xs-12 col-sm-6"
        value={surname}
      />
    </span>
  );
};

const BusinessFields = ({ accountHolderName, setAccountHolderName }) => {
  const handleAccountHolderNameChange = e => setAccountHolderName(e.target.value);

  return (
    <APInputText
      dataset={{
        'data-chargeio': 'name'
      }}
      id='account_holder_name'
      label='Name of Business'
      parentClass='col-xs-12'
      onChange={handleAccountHolderNameChange}
      value={accountHolderName}
    />
  );
};

const AccountHolderFields = props => {
  const [givenName, setGivenName] = useState(props.givenName);
  const [surname, setSurname] = useState(props.surname);
  const [accountHolderName, setAccountHolderName] =useState(props.accountHolderName);
  const fieldTypes = {
    individual: {
      component: IndividualFields,
      props: {givenName, surname, setGivenName, setSurname}
    },
    business: {
      component: BusinessFields,
      props: {accountHolderName, setAccountHolderName}
    }
  };
  const field = fieldTypes[props.type];
  const SpecificField = field?.component;

  return SpecificField ? (
    <div id="account_fields" className="account_fields">
      <SpecificField {...field.props} />
    </div>
  ) : (<></>);
};

IndividualFields.propTypes = {
  givenName: string,
  surname: string,
  setGivenName: func.isRequired,
  setSurname: func.isRequired
};
AccountHolderFields.propTypes = {
  type: string,
  givenName: string,
  surname: string,
  accountHolderName: string
};
BusinessFields.propTypes = {
  accountHolderName: string,
  setAccountHolderName: func.isRequired
};
export default AccountHolderFields;
