// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`datepicker Component shallow snapshot render 1`] = `
<div>
  <LegacyAPDatepicker
    canChangeMonth={true}
    disabledDays={
      {
        "after": 2018-08-09T08:00:00.000Z,
      }
    }
    inlineLabel={false}
    isNavHidden={false}
    labelText=""
    lastDayButtonEnabled={false}
    lastDayTextEnabled={false}
    month={2018-08-09T08:00:00.000Z}
    onDayCallback={[Function]}
    selectedDays={2018-08-09T08:00:00.000Z}
    toMonth={2018-08-09T08:00:00.000Z}
    todayTextEnabled={false}
  />
  <ForwardRef
    className="addon-right"
    id="transactionsrange"
    name="date"
    type="hidden"
    value={2018-08-09T08:00:00.000Z}
  />
</div>
`;
