import React, {useState} from 'react';
import PropTypes from 'prop-types';
import client from 'lib/ajax';
import { APSelect } from 'affinipay-ui-library';
import notify from 'lib/notify';

const statuses = [
  {text: 'Not Trained', value: 'not_trained'},
  {text: 'Scheduled', value: 'scheduled'},
  {text: 'Trained', value: 'trained'}
];

const TrainingStatus = ({ 
  adminCanEdit = false,
  initialTrainingStatus = 'not_trained',
  merchantId 
}) => {
  const [trainingStatus, setTrainingStatus] = useState(initialTrainingStatus);
  const [loading, setLoading] = useState(false);

  const onStatusChange = async ({ selectedItem }) => {
    const { value } = selectedItem;
    setLoading(true);
    try {
      const resp = await client.patch(
        `/admin/merchants/${merchantId}/training_statuses`, 
        { training_status: value }
      );
      const json = await resp.json();
      resp.ok ? setTrainingStatus(json.training_status) : notify.error(json.error);
    } catch (e) {
      notify.error('Training status couldn’t be updated, please refresh the page and try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='col-xs-12 top-spacer-20 admin'>
      <h4>Training Status</h4>
      {adminCanEdit ? 
        <APSelect
          name='trainingStatus'
          options={statuses}
          onChange={onStatusChange}
          value={trainingStatus}
          disabled={loading || !adminCanEdit}
          parentClass="admin"
        />
        :
        <span className='capitalize'>{trainingStatus?.replace(/_/g, ' ')}</span>
      }
    </div>
  );
};

export default TrainingStatus;

TrainingStatus.propTypes = {
  adminCanEdit: PropTypes.bool,
  initialTrainingStatus: PropTypes.string,
  merchantId: PropTypes.number.isRequired
};
