import Dinero from 'dinero.js';
import { getUserLocale, isNumber,  rollbarLog } from './utils';

//Rounding
export const roundNumber = (amount, decimals=0) => {
  amount = Number(amount);
  if (amount === 0 ) return 0;
  if (!amount || !isNumber(Number(amount))){
    rollbarLog('MonetaryUtils', `Invalid value sent to roundNumber: ${amount}`);
    throw `Invalid value sent to roundNumber: ${amount}`;
  }
  const isNegative = amount < 0;
  let num = Number(amount);
  if (isNegative)
    num = num * -1;
  const result = Number(Math.round(num+'e'+decimals)+'e-'+decimals);
  return isNegative ? result * -1 : result;
};

export const getRoundedMonetaryNumber = amount => roundNumber(amount, 2);

export const getCentsFromDollars = dollars => {
  if (dollars === 0 ) return 0;
  if (!dollars) {
    rollbarLog('MonetaryUtils', `Invalid value sent to getCentsFromDollars: ${dollars}`);
    throw `Invalid value sent to getCentsFromDollars: ${dollars}`;
  }
  dollars = Number(dollars)+'';
  let decimals = dollars.substring(dollars.indexOf('.')+1).length;
  if (dollars.indexOf('.') === -1) {
    return Number(dollars+'00');
  } else if (decimals === 1) {
    return Number((getRoundedMonetaryNumber(dollars)+'0').replace('.',''));
  } else if (decimals >= 2){
    return Number((getRoundedMonetaryNumber(dollars)+'').replace('.',''));
  } else {
    return 0;
  }
};

export const getDollarsFromCents = cents => {
  if (cents === 0 ) return 0;
  if (!cents) {
    rollbarLog('MonetaryUtils', `Invalid value sent to getDollarsFromCents: ${cents}`);
    throw `Invalid value sent to getDollarsFromCents: ${cents}`;
  }
  return getRoundedMonetaryNumber(cents/100);
};

//Working with reusable monetary components
export const getDollarsFromMaskedMoneyInput = (input) => input ? parseFloat((input+'').replace(/,/g, '')) : 0;

export const getCentsFromMaskedMoneyInput = (input) => input ? getCentsFromDollars(getDollarsFromMaskedMoneyInput(input)) : 0;

//Formatting
//This is mostly IE 11 fallback method. Use Dinero formatting method below.
export const getMoneyFormatFromDollars = (amount, accountCurrency = 'USD') => {
  const locale = getUserLocale();
  const currencySymbol = getCurrencySymbol(locale, accountCurrency) || '$';

  try {
    if (!amount) return `${currencySymbol}0.00`;

    const decimalCount = 2,
      decimal = '.',
      separator = ',',
      negativeSign = amount < 0 ? '-' : '';
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return `${negativeSign}${currencySymbol}${(j ? i.substr(0, j) + separator : '')}${i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + separator)}${(decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')}`;
  } catch( err ) {
    rollbarLog(`Failed to use fallback method to convert ${amount}, Reason: ${err.message}`);
  }
};

export const DineroDollarsFormat = (dollars, accountCurrency = 'USD') => {
  if (!dollars) dollars = 0;

  dollars = getRoundedMonetaryNumber(dollars);
  try {
    return Dinero({
      amount: getCentsFromDollars(dollars),
      currency: accountCurrency.toUpperCase()
    }).setLocale(getUserLocale()).toFormat('$0,0.00');
  } catch(err){
    rollbarLog('MonetaryUtils', `Dinero failed to convert the following input: ${dollars}. Reason: ${err.message}. Attempting to use native fallback method`, 'warning');
    return getMoneyFormatFromDollars(dollars);
  }
};

export const DineroCentsFormat = (cents, accountCurrency = 'USD') => {
  if(!cents) cents = 0;

  try {
    return Dinero({
      amount: cents,
      currency: accountCurrency
    }).setLocale(getUserLocale()).toFormat('$0,0.00');
  } catch(err){
    rollbarLog('MonetaryUtils', `Dinero failed to convert the following cents input: ${cents}. Reason: ${err.message}. Attempting to use native fallback method`, 'warning');
    return getMoneyFormatFromDollars(getDollarsFromCents(cents));
  }
};

export const getCurrencySymbol = (locale, currency) => {
  return (0).toLocaleString(
    locale,
    {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  ).replace(/\d/g, '').trim();
};
