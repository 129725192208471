export default ({
  access_token: accessToken,
  created_at: createdAt,
  error,
  expires_in: expiresIn,
  scope,
  token_type: tokenType
}) => {
  return {
    accessToken,
    createdAt,
    error: error,
    expiresIn,
    scope,
    tokenType
  };
};
