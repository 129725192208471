import { rollbarLog, isNumber } from '../../../../lib/utils';

export const handleAbandonment = () => {
  let url = window.location.href;
  if (url.indexOf('loan_reload') === -1) {
    url += `${url.indexOf('?') > -1 ? '&' : '?'}loan_reload=true`;
  }
  top.window.location.href = url;
};

export const getAbandonmentTime = (gatewayTimeoutEpoch, UITimeoutDelta) => {
  const timeout = Number(gatewayTimeoutEpoch) - Number(UITimeoutDelta) - Number(Date.now());
  if (!gatewayTimeoutEpoch || !isNumber(timeout)) {
    rollbarLog('LoanJS', `Failed to get expiration time. GW res: ${gatewayTimeoutEpoch} Calc time: ${timeout}. Defaulting to hardcoded timeout`);
    const defaultServerTimeout = 30 * 60 * 1000;
    return defaultServerTimeout + UITimeoutDelta;
  }
  return timeout;
};

