import React, { useState, useEffect } from 'react';
import { APButton, APModal } from 'affinipay-ui-library';
import { string } from 'prop-types';
import { useRefreshingToken } from '../../lib/RefreshingToken';
import IconChargeReader from './icons/icon-charge-reader';
import './style.scss';


const TerminalCheckoutModal = ({ authorizationUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setRefreshEnabled, token } = useRefreshingToken(authorizationUrl);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const navigateToReceipt = (event) => {
      window.location.href = `/transactions/${event.detail.transactionId}`;
    };
    document.addEventListener('TerminalCheckoutReceiptRequested', navigateToReceipt);

    return () => {
      document.removeEventListener('TerminalCheckoutReceiptRequested', navigateToReceipt);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('TerminalCheckoutTerminationRequested', toggleModal);
    document.addEventListener('TerminalCheckoutManualPaymentRequested', toggleModal);

    if (isModalOpen) {
      setRefreshEnabled(true);
    }

    return () => {
      document.removeEventListener('TerminalCheckoutTerminationRequested', toggleModal);
      document.removeEventListener('TerminalCheckoutManualPaymentRequested', toggleModal);

      if (isModalOpen) {
        setRefreshEnabled(false);
      }
    };
  }, [isModalOpen]);

  return (
    <>
      <APButton onClick={toggleModal} className={'ap-btn--secondary btn-row btn-terminal-checkout'}>
        <IconChargeReader className="ap-icon" fill="#FFF"/>
        <span className="ap-btn-label">Charge With Reader</span>
      </APButton>
      <APModal
        isActiveInitial={isModalOpen}
        headerText='Charge With Reader'
        onClose={toggleModal}
      >
        <terminal-checkout token={token.accessToken} />
      </APModal>
    </>
  );
};

TerminalCheckoutModal.propTypes = {
  authorizationUrl: string
};

export default TerminalCheckoutModal;
