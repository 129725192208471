import React from 'react';
import {shallow, mount} from 'enzyme';
import Modal from './modal';
import * as fetchLib from 'whatwg-fetch';

describe('merchant notes modal', () => {
  it('shallow renders component', ()=>{
    const component = shallow(<Modal requestUrl={'www.test.com'} />);
    expect(component).toMatchSnapshot();
  });

  it('sends and handles requests', async ()=>{
    const component = mount(<Modal requestUrl={'www.test.com'} />);
    const goodRequest = {status:200, response: true};
    const badRequest = {status:404, json: () => new Promise((resolve) => {
      resolve([]);})};
    delete window.location;
    window.location = {reload: jest.fn()};
    jest.spyOn(location, 'reload').mockImplementation(()=> {});
    jest.spyOn(component.instance(), 'setErrors').mockImplementation((json) => {
      component.setState({
        errorMessages: json,
        hasErrors: true,
        showAlert: true
      });
    });
    jest.spyOn(fetchLib, 'fetch').mockImplementation(() => Promise.resolve(goodRequest));

    await component.instance().sendRequest();
    component.instance().handleSuccess(goodRequest);

    jest.spyOn(component.instance(), 'sendRequest').mockImplementation(() => Promise.resolve(goodRequest));

    await component.instance().sendRequest();
    expect(component.state('hasErrors')).toEqual(false);
    expect(component.state('showAlert')).toEqual(false);

    jest.spyOn(fetchLib, 'fetch').mockImplementation(() => Promise.resolve(badRequest));

    try {
      await component.instance().handleFailure(badRequest);
    } catch (e) {
      expect(e).toEqual(badRequest);
    }
    // component.instance().handleSuccess(badRequest);
    component.instance().setErrors(['a','b']);
    expect(component.state('hasErrors')).toEqual(true);
    expect(component.state('showAlert')).toEqual(true);
    jest.restoreAllMocks();
    component.unmount();
  });
  it('toggles and resets the modal', ()=>{
    const component = mount(<Modal requestUrl={'www.test.com'} />);
    const spyToggle = jest.spyOn(component.instance(), 'toggleModal');
    const spyReset = jest.spyOn(component.instance(), 'reset');
    const initialState = {...component.state()};

    expect(component.state('show')).toEqual(false);
    component.instance().toggleModal();
    expect(spyToggle).toHaveBeenCalled();
    expect(spyReset).not.toHaveBeenCalled();
    expect(component.state('show')).toEqual(true);

    component.setState({hasErrors: true, showAlert:true});
    expect(component.state('show')).not.toEqual(initialState.show);
    expect(component.state('hasErrors')).not.toEqual(initialState.hasErrors);
    expect(component.state('showAlert')).not.toEqual(initialState.showAlert);

    component.instance().toggleModal();
    expect(spyToggle).toHaveBeenCalled();
    expect(spyReset).toHaveBeenCalled();
    expect(component.state('show')).toEqual(initialState.show);
    expect(component.state('hasErrors')).toEqual(initialState.hasErrors);
    expect(component.state('showAlert')).toEqual(initialState.showAlert);
    component.unmount();
    jest.restoreAllMocks();
  });
  it('sets showAlert to false when handleDismiss is called', ()=>{
    const component = mount(<Modal requestUrl={'www.test.com'} />);
    const spy = jest.spyOn(component.instance(), 'handleDismiss');
    component.setState({showAlert:true});
    expect(component.state('showAlert')).toEqual(true);
    component.instance().handleDismiss();
    expect(spy).toHaveBeenCalled();
    expect(component.state('showAlert')).toEqual(false);
    component.unmount();
    spy.mockRestore();
  });
  it('updates body when handleChange is called and requestBody returns an object with merchant note', ()=>{
    const component = mount(<Modal requestUrl={'www.test.com'} />);
    const spyHandleChange = jest.spyOn(component.instance(), 'handleChange');
    const spyRequestBody = jest.spyOn(component.instance(), 'requestBody');
    const newBody = 'New body';
    expect(component.state('body')).toEqual('');
    component.instance().handleChange({target:{value: newBody}});
    expect(spyHandleChange).toHaveBeenCalled();
    expect(component.state('body')).toEqual(newBody);
    const req = component.instance().requestBody();
    expect(spyRequestBody).toHaveBeenCalled();
    expect(req.merchant_note.body).toEqual(newBody);
    component.unmount();
    jest.restoreAllMocks();
  });
});

