// Usage examples:

// const getFromServer = async () => {
//   try{
//     const res = await server.get({
//       url: // url here
//     });
//     const json = await maybeParseJSON(res);
//     // perform actions on success body/status 
//    } catch(err) {
//      const errorJson = await maybeParseJSON(err); // parse json
//      // perform actions on error body/status
//    }
// }
// const postToServer = async () => {
//   try {
//     const res =  await server.post({ 
//       url: // url here,
//       body: {
//         // request body here
//       }, 
//       headers: {
//         // non-default headers here
//       },
//       mockEnabled: false, // disable/enable mock
//       mockResponse: {} // provide mock response
//     });
//     const json = await maybeParseJSON(res); // parse json
//     // perform actions on success body/status
//   } catch(err) {
//     const errorJson = await maybeParseJSON(err); // parse json
//     // perform actions on error body/status
//   }
// };

// private

const _buildHeaders = headers => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content || '',
  ...headers
});

const _getOptions = (
  method,
  body,
  headers,
  mode,
  cache,
  credentials,
  redirect,
  referrerPolicy
) => {
  let options = {};
  options.method = method || 'GET';
  options.headers = _buildHeaders(headers);
  if (body && method !== 'GET') options.body = JSON.stringify(body);
  if (referrerPolicy) options.referrerPolicy = referrerPolicy;
  if (cache) options.cache = cache; // default to 'default'
  if (credentials) options.credentials = credentials; // default to 'same-origin'
  if (redirect) options.redirect = redirect; // default to 'follow'
  if (mode) options.mode = mode; // default to 'cors'
  return options;
};

const _doFetch = (
  url,
  method,
  body,
  headers,
  mode,
  cache,
  credentials,
  redirect,
  referrerPolicy,
  mockEnabled = false,
  mockResponse
) => {
  if (mockEnabled) {
    return new Response(JSON.stringify(mockResponse), { 'status' : 200 , 'statusText' : 'Mock response resolved' });
  } else {
    return fetch(url, _getOptions(method, body, headers, mode, cache, credentials, redirect, referrerPolicy))
      .then(res => {
        if (!res.ok) throw res;
        return res;
      });
  }
};

// public

const server = {
  get: ({ url, headers, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse }) => 
    _doFetch(url, 'GET', null, headers, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse),

  post: ({ url, headers, body, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse }) =>
    _doFetch(url, 'POST', body, headers, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse),

  put: ({ url, headers, body, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse }) =>
    _doFetch(url, 'PUT', body, headers, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse),

  delete: ({ url, headers, body, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse }) =>
    _doFetch(url, 'DELETE', body, headers, mode, cache, credentials, redirect, referrerPolicy, mockEnabled, mockResponse)
};

export const maybeParseJSON = async res => {
  try {
    const json = await res.json();
    return json;
  } catch {
    return undefined;
  }
};

export default server;