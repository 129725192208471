import { object, string } from 'prop-types';
import React from 'react';

const MerchantLogo = ({ merchant, logo, type = 'desktop' }) => {
  return (
    <div className={`col-12 logo-container ${type}-logo-container`}>
      {merchant.website ? (
        <a href={merchant.website} target="_blank" rel="noopener noreferrer">
          <img src={logo.large} className="merchant-logo" alt={`logo for ${merchant.name}`} role="img" />
        </a>
      ) : (
        <img src={logo.large} className="merchant-logo" alt={`logo for ${merchant.name}`} role="img" />
      )}
    </div>
  );
};

export default MerchantLogo;

MerchantLogo.propTypes = {
  type: string,
  merchant: object,
  logo: object
};