import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import './adhoc.scss';
import {amountMask} from '../../../lib/utils';
import { generateNewFee, buildYearOptions, buildMonthOptions, buildAdHocFeeNameOptions, adHocFeeNamesLength } from './helpers';

export default class AdHoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fees: this.props.fees
    };
  }

  static propTypes = {
    fees: PropTypes.arrayOf(PropTypes.object),
    resourceName: PropTypes.string,
    attributeName: PropTypes.string,
    withEffectiveDate: PropTypes.bool,
    restrictedEdit: PropTypes.bool
  };

  static defaultProps = {
    fees: [],
    resourceName: 'plan',
    attributeName: 'ad_hoc_fees_attributes'
  };

  addFee = () => {
    const newFees = this.state.fees.concat(generateNewFee(this.props.withEffectiveDate));
    this.setState({ fees: newFees });
  };

  removeFee = (idx) => () => {
    const fee = this.state.fees[idx];
    let newFees;

    if (fee.id) {
      // fee is persisted, so we just mark it as "deleted"
      newFees = this.state.fees.map((fee, fidx) => {
        if (idx !== fidx) return fee;

        return { ...fee, _destroy: true };
      });
    } else {
      newFees = this.state.fees.filter((f, fidx) => idx !== fidx);
    }
    this.setState({ fees: newFees });
  };

  updateFee = (idx) => (evt) => {
    const { field } = evt.target.dataset;
    const newFees = this.state.fees.map((fee, fidx) => {
      if (idx !== fidx) return fee;

      return { ...fee, [field]: evt.target.value };
    });
    this.setState({ fees: newFees });
  };

  inputName = (fieldName, idx) => {
    const { attributeName, resourceName } = this.props;
    return `${resourceName}[${attributeName}][${idx}][${fieldName}]`;
  };

  inputId = (fieldName, idx) => {
    const { attributeName, resourceName } = this.props;
    return `${resourceName}_${attributeName}_${idx}_${fieldName}`;
  };

  hasErrorsOn = (fee, fieldName) => {
    return fee.errors && fee.errors[fieldName];
  };

  errorClass = (fee, fieldName) => {
    return this.hasErrorsOn(fee, fieldName) ? 'has-error' : '';
  };

  markAsDeletedClass = (fee) => {
    return fee._destroy ? 'adhoc-fees__fee-row--marked-as-deleted' : '';
  };

  isReadOnly = (fee) => {
    return this.props.readonly || fee._destroy || (fee.id && this.props.restrictedEdit);
  }

  readOnlyClass = (bool) => {
    return bool ? 'readonly' : '';
  }

  canAddFees = () => {
    const feesAdded = this.state.fees.filter(x => !x._destroy ).length;
    return !this.props.disabled && !this.props.readonly && feesAdded < adHocFeeNamesLength;
  }

  render() {
    const { fees } = this.state;
    return (
      <div className="adhoc-fees">
        <div className="row adhoc-fees__header-container">
          <div className="col-md-1" />
          <div className="col-md-11">
            <div className="row">
              <div className="col-md-3">
                <strong>Fee Name</strong>
              </div>
              <div className="col-md-2">
                <strong>Amount</strong>
              </div>
              <div className="col-md-2">
                <strong>Frequency</strong>
              </div>
              {this.props.withEffectiveDate ? (
                <div className="col-md-2">
                  <strong>Effective</strong>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="form-group">
          {fees.map((fee, idx) => {
            return (
              <div
                className={`form-group adhoc-fees__fee-row ${this.markAsDeletedClass(
                  fee
                )}`}
                key={fee.id || idx}
              >
                <span className="col-md-1 control-label">
                  <strong>Fee #{idx + 1}</strong>
                </span>
                <div className="col-md-11">
                  <div className="row">
                    <div className={`col-md-3 ${this.errorClass(fee, 'name')}`}>
                      <select
                        value={fee.name}
                        className={`form-control ${this.readOnlyClass(this.props.readonly || fee._destroy)}`}
                        data-field="name"
                        onChange={this.updateFee(idx)}
                        id={this.inputId('name', idx)}
                        name={this.inputName('name', idx)}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly || fee._destroy}
                      >
                        {buildAdHocFeeNameOptions()}
                      </select>
                      {this.hasErrorsOn(fee, 'name') ? (
                        <span className="help-block">
                          {fee.errors.name.join(', ')}
                        </span>
                      ) : null}
                    </div>

                    <div
                      className={`col-md-2 ${this.errorClass(fee, 'rate')}`}
                    >
                      <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <MaskedInput
                          mask={amountMask}
                          type="text"
                          className="form-control"
                          value={fee.rate}
                          data-field="rate"
                          onChange={this.updateFee(idx)}
                          id={this.inputId('rate', idx)}
                          name={this.inputName('rate', idx)}
                          disabled={this.props.disabled}
                          required={true}
                          readOnly={this.props.readonly || fee._destroy}
                        />
                      </div>
                      {this.hasErrorsOn(fee, 'rate') ? (
                        <span className="help-block">
                          {fee.errors.rate.join(', ')}
                        </span>
                      ) : null}
                    </div>

                    <div
                      className={`col-md-2 ${this.errorClass(
                        fee,
                        'frequency'
                      )}`}
                    >
                      <select
                        value={fee.frequency}
                        className={`form-control ${this.readOnlyClass(this.isReadOnly(fee))}`}
                        data-field="frequency"
                        onChange={this.updateFee(idx)}
                        id={this.inputId('frequency', idx)}
                        name={this.inputName('frequency', idx)}
                        disabled={this.props.disabled}
                        readOnly={this.isReadOnly(fee)}
                      >
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Yearly</option>
                        <option value="ONE_TIME">One Time</option>
                      </select>
                      {this.hasErrorsOn(fee, 'frequency') ? (
                        <span className="help-block">
                          {fee.errors.frequency.join(', ')}
                        </span>
                      ) : null}
                    </div>

                    {this.props.withEffectiveDate ? (
                      <div>
                        <div className={`col-md-2 ${this.errorClass(
                        fee,
                        'effective_month'
                      )}`}>
                          <select
                            value={fee.effective_month}
                            className={`form-control ${this.readOnlyClass(this.isReadOnly(fee))}`}
                            data-field="effective_month"
                            onChange={this.updateFee(idx)}
                            id={this.inputId('effective_month', idx)}
                            name={this.inputName('effective_month', idx)}
                            disabled={this.props.disabled}
                            readOnly={this.isReadOnly(fee)}
                          >
                            <option value="" />
                            {buildMonthOptions()}
                          </select>
                          {this.hasErrorsOn(fee, 'effective_month') ? (
                            <span className="help-block">
                              {fee.errors.effective_month.join(', ')}
                            </span>
                          ) : null}
                        </div>

                        <div className={`col-md-2 ${this.errorClass(
                        fee,
                        'effective_year'
                      )}`}>
                          <select
                            value={fee.effective_year}
                            className={`form-control ${this.readOnlyClass(this.isReadOnly(fee))}`}
                            data-field="effective_year"
                            onChange={this.updateFee(idx)}
                            id={this.inputId('effective_year', idx)}
                            name={this.inputName('effective_year', idx)}
                            disabled={this.props.disabled}
                            readOnly={this.isReadOnly(fee)}
                          >
                            <option value="" />
                            {buildYearOptions(fee.effective_year)}
                          </select>
                          {this.hasErrorsOn(fee, 'effective_year') ? (
                            <span className="help-block">
                              {fee.errors.effective_year.join(', ')}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <div className="col-md-1">
                      {fee.id ? (
                        <input
                          type="hidden"
                          value={fee.id}
                          id={this.inputId('id', idx)}
                          name={this.inputName('id', idx)}
                        />
                      ) : null}

                      {fee._destroy ? (
                        <input
                          type="hidden"
                          value={fee._destroy}
                          data-field="_destroy"
                          onChange={this.updateFee(idx)}
                          id={this.inputId('_destroy', idx)}
                          name={this.inputName('_destroy', idx)}
                        />
                      ) : (
                        !this.props.disabled && !this.props.readonly ?
                          <span
                            className="glyphicon glyphicon-trash"
                            onClick={this.removeFee(idx)}
                          /> : null )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row">
          <div className="col-md-2 col-md-offset-1">
            { this.canAddFees() ?
                <a role="button" onClick={this.addFee}>
                  + Add {fees.length > 0 ? 'another ' : null}fee
                </a> : null }
          </div>
        </div>
      </div>
    );
  }
}
