import React from 'react';
import PropTypes from 'prop-types';
import { APButton } from 'affinipay-ui-library';

const SuccessfulUpdatedAccountModalContent = ({ onClick }) => {
  return (
    <div>
      <h4 className="text-center">The account was successfully updated!</h4>
      <div className="row">
        <div className="col-xs-6 col-xs-offset-4">
          <span> Remove this alert? </span>
          <APButton
            className="btn btn-sm btn-danger"
            onClick={onClick}
          >
            Remove
          </APButton>
        </div>
      </div>
    </div>
  );
};

SuccessfulUpdatedAccountModalContent.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SuccessfulUpdatedAccountModalContent;
