/* eslint-disable react/no-string-refs */
/* eslint-disable no-undef */
import React from 'react';
import {string} from 'prop-types';

export default class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.dateRangePickeOptions = this.dateRangePickeOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    $('.dashboard-dateRangeField').daterangepicker(
      this.dateRangePickeOptions(),
      this.handleChange
    );
  }

  render() {
    return (
      <div className="col-xs-8 col-sm-6 col-md-4 pull-right">
        <div className="input-group">
          <span className="input-group-addon">
            <span className="glyphicon glyphicon-calendar"></span>
          </span>
          <input ref="dateRangeField"
            id="date-range-filter"
            placeholder="Select a Date Range"
            className="dashboard-dateRangeField"
            type="text"
            defaultValue={this.props.initialDate}
          />
        </div>
      </div>
    );
  }

  dateRangePickeOptions() {
    let today = ['today', 'today'];
    let yesterday = ['yesterday', 'yesterday'];
    let last7Days = [ Date.today().add({ days: -6 }), 'today' ];
    let last30Days = [ Date.today().add({ days: -29 }), 'today' ];
    let thisMonth = [
      Date.today().moveToFirstDayOfMonth(),
      Date.today().moveToLastDayOfMonth()
    ];
    let lastMonth = [
      Date.today().moveToFirstDayOfMonth().add({months: -1}),
      Date.today().moveToFirstDayOfMonth().add({days: -1})
    ];

    return {
      maxDate: Date.today(),
      format: 'dd-MMM-yyyy',
      separator: ' / ',
      opens: 'left',
      ranges: {
        'Today': today,
        'Yesterday': yesterday,
        'Last 7 Days': last7Days,
        'Last 30 Days': last30Days,
        'This Month': thisMonth,
        'Last Month': lastMonth
      }
    };
  }


  handleChange() {
    let inputValue = $('.dashboard-dateRangeField').value;
    $.ajax({
      data: { range: inputValue },
      dataType: 'script'
    });
  }

}

DateRangeFilter.propTypes = {
  initialDate: string
};
