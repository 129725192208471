import React from 'react';
import PropTypes from 'prop-types';
import Standard from 'components/Select';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.optionsList.find(
        (option) => option.value === props.selected
      ),
    };
  }

  handleChange(optionsList, value) {
    this.setState({
      selectedOption: optionsList.find((option) => option.value === value),
    });
  }
  render() {
    const { label, name, optionsList } = this.props;

    return (
      <div>
        <label>{label}</label>
        <Standard
          name={name}
          options={optionsList}
          value={this.state.selectedOption}
          onChange={(option) => {
            this.handleChange(optionsList, option.value);
          }}
        />
      </div>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string,
  name: PropTypes.string,
  optionsList: PropTypes.array,
};

export default Select;
