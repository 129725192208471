import React from 'react';
import {number, string} from 'prop-types';

const IconPaperclip = ({
  fill ='#171A1C',
  size = 16,
  className='ap-icon',
  title='Paperclip Icon'
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M2.351 14.567c-1.826-1.884-1.791-4.922.044-6.8L8.95 1.064a3.497 3.497 0 0 1 5.02 0 3.638 3.638 0 0 1 0 5.07l-5.713 5.84a2.332 2.332 0 0 1-3.375-.031c-.883-.937-.855-2.421.046-3.343L9.42 4.011a.5.5 0 0 1 .707-.008l.714.7a.5.5 0 0 1 .008.707L6.357 9.998c-.154.157-.164.42-.02.571a.333.333 0 0 0 .49.006l5.714-5.84a1.637 1.637 0 0 0 0-2.275 1.499 1.499 0 0 0-2.161 0L3.825 9.165c-1.087 1.112-1.103 2.91-.038 4.01a2.665 2.665 0 0 0 3.846.01l5.377-5.5a.5.5 0 0 1 .707-.009l.715.7a.5.5 0 0 1 .008.706l-5.377 5.5a4.663 4.663 0 0 1-6.712-.015z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

IconPaperclip.propTypes = {
  fill: string,
  size: number,
  className: string,
  title: string
};

export default IconPaperclip;
