import client from '../../../lib/ajax';

export const getDeposits = async (payload, merchant) => {
  const requestBody = {
    bank_name: payload.account || '',
    deposit_total: parseFloat(payload.amount) * 100 || '',
    start_date: payload.dateFrom?.getTime() || '',
    end_date: payload.dateTo?.getTime() || '',
    sort_by: payload.sortBy || '',
    sort_order: payload.sortOrder || '',
    page: payload.page || '',
    per_page: 20
  };
  const queryString = Object.keys(requestBody).map(key => `${key}=${requestBody[key]}`).join('&');
  let sourceString = '';
  if(payload.type === 'All') {
    sourceString = '&source[]=ach&source[]=card';
  } else if(payload.type === 'card' || payload.type === 'ach') {
    sourceString = `&source[]=${payload.type}`;
  }

  const rootUrl = `/admin/merchants/${merchant.id}/deposits/search`,
    path=`${rootUrl}?${queryString}${sourceString}`;
  return await client.get(path);
};
