import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import client from 'lib/ajax';
import { switchActiveClass } from 'lib/utils';
import EyeIcon from 'components/icons/EyeIcon';
import {outputError} from '../../lib/utils';

const BusinessOwnerSocialSecurityNumber = ({displayPolicy, socialSecurityNumber, toggle}) => {
  return (
    <div className="business-owner-social-security-number-container">
      <span className="business-owner-social-security-number">
        {socialSecurityNumber}
      </span>
      { displayPolicy ? (
        <span className="business-owner-social-security-number-icons">
          <EyeIcon clickHandler={toggle} tabIndex='0' />
        </span> ) : null }
    </div>
  );
};

BusinessOwnerSocialSecurityNumber.propTypes = {
  socialSecurityNumber: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  displayPolicy: PropTypes.bool.isRequired
};


const OwnerSocialSecurityNumber = ({disabled, readonly, socialSecurityNumber, toggle}) => {
  return (
    <div className="form-group owner-social-security-number-container">
      <label className="col-sm-4 control-label">Social Security Number</label>
      <div className="col-sm-7">
        <APInputText
          autoComplete="off"
          className="ssn_field owner-social-security-number form-control"
          disabled={disabled}
          id="owner-social-security-number"
          placeholder={socialSecurityNumber}
          readOnly={readonly}
        />
      </div>
      {socialSecurityNumber ? (
        <div className="col-sm-1 owner-social-security-number-icons">
          <EyeIcon clickHandler={toggle} tabIndex='0' />
        </div>
      ) : null }
    </div>
  );
};

OwnerSocialSecurityNumber.propTypes = {
  disabled: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  socialSecurityNumber: PropTypes.string,
  toggle: PropTypes.func.isRequired
};

export default class InteractiveSocialSecurityNumber extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    show: false,
    socialSecurityNumber: this.props.maskedSocialSecurityNumber
  };

  componentDidUpdate(prevProps) {
    if (prevProps.maskedSocialSecurityNumber !== this.props.maskedSocialSecurityNumber) {
      this.setState({socialSecurityNumber: this.props.maskedSocialSecurityNumber});
    }
  }

  fetchSocialSecurityNumber = () => {
    client.get(this.props.requestUrl).then(this.handleResponse).catch(() => {
      outputError('Something went wrong please contact Engineering Support');
    });
  };

  handleResponse = (resp) => {

    const status = resp.status;
    resp.json().then((json) => {
      switch (status) {
      case 200:
        this.setState({
          show: !this.state.show,
          socialSecurityNumber: json.data.attributes.social_security_number
        });
        break;
      case 403:
        outputError('Forbidden');
        break;
      default:
        outputError('Something went wrong please contact Engineering Support');
      }
    }).catch(() => {
      outputError('Something went wrong please contact Engineering Support');
    });
  };

  toggle = (e) => {
    e.preventDefault();
    if (this.state.show) {
      this.setState({
        show: !this.state.show,
        socialSecurityNumber: this.props.maskedSocialSecurityNumber
      });
      switchActiveClass(e.target);
    } else {
      this.fetchSocialSecurityNumber();
      switchActiveClass(e.target);
    }
  };

  render() {
    return (
      this.props.isMerchantApplicationOwner ? (
        <OwnerSocialSecurityNumber toggle={this.toggle}
          socialSecurityNumber={this.state.socialSecurityNumber}
          {...this.props} />
      ) : (
        <BusinessOwnerSocialSecurityNumber toggle={this.toggle}
          socialSecurityNumber={this.state.socialSecurityNumber}
          {...this.props} />
      )
    );
  }
}

InteractiveSocialSecurityNumber.propTypes = {
  disabled: PropTypes.bool,
  displayPolicy: PropTypes.bool.isRequired,
  isMerchantApplicationOwner: PropTypes.bool,
  maskedSocialSecurityNumber: PropTypes.string,
  readonly: PropTypes.bool,
  requestUrl: PropTypes.string.isRequired
};
