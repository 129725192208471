import React, { useEffect, useState } from 'react';
import { func, object, string } from 'prop-types';
import { APButton, APInputText, APTable } from 'affinipay-ui-library';
import PCICompliancesService from './src/services/PCICompliancesService';
import { complianceStatusOptions } from './src/lib/StatusOptions';
import PCIModal from './PCIModal';
import PartnersFilter from './PartnersFilter';
import StatusSelectDropdown  from './StatusSelectDropdown';
import BulkUpdateModal from './BulkUpdateModal';
import PreviousTestDateTableCell from './PreviousTestDateTableCell';
import './style.scss';
import ErrorBoundary from '../../../components/ErrorBoundary';
import APTimelinePicker from '../../../components/APTimelinePicker';

const pageSize = 25;
const initialFilterState = {
  textFilter: '',
  dueDateFilter: { startDate: null, endDate: null },
  statusFilter: [],
  referralPartnersFilter: '',
  integrationPartnersFilter: ''
};

const UpdateLink = ({onClick}) => (<a className="update-link" onClick={onClick}>Update</a>);
UpdateLink.propTypes = {onClick: func};

const getStatusForModalSelect = status => {
  const filteredStatus = complianceStatusOptions.find(x => x.text === status);
  return filteredStatus.value;
};

const ShowPreviousTestDatesCell = ({row}) => {
  const  { values: { name, previousTestDate }, original } = row;

  return (<PreviousTestDateTableCell
    previousTestDate={previousTestDate}
    previousTestDates={original?.previousTestDates || []}
    merchantName={name}
  />
  );
};

const PCICompliances = () => {
  const [pciCompliances, setPciCompliances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(initialFilterState);
  const [pageNumber, setPageNumber] = useState(1);
  const [merchantUUID, setMerchantUUID] = useState('');
  const [merchantVTID, setMerchantVTID] = useState('');
  const [merchantDueDate, setMerchantDueDate] = useState(new Date());
  const [merchantStatus, setMerchantStatus] = useState('');
  const [selectedReferralPartner, setSelectedReferralPartner] = useState('');
  const [isTimelinePickerResetting, setIsTimelinePickerResetting] = useState(false);
  const [selectedIntegrationPartner, setSelectedIntegrationPartner] = useState('');
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [isBulkUpdateDisabled, setIsBulkUpdateDisabled] = useState(true);

  useEffect(() => {
    if (isLoading) {
      fetchRecords();
    }
  }, [isLoading]);

  const getDueDateValues = dueDateFilter => {
    let outputDateValue = null;
    const {startDate, endDate} = dueDateFilter;
    if (startDate instanceof Date && endDate instanceof Date) {
      const startTimeInSeconds = startDate.getTime() / 1000;
      const endTimeInSeconds = endDate.getTime() / 1000;
      outputDateValue = `filter[next_due_date][gte]=${startTimeInSeconds}&filter[next_due_date][lte]=${endTimeInSeconds}`;
    }
    return outputDateValue;
  };

  const getFilterParams = () => {
    const {dueDateFilter, statusFilter, textFilter, referralPartnersFilter, integrationPartnersFilter} = filterValues;
    const trimmedTextFilter = textFilter?.trim();

    return [
      statusFilter.length > 0 ? `filter[status]=${statusFilter.join()}` : null,
      trimmedTextFilter ? `filter[text]=${encodeURIComponent(trimmedTextFilter)}` : null,
      getDueDateValues(dueDateFilter),
      referralPartnersFilter && selectedReferralPartner ? `filter[referral_partner_id]=${selectedReferralPartner}` : null,
      integrationPartnersFilter && selectedIntegrationPartner ? `filter[integration_partner_id]=${selectedIntegrationPartner}` : null,
      `page[number]=${pageNumber}`,
      `page[size]=${pageSize}`
    ].filter(x => x !== null);
  };

  const fetchRecords = async () => {
    try {
      const queryParams = getFilterParams();
      const { data, totalCount } = await PCICompliancesService.get(queryParams);
      setPciCompliances(data);
      setTotalCount(totalCount);
      setErrorLoading(false);
    } catch (e) {
      setErrorLoading(true);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const toggleBulkUpdateModal = () => setIsBulkModalOpen(!isBulkModalOpen);

  const handleFilterChange = e => {
    const {name, value} = e.target;
    setFilterValues({...filterValues, [name]: value});
  };

  const handleClearFilters = () => {
    setFilterValues(initialFilterState);
    setSelectedReferralPartner('');
    handleApplyFilters();
    setIsTimelinePickerResetting(true);
    setIsBulkUpdateDisabled(true);
  };

  const handleApplyFilters = () => {
    setPageNumber(1);
    setIsLoading(true);
    setIsBulkUpdateDisabled(false);
  };

  const handlePaginationChange = ({ page }) => {
    setPageNumber(page);
    setIsLoading(true);
  };

  const handleExportCsv = async () => {
    const queryParams = getFilterParams();
    await PCICompliancesService.csv(queryParams).catch();
  };

  const statusAccessor = row => {
    const status = row['status'];
    return complianceStatusOptions.find(x => x.value === status)?.text;
  };

  const getMerchantUUID = merchantVTID => {
    const targetedCompliance = pciCompliances.find(compliance => compliance.merchantVTID === merchantVTID);
    return targetedCompliance.merchantUUID;
  };

  const modalStateChange = (dueDate, status, uuid, vtid) => {
    setMerchantDueDate(dueDate);
    setMerchantStatus(status);
    setMerchantUUID(uuid);
    setMerchantVTID(vtid);
    toggleModal();
  };

  const handleModalOpen = rowValues => {
    const {merchantVTID, nextDueDate} = rowValues;
    const status = rowValues['Compliance Status'];

    modalStateChange(new Date(nextDueDate), getStatusForModalSelect(status), getMerchantUUID(merchantVTID), merchantVTID);
  };

  const handleModalClose = () => {
    modalStateChange(new Date(), '', '', '');
  };

  const handleModalCloseAndRefresh = async () => {
    await setMerchantStatus('');
    await toggleModal();
    setIsLoading(true);
  };

  const handleBulkUpdateCloseAndRefresh = () => {
    toggleBulkUpdateModal();
    setIsLoading(true);
  };

  const UpdateLinkCell = ({row: { values }}) => <UpdateLink onClick={() => handleModalOpen(values)} />;
  UpdateLinkCell.propTypes = { row: object };

  const tableHeaders = [
    { accessor: 'merchantVTID', Header: 'VTID', disableSortBy: true },
    { accessor: 'merchantUUID', Header: 'UUID', disableSortBy: true },
    { accessor: 'name', Header: 'Name', disableSortBy: true },
    { accessor: 'brand', Header: 'Brand', disableSortBy: true },
    // eslint-disable-next-line react/prop-types
    { accessor: 'previousTestDate', Cell: ShowPreviousTestDatesCell, disableSortBy: true, Header: 'Previous Test Date' },
    { accessor: 'nextDueDate', Header: 'Due Date', disableSortBy: true },
    { accessor: statusAccessor, Header: 'Compliance Status', disableSortBy: true },
    { accessor: 'contactEmail', Header: 'Contact Email', disableSortBy: true },
    { accessor: 'referralPartner', Header: 'Referral Partners', disableSortBy: true },
    { accessor: 'integrationPartner', Header: 'Integration Partners', disableSortBy: true },
    { accessor: 'skipMe', Cell: UpdateLinkCell, Header: '', disableSortBy: true }
  ];

  const presentTotalCount = totalCount =>  `${totalCount} ${totalCount === 1 ? 'result' : 'results'} found`;


  const handlePartnerSelection = (partner, partnerType) => {
    if (partnerType == 'referral') {
      setSelectedReferralPartner(partner.selectedItem.id);
    } else {
      setSelectedIntegrationPartner((partner.selectedItem.id));
    }
  };

  const getBulkUpdateFilters = () => {
    return getFilterParams().filter(f => {
      return !f.includes('page');
    });
  };

  return (
    <ErrorBoundary>
      <form className="admin pci-form">
        <div className="pci-filter-row">
          <APInputText
            label="Name or VT ID"
            name="textFilter"
            onChange={handleFilterChange}
            parentClass="admin"
            placeholder="Enter search terms"
            value={filterValues.textFilter}
          />
          <APTimelinePicker
            label="Due date range"
            name="dueDateFilter"
            onChange={handleFilterChange}
            parentClass="admin"
            value={filterValues.dueDateFilter}
            shouldReset={isTimelinePickerResetting}
            setResetTimeline={setIsTimelinePickerResetting}
          />
          <StatusSelectDropdown
            options={complianceStatusOptions}
            onChange={handleFilterChange}
            statusFilterValues={filterValues.statusFilter}
          />
          <PartnersFilter
            onChange={handleFilterChange}
            onSelect={handlePartnerSelection}
            filterValues={filterValues}
            partnerType='referral'
          />
          <PartnersFilter
            onChange={handleFilterChange}
            onSelect={handlePartnerSelection}
            filterValues={filterValues}
            partnerType='integration'
          />

          <div>
            <APButton
              className="ap-button ap-secondary-button admin small"
              onClick={handleApplyFilters}
              type="button"
            >
              Apply Filters
            </APButton>
            <APButton
              className="ap-button ap-table-button admin small"
              onClick={handleClearFilters}
              type="button"
            >
              Clear
            </APButton>
          </div>
        </div>
        <div className="export-results">
          <span>
            {presentTotalCount(totalCount)}
          </span>
          <BulkUpdateModal
            filters={getBulkUpdateFilters()}
            count={totalCount}
            isDisabled={isBulkUpdateDisabled}
            isModalOpen={isBulkModalOpen}
            toggleModal={toggleBulkUpdateModal}
            onCloseAndRefresh={handleBulkUpdateCloseAndRefresh}
          />
          <APButton
            className="ap-button ap-table-button admin small"
            onClick={handleExportCsv}
            type="button"
          >
            Export .CSV
          </APButton>
        </div>
        {
          /**
           * TODO: Update AUL to support table pagination below the table
           * TODO: Update AUL to support striped tables or passthrough classNames or update mockup
           */
        }
        <APTable
          data={pciCompliances}
          handlePaginationChange={handlePaginationChange}
          header={tableHeaders}
          isPaginated={true}
          isServerSort={true}
          loading={isLoading}
          entriesPerPage={pageSize}
          total={totalCount}
          currentPage={pageNumber}
          errorLoading={errorLoading}
          paginationAlignment="right"
        />
        <PCIModal
          isModalOpen={isModalOpen}
          merchantDueDate={merchantDueDate}
          merchantUUID={merchantUUID}
          merchantVTID={merchantVTID}
          merchantStatus={merchantStatus}
          onClose={handleModalClose}
          onCloseAndRefresh={handleModalCloseAndRefresh}
        />
      </form>
    </ErrorBoundary>
  );
};

PCICompliances.propTypes = {
  adminUuidBase62: string
};


ShowPreviousTestDatesCell.propTypes = { row: object };

export default PCICompliances;
