import React from 'react';
import {arrayOf, bool, func, string} from 'prop-types';
import {APButton, APInputText, APModal} from 'affinipay-ui-library';
import DepositAccountsSettingDetails from './DepositAccountsSettingDetails';

const DepositAccountsEditModalText = ({displayType, supportEmail}) => {
  const viewOnlyText = () => {
    const cardDisplayText = 'Card types and Required Fields';
    const eCheckDisplayText = 'Required Fields';
    return displayType === 'Card' ? cardDisplayText : eCheckDisplayText;
  };

  return (
    <div className="deposit-accounts-info-header">
      <div>
        <span className="deposit-accounts-info-bold">View Only:</span>{viewOnlyText()}<span></span>
      </div>
      <div>
        If you would like to make changes to your Bank Account information, please email <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </div>
    </div>
  );
};

const DepositAccountsEditModal = (props) => {
  const {
    acceptedCardTypes,
    displayType,
    supportEmail,
    selectedAccountName,
    isModalOpen,
    isSaving,
    requiredPaymentFields,
    saveErrors = [],
    swipeRequiredPaymentFields,
    handleAccountNameChange,
    handleModalClose,
    handleSave
  } = props;
  const isBankTypeNotPayLater = displayType !== 'Pay Later';

  return (
    <APModal
      className="deposit-accounts-edit-modal"
      isActiveInitial={isModalOpen}
      headerText={`Edit ${displayType || 'Processing'} Account`}
      onClose={handleModalClose}
    >
      <form id="edit_merchant_account" acceptCharset="UTF-8" onSubmit={handleSave}>
        {isBankTypeNotPayLater && (
          <DepositAccountsEditModalText
            displayType={displayType}
            supportEmail={supportEmail}
          />)
        }
        <APInputText
          error={saveErrors.join(', ')}
          className="deposit-accounts-edit-input"
          label="Account Name:"
          id="account_name"
          name="account[name]"
          value={selectedAccountName}
          onChange={handleAccountNameChange}
        />
        {isBankTypeNotPayLater && (
          <DepositAccountsSettingDetails
            acceptedCardTypes={acceptedCardTypes}
            displayType={displayType}
            requiredPaymentFields={requiredPaymentFields}
            swipeRequiredPaymentFields={swipeRequiredPaymentFields}
          />
        )}
        <div className="deposit-accounts-edit-actions">
          <APButton
            className='ap-secondary-button'
            type="button"
            onClick={handleModalClose}
          >
            Cancel
          </APButton>
          <APButton disabled={isSaving}>
            Save
          </APButton>
        </div>
      </form>
    </APModal>
  );
};

DepositAccountsEditModalText.propTypes = {
  displayType: string,
  supportEmail: string
};

DepositAccountsEditModal.propTypes = {
  acceptedCardTypes: string,
  displayType: string,
  supportEmail: string.isRequired,
  isModalOpen: bool,
  isSaving: bool,
  requiredPaymentFields: string,
  selectedAccountName: string,
  saveErrors: arrayOf(string),
  swipeRequiredPaymentFields: string,
  handleAccountNameChange: func.isRequired,
  handleModalClose: func.isRequired,
  handleSave: func.isRequired
};

export default DepositAccountsEditModal;
