import Partner from '../responses/Partner';
import Config from '../lib/Config';
import { authHeaders } from '../lib/Headers';
import { rollbarLog as log } from '../../../../../lib/utils';
import server from '../../../../../lib/server';

const PartnersService = {
  get: async (params = []) => {
    const errorMessage = 'Partners service error fetching records';
    try {
      const baseUrl = Config.referralPartnersUrl;
      const queryString = params.length ? `?${params.join('&')}` : undefined;
      const url = queryString ? `${baseUrl}${queryString}` : baseUrl;
      const headers = await authHeaders();

      const resp = await server.get({
        url,
        headers
      });

      const json = await resp.json();
      const data = json.data.map(({ id, attributes }) => Partner({ id, ...attributes }));

      return { data };
    } catch (e) {
      log(errorMessage, e.message || e.statusText);
      throw new Error(errorMessage);
    }
  }
};

export default PartnersService;
