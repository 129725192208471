import React from 'react';
import PropTypes from 'prop-types';
import { APInputText } from 'affinipay-ui-library';
import LegacyAPDatepicker from '../../components/LegacyAPDatepicker';
import {getDateFromHyphenedFormat} from '../../lib/utils';

const RecurringChargeDatepicker = ({
  oneTime,
  isLastDayFirstCharge,
  isLastDaySecondCharge,
  secondChargeDate,
  recurFrequency,
  selectedDay,
  scheduleStartDate,
  isLastDay,
  recurStart,
  handleChange
}) => {
  const isLastDayEnabled = !(
    !recurFrequency ||
    recurFrequency === 'DAY' ||
    recurFrequency === 'WEEKLY' ||
    recurFrequency === 'YEAR'
  );

  const isLastDays = () => {
    const dayInMilliseconds = 86400000;
    const lastDay = new Date(selectedDay.getTime() + dayInMilliseconds).getDate() === 1;
    return lastDay;
  };

  const disabledDaysArray = [ { before: new Date(Date.now()) } ];

  let disabledDaysArrayBiMonthly = [];

  if(isLastDayFirstCharge) {
    disabledDaysArrayBiMonthly = [new Date(2018, 6, 31)];
  } else if(selectedDay) {
    disabledDaysArrayBiMonthly = [ new Date(2018, 6, selectedDay.getDate()) ];
  }   

  return (
    <>
      <div className='row'>
        <div className="col-sm-6">
          <LegacyAPDatepicker
            labelText={oneTime  ? 'Charge On' : 'First Charge On'}
            disabledDays={disabledDaysArray}
            fromMonth={new Date(Date.now())}
            selectedDays={
              scheduleStartDate
                ? getDateFromHyphenedFormat(scheduleStartDate)
                : new Date()
            }
            id="firstChargeDatePicker"
            lastDayButtonEnabled={isLastDayEnabled}
            lastDayTextEnabled={isLastDayEnabled}
            isLastDayFirstCharge={isLastDayFirstCharge}
            month={new Date(Date.now())}
            todayTextEnabled={true}
            onDayCallback={(day, isLastDay) =>
              handleChange({
                selectedDay: day,
                scheduleStartDate: day,
                recurStart: day,
                isLastDayFirstCharge: isLastDay
              })
            }
          />
        </div>
        <div className='col-sm-6'>
          {/*
            Date below is hardcoded intentionally. UX needs a datepicker with no ability to switch months.
            2018/5 works for the mockups.
          */}

          {recurFrequency == 'BI_MONTHLY' && (
            <>
              <LegacyAPDatepicker
                labelText={'Second Charge every'}
                placeholderText={'Select a day'}
                canChangeMonth={false}
                lastDayButtonEnabled={!isLastDays()}
                lastDayTextEnabled={true}
                month={new Date(2018, 6)}
                isNavHidden={true}
                inlineLabel={true}
                isLastDaySecondCharge={isLastDaySecondCharge}
                datePickerId={'intervalDatePicker'}
                disabledDays={disabledDaysArrayBiMonthly}
                secondChargeDate={secondChargeDate}
                onDayCallback={(day, isLastDay) =>
                  handleChange({
                    days: day === 'LAST' ? day : day.getDate(),
                    isLastDaySecondCharge: isLastDay
                  })
                }
                selectedDays={secondChargeDate ? new Date(`2018/7/${secondChargeDate}`) : undefined}
              />
              <APInputText
                type="hidden"
                name="recurring_charge[schedule][form_days][0]"
                id="firstChargeDate"
                value={isLastDayFirstCharge === 'LAST' ? isLastDayFirstCharge : selectedDay.getDate()}
              />
              <APInputText
                type="hidden"
                name="recurring_charge[schedule][form_days][1]"
                id="secondChargeDate"
                value={isLastDaySecondCharge === 'LAST' ? isLastDaySecondCharge : secondChargeDate}
              />
            </>
          )}
        </div>

        {isLastDay && (
          <input
            type="hidden"
            name="recurring_charge[schedule][form_days][0]"
            defaultValue={'LAST'}
          />
        )}
        <APInputText
          type="hidden"
          name="recurring_charge[schedule][start]"
          id="scheduleStartDate"
          value={scheduleStartDate}
        />
        <APInputText
          type="hidden"
          name="recurring_charge[recur_start]"
          value={recurStart}
        />
      </div>
    </>
  );
};

RecurringChargeDatepicker.propTypes = {
  isLastDayFirstCharge: PropTypes.string,
  isLastDaySecondCharge: PropTypes.string,
  secondChargeDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recurFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedDay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  scheduleStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  recurStart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLastDay: PropTypes.bool,
  oneTime: PropTypes.bool,
  handleChange: PropTypes.func
};

export default RecurringChargeDatepicker;
