import React from 'react';
import {string} from 'prop-types';
import IconCheck from './icons/icon-check';
import IconCharge from './icons/icon-charge';

const PaymentMethodIcon = ({ paymentMethod }) => (
  <span className="eqb-green">
    {paymentMethod === 'credit_card' ? (
      <>
        <IconCharge className="eqb-status-icon" /> Paid
      </>
    ) : (
      <>
        <IconCheck className="eqb-status-icon" /> Marked As Paid
      </>
    )}
  </span>
);

PaymentMethodIcon.propTypes = {
  paymentMethod: string
};

export default PaymentMethodIcon;
