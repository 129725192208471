import React from 'react';
import { bool } from 'prop-types';

const PlaceHolderClientPay = ({isMobile}) => {
  return (
    <svg width={isMobile ? '100%' : '366px'} height={isMobile ? '100%' : '226px'} viewBox="0 0 366 226" version="1.1">
      <g id="Symbols">
        <g id="card_image_clientpay">
          <path
            id="Rectangle"
            stroke="#F5F7F9"
            fill="#F7F7F7"
            d="M8,0h350c4.42,0,8,3.58,8,8v210c0,4.42-3.58,8-8,8H8c-4.42,0-8-3.58-8-8V8C0,3.58,3.58,0,8,0z"
          />
          <path
            id="Card-Holder"
            fill="#7C7E7F"
            d="M21.76,206.24c1.7,0,2.98-0.7,4.02-1.9l-0.92-1.04c-0.84,0.92-1.78,1.48-3.04,1.48
          c-2.48,0-4.06-2.08-4.06-5.38c0-3.26,1.64-5.3,4.12-5.3c1.12,0,1.98,0.5,2.68,1.24l0.9-1.08c-0.76-0.86-2-1.62-3.6-1.62
          c-3.34,0-5.82,2.58-5.82,6.8C16.04,203.72,18.48,206.24,21.76,206.24z M30.3,206.24c-1.62,0-2.84-1.02-2.84-2.76
          c0-2.12,1.82-3.18,5.96-3.64c0-1.24-0.4-2.44-2-2.44c-1.12,0-2.14,0.52-2.9,1.04l-0.64-1.14c0.9-0.58,2.26-1.26,3.82-1.26
          c2.38,0,3.38,1.58,3.38,4V206h-1.36l-0.14-1.16h-0.06C32.6,205.6,31.52,206.24,30.3,206.24z M30.78,204.92
          c0.94,0,1.7-0.46,2.64-1.3v-2.7c-3.24,0.4-4.36,1.2-4.36,2.44C29.06,204.46,29.82,204.92,30.78,204.92z M38.14,206h1.64v-6.24
          c0.66-1.64,1.64-2.24,2.46-2.24c0.4,0,0.62,0.06,0.94,0.16l0.32-1.44c-0.32-0.14-0.62-0.2-1.04-0.2c-1.1,0-2.1,0.78-2.76,2h-0.06
          l-0.14-1.76h-1.36V206z M48.2,206.24c-2.44,0-4.02-1.84-4.02-5.08c0-3.16,2-5.12,4.22-5.12c1.12,0,1.86,0.42,2.72,1.12l-0.08-1.66
          v-3.74h1.66V206h-1.36l-0.14-1.14h-0.06C50.38,205.6,49.34,206.24,48.2,206.24z M48.56,204.86c0.9,0,1.68-0.44,2.48-1.34v-5.08
          c-0.82-0.74-1.56-1.02-2.36-1.02c-1.56,0-2.8,1.5-2.8,3.72C45.88,203.46,46.86,204.86,48.56,204.86z M60.14,206h1.66v-6.18h6.1
          V206h1.68v-13.12H67.9v5.5h-6.1v-5.5h-1.66V206z M76.8,206.24c-2.38,0-4.5-1.86-4.5-5.08c0-3.26,2.12-5.12,4.5-5.12
          c2.38,0,4.5,1.86,4.5,5.12C81.3,204.38,79.18,206.24,76.8,206.24z M76.8,204.88c1.68,0,2.8-1.5,2.8-3.72
          c0-2.24-1.12-3.76-2.8-3.76c-1.66,0-2.8,1.52-2.8,3.76C74,203.38,75.14,204.88,76.8,204.88z M85.6,206.24
          c0.44,0,0.72-0.06,0.94-0.16l-0.22-1.24c-0.18,0.04-0.26,0.04-0.36,0.04c-0.24,0-0.46-0.2-0.46-0.72v-12.4h-1.64v12.28
          C83.86,205.46,84.36,206.24,85.6,206.24z M92.28,206.24c-2.44,0-4.02-1.84-4.02-5.08c0-3.16,2-5.12,4.22-5.12
          c1.12,0,1.86,0.42,2.72,1.12l-0.08-1.66v-3.74h1.66V206h-1.36l-0.14-1.14h-0.06C94.46,205.6,93.42,206.24,92.28,206.24z
          M92.64,204.86c0.9,0,1.68-0.44,2.48-1.34v-5.08c-0.82-0.74-1.56-1.02-2.36-1.02c-1.56,0-2.8,1.5-2.8,3.72
          C89.96,203.46,90.94,204.86,92.64,204.86z M104,206.24c-2.6,0-4.66-1.9-4.66-5.08c0-3.18,2.14-5.12,4.36-5.12
          c2.48,0,3.88,1.78,3.88,4.56c0,0.34-0.02,0.7-0.06,0.94h-6.56c0.12,2.06,1.38,3.38,3.24,3.38c0.92,0,1.7-0.3,2.42-0.76l0.58,1.08
          C106.36,205.78,105.32,206.24,104,206.24z M100.94,200.36h5.2c0-1.96-0.88-3.02-2.4-3.02
          C102.38,197.34,101.14,198.44,100.94,200.36z M109.98,206h1.64v-6.24c0.66-1.64,1.64-2.24,2.46-2.24c0.4,0,0.62,0.06,0.94,0.16
          l0.32-1.44c-0.32-0.14-0.62-0.2-1.04-0.2c-1.1,0-2.1,0.78-2.76,2h-0.06l-0.14-1.76h-1.36V206z"
          />
          <path
            id="_x30_000-1234-5678-9012"
            fill="#7C7E7F"
            d="M32.05,104.9c0,1.94-0.21,3.62-0.63,5.05c-0.42,1.42-0.99,2.6-1.71,3.53
          c-0.72,0.93-1.58,1.62-2.57,2.08c-0.99,0.45-2.04,0.68-3.17,0.68c-1.14,0-2.19-0.23-3.17-0.68c-0.98-0.45-1.83-1.15-2.55-2.08
          c-0.72-0.93-1.29-2.11-1.7-3.53c-0.41-1.42-0.62-3.1-0.62-5.05s0.21-3.63,0.62-5.05c0.41-1.43,0.98-2.61,1.7-3.54
          c0.72-0.94,1.57-1.63,2.55-2.08c0.98-0.45,2.03-0.68,3.17-0.68c1.13,0,2.18,0.23,3.17,0.68c0.99,0.45,1.84,1.15,2.57,2.08
          s1.29,2.12,1.71,3.54C31.84,101.28,32.05,102.96,32.05,104.9z M29.18,104.9c0-1.69-0.14-3.12-0.43-4.27
          c-0.28-1.15-0.67-2.08-1.15-2.78c-0.48-0.7-1.04-1.21-1.67-1.51c-0.63-0.3-1.29-0.46-1.97-0.46c-0.68,0-1.34,0.15-1.97,0.46
          s-1.18,0.81-1.66,1.51c-0.48,0.7-0.86,1.63-1.14,2.78c-0.28,1.15-0.43,2.58-0.43,4.27s0.14,3.12,0.43,4.26
          c0.28,1.15,0.66,2.07,1.14,2.77c0.48,0.7,1.03,1.21,1.66,1.51c0.63,0.3,1.29,0.46,1.97,0.46c0.68,0,1.34-0.15,1.97-0.46
          c0.63-0.3,1.19-0.81,1.67-1.51c0.48-0.7,0.86-1.63,1.15-2.77C29.04,108.02,29.18,106.6,29.18,104.9z M50.03,104.9
          c0,1.94-0.21,3.62-0.63,5.05c-0.42,1.42-0.99,2.6-1.71,3.53c-0.72,0.93-1.58,1.62-2.57,2.08c-0.99,0.45-2.04,0.68-3.17,0.68
          c-1.14,0-2.19-0.23-3.17-0.68c-0.98-0.45-1.83-1.15-2.55-2.08c-0.72-0.93-1.29-2.11-1.71-3.53c-0.41-1.42-0.62-3.1-0.62-5.05
          s0.21-3.63,0.62-5.05c0.41-1.43,0.98-2.61,1.71-3.54c0.72-0.94,1.57-1.63,2.55-2.08c0.98-0.45,2.03-0.68,3.17-0.68
          c1.13,0,2.18,0.23,3.17,0.68c0.99,0.45,1.84,1.15,2.57,2.08c0.72,0.94,1.29,2.12,1.71,3.54C49.82,101.28,50.03,102.96,50.03,104.9
          z M47.16,104.9c0-1.69-0.14-3.12-0.43-4.27c-0.28-1.15-0.67-2.08-1.15-2.78c-0.48-0.7-1.04-1.21-1.67-1.51s-1.29-0.46-1.97-0.46
          c-0.68,0-1.34,0.15-1.97,0.46s-1.18,0.81-1.66,1.51c-0.48,0.7-0.86,1.63-1.14,2.78c-0.28,1.15-0.43,2.58-0.43,4.27
          s0.14,3.12,0.43,4.26c0.28,1.15,0.66,2.07,1.14,2.77c0.48,0.7,1.03,1.21,1.66,1.51c0.63,0.3,1.29,0.46,1.97,0.46
          c0.68,0,1.34-0.15,1.97-0.46c0.63-0.3,1.19-0.81,1.67-1.51c0.48-0.7,0.86-1.63,1.15-2.77C47.02,108.02,47.16,106.6,47.16,104.9z
          M68.01,104.9c0,1.94-0.21,3.62-0.63,5.05s-0.99,2.6-1.71,3.53c-0.72,0.93-1.58,1.62-2.57,2.08c-0.99,0.45-2.04,0.68-3.17,0.68
          c-1.14,0-2.19-0.23-3.17-0.68c-0.98-0.45-1.83-1.15-2.55-2.08c-0.72-0.93-1.29-2.11-1.71-3.53c-0.41-1.42-0.62-3.1-0.62-5.05
          s0.21-3.63,0.62-5.05c0.41-1.43,0.98-2.61,1.71-3.54s1.57-1.63,2.55-2.08c0.98-0.45,2.03-0.68,3.17-0.68
          c1.13,0,2.18,0.23,3.17,0.68c0.99,0.45,1.84,1.15,2.57,2.08c0.72,0.94,1.29,2.12,1.71,3.54C67.8,101.28,68.01,102.96,68.01,104.9z
          M65.14,104.9c0-1.69-0.14-3.12-0.43-4.27c-0.28-1.15-0.67-2.08-1.15-2.78c-0.48-0.7-1.04-1.21-1.67-1.51s-1.29-0.46-1.97-0.46
          c-0.68,0-1.34,0.15-1.97,0.46s-1.18,0.81-1.66,1.51c-0.48,0.7-0.86,1.63-1.14,2.78c-0.28,1.15-0.43,2.58-0.43,4.27
          s0.14,3.12,0.43,4.26c0.28,1.15,0.66,2.07,1.14,2.77c0.48,0.7,1.03,1.21,1.66,1.51c0.63,0.3,1.29,0.46,1.97,0.46
          c0.68,0,1.34-0.15,1.97-0.46c0.63-0.3,1.19-0.81,1.67-1.51c0.48-0.7,0.86-1.63,1.15-2.77C65,108.02,65.14,106.6,65.14,104.9z
          M85.99,104.9c0,1.94-0.21,3.62-0.63,5.05s-0.99,2.6-1.71,3.53c-0.72,0.93-1.58,1.62-2.57,2.08c-0.99,0.45-2.04,0.68-3.17,0.68
          c-1.14,0-2.19-0.23-3.17-0.68c-0.98-0.45-1.83-1.15-2.55-2.08c-0.72-0.93-1.29-2.11-1.71-3.53c-0.41-1.42-0.62-3.1-0.62-5.05
          s0.21-3.63,0.62-5.05c0.41-1.43,0.98-2.61,1.71-3.54s1.57-1.63,2.55-2.08c0.98-0.45,2.03-0.68,3.17-0.68
          c1.13,0,2.18,0.23,3.17,0.68c0.99,0.45,1.84,1.15,2.57,2.08s1.29,2.12,1.71,3.54C85.78,101.28,85.99,102.96,85.99,104.9z
          M83.12,104.9c0-1.69-0.14-3.12-0.43-4.27c-0.28-1.15-0.67-2.08-1.15-2.78c-0.48-0.7-1.04-1.21-1.67-1.51
          c-0.63-0.3-1.29-0.46-1.97-0.46c-0.68,0-1.34,0.15-1.97,0.46c-0.63,0.3-1.18,0.81-1.66,1.51c-0.48,0.7-0.86,1.63-1.14,2.78
          c-0.28,1.15-0.43,2.58-0.43,4.27s0.14,3.12,0.43,4.26c0.28,1.15,0.66,2.07,1.14,2.77c0.48,0.7,1.03,1.21,1.66,1.51
          c0.63,0.3,1.29,0.46,1.97,0.46c0.68,0,1.34-0.15,1.97-0.46c0.63-0.3,1.19-0.81,1.67-1.51c0.48-0.7,0.86-1.63,1.15-2.77
          C82.98,108.02,83.12,106.6,83.12,104.9z M118.57,113.89V116h-11.9v-2.11h4.76V98.75c0-0.45,0.02-0.91,0.05-1.38l-3.95,3.39
          c-0.13,0.11-0.27,0.18-0.4,0.21c-0.13,0.03-0.26,0.03-0.37,0.01c-0.11-0.02-0.22-0.06-0.32-0.12c-0.1-0.06-0.17-0.13-0.22-0.2
          l-0.87-1.19l6.62-5.72h2.25v20.15H118.57z M136.07,113.38c0.3,0,0.54,0.09,0.71,0.26c0.18,0.18,0.26,0.4,0.26,0.68V116h-14.82
          v-0.95c0-0.2,0.04-0.4,0.12-0.6c0.08-0.21,0.21-0.4,0.39-0.57l7.11-7.15c0.59-0.6,1.13-1.18,1.61-1.73
          c0.49-0.55,0.9-1.11,1.24-1.67c0.34-0.56,0.6-1.12,0.79-1.7s0.28-1.19,0.28-1.84c0-0.65-0.1-1.22-0.31-1.71
          c-0.21-0.49-0.49-0.9-0.84-1.22s-0.78-0.56-1.26-0.72c-0.49-0.16-1.01-0.24-1.57-0.24c-0.57,0-1.09,0.08-1.57,0.25
          c-0.48,0.17-0.9,0.4-1.26,0.69c-0.37,0.29-0.67,0.64-0.92,1.05c-0.25,0.4-0.42,0.85-0.53,1.33c-0.12,0.36-0.29,0.6-0.5,0.72
          c-0.21,0.12-0.51,0.15-0.89,0.1l-1.44-0.25c0.14-1.01,0.43-1.91,0.84-2.69c0.42-0.78,0.95-1.43,1.58-1.96
          c0.64-0.53,1.36-0.93,2.18-1.2c0.82-0.27,1.7-0.41,2.65-0.41c0.94,0,1.82,0.14,2.64,0.42c0.82,0.28,1.52,0.68,2.12,1.22
          c0.6,0.53,1.07,1.18,1.41,1.95s0.51,1.63,0.51,2.6c0,0.83-0.12,1.59-0.37,2.3c-0.25,0.71-0.58,1.38-1.01,2.02
          c-0.42,0.64-0.91,1.27-1.46,1.88c-0.55,0.61-1.14,1.22-1.76,1.84l-5.86,5.98c0.41-0.11,0.83-0.2,1.26-0.27
          c0.42-0.07,0.83-0.1,1.22-0.1H136.07z M140.77,99.8c0.14-1.01,0.42-1.91,0.84-2.69c0.41-0.78,0.94-1.43,1.57-1.96
          c0.64-0.53,1.36-0.93,2.19-1.2s1.71-0.41,2.66-0.41c0.94,0,1.81,0.13,2.6,0.4c0.8,0.27,1.48,0.65,2.05,1.15
          c0.57,0.5,1.02,1.1,1.34,1.8c0.32,0.7,0.48,1.48,0.48,2.34c0,0.7-0.09,1.33-0.27,1.88s-0.44,1.04-0.77,1.45s-0.74,0.76-1.22,1.05
          s-1.01,0.53-1.6,0.71c1.45,0.37,2.53,1.01,3.26,1.91c0.73,0.9,1.09,2.03,1.09,3.39c0,1.02-0.19,1.94-0.58,2.76
          c-0.39,0.82-0.92,1.51-1.59,2.08c-0.67,0.57-1.45,1.01-2.35,1.32c-0.89,0.3-1.85,0.46-2.88,0.46c-1.18,0-2.19-0.15-3.02-0.44
          s-1.54-0.7-2.12-1.22s-1.05-1.13-1.43-1.84c-0.37-0.71-0.69-1.48-0.95-2.3l1.19-0.5c0.31-0.13,0.61-0.17,0.91-0.11
          c0.29,0.06,0.51,0.23,0.64,0.51c0.13,0.29,0.3,0.64,0.5,1.04c0.2,0.4,0.48,0.79,0.83,1.16s0.8,0.69,1.34,0.95
          c0.54,0.26,1.23,0.39,2.07,0.39c0.77,0,1.45-0.13,2.04-0.38c0.58-0.25,1.07-0.58,1.46-0.98c0.39-0.4,0.68-0.85,0.88-1.35
          s0.29-0.99,0.29-1.47c0-0.6-0.08-1.15-0.23-1.64s-0.44-0.92-0.87-1.29c-0.42-0.36-1-0.65-1.74-0.85
          c-0.74-0.21-1.69-0.31-2.84-0.31v-2c0.95-0.01,1.76-0.11,2.43-0.31c0.67-0.2,1.21-0.46,1.63-0.81c0.42-0.34,0.72-0.75,0.91-1.22
          s0.29-1,0.29-1.58c0-0.64-0.1-1.2-0.3-1.67c-0.2-0.48-0.48-0.87-0.83-1.18c-0.35-0.31-0.77-0.54-1.25-0.7s-1-0.23-1.56-0.23
          s-1.08,0.08-1.56,0.25c-0.48,0.17-0.9,0.4-1.27,0.69c-0.37,0.29-0.67,0.64-0.92,1.05c-0.25,0.4-0.42,0.85-0.53,1.33
          c-0.13,0.36-0.3,0.6-0.5,0.72c-0.2,0.12-0.49,0.15-0.88,0.1L140.77,99.8z M168.05,107.99v-9.16c0-0.27,0.01-0.56,0.03-0.88
          c0.02-0.32,0.05-0.65,0.09-0.98l-8.09,11.02H168.05z M173.83,107.99v1.58c0,0.17-0.05,0.3-0.15,0.42
          c-0.1,0.11-0.25,0.17-0.46,0.17h-2.76V116h-2.42v-5.84h-9.86c-0.21,0-0.38-0.06-0.53-0.18c-0.15-0.12-0.25-0.27-0.29-0.44
          l-0.28-1.41l10.8-14.34h2.57v14.2H173.83z M196.2,102.17c1.16-0.25,2.22-0.37,3.19-0.37c1.16,0,2.18,0.17,3.07,0.51
          s1.63,0.81,2.22,1.41c0.59,0.6,1.04,1.31,1.35,2.12c0.3,0.82,0.46,1.7,0.46,2.67c0,1.18-0.2,2.24-0.61,3.19
          c-0.41,0.95-0.97,1.76-1.69,2.44s-1.56,1.2-2.53,1.56c-0.97,0.36-2.02,0.54-3.15,0.54c-0.65,0-1.28-0.06-1.88-0.19
          c-0.6-0.13-1.16-0.3-1.68-0.52c-0.52-0.22-1-0.47-1.45-0.74s-0.84-0.57-1.18-0.88l0.85-1.18c0.19-0.27,0.43-0.4,0.73-0.4
          c0.21,0,0.44,0.08,0.7,0.24c0.26,0.16,0.57,0.34,0.95,0.53c0.37,0.2,0.81,0.37,1.31,0.53c0.5,0.16,1.1,0.24,1.79,0.24
          c0.78,0,1.47-0.12,2.09-0.37c0.62-0.25,1.15-0.6,1.6-1.06c0.44-0.46,0.79-1.01,1.02-1.65s0.36-1.36,0.36-2.15
          c0-0.69-0.1-1.32-0.3-1.88c-0.2-0.56-0.51-1.03-0.91-1.43c-0.41-0.39-0.92-0.7-1.53-0.91c-0.61-0.22-1.32-0.33-2.14-0.33
          c-0.56,0-1.14,0.05-1.74,0.14c-0.6,0.09-1.22,0.24-1.85,0.45l-1.74-0.51l1.78-10.39h10.52V95c0,0.39-0.12,0.72-0.36,0.97
          c-0.24,0.25-0.66,0.38-1.25,0.38h-6.97L196.2,102.17z M217.52,113.95c0.74,0,1.42-0.12,2.02-0.36c0.6-0.24,1.12-0.57,1.55-0.99
          s0.76-0.92,0.99-1.5c0.23-0.58,0.35-1.21,0.35-1.89c0-0.72-0.11-1.38-0.34-1.96c-0.23-0.58-0.55-1.08-0.97-1.49
          c-0.42-0.41-0.92-0.72-1.5-0.94s-1.21-0.33-1.91-0.33c-0.74,0-1.42,0.13-2.01,0.38c-0.6,0.25-1.11,0.59-1.53,1.02
          s-0.74,0.92-0.96,1.49c-0.22,0.56-0.33,1.15-0.33,1.76c0,0.71,0.1,1.36,0.31,1.95c0.21,0.59,0.51,1.1,0.91,1.52
          c0.4,0.42,0.88,0.75,1.46,0.98C216.14,113.84,216.79,113.95,217.52,113.95z M215.91,102.11c-0.2,0.25-0.38,0.49-0.57,0.72
          c-0.18,0.23-0.35,0.46-0.52,0.69c0.53-0.36,1.11-0.64,1.74-0.84c0.63-0.2,1.32-0.29,2.06-0.29c0.9,0,1.75,0.15,2.54,0.44
          s1.49,0.72,2.08,1.29c0.59,0.56,1.07,1.26,1.42,2.08s0.53,1.76,0.53,2.81c0,1.02-0.19,1.97-0.56,2.85
          c-0.37,0.88-0.89,1.64-1.56,2.29c-0.67,0.65-1.47,1.16-2.4,1.53c-0.94,0.37-1.97,0.56-3.09,0.56c-1.12,0-2.13-0.18-3.04-0.53
          c-0.91-0.36-1.68-0.86-2.32-1.52c-0.64-0.66-1.13-1.45-1.47-2.39c-0.35-0.94-0.52-1.98-0.52-3.14c0-0.97,0.21-2.01,0.64-3.11
          c0.43-1.1,1.11-2.28,2.05-3.53l5.64-7.58c0.14-0.19,0.34-0.34,0.6-0.47c0.25-0.13,0.55-0.19,0.88-0.19h2.45L215.91,102.11z
          M243.3,93.79v1.24c0,0.35-0.04,0.64-0.12,0.87c-0.08,0.23-0.15,0.42-0.22,0.57l-9.21,18.55c-0.13,0.27-0.32,0.5-0.56,0.69
          s-0.55,0.29-0.95,0.29h-1.98l9.33-18.32c0.13-0.26,0.27-0.5,0.42-0.71c0.14-0.22,0.3-0.42,0.46-0.62h-11.59
          c-0.18,0-0.33-0.07-0.47-0.21c-0.13-0.14-0.2-0.3-0.2-0.47v-1.88H243.3z M253.51,114.05c0.72,0,1.37-0.1,1.95-0.3
          s1.06-0.49,1.46-0.85c0.4-0.37,0.7-0.81,0.91-1.32c0.21-0.51,0.32-1.08,0.32-1.7c0-0.76-0.13-1.42-0.38-1.96
          c-0.25-0.54-0.59-0.98-1.02-1.33s-0.92-0.59-1.48-0.75c-0.56-0.16-1.14-0.24-1.75-0.24c-0.61,0-1.19,0.08-1.75,0.24
          c-0.56,0.16-1.05,0.41-1.48,0.75c-0.43,0.34-0.77,0.78-1.02,1.33c-0.25,0.54-0.38,1.2-0.38,1.96c0,0.62,0.11,1.19,0.32,1.7
          c0.21,0.51,0.52,0.95,0.91,1.32c0.4,0.37,0.88,0.65,1.46,0.85S252.79,114.05,253.51,114.05z M253.51,95.68
          c-0.66,0-1.24,0.1-1.74,0.29s-0.92,0.46-1.26,0.81c-0.34,0.34-0.59,0.74-0.76,1.19c-0.17,0.45-0.26,0.94-0.26,1.46
          c0,0.51,0.07,1,0.22,1.47c0.14,0.48,0.38,0.9,0.7,1.26c0.32,0.37,0.74,0.66,1.25,0.88c0.51,0.22,1.13,0.33,1.85,0.33
          c0.72,0,1.34-0.11,1.85-0.33c0.51-0.22,0.93-0.52,1.25-0.88c0.32-0.37,0.55-0.79,0.7-1.26c0.14-0.48,0.22-0.97,0.22-1.47
          c0-0.52-0.09-1-0.26-1.46c-0.17-0.45-0.42-0.85-0.76-1.19c-0.34-0.34-0.75-0.61-1.26-0.81S254.18,95.68,253.51,95.68z
          M256.69,104.42c1.38,0.39,2.45,1.05,3.19,1.97c0.74,0.92,1.12,2.1,1.12,3.53c0,0.97-0.18,1.85-0.55,2.63
          c-0.37,0.78-0.88,1.44-1.53,1.99c-0.66,0.55-1.44,0.97-2.36,1.26s-1.93,0.44-3.04,0.44c-1.11,0-2.12-0.15-3.04-0.44
          c-0.92-0.29-1.71-0.72-2.37-1.26c-0.66-0.55-1.17-1.21-1.53-1.99c-0.36-0.78-0.54-1.66-0.54-2.63c0-1.44,0.37-2.61,1.12-3.53
          s1.81-1.58,3.19-1.97c-1.17-0.43-2.05-1.08-2.64-1.95c-0.59-0.86-0.89-1.89-0.89-3.09c0-0.82,0.16-1.58,0.48-2.29
          c0.32-0.71,0.77-1.33,1.36-1.86c0.59-0.53,1.29-0.94,2.12-1.24c0.82-0.3,1.74-0.45,2.75-0.45c1,0,1.92,0.15,2.74,0.45
          c0.83,0.3,1.53,0.71,2.12,1.24c0.59,0.53,1.04,1.15,1.36,1.86c0.32,0.71,0.48,1.48,0.48,2.29c0,1.2-0.3,2.23-0.89,3.09
          C258.74,103.34,257.86,103.99,256.69,104.42z M282.76,100.31c0,0.7,0.1,1.33,0.3,1.88s0.49,1.02,0.87,1.4
          c0.38,0.38,0.84,0.67,1.38,0.87s1.15,0.29,1.82,0.29c0.74,0,1.4-0.12,1.98-0.36c0.57-0.24,1.06-0.57,1.45-0.97
          c0.39-0.4,0.69-0.87,0.9-1.39s0.31-1.07,0.31-1.64c0-0.69-0.11-1.32-0.33-1.88c-0.22-0.56-0.53-1.04-0.92-1.43
          s-0.86-0.69-1.4-0.91c-0.54-0.21-1.14-0.32-1.79-0.32c-0.68,0-1.3,0.11-1.86,0.33s-1.04,0.53-1.44,0.92
          c-0.4,0.39-0.71,0.86-0.93,1.41C282.87,99.06,282.76,99.66,282.76,100.31z M289.21,107.15c0.23-0.3,0.44-0.58,0.64-0.85
          c0.2-0.27,0.38-0.54,0.56-0.81c-0.57,0.45-1.21,0.8-1.92,1.04c-0.71,0.24-1.47,0.36-2.26,0.36c-0.84,0-1.63-0.14-2.38-0.42
          s-1.41-0.69-1.98-1.23s-1.02-1.21-1.36-2c-0.34-0.79-0.5-1.7-0.5-2.72c0-0.97,0.18-1.88,0.54-2.73s0.87-1.59,1.52-2.22
          c0.65-0.63,1.43-1.13,2.33-1.49c0.9-0.36,1.89-0.54,2.96-0.54c1.06,0,2.03,0.18,2.9,0.53c0.87,0.35,1.61,0.84,2.23,1.47
          c0.62,0.63,1.1,1.38,1.43,2.26c0.34,0.88,0.5,1.85,0.5,2.91c0,0.64-0.06,1.25-0.18,1.82c-0.12,0.57-0.29,1.14-0.51,1.69
          c-0.22,0.55-0.49,1.1-0.81,1.64c-0.32,0.54-0.68,1.1-1.09,1.67l-5.41,7.81c-0.13,0.2-0.33,0.35-0.57,0.47
          c-0.25,0.12-0.53,0.18-0.85,0.18h-2.54L289.21,107.15z M313.41,104.9c0,1.94-0.21,3.62-0.63,5.05c-0.42,1.42-0.99,2.6-1.71,3.53
          c-0.72,0.93-1.58,1.62-2.57,2.08c-0.99,0.45-2.04,0.68-3.17,0.68c-1.14,0-2.19-0.23-3.17-0.68c-0.98-0.45-1.83-1.15-2.55-2.08
          c-0.72-0.93-1.29-2.11-1.7-3.53s-0.62-3.1-0.62-5.05s0.21-3.63,0.62-5.05c0.41-1.43,0.98-2.61,1.7-3.54
          c0.72-0.94,1.57-1.63,2.55-2.08c0.98-0.45,2.03-0.68,3.17-0.68c1.13,0,2.18,0.23,3.17,0.68c0.99,0.45,1.84,1.15,2.57,2.08
          c0.72,0.94,1.29,2.12,1.71,3.54C313.2,101.28,313.41,102.96,313.41,104.9z M310.54,104.9c0-1.69-0.14-3.12-0.43-4.27
          c-0.28-1.15-0.67-2.08-1.15-2.78c-0.48-0.7-1.04-1.21-1.67-1.51c-0.63-0.3-1.29-0.46-1.97-0.46s-1.34,0.15-1.97,0.46
          s-1.18,0.81-1.66,1.51c-0.48,0.7-0.86,1.63-1.14,2.78c-0.28,1.15-0.43,2.58-0.43,4.27s0.14,3.12,0.43,4.26
          c0.28,1.15,0.66,2.07,1.14,2.77c0.48,0.7,1.03,1.21,1.66,1.51c0.63,0.3,1.29,0.46,1.97,0.46s1.34-0.15,1.97-0.46
          c0.63-0.3,1.19-0.81,1.67-1.51c0.48-0.7,0.86-1.63,1.15-2.77C310.4,108.02,310.54,106.6,310.54,104.9z M330.11,113.89V116h-11.9
          v-2.11h4.76V98.75c0-0.45,0.02-0.91,0.05-1.38l-3.95,3.39c-0.13,0.11-0.27,0.18-0.4,0.21s-0.26,0.03-0.37,0.01
          c-0.11-0.02-0.22-0.06-0.32-0.12c-0.1-0.06-0.17-0.13-0.22-0.2l-0.87-1.19l6.62-5.72h2.25v20.15H330.11z M347.61,113.38
          c0.3,0,0.54,0.09,0.71,0.26c0.18,0.18,0.26,0.4,0.26,0.68V116h-14.82v-0.95c0-0.2,0.04-0.4,0.12-0.6c0.08-0.21,0.21-0.4,0.39-0.57
          l7.11-7.15c0.59-0.6,1.13-1.18,1.61-1.73c0.49-0.55,0.9-1.11,1.24-1.67c0.34-0.56,0.6-1.12,0.79-1.7s0.28-1.19,0.28-1.84
          c0-0.65-0.1-1.22-0.31-1.71s-0.49-0.9-0.84-1.22s-0.78-0.56-1.26-0.72c-0.49-0.16-1.01-0.24-1.57-0.24
          c-0.57,0-1.09,0.08-1.57,0.25c-0.48,0.17-0.9,0.4-1.26,0.69c-0.37,0.29-0.67,0.64-0.92,1.05c-0.25,0.4-0.42,0.85-0.53,1.33
          c-0.12,0.36-0.29,0.6-0.5,0.72c-0.21,0.12-0.51,0.15-0.89,0.1l-1.44-0.25c0.14-1.01,0.43-1.91,0.84-2.69
          c0.42-0.78,0.95-1.43,1.58-1.96c0.64-0.53,1.36-0.93,2.18-1.2c0.82-0.27,1.7-0.41,2.65-0.41c0.94,0,1.82,0.14,2.64,0.42
          c0.82,0.28,1.52,0.68,2.12,1.22c0.6,0.53,1.07,1.18,1.41,1.95s0.51,1.63,0.51,2.6c0,0.83-0.12,1.59-0.37,2.3
          c-0.25,0.71-0.58,1.38-1.01,2.02s-0.91,1.27-1.46,1.88c-0.55,0.61-1.14,1.22-1.76,1.84l-5.86,5.98c0.41-0.11,0.83-0.2,1.26-0.27
          c0.42-0.07,0.83-0.1,1.22-0.1H347.61z"
          />
          <path
            id="Card-Holder_1_"
            fill="none"
            d="M21.8,206.2c1.7,0,3-0.7,4-1.9l-0.9-1c-0.8,0.9-1.8,1.5-3,1.5c-2.5,0-4.1-2.1-4.1-5.4
          c0-3.3,1.6-5.3,4.1-5.3c1.1,0,2,0.5,2.7,1.2l0.9-1.1c-0.8-0.9-2-1.6-3.6-1.6c-3.3,0-5.8,2.6-5.8,6.8
          C16,203.7,18.5,206.2,21.8,206.2z M30.3,206.2c-1.6,0-2.8-1-2.8-2.8c0-2.1,1.8-3.2,6-3.6c0-1.2-0.4-2.4-2-2.4
          c-1.1,0-2.1,0.5-2.9,1l-0.6-1.1c0.9-0.6,2.3-1.3,3.8-1.3c2.4,0,3.4,1.6,3.4,4v6h-1.4l-0.1-1.2h-0.1
          C32.6,205.6,31.5,206.2,30.3,206.2z M30.8,204.9c0.9,0,1.7-0.5,2.6-1.3v-2.7c-3.2,0.4-4.4,1.2-4.4,2.4
          C29.1,204.5,29.8,204.9,30.8,204.9z M38.1,206h1.6v-6.2c0.7-1.6,1.6-2.2,2.5-2.2c0.4,0,0.6,0.1,0.9,0.2l0.3-1.4
          c-0.3-0.1-0.6-0.2-1-0.2c-1.1,0-2.1,0.8-2.8,2h-0.1l-0.1-1.8H38v9.6H38.1z M48.2,206.2c-2.4,0-4-1.8-4-5.1c0-3.2,2-5.1,4.2-5.1
          c1.1,0,1.9,0.4,2.7,1.1l-0.1-1.7v-3.7h1.7V206h-1.4l-0.1-1.1h-0.1C50.4,205.6,49.3,206.2,48.2,206.2z M48.6,204.9
          c0.9,0,1.7-0.4,2.5-1.3v-5.1c-0.8-0.7-1.6-1-2.4-1c-1.6,0-2.8,1.5-2.8,3.7C45.9,203.5,46.9,204.9,48.6,204.9z M60.1,206h1.7v-6.2
          h6.1v6.2h1.7v-13.1h-1.7v5.5h-6.1v-5.5h-1.7C60.1,192.9,60.1,206,60.1,206z M76.8,206.2c-2.4,0-4.5-1.9-4.5-5.1
          c0-3.3,2.1-5.1,4.5-5.1s4.5,1.9,4.5,5.1C81.3,204.4,79.2,206.2,76.8,206.2z M76.8,204.9c1.7,0,2.8-1.5,2.8-3.7s-1.1-3.8-2.8-3.8
          c-1.7,0-2.8,1.5-2.8,3.8C74,203.4,75.1,204.9,76.8,204.9z M85.6,206.2c0.4,0,0.7-0.1,0.9-0.2l-0.2-1.2c-0.2,0-0.3,0-0.4,0
          c-0.2,0-0.5-0.2-0.5-0.7v-12.4h-1.6V204C83.9,205.5,84.4,206.2,85.6,206.2z M92.3,206.2c-2.4,0-4-1.8-4-5.1c0-3.2,2-5.1,4.2-5.1
          c1.1,0,1.9,0.4,2.7,1.1l-0.1-1.7v-3.7h1.7V206h-1.4l-0.1-1.1h-0.1C94.5,205.6,93.4,206.2,92.3,206.2z M92.6,204.9
          c0.9,0,1.7-0.4,2.5-1.3v-5.1c-0.8-0.7-1.6-1-2.4-1c-1.6,0-2.8,1.5-2.8,3.7C90,203.5,90.9,204.9,92.6,204.9z M104,206.2
          c-2.6,0-4.7-1.9-4.7-5.1c0-3.2,2.1-5.1,4.4-5.1c2.5,0,3.9,1.8,3.9,4.6c0,0.3,0,0.7-0.1,0.9H101c0.1,2.1,1.4,3.4,3.2,3.4
          c0.9,0,1.7-0.3,2.4-0.8l0.6,1.1C106.4,205.8,105.3,206.2,104,206.2z M100.9,200.4h5.2c0-2-0.9-3-2.4-3
          C102.4,197.3,101.1,198.4,100.9,200.4z M110,206h1.6v-6.2c0.7-1.6,1.6-2.2,2.5-2.2c0.4,0,0.6,0.1,0.9,0.2l0.3-1.4
          c-0.3-0.1-0.6-0.2-1-0.2c-1.1,0-2.1,0.8-2.8,2h-0.1l-0.1-1.8H110V206z"
          />
          <path
            id="_x30_000-1234-5678-9012_1_"
            fill="none"
            d="M32,104.9c0,1.9-0.2,3.6-0.6,5s-1,2.6-1.7,3.5s-1.6,1.6-2.6,2.1
          s-2,0.7-3.2,0.7c-1.1,0-2.2-0.2-3.2-0.7s-1.8-1.1-2.5-2.1c-0.7-0.9-1.3-2.1-1.7-3.5c-0.4-1.4-0.6-3.1-0.6-5s0.2-3.6,0.6-5.1
          c0.4-1.4,1-2.6,1.7-3.5s1.6-1.6,2.5-2.1c1-0.5,2-0.7,3.2-0.7c1.1,0,2.2,0.2,3.2,0.7s1.8,1.1,2.6,2.1s1.3,2.1,1.7,3.5
          C31.8,101.3,32,103,32,104.9z M29.2,104.9c0-1.7-0.1-3.1-0.4-4.3c-0.3-1.2-0.7-2.1-1.1-2.8c-0.5-0.7-1-1.2-1.7-1.5
          c-0.6-0.3-1.3-0.5-2-0.5s-1.3,0.2-2,0.5s-1.2,0.8-1.7,1.5s-0.9,1.6-1.1,2.8c-0.3,1.2-0.4,2.6-0.4,4.3s0.1,3.1,0.4,4.3
          c0.3,1.1,0.7,2.1,1.1,2.8c0.5,0.7,1,1.2,1.7,1.5c0.6,0.3,1.3,0.5,2,0.5s1.3-0.2,2-0.5c0.6-0.3,1.2-0.8,1.7-1.5s0.9-1.6,1.1-2.8
          C29,108,29.2,106.6,29.2,104.9z M50,104.9c0,1.9-0.2,3.6-0.6,5c-0.4,1.4-1,2.6-1.7,3.5s-1.6,1.6-2.6,2.1s-2,0.7-3.2,0.7
          c-1.1,0-2.2-0.2-3.2-0.7s-1.8-1.1-2.5-2.1c-0.7-0.9-1.3-2.1-1.7-3.5s-0.6-3.1-0.6-5s0.2-3.6,0.6-5.1c0.4-1.4,1-2.6,1.7-3.5
          s1.6-1.6,2.5-2.1c1-0.5,2-0.7,3.2-0.7c1.1,0,2.2,0.2,3.2,0.7s1.8,1.1,2.6,2.1c0.7,0.9,1.3,2.1,1.7,3.5C49.8,101.3,50,103,50,104.9
          z M47.2,104.9c0-1.7-0.1-3.1-0.4-4.3c-0.3-1.2-0.7-2.1-1.1-2.8c-0.5-0.7-1-1.2-1.7-1.5s-1.3-0.5-2-0.5s-1.3,0.2-2,0.5
          s-1.2,0.8-1.7,1.5s-0.9,1.6-1.1,2.8c-0.3,1.2-0.4,2.6-0.4,4.3s0.1,3.1,0.4,4.3c0.3,1.1,0.7,2.1,1.1,2.8c0.5,0.7,1,1.2,1.7,1.5
          c0.6,0.3,1.3,0.5,2,0.5s1.3-0.2,2-0.5c0.6-0.3,1.2-0.8,1.7-1.5s0.9-1.6,1.1-2.8C47,108,47.2,106.6,47.2,104.9z M68,104.9
          c0,1.9-0.2,3.6-0.6,5s-1,2.6-1.7,3.5s-1.6,1.6-2.6,2.1s-2,0.7-3.2,0.7c-1.1,0-2.2-0.2-3.2-0.7s-1.8-1.1-2.5-2.1
          c-0.7-0.9-1.3-2.1-1.7-3.5s-0.6-3.1-0.6-5s0.2-3.6,0.6-5.1c0.4-1.4,1-2.6,1.7-3.5s1.6-1.6,2.5-2.1c1-0.5,2-0.7,3.2-0.7
          c1.1,0,2.2,0.2,3.2,0.7s1.8,1.1,2.6,2.1c0.7,0.9,1.3,2.1,1.7,3.5C67.8,101.3,68,103,68,104.9z M65.1,104.9c0-1.7-0.1-3.1-0.4-4.3
          c-0.3-1.2-0.7-2.1-1.1-2.8c-0.5-0.7-1-1.2-1.7-1.5s-1.3-0.5-2-0.5s-1.3,0.2-2,0.5s-1.2,0.8-1.7,1.5s-0.9,1.6-1.1,2.8
          c-0.3,1.2-0.4,2.6-0.4,4.3s0.1,3.1,0.4,4.3c0.3,1.1,0.7,2.1,1.1,2.8c0.5,0.7,1,1.2,1.7,1.5c0.6,0.3,1.3,0.5,2,0.5s1.3-0.2,2-0.5
          c0.6-0.3,1.2-0.8,1.7-1.5s0.9-1.6,1.1-2.8C65,108,65.1,106.6,65.1,104.9z M86,104.9c0,1.9-0.2,3.6-0.6,5s-1,2.6-1.7,3.5
          s-1.6,1.6-2.6,2.1s-2,0.7-3.2,0.7c-1.1,0-2.2-0.2-3.2-0.7s-1.8-1.1-2.5-2.1c-0.7-0.9-1.3-2.1-1.7-3.5c-0.4-1.4-0.6-3.1-0.6-5
          s0.2-3.6,0.6-5.1c0.4-1.4,1-2.6,1.7-3.5s1.6-1.6,2.5-2.1c1-0.5,2-0.7,3.2-0.7c1.1,0,2.2,0.2,3.2,0.7s1.8,1.1,2.6,2.1
          s1.3,2.1,1.7,3.5C85.8,101.3,86,103,86,104.9z M83.1,104.9c0-1.7-0.1-3.1-0.4-4.3c-0.3-1.2-0.7-2.1-1.1-2.8
          c-0.5-0.7-1-1.2-1.7-1.5c-0.6-0.3-1.3-0.5-2-0.5s-1.3,0.2-2,0.5c-0.6,0.3-1.2,0.8-1.7,1.5s-0.9,1.6-1.1,2.8
          c-0.3,1.2-0.4,2.6-0.4,4.3s0.1,3.1,0.4,4.3c0.3,1.1,0.7,2.1,1.1,2.8c0.5,0.7,1,1.2,1.7,1.5c0.6,0.3,1.3,0.5,2,0.5s1.3-0.2,2-0.5
          c0.6-0.3,1.2-0.8,1.7-1.5s0.9-1.6,1.1-2.8C83,108,83.1,106.6,83.1,104.9z M118.6,113.9v2.1h-11.9v-2.1h4.8V98.7c0-0.5,0-0.9,0-1.4
          l-4,3.4c-0.1,0.1-0.3,0.2-0.4,0.2s-0.3,0-0.4,0s-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2l-0.9-1.2l6.6-5.7h2.2v20.2
          C114.1,113.9,118.6,113.9,118.6,113.9z M136.1,113.4c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v1.7h-14.8v-0.9
          c0-0.2,0-0.4,0.1-0.6s0.2-0.4,0.4-0.6l7.1-7.1c0.6-0.6,1.1-1.2,1.6-1.7c0.5-0.6,0.9-1.1,1.2-1.7s0.6-1.1,0.8-1.7s0.3-1.2,0.3-1.8
          c0-0.7-0.1-1.2-0.3-1.7s-0.5-0.9-0.8-1.2s-0.8-0.6-1.3-0.7c-0.5-0.2-1-0.2-1.6-0.2s-1.1,0.1-1.6,0.2c-0.5,0.2-0.9,0.4-1.3,0.7
          c-0.4,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.4,0.8-0.5,1.3c-0.1,0.4-0.3,0.6-0.5,0.7s-0.5,0.2-0.9,0.1l-1.4-0.2c0.1-1,0.4-1.9,0.8-2.7
          c0.4-0.8,0.9-1.4,1.6-2c0.6-0.5,1.4-0.9,2.2-1.2c0.8-0.3,1.7-0.4,2.7-0.4c0.9,0,1.8,0.1,2.6,0.4s1.5,0.7,2.1,1.2s1.1,1.2,1.4,1.9
          s0.5,1.6,0.5,2.6c0,0.8-0.1,1.6-0.4,2.3c-0.2,0.7-0.6,1.4-1,2s-0.9,1.3-1.5,1.9s-1.1,1.2-1.8,1.8l-5.9,6c0.4-0.1,0.8-0.2,1.3-0.3
          c0.4-0.1,0.8-0.1,1.2-0.1L136.1,113.4L136.1,113.4z M140.8,99.8c0.1-1,0.4-1.9,0.8-2.7c0.4-0.8,0.9-1.4,1.6-2
          c0.6-0.5,1.4-0.9,2.2-1.2s1.7-0.4,2.7-0.4c0.9,0,1.8,0.1,2.6,0.4s1.5,0.7,2.1,1.1c0.6,0.5,1,1.1,1.3,1.8c0.3,0.7,0.5,1.5,0.5,2.3
          c0,0.7-0.1,1.3-0.3,1.9s-0.4,1-0.8,1.4s-0.7,0.8-1.2,1.1s-1,0.5-1.6,0.7c1.4,0.4,2.5,1,3.3,1.9c0.7,0.9,1.1,2,1.1,3.4
          c0,1-0.2,1.9-0.6,2.8c-0.4,0.8-0.9,1.5-1.6,2.1c-0.7,0.6-1.5,1-2.3,1.3c-0.9,0.3-1.9,0.5-2.9,0.5c-1.2,0-2.2-0.1-3-0.4
          s-1.5-0.7-2.1-1.2s-1.1-1.1-1.4-1.8c-0.4-0.7-0.7-1.5-0.9-2.3l1.2-0.5c0.3-0.1,0.6-0.2,0.9-0.1c0.3,0.1,0.5,0.2,0.6,0.5
          s0.3,0.6,0.5,1s0.5,0.8,0.8,1.2s0.8,0.7,1.3,0.9c0.5,0.3,1.2,0.4,2.1,0.4c0.8,0,1.5-0.1,2-0.4c0.6-0.3,1.1-0.6,1.5-1
          s0.7-0.9,0.9-1.3s0.3-1,0.3-1.5c0-0.6-0.1-1.1-0.2-1.6s-0.4-0.9-0.9-1.3c-0.4-0.4-1-0.6-1.7-0.9c-0.7-0.2-1.7-0.3-2.8-0.3v-2
          c1,0,1.8-0.1,2.4-0.3c0.7-0.2,1.2-0.5,1.6-0.8c0.4-0.3,0.7-0.7,0.9-1.2s0.3-1,0.3-1.6s-0.1-1.2-0.3-1.7s-0.5-0.9-0.8-1.2
          c-0.4-0.3-0.8-0.5-1.2-0.7s-1-0.2-1.6-0.2s-1.1,0.1-1.6,0.2c-0.5,0.2-0.9,0.4-1.3,0.7c-0.4,0.3-0.7,0.6-0.9,1s-0.4,0.8-0.5,1.3
          c-0.1,0.4-0.3,0.6-0.5,0.7s-0.5,0.2-0.9,0.1L140.8,99.8z M168,108v-9.2c0-0.3,0-0.6,0-0.9s0.1-0.6,0.1-1l-8.1,11h8V108z
          M173.8,108v1.6c0,0.2,0,0.3-0.1,0.4s-0.3,0.2-0.5,0.2h-2.8v5.8H168v-5.8h-9.9c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.3-0.4l-0.3-1.4
          l10.8-14.3h2.6V108H173.8z M196.2,102.2c1.2-0.2,2.2-0.4,3.2-0.4c1.2,0,2.2,0.2,3.1,0.5s1.6,0.8,2.2,1.4c0.6,0.6,1,1.3,1.3,2.1
          c0.3,0.8,0.5,1.7,0.5,2.7c0,1.2-0.2,2.2-0.6,3.2c-0.4,1-1,1.8-1.7,2.4s-1.6,1.2-2.5,1.6c-1,0.4-2,0.5-3.1,0.5
          c-0.7,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.2-0.3-1.7-0.5s-1-0.5-1.4-0.7s-0.8-0.6-1.2-0.9l0.9-1.2c0.2-0.3,0.4-0.4,0.7-0.4
          c0.2,0,0.4,0.1,0.7,0.2c0.3,0.2,0.6,0.3,0.9,0.5c0.4,0.2,0.8,0.4,1.3,0.5c0.5,0.2,1.1,0.2,1.8,0.2c0.8,0,1.5-0.1,2.1-0.4
          c0.6-0.2,1.2-0.6,1.6-1.1c0.4-0.5,0.8-1,1-1.7s0.4-1.4,0.4-2.2c0-0.7-0.1-1.3-0.3-1.9s-0.5-1-0.9-1.4s-0.9-0.7-1.5-0.9
          c-0.6-0.2-1.3-0.3-2.1-0.3c-0.6,0-1.1,0-1.7,0.1c-0.6,0.1-1.2,0.2-1.9,0.4l-1.7-0.5l1.8-10.4H206V95c0,0.4-0.1,0.7-0.4,1
          c-0.2,0.3-0.7,0.4-1.2,0.4h-7L196.2,102.2z M217.5,114c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.1-0.6,1.6-1s0.8-0.9,1-1.5s0.3-1.2,0.3-1.9
          c0-0.7-0.1-1.4-0.3-2s-0.6-1.1-1-1.5s-0.9-0.7-1.5-0.9s-1.2-0.3-1.9-0.3s-1.4,0.1-2,0.4s-1.1,0.6-1.5,1s-0.7,0.9-1,1.5
          c-0.2,0.6-0.3,1.1-0.3,1.8c0,0.7,0.1,1.4,0.3,2s0.5,1.1,0.9,1.5s0.9,0.8,1.5,1C216.1,113.8,216.8,114,217.5,114z M215.9,102.1
          c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.2-0.4,0.5-0.5,0.7c0.5-0.4,1.1-0.6,1.7-0.8c0.6-0.2,1.3-0.3,2.1-0.3c0.9,0,1.7,0.1,2.5,0.4
          s1.5,0.7,2.1,1.3c0.6,0.6,1.1,1.3,1.4,2.1s0.5,1.8,0.5,2.8s-0.2,2-0.6,2.9c-0.4,0.9-0.9,1.6-1.6,2.3c-0.7,0.7-1.5,1.2-2.4,1.5
          c-0.9,0.4-2,0.6-3.1,0.6s-2.1-0.2-3-0.5c-0.9-0.4-1.7-0.9-2.3-1.5c-0.6-0.7-1.1-1.5-1.5-2.4c-0.3-0.9-0.5-2-0.5-3.1
          c0-1,0.2-2,0.6-3.1c0.4-1.1,1.1-2.3,2.1-3.5l5.6-7.6c0.1-0.2,0.3-0.3,0.6-0.5c0.3-0.1,0.5-0.2,0.9-0.2h2.4L215.9,102.1z
          M243.3,93.8V95c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.4-0.2,0.6l-9.2,18.6c-0.1,0.3-0.3,0.5-0.6,0.7s-0.6,0.3-0.9,0.3h-2l9.3-18.3
          c0.1-0.3,0.3-0.5,0.4-0.7c0.1-0.2,0.3-0.4,0.5-0.6h-11.6c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-1.9h15.1V93.8z
          M253.5,114c0.7,0,1.4-0.1,1.9-0.3s1.1-0.5,1.5-0.9s0.7-0.8,0.9-1.3c0.2-0.5,0.3-1.1,0.3-1.7c0-0.8-0.1-1.4-0.4-2
          c-0.3-0.5-0.6-1-1-1.3s-0.9-0.6-1.5-0.8c-0.6-0.2-1.1-0.2-1.8-0.2c-0.6,0-1.2,0.1-1.8,0.2c-0.6,0.2-1.1,0.4-1.5,0.8
          c-0.4,0.3-0.8,0.8-1,1.3c-0.3,0.5-0.4,1.2-0.4,2c0,0.6,0.1,1.2,0.3,1.7s0.5,1,0.9,1.3c0.4,0.4,0.9,0.7,1.5,0.9
          S252.8,114,253.5,114z M253.5,95.7c-0.7,0-1.2,0.1-1.7,0.3s-0.9,0.5-1.3,0.8c-0.3,0.3-0.6,0.7-0.8,1.2s-0.3,0.9-0.3,1.5
          c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.7,1.3s0.7,0.7,1.2,0.9c0.5,0.2,1.1,0.3,1.9,0.3c0.7,0,1.3-0.1,1.9-0.3
          c0.5-0.2,0.9-0.5,1.2-0.9s0.6-0.8,0.7-1.3s0.2-1,0.2-1.5s-0.1-1-0.3-1.5c-0.2-0.5-0.4-0.9-0.8-1.2c-0.3-0.3-0.8-0.6-1.3-0.8
          S254.2,95.7,253.5,95.7z M256.7,104.4c1.4,0.4,2.4,1,3.2,2c0.7,0.9,1.1,2.1,1.1,3.5c0,1-0.2,1.8-0.6,2.6c-0.4,0.8-0.9,1.4-1.5,2
          c-0.7,0.5-1.4,1-2.4,1.3s-1.9,0.4-3,0.4s-2.1-0.1-3-0.4s-1.7-0.7-2.4-1.3c-0.7-0.5-1.2-1.2-1.5-2c-0.4-0.8-0.5-1.7-0.5-2.6
          c0-1.4,0.4-2.6,1.1-3.5s1.8-1.6,3.2-2c-1.2-0.4-2-1.1-2.6-1.9c-0.6-0.9-0.9-1.9-0.9-3.1c0-0.8,0.2-1.6,0.5-2.3
          c0.3-0.7,0.8-1.3,1.4-1.9c0.6-0.5,1.3-0.9,2.1-1.2c0.8-0.3,1.7-0.4,2.8-0.4c1,0,1.9,0.1,2.7,0.4c0.8,0.3,1.5,0.7,2.1,1.2
          s1,1.1,1.4,1.9c0.3,0.7,0.5,1.5,0.5,2.3c0,1.2-0.3,2.2-0.9,3.1C258.7,103.3,257.9,104,256.7,104.4z M282.8,100.3
          c0,0.7,0.1,1.3,0.3,1.9s0.5,1,0.9,1.4s0.8,0.7,1.4,0.9s1.1,0.3,1.8,0.3c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.1-0.6,1.4-1
          c0.4-0.4,0.7-0.9,0.9-1.4s0.3-1.1,0.3-1.6c0-0.7-0.1-1.3-0.3-1.9c-0.2-0.6-0.5-1-0.9-1.4s-0.9-0.7-1.4-0.9
          c-0.5-0.2-1.1-0.3-1.8-0.3c-0.7,0-1.3,0.1-1.9,0.3s-1,0.5-1.4,0.9s-0.7,0.9-0.9,1.4C282.9,99.1,282.8,99.7,282.8,100.3z
          M289.2,107.1c0.2-0.3,0.4-0.6,0.6-0.9s0.4-0.5,0.6-0.8c-0.6,0.5-1.2,0.8-1.9,1c-0.7,0.2-1.5,0.4-2.3,0.4s-1.6-0.1-2.4-0.4
          s-1.4-0.7-2-1.2s-1-1.2-1.4-2c-0.3-0.8-0.5-1.7-0.5-2.7s0.2-1.9,0.5-2.7s0.9-1.6,1.5-2.2c0.7-0.6,1.4-1.1,2.3-1.5s1.9-0.5,3-0.5
          s2,0.2,2.9,0.5c0.9,0.4,1.6,0.8,2.2,1.5c0.6,0.6,1.1,1.4,1.4,2.3s0.5,1.8,0.5,2.9c0,0.6-0.1,1.2-0.2,1.8c-0.1,0.6-0.3,1.1-0.5,1.7
          s-0.5,1.1-0.8,1.6s-0.7,1.1-1.1,1.7l-5.4,7.8c-0.1,0.2-0.3,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.9,0.2h-2.5L289.2,107.1z M313.4,104.9
          c0,1.9-0.2,3.6-0.6,5s-1,2.6-1.7,3.5s-1.6,1.6-2.6,2.1s-2,0.7-3.2,0.7c-1.1,0-2.2-0.2-3.2-0.7s-1.8-1.1-2.5-2.1
          c-0.7-0.9-1.3-2.1-1.7-3.5s-0.6-3.1-0.6-5s0.2-3.6,0.6-5.1c0.4-1.4,1-2.6,1.7-3.5s1.6-1.6,2.5-2.1c1-0.5,2-0.7,3.2-0.7
          c1.1,0,2.2,0.2,3.2,0.7s1.8,1.1,2.6,2.1c0.7,0.9,1.3,2.1,1.7,3.5C313.2,101.3,313.4,103,313.4,104.9z M310.5,104.9
          c0-1.7-0.1-3.1-0.4-4.3c-0.3-1.2-0.7-2.1-1.1-2.8c-0.5-0.7-1-1.2-1.7-1.5c-0.6-0.3-1.3-0.5-2-0.5s-1.3,0.2-2,0.5s-1.2,0.8-1.7,1.5
          s-0.9,1.6-1.1,2.8c-0.3,1.2-0.4,2.6-0.4,4.3s0.1,3.1,0.4,4.3c0.3,1.1,0.7,2.1,1.1,2.8c0.5,0.7,1,1.2,1.7,1.5
          c0.6,0.3,1.3,0.5,2,0.5s1.3-0.2,2-0.5c0.6-0.3,1.2-0.8,1.7-1.5s0.9-1.6,1.1-2.8C310.4,108,310.5,106.6,310.5,104.9z M330.1,113.9
          v2.1h-11.9v-2.1h4.8V98.7c0-0.5,0-0.9,0-1.4l-4,3.4c-0.1,0.1-0.3,0.2-0.4,0.2s-0.3,0-0.4,0s-0.2-0.1-0.3-0.1
          c-0.1-0.1-0.2-0.1-0.2-0.2l-0.9-1.2l6.6-5.7h2.2v20.2C325.6,113.9,330.1,113.9,330.1,113.9z M347.6,113.4c0.3,0,0.5,0.1,0.7,0.3
          c0.2,0.2,0.3,0.4,0.3,0.7v1.7h-14.8v-0.9c0-0.2,0-0.4,0.1-0.6s0.2-0.4,0.4-0.6l7.1-7.1c0.6-0.6,1.1-1.2,1.6-1.7
          c0.5-0.6,0.9-1.1,1.2-1.7c0.3-0.6,0.6-1.1,0.8-1.7s0.3-1.2,0.3-1.8c0-0.7-0.1-1.2-0.3-1.7s-0.5-0.9-0.8-1.2s-0.8-0.6-1.3-0.7
          c-0.5-0.2-1-0.2-1.6-0.2s-1.1,0.1-1.6,0.2c-0.5,0.2-0.9,0.4-1.3,0.7c-0.4,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.4,0.8-0.5,1.3
          c-0.1,0.4-0.3,0.6-0.5,0.7s-0.5,0.2-0.9,0.1l-1.4-0.2c0.1-1,0.4-1.9,0.8-2.7c0.4-0.8,0.9-1.4,1.6-2c0.6-0.5,1.4-0.9,2.2-1.2
          c0.8-0.3,1.7-0.4,2.7-0.4c0.9,0,1.8,0.1,2.6,0.4c0.8,0.3,1.5,0.7,2.1,1.2s1.1,1.2,1.4,1.9s0.5,1.6,0.5,2.6c0,0.8-0.1,1.6-0.4,2.3
          c-0.2,0.7-0.6,1.4-1,2s-0.9,1.3-1.5,1.9s-1.1,1.2-1.8,1.8l-5.9,6c0.4-0.1,0.8-0.2,1.3-0.3c0.4-0.1,0.8-0.1,1.2-0.1L347.6,113.4
          L347.6,113.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#2FB357"
            points="345.7,41.4 300.59,41.4 300.59,37.79 301.64,37.79 301.64,40.35 344.65,40.35 344.65,15.85
          301.64,15.93 301.64,18.41 300.59,18.41 300.59,14.88 345.7,14.8 		"
          />
        </g>
        <g>
          <g>
            <path
              fill="#0650A0"
              d="M243.35,33.2c-0.25,0.38-0.56,0.73-0.93,1.06c-0.37,0.33-0.79,0.62-1.26,0.86
            c-0.47,0.24-0.98,0.43-1.53,0.57c-0.55,0.14-1.13,0.21-1.74,0.21c-1.1,0-2.11-0.19-3.05-0.58c-0.94-0.39-1.75-0.93-2.44-1.61
            c-0.69-0.69-1.23-1.5-1.61-2.45c-0.39-0.94-0.58-1.98-0.58-3.1c0-1.12,0.19-2.16,0.58-3.1c0.39-0.94,0.93-1.76,1.61-2.45
            c0.69-0.69,1.5-1.23,2.44-1.61c0.94-0.39,1.95-0.58,3.05-0.58c0.92,0,1.83,0.18,2.75,0.54c0.92,0.36,1.7,0.96,2.35,1.79
            l-1.31,0.98c-0.15-0.24-0.36-0.47-0.62-0.71c-0.26-0.24-0.57-0.44-0.91-0.62c-0.34-0.18-0.71-0.33-1.09-0.44
            c-0.39-0.11-0.78-0.17-1.17-0.17c-0.94,0-1.79,0.17-2.54,0.52c-0.75,0.35-1.38,0.81-1.91,1.4c-0.52,0.58-0.92,1.26-1.2,2.03
            c-0.28,0.77-0.42,1.58-0.42,2.43c0,0.85,0.14,1.66,0.42,2.43c0.28,0.77,0.68,1.45,1.2,2.03s1.16,1.05,1.91,1.4
            c0.75,0.35,1.6,0.52,2.54,0.52c0.39,0,0.77-0.03,1.16-0.1c0.38-0.07,0.76-0.19,1.12-0.35c0.37-0.17,0.71-0.39,1.04-0.68
            c0.33-0.28,0.63-0.64,0.91-1.07L243.35,33.2z"
            />
            <path
              fill="#0650A0"
              d="M247.16,34.16h6.87v1.37h-8.37V20.79h1.5V34.16z"
            />
            <path fill="#0650A0" d="M257.36,35.54h-1.5V20.79h1.5V35.54z" />
            <path
              fill="#0650A0"
              d="M262.65,34.16h7.89v1.37h-9.39V20.79h9.14v1.37h-7.64v5.02h7.14v1.37h-7.14V34.16z"
            />
            <path
              fill="#0650A0"
              d="M283.95,33.29H284v-12.5h1.5v14.74h-1.87l-8.79-12.62h-0.04v12.62h-1.5V20.79h1.87L283.95,33.29z"
            />
            <path
              fill="#0650A0"
              d="M294.01,35.54h-1.5V22.17h-4.94v-1.37h11.37v1.37h-4.94V35.54z"
            />
            <path
              fill="#0650A0"
              d="M300.53,20.79h5.48c0.76,0,1.49,0.07,2.17,0.21s1.27,0.38,1.78,0.72c0.51,0.34,0.91,0.8,1.21,1.37
            c0.3,0.58,0.45,1.3,0.45,2.18c0,0.86-0.14,1.58-0.42,2.17c-0.28,0.58-0.66,1.05-1.15,1.4c-0.49,0.35-1.06,0.59-1.73,0.74
            c-0.67,0.15-1.39,0.22-2.17,0.22h-2.37v5.75h-3.25V20.79z M303.78,27.04h2.17c0.29,0,0.57-0.03,0.84-0.08
            c0.27-0.06,0.51-0.15,0.73-0.28c0.22-0.13,0.39-0.31,0.52-0.54s0.2-0.52,0.2-0.86c0-0.37-0.09-0.68-0.26-0.91
            c-0.17-0.23-0.4-0.41-0.67-0.53c-0.27-0.13-0.57-0.2-0.91-0.24c-0.33-0.03-0.65-0.05-0.96-0.05h-1.67V27.04z"
            />
            <path
              fill="#0650A0"
              d="M316.75,20.79h2.69l6.41,14.74h-3.67l-1.27-3.12h-5.73l-1.23,3.12h-3.58L316.75,20.79z M318,25.08
            l-1.79,4.58h3.6L318,25.08z"
            />
            <path
              fill="#0650A0"
              d="M329.21,29.25l-5.54-8.45h4.04l3.12,5.52l3.31-5.52H338l-5.54,8.45v6.29h-3.25V29.25z"
            />
            <path
              fill="#0650A0"
              d="M337.85,32.57c0.29,0,0.57,0.07,0.84,0.22c0.27,0.15,0.48,0.36,0.64,0.63c0.15,0.27,0.23,0.56,0.23,0.86
            c0,0.29-0.07,0.58-0.23,0.85c-0.15,0.27-0.36,0.48-0.63,0.63c-0.27,0.15-0.55,0.23-0.85,0.23c-0.29,0-0.58-0.08-0.85-0.23
            c-0.27-0.15-0.48-0.36-0.63-0.63c-0.15-0.27-0.23-0.56-0.23-0.85c0-0.3,0.08-0.59,0.23-0.86c0.15-0.27,0.37-0.48,0.64-0.63
            C337.29,32.65,337.57,32.57,337.85,32.57 M337.85,32.85c-0.24,0-0.47,0.06-0.7,0.19c-0.23,0.12-0.4,0.3-0.53,0.53
            c-0.13,0.23-0.19,0.47-0.19,0.72c0,0.25,0.06,0.48,0.19,0.71c0.13,0.23,0.3,0.4,0.53,0.53c0.23,0.13,0.46,0.19,0.71,0.19
            c0.25,0,0.48-0.06,0.71-0.19c0.23-0.13,0.4-0.3,0.53-0.53c0.12-0.23,0.19-0.46,0.19-0.71c0-0.25-0.06-0.49-0.19-0.72
            c-0.13-0.23-0.31-0.4-0.53-0.53C338.33,32.92,338.09,32.85,337.85,32.85 M337.2,35.13v-1.76h0.6c0.21,0,0.36,0.01,0.45,0.05
            c0.09,0.03,0.17,0.09,0.22,0.17c0.05,0.08,0.08,0.17,0.08,0.26c0,0.13-0.05,0.24-0.14,0.33c-0.09,0.1-0.21,0.15-0.37,0.16
            c0.06,0.03,0.11,0.05,0.15,0.09c0.07,0.07,0.16,0.19,0.26,0.35l0.21,0.35h-0.35l-0.15-0.28c-0.13-0.22-0.22-0.35-0.3-0.41
            c-0.05-0.04-0.13-0.06-0.23-0.06h-0.16v0.75H337.2z M337.48,34.14h0.34c0.16,0,0.28-0.03,0.34-0.07
            c0.06-0.05,0.09-0.11,0.09-0.19c0-0.05-0.01-0.1-0.04-0.14c-0.03-0.04-0.07-0.07-0.12-0.09c-0.05-0.02-0.15-0.03-0.29-0.03h-0.32
            V34.14z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

PlaceHolderClientPay.propTypes = { isMobile: bool };

export default PlaceHolderClientPay;
