// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RecurringChargeInterval shallow bi monthly render 1`] = `
<Fragment>
  <div>
    <label
      className="hidden-element"
    >
      Interval
    </label>
    <div
      className="bi-monthly-label"
    >
      On the 
      <strong>
        last day of the month
      </strong>
       and the 
      <strong />
    </div>
  </div>
  <ForwardRef
    name="recurring_charge[schedule][interval_delay]"
    type="hidden"
    value="1"
  />
  <ForwardRef
    name="recurring_charge[schedule][interval_unit]"
    type="hidden"
    value="MONTH"
  />
</Fragment>
`;

exports[`RecurringChargeInterval shallow render 1`] = `
<Fragment>
  <ForwardRef
    name="recurring_charge[max_occurrences]"
    type="hidden"
    value="1"
  />
  <ForwardRef
    name="recurring_charge[schedule][interval_delay]"
    type="hidden"
    value="1"
  />
  <ForwardRef
    name="recurring_charge[schedule][interval_unit]"
    type="hidden"
    value="WEEK"
  />
</Fragment>
`;
