import client from '../../lib/ajax';

export const createOwner = async (path, owner) => {
  return  await client.post(
    path,
    { business_owner: owner }
  );
};
export const deleteOwner = async (path, owner) => {
  return  await client.destroy(
    path,
    { business_owner: owner }
  );
};
export const patchOwner = async (path, owner) => {
  return  await client.put(
    path,
    { business_owner: owner }
  );
};
