module.exports = {
  getData: (props, day) => {
    switch(props) {
    case 'transactions': 
      return {
        id: 'transactionsrange',
        disabledDays: { after: new Date(Date.now()) },
        toMonth: new Date(Date.now()), 
        dataObject: {
          selectedDays: day
        }
      };

    case 'monthly_deposits': 
      return {
        id: 'start_month',
        disabledDays: { after: new Date(Date.now()) },
        toMonth: new Date(Date.now()), 
        dataObject: {
          selectedDays: day
        }
      };

    case 'daily_deposits': 
      return {
        id: 'start_date',
        disabledDays: { after: new Date(Date.now()) },
        toMonth: new Date(Date.now()), 
        dataObject: {
          selectedDays: day
        }
      };

    default: 
      return {
        id: 'transactionsrange',
        disabledDays: { after: new Date(Date.now()) },
        toMonth: new Date(Date.now()), 
        dataObject: {
          selectedDays: day
        }
      };
    }
  }
};