import React, { useCallback, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { APButton } from 'affinipay-ui-library';
import './style.scss';

const escapeKeyPressCode = 27;

const TimelineOptions = ({
  selectedOption,
  options,
  handleTimelineSelection
}) => (
  <ul className="aptimeline-selector-options">
    {options.map((option, index) => {
      const isSelected = selectedOption === option.id;
      return (
        <li
          key={option.id}
          className={`aptimeline-selector-option${isSelected ? ' selected' : ''} ${selectedOption === 'custom_range' && index === 0 ? 'custom-selected' : ''}`}
          tabIndex={0}
          onClick={() => handleTimelineSelection(option.id)}
        >
          <div className="aptimeline-selector-option-text">{option.text}</div>
          <div className="aptimeline-selector-option-border" />
        </li>
      );
    })}
  </ul>
);

const APTimelineSelector = ({
  isSelectorOpen,
  selectedOption,
  options,
  parentClass,
  handleApplyRange,
  handleCloseSelection,
  handleTimelineSelection
}) => {
  const escapeKeyClick = useCallback((event) => {
    if (event.keyCode === escapeKeyPressCode) {
      handleCloseSelection();
    }
  }, [handleCloseSelection]);

  useEffect(() => {
    if (isSelectorOpen) document.addEventListener('keydown', escapeKeyClick);
    return () => {
      document.removeEventListener('keydown', escapeKeyClick);
    };
  }, [isSelectorOpen, escapeKeyClick]);

  return (
    <div className="aptimeline-selector" data-testid="aptimeline-selector">
      <TimelineOptions
        selectedOption={selectedOption}
        options={options}
        handleTimelineSelection={handleTimelineSelection}
      />
      {selectedOption === 'custom_range' && (
        <div className={`aptimeline-selector-submit ${parentClass ? parentClass : ''}`}>
          <APButton onClick={handleApplyRange}>
            Apply
          </APButton>
        </div>
      )}
    </div>
  );
};

TimelineOptions.propTypes = {
  selectedOption: string,
  options: arrayOf(shape({
    id: string,
    text: string,
    method: func
  })),
  handleTimelineSelection: func.isRequired
};

APTimelineSelector.propTypes = {
  isSelectorOpen: bool,
  options: arrayOf(shape({
    id: string,
    text: string,
    method: func
  })),
  parentClass: string,
  selectedOption: string,
  handleApplyRange: func.isRequired,
  handleCloseSelection: func.isRequired,
  handleTimelineSelection: func.isRequired
};

export default APTimelineSelector;
