import { getRoundedMonetaryNumber } from '../../../lib/monetaryUtils';
import { allowedURLParams } from '../helpers/constants';

export const getDisabledFieldsFromUrl = (params) => {
  const readonlyParamString = params.get('readOnlyFields'), amountRO = params.get('amount_ro'); // amount_ro: legacy field used by some partners. values that should disable amount field: true, 1
  if(!readonlyParamString && !amountRO) return [];
  const readonlyParams = readonlyParamString?.replace(/ |%20/g, '')?.split(',') || [];
  const disabledFields = readonlyParams.filter(param => allowedURLParams.includes(param) && params.get(param));
  if(!disabledFields.includes('amount') && (amountRO === '1' || amountRO === 'true') && params.get('amount')) disabledFields.push('amount'); // legacy field handler
  if (disabledFields.includes('name')) disabledFields.push('given_name', 'surname');
  return disabledFields;
};

export const getDefaultValuesFromUrl = (params) => {
  const presentParams = allowedURLParams.filter(param => {
    return !!params.get(param) && (param === 'amount' ? !!Number(params.get(param).replace(/\$|,/g, '')) : true);
  });
  return presentParams.reduce((rest, param) => {
    const urlValue = params.get(param);
    const decodedVal = param === 'amount' ? getRoundedMonetaryNumber(Number(urlValue.replace(/\$|,/g, '')))+'' : decodeURIComponent(urlValue.replace(/\+/g, '%20'));
    return param === 'name' ? 
      {...rest, [param]: decodedVal, given_name: decodedVal.split(' ')[0], surname: decodedVal.split(' ').slice(1).join(' ') }
      : {...rest, [param]: decodedVal};
  }, {});
};