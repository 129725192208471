import React from 'react';
import { States, allStates } from 'components/Select';
import PropTypes from 'prop-types';

class State extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: allStates.find(
        (option) => option.value === props.selected
      ),
    };
  }

  handleChange(value) {
    this.setState({
      selectedOption: allStates.find((option) => option.value === value),
    });
  }

  render() {
    const { label, name, id } = this.props;
    return (
      <div>
        <label>{label}</label>
        <States
          name={name}
          value={this.state.selectedOption}
          onChange={(option) => {
            this.handleChange(option.value);
          }}
          id={id}
        />
      </div>
    );
  }
}

State.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default State;
