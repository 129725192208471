import { allowedURLBillingParams, generalUrlParamToFieldMap, cardUrlParamToFieldNameMap, echeckUrlParamToFieldNameMap, allowedURLParams, allowedURLCustomFieldParams } from './constants';
import { getRoundedMonetaryNumber } from '../monetaryUtils';
import { isEmptyObject } from '../utils';

const _setFieldFromUrl = (params, paramName, state, setState, stateName) => {
  let field = params.get(paramName);
  if(!field) return;
  if(paramName === 'amount'){
    const amount = (field+'').replace(/\$|,/g, '');
    if( !Number(amount) ) return;
    field = getRoundedMonetaryNumber(amount)+'';
  }
  setState({...state, ...{[stateName]: field}});
};


const _getAllowedDisabledFieldsFromUrl = params => {
  const readonlyParamString = params.get('readOnlyFields'),
    amountRO = params.get('amount_ro'); //legacy field used by some partners. values that should disable amount field: true, 1
  if(!readonlyParamString && !amountRO) return;
  let filtered = [];
  if(readonlyParamString){
    const readonlyParam = readonlyParamString.replace(/ |%20/g, '').split(',');
    readonlyParam.map(el => {
      if(allowedURLParams.indexOf(el) !== -1 && params.get(el)) filtered = [...filtered, el];
    });
  }
  if(filtered.indexOf('amount') === -1 && (amountRO === '1' || amountRO === 'true') && params.get('amount')) filtered = [...filtered, 'amount']; //legacy field handler
  return filtered;
};

const _getDisabledStateFromUrl = (toDisable, nameMapObject, disabledState) => {
  if(!toDisable) return disabledState;
  let state = {...disabledState};
  toDisable.map(el => {
    if(nameMapObject[el]) state[nameMapObject[el]] = true;
  });
  return state;
};

const _setBillingFieldsFromUrl = (params, state, setStateFromURL, formFieldNames) => {
  let newState = {};
  allowedURLBillingParams.map(el => {
    const field = params.get(el);
    if(field) newState = {...newState, [formFieldNames[el]]:field};
  });
  if(!isEmptyObject(newState)) setStateFromURL({...state, ...newState});
};

const _setCustomFieldsFromUrl = (params, setStateFromURL, state) => {
  let newState = {};
  allowedURLCustomFieldParams.map(el => {
    const field = params.get(el);
    if(field) newState = {...newState, [el]:field};
  });
  if(!isEmptyObject(newState)) setStateFromURL({...state, ...newState});
};

const _fillState = (disabledState, allowedDisabledFields, paramMap) => ({...disabledState, ..._getDisabledStateFromUrl(allowedDisabledFields, paramMap, disabledState)});

const setFormFieldsStateFromUrl = (
  params,
  disabledFieldsState,
  setDisabledFieldsState,
  amountState,
  setAmountState,
  paymentInfoState,
  setPaymentInfoState,
  clientInfoState,
  setClientInfoState,
  customFieldsState,
  setCustomFieldsState,
  accepts_cards,
  cardState,
  setCardState,
  accepts_banks,
  echeckState,
  setEcheckState,
  frequencyState,
  setFrequencyState
) => {
  const allowedDisabledFields = _getAllowedDisabledFieldsFromUrl(params);

  let newDisabledFieldsState = {...disabledFieldsState};
  _setFieldFromUrl(params, 'amount', amountState, setAmountState, 'amountField');
  _setFieldFromUrl(params, 'reference', paymentInfoState, setPaymentInfoState, 'reference');
  _setFieldFromUrl(params, 'email', clientInfoState, setClientInfoState, 'email');
  _setFieldFromUrl(params, 'recur_frequency', frequencyState, setFrequencyState, 'recurFrequency');
  _setCustomFieldsFromUrl(params, setCustomFieldsState, customFieldsState);
  newDisabledFieldsState = _fillState(newDisabledFieldsState, allowedDisabledFields, generalUrlParamToFieldMap);
  if(accepts_cards) {
    _setBillingFieldsFromUrl(params, cardState, setCardState, cardUrlParamToFieldNameMap);
    newDisabledFieldsState = _fillState(newDisabledFieldsState, allowedDisabledFields, cardUrlParamToFieldNameMap);
  }
  if(accepts_banks) {
    _setBillingFieldsFromUrl(params, echeckState, setEcheckState, echeckUrlParamToFieldNameMap);
    newDisabledFieldsState = _fillState(newDisabledFieldsState, allowedDisabledFields, echeckUrlParamToFieldNameMap);
  }
  setDisabledFieldsState(newDisabledFieldsState);
};

export default setFormFieldsStateFromUrl;
