import React, { Component } from 'react';
import {bool, node, string} from 'prop-types';
import {rollbarLog} from './../../lib/utils';

const DefaultErrorComponent = () => (
  <>
    <h1>There was an error loading this page.</h1> 
    <h2>Please refresh the page.</h2> 
    <h3>If the issue continues please contact your Service Provider</h3>
  </>
);

const NullErrorComponent = () => null;

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    rollbarLog('Error Boundary' ,`Failed to load Component: ${error} Info: ${errorInfo.componentStack}`);
  }

  render() {
    const {children, showBoundaryMessage} = this.props;
    const errorComponents = {
      show: DefaultErrorComponent,
      noShow: NullErrorComponent
    };
    const type = showBoundaryMessage ? 'show' : 'noShow';
    const SpecificBoundaryMessage = errorComponents[type];

    return this.state.hasError ? <SpecificBoundaryMessage /> : children;
  }
}

ErrorBoundary.propTypes = {
  error: string,
  children: node,
  showBoundaryMessage: bool
};

ErrorBoundary.defaultProps = {
  showBoundaryMessage: true
};
