import React from 'react';
import {bool, func} from 'prop-types';
import {APButton, APTooltip} from 'affinipay-ui-library';
import './style.scss';
import IconEQB from '../../icons/icon-eqb';

const TooltipContactChangeContent = () => (
  <div className="recipient-change-tooltip">
    <h4 className="recipient-change-title">Changes to the Quick Bill recipient will cause:</h4>
    <ul>
      <li>Original Quick Bill to be deleted</li>
      <li>Resets the age back to zero</li>
      <li>Sends a new Quick Bill</li>
    </ul>
  </div>
);

const SaveAndResendButton = ({ handleSaveAndResend, submitting, contactChanged, isInvoicePending }) => (
  <APTooltip
    placement="top"
    disabled={!contactChanged}
    tooltipContent={<TooltipContactChangeContent />}
  >
    <APButton
      className="ap-primary-button trackable"
      onClick={handleSaveAndResend}
      disabled={submitting}
      dataset={{
        'data-testid': 'save-resend-button'
      }}
    >
      <IconEQB fill="#FFF" size="24"/>
      {`Save ${isInvoicePending ? '& Send' : '& Resend'}`}
    </APButton>
  </APTooltip>
);

export default SaveAndResendButton;

SaveAndResendButton.propTypes = {
  handleSaveAndResend: func.isRequired,
  submitting: bool,
  contactChanged: bool,
  isInvoicePending: bool
};
