import React from 'react';
import { shallow, mount } from 'enzyme/build';
import RecurringChargeDatepicker from './RecurringChargeDatepicker';

describe('RecurringChargeDatepicker', () => {
  it('shallow render', () => {
    Date.now = jest.fn(() => new Date(Date.UTC(2018, 7, 9, 8)).valueOf());
    const mockProps = getMockProps('new'),
      component = shallow(<RecurringChargeDatepicker {...mockProps} />);
    expect(component).toMatchSnapshot();
  });
  it('mount to test events', () => {
    const mockProps = getMockProps('weekly'),
      component = mount(<RecurringChargeDatepicker {...mockProps} />);
    component.setProps({ handleChange: jest.fn() });
    component.find('LegacyAPDatepicker').prop('onDayCallback')('foo');
    expect(component).toBeTruthy();
    component.unmount();
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'new':
    return {
      scheduleStartDate: 'Tue Oct 30 2018 00:00:00 GMT-0500 (Central Daylight Time)',
      recurStart: '1-Oct-2019',
      recurInitial: null
    };
  case 'weekly':
    return {
      recurFrequency: 'WEEKLY',
      scheduleStartDate: 'Tue Oct 30 2018 00:00:00 GMT-0500 (Central Daylight Time)',
      recurStart: '1-Oct-2019',
      recurInitial: null
    };
  default:
    return;
  }
};