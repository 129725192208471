export const features = {
  data: [
    {id: 1, type: 'flipper_feature', attributes: { id: 1, key: 'test_feature_1', created_at: '19-Jan-2021 03:17 pm' } },
    {id: 2, type: 'flipper_feature', attributes: { id: 2, key: 'test_feature_2', created_at: '19-Jan-2021 03:18 pm' } },
    {id: 3, type: 'flipper_feature', attributes: { id: 3, key: 'test_feature_3', created_at: '19-Jan-2021 03:19 pm' } },
    {id: 4, type: 'flipper_feature', attributes: { id: 4, key: 'test_feature_4', created_at: '19-Jan-2021 03:20 pm' } },
    {id: 5, type: 'flipper_feature', attributes: { id: 5, key: 'test_feature_5', created_at: '19-Jan-2021 03:21 pm' } },
    {id: 6, type: 'flipper_feature', attributes: { id: 6, key: 'test_feature_6', created_at: '19-Jan-2021 03:22 pm' } }
  ]
};

export const fullyEnabledFeatureGate = {
  meta: {total_record_count: 1, filtered_record_count: 1},
  data: [{
    id: 1, 
    type: 'flipper_gate',
    attributes: {
      id: 1,
      value: 'true', 
      key: 'boolean', 
      feature_key: 'test_feature_1',
      created_at: '19-Jan-2021 03:23 pm'
    }
  }]
};

export const featureGates = {
  meta: {total_record_count: 4, filtered_record_count: 4},
  data: [
    {
      id: 1, 
      type: 'flipper_gate',
      attributes: {
        id: 1,
        value: 'testActor1', 
        created_at: '23-Apr-2021 11:55 am',
        key: 'actors',
        feature_key: 'test_feature_1'
      }
    },
    {
      id: 2, 
      type: 'flipper_gate',
      attributes: {
        id: 2,
        value: 'testActor2', 
        created_at: '23-Apr-2021 11:56 am',
        key: 'actors',
        feature_key: 'test_feature_1'
      }
    },
    {
      id: 3, 
      type: 'flipper_gate',
      attributes: {
        id: 3,
        value: 'testActor3', 
        created_at: '23-Apr-2021 11:57 am',
        key: 'actors',
        feature_key: 'test_feature_1'
      }
    },
    {
      id: 4, 
      type: 'flipper_gate',
      attributes: {
        id: 4, 
        value: 'testActor4', 
        created_at: '23-Apr-2021 11:58 am',
        key: 'actors',
        feature_key: 'test_feature_1'
      }
    }
  ]
};