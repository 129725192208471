import React, {useState} from 'react';
import {bool, object, string} from 'prop-types';

import QuickBillActionsForPaid from './QuickBillActionsForPaid';
import QuickBillStatusBarPaid from './QuickBillStatusBarPaid';
import QuickBillActionsForPending from './QuickBillActionsForPending';
import QuickBillDetails from './QuickBillDetails';
import './style.scss';
import QuickBillStatusBarUnpaid from '../QuickBillStatusBarUnpaid';
import {bankAccountsProp, invoiceProp} from '../../../customProps';

const PaidQuickBill = ({bankAccounts = [], invoice}) => {
  const selectedBankAccountId = bankAccounts.map(ba => ba.xid).includes(invoice.bankAccountId) ? invoice.bankAccountId : null;
  return (
    <>
      <QuickBillStatusBarPaid invoice={invoice}/>
      <QuickBillActionsForPaid invoice={invoice}/>
      <QuickBillDetails
        bankAccounts={bankAccounts}
        invoice={invoice}
        selectedBankAccountId={selectedBankAccountId}/>
    </>
  );
};

const UnpaidQuickBill = ({
  attachmentDisabled,
  defaultBody,
  defaultSubject,
  bankAccounts,
  invoice,
  permissions,
  siteSupportPhone
}) => {
  const defaultSelectedBankAccountId = bankAccounts.map(ba => ba.xid).includes(invoice.bankAccountId) ? invoice.bankAccountId : null;
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(defaultSelectedBankAccountId);

  return (
    <>
      <QuickBillStatusBarUnpaid invoice={invoice}/>
      <QuickBillActionsForPending
        attachmentDisabled={attachmentDisabled}
        bankAccounts={bankAccounts}
        defaultBody={defaultBody}
        defaultSubject={defaultSubject}
        invoice={invoice}
        permissions={permissions}
        selectedBankAccount={selectedBankAccountId}
        setSelectedBankAccount={setSelectedBankAccountId}
        siteSupportPhone={siteSupportPhone}
      />
      <QuickBillDetails
        key={selectedBankAccountId}
        bankAccounts={bankAccounts}
        invoice={invoice}
        selectedBankAccountId={selectedBankAccountId}
      />
    </>
  );
};

const ShowQuickBill = ({
  attachmentDisabled,
  bankAccounts,
  defaultBody,
  defaultSubject,
  invoice,
  permissions,
  siteSupportPhone
}) => {
  const isInvoicePaid = invoice.status === 'paid';
  const SpecificQuickBillView = isInvoicePaid ? PaidQuickBill : UnpaidQuickBill;
  const quickBillProps = isInvoicePaid ? {invoice} : {
    attachmentDisabled,
    bankAccounts,
    defaultBody,
    defaultSubject,
    invoice,
    permissions,
    siteSupportPhone
  };
  return (<SpecificQuickBillView {...quickBillProps} />);
};

const showQuickBillProps = {
  attachmentDisabled: bool,
  defaultBody: string,
  defaultSubject: string,
  bankAccounts: bankAccountsProp,
  invoice: invoiceProp,
  permissions: object,
  siteSupportPhone: string
};

ShowQuickBill.propTypes = {...showQuickBillProps};
UnpaidQuickBill.propTypes = {...showQuickBillProps};
PaidQuickBill.propTypes = {
  bankAccounts: bankAccountsProp,
  invoice: showQuickBillProps.invoice
};

export default ShowQuickBill;
