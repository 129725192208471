import AccessToken from '../responses/AccessToken';
import Config from '../lib/Config';
import { rollbarLog as log } from '../../../../../lib/utils';
import server from '../../../../../lib/server';

const AccessTokenService = async () => {
  const errorMsg = 'Access Tokens Service Error';
  try {
    const resp = await server.get({
      url: Config.accessTokenUrl,
      credentials: 'include'
    });
    const json = await resp.json();
    return AccessToken(json);
  } catch (message) {
    log(errorMsg, message.message || message.statusText, 'warning');
    return AccessToken({ error: `${message.message || message.statusText} - admin uuid_base62:` });
  }
};

export default AccessTokenService;