import React from 'react';
import {array, bool, func, object, string} from 'prop-types';
import TrustIcon from '../../../components/icons/TrustIcon';

const AccountTitle = ({accountIsTrust, bankAccountPresent, bankName, maskedAccount}) => (
  <div className="account-title">
    {bankAccountPresent ? (
      <>
        <div className="account-title-name">
          {accountIsTrust
            ? <div className="account-title-trust"><TrustIcon size={'11'} />{`Trust - ${bankName}`}</div>
            : <span>{bankName}</span>}
        </div>
        {maskedAccount && <div className='account-title-masked-number'>{maskedAccount}</div>}
      </>
    ) : <span className="text-center">N/A</span>}
  </div>
);

const BankAccountDetails = ({account, handleModalOpen}) => {
  const {
    accountIsActive,
    accountIsTrust,
    bankName,
    bankAccountPresent,
    displayType,
    maskedAccount,
    name
  } = account;

  return (
    <tr className="account-details-row">
      <td>
        <AccountTitle
          accountIsTrust={accountIsTrust}
          bankAccountPresent={bankAccountPresent}
          bankName={bankName}
          maskedAccount={maskedAccount}
        />
      </td>
      <td>
        <div>
          <div>{name}</div>
          <div>{displayType}</div>
        </div>
      </td>
      <td>
        {accountIsActive
          ? <a className="ap-btn--secondary-small" onClick={() => handleModalOpen(account)}>Edit</a>
          : 'INACTIVE'}
      </td>
    </tr>
  );
};

const DepositAccountsListing = ({ accounts = [], handleModalOpen }) => (
  <tbody>
    {accounts.length === 0 ? (
      <tr>
        <td colSpan="3">No Accounts</td>
      </tr>
    ) : accounts.map((account, index) => (
      <BankAccountDetails
        key={`${account?.name}-${index}`}
        account={account}
        handleModalOpen={handleModalOpen}
      />
    ))}
  </tbody>
);

AccountTitle.propTypes = {
  accountIsActive: bool,
  accountIsTrust: bool,
  bankName: string,
  bankAccountPresent: bool,
  displayType: string,
  maskedAccount: string
};

BankAccountDetails.propTypes = {
  account: object,
  handleModalOpen: func
};

DepositAccountsListing.propTypes = {
  accounts: array,
  handleModalOpen: func
};

export default DepositAccountsListing;
