const manualPolicyData = [
  {
    name: 'manualCardNumber',
    text: 'Card Number', 
    value: 'manualCardNumber',
    checked: true,
    disabled: true
  }, 
  {
    name: 'manualAddress1',
    text: 'Address 1', 
    value: 'manualAddress1',
    checked: false,
    disabled: false
  }, 
  {
    name: 'manualCardExpiration',
    text: 'Card Expiration', 
    value: 'manualCardExpiration',
    checked: true,
    disabled: true
  },
  {
    name: 'manualCity',
    text: 'City', 
    value: 'manualCity',
    checked: false,
    disabled: false
  },
  {
    name: 'manualName',
    text: 'Name on card', 
    value: 'manualName',
    checked: false,
    disabled: false
  },
  {
    name: 'manualState',
    text: 'State', 
    value: 'manualState',
    checked: false,
    disabled: false
  },
  {
    name: 'manualCardCVV',
    text: 'Card CVV', 
    value: 'manualCardCVV',
    checked: false,
    disabled: false
  },  
  {
    name: 'manualPostalCode',
    text: 'Postal Code', 
    value: 'manualPostalCode',
    checked: false,
    disabled: false
  },
  {
    name: 'manualEmail',
    text: 'Email address', 
    value: 'manualEmail',
    checked: false,
    disabled: false
  },  
  {
    name: 'manualCountry',
    text: 'Country', 
    value: 'manualCountry',
    checked: false,
    disabled: false
  }
];

const cardPolicyData = [
  {
    name: 'cardCardNumber',
    text: 'Card Number', 
    value: 'cardCardNumber',
    checked: true,
    disabled: true
  },
  {
    name: 'cardAddress1',
    text: 'Address 1', 
    value: 'cardAddress1',
    checked: false,
    disabled: false
  }, 
  {
    name: 'cardCardExpiration',
    text: 'Card Expiration', 
    value: 'cardCardExpiration',
    checked: true,
    disabled: true
  }, 
  {
    name: 'cardCity',
    text: 'City', 
    value: 'cardCity',
    checked: false,
    disabled: false
  },
  {
    name: 'cardName',
    text: 'Name on card', 
    value: 'cardName',
    checked: false,
    disabled: false
  },
  {
    name: 'cardState',
    text: 'State', 
    value: 'cardState',
    checked: false,
    disabled: false
  }, 
  {
    name: 'cardCardCVV',
    text: 'Card CVV', 
    value: 'cardCardCVV',
    checked: false,
    disabled: false
  },  
  {
    name: 'cardPostalCode',
    text: 'Postal Code', 
    value: 'cardPostalCode',
    checked: false,
    disabled: false
  },
  {
    name: 'cardEmail',
    text: 'Email address', 
    checked: false,
    value: 'cardEmail',
    disabled: false
  },
  {
    name: 'cardCountry',
    text: 'Country', 
    value: 'cardCountry',
    checked: false,
    disabled: false
  }
];

const echeckPolicyData = [
  {
    name: 'echeckName',
    text: 'Name', 
    value: 'echeckName',
    checked: false,
    disabled: false
  },
  {
    name: 'echeckState',
    text: 'State', 
    value: 'echeckState',
    checked: false,
    disabled: false
  },
  {
    name: 'echeckEmail',
    text: 'Email address', 
    value: 'echeckEmail',
    checked: false,
    disabled: false
  },
  {
    name: 'echeckPostalCode',
    text: 'Postal Code', 
    value: 'echeckPostalCode',
    checked: false,
    disabled: false
  },
  {
    name: 'echeckAddress1',
    text: 'Address 1', 
    value: 'echeckAddress1',
    checked: false,
    disabled: false
  },
  {
    name: 'echeckCountry',
    text: 'Country', 
    value: 'echeckCountry',
    checked: false,
    disabled: false
  },
  {
    name: 'echeckCity',
    text: 'City', 
    value: 'echeckCity',
    checked: false,
    disabled: false
  }
];

const manualCvvCheck = [
  {
    text: 'Optional',
    value: 'optional',
    selected: true
  },
  {
    text: 'Disabled',
    value: 'disabled'
  },
  {
    text: 'Lenient Match',
    value: 'lenientMatch'
  },
  {
    text: 'Strict Match',
    value: 'strictMatch'
  }
];

const manualAvsCheck = [
  {
    text: 'Disabled',
    value: 'disabled'
  },
  {
    text: 'Submit but ignore Results',
    value: 'submitIgnoreResult',
    selected: true
  },
  {
    text: 'Postal Code Match - Strict',
    value: 'postalCodeMatchStrict'
  },
  {
    text: 'Address only Match - Strict',
    value: 'addressMatchStrict'
  },
  {
    text: 'Address OR Postal Code Match - Lenient',
    value: 'addressOrPostalCodeMatchLenient'
  },
  {
    text: 'Address OR Postal Code Match - Strict',
    value: 'addressOrPostalCodeMatchStrict'
  },
  {
    text: 'Address AND Postal Code Match - Lenient',
    value: 'addressAndPostalCodeMatchLenient'
  },
  {
    text: 'Address AND Postal Code Match - Strict',
    value: 'addressAndPostalCodeMatchStrict'
  }
];

const cardCvvCheck = [
  {
    text: 'Optional',
    value: 'optional',
    selected: false
  },
  {
    text: 'Disabled',
    value: 'disabled',
    selected: true
  },
  {
    text: 'Lenient Match',
    value: 'lenientMatch',
    selected: false
  },
  {
    text: 'Strict Match',
    value: 'strictMatch',
    selected: false
  }
];

const cardAvsCheck = [
  {
    text: 'Disabled',
    value: 'disabled',
    selected: true
  },
  {
    text: 'Submit but ignore Results',
    value: 'submitIgnoreResult',
    selected: false
  },
  {
    text: 'Postal Code Match - Strict',
    value: 'postalCodeMatchStrict',
    selected: false
  },
  {
    text: 'Address only Match - Strict',
    value: 'addressMatchStrict',
    selected: false
  },
  {
    text: 'Address OR Postal Code Match - Lenient',
    value: 'addressOrPostalCodeMatchLenient',
    selected: false
  },
  {
    text: 'Address OR Postal Code Match - Strict',
    value: 'addressOrPostalCodeMatchStrict',
    selected: false
  },
  {
    text: 'Address AND Postal Code Match - Lenient',
    value: 'addressAndPostalCodeMatchLenient',
    selected: false
  },
  {
    text: 'Address AND Postal Code Match - Strict',
    value: 'addressAndPostalCodeMatchStrict',
    selected: false
  }
];

const holdPeriodDropdown = [
  {
    text: '0 Days',
    value: '0'
  },
  {
    text: '1 Day',
    value: '1'
  },
  {
    text: '2 Days',
    value: '2', 
    selected: true
  },
  {
    text: '3 Days',
    value: '3'
  },
  {
    text: '4 Days',
    value: '4'
  },
  {
    text: '5 Days',
    value: '5'
  }
];

export {
  manualPolicyData,
  cardPolicyData,
  echeckPolicyData,
  cardAvsCheck,
  cardCvvCheck,
  manualAvsCheck,
  manualCvvCheck,
  holdPeriodDropdown
};