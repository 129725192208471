/* global Intercom */

export const sendEvent = (eventName, metadata = {}) => {
  if (window.Intercom) {
    Intercom('trackEvent', eventName, metadata);
  }
};

export const EventNames = {
  quickBillButtonClicked: 'Quick Bill Button',
  quickBillCreated: 'Quick Bill Sent',
  quickBillCreatedWithAttachments: 'Quick Bill Sent With Attachment(s)',
  markQuickBillAsPaid: 'Marked Quick Bill as Paid',
  exportedQuickBooksOnlineFile: 'Exported QuickBooks Online file',
  exportedCSVFile: 'Exported CSV file',
  exportedXLSFile: 'Exported Excel file',
  exportedIIFFile: 'Exported QuickBooks Desktop file',
  ClickedPCIBanner: 'Clicked PCI Banner',
  DismissedPCIBanner: 'Dismissed PCI Banner',
  WentToControlScan: 'Went to ControlScan Questionnaire',
  WentToSupportCenterForPCI: 'Went to Support Center from PCI Status',
  launchedPaymentPage: 'Launched Payment Page',
  createdScheduledPayment: 'Created Scheduled Payment',
  rechargedAPayment: 'Recharged a Payment',
  refundedAPayment: 'Refunded a Payment',
  copiedPaymentPageUrl: 'Copy and Paste Link',
  acceptEcheckAgreement: 'Accepted eCheck Agreement',
  wentToExtensionListing: 'Went to Extension Listing',
  rejectedEcheckAgreement: 'Rejected eCheck Agreement',
  clickedSwipeButton: 'Clicked Swipe Button',
  referralButtonClicked: 'Referral Button'
};
