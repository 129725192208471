import React, {useState} from 'react';
import './style.scss';
import ChangePlanSection from './ChangePlanSection';
import SubscriptionFeesSection from '../SubscriptionFeesSection';
import CardFeesSection from '../CardFeesSection';
import EcheckFeesSection from './EcheckFeesSection';
import { APButton, APFormMessageBox, APModal } from 'affinipay-ui-library';
import {addClass, hasClass, removeClass} from '../../../lib/utils';
import ChangePlanForm from './ChangePlanForm';
import PropTypes from 'prop-types';


const PricingForm = ({
  sites = [],
  feeNames = [{text: 'Program Fee', selected: true}],
  frequencies = [{text: 'Monthly'}, {text: 'Yearly'}],
  planName,
  planDescription,
  initialValues = {}
}) => {

  let initialFormState = {
    subscriptionFeeRows: undefined,
    creditCardFee: undefined,
    visaMCDiscoverFee: undefined,
    chargebackFee: undefined,
    debitCardFee: undefined,
    amexFee: undefined,
    foreignFee: undefined,
    eCheckBaseRate: undefined,
    additionalBase: undefined,
    returnFee: undefined
  };

  initialFormState = {...initialFormState, ...initialValues};

  const [formState, setFormState] = useState(initialFormState);
  const [showFormError, setShowFormError] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendFormState();
    }
  };

  const handleSubscriptionRowsChange = (feeRows) => {
    setFormState({...formState, subscriptionFeeRows: feeRows});
  };

  const validateForm = () => {
    let isValid = true;

    for (const prop in formState) { // eslint-disable-line no-unused-vars
      if (prop !== 'subscriptionFeeRows' && !formState[prop]) {
        const invalidField = document.querySelector(`input[name='${prop}']`);
        addClass(invalidField.parentElement, 'error');
        if (!showFormError) {
          setShowFormError(true);
          isValid = false;
        }
      }
    }

    formState.subscriptionFeeRows.map((el, i) => {
      for (const prop in el) { // eslint-disable-line no-unused-vars
        if (!el[prop]) {
          const invalidField = document.querySelectorAll(`input[name='${prop}']`)[i] || document.querySelectorAll(`.${prop}`)[i];
          addClass(invalidField.parentElement, 'error');
          if (!showFormError) {
            setShowFormError(true);
            isValid = false;
          }
        }
      }
    });

    if (isValid) {
      setShowFormError(false);
    }
    return isValid;
  };

  const onInputValidation = (name, value, rowNumber) => {
    let invalidField;
    if (rowNumber || rowNumber === 0) {
      invalidField = document.querySelectorAll(`input[name='${name}']`)[rowNumber] || document.querySelectorAll(`.${name}`)[rowNumber];
    } else {
      invalidField = document.querySelector(`input[name='${name}']`);
    }
    if (value && hasClass(invalidField.parentElement, 'error')) {
      removeClass(invalidField.parentElement, 'error');
    }
    if (!document.querySelector('.error')) {
      setShowFormError(false);
    }
  };

  const sendFormState = () => {
  };

  return (
    <div className="admin">
      <form className="merchant-pricing">
        <ChangePlanSection
          sites={sites}
          planName={planName}
          planDescription={planDescription}/>
        <div className="row form-group">
          <div className="col-xs-12">
            <APButton className='ap-admin-tertiary-button'
              onClick={(e) => {
                e.preventDefault();
                setIsModalShown(!isModalShown);
              }}>
              Change Plan
            </APButton>
          </div>
        </div>
        <hr/>
        <SubscriptionFeesSection
          feeNames={feeNames}
          frequencies={frequencies}
          onRowsChange={handleSubscriptionRowsChange}
          handleValidationError={onInputValidation}/>
        <hr/>
        <CardFeesSection
          setFormState={setFormState}
          formState={formState}
          handleValidationError={onInputValidation}/>
        <hr/>
        <EcheckFeesSection
          setFormState={setFormState}
          formState={formState}
          handleValidationError={onInputValidation}/>
        {showFormError && (
          <APFormMessageBox type="error">
            All fields are required
          </APFormMessageBox>
        )}
        <APButton onClick={submitForm}>
          Save Plan
        </APButton>
      </form>
      <APModal isActiveInitial={isModalShown}
        onClose={() => {
          setIsModalShown(!isModalShown);
        }}>
        <ChangePlanForm currentPlan='testplan'
          availablePlans={[{text: 'testPlan'}, {text: 'testPlan2'}]}
          submitForm={() => {
            setIsModalShown(false);
          }}/>
      </APModal>
    </div>
  );
};

export default PricingForm;

PricingForm.propTypes = {
  sites: PropTypes.array,
  feeNames: PropTypes.array,
  frequencies: PropTypes.array,
  planName: PropTypes.string,
  planDescription: PropTypes.string,
  initialValues: PropTypes.object
};
