import React, {useState} from 'react';
import {bool, func, number, object, shape, string} from 'prop-types';
import {APButton, APFormMessageBox, APModal} from 'affinipay-ui-library';
import { safeSendGA } from '../../../lib/utils';
import MerchantAppDepositService from './services';
import './style.scss';

const reasonsList = [
  {id: 'risk', text: '45% of firms report that Pay Later de-risked their business'},
  {id: 'service', text: '53% of firms saw an increased number of clients able to pay for their services'},
  {id: 'pr-image', text: '45% of firms said that Pay Later improved the image of the company'}
];

const LastChanceModal = ({appId, checkboxId, closeModal, data, formId, isModalOpen, supportPhone}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [optInDisabled, setOptInDisabled] = useState(false);

  const handleOptInClick = async () => {
    safeSendGA('Pay Later Opt In Button', 'Pay Later Opt In Button Clicked');
    setOptInDisabled(true);
    const clientCreditCheckbox = document.getElementById(checkboxId);
    clientCreditCheckbox.checked = true;

    const depositForm = document.getElementById(formId);
    const depositFormData = new FormData(depositForm);

    depositFormData.set('forms_registrations_deposit_step_form[cc_intent_status]', true);
    const body = {};
    for(const [key, value] of depositFormData) {
      body[key] = value;
    }

    const {status, url} = await MerchantAppDepositService.patchMerchantAppDeposit(data['data-path'], body);

    if (status === 200) {
      setOptInDisabled(false);
      window.location.href = url;
    } else {
      setOptInDisabled(false);
      setShowErrorMessage(true);
    }
  };

  const handleOptOutClick =  () => {
    safeSendGA('Pay Later Opt Out Button', 'Pay Later Opt Out Button Clicked');
  };

  return (
    <APModal
      className='client-credit-last-chance-modal'
      headerText='Why our customers love Pay Later'
      isActiveInitial={isModalOpen}
      onClose={closeModal}
    >
      {showErrorMessage && (
        <APFormMessageBox type="error">
          <p
            data-testid="client-credit-last-chance-error"
            className="client-credit-last-chance-error"
          >
            An error occurred while enabling Pay Later, please contact support at <a className="client-credit-last-chance-phone" href={`tel:${supportPhone}`}>{supportPhone}</a>.
          </p>
        </APFormMessageBox>
      )}
      <ul className="client-credit-last-chance-reasons">
        {reasonsList.map(({id, text}) => (
          <li key={id}>{text}</li>
        ))}
      </ul>
      <div className="client-credit-last-chance-quote">
        <p>&quot;I have retained clients that otherwise would not have had the funds to pay for my services.&quot;</p>
        <p>(Firm using Pay Later)</p>
      </div>
      <h4>Not ready for Pay Later yet?</h4>
      <p>If you change your mind anytime, please call support at <a className="client-credit-last-chance-phone" href={`tel:${supportPhone}`}>{supportPhone}</a></p>
      <div className="client-credit-last-chance-buttons">
        <APButton
          id="client-credit-last-chance-opt-out"
          className="client-credit-opt-out"
          dataset={{...data, 'data-app_id': appId}}
          onClick={handleOptOutClick}
        >
          Not Right Now
        </APButton>
        <APButton
          id="client-credit-last-chance-opt-in"
          className={`client-credit-opt-in ${optInDisabled ? 'disabled' : ''}`}
          type="button"
          disabled={optInDisabled}
          dataset={{
            ...data,
            'data-testid': 'client-credit-last-chance-opt-in',
            'data-app_id': appId
          }}
          onClick={handleOptInClick}
        >
          Yes, I&#39;ll Try Pay Later
        </APButton>
      </div>
    </APModal>
  );
};

LastChanceModal.propTypes = {
  appId: number.isRequired,
  checkboxId: string.isRequired,
  closeModal: func.isRequired,
  formId: string.isRequired,
  isModalOpen: bool,
  data: shape({
    'data-action': string,
    'data-form': string,
    'data-path': string,
    'data-step': string,
    'data-options': object
  }).isRequired,
  supportPhone: string.isRequired
};

export default LastChanceModal;
