import React from 'react';
import { shallow, mount } from 'enzyme';
import Masked from './masked';

describe('Masked Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(
      <Masked
        value={'fbc'}
        name={'bar'}
        placeholder={'foobar'}
        mask={['[abc]']}
      />
    );
    expect(component).toMatchSnapshot();
  });
  it('mount for events', () => {
    const component = mount(
      <Masked
        value={'fbc'}
        name={'bar'}
        placeholder={'foobar'}
        mask={['(?-mix:(.*))/']}
      />
    );
    component.instance().handleChange('123');
    expect(component).toBeTruthy();
    component.unmount();
  });
});
