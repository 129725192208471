export const isTrustAccount = successfulPayment => {
  if (!successfulPayment) return false;
  return successfulPayment.payment?.bankAccountType === 'iolta';
};

export const defaultMonetaryOutput = {
  bankDetails: {
    bankAccount: undefined,
    bankName: undefined,
    isTrustAccount: false
  },
  paymentTotal: {
    amount: undefined,
    currency: undefined,
    label: undefined
  },
  depositDetails: {
    currency: undefined,
    surchargeAmount: undefined
  }
};

export const getMonetaryDetails = (invoice = {}, invoiceDetails = {}, transaction = {}) => {
  if (!Object.keys(invoice).length) return defaultMonetaryOutput;

  const { currency, invoicePayments = [] } = invoice;
  const successfulPayment = invoicePayments.find(
    ({ payment }) => ['authorized', 'completed'].includes(payment?.status)
  );

  if (!successfulPayment) return defaultMonetaryOutput;

  const { payment: { bankAccount, bankName } } = successfulPayment;
  const amount = (transaction.status === 'FAILED' || transaction.status === 'VOIDED') ? 0 : invoiceDetails.amount + invoiceDetails.surcharge;

  return {
    bankDetails: {
      bankAccount,
      bankName,
      isTrustAccount: isTrustAccount(successfulPayment)
    },
    paymentTotal: {
      amount,
      currency,
      failedTransaction: transaction.status === 'FAILED',
    },
    depositDetails: {
      currency,
      surchargeAmount: transaction.status === 'VOIDED' ? 0 : invoiceDetails.surcharge,
    }
  };
};

const defaultInvoiceDetails = {
  amount: undefined,
  surcharge: undefined,
  status: 'Failed'
};

export const getInvoiceDetails = (invoice, transactionId) => {
  const invoicePayment = invoice.invoicePayments.find(
    ({ payment }) => payment.transactionId === transactionId
  );

  if (!invoicePayment || invoicePayment.payment?.status === 'transaction_failed') {
    return defaultInvoiceDetails;
  }

  return {
    amount: invoicePayment.amount,
    surcharge: invoicePayment.surchargeAmount || 0,
    status: invoicePayment.payment.status
  };
};

/* Per https://affinipay.atlassian.net/browse/VTUPF-241
    When a Failed Transaction occurs, display
      * '--' in the Associated Invoices →  Amount and Surcharge columns
      * '--' in the Associated Invoices →  Total Amount column
    When a Transaction is Voided, display
      * $0.00 in the Associated Invoices →  Amount column
      * '--' Associated Invoices →  Surcharge column
      * $0.00 in the Total Amount column
*/
export const gatherPaymentValues = (invoices = [], transaction = {}) =>
  invoices.reduce((displayValues, invoice) => {
    if (!invoice || !Object.keys(invoice).length) return displayValues;

    const { id, invoiceDate, currency, amountPaid, contact, created, reference } = invoice;
    const invoiceDetails = getInvoiceDetails(invoice, transaction.gatewayId) || {};

    let totalAmountPaid;

    if (transaction.status === 'VOIDED' || transaction.status === 'FAILED') {
      totalAmountPaid = 0;
    } else {
      totalAmountPaid = invoiceDetails.amount || amountPaid;
    }

    const invoiceOutput = {
      ...getMonetaryDetails(invoice, invoiceDetails, transaction),
      id,
      invoiceDate,
      amountPaid: { subTotal: totalAmountPaid, currency, showAmount: transaction.status !== 'FAILED' },
      nameReference: { displayName: contact?.displayName, reference },
      timestamp: created?.timestamp,
    };

    return [...displayValues, invoiceOutput];
  }, []);
