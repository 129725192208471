import React from 'react';
import {func, object} from 'prop-types';
import { APInputMonetary, APInputPercent } from 'affinipay-ui-library';

const EcheckRateTransactionFee = ({
  formState,
  setFormState, 
  handleValidationError
}) => {

  const onUserInput = e => {
    const {name, value} = e.target;
    setFormState({...formState, [name]: value});
    handleValidationError(name, value);
  };

  return (
    <section className='inputSection'>
      <div className='row'>
        <div className='col-md-4'>
          <APInputPercent 
            label="eCheck Base Rate"
            name='echeckBaseRate'
            onChange={onUserInput}
            parentClass="admin"
            required={true}
            value={formState.echeckBaseRate}
          />
        </div>
        <div className='col-md-4'>
          <APInputMonetary
            label="Additional Per Transaction"
            name="additionalPerTransaction"
            onChange={onUserInput}
            parentClass="admin"
            value={formState.additionalPerTransaction}
          />
        </div>
        <div className='col-md-4'>
          <APInputMonetary
            label="Return Fee"
            name="returnFee"
            onChange={onUserInput}
            parentClass="admin"
            value={formState.returnFee}
          />
        </div>
      </div>
    </section>
  );
};

EcheckRateTransactionFee.propTypes = {
  formState: object,
  setFormState: func,
  handleValidationError: func
};

export default EcheckRateTransactionFee;