import React from 'react';
import { APInputText, APInputMask, APSelect, APButton, APControlledCheckbox, APDatepicker } from 'affinipay-ui-library';
import { usStates, countries } from 'lib/constants';
import { titles, identificationTypes } from './helpers/constants';
import notify from 'lib/notify';
import { buildIdentificationTypeFormState, isBeneficialOwnerFormValid } from './helpers/utils';
import { bool, func, object } from 'prop-types';

const BeneficialOwnerForm = ({
  formState,
  setFormState,
  onSubmit,
  disabled,
  backToLegalEntities,
  isEditView = false
}) => {

  const onFormChange = (e) => {
    const {name, value} = e.target;
    setFormState(prevFormState => ({...prevFormState, [name]: value}));
  };

  const onFormCheckboxChange = (e) => {
    const {name, checked} = e.target;
    setFormState(prevFormState => ({...prevFormState, [name]: checked}));
  };

  const onFormAddressChange = (e) => {
    const {name, value} = e.target;
    setFormState(prevFormState => ({
      ...prevFormState,
      address: {
        ...prevFormState.address,
        [name]: value
      }
    }));
  };

  const onFormCountryChange = (e) => {
    const {name, value} = e.target;
    if (value !== 'us') {
      notify.error('Only US addresses are supported at this time.');
    }
    else {
      setFormState(prevFormState => ({
        ...prevFormState,
        address: {
          ...prevFormState.address,
          [name]: value
        }
      }));
    }
  };

  const onFormIdentificationChange = (e) => {
    const {name, value} = e.target;
    setFormState(prevFormState => ({
      ...prevFormState,
      identification: {
        ...prevFormState.identification,
        [name]: value
      }
    }));
  };

  const onFormIdentificationTypeChange = (e) => {
    const {value} = e.target;
    if (value === formState?.identification?.type) return;
    const newIdentificationState = buildIdentificationTypeFormState(value);
    setFormState(prevFormState => ({
      ...prevFormState,
      identification: newIdentificationState
    }));
  };

  const isFormValid = isBeneficialOwnerFormValid(formState);

  const {
    given_name, surname, title, phone_number, email, date_of_birth,
    address: { address1, address2, city, state, type: addressType, zip_code } = {},
    identification: { type: identificationType, number, state: identificationState, country: identificationCountry, expiration_date } = {},
    citizenship, years_at_residence, significant_ownership, significant_management
  } = formState || {};

  const filteredUsStatesOptions = usStates.map(state => ({...state, value: state.value.toLowerCase()}));

  return (
    <form onSubmit={onSubmit} data-testid='beneficial-owner-form'>
      <h3 className='beneficial-owner-title form'>
        <span>{isEditView ? 'Edit' : 'Create'} Beneficial Owner</span>
        <APButton onClick={backToLegalEntities} disabled={disabled} type='button'>
          Back to Legal
        </APButton>
      </h3>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-4'>
          <APInputText
            name='given_name'
            value={given_name}
            label='Given Name'
            minLength={1}
            maxLength={20}
            onChange={onFormChange}
            parentClass='admin'
            required={true}
            disabled={disabled}
          />
        </div>
        <div className='col-sm-4'>
          <APInputText
            name='surname'
            value={surname}
            label='Surname'
            minLength={1}
            maxLength={20}
            onChange={onFormChange}
            parentClass='admin'
            required={true}
            disabled={disabled}
          />
        </div>
        <div className='col-sm-4'>
          <APSelect
            name='title'
            options={titles}
            value={title}
            label='Title'
            onChange={onFormChange}
            required={true}
            disabled={disabled}
            needsNativeElement={true}
            parentClass='admin'
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-8'>
          <APInputText
            name='address1'
            value={address1}
            label='Address'
            maxLength={255}
            onChange={onFormAddressChange}
            parentClass='admin'
            required={true}
            disabled={disabled}
          />
        </div>
        <div className='col-sm-4'>
          <APInputText
            name='address2'
            value={address2 || ''}
            label='Additional Address'
            maxLength={255}
            onChange={onFormAddressChange}
            parentClass='admin'
            disabled={disabled}
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-4'>
          <APInputText
            name='city'
            value={city}
            label='City'
            minLength={1}
            maxLength={255}
            onChange={onFormAddressChange}
            parentClass='admin'
            required={true}
            disabled={disabled}
          />
        </div>
        <div className='col-sm-4'>
          <APSelect
            name='state'
            value={state}
            label='State'
            onChange={onFormAddressChange}
            options={filteredUsStatesOptions}
            required={true}
            disabled={disabled}
            needsNativeElement={true}
            parentClass='admin'
          />
        </div>
        <div className='col-sm-4'>
          <APInputMask
            name='zip_code'
            value={zip_code}
            label='Postal Code'
            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            onChange={onFormAddressChange}
            parentClass="admin"
            required={true}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-6'>
          <APSelect
            name='type'
            value={addressType}
            label='Country'
            onChange={onFormCountryChange}
            options={countries.map(country => ({...country, value: country.value.toLowerCase()}))}
            required={true}
            disabled={disabled}
            needsNativeElement={true}
            parentClass='admin'
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-8'>
          <APInputText
            name='email'
            value={email}
            label='Email'
            minLength={1}
            onChange={onFormChange}
            parentClass='admin'
            required={true}
            disabled={disabled}
          />
        </div>
        <div className='col-sm-4'>
          <APInputMask
            name='phone_number'
            value={phone_number}
            label='Phone'
            mask={[/[0-9]/, '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            onChange={onFormChange}
            parentClass="admin"
            required={true}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-4'>
          <APDatepicker
            name='date_of_birth'
            value={date_of_birth}
            labelText='Date Of Birth'
            datePickerId='date_of_birth'
            onChange={onFormChange}
            disabled={disabled}
            required={true}
            inputParentClassName='admin'
          />
        </div>
        <div className='col-sm-4'>
          <APSelect
            name='citizenship'
            value={citizenship}
            label='Citizenship'
            onChange={onFormChange}
            options={countries.map(country => ({...country, value: country.value.toLowerCase()}))}
            required={true}
            disabled={disabled}
            needsNativeElement={true}
            parentClass='admin'
          />
        </div>
        <div className='col-sm-4'>
          <APInputText
            name='years_at_residence'
            value={years_at_residence}
            label='Years At Residence'
            min={0}
            type='number'
            onChange={onFormChange}
            parentClass='admin'
            required={false}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-4'>
          <APSelect
            name='type'
            value={identificationType}
            label='Identification Type'
            onChange={onFormIdentificationTypeChange}
            options={identificationTypes}
            required={true}
            disabled={disabled}
            needsNativeElement={true}
            parentClass='admin'
          />
        </div>
        {identificationType === 'us_social_security' &&
          <div className='col-sm-8'>
            <APInputMask
              name='number'
              value={number}
              label='Social Security Number'
              mask={[/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
              onChange={onFormIdentificationChange}
              parentClass="admin"
              required={true}
              disabled={disabled}
            />
          </div>
        }
      </div>
      {identificationType === 'us_driver_license' &&
        <div className='row bottom-spacer-20'>
          <div className='col-sm-4'>
            <APInputText
              name='number'
              value={number}
              label='Drivers License Number'
              minLength={4}
              maxLength={24}
              onChange={onFormIdentificationChange}
              parentClass='admin'
              required={true}
              disabled={disabled}
            />
          </div>
          <div className='col-sm-4'>
            <APSelect
              name='state'
              value={identificationState}
              label='Issuing State'
              onChange={onFormIdentificationChange}
              options={filteredUsStatesOptions}
              required={true}
              disabled={disabled}
              needsNativeElement={true}
              parentClass='admin'
            />
          </div>
          <div className='col-sm-4'>
            <APDatepicker
              name='expiration_date'
              value={expiration_date}
              datePickerId='expiration_date'
              labelText='Expiration Date'
              onChange={onFormIdentificationChange}
              disabled={disabled}
              required={true}
              inputParentClassName='admin'
            />
          </div>
        </div>
      }
      {['visa', 'passport'].includes(identificationType) &&
        <div className='row bottom-spacer-20'>
          <div className='col-sm-4'>
            <APInputText
              name='number'
              value={number}
              label={identificationType === 'visa' ? 'Visa Number' : 'Passport Number'}
              minLength={4}
              maxLength={36}
              onChange={onFormIdentificationChange}
              parentClass='admin'
              required={true}
              disabled={disabled}
            />
          </div>
          <div className='col-sm-4'>
            <APSelect
              name='country'
              value={identificationCountry}
              label='Issuing Country'
              onChange={onFormIdentificationChange}
              options={countries.map(country => ({...country, value: country.value.toLowerCase()}))}
              required={true}
              disabled={disabled}
              needsNativeElement={true}
              parentClass='admin'
            />
          </div>
          <div className='col-sm-4'>
            <APDatepicker
              name='expiration_date'
              value={expiration_date}
              labelText='Expiration Date'
              datePickerId='expiration_date'
              onChange={onFormIdentificationChange}
              disabled={disabled}
              required={true}
              inputParentClassName='admin'
            />
          </div>
        </div>
      }
      <div className='row significant-checkbox-row'>
        <div className='col-sm-3'>
          <APControlledCheckbox
            name='significant_ownership'
            text='Has Significant Ownership'
            checked={significant_ownership}
            onChange={onFormCheckboxChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='row significant-checkbox-row'>
        <div className='col-sm-3'>
          <APControlledCheckbox
            name='significant_management'
            text='Has Significant Management'
            checked={significant_management}
            onChange={onFormCheckboxChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='row bottom-spacer-20'>
        <div className='col-sm-12'>
          <APButton type='submit' disabled={disabled || !isFormValid}>
            Save Changes
          </APButton>
        </div>
      </div>
    </form>
  );
};

export default BeneficialOwnerForm;

BeneficialOwnerForm.propTypes = {
  formState: object,
  setFormState: func,
  onSubmit: func,
  disabled: bool,
  backToLegalEntities: func,
  isEditView: bool
};
