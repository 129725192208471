import React, { useState, useEffect, useCallback } from 'react';
import { APButton, APModal, APTable } from 'affinipay-ui-library';
import FeatureOptions from './FeatureOptions';
import DeleteFeatureGateForm from './DeleteFeatureGateForm';
import { getFeatureGates } from './helpers/services';
import { strings, initGateTableState, gateTableHeaderData } from './helpers/constants';
import { isEnabledForAllActors } from './helpers/utils';
import PropTypes from 'prop-types';

const FeatureTogglePage = ({ feature = {}, setFeatures, setSelectedFeature }) => {
  const [tableState, setState] = useState(initGateTableState);
  const setTableState = (state) => setState(prevState => ({...prevState, ...state}));
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const featureEnabledForAllActors = isEnabledForAllActors(tableState?.featureGates);

  useEffect(() => {
    fetchData();
  }, [tableState.page, tableState.sortBy, tableState.sortOrder]);

  const fetchData = async () => {
    setTableState({loading: true, error: null});
    try {
      const response = await getFeatureGates(feature, tableState);
      const json = await response.json();
      if (response.ok) {
        setTableState({
          featureGates: json.data?.map(d => d?.attributes), 
          totalRecordCount: json.meta.total_record_count, 
          filteredRecordCount: json.meta.filtered_record_count
        });
      }
      else {
        setTableState({error: strings.featureGatesErrorText});
      }
    }
    catch (e) {
      setTableState({error: strings.featureGatesErrorText});
    }
    finally { 
      setTableState({loading: false});
    }
  };

  const handlePageChange = useCallback(({ page }) => setTableState({page: page}), []);

  const handleTableSort = useCallback(({ id, desc }) => {
    setTableState({sortBy: id, sortOrder: desc ? 'desc' : 'asc' });
  }, []);

  const handleFeatureGateRowClick = (rowData) => {
    setTableState({selectedFeatureGate: rowData});
    toggleModal();
  };

  const toggleModal = () => setIsDeleteModalShowing(!isDeleteModalShowing);

  return (
    <>
      <FeatureOptions
        feature={feature}
        setFeatures={setFeatures}
        setSelectedFeature={setSelectedFeature}
        tableState={tableState}
        setTableState={setTableState}
        fetchData={fetchData}
        featureEnabledForAllActors={featureEnabledForAllActors}
      />
      {!featureEnabledForAllActors &&
        <>
          <div className='row'>
            <div className="col-sm-12">
              <APTable
                data={tableState.featureGates}
                header={[
                  ...gateTableHeaderData,
                  {
                    accessor: 'actions',
                    disableSortBy: true,
                    // eslint-disable-next-line react/prop-types, react/display-name
                    Cell: ({ row }) => (
                      <APButton
                        className='ap-negative-button'
                        onClick={() => handleFeatureGateRowClick(row.original)} // eslint-disable-line react/prop-types
                      >
                        Delete
                      </APButton>
                    )
                  }
                ]}
                onSort={handleTableSort}
                isServerSort={true}
                isPaginated={true}
                currentPage={tableState.page}
                total={tableState.filteredRecordCount}
                showPageSizeOptions={false}
                handlePaginationChange={handlePageChange}
                noRecordsText={strings.noRecords}
                loading={tableState.loading}
                errorLoading={tableState.error ? true : false}
                errorText={tableState.error}
              />
            </div>
          </div>
          <APModal
            isActiveInitial={isDeleteModalShowing}
            onClose={toggleModal}
            headerText={strings.deleteGateModalTitle}
            className='feature-modal'
          >
            <div className="container-fluid">
              <DeleteFeatureGateForm 
                gate={tableState.selectedFeatureGate}
                fetchData={fetchData}
                closeModal={toggleModal}
                setTableState={setTableState}
              />
            </div>
          </APModal>
        </>
      }
    </>
  );
};

FeatureTogglePage.propTypes = {
  feature: PropTypes.object,
  setFeatures: PropTypes.func.isRequired,
  setSelectedFeature: PropTypes.func.isRequired
};

export default FeatureTogglePage;