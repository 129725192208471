import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BorderedContentBox extends Component {
  static propTypes = {
    style: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  };

  render() {
    const container = {
      padding: '25px',
      border: '1px solid #979797'
    };

    return (
      <div
        className={this.props.className}
        style={{ ...container, ...this.props.style }}
      >
        {this.props.children}
      </div>
    );
  }
}
