import React from 'react';
import BankAccountDisplay from '../BankAccountDisplay';
import QuickBillStatusIndicator from '../QuickBillStatusIndicator';
import QuickBillStatistic from '../QuickBillStatistic';
import { dateFormat } from '../../../lib/utils';
import {invoiceProp} from '../../../customProps';

const QuickBillStatusBarPaid = ({ invoice }) => {
  let {
    invoicePayments: [{payment: { paymentType }} = ''],
    presentmentStatus
  } = invoice;

  return (
    <div className="eqb-status-bar">
      <QuickBillStatusIndicator
        presentmentStatus={presentmentStatus}
        paymentMethod={paymentType}
      />
      <QuickBillStatistic
        label="Last Updated"
        value={dateFormat(invoice.modified.timestamp)}
      />
      <QuickBillStatistic
        label="Deposit Account"
        value={<BankAccountDisplay invoice={invoice} />}
      />
    </div>
  );
};

QuickBillStatusBarPaid.propTypes = {
  invoice: invoiceProp
};

export default QuickBillStatusBarPaid;
