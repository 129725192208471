import React, { Component } from 'react';
import { APButton } from 'affinipay-ui-library';
import PropTypes from 'prop-types';
import './style.scss';

export default class ActivateText extends Component {
  render() {
    return (
      <div data-jest-activate-text>
        <h2>Activate eCheck!</h2>
        <div>
          <p className='paragraphSpacing'>
            New integrated eCheck feature is now available and included with
            your {this.props.brandIdentifier} account.
          </p>
          <p className='paragraphSpacing'>
            To provide even more options and a better overall customer
            experience, {this.props.brandIdentifier} has developed a fully
            integrated and proprietary ACH/eCheck service. Getting paid has
            never been easier. Now you can manage all your payments seamlessly
            through the same platform.
          </p>
          <p className='paragraphSpacing'>
            Available through your existing account with no monthly fee.
          </p>
          <p>
            Simply activate eChecks and start collecting payments immediately.
          </p>
        </div>

        <div className='buttonContainer'>
          <APButton
            type="primary"
            text="Activate eCheck"
            onClick={this.props.onAccept}
          >
            Activate eCheck
          </APButton>
          <APButton
            className="ap-secondary-button"
            type="secondary"
            text="Maybe Later"
            onClick={this.props.onDecline}
          >
            Maybe Later
          </APButton>
        </div>
      </div>
    );
  }

  static propTypes = {
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    brandIdentifier: PropTypes.string.isRequired
  };
}
