import { truncateTitle } from './truncateTitle';
import { getAdditionalUrlParams } from './getAdditionalUrlParams';
import { convertSvgToImage } from './convertSvgToImage';

describe('truncateTitle', () => {
  it('will return an empty string if no values are passed', () => {
    expect(truncateTitle()).toBeFalsy();
    expect(truncateTitle()).toEqual('');
  });

  it('will return the passed title argument if it is under the limit', () => {
    expect(truncateTitle('Short Title')).toEqual('Short Title');
    expect(truncateTitle('Four', 5)).toEqual('Four');
  });

  it('will return a truncated title argument with an elipsis limit is met', () => {
    expect(truncateTitle('This is a really long title that will be truncated', 27))
      .toEqual('This is a really long title...');
    expect(truncateTitle('Fourty', 5)).toEqual('Fourt...');
  });
});

describe('getAdditionalUrlParams', () => {
  it('will return an empty string if no value is passed', () => {
    expect(getAdditionalUrlParams()).toBeFalsy();
    expect(getAdditionalUrlParams()).toEqual('');
  });

  it('will return the passed url argument lacking params with the qr_used param', () => {
    expect(getAdditionalUrlParams('www.affinipay.com')).toEqual('www.affinipay.com?qr_used=true');
    expect(getAdditionalUrlParams('www.lawpay.com')).toEqual('www.lawpay.com?qr_used=true');
  });

  it('will return the passed url argument with params in addition to the qr_used param', () => {
    expect(getAdditionalUrlParams('www.affinipay.com?amount=10000&name=payment_account&email=john@foo.com'))
      .toEqual('www.affinipay.com?qr_used=true&amount=10000&name=payment_account&email=john@foo.com');
    expect(getAdditionalUrlParams('www.lawpay.com?merchant_name=Foo'))
      .toEqual('www.lawpay.com?qr_used=true&merchant_name=Foo');
  });
});

describe('convertSvgToImage', () => {
  it('will return undefined if no value is passed', () => {
    const result = convertSvgToImage();
    expect(result).toBeTruthy();
    expect(typeof result).toEqual('object');
  });
});
