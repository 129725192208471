
import { rollbarLog } from '../../../../lib/utils';
import { loanErrors } from '../../../../lib/paymentPage/constants';

const _isScriptLoaded = (script) => {
  return document.querySelectorAll('[src="' + script + '"]').length > 0;
};

const _loadScript = (script) => {
  const scriptEl = document.createElement('script');
  scriptEl.setAttribute('src', script);
  document.getElementsByTagName('head')[0].appendChild(scriptEl);
  return scriptEl;
};

const _getAffirmConfigObject = (environment) => {
  if (environment === 'sandbox') {
    return {
      public_api_key: 'MCJ9RG3R5XXP61WU',
      script: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js'
    };
  } else {
    return {
      public_api_key: '1IZJLS6TKN620Q87',
      script: 'https://cdn1.affirm.com/js/v2/affirm.js'
    };
  }
};

const attachAffirmScript = (environment) => {
  try {
    const affirmObject = _getAffirmConfigObject(environment);
    const affirmScript = affirmObject.script;

    window._affirm_config = affirmObject;

    if (!_isScriptLoaded(affirmScript)) {
      _loadScript(affirmScript);
    }

    return true;
  } catch (err) {
    rollbarLog('UPFCheckout', 'Failed to attach Affirm script');
    return false;
  }
};

const _handleAffirmFail = e => {
  rollbarLog('LoanJS', `Affirm flow fail: ${JSON.stringify(e)}`, 'warning');

  if (e.reason === 'error') {
    const event = new Event('loan-js-affirm-error');
    window.dispatchEvent(event);
  }
};

const _handleAffirmSuccess = _ => {
  const event = new Event('loan-js-success');
  window.dispatchEvent(event);
};

//public

const initiateAffirmCheckout = (chargeRes, setFormErrors) => {
  if (!window.affirm) {
    rollbarLog('LoanJS', `Failed to initialize Affirm global.`);
    setFormErrors([loanErrors.chargeFailure]);
    return;
  }
  window.affirm.checkout({
    'checkoutAri': chargeRes?.action?.checkout_ari,
    'metadata': { 'mode': 'modal' }
  });
  window.affirm.checkout.open({
    onFail: function (e) { _handleAffirmFail(e); },
    onSuccess: function (e) { _handleAffirmSuccess(e); }
  });
};

const AFFIRM_COMMUNICATIONS = {
  initiateAffirmCheckout,
  attachAffirmScript
};

export default AFFIRM_COMMUNICATIONS;
