// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Select Component shallow snapshot render 1`] = `
<div>
  <label>
    foo
  </label>
  <withProps(StateManager)
    name="bar"
    onChange={[Function]}
    options={
      [
        {
          "value": "dog",
        },
        {
          "value": "cat",
        },
      ]
    }
    value={
      {
        "value": "dog",
      }
    }
  />
</div>
`;
