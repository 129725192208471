import React from 'react';
import { shallow, mount } from 'enzyme';
import JobTitles from './jobTitle';

describe('JobTitle Component', () => {
  it('shallow snapshot render', () => {
    const component = shallow(<JobTitles />);
    expect(component).toMatchSnapshot();
  });
  it('mount for events', () => {
    const component = mount(<JobTitles selected={'bar'} name={'foobar'} />);
    component
      .find('[name="foobar"]')
      .at(1)
      .prop('onChange')({ value: 'bar' });
    expect(component.state('selectedOption')).toBeUndefined();
    component.unmount();
  });
});
