import React from 'react';
import PropTypes from 'prop-types';
import MerchantLogo from './MerchantLogo';

// eslint-disable-next-line react/display-name
const BusinessInformationComponent = React.memo(({
  name,
  address,
  additional_address,
  city,
  state,
  country_code,
  postal_code,
  support_phone,
  support_email,
  website,
  logo,
  notes,
  hideLocation
}) => {

  return (
    <>
      <MerchantLogo merchant={{ website, name }} logo={logo} type='desktop' />
      <div className="col-12 footer-merchant-name" data-testid="merchant-name">
        <span>{name}</span>
      </div>
      {!hideLocation && (
        <>
          <div className="col-12">
            {address}
          </div>
          <div className="col-12">
            {additional_address}
          </div>
          <div className="col-12">
            {city}, {state}{country_code === 'US' ?  '' : `, ${country_code}`} {postal_code}
          </div>
          <div className="col-12 phone">
            <a href={`tel:${support_phone}`}>{support_phone}</a>
          </div>
          <div className="col-12">
            <a href={`mailto:${support_email}`}>{support_email}</a>
          </div>
          <div className="col-12">
            <a href={website} target="_blank" rel="noopener noreferrer">{website}</a>
          </div>
        </>
      )}
      <div className="col-12 notes" dangerouslySetInnerHTML={{__html: notes}}></div>
    </>
  );
});

BusinessInformationComponent.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  additional_address: PropTypes.string,
  city: PropTypes.string,
  country_code: PropTypes.string,
  postal_code: PropTypes.string,
  support_phone: PropTypes.string,
  support_email: PropTypes.string,
  website: PropTypes.string,
  logo: PropTypes.object,
  notes: PropTypes.string,
  state: PropTypes.string,
  hideLocation: PropTypes.bool
};

export default BusinessInformationComponent;
