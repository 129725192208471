import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import LawpayPlusButtonComponent from '.';

describe('LawPay Pro Button Component', () => {
  it('renders without crashing', () => {
    render(<LawpayPlusButtonComponent link={mockLink} text="LAWPAY PRO" />);
    expect(screen.getByTestId('lpp-button')).toBeVisible();
  });

  it('LawPay pro button is clicked and call to open a tab', () => {
    render(<LawpayPlusButtonComponent link={mockLink} text="LAWPAY PRO" />);
    const button = screen.getByRole('button', { name: 'LAWPAY PRO' });
    jest.spyOn(window, 'open');

    userEvent.click(button);

    expect(window.open).toHaveBeenCalledWith(mockLink, '_self');
  });
});

const mockLink = 'https://app.lawpay.com';