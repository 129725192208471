// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AdditionalOwners Component shallow snapshot render 1`] = `
<div
  className="row"
>
  <div
    className="col-xs-12"
  >
    <hr />
    <h3>
      Additional Business Owners
    </h3>
    <p>
      Business owners are individuals with an ownership stake or significant management responsibilities, like a CEO or CFO. Please list everyone who owns 25% or more of the company. And if no one meets this criteria, list at least one business owner with management responsibilities.
    </p>
    <p>
      If you don't have all of their information now, you can save and come back later or follow up after you submit your application.
    </p>
  </div>
  <div
    className="col-xs-12 button-row"
  >
    <ForwardRef
      className="ap-secondary-button"
      onClick={[Function]}
    >
      + ADD BUSINESS OWNER
    </ForwardRef>
    <APModal
      isActiveInitial={false}
      onClose={[Function]}
    >
      <div
        className="container-fluid"
      >
        <AddOwnerForm
          errors={[]}
          isAdmin={false}
          onCancel={[Function]}
          onEditSubmit={[Function]}
          onSubmit={[Function]}
        />
      </div>
    </APModal>
  </div>
</div>
`;
