//not exposed in production
import React, {useEffect, useState} from 'react';
import {array, bool, func, number, object} from 'prop-types';
import { APButton, APInputMonetary, APInputText, APSelect } from 'affinipay-ui-library';
import './style.scss';
import {months} from '../../../lib/constants';

const SubscriptionFeesSection = ({feeNames, frequencies, onRowsChange, handleValidationError, dateFieldsEnabled = true, addRowEnabled = false}) => {
  const initialRowState = {
    key: 1,
    feeName: 'Program Fee',
    amount: undefined,
    frequency: undefined
  };
  if (dateFieldsEnabled) {
    initialRowState.month = undefined;
    initialRowState.year = undefined;
  }

  const [feeRows, setFeeRows] = useState([initialRowState]);

  useEffect(() => {
    onRowsChange(feeRows);
  }, [feeRows]);

  const addRow = (e) => {
    e.preventDefault();
    setFeeRows([...feeRows, {
      key: generateKey(),
      feeName: 'Program Fee',
      amount: undefined,
      frequency: undefined,
      month: undefined,
      year: undefined
    }]);
  };

  const generateKey = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  const removeRow = (e, rowNumber) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete the fee?')) {
      const newRows = feeRows.filter((el, i) => i !== rowNumber);
      setFeeRows(newRows);
    }
  };

  const handleUserInput = (rowNumber, name, value) => {
    const newRows = [...feeRows];
    newRows[rowNumber][name] = value;
    setFeeRows(newRows);
    handleValidationError(name, value, rowNumber);
  };

  return (
    <section>
      <div className="row">
        <div className="col-xs-12">
          <h4>Subscription Fees</h4>
        </div>
      </div>
      {
        feeRows.map((el, i) => {
          return (
            <FeesRow feeNames={feeNames}
              frequencies={frequencies}
              key={el.key}
              onChange={handleUserInput}
              rowState={feeRows[i]}
              rowNumber={i}
              removeRow={i === 0 ? false : removeRow}
              dateFieldsEnabled={dateFieldsEnabled}
            />
          );
        })
      }
      {addRowEnabled && (
        <div className="row form-group">
          <div className="col-xs-12">
            <APButton className='ap-admin-small-secondary-button'
              onClick={addRow}>
              Add another fee
            </APButton>
          </div>
        </div>
      )}
    </section>
  );
};

const FeesRow = ({
  feeNames, frequencies, removeRow, rowNumber, onChange, rowState, dateFieldsEnabled = true
}) => {
  const handleUserInput = (e) => {
    const name = e.target.name,
      value = e.target.value;
    onChange(rowNumber, name, value);
  };
  const validateYearInput = (val) => val.length < 5 && !isNaN(val);

  return (
    <div className="row form-group">
      <div className="col-xs-3">
        <APSelect 
          label='Fee Name'
          name='feeName'
          options={feeNames}
          onChange={handleUserInput}
          parentClass='admin feeName'
          required={true}
        />
      </div>
      <div className="col-xs-2">
        <div className="input-group">
          <APInputMonetary
            className="subscription-amount"
            label="Amount"
            name="amount"
            onChange={handleUserInput}
            parentClass="admin"
            required={true}
            value={rowState.amount}
          />
        </div>
      </div>
      <div className="col-xs-3">
        <APSelect 
          label='Frequency'
          name='frequency'
          onChange={handleUserInput}
          options={frequencies}
          parentClass='admin frequency'
          required={true}
        />
      </div>
      {dateFieldsEnabled && (
        <>
          <div className="col-xs-2">
            <APSelect 
              label='Effective Month'
              name='month'
              onChange={handleUserInput}
              options={months}
              parentClass='admin month'
              required={true}
            />
          </div>
          <div className="col-xs-1">
            <APInputText 
              label="Year"
              name='year'
              onChange={(e) => {
                validateYearInput(e.target.value) && handleUserInput(e);
              }}
              parentClass="admin year-input"
              required={true}
              value={rowState.year}
            />
          </div>
        </>
      )}
      {removeRow && (
        <div className="col-xs-1">
          <APButton className="delete-fee"
            onClick={(e) => {
              removeRow(e, rowNumber);
            }}></APButton>
        </div>
      )}
    </div>
  );
};

export default SubscriptionFeesSection;

SubscriptionFeesSection.propTypes = {
  feeNames: array,
  frequencies: array,
  onRowsChange: func,
  handleValidationError: func,
  dateFieldsEnabled: bool,
  addRowEnabled: bool
};

FeesRow.propTypes = {
  feeNames: array,
  frequencies: array,
  removeRow: func,
  rowNumber: number,
  onChange: func,
  rowState: object,
  dateFieldsEnabled: bool
};
