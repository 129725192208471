/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import ChartSection from './chart_section';
import EmptyCardPlaceholder from './empty_card_placeholder';
import DateRangeSegmentControl from './date_range_segment_control';
import StatBox from './stat_box';
import RecentTransactionsTable from './recent_transactions_table';
import TopClientsTable from './top_clients_table';
import { APFormMessageBox } from 'affinipay-ui-library';

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeDateRange: this.props.data.activeDateRange,
      tableRecent: this.props.data.recentTransactions,
      tableTopClients: this.props.data.topClients,
      graphVolume: this.props.data.graphVolume,
      graphSource: this.props.data.graphSource,
      allGraphDataLoaded: this.props.data.allGraphDataLoaded,
      oldTransactionsExist: this.props.data.oldTransactionsExist
    };

    this.refreshFromServer = this.refreshFromServer.bind(this);
  }

  componentDidMount() {
    this.rangeTotal = AffiniPay.DashboardUtils.calculateRange(this.state.graphSource).sum;
    if (this.doTransactionsExist(this.rangeTotal)) this.addAriaLabel();
  }

  componentDidUpdate() {
    if (this.doTransactionsExist(this.rangeTotal)) this.addAriaLabel();
  }

  addAriaLabel() {
    if (document.getElementById('highcharts-0')) {
      const tSpanElement = document.getElementById('highcharts-0').getElementsByTagName('tspan');

      for (let i = 0; i < tSpanElement.length; i++) {
        const dates = tSpanElement[i].innerHTML;
        tSpanElement[i].setAttribute('aria-label', dates);
      }
    }
  }

  doTransactionsExist(rangeTotal) {
    return rangeTotal > 0 ? true : false;
  }

  refreshFromServer(activeRange) {
    return $.ajax({
      url: '/?range='+ activeRange,
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      context: this,
      beforeSend: function() {
        $('#transaction_volume_chart, #payment_source_chart').addClass('refresh-active');
      },
      success: function(resp) {
        $('#transaction_volume_chart, #payment_source_chart').removeClass('refresh-active');

        this.processForState(resp);
      },
      error: function() {
        window.location = '/';
      }
    });
  }

  processForState(data) {
    this.setState({
      activeDateRange: data.activeDateRange,
      graphVolume: data.graphVolume,
      graphSource: data.graphSource,
      tableTopClients: data.topClients,
      rangeVolumeTotal: AffiniPay.DashboardUtils.numberFormat(AffiniPay.DashboardUtils.calculateRange(data.graphSource).sum, 2),
      rangeVolumeAverage: AffiniPay.DashboardUtils.numberFormat(AffiniPay.DashboardUtils.calculateRange(data.graphSource).avg, 2)
    });

    AffiniPay.DashboardUtils.positionPaymentSourceStats();
  }

  displayChartSection(rangeTotal) {
    if (this.doTransactionsExist(rangeTotal)) {
      return ( <ChartSection activeDateRange={ this.state.activeDateRange }
        graphVolume={ this.state.graphVolume }
        graphSource={ this.state.graphSource } /> );
    } else {
      return ( <div className="dash-placeholder">
        {
          this.state.oldTransactionsExist ?  (
            <p>No recent activity to display.</p>
          ) : (
            <p><a href="/charges">Run a charge</a> to populate this dashboard.</p>
          )
        }
        <EmptyCardPlaceholder brand={ this.props.brand } />
      </div> );
    }
  }

  render() {
    if (this.state.allGraphDataLoaded) {
      const rangeTotal = AffiniPay.DashboardUtils.calculateRange(this.state.graphSource).sum;
      const rangeAverage = (this.doTransactionsExist(rangeTotal) ? AffiniPay.DashboardUtils.calculateRange(this.state.graphSource).avg : AffiniPay.DashboardUtils.numberFormat(0, 2));

      return(
        <div className="dashboard">

          <DateRangeSegmentControl activeDateRange={this.state.activeDateRange} onClick={this.refreshFromServer} />

          <div className="row dashboard-summary">
            <StatBox label="Total Volume" value={ AffiniPay.DashboardUtils.numberFormat(rangeTotal, 2) } placement="left" />
            <StatBox label="Average Transaction" value={ AffiniPay.DashboardUtils.numberFormat(rangeAverage, 2) } placement="right" />
          </div>

          { this.displayChartSection(rangeTotal) }

          <section className="col-xs-12 dashboard-tables clearfix">
            <div className="row">
              <div className="col-sm-6">
                <RecentTransactionsTable transactions={ this.state.tableRecent } />
              </div>

              <div className="col-sm-6">
                <TopClientsTable range={ this.state.activeDateRange }
                  clients={ this.state.tableTopClients }
                />
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <APFormMessageBox type='error' header='Something went wrong grabbing your data'>
          <p>If problem persist, please contact support {this.props.supportPhone && (<span> at <a href={`tel:${this.props.supportPhone}`}>{this.props.supportPhone}</a></span>)}</p>
        </APFormMessageBox>
      );
    }
  }
}

Dashboard.propTypes = {
  brand: PropTypes.string,
  data: PropTypes.object,
  supportPhone: PropTypes.string
};
