// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`OwnersList shallow snapshot 1`] = `
<Fragment>
  <OwnerListItem
    handleEdit={[Function]}
    handleRemove={[MockFunction]}
    key="0"
    owner={
      {
        "created_at": "2020-04-20T20:03:12.000Z",
        "date_of_birth": "08/08/1994",
        "first_name": "John",
        "has_significant_ownership": true,
        "has_significant_responsibility": null,
        "home_address1": "2020 Fake Elm St",
        "home_address2": "",
        "home_city": "Wonderland",
        "home_state": "AA",
        "home_zip_code": "55998",
        "id": 31,
        "job_title": "Owner",
        "last_name": "Doe",
        "merchant_application_id": 4,
        "social_security_number_last_4": "8989",
        "ssn_request_url": "/admin/merchant_applications/4/business_owner_social_security_numbers/31",
        "updated_at": "2020-04-21T21:12:52.000Z",
      }
    }
  />
  <OwnerListItem
    handleEdit={[Function]}
    handleRemove={[MockFunction]}
    key="1"
    owner={
      {
        "created_at": "2020-04-21T21:09:54.000Z",
        "date_of_birth": "11/11/1990",
        "first_name": "John2312",
        "has_significant_ownership": true,
        "has_significant_responsibility": null,
        "home_address1": "2020 Fake Elm St",
        "home_address2": "",
        "home_city": "Wonderland",
        "home_state": "AZ",
        "home_zip_code": "55998",
        "id": 32,
        "job_title": "Manager",
        "last_name": "Doe",
        "merchant_application_id": 4,
        "social_security_number_last_4": "3123",
        "ssn_request_url": "/admin/merchant_applications/4/business_owner_social_security_numbers/32",
        "updated_at": "2020-04-21T21:09:54.000Z",
      }
    }
  />
</Fragment>
`;
