import React from 'react';
import {func, object} from 'prop-types';
import { APInputMonetary, APInputPercent } from 'affinipay-ui-library'; 

const EcheckFeesSection = ({formState, setFormState, handleValidationError}) => {

  const onUserInput = e => {
    const {name, value} = e.target;
    const newState = {};
    newState[name] = value;
    setFormState({...formState, ...newState});
    handleValidationError(name, value);
  };

  return (
    <section>
      <div className="row">
        <div className="col-xs-12">
          <h4>Echeck Transaction Rates</h4>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-xs-4">
          <APInputPercent 
            label="eCheck Base Rate"
            name='eCheckBaseRate'
            onChange={onUserInput}
            parentClass="admin"
            required={true}
            value={formState.eCheckBaseRate}
          />
        </div>
        <div className="col-xs-4">
          <div className="input-group">
            <APInputMonetary
              label="Additional Base Transaction"
              name="additionalBase"
              parentClass="admin"
              onChange={onUserInput}
              value={formState.additionalBase}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="input-group">
            <APInputMonetary
              label="Return Fee"
              onChange={onUserInput}
              name="returnFee"
              parentClass="admin"
              value={formState.returnFee}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EcheckFeesSection;

EcheckFeesSection.propTypes = {
  formState: object,
  setFormState: func,
  handleValidationError: func
};
