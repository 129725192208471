import React, {useState} from 'react';
import { array, bool, string } from 'prop-types';
import {APToast} from 'affinipay-ui-library';
import './style.scss';
import DepositAccountsTableHead from './DepositAccountsTableHead';
import DepositAccountsListing from './DepositAccountsListing';
import DepositAccountsEditModal from './DepositAccountsEditModal';
import {updateProcessorAccountName} from './services';

const DepositAccountsTable = ({bankAccounts = [], supportEmail = 'support@affinipay.com', isPolicyQuickBills}) => {
  const [accounts, setAccounts] = useState(bankAccounts);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveErrors, setSaveErrors] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleModalOpen = account => {
    setShowSuccessToast(false);
    setSelectedAccount(account);
    toggleModal();
  };

  const handleModalClose = () => {
    setSelectedAccount(null);
    toggleModal();
    setIsSaving(false);
    setSaveErrors([]);
  };

  const handleAccountNameChange = ({currentTarget: {value}}) => {
    setSelectedAccount({...selectedAccount, name: value});
  };

  const handleToastClose = () => {
    setShowSuccessToast(false);
  };

  const handleSave = async event => {
    event.preventDefault();
    setIsSaving(true);

    try {
      const response = await updateProcessorAccountName(`/accounts/${selectedAccount.id}`, {name: selectedAccount.name});

      if (response?.ok) {
        const updatedAccounts = accounts.map(account => account.id === selectedAccount.id ? { ...account, name: selectedAccount.name } : account);
        setAccounts(updatedAccounts);
        setShowSuccessToast(true);
        toggleModal();
      } else {
        const {errors} = await response.json();
        setSaveErrors(Array.isArray(errors) ? errors : [errors]);
      }

      setIsSaving(false);
    } catch(e) {
      setSaveErrors('An error occurred updating the account name.');
      setIsSaving(false);
    }
  };

  return (
    <div className="table-responsive">
      <h4 className="deposit-accounts-table-title">Deposit Accounts</h4>
      <table className="table table-striped">
        <DepositAccountsTableHead isPolicyQuickBills={isPolicyQuickBills} />
        <DepositAccountsListing accounts={accounts} handleModalOpen={handleModalOpen} />
      </table>
      <DepositAccountsEditModal
        acceptedCardTypes={selectedAccount?.acceptedCardTypes}
        accountId={selectedAccount?.id}
        selectedAccountName={selectedAccount?.name}
        displayType={selectedAccount?.displayType}
        requiredPaymentFields={selectedAccount?.requiredPaymentFields}
        supportEmail={supportEmail}
        isModalOpen={isModalOpen}
        isSaving={isSaving}
        swipeRequiredPaymentFields={selectedAccount?.swipeRequiredPaymentFields}
        saveErrors={saveErrors}
        handleAccountNameChange={handleAccountNameChange}
        handleSave={handleSave}
        handleModalClose={handleModalClose}
      />
      {showSuccessToast && (
        <APToast
          isOpen={true}
          type="success"
          onClose={handleToastClose}
          fadeOutTime={3000}
        >
          <div>
            <h4 className="deposits-accounts-table-toast-header">Account name updated.</h4>
          </div>
        </APToast>
      )}
    </div>
  );
};

DepositAccountsTable.propTypes = {
  bankAccounts: array,
  supportEmail: string,
  isPolicyQuickBills: bool
};

export default DepositAccountsTable;
