// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AddOwnerForm shallow snapshot 1`] = `
<div
  className="row"
>
  <div
    className="col-xs-12"
  >
    <h3>
      Add a Business Owner
    </h3>
  </div>
  <form
    className="add-business-owner"
  >
    <div
      className="col-xs-6 form-group"
    >
      <ForwardRef
        label="First name"
        name="first_name"
        onBlur={[Function]}
        onChange={[Function]}
        parentClass=""
        required={true}
        value=""
      />
    </div>
    <div
      className="col-xs-6 form-group"
    >
      <ForwardRef
        label="Last name"
        name="last_name"
        onBlur={[Function]}
        onChange={[Function]}
        parentClass=""
        required={true}
        value=""
      />
    </div>
    <div
      className="col-xs-12 form-group"
    >
      <div>
        <label
          className="ownership-label"
        >
          Does this person own 25% or more of the business?
        </label>
      </div>
      <APRadioButton
        checked={false}
        name="has_significant_ownership"
        onChange={[Function]}
        text="No"
        value=""
      />
      <APRadioButton
        checked={true}
        name="has_significant_ownership"
        onChange={[Function]}
        text="Yes"
        value={true}
      />
    </div>
    <div>
      <div
        className="col-xs-12 form-group"
        onClick={[Function]}
      >
        <div
          className=""
        >
          <APSelect
            label="Job title"
            name="job_title"
            onChange={[Function]}
            options={
              [
                {
                  "text": "President",
                  "value": "President",
                },
                {
                  "text": "Vice President",
                  "value": "V.President",
                },
                {
                  "text": "Owner",
                  "value": "Owner",
                },
                {
                  "text": "Partner",
                  "value": "Partner",
                },
                {
                  "text": "Manager",
                  "value": "Manager",
                },
                {
                  "text": "Treasurer",
                  "value": "Treasurer",
                },
                {
                  "text": "Secretary",
                  "value": "Secretary",
                },
                {
                  "text": "Executive Director",
                  "value": "Exec.Director",
                },
              ]
            }
            parentClass="job_title"
            required={true}
            value=""
          />
        </div>
      </div>
      <div
        className="col-xs-6 form-group"
      >
        <APInputMask
          dataset={{}}
          disabled={false}
          error=""
          label="Social Security Number"
          mask={
            [
              /\\\\d/,
              /\\\\d/,
              /\\\\d/,
              "-",
              /\\\\d/,
              /\\\\d/,
              "-",
              /\\\\d/,
              /\\\\d/,
              /\\\\d/,
              /\\\\d/,
            ]
          }
          name="social_security_number"
          onBlur={[Function]}
          onChange={[Function]}
          parentClass=""
          placeholder=""
          required={true}
          showFieldRequirement={false}
          value=""
        />
      </div>
      <div
        className="col-xs-6 form-group"
      >
        <APInputMask
          dataset={{}}
          disabled={false}
          error=""
          label="Date of Birth"
          mask={
            [
              /\\\\d/,
              /\\\\d/,
              "/",
              /\\\\d/,
              /\\\\d/,
              "/",
              /\\\\d/,
              /\\\\d/,
              /\\\\d/,
              /\\\\d/,
            ]
          }
          name="date_of_birth"
          onBlur={[Function]}
          onChange={[Function]}
          parentClass=""
          placeholder=""
          required={true}
          showFieldRequirement={false}
          value=""
        />
      </div>
      <div
        className="col-xs-8 form-group"
      >
        <ForwardRef
          label="Home Address"
          name="home_address1"
          onBlur={[Function]}
          onChange={[Function]}
          parentClass=""
          required={true}
          value=""
        />
      </div>
      <div
        className="col-xs-4 form-group"
      >
        <ForwardRef
          label="Apt, suite, etc"
          name="home_address2"
          onChange={[Function]}
          parentClass=""
          value=""
        />
      </div>
      <div
        className="col-xs-5 form-group"
      >
        <ForwardRef
          label="City"
          name="home_city"
          onBlur={[Function]}
          onChange={[Function]}
          parentClass=""
          required={true}
          value=""
        />
      </div>
      <div
        className="col-xs-3 form-group"
        onClick={[Function]}
      >
        <div
          className=""
        >
          <APSelect
            label="State"
            name="home_state"
            onChange={[Function]}
            options={
              [
                {
                  "text": "Alabama",
                  "value": "AL",
                },
                {
                  "text": "Alaska",
                  "value": "AK",
                },
                {
                  "text": "American Samoa",
                  "value": "AS",
                },
                {
                  "text": "Arizona",
                  "value": "AZ",
                },
                {
                  "text": "Arkansas",
                  "value": "AR",
                },
                {
                  "text": "California",
                  "value": "CA",
                },
                {
                  "text": "Colorado",
                  "value": "CO",
                },
                {
                  "text": "Connecticut",
                  "value": "CT",
                },
                {
                  "text": "Delaware",
                  "value": "DE",
                },
                {
                  "text": "District Of Columbia",
                  "value": "DC",
                },
                {
                  "text": "Federated States of Micronesia",
                  "value": "FM",
                },
                {
                  "text": "Florida",
                  "value": "FL",
                },
                {
                  "text": "Georgia",
                  "value": "GA",
                },
                {
                  "text": "Guam",
                  "value": "GU",
                },
                {
                  "text": "Hawaii",
                  "value": "HI",
                },
                {
                  "text": "Idaho",
                  "value": "ID",
                },
                {
                  "text": "Illinois",
                  "value": "IL",
                },
                {
                  "text": "Indiana",
                  "value": "IN",
                },
                {
                  "text": "Iowa",
                  "value": "IA",
                },
                {
                  "text": "Kansas",
                  "value": "KS",
                },
                {
                  "text": "Kentucky",
                  "value": "KY",
                },
                {
                  "text": "Louisiana",
                  "value": "LA",
                },
                {
                  "text": "Maine",
                  "value": "ME",
                },
                {
                  "text": "Marshall Islands",
                  "value": "MH",
                },
                {
                  "text": "Maryland",
                  "value": "MD",
                },
                {
                  "text": "Massachusetts",
                  "value": "MA",
                },
                {
                  "text": "Michigan",
                  "value": "MI",
                },
                {
                  "text": "Minnesota",
                  "value": "MN",
                },
                {
                  "text": "Mississippi",
                  "value": "MS",
                },
                {
                  "text": "Missouri",
                  "value": "MO",
                },
                {
                  "text": "Montana",
                  "value": "MT",
                },
                {
                  "text": "Nebraska",
                  "value": "NE",
                },
                {
                  "text": "Nevada",
                  "value": "NV",
                },
                {
                  "text": "New Hampshire",
                  "value": "NH",
                },
                {
                  "text": "New Jersey",
                  "value": "NJ",
                },
                {
                  "text": "New Mexico",
                  "value": "NM",
                },
                {
                  "text": "New York",
                  "value": "NY",
                },
                {
                  "text": "North Carolina",
                  "value": "NC",
                },
                {
                  "text": "North Dakota",
                  "value": "ND",
                },
                {
                  "text": "Northern Mariana Islands",
                  "value": "MP",
                },
                {
                  "text": "Ohio",
                  "value": "OH",
                },
                {
                  "text": "Oklahoma",
                  "value": "OK",
                },
                {
                  "text": "Oregon",
                  "value": "OR",
                },
                {
                  "text": "Palau",
                  "value": "PW",
                },
                {
                  "text": "Pennsylvania",
                  "value": "PA",
                },
                {
                  "text": "Rhode Island",
                  "value": "RI",
                },
                {
                  "text": "South Carolina",
                  "value": "SC",
                },
                {
                  "text": "South Dakota",
                  "value": "SD",
                },
                {
                  "text": "Tennessee",
                  "value": "TN",
                },
                {
                  "text": "Texas",
                  "value": "TX",
                },
                {
                  "text": "U.S. Virgin Islands",
                  "value": "VI",
                },
                {
                  "text": "U.S Armed Forces Americas",
                  "value": "AA",
                },
                {
                  "text": "U.S Armed Forces Europe",
                  "value": "AE",
                },
                {
                  "text": "U.S Armed Forces Pacific",
                  "value": "AP",
                },
                {
                  "text": "Utah",
                  "value": "UT",
                },
                {
                  "text": "Vermont",
                  "value": "VT",
                },
                {
                  "text": "Virginia",
                  "value": "VA",
                },
                {
                  "text": "Washington",
                  "value": "WA",
                },
                {
                  "text": "West Virginia",
                  "value": "WV",
                },
                {
                  "text": "Wisconsin",
                  "value": "WI",
                },
                {
                  "text": "Wyoming",
                  "value": "WY",
                },
              ]
            }
            parentClass="home_state"
            placeholderText="Select..."
            required={true}
            value=""
          />
        </div>
      </div>
      <div
        className="col-xs-4 form-group"
      >
        <APInputMask
          dataset={{}}
          disabled={false}
          error=""
          label="Postal Code"
          mask={
            [
              /\\[0-9\\]/,
              /\\[0-9\\]/,
              /\\[0-9\\]/,
              /\\[0-9\\]/,
              /\\[0-9\\]/,
            ]
          }
          name="home_zip_code"
          onBlur={[Function]}
          onChange={[Function]}
          parentClass=""
          placeholder=""
          required={true}
          showFieldRequirement={false}
          value=""
        />
      </div>
    </div>
    <div
      className="col-xs-12 form-group"
    >
      <ForwardRef
        className="ap-primary-button pull-right submit-btn"
        disabled={true}
        onClick={[Function]}
      >
        Submit
      </ForwardRef>
      <ForwardRef
        className="ap-secondary-button pull-right"
      >
        Cancel
      </ForwardRef>
    </div>
  </form>
</div>
`;
