import React from 'react';
import { shallow } from 'enzyme';
import LegacyAPDatepickerRails from '.';

describe('datepicker Component', () => {
  it('shallow snapshot render', () => {
    Date.now = jest.fn(() => new Date(Date.UTC(2018, 7, 9, 8)).valueOf());
    const component = shallow(<LegacyAPDatepickerRails />);
    expect(component).toMatchSnapshot();
  });
});
