/* eslint-disable no-undef */
import { APAccordion } from 'affinipay-ui-library';
import { array, bool, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'url-search-params-polyfill';
import affirmIcon from '../../../assets/images/paymentpagereact/affirm-logo.svg';
import amexIcon from '../../../assets/images/paymentpagereact/amex.svg';
import bankIcon from '../../../assets/images/paymentpagereact/bank.svg';
import dinersIcon from '../../../assets/images/paymentpagereact/dinersclub.svg';
import discoverIcon from '../../../assets/images/paymentpagereact/discover.svg';
import jcbIcon from '../../../assets/images/paymentpagereact/jcb.svg';
import masterCardIcon from '../../../assets/images/paymentpagereact/mastercard.svg';
import visaIcon from '../../../assets/images/paymentpagereact/visa.svg';
import ErrorBoundary from '../../components/ErrorBoundary';
import { DineroCentsFormat, DineroDollarsFormat, getCentsFromDollars, getCentsFromMaskedMoneyInput, getDollarsFromCents } from '../../lib/monetaryUtils';
import { isEmailPattern, rollbarLog, safeSendGA } from '../../lib/utils';
import { gatewayErrorMessages } from '../../lib/paymentPage/errorHandler';
import CaptchaHelper from '../../lib/paymentPage/captcha';
import { basicFrequencyOptions, fullFrequencyOptions, paymentPageErrors as errors, paymentPageFieldNameMap, paymentPageTabsText, swiperStates } from '../../lib/paymentPage/constants';
import HostedFieldsHelper from '../../lib/paymentPage/hostedFields';
import optionalFieldsHelper from '../../lib/paymentPage/optionalFields';
import { makeCharge, processSignatureLink } from '../../lib/paymentPage/services';
import { getSelectedCustomFieldsWithoutSections } from '../../lib/paymentPage/submitBuilders';
import setFormFieldsStateFromUrl from '../../lib/paymentPage/urlParams';
import AmountComponent from './Amount';
import BusinessInformationComponent from './BusinessInformationComponent';
import CardTabComponent from './CardTab';
import CustomFieldsComponent from './CustomFields';
import EcheckTabComponent from './EcheckTab';
import FooterComponent from './Footer';
import FrequencyComponent from './Frequency';
import LoanTab from './LoanPayment/LoanTab';
import LoanThankYou from './LoanPayment/LoanThankYou';
import PaymentInformationComponent from './PaymentInformation';
import './paymentPageStyle.scss';
import SignatureComponent from './Signature';
import SubmitButtonComponent from './SubmitButton';
import SummaryComponent from './Summary';
import SwiperComponent from './Swiper';
import ThankYouComponent from './ThankYou';

const PaymentPage = ({
  title,
  merchant,
  hide_location_info,
  merchant_account,
  logo,
  accepts_banks,
  accepts_cards,
  default_payment_method,
  description,
  notes,
  cc_limit,
  ach_limit,
  reference_label,
  reference_required,
  payment_page_url,
  signature,
  signature_text,
  redirect_url,
  current_user,
  cc_required_fields,
  swipe_cc_required_fields,
  ach_required_fields,
  amount_fields,
  custom_fields,
  privacy_policy_url,
  cc_currency,
  cc_surcharge_enabled,
  cc_surcharge_type,
  cc_surcharge_amount,
  cc_surcharge_label,
  cc_surcharge_percent,
  ach_currency,
  ach_surcharge_enabled,
  ach_surcharge_type,
  ach_surcharge_amount,
  ach_surcharge_label,
  ach_surcharge_percent,
  recurring_visible,
  v2_captcha_key,
  v3_captcha_key,
  hosted_fields_config,
  card_reader_visible,
  api_gw_url,
  loan_currency,
  loan_account_id,
  loan_max_limit,
  loan_min_limit,
  loan_support_number,
  environment,
  minimal_required_payment_fields_selected,
  show_pay_later_promo_text
}) => {
  const generateInitialTabsState = () => {
    let initialTabsState = [];
    if (loan_account_id) {
      initialTabsState.unshift({ text: paymentPageTabsText.loan, active: false });
    }
    if (accepts_banks) {
      initialTabsState.unshift({ text: paymentPageTabsText.echeck, active: false });
    }
    if (accepts_cards) {
      initialTabsState.unshift({ text: paymentPageTabsText.card, active: false });
    }
    return maybeOpenDefaultPaymentMethod(initialTabsState);
  };

  const maybeOpenDefaultPaymentMethod = (initialTabsState) => {
    let shouldOpen = false;
    amount_fields.forEach(el => {
      shouldOpen = el?.incompatible_payment_types?.length > 0;
    });
    if (shouldOpen) {
      switch (default_payment_method) {
      case 'card':
        initialTabsState.find(e => e.text === 'Card').active = true;
        break;
      case 'bank':
        initialTabsState.find(e => e.text === 'eCheck').active = true;
        break;
      case 'loan':
        initialTabsState.find(e => e.text === 'Pay Later').active = true;
        break;
      }
    }
    return initialTabsState;
  };

  let initialAmountFieldsData = [];

  if (default_payment_method === 'card') {
    initialAmountFieldsData = amount_fields.filter(el => el.incompatible_payment_types.indexOf('card') === -1);
  } else {
    initialAmountFieldsData = amount_fields.filter(el => el.incompatible_payment_types.indexOf('bank') === -1);
  }

  const initialAmountState = {
    amountField: undefined,
    customFieldsSubtotal: 0
  };

  const initialPaymentInfoState = {
    reference: undefined
  };

  const initialClientInfoState = {
    email: undefined
  };

  const initialCardState = {
    cardName: undefined,
    cardNumber: undefined,
    cardCVV: undefined,
    cardExpiration: undefined,
    cardBillingAddress: undefined,
    cardBillingAddress2: undefined,
    cardBillingCity: undefined,
    cardBillingState: undefined,
    cardBillingZip: undefined,
    cardBillingCountry: minimal_required_payment_fields_selected ? undefined : merchant?.country_code || 'US'
  };

  const initialEcheckState = {
    echeckAccountOwnerType: 'individual',
    echeckAccountType: 'checking',
    echeckFirstName: undefined,
    echeckLastName: undefined,
    echeckHolderName: undefined,
    echeckAccountNumber: undefined,
    echeckRoutingNumber: undefined,
    echeckAccountConfirm: undefined,
    echeckRoutingConfirm: undefined,
    echeckBillingAddress: undefined,
    echeckBillingAddress2: undefined,
    echeckBillingCity: undefined,
    echeckBillingState: undefined,
    echeckBillingZip: undefined,
    echeckBillingCountry: merchant?.country_code || 'US'
  };

  const getInitialDisabledFieldsState = (
    initialPaymentInfoState,
    initialClientInfoState,
    accepts_cards,
    accepts_banks,
    initialCardState,
    initialEcheckState,
    custom_fields,
    amount_fields,
    recurring_visible
  ) => {
    let initialDisabledFieldsState = {
      amountField: false,
      ...initialPaymentInfoState,
      ...initialClientInfoState
    };
    if (accepts_cards) initialDisabledFieldsState = { ...initialDisabledFieldsState, ...initialCardState, cc_number: false, cvv: false };
    if (accepts_banks) initialDisabledFieldsState = { ...initialDisabledFieldsState, ...initialEcheckState };
    if (custom_fields?.length > 0) custom_fields.map(el => { initialDisabledFieldsState = { ...initialDisabledFieldsState, [el.name]: false }; });
    if (amount_fields?.length > 0) amount_fields.map(el => { initialDisabledFieldsState = { ...initialDisabledFieldsState, [el.name]: false }; });
    if (recurring_visible && recurring_visible !== 'NONE') initialDisabledFieldsState = {
      ...initialDisabledFieldsState,
      recurFrequency: false,
      secondDayOfMonth: false,
      recurringEnds: false,
      endAmountField: false
    };
    for (let key in initialDisabledFieldsState) {
      initialDisabledFieldsState[key] = false;
    }
    return initialDisabledFieldsState;
  };


  const [amountState, setAmountState] = useState(initialAmountState);
  const [paymentInfoState, setPaymentInfoState] = useState(initialPaymentInfoState);
  const [clientInfoState, setClientInfoState] = useState(initialClientInfoState);
  const [cardState, setCardState] = useState(initialCardState);
  const [echeckState, setEcheckState] = useState(initialEcheckState);
  const [centsChargeTotal, setCentsChargeTotal] = useState();
  const [centsAmountDue, setCentsAmountDue] = useState();

  const [customFieldsState, setCustomFieldsState] = useState();
  const [customAmountFieldsState, setCustomAmountFieldsState] = useState();
  const [amountFieldsData, setAmountFieldsData] = useState(initialAmountFieldsData);

  const [tabsState, setTabsState] = useState(generateInitialTabsState);
  const [formErrors, setFormErrors] = useState([]);

  const [revalidateEmail, setRevalidateEmail] = useState();
  const [optionalFields, setOptionalFields] = useState(undefined);
  const [optionalCustomFields, setOptionalCustomFields] = useState([]);
  const [disabledFieldsState, setDisabledFieldsState] = useState(() => getInitialDisabledFieldsState(
    initialPaymentInfoState,
    initialClientInfoState,
    accepts_cards,
    accepts_banks,
    initialCardState,
    initialEcheckState,
    custom_fields,
    amount_fields,
    recurring_visible));

  const [hostedCardFields, setHostedCardFields] = useState();
  const [hostedEcheckFields, setHostedEcheckFields] = useState();
  const [hostedFieldsState, setHostedFieldsState] = useState();

  const [captchaVersion, setCaptchaVersion] = useState('v3');
  const [V2Token, setV2Token] = useState('');

  const [swiperState, setSwiperState] = useState(swiperStates.initial);

  const [isThankYou, setIsThankYou] = useState(false);
  const [thankYouContent, setThankYouContent] = useState('');
  const [isLoanThankYou, setIsLoanThankYou] = useState(false);

  const [isSignature, setIsSignature] = useState(false);
  const [chargeResponse, setChargeResponse] = useState();
  const [dataSignature, setDataSignature] = useState();
  const [frequencyState, setFrequencyState] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSurchargeable, setIsSurchargeable] = useState();
  const [surchargeFormattedCardFee, setSurchargeFormattedCardFee] = useState();
  const [surchargeFormattedAchFee, setSurchargeFormattedAchFee] = useState();
  const [surchargeCardFee, setSurchargeCardFee] = useState();
  const [surchargeAchFee, setSurchargeAchFee] = useState();
  const [disablePayButton, setDisablePayButton] = useState(false);

  const generateCardIcons = () => {
    const iconMap = {
      visa: { src: visaIcon, alt: 'Visa' },
      mastercard: { src: masterCardIcon, alt: 'Master Card' },
      amex: { src: amexIcon, alt: 'American Express' },
      discover: { src: discoverIcon, alt: 'Discover' },
      dinersclub: { src: dinersIcon, alt: 'Diners Club' },
      jcb: { src: jcbIcon, alt: 'JCB' }
    };
    return merchant_account?.accepted_card_type_image_names.map(el => iconMap[el]) || [];
  };

  // eslint-disable-next-line no-unused-vars
  const [cardIconsArray, setCardIconsArray] = useState(generateCardIcons);

  useEffect(() => {
    if (/Trident\/|MSIE /.test(window.navigator.userAgent)) {
      document.location.href = '/unsupported';
    }

    safeSendGA('Payment Page 3.0', 'The bar version of the payment page is displayed', 'PaymentPage-Accordion-Display');
  }, []);

  useEffect(() => {
    const activeTab = tabsState.find(el => el.active);
    const gaBar = activeTab?.text === 'Pay Later' ? 'PL' : activeTab?.text;
    if (gaBar) {
      safeSendGA('Payment Page 3.0', `The ${gaBar} bar is displayed (clicked)`, `${gaBar}-Accordion-Display`);
    }
    CaptchaHelper.removeCaptcha(() => CaptchaHelper.turnOnV2(setCaptchaVersion, setFormErrors, v2_captcha_key));
  }, [tabsState]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    handleSignatureLink(params);
    CaptchaHelper.setV2Callbacks(setV2Token, setCaptchaVersion, formErrors, setFormErrors);
    setOptionalFields(optionalFieldsHelper.getOptionalFields(
      reference_required,
      tabsState,
      cc_required_fields,
      ach_required_fields
    ));
    HostedFieldsHelper.initLibrary(
      merchant,
      accepts_cards,
      hostedFieldsCallback,
      accepts_banks,
      hosted_fields_config,
      cc_surcharge_enabled,
      setHostedEcheckFields,
      setHostedCardFields
    );
    sendOnLoadGAEvent();
    sendQrGAEvent(params);
    setFormFieldsStateFromUrl(
      params,
      disabledFieldsState,
      setDisabledFieldsState,
      amountState,
      setAmountState,
      paymentInfoState,
      setPaymentInfoState,
      clientInfoState,
      setClientInfoState,
      customFieldsState,
      setCustomFieldsState,
      accepts_cards,
      cardState,
      setCardState,
      accepts_banks,
      echeckState,
      setEcheckState,
      frequencyState,
      setFrequencyState
    );
  }, []);

  useEffect(() => {
    if (chargeResponse && chargeResponse.method && (signature === 2 || signature === 3)) {
      setIsSignature(true);
    }
  }, [chargeResponse]);

  useEffect(() => {
    if (cc_surcharge_enabled)
      setSurchargeCardFee(HostedFieldsHelper.setStateFromProps(cc_surcharge_type, cc_surcharge_amount, cc_surcharge_percent, centsAmountDue));
  }, [cc_surcharge_type]);

  useEffect(() => {
    if (ach_surcharge_enabled)
      setSurchargeAchFee(HostedFieldsHelper.setStateFromProps(ach_surcharge_type, ach_surcharge_amount, cc_surcharge_percent, centsAmountDue));
  }, [ach_surcharge_type]);

  useEffect(() => {
    const centsAmountDue = getCentsFromMaskedMoneyInput(amountState.amountField) + getCentsFromDollars(amountState.customFieldsSubtotal),
      activeTab = tabsState.find(el => el.active),
      needToRecalculateSurchargeCard = cc_surcharge_enabled && cc_surcharge_type === 'percent',
      needToRecalculateSurchargeAch = ach_surcharge_enabled && ach_surcharge_type === 'percent';
    if (activeTab?.text === paymentPageTabsText.card && needToRecalculateSurchargeCard) {
      const calculatedSurcharge = HostedFieldsHelper.calculatePercent(cc_surcharge_percent, centsAmountDue, false);
      setSurchargeCardFee(calculatedSurcharge);
      setSurchargeFormattedCardFee(`${cc_surcharge_percent}% / ${DineroCentsFormat(calculatedSurcharge, paymentMethodCurrency())}`);
    } else if (activeTab?.text === paymentPageTabsText.echeck && needToRecalculateSurchargeAch) {
      const calculatedSurcharge = HostedFieldsHelper.calculatePercent(ach_surcharge_percent, centsAmountDue, false);
      setSurchargeAchFee(calculatedSurcharge);
      setSurchargeFormattedAchFee(`${ach_surcharge_percent}% / ${DineroCentsFormat(calculatedSurcharge, paymentMethodCurrency())}`);
    }
    setCentsAmountDue(centsAmountDue);
  }, [amountState, tabsState]);

  useEffect(() => {
    let total = centsAmountDue;
    const activeTab = tabsState.find(el => el.active);
    if (activeTab?.text === paymentPageTabsText.card && cc_surcharge_enabled && isSurchargeable) {
      total += surchargeCardFee;
    } else if (activeTab?.text === paymentPageTabsText.echeck && ach_surcharge_enabled) {
      total += surchargeAchFee;
    }
    setCentsChargeTotal(total);
  }, [centsAmountDue, isSurchargeable, surchargeAchFee, surchargeCardFee, tabsState]);

  useEffect(() => {
    if (window.affirm) {
      affirm.ui.ready(function () {
        affirm.ui.refresh();
      });
    }
  }, [centsAmountDue]);

  useEffect(() => {
    const fee = cc_surcharge_type === 'percent' ? `${cc_surcharge_percent}%` : DineroDollarsFormat(cc_surcharge_amount, paymentMethodCurrency());
    setSurchargeFormattedCardFee(fee);
  }, [cc_surcharge_enabled]);

  useEffect(() => {
    const fee = ach_surcharge_type === 'percent' ? `${ach_surcharge_percent}%` : DineroDollarsFormat(ach_surcharge_amount, paymentMethodCurrency());
    setSurchargeFormattedAchFee(fee);
  }, [ach_surcharge_enabled]);

  useEffect(() => {
    if (swiperState.isSwiping === true) {
      setOptionalFields(optionalFieldsHelper.getOptionalFields(
        reference_required,
        tabsState,
        swipe_cc_required_fields,
        ach_required_fields
      ));
    } else if (
      swiperState.isSwiping === false &&
      swiperState.isSwiped === false
    ) {
      setOptionalFields(optionalFieldsHelper.getOptionalFields(
        reference_required,
        tabsState,
        cc_required_fields,
        ach_required_fields
      ));
    }
  }, [swiperState.isSwiping, swiperState.isSwiped]);

  const hostedFieldsCallback = state => {
    if (cc_surcharge_enabled) {
      const ccField = state?.fields.find(el => el.type === 'credit_card_number');
      const eCheckFieldsShowing = state?.fields.some(el => el.type === 'routing_number' || el.type === 'bank_account_number');

      if (!eCheckFieldsShowing) {
        const surchargeable = ccField?.surchargeable;
        setDisablePayButton(surchargeable === 'verifying');
        setIsSurchargeable(surchargeable === true);
      }
    }
    setHostedFieldsState(state);
  };

  const handleSignatureLink = params => {
    const signatureToken = params.get('sig');
    if (signatureToken) {
      processSignatureLink(`/signatures/${signatureToken}`)
        .then(async (res) => {
          if (res.ok) {
            const json = await res.json();
            // 2 - electronic, 0 - no signature, 1 - line on receipt
            if (signature === 2) {
              setChargeResponse(json.charge);
            } else {
              setIsThankYou(true);
              setThankYouContent(json.receipt_html);
            }
          } else {
            rollbarLog('Payment Page', `Failed to process 'resend signature' with non-ok: ${res.status}`, 'warning');
            window.location = payment_page_url;
          }
        })
        .catch(err => {
          rollbarLog('Payment Page', `Failed to reach server to process 'resend signature' with: ${err.status}`, 'warning');
          window.location = payment_page_url;
        });
    }
  };

  const getActiveState = () => {
    // eslint-disable-next-line no-unused-vars
    for (const el of tabsState) {
      if (el.active) {
        if (el.text === paymentPageTabsText.card) {
          return cardState;
        } else if (el.text === paymentPageTabsText.echeck) {
          return echeckState;
        }
      }
    }
  };

  const sendOnLoadGAEvent = () => {
    if (accepts_banks && accepts_cards) {
      safeSendGA('Payment Page 3.0', 'Page load', `Card, surcharge: ${cc_surcharge_enabled}; Echeck, surcharge: ${ach_surcharge_enabled}`);
    } else if (accepts_banks) {
      safeSendGA('Payment Page 3.0', 'Page load', `Echeck only, surcharge: ${ach_surcharge_enabled}`);
    } else if (accepts_cards) {
      safeSendGA('Payment Page 3.0', 'Page load', `Card only, surcharge: ${cc_surcharge_enabled}`);
    }
  };

  const sendQrGAEvent = params => {
    if (params.get('qr_used') === 'true') {
      safeSendGA('Payment Page 3.0', 'Page loaded from QR Code', `Payment Title: ${title}; Merchant Name: ${merchant.name}; Payment URL: ${payment_page_url}`);
    }
  };

  const handleAccordionToggle = el => {
    const state = tabsState.map(tab => {
      const newTab = { ...tab };
      newTab.text === el.name ? newTab.active = !newTab.active : newTab.active = false;
      return newTab;
    });
    setTabsState(state);

    optionalFieldsHelper.setEmailFieldRequiredOrOptional(
      state,
      cc_required_fields,
      ach_required_fields,
      optionalFields,
      setRevalidateEmail,
      setOptionalFields
    );

    if (amount_fields?.length > 0) {
      const activeTab = state.find(st => st.active);
      if (activeTab?.text === paymentPageTabsText.card) {
        setAmountFieldsData(amount_fields.filter(el => el.incompatible_payment_types.indexOf('card') === -1));
      } else if (activeTab?.text === paymentPageTabsText.echeck) {
        setAmountFieldsData(amount_fields.filter(el => el.incompatible_payment_types.indexOf('bank') === -1));
      }
    }

    setFormErrors([]);
  };

  const validateLimits = state => {
    const isCardTabActive = Object.prototype.hasOwnProperty.call(state, 'cardName'),
      isEcheckTabActive = Object.prototype.hasOwnProperty.call(state, 'echeckAccountType');
    if (
      cc_limit !== 0 &&
      isCardTabActive &&
      centsChargeTotal > cc_limit) {
      rollbarLog(
        'Payment Page',
        `[Error]: Merchant Transaction Amount Limit Exceeded - card, [Merchant]: ${merchant.name}, [Message]: ${errors.transactionLimit}`,
        'info'
      );
      return [errors.transactionLimit];
    } else if (
      ach_limit !== 0 &&
      isEcheckTabActive &&
      centsChargeTotal > ach_limit
    ) {
      rollbarLog(
        'Payment Page',
        `[Error]: Merchant Transaction Amount Limit Exceeded - ach,  [Merchant]: ${merchant.name}, [Message]: ${errors.transactionLimit}`,
        'info'
      );
      return [errors.transactionLimit];
    } else {
      return [];
    }
  };

  const validateCaptcha = (paymentUrl, chargePayload) => {
    if (captchaVersion === 'v3') {
      try {
        grecaptcha.ready(() => {
          try {
            grecaptcha.execute(v3_captcha_key, { action: 'paymentpage' })
              .then((cToken) => {
                chargePayload.captcha.response = cToken;
                fetchAPI(paymentUrl, chargePayload);
              });
          } catch (err) {
            setIsSubmitting(false);
            setFormErrors([errors.genericRefreshPage]);
            rollbarLog('Payment Page', `V3 captcha failed to execute`);
          }
        });
      } catch (err) {
        setIsSubmitting(false);
        if (err === 'grecaptcha is not defined') {
          setFormErrors([errors.genericRefreshPage]);
          rollbarLog('Payment Page', `V3 captcha failed to load 1 ${err}`);
        } else {
          setFormErrors([errors.genericRefreshPage]);
          rollbarLog('Payment Page', `V3 captcha failed to load 2 ${err}`);
          return false;
        }
      }
    } else {
      chargePayload.captcha.response = V2Token;
      fetchAPI(paymentUrl, chargePayload);
    }
  };

  const getScheduleObject = () => {
    let scheduleObject = {
      schedule: {
        recur_frequency: frequencyState?.recurFrequency || 'NONE',
        second_day_of_month: frequencyState?.secondDayOfMonth || new Date().getDate().toString(),
        end_amount: frequencyState?.endAmountField || ''
      }
    };

    if (recurring_visible === 'FULL' || recurring_visible === 'BASIC') {
      return { ...scheduleObject };
    }
  };

  const getFrequencyText = () => {
    let recurFrequency = frequencyState?.recurFrequency || 'NONE';
    let options = recurring_visible === 'FULL' ? fullFrequencyOptions : basicFrequencyOptions;

    return recurFrequency !== 'NONE' ? options.find((el) => el.value === recurFrequency)?.text.toLowerCase() : 'NONE';
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const activeTab = tabsState.find(el => el.active);
    const activeState = getActiveState(),
      formState = { ...paymentInfoState, ...clientInfoState, ...activeState, ...customFieldsState };

    const gaBar = activeTab?.text === 'Pay Later' ? 'PL' : activeTab?.text;
    if (gaBar) {
      safeSendGA('Payment Page 3.0', `The ${gaBar} bar payment button is submitted`, `${gaBar}-Accordion-Payment`);
    }

    if (!validateFormOnSubmit(formState)) { return; }
    setIsSubmitting(true);

    const hostedFieldsResponse = await HostedFieldsHelper.validate(formState, hostedCardFields, hostedEcheckFields, formErrors, setFormErrors, swiperState);

    if (!hostedFieldsResponse) { setIsSubmitting(false); return; }
    const paymentUrl = payment_page_url;
    const flatResponse = { ...hostedFieldsResponse, ...hostedFieldsResponse.form_data };
    delete flatResponse.form_data;
    let chargePayload = {
      captcha: {
        error: false,
        response: '',
        version: captchaVersion
      },
      charge: {
        amount: getDollarsFromCents(centsChargeTotal),
        reference: formState.reference,
        ...getScheduleObject()
      },
      token: flatResponse
    };

    if (customFieldsState || customAmountFieldsState) {
      chargePayload.charge.data = {};
      chargePayload.charge.data.custom_fields = getSelectedCustomFieldsWithoutSections({ ...customAmountFieldsState, ...customFieldsState });
    }

    if (!maybeAttachSurcharge(chargePayload)) {
      setFormErrors([errors.genericRefreshPage]);
      return;
    }
    validateCaptcha(paymentUrl, chargePayload);
  };

  const maybeAttachSurcharge = chargePayload => {
    const activeTab = tabsState.find(el => el.active);
    if (activeTab.text === paymentPageTabsText.card && cc_surcharge_enabled && isSurchargeable) {
      if (!validateIfSurchargeApplied(surchargeCardFee))
        return false;
      chargePayload.charge.surcharge_amount = surchargeCardFee;
      chargePayload.charge.subtotal_amount = centsAmountDue;
      addSurchargeDataToChargeObject(chargePayload.charge, cc_surcharge_label, cc_surcharge_type, cc_surcharge_amount, cc_surcharge_percent);
    } else if (activeTab.text === paymentPageTabsText.echeck && ach_surcharge_enabled) {
      if (!validateIfSurchargeApplied(surchargeAchFee))
        return false;
      chargePayload.charge.surcharge_amount = surchargeAchFee;
      chargePayload.charge.subtotal_amount = centsAmountDue;
      addSurchargeDataToChargeObject(chargePayload.charge, ach_surcharge_label, ach_surcharge_type, ach_surcharge_amount, ach_surcharge_percent);
    }
    return true;
  };

  const addSurchargeDataToChargeObject = (charge, label, type, flatAmount, percentAmount) => {
    charge.surcharge_label = label;
    charge.surcharge_type = type;
    charge.surcharge_applied = type === 'flat' ? flatAmount : type === 'percent' ? percentAmount : undefined;
  };

  const validateIfSurchargeApplied = (fee, needsLogs = true) => {
    try {
      const calculatedCentsTotal = fee + centsAmountDue,
        isValid = centsChargeTotal - calculatedCentsTotal <= 0.5;
      if (!isValid && needsLogs)
        rollbarLog('Payment Page', `Failed to verify adding surcharge, fee ${fee}, total ${centsChargeTotal}, centsAmountDue ${centsAmountDue}`);
      return isValid;
    } catch (err) {
      if (needsLogs)
        rollbarLog('Payment Page', `Failed to verify adding surcharge, fee ${fee}, total ${centsChargeTotal}, centsAmountDue ${centsAmountDue}, err ${err.message}`);
      return false;
    }
  };

  const validateTotalAmount = () => {
    if (centsAmountDue === 0 || centsChargeTotal === 0) return [errors.zeroChargeAttempt];
    return [];
  };

  const validateExpMonthYear = state => {
    if (!state.cardExpiration) return [];
    let dateErrors = [];
    const exp = state.cardExpiration,
      [_, expMonth, expYear] = exp.match(/^(\d{1,2})\/20(\d{2})$/) || []; // eslint-disable-line no-unused-vars
    if (!expMonth || expMonth.indexOf('_') !== -1) { dateErrors = [...dateErrors, errors.missingExpMonth]; }
    else if (Number(expMonth) > 12 || Number(expMonth) < 1) { dateErrors = [...dateErrors, errors.missingExpMonth]; }
    if (!expYear || expYear.indexOf('_') !== -1) dateErrors = [...dateErrors, errors.missingExpYear];
    return dateErrors;
  };

  const validateFormOnSubmit = formState => {
    let invalidFields = [],
      nameMap = paymentPageFieldNameMap;
    const allOptionalFields = [...optionalFields, ...optionalCustomFields];
    const cvvField = hostedFieldsState?.fields?.find(el => el.type === 'cvv');
    nameMap.reference = reference_label || 'Reference';
    invalidFields = [...invalidFields, ...validateTotalAmount()];
    for (let state in formState) {
      if (!optionalFields.includes(state) && state === 'cardCVV') {
        if (!cvvField || cvvField?.length < 3) {
          invalidFields = [...invalidFields, errors.hostedFields.invalidCVV];
        }
        continue;
      }
      if (!allOptionalFields.includes(state) && !formState[state]) {
        invalidFields = [...invalidFields, `${nameMap[state] || state} is required.`];
      }
    }
    if (centsChargeTotal) {
      invalidFields = [...invalidFields, ...validateLimits(formState)];
    }
    if (formState.email && !isEmailPattern(formState.email)) {
      if (allOptionalFields.includes('email')) {
        invalidFields = [...invalidFields, 'Please provide a valid email address or clear the email field'];
      } else {
        invalidFields = [...invalidFields, 'Please provide a valid email address'];
      }
    }
    if (optionalFields.includes('cardCVV') && cvvField?.length > 0 && cvvField?.length < 3) {
      invalidFields = [...invalidFields, errors.hostedFields.invalidCVV];
    }
    invalidFields = [...invalidFields, ...validateExpMonthYear(formState)];
    setFormErrors(invalidFields);
    return invalidFields.length === 0;
  };

  const sendGAPaymentSuccessEvent = (method, isSurcharged) => {
    const isSurchargedLabel = isSurcharged ? 'Surcharge applied' : 'No Surcharge';
    if (method === 'card') {
      safeSendGA('Payment Page 3.0', 'Payment Successful', `Card ${isSurchargedLabel}`);
    } else if (method === 'bank') {
      safeSendGA('Payment Page 3.0', 'Payment Successful', `echeck ${isSurchargedLabel}`);
    } else {
      safeSendGA('Payment Page 3.0', 'Payment Successful', `Unknown payment method ${isSurchargedLabel}`);
    }
  };

  const fetchAPI = async (paymentUrl, chargePayload) => {
    makeCharge(paymentUrl, chargePayload).then(
      async (res) => {
        if (res.ok) {
          const json = await res.json();
          const receiptHtml = json.receipt_html;
          sendGAPaymentSuccessEvent(json?.charge?.method, !!chargePayload?.charge?.surcharge_amount);
          if (signature === 2 || signature === 3) {
            setChargeResponse(json.charge);
          } else if (redirect_url) {
            window.location = redirect_url;
          } else {
            setIsThankYou(true);
            setThankYouContent(receiptHtml);
          }
        } else {
          const json = await res.json();
          setIsSubmitting(false);
          if (res.status === 403) {
            let uniqueErrors = [...formErrors, errors.captchaVerify];
            setFormErrors([...new Set(uniqueErrors)]);
            CaptchaHelper.turnOnV2(setCaptchaVersion, setFormErrors, v2_captcha_key);
            return false;
          } else if (res.status === 422) {
            if (json?.messages?.length > 0) {
              let logs = [];
              let errors = json.messages.map(msg => {
                const message = gatewayErrorMessages.getMessage(msg, merchant.name);
                logs = [...logs, {
                  code: msg.code,
                  message,
                  severity: msg.level === 'critical' ? msg.level : 'info'
                }];
                return message;
              });
              setFormErrors(errors);
              if (logs.length > 0) {
                logs.forEach(log =>
                  rollbarLog(
                    'Payment Page',
                    `[Error]: ${log.code}, [Merchant]: ${merchant.name}, [Message]: ${log.message}`,
                    log.severity
                  )
                );
              }
            } else {
              setFormErrors([errors.genericRefreshPage]);
              rollbarLog('Payment Page', `Response error messages not found for status 422, body: ${JSON.stringify(json)}`);
            }
          } else {
            setFormErrors([errors.genericRefreshPage]);
            rollbarLog('Payment Page', `Unexpected response status: ${res.status}, body: ${JSON.stringify(json)}`);
          }
        }
      }
    ).catch(err => {
      setIsSubmitting(false);
      setFormErrors([errors.genericRefreshPage]);
      rollbarLog('Payment Page', `makeCharge catch error: ${err.message}`);
    });
  };

  const onSignatureFailure = errMessage => {
    rollbarLog('Payment Page', `Signature Call failed: ${errMessage}`);
  };

  const onSignatureSuccess = (json, dataSignature) => {
    if (redirect_url) {
      window.location = redirect_url;
    } else {
      setDataSignature(dataSignature);
      setThankYouContent(json.receipt_html);
      setIsThankYou(true);
    }
  };

  const handleLoanSuccess = _ => {
    safeSendGA('Payment Page 3.0', 'ClientCredit', 'Application Approved');
    setIsLoanThankYou(true);
  };

  const handleAffirmLinkClicked = (event) => {
    if (event.target.nodeName.toLowerCase() === 'a') {
      safeSendGA(
        'Payment Page 3.0',
        'The PL bar “Example Payment Plans” up-funnel is displayed (link clicked)',
        'PL-Accordion-UpFunnel'
      );
    }
  };

  const paymentMethodCurrency = () => {
    const activeTab = tabsState.find(tab => tab.active);
    if (activeTab) return {
      [paymentPageTabsText.card]: cc_currency,
      [paymentPageTabsText.echeck]: ach_currency,
      [paymentPageTabsText.loan]: loan_currency
    }[activeTab.text];
    return cc_currency || ach_currency || loan_currency || 'USD';
  };

  return (
    <ErrorBoundary>
      <div className="container payment-page-container">
        {isThankYou ? (
          <div className="row" role="main">
            <ThankYouComponent
              content={thankYouContent}
              dataSignature={dataSignature}
              current_user={current_user}
              charge={chargeResponse}
            />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 logo-container mobile-logo-container">
                {merchant.website ? (
                  <a href={merchant.website} target="_blank" rel="noopener noreferrer">
                    <img src={logo.large} className="merchant-logo" alt={`logo for ${merchant.name}`} role="img" />
                  </a>
                ) : (
                  <img src={logo.large} className="merchant-logo" alt={`logo for ${merchant.name}`} role="img" />
                )}
              </div>
              <div className="col-12 col-md-4 business-info order-2 order-sm-1" role="complementary">
                <BusinessInformationComponent
                  {...merchant}
                  logo={logo}
                  notes={notes}
                  hideLocation={hide_location_info}
                />
              </div>
              <div className="col-12 col-md-8 order-1 order-sm-2">
                {!isLoanThankYou && (
                  <>
                    <header role="banner">
                      <h1>{title}</h1>
                      <strong>{description}</strong>
                      <div className="col-12 header-merchant-name">
                        <strong>{merchant.name}</strong>
                      </div>
                    </header>
                  </>
                )}
                {isSignature || isLoanThankYou ? (
                  <>
                    {isLoanThankYou ? (
                      <LoanThankYou
                        brand={merchant?.site?.title}
                      />
                    ) : (
                      <SignatureComponent
                        currency={paymentMethodCurrency()}
                        signature={signature}
                        charge={chargeResponse}
                        text={signature_text}
                        onSignatureFailure={onSignatureFailure}
                        onSignatureSuccess={onSignatureSuccess}
                      />
                    )}
                  </>
                ) : (
                  <div role="main">
                    <div role="form">
                      <form id="paymentForm" aria-label="payment-form">
                        <AmountComponent
                          formState={amountState}
                          setFormState={setAmountState}
                          customFieldsState={customAmountFieldsState}
                          setCustomFieldsState={setCustomAmountFieldsState}
                          fieldsData={amountFieldsData}
                          currencySymbol={paymentMethodCurrency()}
                          disabledFormFieldsState={disabledFieldsState}
                        />
                        <PaymentInformationComponent
                          formState={paymentInfoState}
                          setFormState={setPaymentInfoState}
                          referenceLabel={reference_label}
                          referenceRequired={reference_required}
                          disabledFieldsState={disabledFieldsState}
                        />
                        <CustomFieldsComponent
                          formState={customFieldsState}
                          setFormState={setCustomFieldsState}
                          customFieldsData={custom_fields}
                          optionalFields={optionalCustomFields}
                          setOptionalFields={setOptionalCustomFields}
                          disabledFieldsState={disabledFieldsState}
                        />
                        {recurring_visible && recurring_visible !== 'NONE' && tabsState.find(el => el.active)?.text !== paymentPageTabsText.loan && (
                          <FrequencyComponent
                            formState={frequencyState}
                            setFormState={setFrequencyState}
                            recurringVisible={recurring_visible}
                            disabledFormFieldsState={disabledFieldsState}
                          />
                        )}
                        <div className="row">
                          <div className="col-12">
                            <h2>Payment Method</h2>
                          </div>
                        </div>
                        {accepts_cards && (<>
                          <APAccordion
                            title='Card'
                            subtitle='Debit and Credit accepted.'
                            onToggle={handleAccordionToggle}
                            isOpen={tabsState.find(e => e.text === 'Card').active}
                            name='Card'
                            icons={cardIconsArray}
                            disabled={isSubmitting}
                          >
                            <div className="row">
                              {card_reader_visible && (
                                <SwiperComponent
                                  swiperState={swiperState}
                                  setSwiperState={setSwiperState}
                                  cardState={cardState}
                                  setCardState={setCardState}
                                  options={{ 'byLoggedInUsers': current_user }}
                                  hostedCardFields={hostedCardFields}
                                  disabledFieldsState={disabledFieldsState}
                                  setDisabledFieldsState={setDisabledFieldsState}
                                  tabsState={tabsState}
                                />
                              )}
                              {cc_surcharge_enabled && (
                                <div className='col-12 surcharge-message'>
                                  <div className="blue-i-icon"></div> Credit Card fee: {surchargeFormattedCardFee}
                                </div>
                              )}
                              <CardTabComponent
                                formState={cardState}
                                setFormState={setCardState}
                                optionalFields={optionalFields}
                                privacy_policy_url={privacy_policy_url}
                                isSurchargeable={isSurchargeable}
                                surchargeEnabled={cc_surcharge_enabled}
                                hostedFieldsState={hostedFieldsState}
                                disabledFieldsState={disabledFieldsState}
                                revalidateEmail={revalidateEmail}
                                merchant={merchant}
                                clientInfoState={clientInfoState}
                                setClientInfoState={setClientInfoState}
                                minimalRequiredPaymentFieldsSelected={minimal_required_payment_fields_selected}
                              />
                            </div>
                            <SummaryComponent
                              currencySymbol={paymentMethodCurrency()}
                              centsAmountDue={centsAmountDue}
                              tabsState={tabsState}
                              ccSurcharge={{
                                enabled: cc_surcharge_enabled,
                                percent: cc_surcharge_percent,
                                label: cc_surcharge_label,
                                type: cc_surcharge_type
                              }}
                              achSurcharge={{
                                enabled: ach_surcharge_enabled,
                                percent: ach_surcharge_percent,
                                label: ach_surcharge_label,
                                type: ach_surcharge_type
                              }}
                              isSurchargeable={isSurchargeable}
                              hostedFieldsState={hostedFieldsState}
                              surchargeAchFee={surchargeAchFee}
                              surchargeCardFee={surchargeCardFee}
                            />
                            <SubmitButtonComponent
                              currency={paymentMethodCurrency()}
                              formErrors={formErrors}
                              isSubmitting={isSubmitting}
                              submitForm={submitForm}
                              swiperState={swiperState}
                              centsChargeTotal={centsChargeTotal}
                              paymentType="card"
                              disabled={disablePayButton}
                            />
                            <div className="row">
                              <div className="col-12 payment-disclaimer">
                                By clicking on the Pay Button, you agree to pay the above amount and provide your information to help us process your payment and detect fraud.
                                Please see our <a href={`${privacy_policy_url}`} rel="noopener noreferrer" target="_blank">Privacy Policy</a> for more information on our data practices.
                              </div>
                            </div>
                          </APAccordion>
                        </>)}
                        {accepts_banks && (<>
                          <APAccordion
                            title='eCheck'
                            subtitle='Use your bank account.'
                            onToggle={handleAccordionToggle}
                            name='eCheck'
                            icons={[{ src: bankIcon, alt: 'eCheck' }]}
                            isOpen={tabsState.find(e => e.text === 'eCheck').active}
                            disabled={isSubmitting}
                          >
                            <div className="row">
                              {ach_surcharge_enabled && (
                                <div className='col-12 surcharge-message'>
                                  <div className="blue-i-icon"></div> eCheck fee: {surchargeFormattedAchFee}
                                </div>
                              )}
                              <EcheckTabComponent
                                formState={echeckState}
                                setFormState={setEcheckState}
                                optionalFieldsFromServer={ach_required_fields}
                                optionalFields={optionalFields}
                                setOptionalFields={setOptionalFields}
                                hostedFieldsState={hostedFieldsState}
                                merchant={merchant}
                                frequencyText={getFrequencyText()}
                                privacy_policy_url={privacy_policy_url}
                                revalidateEmail={revalidateEmail}
                                disabledFieldsState={disabledFieldsState}
                                clientInfoState={clientInfoState}
                                setClientInfoState={setClientInfoState}
                                minimalRequiredPaymentFieldsSelected={minimal_required_payment_fields_selected}
                              />
                            </div>
                            <SummaryComponent
                              currencySymbol={paymentMethodCurrency()}
                              centsAmountDue={centsAmountDue}
                              tabsState={tabsState}
                              ccSurcharge={{
                                enabled: cc_surcharge_enabled,
                                percent: cc_surcharge_percent,
                                label: cc_surcharge_label,
                                type: cc_surcharge_type
                              }}
                              achSurcharge={{
                                enabled: ach_surcharge_enabled,
                                percent: ach_surcharge_percent,
                                label: ach_surcharge_label,
                                type: ach_surcharge_type
                              }}
                              isSurchargeable={isSurchargeable}
                              hostedFieldsState={hostedFieldsState}
                              surchargeAchFee={surchargeAchFee}
                              surchargeCardFee={surchargeCardFee}
                            />
                            <SubmitButtonComponent
                              currency={paymentMethodCurrency()}
                              formErrors={formErrors}
                              isSubmitting={isSubmitting}
                              submitForm={submitForm}
                              swiperState={swiperState}
                              centsChargeTotal={centsChargeTotal}
                              paymentType="echeck"
                            />
                          </APAccordion>
                        </>)}
                        {loan_account_id && (
                          <APAccordion
                            title='Pay Later'
                            subtitle={centsChargeTotal <= loan_max_limit ?
                              <>
                                <p className="affirm-as-low-as" data-page-type="product" data-amount={centsChargeTotal} onClick={handleAffirmLinkClicked}></p>
                                {
                                  show_pay_later_promo_text && <p>0% interest for qualified clients. See example payment plans for more information.</p>
                                }
                              </> :
                              <>
                                {<span><strong>The amount you entered is too high.</strong> Please enter an amount of {DineroCentsFormat(loan_max_limit, paymentMethodCurrency())} or less.</span>}
                              </>
                            }
                            onToggle={handleAccordionToggle}
                            isOpen={tabsState.find(e => e.text === 'Pay Later').active}
                            name='Pay Later'
                            icons={[{ src: affirmIcon, alt: 'Affirm' }]}
                            disabled={isSubmitting}
                          >
                            <LoanTab
                              email={clientInfoState.email}
                              merchantPublicKey={merchant.auth_user}
                              merchantName={merchant.name}
                              merchantSupportPhone={merchant.support_phone}
                              reference={paymentInfoState.reference}
                              amount={centsChargeTotal}
                              accountId={loan_account_id}
                              chargeURL={payment_page_url}
                              handleLoanSuccess={handleLoanSuccess}
                              tokenURL={api_gw_url}
                              customFieldsStates={{ ...customAmountFieldsState, ...customFieldsState }}
                              isUserLoggedIn={current_user}
                              brand={merchant?.site?.title}
                              maxLimitCents={loan_max_limit}
                              minLimitCents={loan_min_limit}
                              environment={environment}
                              isAccordionEnabled={true}
                              loanSupportNumber={loan_support_number}
                            />
                          </APAccordion>
                        )}
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FooterComponent
              site={merchant?.site?.identifier}
              hasEcheck={!!accepts_banks}
            />
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

PaymentPage.propTypes = {
  title: string,
  merchant: object,
  merchant_account: object,
  logo: object,
  accepts_banks: bool,
  accepts_cards: bool,
  default_payment_method: string,
  description: string,
  notes: string,
  cc_limit: number,
  formatted_cc_limit: string,
  ach_limit: number,
  formatted_ach_limit: string,
  reference_label: string,
  reference_required: number,
  payment_page_url: string,
  signature: number,
  signature_text: string,
  redirect_url: string,
  current_user: bool,
  cc_required_fields: string,
  ach_required_fields: string,
  amount_fields: array,
  custom_fields: array,
  privacy_policy_url: string,
  cc_currency: string,
  cc_surcharge_enabled: bool,
  cc_surcharge_type: string,
  cc_surcharge_amount: number,
  cc_surcharge_label: string,
  cc_surcharge_percent: string,
  ach_currency: string,
  ach_surcharge_enabled: bool,
  ach_surcharge_type: string,
  ach_surcharge_amount: number,
  ach_surcharge_label: string,
  ach_surcharge_percent: string,
  recurring_visible: string,
  v2_captcha_key: string,
  v3_captcha_key: string,
  hosted_fields_config: object,
  hide_location_info: bool,
  card_reader_visible: bool,
  isSwiped: bool,
  swipe_cc_required_fields: string,
  currency_symbol: string,
  api_gw_url: string,
  loan_account_id: string,
  environment: string,
  loan_currency: string,
  loan_max_limit: number,
  loan_min_limit: number,
  loan_support_number: string,
  minimal_required_payment_fields_selected: bool,
  show_pay_later_promo_text: bool
};

export default PaymentPage;
