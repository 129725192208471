const Partner = ({
  id,
  name,
  error
}) => {
  return {
    id,
    name,
    error
  };
};

export default Partner;
