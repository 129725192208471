import React from 'react';
import {string} from 'prop-types';
import QRCode from 'qrcode.react';
import './style.scss';
import SvgToImage from './SvgToImage';
import MobileScanIcon from '../../components/icons/MobileScanIcon';

const QRCodeCard = ({currentMerchantName = '', id, paymentPath = '', paymentTitle = '', site = 'affinipay'}) => {
  const logoPath=`/assets/${site}/powered-by-logo.png`;
  const qrCardHeaderClass = site === 'cpacharge' ? 'qr-card-header qr-card-header-cpacharge' : 'qr-card-header';
  const qrClassAndId = 'qr-code-svg';
  
  return (
    <div className="qr-card" id={id}>
      <div className={qrCardHeaderClass}>
        <div className="qr-card-scan-icon"><MobileScanIcon /></div>
        <div className="qr-card-header-details">
          <span className="qr-card-header-title">Scan To Pay</span>
          <span className="qr-card-header-subtitle qr-card-merchant-title">{currentMerchantName}</span>
          <span className="qr-card-header-subtitle qr-card-payment-title">{paymentTitle}</span>
        </div>
      </div>
      <div className="qr-card-body">
        <SvgToImage 
          focusId={qrClassAndId}
          parentId="qr-code-wrapper"
          svgSettings={{width: 260, height: 260}} 
        >
          <QRCode
            id={qrClassAndId}
            className={qrClassAndId}
            size={260}
            level="M"
            renderAs="svg" 
            value={paymentPath}
          />
        </SvgToImage>
      </div>
      <div className="qr-card-footer">
        <img src={logoPath}  className="powered-logo" alt={`Powered by ${site}`} />
      </div>
    </div>
  );
};

QRCodeCard.propTypes = {
  currentMerchantName: string,
  id: string,
  paymentPath: string,
  paymentTitle: string,
  site: string
};

export default  QRCodeCard;
