import React from 'react';
import {invoiceProp} from '../../customProps';
import QuickBillStatusIndicator from './QuickBillStatusIndicator';
import QuickBillStatistic from './QuickBillStatistic';
import {daysOutText} from './utils';
import './style.scss';
import {dateFormat} from '../../lib/utils';

const QuickBillStatusBarUnpaid = ({invoice}) => (
  <div className="quick-bill-status-bar">
    <QuickBillStatusIndicator presentmentStatus={invoice.presentmentStatus} />
    <QuickBillStatistic
      label="Age"
      value = {daysOutText(invoice.created.timestamp)}
    />
    <QuickBillStatistic
      label="Last Updated"
      value = {dateFormat(invoice.modified.timestamp)}
    />
  </div>
);

QuickBillStatusBarUnpaid.propTypes = {invoice: invoiceProp};

export default QuickBillStatusBarUnpaid;