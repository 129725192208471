import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { rollbarLog } from '../../../lib/utils';

export default class LoanJSErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    rollbarLog('LoanJS Error Boundary' ,`Failed to load Component: ${error} Info: ${errorInfo}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h2>There was an error. Please refresh the page.</h2>
          <h3>If the issue continues please contact your Service Provider</h3>
        </>
      );
    }
    return this.props.children;
  }
}

LoanJSErrorBoundary.propTypes = {
  error: PropTypes.string,
  children: PropTypes.node
};