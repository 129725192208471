import React from 'react';
import {string} from 'prop-types';

const DraftIcon = ({className = '', title}) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <g fill="#5C6870" fillRule="nonzero"><path d="M4.692 3.429h11.077v1.714H4.692zM4.692 6.857h11.077v1.714H4.692zM4.692 10.286h5.538V12H4.692zM4.692 18.857h3.692v1.714H4.692zM22.73 14.823l-2.77-2.572a.96.96 0 0 0-.652-.251.96.96 0 0 0-.653.251l-8.578 7.965V24h4.074l8.579-7.965a.827.827 0 0 0 .27-.606.827.827 0 0 0-.27-.606zm-9.343 7.463h-1.464v-1.36l4.615-4.285L18.002 18l-4.615 4.286zm5.92-5.498-1.463-1.36 1.464-1.359 1.464 1.36-1.464 1.359z"/>
      <path d="M8.385 24H2.846c-1.019-.001-1.845-.768-1.846-1.714V1.714C1.001.768 1.827.001 2.846 0h14.77c1.019.001 1.844.768 1.846 1.714v8.572h-1.847V1.714H2.846v20.572h5.539V24z"/>
    </g>
  </svg>
);

DraftIcon.propTypes = {
  className: string,
  title: string
};

export default DraftIcon;
