import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import EnrollInAdyenButton from './index';
import client from 'lib/ajax';
import notify from 'lib/notify';

describe('EnrollInAdyenButton component', () => {

  // https://testing-library.com/docs/using-fake-timers/
  beforeEach(() => {
    jest.useFakeTimers();
    jest.spyOn(notify, 'error').mockImplementation(() => {});
    jest.spyOn(notify, 'success').mockImplementation(() => {});
  });
  afterEach(() => {
    jest.runOnlyPendingTimers();
    jest.clearAllTimers();
    jest.useRealTimers();
  });

  it('renders a button when only given required props', () => {
    render(<EnrollInAdyenButton merchantId={1} />);
    expect(screen.getByRole('button', {name: 'Enroll In Adyen'})).toBeInTheDocument();
  });

  it('Shows failed notification and text on component mount when status is failed', () => {
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='failed' adyenSyncMessage='test error' />);
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('changes button text if merchant is partially migrated', () => {
    render(<EnrollInAdyenButton merchantId={1} merchantPartiallyMigrated={true} />);
    expect(screen.queryByRole('button', {name: 'Enroll In Adyen'})).not.toBeInTheDocument();
    expect(screen.getByRole('button', {name: 'Adyen Enrollment Incomplete: Submit again to complete'})).toBeInTheDocument();
  });

  it('Doesnt show button if status is set to completed', () => {
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='completed' />);
    expect(screen.queryByRole('button', {name: 'Enroll in Adyen'})).not.toBeInTheDocument();
    expect(screen.queryByRole('button', {name: 'Adyen Enrollment Incomplete: Submit again to complete'})).not.toBeInTheDocument();
  });

  it('Doesnt show button if status is set to failed', () => {
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='failed' adyenSyncMessage='test error'/>);
    expect(screen.queryByRole('button', {name: 'Enroll In Adyen'})).not.toBeInTheDocument();
    expect(screen.queryByRole('button', {name: 'Adyen Enrollment Incomplete: Submit again to complete'})).not.toBeInTheDocument();
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('Does show button if status is set to failed but merchant is partially migrated', () => {
    render(
      <EnrollInAdyenButton 
        merchantId={1} 
        adyenSyncStatus='failed' 
        adyenSyncMessage='test error' 
        merchantPartiallyMigrated={true} 
      />
    );
    expect(screen.queryByRole('button', {name: 'Enroll In Adyen'})).not.toBeInTheDocument();
    expect(screen.getByRole('button', {name: 'Adyen Enrollment Incomplete: Submit again to complete'})).toBeInTheDocument();
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('Disables enroll button if status is synchronizing', () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'synchronizing'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' />);
    const button = screen.queryByRole('button', {name: 'Enroll In Adyen'});
    expect(button).toBeInTheDocument();
    expect(button).toBeDisabled();
  });

  it('Doesnt disable enroll button if status is synchrozining but merchant is partially migrated', () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'synchronizing'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' merchantPartiallyMigrated={true} />);
    const button = screen.queryByRole('button', {name: 'Adyen Enrollment Incomplete: Submit again to complete'});
    expect(button).toBeInTheDocument();
    expect(button).not.toBeDisabled();
  });

  it('Shows text to show user that it is synchronizing with adyen if status is synchronizing', () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'synchronizing'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' />);
    expect(screen.getByText('Synchronizing with Adyen...')).toBeInTheDocument();
  });

  it('Doesnt show text to show user that it is synchronizing with adyen if status is synchronizing and merchant is partially migrated', () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'synchronizing'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' merchantPartiallyMigrated={true} />);
    expect(screen.queryByText('Synchronizing with Adyen...')).not.toBeInTheDocument();
  });

  it('Doesnt show text to show user that it is synchronizing with adyen if status is not synchronizing', () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'synchronizing'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} />);
    expect(screen.queryByText('Synchronizing with Adyen...')).not.toBeInTheDocument();
  });


  it('Polls for new status every 10 seconds while status is synchronizing', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'synchronizing'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' />);
    expect(client.get).toHaveBeenCalledTimes(0);
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(2));
  });

  it('Stops timer if status returned is completed and shows success banner', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'completed'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' />);
    expect(client.get).toHaveBeenCalledTimes(0);
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(1);
    expect(notify.success).toHaveBeenCalledWith('Successfully synchronized to Adyen');
  });

  it('Stops timer if status returned is failed and shows failed banner', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({status: 'failed', message: 'test error'});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' />);
    expect(client.get).toHaveBeenCalledTimes(0);
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('Stops timer and shows an error if get status request throws an error', async () => {
    jest.spyOn(client, 'get').mockImplementation(() => {
      throw new Error();
    });
    render(<EnrollInAdyenButton merchantId={1} adyenSyncStatus='synchronizing' />);
    expect(client.get).toHaveBeenCalledTimes(0);
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    jest.advanceTimersByTime(10001);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(notify.error).toHaveBeenCalledWith('Error getting sync status from adyen. Please refresh the page and try again', [], false);
  });

  it('clicking the enroll button disables the button', async () => {
    jest.spyOn(client, 'post').mockImplementation(() => {});
    render(<EnrollInAdyenButton merchantId={1} />);
    const button = screen.queryByRole('button', {name: 'Enroll In Adyen'});
    userEvent.click(button);
    expect(button).toBeDisabled();
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(button).not.toBeDisabled();
  });

  it('successfully enrolling merchant shows success banner and reloads the page', async () => {
    // makes reload function on window writable and then sets to jest function so we can test it
    Object.defineProperty(window, 'location', {
      configurable: true,
      value: { reload: jest.fn() }
    });

    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<EnrollInAdyenButton merchantId={1} />);
    const button = screen.queryByRole('button', {name: 'Enroll In Adyen'});
    userEvent.click(button);
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledWith('Merchant enrolled in adyen');
    expect(button).not.toBeDisabled();
    expect(window.location.reload).toHaveBeenCalled();
    jest.restoreAllMocks();
  });

  it('Error enrolling merchant shows error from server in error banner', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );

    render(<EnrollInAdyenButton merchantId={1} />);
    const button = screen.queryByRole('button', {name: 'Enroll In Adyen'});
    userEvent.click(button);
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
    expect(button).not.toBeDisabled();
  });

  it('Error enrolling merchant shows generic error if error from server is undefined', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );

    render(<EnrollInAdyenButton merchantId={1} />);
    const button = screen.queryByRole('button', {name: 'Enroll In Adyen'});
    userEvent.click(button);
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.error).toHaveBeenCalledWith('Error enrolling merchant to Adyen. Please reload the page and try again', [], false);
    expect(button).not.toBeDisabled();
  });

  it('Error enrolling merchant shows generic error if error is thrown', async () => {
    jest.spyOn(client, 'post').mockImplementation(() => {
      throw new Error();
    });
    render(<EnrollInAdyenButton merchantId={1} />);
    const button = screen.queryByRole('button', {name: 'Enroll In Adyen'});
    userEvent.click(button);
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.error).toHaveBeenCalledWith('Error enrolling merchant to Adyen. Please reload the page and try again', [], false);
    expect(button).not.toBeDisabled();
  });

});