export const convertSvgToImage = settings => {
  let imageSettings = {
    svg: '<div></div>',
    mimetype: 'image/png',
    quality: 0.92,
    width: 'auto',
    height: 'auto',
    outputFormat: 'base64',
    ...settings
  };

  return new Promise(resolve => {
    let svgNode;

    if (typeof(imageSettings.svg) === 'string') {
      let SVGContainer = document.createElement('div');
      SVGContainer.style.display = 'none';
      SVGContainer.innerHTML = imageSettings.svg;
      svgNode = SVGContainer.firstElementChild;
    } else {
      svgNode = imageSettings.svg;
    }

    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let svgXml = new XMLSerializer().serializeToString(svgNode);
    let svgBase64 = 'data:image/svg+xml;base64,' + btoa(svgXml);

    const image = new Image();

    image.onload = () => {
      canvas.width = imageSettings.width;
      canvas.height = imageSettings.height;
      context.drawImage(image, 0, 0, imageSettings.width, imageSettings.height);

      if (imageSettings.outputFormat === 'blob') {
        canvas.toBlob(blob => {
          resolve(blob);
        }, imageSettings.mimetype, imageSettings.quality);
      } else {
        resolve(canvas.toDataURL(imageSettings.mimetype, imageSettings.quality));
      }
    };
    image.src = svgBase64;
  });
};
