import React, { useCallback, useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { APAlert, APInputSearch, APSelect, APToast } from 'affinipay-ui-library';
import { camelizeKeys } from 'humps';
import QuickBillsTable from './QuickBillsTable';
import Paginator from './Paginator';
import client from '../../../lib/ajax';
import { debounce } from '../../../lib/utils';
import './style.scss';

const statusOptions = [
  { text: 'Any', value: '' },
  { text: 'Sent', value: 'sent' },
  { text: 'Viewed', value: 'viewed' },
  { text: 'Paid', value: 'paid' }
];

const initialToastValues = {
  show: false,
  header: undefined,
  message: undefined
};

const paramMap = new Map([['currentPage', 'page'], ['currentSort', 'sort'], ['sortDirection', 'direction'], ['invoiceStatusSort', 'status'], ['searchValue', 'search']]);

const buildSearchParams = stateValues => Object.keys(stateValues).reduce((acc, stateName) => {
  if (stateValues[stateName]) {
    acc[paramMap.get(stateName)] = stateValues[stateName];
  }
  return acc;
}, {});

const QuickBillTableContainer = ({canCollectPayments, permissionBannerHidden }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sortDirection, setSortDirection] = useState('desc');
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [pageSize, setPageSize] = useState();
  const [searchValue, setSearchValue] = useState();
  const [showCollectPaymentsNotice, setShowCollectPaymentsNotice] = useState(!canCollectPayments && !permissionBannerHidden);
  const [invoiceStatusSort, setInvoiceStatusSort] = useState();
  const [currentSort, setCurrentSort] = useState('created');
  const [totalEntries, setTotalEntries] = useState();
  const [toastValues, setToastValues] = useState(initialToastValues);

  const debounceInvoiceData = useCallback(debounce(searchValues => fetchFromServer(searchValues), 300), []);

  useEffect(() => {
    void fetchFromServer({
      currentPage,
      currentSort,
      sortDirection,
      invoiceStatusSort,
      searchValue
    });
  }, []);

  const handleToastClose = () => {
    setToastValues({
      ...initialToastValues
    });
  };

  const fetchFromServer = async (fetchValues) => {
    if (!isLoading) setIsLoading(true);

    const searchParams = buildSearchParams(fetchValues);
    const queryString = new URLSearchParams([...Object.entries(searchParams)]).toString();
    const response = await client.get(`/quick_bills?${queryString}`, searchParams);
    const {status: responseStatus} = response;
    const json = await response.json();

    const successAction = () => {
      const { page, pageSize, results, totalEntries } = camelizeKeys(json);
      setCurrentPage(page);
      setPageSize(pageSize);
      setInvoices(results);
      setTotalEntries(totalEntries);
      setIsLoading(false);
    };

    const unauthorizedAction = () => {
      setToastValues({
        show: true,
        header: 'Not Authorized',
        message: 'You do not have permission to do this operation.'
      });
      setIsLoading(false);
    };

    const failedFetchAction = () => {
      setToastValues({
        show: true,
        header: 'Error',
        message: 'Unable to fetch QuickBills.'
      });
      setIsLoading(false);
    };

    const actionMap = new Map([
      [200, successAction],
      [401, unauthorizedAction],
      [403, unauthorizedAction],
      [422, failedFetchAction]
    ]);

    const action = actionMap.get(responseStatus);
    if (typeof action === 'function') {
      action();
    } else {
      failedFetchAction();
    }
  };

  const handleStatusChange = async status => {
    const value = status?.selectedItem?.value;

    setInvoiceStatusSort(value);

    fetchFromServer({
      currentPage: 1,
      currentSort,
      sortDirection,
      invoiceStatusSort: value,
      searchValue
    });
  };

  const handleSearchChange = async ({ target: { value } }) => {
    await setSearchValue(value);

    await debounceInvoiceData({
      currentPage,
      currentSort,
      sortDirection,
      invoiceStatusSort,
      searchValue: value
    });
  };

  const handleSortChange = statusSelected => {
    const updatedDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(updatedDirection);
    setCurrentSort(statusSelected);
    fetchFromServer({
      currentPage: 1,
      currentSort: statusSelected,
      sortDirection: updatedDirection,
      invoiceStatusSort,
      searchValue
    });
  };

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage);

    fetchFromServer({
      currentPage: selectedPage,
      currentSort,
      sortDirection,
      invoiceStatusSort,
      searchValue
    });
  };

  const dismissCollectPaymentsNotice = async () => {
    await client.post('/quick_bills/dismiss_permission_banner');
    setShowCollectPaymentsNotice(false);
  };

  return (
    <div className="eqb-wrapper">
      {
        showCollectPaymentsNotice && (
          <div className="row">
            <div className="col-sm-6">
              <APAlert alertType="information" onDismiss={dismissCollectPaymentsNotice}>
                You have the permissions to manage but not edit or send Quick
                Bills.
              </APAlert>
            </div>
          </div>
        )
      }
      <div className="row eqb-toolbar">
        <div className="col-sm-6">
          <APSelect
            label="Status"
            onChange={handleStatusChange}
            options={statusOptions}
            value={invoiceStatusSort}
          />
        </div>
        <div className="col-sm-6">
          <APInputSearch
            className="quick-bill-search-textbox"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            onClick={handleSearchChange}
            dataset={{['data-testid']: 'invoice-input-search'}}
          />
        </div>
      </div>
      <QuickBillsTable
        invoices={invoices}
        currentSort={currentSort}
        sortDirection={sortDirection}
        onSort={handleSortChange}
      />
      <Paginator
        className="pull-right"
        currentPage={currentPage}
        pageSize={pageSize}
        totalEntries={totalEntries}
        onClick={handlePageChange}
      />
      <APToast
        type='error'
        isOpen={toastValues.show}
        header={toastValues.header}
        fadeOutTime={5000}
        onClose={handleToastClose}
      >
        {toastValues.message}
      </APToast>
    </div>
  );
};

QuickBillTableContainer.propTypes = {
  canCollectPayments: bool,
  permissionBannerHidden: bool
};

export default QuickBillTableContainer;
