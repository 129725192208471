import React from 'react';
import {string} from 'prop-types';

const IconViewed = ({className = ''}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 36 36"
    className={className}
  >
    <path d="M18,23c-2.8,0-5-2.3-5-5s2.3-5,5-5s5,2.3,5,5S20.8,23,18,23z M18,16c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9
    s1.9-0.9,1.9-1.9S19.1,16,18,16z"/>
    <path d="M18,29.2c-8.6,0-15.2-10-15.4-10.4L2,17.9l0.6-0.8C2.8,16.7,9.4,6.7,18,6.7s15.2,10,15.4,10.4l0.6,0.8
    l-0.6,0.8C33.2,19.2,26.6,29.2,18,29.2z M5.7,17.9c1.7,2.2,6.7,8.2,12.3,8.2c5.6,0,10.6-5.9,12.3-8.2c-1.7-2.2-6.7-8.2-12.3-8.2
    C12.4,9.8,7.4,15.7,5.7,17.9z"/>
  </svg>
);

IconViewed.propTypes = {
  className: string
};

export default IconViewed;
