import React from 'react';
import {array, func, string} from 'prop-types';

const HeaderProfileMenu = ({
  desktopUserName,
  items = [],
  mobileUserName,
  onClick
}) => {
  const menuItems = items.map((el, i) => {
    if (!el.isShown) {
      return;
    }

    const iconName = el.icon.toLowerCase().replace(' ', '-');

    const attributes = {
      target: el.newTab ? '_blank' : undefined,
      className: `header-profile__${iconName}`
    };

    let dataAttributes = {};
    if (el.data) {
      for (let dataName in el.data) {
        dataAttributes[`data-${dataName}`] = el.data[dataName];
      }
    }

    return (
      <li key={i}>
        <a href={el.url} {...attributes} {...dataAttributes}>
          <div id={iconName} className="nav-icon" />
          <span>{el.text}</span>
          { el.displayBadge && <div className="menu-notification" /> }
        </a>
      </li>
    );
  });

  return (
    <div className="header-menu">
      <div id="header-profile-toggle" onClick={onClick}>
        <div className="nav-icon" id="profile-blue" />
        <span className="desktop-username">{desktopUserName}</span>
        <span className="mobile-username">{mobileUserName}</span>
      </div>
      <div id="header-profile-menu">
        <ul>
          {menuItems}
        </ul>
      </div>
    </div>
  );
};

HeaderProfileMenu.propTypes = {
  desktopUserName: string,
  items: array,
  mobileUserName: string,
  onClick: func
};

export default HeaderProfileMenu;
