export const displayAbilities = {
  admin: 'Administrator',
  charge: 'Collect Payments',
  schedule_charge: 'Schedule Recurring Charges',
  refund: 'Void Transactions and Issue Refunds',
  tag_transactions: 'Tag Transactions',
  view_reports: 'View Reports',
  developer: 'Developer',
  manage_quick_bills: 'Manage Quick Bills and Invoices',
  payment_method_write: 'Manage Cards In Vault'
};

export const displayAccounts = [
  'User can access all accounts', 
  'Operating', 
  'Trust',
  'Operating ACH',
  'Test Processing Account',
  'Test ACH Processing Account',
  'Wells Operating',
  'Wells Trust',
  'Wells Operating ACH',
  'Wells Trust ACH',
  '[Orphan] ACH account',
  '[Orphan] CC account',
  'Account 869'
];

export const userAbilities = [
  'charge',
  'schedule_charge',
  'refund',
  'tag_transactions',
  'view_reports',
  'developer',
  'manage_quick_bills',
  'payment_method_write'
];

export const userAbilitiesForCheckList = [
  'admin',
  'charge',
  'schedule_charge',
  'refund',
  'tag_transactions',
  'view_reports',
  'developer',
  'manage_quick_bills',
  'payment_method_write'
];

export const nonEntitlementUserAbilitiesForCheckList = [
  'tag_transactions',
  'developer',
  'payment_method_write'
];

export const userAccounts = [
  {
    'id': 4,
    'name': 'Operating'
  },
  {
    'id': 5,
    'name': 'Trust'
  },
  {
    'id': 6,
    'name': 'Operating ACH'
  },
  {
    'id': 7,
    'name': 'Test Processing Account'
  },
  {
    'id': 8,
    'name': 'Test ACH Processing Account'
  },
  {
    'id': 9,
    'name': 'Wells Operating'
  },
  {
    'id': 10,
    'name': 'Wells Trust'
  },
  {
    'id': 11,
    'name': 'Wells Operating ACH'
  },
  {
    'id': 12,
    'name': 'Wells Trust ACH'
  },
  {
    'id': 13,
    'name': '[Orphan] ACH account'
  },
  {
    'id': 14,
    'name': '[Orphan] CC account'
  },
  {
    'id': 41,
    'name': 'Account 869'
  }
];