import React, {useEffect, useState} from 'react';
import {array, func, shape, string} from 'prop-types';
import QuickBillSpinner from '../QuickBillSpinner';
import AttachmentsButton from './AttachmentsButton';
import AttachmentsList from './AttachmentsList';
import './style.scss';
import {rollbarLog} from '../../../../lib/utils';
import client from '../../../../lib/ajax';

const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

const initialFileSizeException = {
  exceeded: false,
  name: undefined,
  size: undefined
};

export const decorateFileSize = (fileSize = 0) => {
  let sizingNames = ['B', 'KB', 'MB'];
  let sizing = 0;
  while(sizing < 3 && fileSize > 1000){
    fileSize = fileSize / 1000;
    sizing++;
  }

  return `${sizing > 0 ? fileSize.toFixed(2) : fileSize}${sizingNames[sizing]}`;
};

const QuickBillAttachments = ({
  formValues,
  setFormValues,
  setAttachmentsToDelete,
  setIsDisabled,
  checkValuesMissing,
  merchantId
}) => {
  const [error, setError] = useState();
  const [fileSizing, setFileSizing] = useState(initialFileSizeException);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();
  const [independentFiles, setIndependentFiles] = useState([]);

  useEffect(() => {
    if (fileToUpload) {
      setIsDisabled(true);
      setIsFileUploading(true);
      authorizeFile();
    } else {
      setIsFileUploading(false);
    }
  }, [fileToUpload]);

  const unknownErrorAction = message => {
    setError(message);
    rollbarLog('Quick Bill', message);
    setIsFileUploading(false);
    setFileToUpload();
    setIsDisabled(checkValuesMissing(formValues));
  };


  const virusScanCheck = async attachment => {
    const response = await client.get(`/quick_bills/attachments/${attachment.id}/virus_scan_check`);
    const {status} = response;

    const successAction = () => {
      const attachments = formValues.attachments.concat(attachment);
      setIsDisabled(false);
      setFormValues({
        ...formValues,
        attachments
      });

      setIndependentFiles(prev => [...prev, attachment]);
    };

    const acceptedAction = () => setTimeout(() => virusScanCheck(attachment), 3000);

    const statusActionMap = new Map([
      [200, successAction],
      [202, acceptedAction]
    ]);

    const action = statusActionMap.get(status);

    if (typeof action === 'function') {
      action();
      if (status === 200) {
        setIsDisabled(checkValuesMissing(formValues));
        setIsFileUploading(false);
      }

      return;
    }

    unknownErrorAction(`Virus scan has failed for file: ${fileToUpload.name}`);
  };

  const authorizeFile = async () => {
    const response = await client.get('/quick_bills/authorize_attachment');
    const {status} = response;
    if (status === 200) {
      const json = await response.json();
      uploadFile(json);
      return;
    }
    unknownErrorAction(`Could not get permission to upload file: ${fileToUpload.name}`);
  };

  const uploadFile = async authorizeJSON => {
    const {name} = fileToUpload;
    const formDataForDirect = new FormData();
    formDataForDirect.append('file', fileToUpload);
    formDataForDirect.append('name', name);

    const response = await client.multiPartPost(authorizeJSON.url, formDataForDirect);
    const {status} = response;
    const json = await response.json();

    if ('messages' in json) {
      const {messages} = json;
      const {message} = messages[0];
      setError(message);
      setIsFileUploading(false);
      setIsDisabled(checkValuesMissing(formValues));
      return;
    }
    if (status === 200) {
      const attachment = {
        id: json.id,
        fileName: name,
        size: fileToUpload.size
      };
      virusScanCheck(attachment);
      return;
    }
    unknownErrorAction(`Could not upload file: ${fileToUpload.name}`);
  };

  const handleNewAttachment = ({target}) => {
    setFileToUpload(undefined);
    setError(undefined);
    setFileSizing(initialFileSizeException);
    let fileToUpload = target?.files[0];
    const isSizeLimitExceeded = fileToUpload?.size > FILE_SIZE_LIMIT;
    if (isSizeLimitExceeded) {
      setFileSizing({
        exceeded: true,
        name: fileToUpload?.name,
        size: fileToUpload?.size
      });
      setIsDisabled(checkValuesMissing(formValues));
      setIsFileUploading(false);
      return;
    }

    if (fileToUpload && fileToUpload.name) {
      const extension = fileToUpload.name?.split('.').pop();
      const allowedFileExtensions = ['csv', 'doc', 'docx', 'gif', 'jpg', 'pdf', 'png', 'rtf', 'tif', 'txt', 'xlsx', 'xls'];

      if (allowedFileExtensions.indexOf(extension.toLowerCase()) === -1) {
        setError(`File extension is invalid, it must be one of: ${allowedFileExtensions.join(', ')}`);
        setIsFileUploading(false);
        return;
      }
    }
    if (fileToUpload){
      target.value = null;
      setFileToUpload(fileToUpload);
    }
  };

  const removeAttachment = async attachment_id => {
    const {attachments} = formValues;
    const reducedAttachments = attachments.filter(a => a.id !== attachment_id);
    const isFileIndependent = Boolean(independentFiles.find(a => a.id === attachment_id));

    if (isFileIndependent) {
      const {ok, status} = await client.destroy(`/quick_bills/attachments/${attachment_id}/delete_from_merchant/${merchantId}`);

      if (ok && (status >= 200 || status < 300)) {
        setFormValues({ ...formValues, attachments: reducedAttachments });
      } else {
        setError('An error occurred removing the attachment, please try again.');
      }
    } else {
      setAttachmentsToDelete(prev => [...prev, attachment_id]);
      setFormValues({ ...formValues, attachments: reducedAttachments });
    }
  };

  const showFileSizeExceededMessage = file => (
    `File size is limited to 10MB. ${file.name} has a size of ${decorateFileSize(file.size)}`
  );

  return (
    <div id="quick-bill-attachments">
      <p className="quick-bill-attachments-label">
        <span>
          Add Attachment(s) (Optional){
            (fileSizing.exceeded || error) &&
            <span className='attachment-size-exceeded'>
              {`${fileSizing.exceeded ? showFileSizeExceededMessage(fileSizing) : ''} ${error ? error : ''}`}
            </span>
          }
        </span>
      </p>
      <AttachmentsButton
        onChange={handleNewAttachment}
        disabled={isFileUploading}
      />
      {isFileUploading && (
        <div className="quick-bill-attachments-upload-message">
          <span>Uploading file:</span><span>{fileToUpload.name}</span>
          <QuickBillSpinner />
        </div>
      )}
      {formValues.attachments?.length > 0 && (
        <AttachmentsList
          attachments={formValues.attachments}
          removeAttachment={removeAttachment}
        />
      )}
    </div>
  );
};

QuickBillAttachments.propTypes = {
  invoiceId: string,
  merchantId: string.isRequired,
  formValues: shape({
    amount: string,
    attachments: array,
    bankAccountId: string,
    emailAddress: string,
    emailBody: string,
    emailSubject: string,
    firstName: string,
    lastName: string,
    reference: string
  }),
  checkValuesMissing: func,
  setAttachmentsToDelete: func,
  setFormValues: func,
  setIsDisabled: func
};

export default QuickBillAttachments;
