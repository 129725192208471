import React from 'react';
import {func, object, string} from 'prop-types';
import { APInputMonetary, APInputText } from 'affinipay-ui-library';

const LimitsSection = ({
  formState,
  setFormState,
  onInputValidation,
  statePrefix
}) => {
  const handleInputChange = e => {
    const {name, value} = e.target;
    let newState = {[`${name}`]: value};
    setFormState({...formState, ...newState});
    onInputValidation(name, value);
  };

  return (
    <section>
      <div className="row">
        <div className="col-xs-12">
          <h4 className='uppercase'>Limits</h4>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-xs-12">
          <label className="transaction-label"><strong>Daily Transactions</strong></label>
        </div>
        <div className="col-xs-2">
          <APInputText
            label="Warning"
            name={`${statePrefix}DailyWarning`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}DailyWarning`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputText
            label="Max"
            name={`${statePrefix}DailyMax`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}DailyMax`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputMonetary
            label="Warning"
            name={`${statePrefix}DailyWarningMonetary`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}DailyWarningMonetary`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputMonetary
            label="Max"
            name={`${statePrefix}DailyMaxMonetary`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}DailyMaxMonetary`]}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col-xs-12">
          <label className="transaction-label"><strong>Monthly Transactions</strong></label>
        </div>
        <div className="col-xs-2">
          <APInputText
            label="Warning"
            name={`${statePrefix}MonthlyWarning`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}MonthlyWarning`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputText
            label="Max"
            name={`${statePrefix}MonthlyMax`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}MonthlyMax`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputMonetary
            label="Warning"
            name={`${statePrefix}MonthlyWarningMonetary`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}MonthlyWarningMonetary`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputMonetary
            label="Max"
            name={`${statePrefix}MonthlyMaxMonetary`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}MonthlyMaxMonetary`]}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col-xs-12">
          <label className="transaction-label"><strong>Single Transaction</strong></label>
        </div>
        <div className="col-xs-2">
          <APInputMonetary
            label="Warning"
            name={`${statePrefix}SingleWarningMonetary`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}SingleWarningMonetary`]}
          />
        </div>
        <div className="col-xs-2">
          <APInputMonetary
            label="Max"
            name={`${statePrefix}SingleMaxMonetary`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}SingleMaxMonetary`]}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col-xs-2">
          <APInputText
            label="Transaction Floor"
            name={`${statePrefix}TransactionFloor`}
            onChange={handleInputChange}
            parentClass="admin"
            value={formState[`${statePrefix}TransactionFloor`]}
          />
        </div>
      </div>
    </section>

  );
};

LimitsSection.propTypes = {
  formState: object,
  setFormState: func,
  onInputValidation: func,
  statePrefix: string
};
export default LimitsSection;
