import React from 'react';
import Mask from 'components/MaskedInput';

import PropTypes from 'prop-types';

class Masked extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  handleChange(value) {
    this.setState({
      value: value,
    });
  }

  render() {
    const { name, mask, placeholder } = this.props;
    const mappedMask = mask.map((x) => {
      if (x.startsWith('(?-mix:')) {
        return new RegExp(x.match(/\(\?-mix:(.*)\)/)[1]);
      } else {
        return x;
      }
    });
    return (
      <Mask
        className="form-control"
        mask={mappedMask}
        name={name}
        placeholder={placeholder}
        value={this.state.value}
      />
    );
  }
}

Masked.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
};

export default Masked;
