import server, { maybeParseJSON } from './server';
import {rollbarLog} from './utils';
import AccessToken from './AccessToken';

/**
 * Fetches an access token from the identity service and returns
 * a promise yielding an access token object
 * @async
 * @returns {Promise<AccessToken>}
 */
export default async (authorizationUrl) => {
  try {
    const res = await server.get({ url: authorizationUrl, credentials: 'include' });
    const json = await maybeParseJSON(res);
    return AccessToken(json);
  } catch(err) {
    rollbarLog('Terminal Checkout Access Token Service', `Identity service returned error: ${err.message}`);
    return AccessToken({error: err.message});
  }
};
